import React, { useContext, useEffect, useState } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { Field } from 'formik';

import { context } from '../../../../context/Context';
import { FormElementTypes } from './FormikComponent';

// get inner div of FormikComponent containing "data-name": "form-div"
export const findFormElements = (block) => {
	for (let i = 0; i < block?.length; i++) {

		if (block[i]?.data != undefined && "data-name" in block[i]?.data && block[i].data["data-name"] == "form-div") {
			const formElements = block[i].children;
			return formElements;

		} else if (block[i]?.children) {
			const formElements = findFormElements(block[i].children);

			if (formElements != null && formElements != undefined) {
				return formElements;
			}
		}
	}
}

// get form elements only from FormikComponent block
export const getFormElementsOnly = (block, formElements) => {
	for (let i = 0; i < block?.length; i++) {
		if (FormElementTypes.includes(block[i].type)) {
			formElements.push(block[i])
		} else if (block[i]?.children) {
			getFormElementsOnly(block[i].children, formElements);
		}
	}
}

// check if FormikComponent exists in schema & return its form_id
export const findFormikComponent = (children) => {
	for (let i = 0; i < children?.length; i++) {

		if ('FormikComponent' == children[i].type) {
			const form_id = children[i].id;
			// console.log('found', children[i], form_id);
			return form_id;

		} else if (children[i]?.children) {
			const form_id = findFormikComponent(children[i].children)

			if (form_id != undefined && form_id != null) {
				return form_id;
			}
		}
	}
}

// label
const Label = ({ block }) => {
	return (
		<>
			{
				block.data.label &&
				<div className='flex' data-name="form">
					<label
						// htmlFor={block.id} 				// used in real components
						id={`input_label_${block.id}`}
						data-name="label"
						className={`${block.data.css['label']} ${block.data.help ? "mb-1" : "mb-2"}`}
						onDoubleClick={(e) => {
							e.target.contentEditable = true
							e.target.suppressContentEditableWarning = true
						}}
						onBlur={async (e) => {
							block.data.labelText = e.target.innerText
						}}
					>
						{block.data.labelText}
					</label>
					{block.data.required && <span className='ms-1 text-red-600'>*</span>}
				</div>
			}
		</>
	)
}

// help text or description
const HelpText = ({ block }) => {
	return (
		<>
			{
				block.data.help &&
				<p
					id={`input_help_${block.id}`}
					data-name="help"
					className={block.data.css['help']}
					onDoubleClick={(e) => {
						e.target.contentEditable = true
						e.target.suppressContentEditableWarning = true
					}}
					onBlur={async (e) => {
						block.data.helpText = e.target.innerText
					}}
				>
					{block.data.helpText}
					{/* {block.data.helpText ? block.data.helpText : "Help Text (Optional)"} */}
				</p>
			}
		</>
	)
}

// validation message
const ValidationMessage = ({ block, formik }) => {
	return (
		<>
			{
				block.data.validations.length != 0 && formik.touched[block.data.stateName] && formik.errors[block.data.stateName] &&
				!formik.errors[block.data.stateName].includes('must be a `string` type, but the final value was:') &&
				<p
					id={`input_warning_${block.id}`}
					data-name="warning"
					className={block.data.css['warning']}
				// onDoubleClick={(e) => {
				// 	e.target.contentEditable = true
				// 	e.target.suppressContentEditableWarning = true
				// }}
				// onBlur={async (e) => {
				// 	block.data.labelText = e.target.innerText
				// }}	
				>
					*{formik.errors[block.data.stateName]}
				</p>
			}
		</>
	)
}

// document validation message
const DocumentValidationMessage = ({ block, formik }) => {
	const [message, setMessage] = useState(null);

	useEffect(() => {
		if (Object.keys(formik.touched).length > 0 && formik.touched[block.data.stateName]) {
			uploadValidations();
		}
	}, [formik.touched]);

	const uploadValidations = () => {
		let file_document = null;
		let isMultiple = false;
		let isDocument = false;

		setMessage(null);

		for (const x in formik.values) {
			if (x.includes("_file")) {
				if (formik.values[x].name != undefined) {
					file_document = formik.values[x];
					isMultiple = false;
				} else {
					file_document = [...formik.values[x]];
					isMultiple = true;
				}

				isDocument = true;
			}
		}

		if (isDocument && file_document != null) {

			let maxSize = 0;
			let maxSizeMessage = null;
			let numberOfFiles = 0;
			let numberOfFileMessage = null;
			let requiredMessage = null;

			for (let j = 0; j < block.data?.validations.length; j++) {

				// required message
				if (formik.touched[block.data.stateName] && block.data.validations[j].type == "required" &&
					(formik.values[block.data.stateName] == "" || formik.values[block.data.stateName] == null || formik.values[block.data.stateName] == undefined)
				) {
					requiredMessage = block.data.validations[j].message;
				}

				// maxSize value & message
				if (block.data.validations[j].type == "maxSize") {
					maxSize = block.data.validations[j].value;
					maxSizeMessage = block.data.validations[j].message;
				}

				// numberOfFiles value & message
				if (isMultiple && block.data.validations[j].type == "numberOfFiles") {
					numberOfFiles = block.data.validations[j].value;
					numberOfFileMessage = block.data.validations[j].message;
				}

			}

			// multiple files
			if (isMultiple) {
				// checking file size
				if (maxSize != undefined && maxSize != null && maxSize != 0) {
					for (let i = 0; i < file_document.length; i++) {
						if (file_document[i].size / 1000 > maxSize) {
							setMessage(maxSizeMessage);
							break;
						}
					}
				}

				// checking number of files
				if (numberOfFiles != undefined && numberOfFiles != null && numberOfFiles != 0) {
					if (file_document.length > numberOfFiles) {
						setMessage(numberOfFileMessage);
					}
				}

			} else {

				// checking file size
				if (maxSize != undefined || maxSize != null || maxSize != 0) {
					if (file_document.size / 1000 > maxSize) {
						setMessage(maxSizeMessage);
					} else {
						setMessage(null);
					}
				}
			}

			// required message
			if (requiredMessage != null) {
				setMessage(requiredMessage);
			}

		}
	}

	return (
		<>
			{
				message != '' && message != null &&
				<p
					id={`input_warning_${block.id}`}
					data-name="warning"
					className={block.data.css['warning']}
				// onDoubleClick={(e) => {
				// 	e.target.contentEditable = true
				// 	e.target.suppressContentEditableWarning = true
				// }}
				// onBlur={async (e) => {
				// 	block.data.labelText = e.target.innerText
				// }}
				>
					*{message}
				</p>
			}
		</>
	)
}

// email field - input type email
export const EmailField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)
	const { currentTheme } = useContext(context);
	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => {
				handleInputClick(event, block.id)
			}}
			data-name="form"
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			<input
				id={block.id}
				data-name="input"

				type={block.data.inputType}
				name={block.data.stateName}
				placeholder={block.data.placeholder}

				// required={block.data.required ?? false}
				autoFocus={block.data.autoFocus ?? ''}
				autoComplete={block.data.autoComplete ? 'autoComplete' : ''}
				readOnly={block.data.readOnly ?? false}
				disabled={block.data.disabled ?? false}

				className={`${block?.data.css['input']
					?.replace("secondary_color", `[${currentTheme?.secondary_color}]`)
					?.replace("bg_color", `[${currentTheme?.bg_color}]`)
					?.replace("tertiary_color", `[${currentTheme?.tertiary_color}]`)
					?.replace("primary_color", `[${currentTheme?.primary_color}]`)
					?.replace("primary_font_size", `[${currentTheme?.primary_font_size}]`)
					?.replace("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
					?.replace("font_size", `[${currentTheme?.font_size}]`)
					?.replace("cursor-pointer", "").split(" ")
					.map(property => {
						if (property.trim() != '') {
							if (property.includes('!')) {
								return property;
							} else {
								return `!${property}`;
							}
						}
					})
					.join(" ").trim()}${block.data.disabled ? " cursor-not-allowed bg-gray-200" : ""}`}

				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values[block.data.stateName] ?? block.data.defaultValue ?? ""}
			/>

			{/* validation message */}
			<ValidationMessage block={block} formik={formik} />
		</div>
	);
}

// short text field - input type - text, number
export const ShortTextField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)
	const { currentTheme } = useContext(context);
	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => {
				handleInputClick(event, block.id)
			}}
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			{/* <div className={`flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ${block.data.disabled && "bg-gray-200"}`}> */}
			<input
				id={block.id}
				data-name="input"

				type={block.data.inputType}
				name={block.data.stateName}
				placeholder={block.data.placeholder}

				// required={block.data.required ?? false}
				autoFocus={block.data.autoFocus ?? ''}
				autoComplete={block.data.autoComplete ? 'autoComplete' : ''}
				readOnly={block.data.readOnly ?? false}
				disabled={block.data.disabled ?? false}

				// maxLength={block.data.maxLength ?? 255}
				// minLength={block.data.minLength ?? 0}

				// work with : number, range, date, datetime-local, month, time and week.
				// min={block.data.min}
				// max={block.data.max}

				// works with : email, select and file.
				// multiple={block.data.multiple ?? false}

				// works with : text, date, search, url, tel, email, and password.
				// pattern={block.data.pattern ?? null}

				className={`${block?.data.css['input']
					?.replace("secondary_color", `[${currentTheme?.secondary_color}]`)
					?.replace("bg_color", `[${currentTheme?.bg_color}]`)
					?.replace("tertiary_color", `[${currentTheme?.tertiary_color}]`)
					?.replace("primary_color", `[${currentTheme?.primary_color}]`)
					?.replace("primary_font_size", `[${currentTheme?.primary_font_size}]`)
					?.replace("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
					?.replace("font_size", `[${currentTheme?.font_size}]`)
					?.replace("cursor-pointer", "").split(" ")
					.map(property => {
						if (property.trim() != '') {
							if (property.includes('!')) {
								return property;
							} else {
								return `!${property}`;
							}
						}
					})
					.join(" ").trim()}${block.data.disabled ? " cursor-not-allowed bg-gray-200" : ""}`}

				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values[block.data.stateName] ?? block.data.defaultValue ?? ""}
			/>
			{/* </div> */}

			{/* validation message */}
			<ValidationMessage block={block} formik={formik} />
		</div>
	);
}

// short text field - input type text
export const LongTextField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)
	const { currentTheme } = useContext(context);
	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => {
				handleInputClick(event, block.id)
			}}
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			<textarea
				id={block.id}
				data-name="input"

				name={block.data.stateName}
				placeholder={block.data.placeholder}

				// required={block.data.required ?? false}
				autoFocus={block.data.autoFocus ?? ''}
				autoComplete={block.data.autoComplete ? 'autoComplete' : ''}
				readOnly={block.data.readOnly ?? false}
				disabled={block.data.disabled ?? false}

				rows={block.data.rows ?? 5}

				// maxLength={block.data.maxLength ?? 255}
				// minLength={block.data.minLength ?? 0}

				className={`${block?.data.css['input']
					?.replace("secondary_color", `[${currentTheme?.secondary_color}]`)
					?.replace("bg_color", `[${currentTheme?.bg_color}]`)
					?.replace("tertiary_color", `[${currentTheme?.tertiary_color}]`)
					?.replace("primary_color", `[${currentTheme?.primary_color}]`)
					?.replace("primary_font_size", `[${currentTheme?.primary_font_size}]`)
					?.replace("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
					?.replace("font_size", `[${currentTheme?.font_size}]`)
					?.replace("cursor-pointer", "").split(" ")
					.map(property => {
						if (property.trim() != '') {
							if (property.includes('!')) {
								return property;
							} else {
								return `!${property}`;
							}
						}
					})
					.join(" ").trim()}${block.data.disabled ? " cursor-not-allowed bg-gray-200" : ""}`}

				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values[block.data.stateName] ?? block.data.defaultValue ?? ""}
			/>

			{/* validation message */}
			<ValidationMessage block={block} formik={formik} />
		</div>
	);
}

// select field
export const SelectField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)
	const { currentTheme } = useContext(context);
	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => handleInputClick(event, block.id)}
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			{/* <div className={`flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ${block.data.disabled && "bg-gray-200"}`}> */}
			<select
				id={block.id}
				data-name="input"

				name={block.data.stateName}

				// size={block.data?.size ?? 1}

				autoFocus={block.data.autoFocus ?? ''}
				disabled={block.data.disabled ?? false}

				// multiple={block.data.multiple ?? false}

				className={`${block?.data.css['input']
					?.replace("secondary_color", `[${currentTheme?.secondary_color}]`)
					?.replace("bg_color", `[${currentTheme?.bg_color}]`)
					?.replace("tertiary_color", `[${currentTheme?.tertiary_color}]`)
					?.replace("primary_color", `[${currentTheme?.primary_color}]`)
					?.replace("primary_font_size", `[${currentTheme?.primary_font_size}]`)
					?.replace("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
					?.replace("font_size", `[${currentTheme?.font_size}]`)
					?.replace("cursor-pointer", "").split(" ")
					.map(property => {
						if (property.trim() != '') {
							if (property.includes('!')) {
								return property;
							} else {
								return `!${property}`;
							}
						}
					})
					.join(" ").trim()}${block.data.disabled ? " cursor-not-allowed bg-gray-200" : ""}`}

				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values[block.data.stateName] ?? ""}
			>
				{/* select options */}
				{
					block.data?.emptyOptionValue && <option value="">{block.data.emptyOptionValue}</option>
				}

				{
					block.data.options.map((item, index) => (
						<option
							key={index}
							value={item}
							className='capitalize'
							selected={block.data.defaultValue == item ? true : false}
						>
							{item}
						</option>
					))
				}
			</select>
			{/* </div> */}

			{/* validation message */}
			<ValidationMessage block={block} formik={formik} />
		</div>
	);
}

// password field
export const PasswordField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)
	const { currentTheme } = useContext(context);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => handleInputClick(event, block.id)}
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			{/* <div className={`flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ${block.data.disabled && "bg-gray-200"}`}> */}
			<input
				id={block.id}
				data-name="input"

				type={isPasswordVisible ? "text" : "password"}
				name={block.data.stateName}
				placeholder={block.data.placeholder}

				autoFocus={block.data.autoFocus ?? ''}
				disabled={block.data.disabled ?? false}

				// works with : text, date, search, url, tel, email, and password.
				// pattern={block.data.pattern ?? null}

				className={`${block?.data.css['input']
					?.replace("secondary_color", `[${currentTheme?.secondary_color}]`)
					?.replace("bg_color", `[${currentTheme?.bg_color}]`)
					?.replace("tertiary_color", `[${currentTheme?.tertiary_color}]`)
					?.replace("primary_color", `[${currentTheme?.primary_color}]`)
					?.replace("primary_font_size", `[${currentTheme?.primary_font_size}]`)
					?.replace("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
					?.replace("font_size", `[${currentTheme?.font_size}]`)
					?.replace("cursor-pointer", "").split(" ")
					.map(property => {
						if (property.trim() != '') {
							if (property.includes('!')) {
								return property;
							} else {
								return `!${property}`;
							}
						}
					})
					.join(" ").trim()}${block.data.disabled ? " cursor-not-allowed bg-gray-200" : ""}`}

				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values[block.data.stateName] ?? ""}
			/>

			{
				block.data?.showPassword &&
				<label className={`${block.data.css['help']} flex items-center mb-0 mt-2`} data-name="help">
					<input
						type="checkbox"
						className="mr-2 rounded-md"
						checked={isPasswordVisible}
						onChange={() => setIsPasswordVisible((prevState) => !prevState)}
					/>
					<span className="text-sm text-gray-600">Show password</span>
				</label>
			}
			{/* </div> */}

			{/* validation message */}
			<ValidationMessage block={block} formik={formik} />
		</div>
	);
}

// preview images
const PreviewImage = ({ file, multiple, count }) => {
	const [preview, setPreview] = useState(null);

	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => {
		setPreview(reader.result);
	};

	return (
		<div className="m-2">
			{
				file.type.includes('image') ? <img src={preview} alt="Image Preview" className={`rounded-lg ${multiple ? count == 1 ? 'w-auto lg:w-[500px] h-auto lg:h-[281px]' : 'w-56 h-36' : 'w-auto lg:w-[500px] h-auto lg:h-[281px]'}`} /> :
				file.type.includes('video') ? <video className={`rounded-lg ${multiple ? count == 1 ? 'w-auto lg:w-[500px] h-auto lg:h-[281px]' : 'w-56 h-36' : 'w-auto lg:w-[500px] h-auto lg:h-[281px]'}`} src={preview} controls controlsList="nodownload" /> :
					'Preview not available'
			}
		</div>
	);
};

// upload field
export const UploadField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)
	const { currentTheme } = useContext(context);
	let allowedTypes = "";
	let types = [];
	let imageTypes = [];
	let audioTypes = [];
	let videoTypes = [];
	let fileTypes = [];

	// loop types
	if (block.data?.allowedTypes && block.data?.allowedTypes?.length != 0) {
		for (let i = 0; i < block.data.allowedTypes.length; i++) {

			// loop sub-types
			for (let j = 0; j < block.data.allowedTypes[i].types.length; j++) {
				if (block.data.allowedTypes[i].name === 'images') {
					imageTypes.push(`.${block.data.allowedTypes[i].types[j]}`);
				} else if (block.data.allowedTypes[i].name === 'videos') {
					videoTypes.push(`.${block.data.allowedTypes[i].types[j]}`);
				} else if (block.data.allowedTypes[i].name === 'audios') {
					audioTypes.push(`.${block.data.allowedTypes[i].types[j]}`);
				} else if (block.data.allowedTypes[i].name === 'files') {
					fileTypes.push(`.${block.data.allowedTypes[i].types[j]}`);
				}
			}
		}
	}

	if (imageTypes.length != 0) {
		// Convert the array of options to a string with each element on a new line
		var arrayAsString = imageTypes.join(', ');
		types.push(arrayAsString);
	}

	if (videoTypes.length != 0) {
		var arrayAsString = videoTypes.join(', ');
		types.push(arrayAsString);
	}

	if (audioTypes.length != 0) {
		var arrayAsString = audioTypes.join(', ');
		types.push(arrayAsString);
	}

	if (fileTypes.length != 0) {
		var arrayAsString = fileTypes.join(', ');
		types.push(arrayAsString);
	}

	// Convert the array of options to a string with each element on a new line
	allowedTypes = types.join(', ');

	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => handleInputClick(event, block.id)}
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			{/* <div className={`flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ${block.data.disabled && "bg-gray-200"}`}> */}
			<div data-name="uploadBlock" className={`${block.data.css['uploadBlock']}`}>
				{
					formik?.values?.[block?.data?.stateName] != undefined && formik?.values?.[block?.data?.stateName] != "" && typeof formik?.values?.[block?.data?.stateName] == 'object' ?
						<div class="flex flex-wrap justify-center">
							{
								Array.isArray(formik?.values?.[block?.data?.stateName]) ?
									formik.values[block.data.stateName].map(image =>
										<PreviewImage file={image} multiple={block.data.multiple} count={Object.keys(formik.values[block.data.stateName]).length} />
									)
								:
									<PreviewImage file={formik?.values?.[block?.data?.stateName]} multiple={false} count={1} />
							}
						</div>
					:
						<PhotoIcon className="mx-auto h-20 w-20 text-gray-300" aria-hidden="true" />
				}

				{/* upload btn */}
				<div className="mt-4 w-full flex justify-center leading-6">
					<label htmlFor={block.id} className="relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
						<span>{block.data.uploadText}</span>

						<input
							id={block.id}

							type="file"
							name={block.data.stateName}

							autoFocus={block.data.autoFocus ?? ''}
							disabled={block.data.disabled ?? false}

							multiple={block.data.multiple ?? false}

							accept={allowedTypes}

							className={`${block?.data.css['uploadBlock']
								?.replace("secondary_color", `[${currentTheme?.secondary_color}]`)
								?.replace("bg_color", `[${currentTheme?.bg_color}]`)
								?.replace("tertiary_color", `[${currentTheme?.tertiary_color}]`)
								?.replace("primary_color", `[${currentTheme?.primary_color}]`)
								?.replace("primary_font_size", `[${currentTheme?.primary_font_size}]`)
								?.replace("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
								?.replace("font_size", `[${currentTheme?.font_size}]`)
								?.replace("cursor-pointer", "").split(" ")
								.map(property => {
									if (property.trim() != '') {
										if (property.includes('!')) {
											return property;
										} else {
											return `!${property}`;
										}
									}
								})
								.join(" ").trim()} sr-only${block.data.disabled ? " cursor-not-allowed" : ""}`}

							onChange={(event) => {
								formik.setFieldValue(block.data.stateName, block.data.multiple ? [...event.currentTarget.files] : event.currentTarget.files[0]);
							}}

							onBlur={formik.handleBlur}
							// value={formik.values[block.data.stateName] ?? ""}
						/>
					</label>
					{/* <p className="pl-1">or drag and drop</p> */}
				</div>

				{/* <p className="leading-5">Only JPEG, PNG files with max size of {block.data.maxSize} Kb</p> */}
			</div>

			{/* </div> */}

			{/* validation message */}
			<DocumentValidationMessage block={block} formik={formik} />
		</div>
	);
}

// radio field
export const RadioField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)

	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => {
				handleInputClick(event, block.id)
			}}
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			<div className='flex flex-col space-y-2'>
				{block.data.options.map((item, index) =>
					<div
						className={`${block.data.css['input']} flex items-center`}
						key={index}
						data-name="input"
					>
						<Field
							id={`${block.id}_${index}`}

							type="radio"
							name={block.data.stateName}
							placeholder={block.data.placeholder}

							// autoFocus={block.data.autoFocus ?? ''}
							disabled={block.data.disabled == true ? true : block.data.readOnly == true ? true : false}

							className={`${(block.data.disabled || block.data.readOnly) ? "cursor-not-allowed bg-gray-300" : ""}`}

							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={item}
							checked={block.data.defaultValue == item || formik.values[block.data.stateName] === item}
						/>

						<label htmlFor={`${block.id}_${index}`} className='ms-3 text-sm'>{item}</label>
					</div>
				)}

				{
					block.data.otherOptionValue != "" &&
					<>
						<div
							className={`${block.data.css['input']} flex items-center`}
							data-name="input"
						>
							<Field
								id={`${block.id}_other`}

								type="radio"
								name={block.data.stateName}
								placeholder={block.data.placeholder}

								// autoFocus={block.data.autoFocus ?? ''}
								disabled={block.data.disabled == true ? true : block.data.readOnly == true ? true : false}

								className={`${(block.data.disabled || block.data.readOnly) ? "cursor-not-allowed bg-gray-300" : ""}`}

								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={block.data.otherOptionValue}
								checked={block.data.defaultValue == block.data.otherOptionValue || formik.values[block.data.stateName] === block.data.otherOptionValue}
							/>

							<label htmlFor={`${block.id}_other`} className='ms-3 text-sm'>{block.data.otherOptionValue}</label>
						</div>

						{/* Other input
						{
							formik.values[block.data.stateName] === block.data.otherOptionValue &&
							<input
								type="text"
								value={otherOptionValue}
								onChange={(e) => setOtherOptionValue(e.target.value)}
								className={`${block.data.css['input']} py-1.5 px-2.5 rounded-md bg-white text-black border-gray-600`}
							/>
						}
						*/}
					</>
				}
			</div>

			{/* validation message */}
			<ValidationMessage block={block} formik={formik} />
		</div>
	);
}

// check box field
export const CheckboxField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)

	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => {
				handleInputClick(event, block.id)
			}}
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			<div className='flex flex-col space-y-2'>
				{block.data.options.map((item, index) =>
					<div
						className={`${block.data.css['input']} flex items-center`}
						key={index}
						data-name="input"
					>
						<Field
							id={`${block.id}_${index}`}

							type="checkbox"
							name={block.data.stateName}
							placeholder={block.data.placeholder}

							// autoFocus={block.data.autoFocus ?? ''}
							disabled={block.data.disabled == true ? true : block.data.readOnly == true ? true : false}

							className={`${(block.data.disabled || block.data.readOnly) ? "cursor-not-allowed bg-gray-300" : ""}`}

							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={item}

							checked={block.data.defaultValue == item || (formik.values[block.data.stateName]?.length > 0 && formik.values[block.data.stateName].includes(item))}
						/>

						<label htmlFor={`${block.id}_${index}`} className='ms-3 text-sm'>{item}</label>
					</div>
				)}

				{
					block.data.otherOptionValue != "" &&
					<>
						<div
							className={`${block.data.css['input']} flex items-center`}
							data-name="input"
						>
							<Field
								id={`${block.id}_other`}

								type="checkbox"
								name={block.data.stateName}
								placeholder={block.data.placeholder}

								// autoFocus={block.data.autoFocus ?? ''}
								disabled={block.data.disabled == true ? true : block.data.readOnly == true ? true : false}

								className={`${(block.data.disabled || block.data.readOnly) ? "cursor-not-allowed bg-gray-300" : ""}`}

								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={block.data.otherOptionValue}
								checked={block.data.defaultValue == block.data.otherOptionValue || (formik.values[block.data.stateName]?.length > 0 && formik.values[block.data.stateName].includes(block.data.otherOptionValue))}
							/>

							<label htmlFor={`${block.id}_other`} className='ms-3 text-sm'>{block.data.otherOptionValue}</label>
						</div>

						{/* Other input
						{
							formik.values[block.data.stateName] === block.data.otherOptionValue &&
							<input
								type="text"
								value={otherOptionValue}
								onChange={(e) => setOtherOptionValue(e.target.value)}
								className={`${block.data.css['input']} py-1.5 px-2.5 rounded-md bg-white text-black border-gray-600`}
							/>
						}
						*/}
					</>
				}
			</div>

			{/* validation message */}
			<ValidationMessage block={block} formik={formik} />
		</div>
	);
}

// date time field
export const DateTimeField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)
	const { currentTheme } = useContext(context);
	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => {
				handleInputClick(event, block.id)
			}}
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			<input
				id={block.id}
				data-name="input"

				type="datetime-local"
				name={block.data.stateName}

				// required={block.data.required ?? false}
				autoFocus={block.data.autoFocus ?? ''}
				readOnly={block.data.readOnly ?? false}
				disabled={block.data.disabled ?? false}

				/*
					default value : YYYY-MM-DDThh:mm
					
					to apply range, set value to min and max both
					to disable past days, set value to min only
					to disable future days, set value to max only
				*/
				min={block.data.minValue == "current" ? moment().format('YYYY-MM-DDThh:mm') : block.data.minValue}
				max={block.data.maxValue == "current" ? moment().format('YYYY-MM-DDThh:mm') : block.data.maxValue}

				// display seconds field
				step={block.data.step ?? 0}

				// pattern="\d{4}-\d{2}-\d{2}"
				// placeholder="dd-mm-yyyy"

				className={`${block?.data.css['input']
					?.replace("secondary_color", `[${currentTheme?.secondary_color}]`)
					?.replace("bg_color", `[${currentTheme?.bg_color}]`)
					?.replace("tertiary_color", `[${currentTheme?.tertiary_color}]`)
					?.replace("primary_color", `[${currentTheme?.primary_color}]`)
					?.replace("primary_font_size", `[${currentTheme?.primary_font_size}]`)
					?.replace("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
					?.replace("font_size", `[${currentTheme?.font_size}]`)
					?.replace("cursor-pointer", "").split(" ")
					.map(property => {
						if (property.trim() != '') {
							if (property.includes('!')) {
								return property;
							} else {
								return `!${property}`;
							}
						}
					})
					.join(" ").trim()}${block.data.disabled ? " cursor-not-allowed bg-gray-200" : ""}`}

				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values[block.data.stateName] ?? block.data.defaultValue ?? ""}
			/>

			{/* validation message */}
			<ValidationMessage block={block} formik={formik} />
		</div>
	);
}

// date field
export const DateField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)
	const { currentTheme } = useContext(context);
	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => handleInputClick(event, block.id)}
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			<input
				id={block.id}
				data-name="input"

				type="date"
				name={block.data.stateName}

				// required={block.data.required ?? false}
				autoFocus={block.data.autoFocus ?? ''}
				readOnly={block.data.readOnly ?? false}
				disabled={block.data.disabled ?? false}

				/*
					default value : YYYY-MM-DD
					
					to apply range, set value to min and max both
					to disable past days, set value to min only
					to disable future days, set value to max only
				*/
				min={block.data.minValue == "current" ? moment().format('YYYY-MM-DD') : block.data.minValue}
				max={block.data.maxValue == "current" ? moment().format('YYYY-MM-DD') : block.data.maxValue}

				// to disable days of a week, set value to step as 1, 2, ... 7
				step={block.data.step ?? 1}

				// pattern="\d{4}-\d{2}-\d{2}"
				// placeholder="dd-mm-yyyy"

				className={`${block?.data.css['input']
					?.replace("secondary_color", `[${currentTheme?.secondary_color}]`)
					?.replace("bg_color", `[${currentTheme?.bg_color}]`)
					?.replace("tertiary_color", `[${currentTheme?.tertiary_color}]`)
					?.replace("primary_color", `[${currentTheme?.primary_color}]`)
					?.replace("primary_font_size", `[${currentTheme?.primary_font_size}]`)
					?.replace("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
					?.replace("font_size", `[${currentTheme?.font_size}]`)
					?.replace("cursor-pointer", "").split(" ")
					.map(property => {
						if (property.trim() != '') {
							if (property.includes('!')) {
								return property;
							} else {
								return `!${property}`;
							}
						}
					})
					.join(" ").trim()}${block.data.disabled ? " cursor-not-allowed bg-gray-200" : ""}`}

				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values[block.data.stateName] ?? block.data.defaultValue ?? ""}
			/>

			{/* validation message */}
			<ValidationMessage block={block} formik={formik} />
		</div>
	);
}

// time field
export const TimeField = ({ block, formik, handleInputClick }) => {
	// console.log('formik', formik)
	const { currentTheme } = useContext(context);
	return (
		<div
			id={`input_div_${block.id}`}
			className="sm:col-span-4"
			onClick={(event) => {
				handleInputClick(event, block.id)
			}}
		>
			{/* label */}
			<Label block={block} />

			{/* help text or description */}
			<HelpText block={block} />

			{/* input field */}
			<input
				id={block.id}
				data-name="input"

				type="time"
				name={block.data.stateName}
				placeholder={block.data.placeholder}

				// required={block.data.required ?? false}
				autoFocus={block.data.autoFocus ?? ''}
				readOnly={block.data.readOnly ?? false}
				disabled={block.data.disabled ?? false}

				// default value : hh:mm
				min={block.data.minValue}
				max={block.data.maxValue}

				// to display seconds column, set it to < 60 or > 60
				step={block.data.step}

				// works with : text, date, search, url, tel, email, and password.
				// pattern={block.data.pattern ?? null}

				className={`${block?.data.css['input']
					?.replace("secondary_color", `[${currentTheme?.secondary_color}]`)
					?.replace("bg_color", `[${currentTheme?.bg_color}]`)
					?.replace("tertiary_color", `[${currentTheme?.tertiary_color}]`)
					?.replace("primary_color", `[${currentTheme?.primary_color}]`)
					?.replace("primary_font_size", `[${currentTheme?.primary_font_size}]`)
					?.replace("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
					?.replace("font_size", `[${currentTheme?.font_size}]`)
					?.replace("cursor-pointer", "").split(" ")
					.map(property => {
						if (property.trim() != '') {
							if (property.includes('!')) {
								return property;
							} else {
								return `!${property}`;
							}
						}
					})
					.join(" ").trim()}${block.data.disabled ? " cursor-not-allowed bg-gray-200" : ""}`}

				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values[block.data.stateName] ?? block.data.defaultValue ?? ""}
			/>

			{/* validation message */}
			<ValidationMessage block={block} formik={formik} />
		</div>
	);
}