import { BlockWrapper } from '../Sortable';
import { createElement, useContext } from 'react';
import { generateCss } from '../functions/sortableFunctions';
import { context } from '../../../../context/Context';
import { useLocation, useParams } from 'react-router-dom';
const Input = ({ block, ...props }) => {
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (
		<>
			{
				createElement(
					block.type,
					{
						id: block?.id,
						style: {
							fontFamily: block?.style?.fontFamily
						},
						className: generateCss({
							contextVariables: contextVariables,
							props: {
								...props,
								block: block
							},
							params: params,
							location: location
						}),
						...block.data,
						autoComplete: "off",
						onClick: (e) => {
							props.onClick(e, block.id);
							if (props.customComponentChildren) {
								props.handleComponentWithSettingsClick();
							}
						},
						onMouseOverCapture: (e) => { props.onMouseOverCapture(e) },
						onMouseOut: (e) => props.onMouseOut(e),
						onDrag: (e) => props.onDrag(e),
						onDragOver: (e) => props.onDragOver(e),
						onDragStart: (e) => props.onDragStart(e),
						onDrop: (e) => props.onDrop(e),

					},
					block.children ?
						block.children?.map((value, index) => {

							return <BlockWrapper {...props} block={value} key={index} />;
						})
						:
						null
				)
			}
		</>
	)
}
export default Input;