import React, { useState } from 'react'
import { Walktour } from 'walktour'

export default function WalkThrough() {
	const [currentStepInde, setCurrentStepIndex] = useState();
	return (
		<>
			<Walktour
				steps={[
					{ selector: "#afaf", title: "asda", description: "as" },
					{ selector: "#step-one", title: "Pages", description: "One foot in front of the other" },
					{ selector: "#step-two", title: "Elements & Sections", description: "One foot in front of the other" },
					{ selector: "#step-three", title: "Thems", description: "One foot in front of the other" },
					{ selector: "#step-four", title: "Forms", description: "One foot in front of the other" },
					{ selector: "#step-five", title: "Media", description: "One foot in front of the other" },
				]}
				initialStepIndex={0}

				customTooltipRenderer={(logic) => <Tooltip logic={logic} />}
			/>
		</>
	)
}


const Tooltip = ({ logic }) => {
	const [title, setTitle] = useState("")
	const [isLast, setIsLast] = useState(false)
	const [isPrev, setIsPrev] = useState(false)
	return (
		<>
			{
				title?.length > 0 ?
					<div className='bg-white p-2 shadow-lg rounded-md'>
						<p className='text-xl text-gray-500'>{title}</p>
						<p className='tetx-gray-700'>This is the description here</p>
						<div className={`mt-4 flex items-center ${!isPrev ? "justify-end" : "justify-between"}`}>
							{
								isPrev &&
								<button
									type="button"
									className='px-2 py-1 bg-indigo-500 text-white rounded-lg font-medium'
									onClick={() => {
										let obj = logic?.allSteps?.findIndex(o => o.selector === logic?.stepContent?.selector);
										setTitle(logic?.allSteps[obj - 1]?.title)
										// setTitle(logic?.stepContent?.title)
										console.log(obj)
										if (obj - 1 == 1) {
											setIsPrev(false)
											setIsLast(false)
										}
										logic.prev()
									}}>Prev</button>
							}
							<button
								type="button"
								className='px-2 py-1 bg-indigo-500 text-white rounded-lg ml-2 font-medium'
								onClick={() => {
									if (!isLast) {
										// console.log(logic)
										setIsPrev(true)
										let obj = logic?.allSteps?.findIndex(o => o.selector === logic?.stepContent?.selector);
										setTitle(logic?.allSteps[obj + 1]?.title)
										logic.next()
										console.log(logic?.allSteps.length, obj)
										if (logic?.allSteps.length == obj + 2) {
											setIsLast(true)
										}
									}
								}}>{isLast ? "Done" : "next"}</button>
						</div>
					</div>
					:
					<div className='bg-white p-2 shadow-lg rounded-md'>
						<p>Start Tutorial</p>
						<p>in 2 minultes you will learn how to use qafto</p>
						<div className='flex items-center justify-end mt-3 border-0 outline-none'>
							<button
								type="button"
								className='px-2 py-1 bg-green-500 text-white rounded-lg ml-2 font-medium'
								onClick={() => {
									if (!isLast) {
										// console.log(logic)
										setIsPrev(true)
										let obj = logic?.allSteps?.findIndex(o => o.selector === logic?.stepContent?.selector);
										setTitle(logic?.allSteps[obj + 1]?.title)
										logic.next()
										console.log(logic?.allSteps.length, obj)
										if (logic?.allSteps.length == obj + 2) {
											setIsLast(true)
										}
									}
								}}>Start Now</button>
						</div>
					</div>
			}
		</>
	)
}