import { BlockWrapper } from "../Sortable";
import React, { useContext } from "react";
import { context } from "../../../../context/Context";
import { useParams, useLocation } from "react-router-dom";
import { generateContent, generateCss } from "../functions/sortableFunctions";
const AComponent = ({ block, handleClick, handleMouseOver,...props }) => {
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (
		<>
			{
				React.createElement(
					'span',
					{
						style: {
							fontFamily: block?.style?.fontFamily
						},
						className: generateCss({
							contextVariables: contextVariables,
							props: {
								...props,
								block: block
							},
							params: params,
							location: location
						}),
						onClick: (e) => {
							handleClick(e, block.id);
						},
						onMouseEnter: (e) => handleMouseOver(e, block.id),
						id: block.id,
						...block.data,
						href: null,
						to: null
					},
					block.children ?
						block.children.map((value, index) => {
							return <BlockWrapper
								{...props}
								key={index}
								block={value}
							/>
						})
						:
						generateContent({
							contextVariables: contextVariables,
							props: {
								...props,
								block: block
							},
							params: params,
							location: location
						})
				)
			}
		</>
	)
}
export default AComponent;