import _ from "lodash";

// import { newFormElementSchema } from "../layouts/editor";
import { randomString } from '../libraries/utility';
import { AddToUndo } from "../screens/account/editor";
import { findFormElements, getFormElementsOnly } from "../screens/account/editor/form/FormComponents";
import { FormElementTypes } from "../screens/account/editor/form/FormikComponent";
import { addEvent, deleteEvent, editEvent } from "./eventsFunctions";

export const dataschema = {
	"id": "m89zm2",
	"type": "custom",
	"element": "Product",
	"style": {
		"top": 184,
		"left": 121,
		"width": 158,
		"height": 138,
		"locked": false,
		"rotation": 0,
		"fontFamily": "Raleway",
		"fontSize": "10px",
		"fontStretch": "",
		"fontStyle": "italic",
		"variant": "",
		"fontWeight": "",
		"lineHeight": "",
		"textDecoration": "",
		"border": "",
		"color": "",
		"background": "",
		"borderTop": 0,
		"borderBottom": 0,
		"borderLeft": 0,
		"borderRight": 0,
		"radius": 0,
		"marginTop": 0,
		"marginBottom": 0,
		"marginLeft": 0,
		"marginRight": 0,
		"paddingTop": 0,
		"paddingBottom": 0,
		"paddingLeft": 0,
		"paddingRight": 0,
		"display": "flex",
		"animation": "ping"
	},
	"data": {
		"url": "http://strapi.com/product"
	},
	"events": {
		"onClick": {},
		"onHover": {},
		"onDbClick": {}
	},
	"childrens": [
		{

			"id": "m89zm1",
			"type": "custom",
			"element": "Product",
			"style": {
				"top": 184,
				"left": 121,
				"width": 158,
				"height": 138,
				"locked": false,
				"rotation": 0,
				"fontFamily": "Raleway",
				"fontSize": "10px",
				"fontStretch": "",
				"fontStyle": "italic",
				"variant": "",
				"fontWeight": "",
				"lineHeight": "",
				"textDecoration": "",
				"border": "",
				"color": "",
				"background": "",
				"borderTop": 0,
				"borderBottom": 0,
				"borderLeft": 0,
				"borderRight": 0,
				"radius": 0,
				"marginTop": 0,
				"marginBottom": 0,
				"marginLeft": 0,
				"marginRight": 0,
				"paddingTop": 0,
				"paddingBottom": 0,
				"paddingLeft": 0,
				"paddingRight": 0,
				"display": "flex",
				"animation": "ping"
			},
			"data": {
				"url": "http://strapi.com/product"
			},
			"events": {
				"onClick": {},
				"onHover": {},
				"onDbClick": {}
			},
			"childrens": null
		}
	]

};
export const updateSchema = (id, schema, data) => {
	// console.log(schema)
	if (schema?.id == id) {
		schema.style[`${data[0]}`] = data[1]
		return schema
	}
	else if (schema?.childrens) {
		for (let i = 0; i < schema?.childrens.length; i++) {
			updateSchema(id, schema.childrens[i], data)
		}
	}
}
// export const updateElementSchema = (id, schema, data) => {
// 	if (schema?.element?.id == id) {
// 		!schema.children && (schema.children = []);

// 		schema.children.push(data)
// 		return schema
// 	}


// 	if (schema?.children?.length > 0) {
// 		for (let i = 0; i < schema?.children?.length; i++) {
// 			updateElementSchema(id, schema?.children[i], data)
// 		}
// 	}
// }
export const deleteElementSchema = (id, schema, data, i) => {
	if (id == schema?.element?.id) {
		// console.log(data)
		data.splice(i, 1)
		return schema
	}

	if (schema?.children?.length > 0) {
		for (let i = 0; i < schema?.children?.length; i++) {
			deleteElementSchema(id, schema?.children[i], schema.children, i)
		}
	}
}


const cleanExtraObject = (state) => {
	for (let i = 0; i < state?.length; i++) {
		if (state[i]?.children?.length == 0) {
			state.splice(i, state[i])
		}
	}
}
function deleteObjectById(arr, id) {
	for (let i = 0; i < arr?.length; i++) {
		const obj = arr[i];
		if (obj.id == id) {
			// If the object is found, remove it from the array
			arr.splice(i, 1);
			return true;
		} else if (obj.children && obj?.children?.length > 0) {
			deleteObjectById(obj.children, id);
		}
	}
	return false;
}

function duplicateObjectById(arr, id, element) {
	for (let i = 0; i < arr?.length; i++) {
		const obj = arr[i];
		if (obj.id == id) {
			arr.splice(i + 1, 0, element);
		} else if (obj.children && obj?.children?.length > 0) {
			duplicateObjectById(obj.children, id, element);
		}
	}
}

// export const updateElementSchema = (id, schema, data, element) => {
export const updateElementSchema = async (arr, id, element, type) => {
	for (let i = 0; i < arr?.length; i++) {
		let obj = arr[i];

		if (obj.id == id) {
			// console.log(arr[i - 1], obj, "THIS IS OBJ", element);

			if (type == "CHANGEBLOCK") {

				console.log(element, "ADDELEMENTCENTER")
				obj = element;
				return true

			} else if (type == "ADDELEMENT") {

				// console.log(arr,i+1,element,"ADDELEMENTCENTER")
				arr.splice(i + 1, 0, element);
				return true

			}
			else if (type == "ADDELEMENTCENTER" && obj?.children) {
				// obj?.children?.splice(obj.length - 1, 0, element);
				obj?.children?.push(element);

			}
			if (type == "ADDELEMENTTOP") {
				arr.splice(i, 0, element);

			}
			if (type == "REPLACEELEMENT") {
				arr.splice(i, 1, element);

			} else if (type == "UPDATE_CONTENT") {
				obj.content = element;

			} else if (type == "UPDATE_CUSTOM_COMPONENT") {
				obj.data.name = element;

			} else if (type == "UPDATE_MEDIA") {
				obj.data.src = element;

			} else if (type == "UPDATE_CONTENT" && arr[i - 1]?.events?.onChange?.task == "Query") {
				arr[i - 1].events.onChange.parameters.query = element

			} else if (type == "UPDATE_ICON") {

				let filteredNewCSS = element.css.replace(" text-3xl", "");			// remove text-3xl from new element css to add

				if (obj?.css !== undefined) {
					if (obj.css.includes('mdi mdi-')) {			// material community icons
						// it will replace mdi mdi-[name] & any extra white space in it
						let cleanedCSS = obj?.css.replace(/\bmdi(-\w+)?\s*/g, '').replace(/\s+/g, ' ').trim();

						obj.css = cleanedCSS + ' ' + filteredNewCSS;

					} else if (obj.css.includes('ti ti-')) {	// tabler icons
						// it will replace ti ti-[name] & any extra white space in it
						let cleanedCSS = obj?.css.replace(/\bti(-\w+)?\s*/g, '').replace(/\s+/g, ' ').trim();

						obj.css = cleanedCSS + ' ' + filteredNewCSS;

					} else if (obj.css.includes('material-symbols-outlined')) {		// google material symbols
						obj.content = element.name;
					}
				}

			} else if (type == "UPDATE_CSS") {
				obj["css"] = element;

				obj["style"] = obj["style"] || {};
				obj["style"]["display"] = obj["style"]["display"] || "";
				obj["style"]["display"] = obj?.["style"]?.["display"] == "hidden" ? "block" : "hidden"

			} else if (type == "UPDATE_STATIC_DATA") {
				const static_data = obj["data"]["staticData"][0]
				const static_data_length = obj["data"]["staticData"].length
				let calculate_length = 0;
				obj["data"]["limit"] = element
				if (static_data_length > element) {
					calculate_length = static_data_length - element
					obj["data"]["limit"] = element
					// const data = await obj["data"]["staticData"].splice(0,element)
					// obj["data"]["staticData"] = data
					// console.log(data)

					return true;
				} else {
					calculate_length = element - static_data_length
				}
				console.log(calculate_length, element, "calculate_length")
				for (let i = 0; i < calculate_length; i++) {
					obj["data"]["staticData"].push(static_data)
				}

			} else if (type == "UPDATE_IS_DYNAMIC") {
				obj["dynamic"] = element

			} else if (type == "UPDATE_IS_DYNAMIC_DATA") {
				obj["data"]["dynamicData"] = element

			} else if (type == "UPDATE_STATIC_DATA_ARRAY") {
				console.log(element)
				obj["data"]["staticData"] = [...element]

			} else if (type == "UPDATE_QUERY") {
				console.log(obj["query"], element, "element here1")
				delete obj["data"]["query"][`${element[2]}`]
				obj["data"]["query"][`${element[0]}`] = element[1]
				console.log(obj, element, "element here")

			} else if (type == "UPDATE_BACKGROUND_MEDIA") {
				const index = obj?.css?.indexOf("bg-[url")
				let lastIndex = -1
				let str = " bg-[url("

				for (let i = index; i < obj?.css?.length; i++) {
					if (obj?.css[i] == "]") {
						lastIndex = i
						let bgStr = obj.css.slice(index, lastIndex + 1)
						console.log(bgStr)
						const newCss = obj.css.replace(bgStr, "")
						str += "'" + element + "')]" + newCss
						break;
					}
				}
				console.log(index, obj, "UPDATE_BACKGROUND_MEDIA")

				obj["data"]["src"] = element
				obj["css"] = str
			}
			else if (type == "CHANGE_ELEMENT_SCHEMA") {
				// obj = {}
				// obj["id"] = await element.id;
				obj["data"] = { ...obj["data"], ...element };
				console.log(obj, element, "CHANGE_ELEMENT_SCHEMA")
				// return
			}

			return true;

		} else if (obj.children && obj?.children?.length > 0) {
			updateElementSchema(obj.children, id, element, type);
		}
	}

	return false;
}

export const updateContent = ({ schema, id, value, selectedSiteData, currentLanguage, setContent }) => {
	try {
		for (let i = 0; i < schema.length; i++) {
			if (schema[i].id == id) {
				if (selectedSiteData.multiLanguageEnabled) {
					if (currentLanguage.code == selectedSiteData.templateLanguage.code) {
						schema[i].content = value;
						break;
					} else {

						setContent((prev) => {
							if (prev) {
								if (prev[currentLanguage.code]) {
									return {
										...prev,
										[currentLanguage.code]: {
											...prev[currentLanguage.code],
											[id]: value
										}
									};
								} else {
									return {
										...prev,
										[currentLanguage.code]: {
											[id]: value
										}
									}
								}
							} else {
								return {
									[currentLanguage.code]: {
										[id]: value
									}
								}
							}

						})
					}
				} else {
					schema[i].content = value;
					break;
				}
			} else if (schema[i].children) {
				updateContent({ schema: schema[i].children, id, value, selectedSiteData, currentLanguage, setContent })
			}
		}
	} catch (error) {
		console.log(error);
	}
}
// START - form functions


// add new form
const addFormSchema = (state, id, data) => {
	// console.log('schema 0 ', id, state, data)

	let schemaElementIndex = null;

	if (id == state.length - 1) {
		schemaElementIndex = state.length - 1;
	} else {
		schemaElementIndex = id + 1;
	}

	state.splice(schemaElementIndex, 0, data);
}

// add new form element in schemas
const add_new_form_element = (state, formId, id, add_form_element_section) => {
	// ui schema loop
	for (let i = 0; i < state.length; i++) {
		let updated_formik_state = {};

		if (state[i].id === formId) {
			let formElements = findFormElements(state[i]?.children);

			// update formik_state
			updated_formik_state = { ...state[i]?.data.formik_state, [add_form_element_section?.data.stateName]: add_form_element_section?.data.defaultValue ? add_form_element_section?.data.defaultValue : "" }

			state[i].data.formik_state = updated_formik_state;

			// console.log('updated_formik_state', updated_formik_state, state, updatedState)

			// added new form element in ui schema
			formElements?.splice(id, 0, add_form_element_section);

			break;

		} else if (state[i]?.children) {
			add_new_form_element(state[i].children, formId, id, add_form_element_section);
		}
	}
}

const find_nested_form_children = (formElements, statesToUpdate) => {

	for (let j = 0; j < formElements?.length; j++) {
		if (FormElementTypes.includes(formElements[j].type)) {

			let completeState = null;

			const originalState = formElements[j].data.stateName;
			const halfState = originalState.split('_')[0];

			if (formElements[j].type == 'UploadField' || formElements[j].type == 'UploadVideoField') {
				completeState = `${halfState}_${randomString()}_file`;

			} else {
				completeState = `${halfState}_${randomString()}`;
			}

			// update stateName
			formElements[j].data.stateName = completeState;
			statesToUpdate.push({ oldAttribute: originalState, newAttribute: completeState });

		} else if (formElements[j]?.children) {
			find_nested_form_children(formElements[j].children, statesToUpdate);
		}
	}
}

// duplicate form schema in backend when duplicating ui schema
const find_forms = async (block, form_id, strapiDispatch, uiSchema, undo, setUndo, setRedo) => {
	for (let j = 0; j < block.length; j++) {

		if (block[j].type == "FormikComponent" && block[j]?.data?.form_id == form_id) {

			let formikChildrens = block[j];

			const newForm_id = randomString();
			const newForm_name = `${formikChildrens.data.form_name}-copy-${randomString()}`;

			let formElements = findFormElements(formikChildrens?.children);

			// get all updated states to store it in formik_state and to add update in strapi schema
			let statesToUpdate = [];

			// empty formik_state
			formikChildrens.data.formik_state = {};

			// update stateName in ui schema of duplicated element
			find_nested_form_children(formElements, statesToUpdate);

			// add form_id, form_name & new state in formik_state
			formikChildrens.data.form_id = newForm_id;
			formikChildrens.data.form_name = newForm_name;

			for (let k = 0; k < statesToUpdate.length; k++) {
				formikChildrens.data.formik_state = { ...formikChildrens.data.formik_state, [statesToUpdate[k].newAttribute]: "" };
			}

			// duplicate strapi attribute schema
			await strapiDispatch({
				type: "DUPLICATE_STRAPI_FORM",
				originalForm_id: form_id,
				newForm_id,
				newForm_name,
				statesToUpdate,
				uiSchema,
				undo,
				setUndo,
				setRedo
			});

			break;
		} else if (block[j]?.children) {
			find_forms(block[j]?.children, form_id, strapiDispatch, uiSchema, undo, setUndo, setRedo);
		}
	}
}

// chenge formik state in ui & strapi of a particular form
const duplicate_form = async (state, originalForm_id, duplicateID, strapiDispatch, uiSchema, undo, setUndo, setRedo) => {
	// form is already duplicated by using duplicateObjectById, just update stateName of its elements, update state in formik_state
	// & add new strapi schema

	// ui schema loop
	for (let i = 0; i < state.length; i++) {
		// find form
		if (state[i].id === duplicateID) {

			let backend_form_ids = [];
			if (Array.isArray(originalForm_id)) {
				backend_form_ids = originalForm_id;
			} else {
				backend_form_ids = [originalForm_id];
			}
			// console.log('backend_form_ids', backend_form_ids)

			for (let k = 0; k < backend_form_ids.length; k++) {
				// duplicate form schema in backend when duplicating ui schema
				find_forms(state[i]?.children, backend_form_ids[k], strapiDispatch, uiSchema, undo, setUndo, setRedo);
			}

			break;

		} else if (state[i]?.children) {
			await duplicate_form(state[i].children, originalForm_id, duplicateID, strapiDispatch, uiSchema, undo, setUndo, setRedo);
		}
	}
}

// loop form elements i.e. extra divs inside FormikComponent
const update_form_ui_schema = (state, block, duplicateID) => {
	let completeState = null, originalState = null;

	for (let j = 0; j < block?.length; j++) {

		if (block[j].id == duplicateID) {
			originalState = block[j].data.stateName;
			const halfState = originalState.split('_')[0];

			if (block[j].type == 'UploadField' || block[j].type == 'UploadVideoField') {
				completeState = `${halfState}_${randomString()}_file`;

			} else {
				completeState = `${halfState}_${randomString()}`;
			}

			// update stateName
			block[j].data.stateName = completeState;

			// add state in formik_state
			state.data.formik_state = { ...state.data.formik_state, [completeState]: "" };

			return { "form_state": state, "completeState": completeState, "originalState": originalState };

		} else if (block[j]?.children) {
			const updated_data = update_form_ui_schema(state, block[j].children, duplicateID)

			if (updated_data != null && updated_data != undefined) {
				return updated_data;
			}
		}
	}
}

// chenge formik state in ui & strapi of a particular form element
const duplicate_form_element = (state, formId, duplicateID, strapiDispatch, uiSchema, undo, setUndo, setRedo) => {
	// form element is already duplicated by using duplicateObjectById, just change its stateName, add new state in formik_state
	// & add new state attribute in strapi schema

	// ui schema loop
	for (let i = 0; i < state.length; i++) {
		// find form
		if (state[i].id === formId) {

			let formElements = findFormElements(state[i]?.children);

			const state_data = update_form_ui_schema(state[i], formElements, duplicateID);
			// console.log('inside- completeState', state_data);

			// duplicate strapi attribute schema
			strapiDispatch({
				type: "DUPLICATE_STRAPI_ATTRIBUTE",
				form_id: state_data?.form_state.data.form_id,
				fieldName: state_data?.originalState,
				newFieldName: state_data?.completeState,
				uiSchema,
				undo,
				setUndo,
				setRedo
			});

			break;

		} else if (state[i]?.children) {
			duplicate_form_element(state[i].children, formId, duplicateID, strapiDispatch, uiSchema, undo, setUndo, setRedo);
		}
	}
}

// remove formik state from ui schema
const remove_formik_state = (state, formId, formElementId) => {

	// ui schema loop
	for (let i = 0; i < state.length; i++) {
		// find form
		if (state[i].id === formId) {

			let formElements = [];
			getFormElementsOnly(state[i]?.children, formElements);

			// get state name
			let stateName = null;
			for (let j = 0; j < formElements.length; j++) {
				if (formElements[j].id == formElementId) {
					stateName = formElements[j].data.stateName;
					break;
				}
			}
			// console.log('state[i].data.formik_state.stateName', state[i], state[i].data.formik_state, stateName)

			// remove state from formik_state schema
			for (let x in state[i].data.formik_state) {
				if (x == stateName) {
					delete state[i].data.formik_state[x];
					break;
				}
			}

			break;

		} else if (state[i]?.children) {
			remove_formik_state(state[i].children, formId, formElementId);
		}
	}
}

// update selected form name in schemas
const update_form_name = (state, formId, formName, strapiDispatch, sqliteDispatch, isStatic, uiSchema, undo, setUndo, setRedo) => {
	for (let i = 0; i < state.length; i++) {
		// loop to the right form
		if (state[i].id == formId) {

			let collection_name = formName.toLowerCase().replace(/-/g, "");

			// updating ui schema
			const updatedData = { ...state[i].data, form_name: collection_name, display_name: formName };
			state[i].data = updatedData;

			let form_id = state[i].data.form_id;

			if (isStatic) {			// update sqlite schema
				sqliteDispatch({
					type: "UPDATE_SQLITE_FORM_NAME",
					form_id,
					formName,
					uiSchema,
					undo,
					setUndo,
					setRedo
				})
			} else { 				// updating strapi schema
				strapiDispatch({
					type: "UPDATE_STRAPI_FORM_NAME",
					form_id,
					formName,
					uiSchema,
					undo,
					setUndo,
					setRedo
				})
			}

			break;

		} else if (state[i]?.children) {
			update_form_name(state[i]?.children, formId, formName, strapiDispatch, sqliteDispatch, isStatic, uiSchema, undo, setUndo, setRedo);
		}
	}
}

// update form attributes
const update_form_attributes = (state, formId, name, value) => {
	for (let i = 0; i < state.length; i++) {
		// loop to the right form
		if (state[i].id == formId) {

			const updatedData = { ...state[i].data, [name]: value };
			state[i].data = updatedData;

			break;

		} else if (state[i]?.children) {
			update_form_attributes(state[i]?.children, formId, name, value);
		}
	}
}

// update selected form element data in schemas
const update_form_element_data = (state, formId, selectedFormElementId, name, value, strapiDispatch, isStatic, undo, setUndo, setRedo) => {
	// console.log('updatedData data ', state, formId, selectedFormElementId, name, value);

	for (let i = 0; i < state.length; i++) {
		// loop to the right form
		if (state[i].id == formId) {
			let form_id = state[i].data.form_id;

			let formElements = findFormElements(state[i]?.children);

			// loop to the right element in form childrens
			for (let j = 0; j < formElements.length; j++) {
				if (formElements[j].id == selectedFormElementId) {
					// console.log('children', formElements[j])

					const data = formElements[j].data;
					let updatedData = formElements[j].data;

					let fieldName = formElements[j].data.stateName;

					let validationIndex = null;
					let updatedValidationMessage = '';

					if (name === 'required' && value == false) {			// remove validation from schemas
						for (let y = 0; y < data.validations.length; y++) {
							if (data.validations[y].type == 'required') {
								validationIndex = y;
							}
						}

						// updating ui schema
						for (let x in data) {
							if (x == name) {
								updatedData = { ...updatedData, [name]: value };		// required: false
								formElements[j].data = updatedData;
							}
						}

						// updating ui validation schema
						formElements[j].data.validations.splice(validationIndex, 1)

						// updating strapi schema
						strapiDispatch({
							type: "UPDATE_STRAPI_ATTRIBUTE_SCHEMA",
							form_id,
							fieldName,
							attributeName: name,
							value,
							uiSchema: state,
							undo,
							setUndo,
							setRedo
						});

						break;

					} else if (name === 'required' || name === 'requiredText') {

						for (let y = 0; y < data.validations.length; y++) {
							if (data.validations[y].type == 'required') {
								validationIndex = y;
								updatedValidationMessage = { ...data.validations[y], message: value };
							}
						}

						// updating ui schema
						for (let x in data) {
							if (x == name) {
								updatedData = { ...updatedData, [name]: value };		// required: true 
								formElements[j].data = updatedData;
							}
						}

						// updating ui validation schema
						if (validationIndex != null) {		// update existing data
							formElements[j].data.validations[validationIndex] = updatedValidationMessage;
						} else {							// add new data
							const requiredMessage = { type: 'required', message: `${formElements[j].data.name} is required.` };
							formElements[j].data.validations.push(requiredMessage)
						}

						// updating strapi schema
						strapiDispatch({
							type: "UPDATE_STRAPI_ATTRIBUTE_SCHEMA",
							form_id,
							fieldName,
							attributeName: "required",
							value: true,
							uiSchema: state,
							undo,
							setUndo,
							setRedo
						});

						break;

					} else if (name === 'minLength' || name === 'maxLength' || name == 'min' || name == 'max') {		// remove validation from schema
						let message = null;

						if (name == 'minLength') {
							message = `Minimum ${value} characters required`;

						} else if (name == 'maxLength') {
							message = `Maximum ${value} characters required`;

						} else if (name == 'min') {
							message = `Value cannot be more than ${value}`;

						} else if (name == 'max') {
							message = `Value must be more than ${value}`;
						}

						for (let y = 0; y < data.validations.length; y++) {
							if (data.validations[y].type == name) {
								validationIndex = y;
								break;
							}
						}

						updatedValidationMessage = { type: name, value, message };

						if (value == '' || value == 0) {		// remove from schemas

							// updating validations ui schema
							if (validationIndex != null) {
								formElements[j].data.validations.splice(validationIndex, 1)
							}

							// updating strapi schema
							strapiDispatch({
								type: "UPDATE_STRAPI_ATTRIBUTE_SCHEMA",
								form_id,
								fieldName,
								attributeName: name,
								value,
								uiSchema: state,
								undo,
								setUndo,
								setRedo
							});

						} else {			// update schemas

							// updating ui schema
							for (let x in data) {
								if (x != name) {		// add attribute
									updatedData = { ...updatedData, [name]: value };		// required: true 
									formElements[j].data = updatedData;
								}

								if (x == name) {		// update attribute
									updatedData = { ...updatedData, [name]: value };		// required: true 
									formElements[j].data = updatedData;
								}
							}

							// updating validation ui schema
							if (validationIndex == null) {		// add new data
								const requiredMessage = { type: name, value, message };
								formElements[j].data.validations.push(requiredMessage)
							} else {							// update existing data
								formElements[j].data.validations[validationIndex] = updatedValidationMessage;
							}

							// updating strapi schema
							strapiDispatch({
								type: "UPDATE_STRAPI_ATTRIBUTE_SCHEMA",
								form_id,
								fieldName,
								attributeName: name,
								value,
								uiSchema: state,
								undo,
								setUndo,
								setRedo
							});
						}

						break;

					} else if (name === 'maxSize' || name === 'numberOfFiles') {

						let message = null;

						if (name == 'maxSize') {
							message = `The maximum allowed file size is ${value} KB.`;

						} else if (name == 'numberOfFiles') {
							message = `You can upload up to ${value} files at once.`;
						}

						for (let y = 0; y < data.validations.length; y++) {
							if (data.validations[y].type == name) {
								validationIndex = y;
								break;
							}
						}

						updatedValidationMessage = { type: name, value, message };

						// updating ui schema
						for (let x in data) {
							if (x == name) {
								updatedData = { ...updatedData, [name]: value };		// maxSize || numberOfFiles: value 
								formElements[j].data = updatedData;
							}
						}

						if (value == '' || value == 0) {		// remove from schemas

							// updating validations ui schema
							if (validationIndex != null) {
								formElements[j].data.validations.splice(validationIndex, 1)
							}

							// no need to update strapi schema

						} else {			// update schemas

							// updating validation ui schema
							if (validationIndex == null) {		// add new data
								const requiredMessage = { type: name, value, message };
								formElements[j].data.validations.push(requiredMessage)
							} else {							// update existing data
								formElements[j].data.validations[validationIndex] = updatedValidationMessage;
							}

							// no need to update strapi schema
						}

						break;

					} else {

						// above conditions were outside because they will be updated in data.validations array

						for (let x in data) {
							if (x == name) {
								updatedData = { ...updatedData, [x]: value };
							} else {
								updatedData = { ...updatedData, [name]: value };
							}

							// updating ui schema
							formElements[j].data = updatedData;

							// update formik_state value when defaultValue is updated
							if (name == 'defaultValue') {
								state[i].data.formik_state = { ...state[i].data.formik_state, [fieldName]: value };
							}

							// updating strapi schema
							let isBackendSchemaUpdated = false;

							let strapiSchemaName = name;
							if (name == "options") {
								strapiSchemaName = "enum";
							} else if (name == "defaultValue") {
								strapiSchemaName = "default";
							} else if (name == "allowedTypes") {
								let allowedTypesForStrapi = [];

								for (let k = 0; k < formElements[j].data.allowedTypes.length; k++) {
									allowedTypesForStrapi.push(formElements[j].data.allowedTypes[k].name);
								}

								value = allowedTypesForStrapi;
							} else if (name == "numberType") {
								strapiSchemaName = "type";
							}

							if (strapiSchemaName == "default" || strapiSchemaName == "regex" ||
								strapiSchemaName == "enum" || strapiSchemaName == "allowedTypes" ||
								strapiSchemaName == "multiple" || strapiSchemaName == "unique" ||
								strapiSchemaName == "type"
							) {
								isBackendSchemaUpdated = true;

								strapiDispatch({
									type: "UPDATE_STRAPI_ATTRIBUTE_SCHEMA",
									form_id,
									fieldName,
									attributeName: strapiSchemaName,
									value,
									uiSchema: state,
									undo,
									setUndo,
									setRedo
								});
							}

							if (isBackendSchemaUpdated == false) {
								strapiDispatch({
									type: "UPDATE_STRAPI_ATTRIBUTE_SCHEMA",
									form_id: "NO_UPDATATION_IN_BACKEND_SCHEMA",
									fieldName: "",
									attributeName: "",
									value: "",
									uiSchema: state,
									undo,
									setUndo,
									setRedo
								});
							}
							// updating strapi field name
							// if (strapiSchemaName == 'name') {
							// 	strapiDispatch({
							// 		type: "UPDATE_STRAPI_FIELD_NAME",
							// 		form_id,
							// 		oldName: fieldName,
							// 		newName: value,
							// 		false,
							// 		undo,
							// 		setUndo,
							// 		setRedo
							// 	});
							// }

							break;
						}
					}


				}

			}
		} else if (state[i]?.children) {
			update_form_element_data(state[i].children, formId, selectedFormElementId, name, value, strapiDispatch, isStatic, undo, setUndo, setRedo);
		}
	}
}

// change form element
const change_form_element = (state, formId, selectedFormElementId, oldElement, newElement, strapiDispatch, uiSchema, undo, setUndo, setRedo) => {
	// console.log('state, formId, oldElement, newElement', state, formId, oldElement, newElement);

	// loop schema
	// for (let i = 0; i < state.length; i++) {
	// 	if (state[i].id == formId) {
	// 		let form_id = state[i].data.form_id;

	// 		let oldFormElementIndex = null;
	// 		let oldFormElementName = null;

	// 		let formElements = findFormElements(state[i]?.children);

	// 		// loop children
	// 		for (let j = 0; j < formElements.length; j++) {
	// 			if (formElements[j].id === selectedFormElementId) {
	// 				oldFormElementIndex = j;
	// 				oldFormElementName = formElements[j].data.stateName;
	// 				break;
	// 			}
	// 		}

	// 		const schemas = newFormElementSchema(newElement);

	// 		// updating ui schema
	// 		formElements[oldFormElementIndex] = schemas[0];

	// 		// updating strapi schema
	// 		strapiDispatch({
	// 			type: "CHANGE_STRAPI_ATTRIBUTE",
	// 			form_id,
	// 			oldStrapiAttibute: oldFormElementName,
	// 			newStrapiAttibute: schemas[1],
	// 			uiSchema,
	// 			undo,
	// 			setUndo,
	// 			setRedo
	// 		});

	// 		break;

	// 	} else if (state[i]?.children) {
	// 		change_form_element(state[i].children, formId, selectedFormElementId, oldElement, newElement, strapiDispatch, uiSchema, undo, setUndo, setRedo);
	// 	}
	// }
}


// END - form functions


// START - component functions

const update_component_strapi_schema = (state, id, collectionType, collectionDataOrder, mapToData) => {
	// console.log('state, id, collectionType, mapToData', state, id, collectionType, mapToData);

	for (let i = 0; i < state.length; i++) {
		// loop to the right form
		if (state[i].id == id) {
			let updatedData = state[i].data;

			if (state[i].data.collectionType != collectionType) {
				// remove values of mapTo
				// loop data object
				for (let j = 0; j < state[i].data?.attributes.length; j++) {
					if ("mapTo" in state[i].data?.attributes[j]) {
						const updatedDatas = { ...updatedData?.attributes[j], mapTo: "" };
						state[i].data.attributes[j] = updatedDatas;
					}
				}
			}

			updatedData = { ...updatedData, collectionType, collectionDataOrder };
			state[i].data = updatedData;

			// loop attributes
			for (let j = 0; j < state[i].data?.attributes.length; j++) {

				for (let k = 0; k < mapToData.length; k++) {
					if (updatedData.attributes[j].key == mapToData[k].name) {

						let updatedAttributes = { ...updatedData.attributes[j], mapTo: mapToData[k].mapTo }
						state[i].data.attributes[j] = updatedAttributes;

						break;
					}
				}

			}

		} else if (state[i].children) {
			update_component_strapi_schema(state[i].children, id, collectionType, collectionDataOrder, mapToData);
		}
	}
}

const update_component_schema = (state, id, key, value, mapField) => {
	// console.log('state, id, key, value', state, id, key, value, mapField);

	for (let i = 0; i < state.length; i++) {
		// loop to the right form
		if (state[i].id == id) {
			// console.log('intel inside', state[i], state[i].data, state[i].data?.attributes.length)
			let updatedData = state[i].data;

			if (key == 'collectionType' || key == 'collectionDataOrder') {
				updatedData = { ...updatedData, [key]: value };
				state[i].data = updatedData;

				if (key == 'collectionType') {
					// remove values of mapTo
					// loop data object
					for (let j = 0; j < state[i].data?.attributes.length; j++) {
						if ("mapTo" in state[i].data?.attributes[j]) {
							const updatedDatas = { ...updatedData?.attributes[j], mapTo: "" };
							state[i].data.attributes[j] = updatedDatas;
						}
					}
				}

				break;

			} else {

				// loop data object
				for (let j = 0; j < state[i].data?.attributes.length; j++) {
					// find name
					if (updatedData.attributes[j].key == key) {
						if (mapField) {					// update mapTo field to map dynamic data with component
							updatedData = { ...updatedData?.attributes[j], mapTo: value };
							state[i].data.attributes[j] = updatedData;
							break;

						} else {						// update collectionType, rest of attributes

							if (key == 'numberOfItems' && state[i].data.collectionType == "") {
								// add dummy static data
								if (state[i].data?.data.length < value) {
									const defaultData = state[i].data?.defaultData;

									const difference = value - state[i].data?.data.length;

									let extraData = [];
									for (let k = 0; k < difference; k++) {
										extraData = [...extraData, defaultData];
									}

									const updatedStaticData = [...state[i].data?.data, ...extraData]
									state[i].data.data = updatedStaticData;
								}

							}

							updatedData = { ...updatedData?.attributes[j], value: value };
							state[i].data.attributes[j] = updatedData;
							break;
						}
					}
				}

			}

		} else if (state[i].children) {
			update_component_schema(state[i].children, id, key, value, mapField);
		}
	}
}

// END - component functions

export const Breducer = (state, action) => {
	switch (action.type) {
		case "ADD":
			const data = action.payload
			// console.log(data, "Hello buddy")
			return [...JSON.parse(JSON.stringify(data))]

		case "REMOVE":
			return []

		case "UPDATE":
			state.splice(action.index, 0, action.payload)
			return [...state]

		case "CLEAN":
			cleanExtraObject(state)
			return [...state]

		case "CHANGEBLOCK":
			updateElementSchema(state, action.id, action.element, "CHANGEBLOCK")
			// console.log(state)
			return [...state]
		case "ADDELEMENT":
			updateElementSchema(state, action.id, action.element, "ADDELEMENT")
			// console.log(state)
			return [...state]
		case "ADDELEMENTTOP":
			updateElementSchema(state, action.id, action.element, "ADDELEMENTTOP")
			// console.log(state)
			return [...state]
		case "REPLACEELEMENT":
			updateElementSchema(state, action.id, action.element, "REPLACEELEMENT")
			// console.log(state)
			return [...state]
		case "ADDELEMENTCENTER":
			updateElementSchema(state, action.id, action.element, "ADDELEMENTCENTER")
			// console.log(state)
			return [...state]

		case "UPDATE_CONTENT":
			updateContent({ schema: state, id: action.id, value: action.value, currentLanguage: action.currentLanguage, setContent: action.setContent, selectedSiteData: action.selectedSiteData })
			return state;

		case "UPDATE_CSS":
			updateElementSchema(state, action.id, action.element, "UPDATE_CSS")
			return [...state]

		case "UPDATE_STATIC_DATA":
			updateElementSchema(state, action.id, action.element, "UPDATE_STATIC_DATA")
			return [...state]

		case "UPDATE_STATIC_DATA_ARRAY":
			updateElementSchema(state, action.id, action.element, "UPDATE_STATIC_DATA_ARRAY")
			return [...state]

		case "UPDATE_QUERY":
			updateElementSchema(state, action.id, action.element, "UPDATE_QUERY")
			return [...state]

		case "UPDATE_CUSTOM_COMPONENT":
			updateElementSchema(state, action.id, action.element, "UPDATE_CUSTOM_COMPONENT")
			return [...state]

		case "UPDATE_IS_DYNAMIC":
			updateElementSchema(state, action.id, action.element, "UPDATE_IS_DYNAMIC")
			return [...state]

		case "UPDATE_IS_DYNAMIC_DATA":
			updateElementSchema(state, action.id, action.element, "UPDATE_IS_DYNAMIC_DATA")
			return [...state]

		case "RESET_STYLE":
			updateElementSchema(state, action.id, action.element, "RESET_STYLE")
			return [...state]

		case "UPDATE_MEDIA":
			updateElementSchema(state, action.id, action.element, "UPDATE_MEDIA")
			return [...state]

		case "UPDATE_BACKGROUND_MEDIA":
			updateElementSchema(state, action.id, action.element, "UPDATE_BACKGROUND_MEDIA")
			return [...state]
		case "CHANGE_ELEMENT_SCHEMA":
			updateElementSchema(state, action.id, action.element, "CHANGE_ELEMENT_SCHEMA")
			return [...state]

		case "UPDATE_ICON":
			updateElementSchema(state, action.id, action.element, "UPDATE_ICON")
			return [...state]

		case "DELETE":
			deleteObjectById(state, action.id)
			return [...state]

		case "DUPLICATE":
			duplicateObjectById(state, action.id, action.element)
			// console.log(state)
			return [...state]

		case "MOVE_DOWN":
			// console.log(action.element)
			state.splice(action.index + 2, 0, state[action.index])
			state.splice(action.index, 1)
			return [...state]

		case "MOVE_UP":
			// console.log(action.element)
			state.splice(action.index - 1, 0, state[action.index])
			state.splice(action.index + 1, 1)
			return [...state]

		// START - form actions

		case "ADD_FORM_SECTION":
			addFormSchema(state, action.id, action.add_section)
			// console.log('state new form', state, action)
			return [...state]

		case "ADD_NEW_FORM_ELEMENT":
			add_new_form_element(state, action.formId, action.id, action.add_form_element_section)
			return [...state]

		case "DUPLICATE_FORM":
			duplicate_form(state, action.originalForm_id, action.duplicateID, action.strapiDispatch, action.uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('state duplicate form', state)
			return [...state]

		case "DUPLICATE_FORM_ELEMENT":
			duplicate_form_element(state, action.formId, action.duplicateID, action.strapiDispatch, action.uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('dtate', state)
			return [...state]

		case "REMOVE_FORMIK_STATE":
			remove_formik_state(state, action.formId, action.formElementId)
			// console.log('state', state)
			return [...state]

		case "UPDATE_FORM_NAME":
			update_form_name(state, action.formId, action.formName, action.strapiDispatch, action.sqliteDispatch, action.isStatic, action.uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('state form name', state, action)
			return [...state]

		case "UPDATE_FORM_ATTRIBUTES":
			update_form_attributes(state, action.formId, action.name, action.value);
			// console.log('state form attributes', state, action)
			return [...state]

		case "UPDATE_FORM_ELEMENT_DATA":
			update_form_element_data(state, action.formId, action.selectedFormElementId, action.name, action.value, action.strapiDispatch, action.isStatic, action.undo, action.setUndo, action.setRedo)
			const newState1 = JSON.parse(JSON.stringify(state));
			// console.log('state form elements', newState1, action)
			return [...newState1]

		case "CHANGE_FORM_ELEMENT":
			change_form_element(state, action.formId, action.selectedFormElementId, action.oldElement, action.newElement, action.strapiDispatch, action.uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('state form new element', state, action)
			return [...state]

		// END - form actions

		case "UPDATE_COMPONENT_SCHEMA":
			update_component_schema(state, action.id, action.key, action.value, action.mapField)
			const newState = JSON.parse(JSON.stringify(state));
			return newState

		case "UPDATE_COMPONENT_STRAPI_SCHEMA":
			update_component_strapi_schema(state, action.id, action.collectionType, action.collectionDataOrder, action.mapToData)
			return [...state]
		case "ADD_EVENT":
			addEvent({ schema: state, id: action.id, newEvent: action.newEvent });
			return state;
		case "EDIT_EVENT":
			editEvent({ schema: state, id: action.id, newEvent: action.newEvent });
			return state;
		case "DELETE_EVENT":
			deleteEvent({ schema: state, id: action.id, event: action.event, task: action.task });
			return state;
		default:
			return state;
	}
}


// START - Strapi Reducer

// update form name in strapi schema
const update_strapi_form_name = (state, form_id, formName, uiSchema, undo, setUndo, setRedo) => {
	// console.log('state, formId, formName', state, form_id, formName)

	for (let i = 0; i < state.length; i++) {
		if (state[i].form_id == form_id) {

			// update strapi schema status i.e. create, update, delete
			if (state[i].operation != "update") {
				state[i].operation = "update";
			}

			// let collection_name = formName.replace(/-/g, "_");
			let collection_name = formName.toLowerCase().replace(/-/g, "");
			// let display_name = formName.replace(/-/g, " ");

			const updateData = {
				...state[i],
				strapiSchema: {
					...state[i].strapiSchema,
					collectionName: collection_name,
					info: {
						...state[i].strapiSchema.info,
						singularName: collection_name,
						pluralName: `${collection_name}s`,
						displayName: formName
					}
				}
			}

			// console.log('updateData strapi', updateData)
			state[i] = updateData;

			// add schemas to undo state
			AddToUndo(false, uiSchema, [], state, undo, setUndo, setRedo);

			break;
		}
	}
}

// update strapi field name
const update_strapi_field_name = (state, form_id, oldName, newName, uiSchema, undo, setUndo, setRedo) => {
	// console.log('state, form_id, oldName, newName', state, form_id, oldName, newName);

	for (let i = 0; i < state.length; i++) {
		if (state[i].form_id == form_id) {

			// update strapi schema status i.e. create, update, delete
			if (state[i].operation != "update") {
				state[i].operation = "update";
			}

			// loop fields
			for (let x in state[i].strapiSchema.attributes) {
				if (x == oldName) {

					// it includes old field & new field 
					let updatedData = { ...state[i].strapiSchema.attributes, [newName]: { ...state[i].strapiSchema.attributes[x] } }
					state[i].strapiSchema.attributes = updatedData;

					// delete field with oldName
					delete state[i].strapiSchema.attributes[x];

					break;
				}
			}

			// add schemas to undo state
			AddToUndo(false, uiSchema, [], state, undo, setUndo, setRedo);
		}
	}

}

// update strapi attribute data
const update_strapi_attribute_schema = (state, form_id, fieldName, attributeName, value, uiSchema, undo, setUndo, setRedo) => {
	// console.log('form_id, attributeName', state, form_id, fieldName, attributeName, value);

	if (form_id == 'NO_UPDATATION_IN_BACKEND_SCHEMA') {
		// add schemas to undo state
		AddToUndo(false, uiSchema, [], state, undo, setUndo, setRedo);

	} else {
		for (let i = 0; i < state.length; i++) {
			if (state[i].form_id == form_id) {

				// update strapi schema status i.e. create, update, delete
				if (state[i].operation != "update") {
					state[i].operation = "update";
				}

				// loop fields
				for (let x in state[i].strapiSchema.attributes) {

					if (x == fieldName) {

						// loop attributes
						for (let y in state[i].strapiSchema.attributes[x]) {
							if (y == attributeName) {
								if (value == '' || value == 0) {		// remove from schema
									delete state[i].strapiSchema.attributes[x][y];

								} else {
									let updatedData = { ...state[i].strapiSchema.attributes[x], [y]: value }
									state[i].strapiSchema.attributes[x] = updatedData;
								}

								break;

							} else {
								if (value != '' || value != 0) {

									let newValue = null;

									if (typeof value == 'boolean' || typeof value == 'string' || typeof value == 'object') {
										newValue = value;
									} else {
										newValue = parseInt(value);
									}

									let updatedData = { ...state[i].strapiSchema.attributes[x], [attributeName]: newValue }
									state[i].strapiSchema.attributes[x] = updatedData;

									break;
								}
							}
						}

						// add schemas to undo state
						AddToUndo(false, uiSchema, [], state, undo, setUndo, setRedo);
					}
				}

			}
		}
	}
}

// change strapi attibute
const change_strapi_attribute = (state, form_id, oldStrapiAttibute, newStrapiAttibute, uiSchema, undo, setUndo, setRedo) => {
	// console.log('state, form_id, oldStrapiAttibute, newStrapiAttibute', state, form_id, oldStrapiAttibute, newStrapiAttibute);

	for (let i = 0; i < state.length; i++) {
		if (state[i].form_id == form_id) {

			// update strapi schema status i.e. create, update, delete
			if (state[i].operation != "update") {
				state[i].operation = "update";
			}

			// loop fields
			for (let x in state[i].strapiSchema.attributes) {

				if (x == oldStrapiAttibute) {
					// it includes old field & new field 
					let updatedData = { ...state[i].strapiSchema.attributes, ...newStrapiAttibute };
					state[i].strapiSchema.attributes = updatedData;

					// delete field with oldName
					delete state[i].strapiSchema.attributes[x];

					break;
				}
			}

			// add schemas to undo state
			AddToUndo(false, uiSchema, [], state, undo, setUndo, setRedo);
		}
	}
}

// delete strapi schema
const delete_strapi_form_schema = (state, uiSchema, formId, selectedFormElementId, formSelected, updated_uiSchema, undo, setUndo, setRedo) => {
	// console.log('formId, selectedFormElementId', formId, selectedFormElementId, formSelected)

	// loop ui schema
	for (let i = 0; i < uiSchema.length; i++) {
		// find form
		if (uiSchema[i].id == formId) {
			const strapi_form_id = uiSchema[i].data.form_id;

			// loop strapi schema
			for (let j = 0; j < state.length; j++) {
				// find form
				if (state[j].form_id == strapi_form_id) {
					if (formSelected) {		// update operation = delete
						// state.splice(j, 1);
						if (state[j].operation != "delete") {
							state[j].operation = "delete";
						}
						break;

					} else {				// delete attribute in form

						let formElements = [];
						getFormElementsOnly(uiSchema[i]?.children, formElements);

						// update operation = update
						if (state[j].operation != "update") {
							state[j].operation = "update";
						}

						// find attribute name in ui schema using its id
						let attributeName = null;
						for (let k = 0; k < formElements.length; k++) {
							if (formElements[k].id == selectedFormElementId) {
								attributeName = formElements[k].data.stateName;
							}
						}

						// delete attribute from strapi schema
						for (let x in state[j].strapiSchema.attributes) {
							if (x == attributeName) {
								delete state[j].strapiSchema.attributes[x];
							}
						}

						break;
					}
				}
			}

			// add schemas to undo state
			AddToUndo(false, updated_uiSchema, [], state, undo, setUndo, setRedo);

		} else if (uiSchema[i]?.children) {
			delete_strapi_form_schema(state, uiSchema[i]?.children, formId, selectedFormElementId, formSelected, updated_uiSchema, undo, setUndo, setRedo);
		}
	}
}

// duplicate strapi form schema
const duplicate_strapi_form = (state, originalForm_id, newForm_id, newForm_name, statesToUpdate, uiSchema, undo, setUndo, setRedo) => {
	let originalFormSchema = {};

	for (let i = 0; i < state.length; i++) {
		// find original form from which duplicate form is created
		if (state[i].form_id == originalForm_id) {
			originalFormSchema = state[i];
			break;
		}
	}

	// update attributes
	let updatedAttributes = {};
	for (let x in originalFormSchema.strapiSchema.attributes) {
		for (let k = 0; k < statesToUpdate.length; k++) {
			if (x == statesToUpdate[k].oldAttribute) {
				updatedAttributes = { ...updatedAttributes, [statesToUpdate[k].newAttribute]: originalFormSchema.strapiSchema.attributes[x] };
			}
		}
	}

	let collection_name = newForm_name.replace(/-/g, "_");
	let display_name = newForm_name.replace(/-/g, " ");

	// update form schema
	let newFormSchema = {
		...originalFormSchema,
		form_id: newForm_id,
		operation: "create",
		strapiSchema: {
			...originalFormSchema.strapiSchema,
			collectionName: collection_name,
			info: {
				...originalFormSchema.strapiSchema.info,
				singularName: newForm_name,
				pluralName: `${newForm_name}s`,
				displayName: display_name
			},
			attributes: updatedAttributes
		}
	};

	state = [...state, newFormSchema];
	// console.log('originalFormSchema', state, originalFormSchema, newFormSchema, updatedAttributes);

	// add schemas to undo state
	AddToUndo(false, uiSchema, [], state, undo, setUndo, setRedo);

	return state;
}

// duplicate strapi attribute schema
const duplicate_strapi_attribute = (state, form_id, fieldName, newFieldName, uiSchema, undo, setUndo, setRedo) => {
	// console.log('form_id, fieldName', state, form_id, fieldName, newFieldName);

	for (let i = 0; i < state.length; i++) {
		if (state[i].form_id == form_id) {

			// update strapi schema status i.e. create, update, delete
			if (state[i].operation != "update") {
				state[i].operation = "update";
			}

			// loop fields
			for (let x in state[i].strapiSchema.attributes) {

				if (x == fieldName) {
					state[i].strapiSchema.attributes = { ...state[i].strapiSchema.attributes, [newFieldName]: state[i].strapiSchema.attributes[x] };
					break;
				}
			}

			// add schemas to undo state
			AddToUndo(false, uiSchema, [], state, undo, setUndo, setRedo);
		}
	}
}

// strapi reducer
export const strapiReducer = (state, action) => {
	switch (action.type) {

		case "INITIALIZE":
			// add schemas to undo state
			AddToUndo(false, action.uiSchema, [], action.payload, action.undo, action.setUndo, action.setRedo);

			return action.payload

		case "ADD":
			state = state.length > 0 ? JSON.parse(JSON.stringify(action.payload)) : action.payload

			if (state.length > 0) {
				// add schemas to undo state
				AddToUndo(false, action.uiSchema, [], state, action.undo, action.setUndo, action.setRedo);
			}

			return state;

		case "UPDATE_FROM_UNDO":
			return state.length > 0 ? JSON.parse(JSON.stringify(action.payload)) : action.payload;

		case "ADD_NEW_ATTIBUTE_IN_STRAPI_SCHEMA":

			let updatedState = [];
			for (let i = 0; i < state.length; i++) {

				if (state[i].form_id == action.form_id) {
					let obj = { ...state[i] };

					obj.strapiSchema = {
						...obj.strapiSchema,
						attributes: {
							...obj.strapiSchema.attributes,
							...action.add_new_strapi_attribute
						}
					}

					updatedState.push(obj)

				} else {
					updatedState.push(state[i])
				}
			}
			// console.log('updatedAttributes', state, updatedState)

			// add schemas to undo state
			AddToUndo(false, action.uiSchema, [], updatedState, action.undo, action.setUndo, action.setRedo);

			return updatedState

		case "DUPLICATE_STRAPI_FORM":
			const newState = duplicate_strapi_form(state, action.originalForm_id, action.newForm_id, action.newForm_name, action.statesToUpdate, action.uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('strapi duplicate form : ', newState)
			return [...newState]

		case "DUPLICATE_STRAPI_ATTRIBUTE":
			duplicate_strapi_attribute(state, action.form_id, action.fieldName, action.newFieldName, action.uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('strapi duplicate attribute : ', state)
			return [...state]

		case "UPDATE_STRAPI_FORM_NAME":
			update_strapi_form_name(state, action.form_id, action.formName, action.uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('strapi form name : ', state)
			return [...state]

		case "UPDATE_STRAPI_FIELD_NAME":
			update_strapi_field_name(state, action.form_id, action.oldName, action.newName, action.uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('strapi field name : ', state)
			return [...state]

		case "UPDATE_STRAPI_ATTRIBUTE_SCHEMA":
			update_strapi_attribute_schema(state, action.form_id, action.fieldName, action.attributeName, action.value, action.uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('strapi form data : ', state)
			return [...state]

		case "CHANGE_STRAPI_ATTRIBUTE":
			change_strapi_attribute(state, action.form_id, action.oldStrapiAttibute, action.newStrapiAttibute, action.uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('strapi form new data : ', state)
			return [...state]

		case "DELETE_STRAPI_FORM_SCHEMA":
			delete_strapi_form_schema(state, action.uiSchema, action.formId, action.selectedFormElementId, action.formSelected, action.updated_uiSchema, action.undo, action.setUndo, action.setRedo)
			// console.log('strapi delete form data : ', state)
			return [...state]

		case "EMPTY_STRAPI_SCHEMA":
			return [];

		default:
			return state;
	}
};

// END - Strapi Reducer


// START - Sqlite Reducer

// update sqlite form name (display name)
const update_sqlite_form_name = (state, form_id, formName, uiSchema, undo, setUndo, setRedo) => {
	// console.log('state, formId, formName', state, form_id, formName)

	for (let i = 0; i < state.length; i++) {
		if (state[i].form_id == form_id) {

			// update sqlite schema status i.e. create, update, delete
			if (state[i].operation != "update") {
				state[i].operation = "update";
			}

			const updateData = {
				...state[i],
				displayName: formName
			}

			state[i] = updateData;

			// add schemas to undo state// add schemas to undo state
			AddToUndo(true, uiSchema, state, [], undo, setUndo, setRedo);

			break;
		}
	}
}

// delete sqlite form
const delete_sqlite_form_schema = (schema, formId) => {
	const index = [];
	for (let i = 0; i < schema.length; i++) {
		if (Array.isArray(schema[i].formId)) {
			if (schema[i].formId.includes(formId)) {
				index = [i, schema[i].formId.indexOf(formId)];
				break;
			}
		} else {

			if (schema[i].formId == formId) {
				index = [i];
				break;
			}
		}
	}
	return index;
}
const returnCorrespondingTableIndex = (schema, tableName) => {
	try {
		for (let i = 0; i < schema?.length; i++) {
			if (schema[i].name == tableName) {
				return i;
			}
		}
	} catch (error) {
		console.log(error);
	}
}
// sqlite reducer
export const sqliteReducer = (state, action) => {
	try {
		switch (action.type) {

			case "INITIALIZE":
				// add schemas to undo state
				AddToUndo(true, action.uiSchema, action.payload, [], action.undo, action.setUndo, action.setRedo);

				return action.payload

			case "ADD":
				// state = state.length > 0 ? JSON.parse(JSON.stringify(action.payload)) : action.payload

				// if (state.length > 0) {
				// 	// add schemas to undo state
				// 	AddToUndo(true, action.uiSchema, state, [], action.undo, action.setUndo, action.setRedo);
				// }

				return action.payload;

			case "UPDATE_FROM_UNDO":
				return state.length > 0 ? JSON.parse(JSON.stringify(action.payload)) : action.payload;

			case "UPDATE_SQLITE_FORM_NAME":
				update_sqlite_form_name(state, action.form_id, action.formName, action.uiSchema, action.undo, action.setUndo, action.setRedo)
				// console.log('sqlite form name : ', state)
				return [...state]

			case "DELETE_SQLITE_FORM_SCHEMA":
				for (let i = 0; i < action.formIds.length; i++) {
					const index = delete_sqlite_form_schema(state, action.formIds[i]);
					if (index.length == 2) {
						state[index[0]].formId.splice(index[1]);
					} else if (index.length == 1) {
						state.splice(index[0]);
					}
				}
				return state;

			case "RESET":
				const newBackendSchema = [];
				for (let i = 0; i < state.length; i++) {
					if (state[i].operation == "") {
						newBackendSchema.push(state[i]);

					} else if (state[i].operation == "create") {
						newBackendSchema.push({
							...state[i],
							operation: ""
						});
					}
				}
				return newBackendSchema;

			case "EMPTY_SQLITE_SCHEMA":
				return []
			case "DUPLICATE_FORM":
				for (let i = 0; i < action.formIds.length; i++) {
					const index = returnCorrespondingTableIndex(state, action.formIds[i].tableName);
					if (Array.isArray(state[index].formId)) {
						state[index].formId = [...state[index].formId, action.formIds[i].id];
					} else {
						state[index].formId = [state[index].formId, action.formIds[i].id];
					}
				}
				return state;
			default:
				return state;
		}
	} catch (error) {
		console.log(error);
	}
};

// END - Sqlite Reducer