import { useContext } from 'react';
import Style from './Style';
import Events from './Events';
import ComponentSettings from '../../../screens/account/editor/customComponentSettings/ComponentSettings';
import { context } from '../../../context/Context';
import ComponentSettingsTab from './ComponentSettings';

const RightSideBar = () => {
	const {
		displayDataTab,
		displayEventTab,
		component,
		openedRightSidebarSection,
		setOpenedRightSidebarSection
	} = useContext(context);

	function classNames(...classes) {
		return classes.filter(Boolean).join(' ')
	}
	const componentsWithSettings = ["Carousel2", "SocialLinks", "GoogleMap", "TimerPopup", "form"];
	return (
		<div className='h-full'>
			<div className="flex items-center justify-center border-b border-[#D9D9D9] text-sm text-center">
				<button
					type='button'
					className={
						classNames(
							openedRightSidebarSection == "style" ? ' text-[#4D67A9] bg-[#D0DAF3] font-semibold'
								: 'font-medium hover:bg-gray-100',
							'py-2.5 w-full'
						)
					}
					onClick={() => setOpenedRightSidebarSection("style")}
				>
					Style
				</button>

				{
					displayEventTab &&
					<button
						type='button'
						className={
							classNames(
								openedRightSidebarSection == "events" ? ' text-[#4D67A9] bg-[#D0DAF3] font-semibold'
									: 'font-medium hover:bg-gray-100',
								'py-2.5 w-full'
							)
						}
						onClick={() => setOpenedRightSidebarSection("events")}
					>
						Events
					</button>
				}

				{
					displayDataTab &&
					<button
						type='button'
						className={
							classNames(
								openedRightSidebarSection == "data" ? ' text-[#4D67A9] bg-[#D0DAF3] font-semibold'
									: 'font-medium hover:bg-gray-100',
								'py-2.5 w-full'
							)
						}
						onClick={() => setOpenedRightSidebarSection("data")}
					>
						Data
					</button>
				}
				{
					(component && componentsWithSettings.includes(component.type)) &&
					<button
						type='button'
						className={
							classNames(
								openedRightSidebarSection == "settings" ? ' text-[#4D67A9] bg-[#D0DAF3] font-semibold'
									: 'font-medium hover:bg-gray-100',
								'py-2.5 w-full'
							)
						}
						onClick={() => setOpenedRightSidebarSection("settings")}
					>
						Settings
					</button>
				}
			</div>


			<div className='h-[calc(100%-55px)]'>

				{openedRightSidebarSection == "style" &&
					<Style />}

				{openedRightSidebarSection == "events" && displayEventTab &&
					<Events />
				}

				{openedRightSidebarSection == "data" && displayDataTab &&
					<ComponentSettings />
				}
				{
					(component && componentsWithSettings.includes(component.type) && openedRightSidebarSection == 'settings') &&
					<ComponentSettingsTab />
				}
			</div>
		</div>
	)
}
export default RightSideBar;