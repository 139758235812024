import { Tab } from "@headlessui/react";
import { BlockWrapper } from "../Sortable";
import { createElement } from "react";
import { useContext } from "react";
import { context } from "../../../../context/Context";
import { AddToUndo } from "../../editor";
import { useLocation, useParams } from "react-router-dom";
import { generateContent, generateCss } from "../functions/sortableFunctions";
const TabPanel = ({ block, ...props }) => {
	const { selectedSiteData, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo } = useContext(context);
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (

		createElement(
			Tab.Panel,
			{
				style: {
					fontFamily: block?.style?.fontFamily
				},
				className: generateCss({
					contextVariables: contextVariables,
					props: {
						...props,
						block: block
					},
					params: params,
					location: location
				}),
				id: block.id,
				...block.data,
				as: block.data?.type ? block.data.type : 'div',
				onMouseOver: (e) => props.handleMouseOver(e),
				onClick: (e) => {
					props.handleClick(e, block.id);
					if (props.customComponentChildren) {
						props.handleComponentWithSettingsClick();
					}

				},
				onDoubleClick: (e) => e.target.contentEditable = true,
				suppressContentEditableWarning: true,
				onBlur: (e) => {
					block.content = e.target.innerText;
					AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
				}
			},
			block.children ?
				block.children.map((value, index) => {
					return <BlockWrapper
						{...props}
						key={index}
						block={value}
						blockIndex={[...props.blockIndex, index]}
					/>
				})
				:
				generateContent({
					contextVariables: contextVariables,
					props: {
						...props,
						block: block,
					},
					params: params,
					location: location
				})
		)

	)
}
export default TabPanel;