import { useContext, useEffect, useState } from "react";
import { context } from "../../../context/Context";
import Carousel2Settings from "./Carousel2Settings";
import GoogleMapSettings from "./GoogleMapSettings";
import SocialLinksSettings from "./SocialLinksSettings";
import TimerPopupSettings from "./TimerPopupSettings";
import { Formik } from "formik";
const ComponentSettingsTab = () => {
	const { component } = useContext(context);

	return (
		<div className="overflow-y-auto no-scrollbar h-full p-4 text-sm">
			{
				(component && component.type == 'form') &&
				<>
					<FormSetting />
				</>

			}
			{
				(component && component.type == 'Carousel2') &&
				<Carousel2Settings />

			}
			{
				(component && component.type == 'GoogleMap') &&
				<GoogleMapSettings />

			}
			{
				(component && component.type == 'SocialLinks') &&
				<SocialLinksSettings />

			}
			{
				(component && component.type == 'TimerPopup') &&
				<TimerPopupSettings />

			}

		</div >
	)
}
export default ComponentSettingsTab;

const FormSetting = () => {
	const [parameter, setParameter] = useState({})
	const { component,Bdispatch } = useContext(context);

	
	useEffect(()=>{
		for(let i = 0; i < component.events?.onSubmit?.length;i++){
			if(component.events?.onSubmit[i]?.task == "saveData"){
				console.log(component.events.onSubmit[i].parameters, "PARAMETER")
				setParameter({
					...component.events.onSubmit[i].parameters
				})
			}
		}
	},[JSON.stringify(component)])
	return (
		<div>
			{
				(component?.data?.name == "register" || component?.data?.name == "login") && (
					<Formik
						enableReinitialize={true}
						initialValues={{ enableOtp: parameter?.enableOtp || false, otpSendTo: parameter?.otpSendTo || 'Email' }}
						validate={values => {
							// const errors = {};
							// if (!values.email) {
							// 	errors.email = 'Required';
							// } else if (
							// 	!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
							// ) {
							// 	errors.email = 'Invalid email address';
							// }
							// return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							// setTimeout(() => {
							// 	alert(JSON.stringify(values, null, 2));
							// 	setSubmitting(false);
							// }, 400);
							// component.events[onSubl]["enableOtp"] = values?.enableOtp
							for(let i = 0; i < component.events?.onSubmit?.length;i++){
								if(component.events?.onSubmit[i]?.task == "saveData"){
									component.events.onSubmit[i].parameters = {
										...component.events.onSubmit[i].parameters,
										enableOtp: values?.enableOtp,
										otpSendTo: values?.otpSendTo
									}
								}
							}
							Bdispatch({type: "CHANGEBLOCK", id:component?.id, element: component})
							console.log(component)
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setFieldValue
							/* and other goodies */
						}) => (
							<form onSubmit={handleSubmit}>
								<div className="flex items-center gap-4">
									<input
										type="checkbox"
										checked={values?.enableOtp}
										className="rounded-full"
										onChange={(e) => {
											setFieldValue("enableOtp", e.target.checked)
										}}
									/>
									<p className=" form-bold text-gray-700">Enable OTP Verification</p>
								</div>
								{
									values?.enableOtp &&
									<div className="flex items-center justify-between mt-4 ml-10">
										<p className=" form-bold text-gray-700">
											OTP Send To:
										</p>
										<span className="tracking-[1px] px-4 py-2 border-[1px] rounded-lg  font-medium">{values?.otpSendTo}</span>
										{/* <select
											className="outline-none border-[1px] border-gray-500 rounded-lg py-1"
											onChange={(e)=>{
												setFieldValue("otpSendTo",e.target.value)
											}}
										>
											<option value="Email">Email</option>
											<option value="Mobile">Mobile</option>
										</select> */}
									</div>
								}

								<hr className="my-4" />
								<button type="submit" disabled={isSubmitting}
									className="bg-[#1E40AF] px-3 py-2 rounded-lg text-white float-right"
								>
									Save
								</button>
							</form>
						)}
					</Formik>
				)
			}
		</div>
	)
}