import { useContext } from "react";
import { context } from "../../../context/Context";
import { Formik } from "formik";
import { AddToUndo } from "../../../screens/account/editor";


const TimerPopupSettings = () => {
	const { component, blocks, undo, setUndo, setRedo, strapiSchema, sqliteSchema, selectedSiteData } = useContext(context);
	return (
		<div className="space-y-6">
			<Formik
				enableReinitialize={true}
				initialValues={{
					openAfter: (component.data?.openAfter != null && component.data?.openAfter != undefined) ? component.data.openAfter : ""
				}}
				onSubmit={async (values) => {
					component.data = { ...component.data, ...values };
					AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
				}}
			>
				{
					(formik) => (
						<form onSubmit={formik.handleSubmit} className="space-y-5">

							<div className="space-y-2">
								<label className="text-sm font-medium">Open After (seconds)</label>
								<input type="number" name="openAfter" id="openAfter" value={formik.values.openAfter / 1000} onChange={(e) => {
									formik.setFieldValue('openAfter', e.target.value * 1000);
								}} onBlur={formik.handleBlur} className="w-full border border-gray-300 rounded-md py-1 text-sm focus:outline-none focus:border-indigo-600 focus:border-2 focus:ring-0" />

							</div>

							<button type="submit"
								disabled={formik.isSubmitting}
								className="bg-indigo-600 px-3 py-2 font-medium text-sm rounded-md text-white w-full"
							>
								Save
							</button>
						</form>
					)
				}
			</Formik>
		</div>
	)
}
export default TimerPopupSettings;