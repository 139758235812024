import { Popover, Switch, Tab } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';
import { classNames } from '../../../libraries/utility';
import ColorPicker from "./ColorPicker";
import ColorPicker2 from "./ColorPicker2";
import { dbCall } from '../../../common/db';
import { toast } from 'react-toastify';
import { SketchPicker } from 'react-color';

function Background({ setElementBreakpointSpecificStyleObject, setElementAllBreakpointsStyleObject, elementBreakpointSpecificStyleObject, updateDesign, elementId }) {

	const tabs = [
		{
			name: 'Color', content: <BgColor setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject}
				setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject}
				updateDesign={updateDesign} elementId={elementId} />, icon: <span class="mdi mdi-format-color-fill text-lg text-[#757575]"></span>
		},
		// {
		// 	name: 'Gradient', content: <Gradient setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject}
		// 		setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject}
		// 		updateDesign={updateDesign} elementId={elementId} />, icon: <span class="mdi mdi-gradient-vertical text-lg text-[#757575]"></span>
		// },
		{
			name: 'Image', content: <Image setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject}
				setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject}
				updateDesign={updateDesign} elementId={elementId} />, icon: <span class="mdi mdi-image text-lg text-[#757575]"></span>
		},
	];

	return (
		<div className="w-full max-w-xs px-2 sm:px-0 divide-y">
			<Tab.Group>
				<Tab.List className="ml-auto flex space-x-1 rounded-xl p-3 w-fit ">
					{tabs.map((tab) => (
						<Tab
							key={tab.name}
							className={({ selected }) =>
								classNames(
									'flex items-center w-full text-sm w-fit leading-5 px-2 rounded-md border border-[#D9D9D9]',
									'',
									selected
										? 'bg-[#D9D9D9]'
										: 'bg-white'
								)
							}
						>
							{tab.icon}
							{tab.name}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels className="mt-2">
					{tabs.map((tab) => (
						<Tab.Panel
							key={tab.name}
							className={classNames(
								'rounded-xl bg-white',
							)}
						>
							{tab.content}
						</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

export default Background

const BgColor = ({ setElementBreakpointSpecificStyleObject, setElementAllBreakpointsStyleObject, elementBreakpointSpecificStyleObject, updateDesign, elementId }) => {

	return (
		<div className="flex items-center justify-between  p-3">
			<div className="font-medium text-gray-900">
				Color
			</div>
			<ColorPicker
				setElementBreakpointSpecificStyleObject={
					setElementBreakpointSpecificStyleObject
				}
				setElementAllBreakpointsStyleObject={
					setElementAllBreakpointsStyleObject
				}
				elementBreakpointSpecificStyleObject={
					elementBreakpointSpecificStyleObject
				}
				updateDesign={updateDesign}
				field={"backgroundColor"}
			/>
		</div>
	)
}

const Gradient = ({ setElementBreakpointSpecificStyleObject, setElementAllBreakpointsStyleObject, elementBreakpointSpecificStyleObject, updateDesign, elementId }) => {
	const [colors, setColors] = useState(['#757575', '#757575', '#757575'])
	const [direction, SetDirection] = useState('l')
	const [gpercent, setGpercent] = useState([]);
	const timerref = useRef();
	const generateTailwindGradient = (colors) => {
		if (colors?.length === 1) {
			window.alert("Please add one more color to apply gradient");
			return '';
		}

		// const getGradientPart = (color, percent, position) => {
		// 	const percentPart = percent ? `${position}-[${color}] ${position}-${percent}%` : `${position}-[${color}]`;
		// 	return percentPart;
		// };

		// const fromPart = getGradientPart(colors[0], gpercent[0], 'from');
		// const viaPart = colors[1] ? getGradientPart(colors[1], gpercent[1], 'via') : '';
		// const toPart = colors[2] ? getGradientPart(colors[2], gpercent[2], 'to') : getGradientPart(colors[1], gpercent[1], 'to');

		let bg = `gradient-to-${direction}`.trim();
		let fromColor = colors[0];
		let fromPercent = gpercent[0]
		let viaColor = colors[1];
		let viaPercent = gpercent[1];
		let toColor = colors[2];
		let toPercent = gpercent[2]

		let changes = [{ name: 'backgroundImage', value: bg }, { name: 'fromPercent', value: fromPercent },
		{ name: 'toColor', value: toColor }, { name: 'toPercent', value: toPercent }, { name: 'viaColor', value: viaColor },
		{ name: 'viaPercent', value: viaPercent },  { name: 'fromColor', value: fromColor },];
		changes.forEach(item => {
			if (item?.value) {
				setElementBreakpointSpecificStyleObject((prev) => {
					return {
						...prev,
						[item.name]: item.value
					}
				});
				timerref.current = setTimeout(() => {
					updateDesign(item.name, item.value);
				}, 1000);
			}
		})
	};
	const first = useRef(true);

	useEffect(() => {
		if (!first.current)
			generateTailwindGradient(colors);
		else first.current = false
	}, [colors, direction, gpercent])

	const gradientDirections = [{ name: 'Left', value: 'l' }, { name: 'Right', value: 'r' }, { name: 'Bottom', value: 'b' }, { name: 'Top', value: 't' }];
	const tabs = [
		{
			name: 'Linear',
			icon: <div className="border border-solid border-neutral-500 w-[14px] w-full h-[14px] bg-gradient-to-b from-[#757575] to-[#D9D9D9]" />,
			content: <div>
				<div className='flex gap-2 justify-between items-center'>
					<span>Direction</span>
					{/* <input type='range' min={0} max={100} className='w-full h-0.5 mb-6 ml-6 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' /> */}
					<div className='flex items-center'>
						{/* <input
							type="number"
							max={100}
							className="w-[36px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9] "
						/>
						<div className='py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 text-xs rounded-r'>%</div> */}
						<select onChange={(e) => SetDirection(e.target.value)} id="direction" class="w-fit px-2 py-1 border border-[#D9D9D9] text-xs rounded text-[#373C47] focus:ring-0  focus:border-[#D9D9D9]">
							{gradientDirections.map(item => <option selected={direction === item?.value} value={item?.value}>{item?.name}</option>)}
						</select>
					</div>
				</div>
				<div className='flex gap-2 justify-between items-center mt-2'>
					<span>Color</span>
					<button onClick={() => colors.length < 3 && setColors([...colors, '#757575'])} className='py-1 px-2 border border-black text-black flex items-center justify-between gap-1 rounded-md'>+ Add Color</button>
				</div>
				{colors?.map((item, index) =>
					<div className='flex gap-2 justify-between items-center ml-6 mt-2'>
						<>

							<Popover className="relative">
								<Popover.Button
									className={`outline-none border-none focus:outline-none`}
								>
									<div
										className={`w-[15px] h-[15px] rounded-[3px] border bg-[${item}]`}
									/>
								</Popover.Button>

								<Popover.Panel
									className={'absolute z-[51] top-8 left-0'}
								>
									<SketchPicker
										color={colors}
										onChange={(color) => {
											let newcolor = [...colors];
											newcolor[index] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
											setColors(newcolor)
										}}
										presetColors={[
											'#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE',
											'#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'
										]}
									/>
								</Popover.Panel>
							</Popover>
						</>
						<input onChange={(e) => {
							if (e.target.value <= 100) {
								let newvalues = [...gpercent];
								newvalues[index] = e.target.value;
								setGpercent(newvalues)
							}
						}} value={gpercent[index]} type='range' min={0} max={100} className='w-full h-0.5 mb-6 ml-6 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
						<div className='flex items-center'>
							<input
								type="number"
								max={100}
								value={gpercent[index]}
								onChange={(e) => {
									if (e.target.value <= 100) {
										let newvalues = [...gpercent];
										newvalues[index] = e.target.value;
										setGpercent(newvalues)
									}
								}}
								className="w-[36px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9] "
							/>
							<div className='py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 text-xs rounded-r'>%</div>
						</div>
					</div>
				)}
				<div className='flex items-center justify-end gap-2 mt-4'>
					<button onClick={() => {
						setColors([]);
					}} className={
						`flex items-center gap-1 py-1 w-full text-sm w-fit leading-5 px-2 rounded-md border border-[#D9D9D9] bg-white`
					}
					>Remove</button>
				</div>
			</div>
		},
		{
			name: 'Radial', icon: <div style={{
				background: 'radial-gradient(circle, #757575, transparent)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}} className="border border-solid border-neutral-500 w-[14px] w-full h-[14px]" />,
			content: <div>
				<div className='flex gap-2 justify-between items-center'>
					<span>Color</span>
					<button className='py-1 px-2 border border-black text-black flex items-center justify-between gap-1 rounded-md'>+ Add Color</button>
				</div>
				<div className='flex gap-2 justify-between items-center ml-6 mt-2'>
					<ColorPicker2
						setElementBreakpointSpecificStyleObject={
							setElementBreakpointSpecificStyleObject
						}
						setElementAllBreakpointsStyleObject={
							setElementAllBreakpointsStyleObject
						}
						elementBreakpointSpecificStyleObject={
							elementBreakpointSpecificStyleObject
						}
						updateDesign={updateDesign}
						field={"color"}
					/>
					<input type='range' min={0} max={100} className='w-full h-0.5 mb-6 ml-6 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
					<div className='flex items-center'>
						<input
							type="number"
							max={100}
							className="w-[36px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9]"
						/>
						<div className='py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 text-xs rounded-r'>%</div>
					</div>
				</div>
			</div>
		},
	];

	return (
		<div className=' p-3'>
			<Tab.Group>
				<div className='flex items-center justify-between'>
					<div>Type</div>
					<Tab.List className=" ml-auto flex space-x-1 rounded-xl w-fit ">
						{tabs.map((tab) => (
							<Tab
								key={tab.name}
								className={({ selected }) =>
									classNames(
										'flex items-center gap-1 py-1 w-full text-sm w-fit leading-5 px-2 rounded-md border border-[#D9D9D9]',
										'',
										selected
											? 'bg-[#D9D9D9]'
											: 'bg-white'
									)
								}
							>
								{tab.icon}
								{tab.name}
							</Tab>
						))}
					</Tab.List>

				</div>
				<Tab.Panels className="mt-2">
					{tabs.map((tab) => (
						<Tab.Panel
							key={tab.name}
							className={classNames(
								'rounded-xl bg-white p-3',
							)}
						>
							{tab.content}
						</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

const Image = ({ setElementBreakpointSpecificStyleObject, setElementAllBreakpointsStyleObject, elementBreakpointSpecificStyleObject, updateDesign, elementId }) => {

	const [imageURL, setImageURL] = useState(elementBreakpointSpecificStyleObject?.backgroundImage)
	const timerref = useRef();
	const fileInputRef = useRef(null);

	const handleImageUpload = async (blobInfo) => {
		const filesData = new FormData();
		filesData.append('files', blobInfo)
		const image_url = await dbCall.post('/qafto/upload-files/asdfghjkqwert', filesData)
			.then(response => {
				let media_cdn_to_upload;
				if (response.data.cdn_url.length > 1) {
					media_cdn_to_upload = response.data.cdn_url;
				} else {
					media_cdn_to_upload = response.data.cdn_url[0];
				}
				setImageURL(media_cdn_to_upload);
				setElementBreakpointSpecificStyleObject((prev) => {
					return {
						...prev,
						'backgroundImage': media_cdn_to_upload
					}
				});
				clearTimeout(timerref.current);
				timerref.current = setTimeout(() => {
					updateDesign('backgroundImage', media_cdn_to_upload);
				}, 1000);
			})
			.catch(error => {
				if (error.response.data?.error?.message) {
					toast.error(error.response.data.error.message);
				} else if (error.response.data.message) {
					toast.error(error.response.data.message);
				} else {
					toast.error('Something went wrong!');
				}
			})
		return image_url;
	};


	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	return (
		<div className=''>
			<div className='flex gap-4 items-end  p-3'>
				<div className={`border w-full h-full flex items-center bg-white rounded-md max-w-[193.88px] h-[107px]`}>
					<div className="flex items-center justify-center space-x-4 w-full">
						<div className="flex-shrink-0">
							<div className="relative">
								{!elementBreakpointSpecificStyleObject?.backgroundImage?.length > 0 || elementBreakpointSpecificStyleObject?.backgroundImage === 'none' ? <span class="mdi mdi-cloud-upload text-7xl text-[#D9D9D9]"></span>
									: <img className='' src={elementBreakpointSpecificStyleObject?.backgroundImage} class="max-w-[193px] max-h-[107px] self-center rounded-md object-cover" alt='upload' />}
								<label htmlFor="file-upload" className="absolute inset-0 cursor-pointer">
									<span className="sr-only">Upload Image</span>
								</label>
								<input
									id="file-upload"
									name="file-upload"
									type="file"
									onChange={(e) => handleImageUpload(e.target.files[0])}
									multiple={false}
									accept="image/*"
									className="sr-only"
									ref={fileInputRef}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='text-xs space-y-1'>
					<button onClick={() => {
						setElementBreakpointSpecificStyleObject((prev) => {
							return {
								...prev,
								'backgroundImage': 'none'
							}
						});
						clearTimeout(timerref.current);
						timerref.current = setTimeout(() => {
							updateDesign('backgroundImage', 'none');
						}, 1000);
						setImageURL('')
					}}
						className='rounded-md border border-solid border-zinc-300 px-2 py-1 w-full min-h-[25px]'>
						Remove
					</button>
					<button onClick={handleButtonClick} className='rounded-md bg-[#D9D9D9] border border-solid border-zinc-300  py-1 w-full min-h-[25px]'>
						Select Image
					</button>
				</div>
			</div>
			{true && <>
				<div className='text-xs mt-1 px-3'>Upload a jpeg, png, gif or webp</div>
				<div className='text-sm w-full text-center my-1 px-3'>OR</div>
				<div className='flex gap-2 px-3'>
					<span>URL</span>
					<input value={imageURL === 'none' ? '' : imageURL} onChange={(e) => {
						setImageURL(e.target.value)
					}} className='rounded-md border border-solid border-zinc-300 w-full h-[25px]' />
					<button
						onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundImage': imageURL
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundImage', imageURL);
							}, 1000);
						}} className='rounded-md border border-solid w-fit bg-zinc-300 px-2 h-[25px]'>Save</button>
				</div></>}
			<div className='divide-y border mt-2'>
				<div className='flex items-center justify-between p-3'>
					<div className="text-[#373C47] font-medium">Format</div>
					<div className=" ml-auto flex space-x-1 rounded-xl w-fit">
						<button
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundSize': 'cover'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundSize', 'cover');
								}, 1000);
							}}

							className={
								`flex items-center gap-1 py-1 w-full text-[11px] w-fit leading-5 px-2 rounded-md ${elementBreakpointSpecificStyleObject?.backgroundSize === 'cover' ? 'bg-[#D9D9D9]' : 'bg-white'}`
							}
						>
							Full Width
						</button><button
							onClick={() => {
								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										'backgroundSize': 'contain'
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign('backgroundSize', 'contain');
								}, 1000);
							}}
							className={
								`flex items-center gap-1 py-1 w-full text-[11px] w-fit leading-5 px-2 rounded-md ${elementBreakpointSpecificStyleObject?.backgroundSize === 'contain' ? 'bg-[#D9D9D9]' : 'bg-white'}`
							}
						>
							Shrink to Fit
						</button><button
							className={
								`flex items-center gap-1 py-1 w-full text-[11px] w-fit leading-5 px-2 rounded-md ${elementBreakpointSpecificStyleObject?.backgroundSize?.includes('%') ? 'bg-[#D9D9D9]' : 'bg-white'}`
							}
						>
							Image Size
						</button>
					</div>
				</div>
				{
					elementBreakpointSpecificStyleObject?.backgroundSize?.includes('%') &&
					<div className='p-3'>
						<div className='flex items-center gap-2'>
							<span>Scale</span>
							<input type='range' className='w-full h-0.5 mb-6 ml-6 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
							<div className='flex items-center'>
								<input
									type="number"
									max={100}
									className="w-[36px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9] "
								/>
								<div className='py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 text-xs rounded-r'>%</div>
							</div>
						</div>
						<div className='flex items-center justify-between w-full mt-2'>
							<div className='flex items-center gap-2'>
								<span className=''>Repeat X</span>
								<NewSwith />
							</div>
							<div className='flex items-center gap-2'>
								<span className=''>Repeat Y</span>
								<NewSwith />
							</div>
						</div>
						<div className='flex justify-between gap-2 mt-2'>
							<span className=''>Color Tint</span>
							<NewSwith />
						</div>
						{/* Shown when color tint is active */}
						{true && <div className='ml-6 mt-2'>
							<div className='flex items-center justify-between'>
								<span>Color</span>
								<ColorPicker
									setElementBreakpointSpecificStyleObject={
										setElementBreakpointSpecificStyleObject
									}
									setElementAllBreakpointsStyleObject={
										setElementAllBreakpointsStyleObject
									}
									elementBreakpointSpecificStyleObject={
										elementBreakpointSpecificStyleObject
									}
									updateDesign={updateDesign}
									field={"color"}
								/>
							</div>
							<div className='flex items-center gap-2 mt-2'>
								<span>Opacity</span>
								<input type='range' className='w-full h-0.5 mb-6 ml-6 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
								<div className='flex items-center'>
									<input
										type="number"
										max={100}
										className="w-[36px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9] "
									/>
									<div className='py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 text-xs rounded-r'>%</div>
								</div>
							</div>
						</div>}
					</div>
				}
				<div className='divide-y'><div className='flex gap-4 p-3'>
					<span>Position</span>
					<div className='grid grid-cols-3 gap-2'>
						<button onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundPosition': 'left-top'
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundPosition', 'left-top');
							}, 1000);
						}} className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'left-top' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}>
							<span class="mdi mdi-arrow-top-left"></span>
						</button>
						<button onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundPosition': 'top'
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundPosition', 'top');
							}, 1000);
						}} className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'top' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}>
							<span class="mdi mdi-arrow-collapse-up"></span>
						</button>
						<button onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundPosition': 'right-top'
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundPosition', 'right-top');
							}, 1000);
						}} className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'right-top' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}>
							<span class="mdi mdi-arrow-top-right"></span>
						</button>
						<button onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundPosition': 'left'
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundPosition', 'left');
							}, 1000);
						}} className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'left' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}>
							<span class="mdi mdi-arrow-collapse-left"></span>
						</button>
						<button onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundPosition': 'center'
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundPosition', 'center');
							}, 1000);
						}} className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'center' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}>
							<span class="mdi mdi-arrow-collapse-all"></span>
						</button>
						<button onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundPosition': 'right'
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundPosition', 'right');
							}, 1000);
						}} className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'right' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}>
							<span class="mdi mdi-arrow-collapse-right"></span>
						</button>
						<button onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundPosition': 'right-bottom'
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundPosition', 'right-bottom');
							}, 1000);
						}} className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'right-bottom' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}>
							<span class="mdi mdi-arrow-bottom-left"></span>
						</button>
						<button onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundPosition': 'bottom'
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundPosition', 'bottom');
							}, 1000);
						}} className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'bottom' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}>
							<span class="mdi mdi-arrow-collapse-down"></span>
						</button>
						<button onClick={() => {
							setElementBreakpointSpecificStyleObject((prev) => {
								return {
									...prev,
									'backgroundPosition': 'left-bottom'
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign('backgroundPosition', 'left-bottom');
							}, 1000);
						}} className={`rounded-md border border-solid ${elementBreakpointSpecificStyleObject?.backgroundPosition === 'left-bottom' && 'bg-zinc-300'} text-[#757575] flex items-center justify-center border-zinc-300 w-[32px] min-h-[22px]`}>
							<span class="mdi mdi-arrow-bottom-right"></span>
						</button>
					</div>
				</div>
					<div className='flex items-center justify-between mt-3 p-3'>
						<div className='flex items-center justify-center gap-1'>
							Parallax Effects
							<span class="mdi mdi-information-outline"></span>
						</div>
						<NewSwith />
					</div>
				</div>
			</div>
		</div >
	)
}

function NewSwith({ elementBreakpointSpecificStyleObject, setElementBreakpointSpecificStyleObject }) {
	const [enabled, setEnabled] = useState(false)

	return (
		<div className="">
			<Switch
				checked={enabled}
				onChange={setEnabled}
				className={`${enabled ? 'bg-indigo-600' : 'bg-[#757575]'}
			relative inline-flex h-[16px] w-[32px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
			>
				<span className="sr-only">Use setting</span>
				<span
					aria-hidden="true"
					className={`${enabled ? 'translate-x-4' : 'translate-x-0'}
			  pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
				/>
			</Switch>
		</div>
	)
}