import React, { Fragment } from 'react';
import { BlockWrapper } from '../Sortable';
import ScrollAnimation from "react-animate-on-scroll";


export default function ScrollAnimations({ block, blockIndex, handleClick }) {
	return (
		<>
			<ScrollAnimation
				animateIn={block.data.animationIn}
				animateOnce={block?.data?.animateOnce}
				duration={block?.data?.Duration ?? 1}
				delay={block?.data?.Delay ?? 0}
				animateOut="fadeOut"
				style={{ "opacity": 1 }}
				id={block.id}
			>
				
				<div className={block?.css} id={block.id}
					onClick={(event) => {
						event.stopPropagation();
						const id = event.target.id;
						handleClick(event, id)
					}}>
					{block?.children &&
						block?.children?.map((childBlock, index) => (
							<BlockWrapper key={childBlock.id} block={childBlock} blockIndex={[...blockIndex, index]} />
						))
					}
				</div>
				{/* {
					<BlockWrapper key={block.id} blockIndex={blockIndex} handleClick={handleClick} block={{...block, data: { ...block?.data, IsAnimation: false }}} />
				} */}
			</ScrollAnimation>
		</>
	)
}
