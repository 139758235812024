import axios from 'axios';

export function adminDBCall(adminURL, adminToken) {
	const instance = axios.create();

	// console.log('adminURL, adminToken inside ', adminURL, adminToken);

	instance.defaults.baseURL = adminURL + '/api';
	instance.defaults.headers.common['Authorization'] = 'Bearer ' + adminToken;

	instance.defaults.headers.get['Accept'] = 'application/json';
	instance.defaults.headers.post['Accept'] = 'application/json';
	instance.defaults.onDownloadProgress = function (progressEvent) {
		// setShowProgressBar(true);
	};
	instance.defaults.withCredentials = true;

	instance.defaults.onUploadProgress = function (progressEvent) {
		// setShowProgressBar(true);
	};

	instance.interceptors.request.use(function (request) {
		// setShowProgressBar(true);
		return request;
	}, function (error) {
		alert("unable to load");
	});

	instance.interceptors.response.use(function (response) {
		return response;
	}, function (error) {

		// if (error.response) {
		// 	if (error.response.status == 401) {

		// 		AuthService.logout();
		// 		return;
		// 	}
		// }
		throw error;
	});

	return instance;
}