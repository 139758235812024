
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SquaresPlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Components, Elements as Element, Forms, Icons } from '@qafto/pkg_qafto_builder_components';
import ReactPlayer from 'react-player';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { context } from '../../../context/Context';
import { classNames, changeId, randomString } from '../../../libraries/utility';
import { AddToUndo } from '../../../screens/account/editor';
import { processChange } from '../../../layouts/NewEditor';
import { dbCall } from '../../../common/db';

const DropDown = {
	"type": "Dropdown",
	"children": [
		{
			"type": "button",
			"style": {
				"color": "white",
				"backgroundColor": "#1d4ed8",
				"fontSize": "14px",
				"paddingLeft": "20px",
				"paddingRight": "20px",
				"paddingTop": "10px",
				"paddingBottom": "10px",
				"textAlign": "center",
				"display": "inline-flex",
				"alignItems": "center"
			},
			"data": {
				"data-dropdown-toggle": "dropdown",
				"type": "button"
			},
			"css": "text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center ",
			"content": "Dropdown button "
		},
		{
			"type": "div",
			"children": [
				{
					"type": "ul",
					"children": [
						{
							"type": "li",
							"children": [
								{
									"type": "a",
									"style": {
										"display": "block",
										"paddingLeft": "16px",
										"paddingRight": "16px",
										"paddingTop": "8px",
										"paddingBottom": "8px",
										"color": "#6b7280"
									},
									"data": {
										"href": "#"
									},
									"css": "block px-4 py-2 hover:bg-gray-100 text-gray-500",
									"content": "Dashboard"
								}
							],
							"style": {}
						},
						{
							"type": "li",
							"children": [
								{
									"type": "a",
									"style": {
										"display": "block",
										"paddingLeft": "16px",
										"paddingRight": "16px",
										"paddingTop": "8px",
										"paddingBottom": "8px",
										"color": "#6b7280"
									},
									"data": {
										"href": "#"
									},
									"css": "block px-4 py-2 hover:bg-gray-100 text-gray-500",
									"content": "Settings"
								}
							],
							"style": {}
						},
						{
							"type": "li",
							"children": [
								{
									"type": "a",
									"style": {
										"display": "block",
										"paddingLeft": "16px",
										"paddingRight": "16px",
										"paddingTop": "8px",
										"paddingBottom": "8px",
										"color": "#6b7280"
									},
									"data": {
										"href": "#"
									},
									"css": "block px-4 py-2 hover:bg-gray-100 text-gray-500",
									"content": "Earnings"
								}
							],
							"style": {}
						},
						{
							"type": "li",
							"children": [
								{
									"type": "a",
									"style": {
										"display": "block",
										"paddingLeft": "16px",
										"paddingRight": "16px",
										"paddingTop": "8px",
										"paddingBottom": "8px",
										"color": "#6b7280"
									},
									"data": {
										"href": "#"
									},
									"css": "block px-4 py-2 hover:bg-gray-100 text-gray-500",
									"content": "Sign out"
								}
							],
							"style": {}
						}
					],
					"style": {
						"paddingTop": "8px",
						"paddingBottom": "8px",
						"fontSize": "14px",
						"color": "#374151"
					},
					"css": "py-2 text-sm text-gray-700 dark:text-gray-200",
					"data": {
						"aria-labelledby": "dropdownDefaultButton"
					}
				}
			],
			"style": {
				"backgroundColor": "white",
				"width": "176px"
			},
			"css": "absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 "
		}
	],
	"style": {}
};

export function Elements() {
	const [filteredItems, setFilteredItems] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	const [itemsToDisplay, setItemsToDisplay] = useState(5);
	const [search, setSearch] = useState('');
	// const [f, setFormSelected] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const newQueryParameters = new URLSearchParams();
	const [registerFormField, setRegisterFormField] = useState([])

	const {
		setIsRender,
		elementRef,
		setElement,
		setFormSelected,
		currentLeftSidebarSectionTAB, setCurrentLeftSidebarSectionTAB,
		isDropped, setIsDropped,
		selectedSiteData,
		sqliteSchema,
		strapiSchema,
		undo, setUndo,
		setRedo,
		blocks,
		theme,
		formElementRef,
		componentRef,
		setCordinates,
		setSelectedFormElementId,
		onHoverCoordinates,
		Bdispatch,
		currentPlan,
		sqliteDispatch,
		currentPlatform,
		setIsAnimating,
		openedRightSidebarSection,
		setOpenedRightSidebarSection,
		openedLeftSidebarSection,
		setOpenedLeftSidebarSection
	} = useContext(context)

	const params = useParams()


	const findAndSet = (arr) => {
		for (let i = 0; i < arr?.length; i++) {
			const obj = arr[i];
			if (obj?.type == "form" && obj?.data?.name == "register") {
				return obj?.data?.validations;
			} else if (obj.children && obj?.children?.length > 0) {
				findAndSet(obj.children);
			}
		}
		return false;
	}
	const getPageData = async () => {
		try {
			setIsAnimating(true);
			const response = await dbCall.get(`site/get-page-data/Register/${params.siteId}/${currentPlatform}`);

			const validation = await findAndSet(response?.data?.data?.pageData?.schema)

			const formField = []
			for (const key in validation) {
				if (validation[key]?.required) {
					formField.push(key)
				}
			}
			console.log(formField, "this is register page response");
			setRegisterFormField([...formField])
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!");

		} finally {
			setIsAnimating(false)
		}
	}

	useEffect(() => {
		if (openedLeftSidebarSection == 'elements') {
			getPageData()
		}
	}, [openedLeftSidebarSection])

	useEffect(() => {
		if (!openedLeftSidebarSection) {
			setCurrentLeftSidebarSectionTAB("Section");
			setItemsToDisplay(5);
			setIsSearching(false);
			setFilteredItems([]);
			setSearch('');
		}
	}, [openedLeftSidebarSection]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const myParam = urlParams.get('section');
		setSearch('');
		if (myParam) {
			const filterSection = [...Components, ...Forms.filter(item => selectedSiteData.static == item.static)].filter(data => data.type === myParam)
			setFilteredItems(filterSection)
			setIsSearching(true);
		} else {
			setIsSearching(false);
		}
		setItemsToDisplay(5);
	}, [currentLeftSidebarSectionTAB, window.location.search])


	// drag and drop form anywhere from form layouts
	// const dragAndAddNewForm = (layout) => {
	// 	setElement(layout);

	// 	setIsRender(false);

	// 	setFormSelected(true);
	// 	setSelectedFormElementId(null);
	// }

	const timerRef = useRef();
	return (
		<div>
			<button
				type="button"
				className={classNames(
					openedLeftSidebarSection == 'elements'
						? "bg-[#393939]"
						: "hover:bg-[#393939]",
					"rounded-md px-3 py-2 font-semibold text-white"
				)}
				id="step-two"
				onClick={(e) => {
					processChange()
					setOpenedRightSidebarSection(openedLeftSidebarSection ? openedRightSidebarSection : false);
					setOpenedLeftSidebarSection((prev) => {
						if (prev == 'elements') {
							return null;
						} else {
							return 'elements';
						}
					})

					if (elementRef.current != null) {
						elementRef.current.style.outline = "none";
						elementRef.current = null;
					}
					if (formElementRef.current != null) {
						formElementRef.current.style.outline = "none";
						formElementRef.current = null;
					}
					if (componentRef.current != null) {
						componentRef.current.style.outline = "none";
						componentRef.current = null;
					}

					setCordinates({
						cordinatesValue: {
							top: 0,
							left: 0
						}
					});
				}}
				title="Elements"
			>
				<SquaresPlusIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
				<span className="sr-only">Elements</span>
			</button>
			{
				<div className={`${openedLeftSidebarSection == 'elements' ? "w-full opacity-100 z-[55]" : "w-0 opacity-0"} duration-500 transition flex flex-col bg-white fixed inset-y-0 left-20 top-[103px] ${currentLeftSidebarSectionTAB === 'Section' ? 'max-w-xl' : 'max-w-sm'}`}>
					<div className={`px-4 border-b-2 py-2.5 flex items-center justify-between`}>
						<div className='flex space-x-4'>

							<button
								type="button"
								className={classNames(
									currentLeftSidebarSectionTAB == 'Section' ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
									'rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
								)}
								onClick={() => setCurrentLeftSidebarSectionTAB("Section")}
							>
								Section
							</button>
							<button
								type="button"
								className={classNames(
									currentLeftSidebarSectionTAB == 'Element' ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
									'rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
								)}
								onClick={() => setCurrentLeftSidebarSectionTAB("Element")}
							>
								Element
							</button>
							<button
								type="button"
								className={classNames(
									currentLeftSidebarSectionTAB == 'Icon' ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
									'rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
								)}
								onClick={() => setCurrentLeftSidebarSectionTAB("Icon")}
							>
								Icon
							</button>
						</div>

						<button
							type="button"
							className="rounded-md bg-white text-gray-400 hover:text-gray-500 "
							onClick={() => {
								setOpenedLeftSidebarSection(null)
							}}
						>
							<XMarkIcon className="h-6 w-6" aria-hidden="true" />
						</button>
					</div>
					{/* END - tabs (elemen, icon, section) */}
					{
						openedLeftSidebarSection == 'elements' &&
						<div className={`${openedLeftSidebarSection != 'elements' && "hidden"} ${(currentLeftSidebarSectionTAB == "Element" || currentLeftSidebarSectionTAB == 'Icon') ? 'overflow-y-auto no-scrollbar' : 'flex-auto'}`}>
							{/* START - search */}
							{currentLeftSidebarSectionTAB === "Icon" && <div class="w-full flex justify-center pt-7 pb-4">
								<div className="w-[90%] relative">
									<div className="w-full absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
										<svg
											className="w-4 h-4 text-gray-500 "
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 20 20"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
											/>
										</svg>
									</div>
									<input
										type="search"
										id="search"
										className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-200 rounded-lg bg-gray-50 shadow-sm outline-none"
										placeholder={`Search ${currentLeftSidebarSectionTAB}`}
										value={search}
										onChange={(e) => {
											if (itemsToDisplay != 5) {
												setItemsToDisplay(5)
											}
											setSearch(e.target.value);
											if (e.target.value == '') {
												setIsSearching(false)
												setFilteredItems([])
											} else {
												setIsSearching(true);
												clearTimeout(timerRef.current);
												timerRef.current = setTimeout(() => {
													const searchValue = e.target.value.toLowerCase();
													if (currentLeftSidebarSectionTAB == "Section") {
														const filterSection = [...Components, ...Forms].filter(item => item.type.toLowerCase()?.includes(searchValue))
														setFilteredItems(filterSection)
													} else if (currentLeftSidebarSectionTAB == "Element") {
														const filterSection = Element.filter(item => item.type.toLowerCase()?.includes(searchValue))
														setFilteredItems(filterSection);

													} else if (currentLeftSidebarSectionTAB == "Icon") {
														const filterSection = Icons.filter(item => item?.name?.toLowerCase()?.includes(searchValue))
														setFilteredItems(filterSection)
													}
												}, 1000);
											}
										}}
										onKeyUp={(e) => {
											if (e.key == 'Enter') {
												clearTimeout(timerRef.current);
												const searchValue = e.target.value.toLowerCase();
												if (currentLeftSidebarSectionTAB == "Section") {
													const filterSection = Components.filter(item => item.type.toLowerCase()?.includes(searchValue))
													setFilteredItems(filterSection)
												} else if (currentLeftSidebarSectionTAB == "Element") {
													const filterSection = Element.filter(item => item.type.toLowerCase()?.includes(searchValue))
													setFilteredItems(filterSection);

												} else if (currentLeftSidebarSectionTAB == "Icon") {
													const filterSection = Icons.filter(item => item?.name?.toLowerCase()?.includes(searchValue))
													setFilteredItems(filterSection)
												}
											}
										}}
									/>
								</div>
							</div>}
							{/* END - search */}

							<hr />

							{
								currentLeftSidebarSectionTAB == "Element" &&
								<div className=" w-full h-full p-3"
									onScroll={(e) => {
										if (isSearching == true) {
											if (filteredItems.length > itemsToDisplay) {
												if ((e.currentTarget.offsetHeight + e.currentTarget.scrollTop) == e.currentTarget.scrollHeight) {
													if ((itemsToDisplay + 5) <= filteredItems.length) {
														setItemsToDisplay(itemsToDisplay + 5)
													} else {
														if ((filteredItems.length - itemsToDisplay) % 5 != 0) {
															setItemsToDisplay(itemsToDisplay + ((filteredItems.length - itemsToDisplay) % 5))
														} else {
															setItemsToDisplay(itemsToDisplay + 5)
														}
													}
												}
											}
										} else {

											if (Element.length > itemsToDisplay) {
												if ((e.currentTarget.offsetHeight + e.currentTarget.scrollTop) == e.currentTarget.scrollHeight) {
													if ((itemsToDisplay + 5) <= Element.length) {
														setItemsToDisplay(itemsToDisplay + 5)
													} else {
														if ((Element.length - itemsToDisplay) % 5 != 0) {
															setItemsToDisplay(itemsToDisplay + ((Element.length - itemsToDisplay) % 5))
														} else {
															setItemsToDisplay(itemsToDisplay + 5)
														}
													}
												}
											}
										}
									}}>
									{
										(isSearching == true ? filteredItems.slice(0, itemsToDisplay) : Element).map((element, i) => (
											<>
												<div className='w-[100%] mx-2' key={i}>
													<div className="rounded-md bg-purple-50 my-3 w-fit  px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">{element.type}</div>
													<div className={` grid grid-cols-3 gap-3 `}>
														{
															element?.elements?.map((data, i) => (
																<>
																	<div
																		onClick={() => {
																			console.log("");
																		}}
																		onDragStart={async (e) => {
																			// setElement(data.element)
																			// setIsRender(false)
																			const changedValue = await changeId(JSON.parse(JSON.stringify(data.element)));

																			// const fieldName = await genWords(1)
																			// changedValue["fieldName"] = fieldName

																			setElement(changedValue)
																			setIsRender(false)

																			setIsDropped(false);
																		}}
																		draggable={true}
																		className='group'
																	>
																		<div

																			className='flex flex-col items-center justify-between group h-full w-[100px] p-2 cursor-pointer rounded shadow'>
																			<img src={data.icon} className='h-[30px] w-[30px]' alt='icon' />
																			<p className='text-xs mt-3 text-center mb-auto'>{data?.type}</p>
																		</div>
																		<div className={`h-48 w-48 items-center overflow-hidden ease-in transition  justify-center bg-gray-100 group-hover:absolute top-0 -right-48 shadow group-hover:flex hidden !p-4`}>
																			<Renderer key={i} config={data?.element} index={i} category={data.type} />
																		</div>

																	</div >
																</>
															))
														}
													</div>
												</div >
												<hr className='w-full my-4' />
											</>
										))
									}
								</div>
							}

							{
								currentLeftSidebarSectionTAB == "Section" &&
								<div className="relative py-1 w-full h-full overflow-auto lol no-scrollbar" onScroll={(e) => {
									if (isSearching == true) {
										if (filteredItems.length > itemsToDisplay) {
											if ((e.currentTarget.offsetHeight + e.currentTarget.scrollTop) == e.currentTarget.scrollHeight) {
												if ((itemsToDisplay + 5) <= filteredItems.length) {
													setItemsToDisplay(itemsToDisplay + 5)
												} else {
													if ((filteredItems.length - itemsToDisplay) % 5 != 0) {
														setItemsToDisplay(itemsToDisplay + ((filteredItems.length - itemsToDisplay) % 5))
													} else {
														setItemsToDisplay(itemsToDisplay + 5)
													}
												}
											}
										}
									} else {
										if (Components.length > itemsToDisplay) {
											if ((e.currentTarget.offsetHeight + e.currentTarget.scrollTop) == e.currentTarget.scrollHeight) {
												if ((itemsToDisplay + 5) <= Components.length) {
													setItemsToDisplay(itemsToDisplay + 5)
												} else {
													if ((Components.length - itemsToDisplay) % 5 != 0) {
														setItemsToDisplay(itemsToDisplay + ((Components.length - itemsToDisplay) % 5))
													} else {
														setItemsToDisplay(itemsToDisplay + 5)
													}
												}
											}
										}
									}
								}}>
									{/* <div className='flex items-start max-h-screen'> */}

									<div className='w-[30%] mx-2 absolute overflow-y-auto left-0 inset-y-0 border-r no-scrollbar'>
										<div className='w-full h-full flex flex-col p-2 '>
											{[...Components, ...Forms.filter((item) => {
												if (selectedSiteData.static) {
													let match = false;
													for (let i = 0; i < item.sections.length; i++) {
														if ('SqliteSchema' in item.sections[i]) {
															match = true;
															break
														}
													}
													return match;
												} else {
													let match = false;
													for (let i = 0; i < item.sections.length; i++) {
														if ('StrapiSchema' in item.sections[i]) {
															match = true;
															break
														}
													}
												}
											})].map(item =>
												<>
													{

														// true && 
														item?.category == currentPlan &&
														<button type="button"
															className={classNames(search == item.type ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm text-start  font-medium cursor-pointer w-full whitespace-nowrap')}
															onClick={() => {
																const type = item?.type;
																clearTimeout(timerRef.current);
																const filterSection = [...Components, ...Forms.filter(item => selectedSiteData.static == item.static)].filter(data => data.type === type)
																setFilteredItems(filterSection)
																setSearch(type);
																setIsSearching(true);
																newQueryParameters.delete("section");
																setSearchParams(newQueryParameters);
															}} >
															{item.type} </button>
													}
													{
														!item?.category &&
														<button
															type="button"
															className={classNames(search == item.type ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm text-start  font-medium cursor-pointer w-full whitespace-nowrap')}
															onClick={() => {
																clearTimeout(timerRef.current);
																const filterSection = [...Components, ...Forms.filter(item => selectedSiteData.static == item.static)].filter(data => data.type === item.type)
																setFilteredItems(filterSection)
																setSearch(item.type);
																setIsSearching(true);
															}} > {item.type}
														</button>
													}
												</>
											)}
										</div>
									</div>
									{/* {console.log('"', search, '"', "search")} */}
									<div className='w-[70%] w-full overflow-y-auto inset-y-0 absolute right-0 no-scrollbar'>

										{
											(isSearching == true ? filteredItems.slice(0, itemsToDisplay) : [...Components, ...Forms.filter((item) => {
												if (selectedSiteData.static) {
													let match = false;
													for (let i = 0; i < item.sections.length; i++) {
														if ('SqliteSchema' in item.sections[i]) {
															match = true;
															break
														}
													}
													return match;
												} else {
													let match = false;
													for (let i = 0; i < item.sections.length; i++) {
														if ('StrapiSchema' in item.sections[i]) {
															match = true;
															break
														}
													}
													return match;
												}
											})].slice(0, itemsToDisplay)).map((sections, i) => {

												return (
													<>
														<div key={i} className='my-2 px-5'>
															{!isSearching && <div className="rounded-md bg-purple-50 my-3 w-fit  px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">{sections.type}</div>}
															{
																sections?.sections?.map((section, i) => {
																	if ("UISchema" in section && (selectedSiteData.static ? 'SqliteSchema' in section : "StrapiSchema" in section)) {
																		return (
																			<div
																				key={i}
																				onDragStart={() => {
																					setElement(section)
																					setIsRender(false)
																				}}

																				onClick={async () => {
																					if (onHoverCoordinates?.id) {
																						if (selectedSiteData.static) {
																							const uniqueFormId = randomString();
																							const uiSchema = section?.UISchema(uniqueFormId, selectedSiteData.static);
																							const newComponentSchema = changeId(uiSchema);
																							const addedFormSqliteSchema = section?.SqliteSchema(uniqueFormId);
																							if (onHoverCoordinates?.addTop == true) {
																								await Bdispatch({ type: "ADDELEMENTTOP", id: onHoverCoordinates?.id, element: newComponentSchema });
																							}
																							else if (onHoverCoordinates?.addTop == false) {
																								await Bdispatch({ type: "ADDELEMENT", id: onHoverCoordinates?.id, element: newComponentSchema });
																							}
																							let match = false;
																							for (let i = 0; i < sqliteSchema?.length; i++) {
																								if (sqliteSchema[i].displayName == addedFormSqliteSchema.displayName) {
																									match = true;
																									break;
																								}
																							}
																							if (match) {
																								addedFormSqliteSchema.displayName = (addedFormSqliteSchema.displayName + (Math.floor(Math.random() * 900 + 100)).toString());
																							}
																							await sqliteDispatch({ type: "ADD", payload: [...sqliteSchema, addedFormSqliteSchema], uiSchema: blocks, undo, setUndo, setRedo });
																						} else {

																						}

																					}
																				}}
																			>
																				<div class="my-3 bg-gray-200 p-1 rounded-xl">
																					<img src={section.url} className="object-cover rounded-xl w-full" alt="sections" loading='lazy' />
																				</div>
																			</div>
																		)
																	} else if (!Object.keys(section).includes("UISchema")) {
																		let found = false;
																		if (sections?.type == "Login" && registerFormField.length > 0) {
																			var keys = Object.keys(section?.schema?.data?.fields);
																			found = registerFormField.some(r => [...keys].includes(r))
																		}
																		return (
																			<>
																				{
																					sections?.type == "Login" ?
																						found &&
																						<div
																							key={i}
																							onDragStart={() => {
																								setElement(section)
																								setIsRender(false)
																							}}

																							onClick={async () => {
																								console.log(sections?.type)
																								if (onHoverCoordinates?.id) {
																									let addUIComponent = section?.schema ? changeId(JSON.parse(JSON.stringify(section.schema))) : section;
																									if (onHoverCoordinates?.addTop == true) {
																										await Bdispatch({ type: "ADDELEMENTTOP", id: onHoverCoordinates?.id, element: addUIComponent });
																									}
																									else if (onHoverCoordinates?.addTop == false) {
																										await Bdispatch({ type: "ADDELEMENT", id: onHoverCoordinates?.id, element: addUIComponent });
																									}
																								}
																							}}
																						>
																							<div class="my-3 bg-gray-200 p-1 rounded-xl">
																								<img src={section.url} className="object-cover rounded-xl w-full" alt="sections" loading='lazy' />
																							</div>
																						</div>
																						:
																						<div
																							key={i}
																							onDragStart={() => {
																								setElement(section)
																								setIsRender(false)
																							}}

																							onClick={async () => {
																								console.log(sections?.type)
																								if (onHoverCoordinates?.id) {
																									let addUIComponent = section?.schema ? changeId(JSON.parse(JSON.stringify(section.schema))) : section;
																									if (onHoverCoordinates?.addTop == true) {
																										await Bdispatch({ type: "ADDELEMENTTOP", id: onHoverCoordinates?.id, element: addUIComponent });
																									}
																									else if (onHoverCoordinates?.addTop == false) {
																										await Bdispatch({ type: "ADDELEMENT", id: onHoverCoordinates?.id, element: addUIComponent });
																									}
																								}
																							}}
																						>
																							<div class="my-3 bg-gray-200 p-1 rounded-xl">
																								<img src={section.url} className="object-cover rounded-xl w-full" alt="sections" loading='lazy' />
																							</div>
																						</div>

																				}
																			</>
																		)
																	}
																})
															}
														</div>
														<hr className='w-full ml-2 mt-4' />
													</>
												)


											})
										}
									</div>
								</div>
							}

							{
								currentLeftSidebarSectionTAB == "Icon" &&
								<div className="relative w-full px-5 py-3 h-[70vh] overflow-auto no-scrollbar flex flex-wrap items-center gap-3" >
									{
										(isSearching == true ? filteredItems : Icons).map((element, i) => (
											<Renderer key={i} config={element} category={element.type} />
										))
									}
								</div>
							}

						</div>
					}

				</div>

			}
		</div >
	);
}

function Renderer({ config, category }) {
	const {
		elementRef,
		setElement,
		setIsRender,
		elementId,
		blocks, Bdispatch,
		undo, setUndo,
		setRedo,
		strapiSchema,
		sqliteSchema,
		selectedSiteData,
		isDropped, setIsDropped,
	} = useContext(context)

	// for https://www.sololearn.com/Discuss/2619111/
	function genWords(numberOfWords, minWordLen = 2, maxWordLen = 8) {
		const alpha = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",]
		const words = []

		for (let i = 0; i < numberOfWords; i++) {
			// get a random number of letter for each word based on the minWordLen & maxWordLen
			const randWordLen = Math.floor(Math.random() * (maxWordLen - minWordLen)) + minWordLen;
			let word = ""
			for (let j = 0; j < randWordLen; j++) {
				// get a random letter
				const randLetter = alpha[Math.floor(Math.random() * alpha.length)];
				// append the random letter to the current word
				word += randLetter
			}
			// add it to the array of words
			words.push(word)

		}
		// convert array to string using a whitespace as separator
		return words.join(" ")
	}

	if (typeof config !== "undefined") {
		return (
			// <span className={`${(category == "Headings" || category == "Paragraphs" || category == "input") && "block bg-gray-200 mb-2 p-2 rounded-md"}`}>
			<>
				{
					React.createElement(
						config?.type,
						{
							id: config?.id,
							// className: `${config?.css} ${config?.type == "a" ? currentTheme.linkColor : currentTheme.text} ${config?.css?.includes("shadow") && `shadow-[${currentTheme.bgColor}]`} ${currentTheme.font} ${currentTheme.bgColor}`,
							className: `${config?.css} cursor-pointer`,
							// draggable: true,
							title: config?.name,
							onClick: async (e) => {
								if (elementId?.data?.icon == "true" || elementId?.data?.icon == true || elementId.type == "icon") {
									await Bdispatch({
										type: "UPDATE_ICON", id: elementId.id, element: config,
									})

									// add schemas to undo state for icons
									AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
								}
							},
							onDragStart: async () => {

								// add random id to element schema
								let changedValue;
								if (category == "Dropdown") {
									changedValue = await changeId(JSON.parse(JSON.stringify(DropDown)));
								} else {
									changedValue = await changeId(JSON.parse(JSON.stringify(config)));
								}
								// console.log(changedValue, "changedValue")
								const fieldName = await genWords(1)
								changedValue["fieldName"] = fieldName
								if (changedValue?.type == "div") {
									delete changedValue?.style?.height
									const val = changedValue?.css?.replace("h-[10vh]", '')
									// console.log(val, changedValue)
									changedValue.css = val
								}
								setElement(changedValue)
								setIsRender(false)

								setIsDropped(false);
							},
							onDragEndCapture: () => {
								// console.log(elementRef)
								const newsec = document?.getElementById("newsec")
								newsec?.remove()
								if (elementRef.current != null) {
									elementRef?.current?.classList?.remove('bottom-outline', 'top-outline', "opacityLow")
									// elementRef.current.style.outline = "none"
									// elementRef.current.style.opacity = "1"
								}

								if (isDropped) {
									// add schemas to undo state
									AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
								}
							},
							...config?.data
						},
						config.children ?
							config.children.map((c, i) => <RendereChildren key={i} config={c} index={i} />) : config.content && config.content
					)
				}
			</>
		)

	}
}

function RendereChildren({ config, index }) {
	if (typeof config !== "undefined") {
		if (config?.type === 'player') {
			return <ReactPlayer style={{ pointerEvents: 'none' }} width="100%"
				height="100%"
				url={config?.data?.src} controls={false} playing={true} muted={true} />
		}
		return (<>
			{
				React.createElement(
					config?.type,
					{
						id: config?.id,
						className: `${config?.css}`,
						src: config.src && config.src,
						title: config?.name,
						...config?.data
					},
					config.children ?
						config.children.map((c, i) => <RendereChildren key={i} config={c} index={i} />) : config.content && config.content
				)
			}
		</>)

	}
}

