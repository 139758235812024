const generateContent = (paramsObject) => {
	try {
		const { props, contextVariables } = paramsObject;
		const { block } = props;
		if (block.content) {
			if (typeof block.content == 'object') {
				if (block.content.custom) {
					return eval(block.content.value);
				} else {
					if (props.singleData?.[block.content.fieldName]) {
						return props.singleData[block.content.fieldName];
					} else {
						return null;
					}
				}
			} else {
				if (contextVariables.selectedSiteData.multiLanguageEnabled && contextVariables.selectedSiteData.templateLanguage && contextVariables.currentLanguage) {
					if (contextVariables.selectedSiteData.templateLanguage.code === contextVariables.currentLanguage.code) {
						return block.content;
					} else {
						if (contextVariables.content?.[contextVariables.currentLanguage.code]?.[block.id]) {
							return contextVariables.content[contextVariables.currentLanguage.code][block.id]

						} else {
							return block.content;
						}
					}
				} else {
					return block.content;
				}
			}
		} else {
			return null
		}
	} catch (error) {
		console.log(error);
		return null;
	}
}

const generateCss = (paramsObject) => {
	try {
		const { props, params, location, contextVariables, container, elementWithChildren } = paramsObject;
		const { block } = props;
		if (block.css) {
			let css = block.css;
			if (typeof block.css == 'object') {
				css = eval(block.css.value);
			}
			css = css.replaceAll(
				"secondary_color",
				`[${contextVariables.currentTheme?.secondary_color}]`
			).replaceAll(
				"bg_color",
				`[${contextVariables.currentTheme?.bg_color}]`
			).replaceAll(
				"textColor",
				`[${contextVariables.currentTheme?.textColor}]`
			).replaceAll(
				"primary_color",
				`[${contextVariables.currentTheme?.primary_color}]`
			).replaceAll(
				"primary_font_size",
				`[${contextVariables.currentTheme?.primary_font_size}]`
			).replaceAll(
				"secondary_font_size",
				`[${contextVariables.currentTheme?.secondary_font_size}]`
			).replaceAll(
				"font_size",
				`[${contextVariables.currentTheme?.font_size}]`
			).replaceAll(
				/cursor-[a-zA-Z0-9_-]+/g,
				""
			);
			if (elementWithChildren) {
				if (block.id.includes('popover')) {
					css.replaceAll("hidden", "");
				}
				if (css.includes('grid')) {
					css = css + ' !block';
				}
				if (css.includes('overflow')) {
					css = css + ' !overflow-hidden';
				}
			}
			if (container) {
				css = css + ' !w-full !p-0 !m-0 !border-0 !static !shadow-none !transform-none !animate-none !bg-none !bg-transparent !h-full !max-w-none !min-w-0';
			}
			return css;
		} else {
			return null;
		}
	} catch (error) {
		console.log(error);
		return null;
	}
}

const generateSrc = (paramsObject) => {
	try {
		const { props, params, location, contextVariables } = paramsObject;
		const { block } = props;
		if (block.dynamic) {
			if (props.data?.[block.data?.src]) {
				return props.data[block.data.src];
			} else if (typeof props.data !== 'object') {
				return props.data;
			} else {
				return null;
			}
		} else if (block.data?.src) {
			if (typeof block.data.src != 'object') {
				if (block.data.src.includes("https://") || block.data.src.includes("http://")) {
					return block.data.src;
				} else {
					return `${process.env.REACT_APP_USER_CDN}/${block.data.src}`;
				}
			} else {
				if (block.data.src.custom) {
					const value = eval(block.data.src.value);
					if (value.includes("https://") || value.includes("http://")) {
						return value;
					} else {
						return `${process.env.REACT_APP_USER_CDN}/${value}`;
					}
				} else if (props.singleData && props.singleData[block.data.src.fieldName]) {
					if (props.singleData[block.data.src.fieldName].includes("https://") || props.singleData[block.data.src.fieldName].includes("http://")) {
						return props.singleData[block.data.src.fieldName];
					} else {
						return `${process.env.REACT_APP_USER_CDN}/${props.singleData[block.data.src.fieldName]}`;
					}
				} else {
					return null;
				}
			}
		} else {
			return null;
		}
	} catch (error) {
		console.log(error);
		return null;

	}
}

export { generateContent, generateCss, generateSrc };