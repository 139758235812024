import { useRef } from "react";

const Slider = ({ property, setElementBreakpointSpecificStyleObject, updateDesign, valueTypes, elementBreakpointSpecificStyleObject }) => {
	const timerRef = useRef();

	return (
		<input type="range" min={0} max={100} className="rangeInput w-full" value={parseInt(elementBreakpointSpecificStyleObject[property])} onChange={(e) => {
			setElementBreakpointSpecificStyleObject((prev) => {
				return {
					...prev,
					[property]: `${e
						.target
						.value
						}${valueTypes[property].unit}`
				};
			}
			);
			clearTimeout(timerRef.current);
			timerRef.current = setTimeout(
				() => {
					updateDesign(
						property,
						`${e.target.value}${valueTypes[property].unit}`
					);
				},
				1000
			);
		}} />

	)
}
export default Slider;