import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary"
import { ParallaxProvider } from "react-scroll-parallax";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// classes
import './index.css';
import "./animate.css";

import reportWebVitals from './reportWebVitals';

import TopLoadingBar from './components/TopLoadingBar';
import NotificationModal from './components/user/NotificationModal';

// context
import { context, ContextProvider } from './context/Context';

// AUth
import { AuthService } from './common/auth.service.js';

import MailChimp from "../src/screens/account/sites/admin/MailChimp.jsx";
import MailchimpUtil from '../src/screens/account/sites/admin/MailchimpUtil.jsx';
import Features from './screens/account/sites/admin/Features.js';
import WalkThrough from './libraries/walkthrough.js';
import { Dashboard } from './screens/account/sites/admin/Dashboad.js';
// const Builder = React.lazy(() => import('./screens/account/sites/builder'));

// layouts
const AdminLayout = React.lazy(() => import('./layouts/admin'));
// const EditorLayout = React.lazy(() => import('./layouts/editor'));
const NewEditorLayout = React.lazy(() => import('./layouts/NewEditor'));
const UserLayout = React.lazy(() => import('./layouts/user1'));
const MarketplaceLayout = React.lazy(() => import('./layouts/marketplace'));
const PlanLayout = React.lazy(() => import('./layouts/plans'));

// error fallback
const ErrorFallback = React.lazy(() => import('./ErrorFallback'));

// all plans
const Plans = React.lazy(() => import('./screens/account/plans'));

// template
const Template = React.lazy(() => import('./screens/account/sites/template'));
const TemplatePages = React.lazy(() => import('./screens/account/sites/templatepages'));

// guest
const Auth = React.lazy(() => import('./screens/guest/auth'));
const Logout = React.lazy(() => import('./screens/guest/logout'));

// for local environment only
const Register = React.lazy(() => import('./screens/guest/register'));
const RegisterVerifyOtp = React.lazy(() => import('./screens/guest/registerVerifyOtp'));
const Login = React.lazy(() => import('./screens/guest/login'));

// user
const User_Overview = React.lazy(() => import('./screens/account/overview'));
const AllNotifications = React.lazy(() => import('./screens/account/allNotifications'));
const Settings = React.lazy(() => import('./screens/account/settings'));
const SitesAll = React.lazy(() => import('./screens/account/sites/all'));
const ConnectGoogleCallback = React.lazy(() => import('./screens/guest/connectGoogle'));
const ConnectFacebookCallback = React.lazy(() => import('./screens/guest/connectFacebook'));

// team
const Team = React.lazy(() => import('./screens/account/team/team'));
const RolePermission = React.lazy(() => import('./screens/account/team/RolePermission'));

// user right popover pages
const UserProfile = React.lazy(() => import('./screens/account/right_popover/UserProfile'));
const UserAccountSecurity = React.lazy(() => import('./screens/account/right_popover/UserAccountSecurity'));
const UserNotificationPreferences = React.lazy(() => import('./screens/account/right_popover/UserNotificationPreferences'));

// billing
const Subscriptions = React.lazy(() => import('./screens/account/billing/Subscriptions'));
const Billing = React.lazy(() => import('./screens/account/billing/Billings'));
const BllingDetails = React.lazy(() => import('./screens/account/billing/BllingDetails'));

// tickets
const Tickets = React.lazy(() => import('./screens/account/tickets/Tickets'));
const Ticket = React.lazy(() => import('./screens/account/tickets/Ticket'));

// site admin
const General = React.lazy(() => import('./screens/account/sites/admin/General'));
const SEO = React.lazy(() => import('./screens/account/sites/admin/Seo'));
const Analytics = React.lazy(() => import('./screens/account/sites/admin/Analytics'));
const Domain = React.lazy(() => import('./screens/account/sites/admin/Domain'));
const Notification = React.lazy(() => import('./screens/account/sites/admin/NotificationsSetting'));

const Payment = React.lazy(() => import('./screens/account/sites/admin/Payment'));
const Delivery = React.lazy(() => import('./screens/account/sites/admin/Delivery'));

// site multi-language
const Language = React.lazy(() => import('./screens/account/sites/admin/Language'));
const SitePage = React.lazy(() => import('./screens/account/sites/admin/SitePage'));

// collection
const Collection = React.lazy(() => import('./screens/account/sites/admin/dynamic/Collection'));
const EditCollection = React.lazy(() => import('./screens/account/sites/admin/dynamic/EditCollection'));
const AddCollection = React.lazy(() => import('./screens/account/sites/admin/dynamic/AddCollection'));
const StaticCollection = React.lazy(() => import('./screens/account/sites/admin/static/Collection'));
const AddRecord = React.lazy(() => import('./screens/account/sites/admin/static/AddRecord'));
const EditRecord = React.lazy(() => import('./screens/account/sites/admin/static/EditRecord'));

// editor
const Editor = React.lazy(() => import('./screens/account/editor'));

// preview screen
const Preview = React.lazy(() => import('./screens/account/previewNew'));

// marketplace
const AllCategories = React.lazy(() => import('./screens/account/marketplace/allCategories'));
const MarketPlace = React.lazy(() => import('./screens/account/marketplace/marketplace'));
const Profile = React.lazy(() => import('./screens/account/marketplace/profile'));
const Chat = React.lazy(() => import('./screens/account/marketplace/chat'));
const SendContractOffer = React.lazy(() => import('./screens/account/marketplace/sendContractOffer'));
const MyContracts = React.lazy(() => import('./screens/account/marketplace/myContracts'));
const Contract = React.lazy(() => import('./screens/account/marketplace/contract'));

// Products
const AllProducts = React.lazy(() => import('./screens/account/sites/admin/product/all'));
const AddProduct = React.lazy(() => import('./screens/account/sites/admin/product/add'));

// coupon, global settings
const Coupan = React.lazy(() => import('./screens/account/sites/admin/Coupan.js'));
const GlobalSetting = React?.lazy(() => import('./screens/account/sites/admin/GlobalSetting.js'));

function RequireAuth({ children }) {
	const { user, setUser } = useContext(context);

	const [res, setRes] = useState(null);

	if (!user) {
		const fetchUser = async () => {
			const response = await AuthService.userVerify()
			setRes(response)
		};

		fetchUser();

		if (res?.message == "User not found please login") {
			if (process.env.REACT_APP_MODE == 'development') {
				window.location.href = '/guest/user/login';
			} else {
				window.location.href = `${process.env.REACT_APP_LANDING_PAGES_URL}/login`
			}
		} else {
			if (res?.id)
				setUser(res)
			// else return toast.error("Something went wrong")
		}

		return;
	}

	return children;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<ErrorBoundary
		FallbackComponent={ErrorFallback}
	>
		<ContextProvider>
			<BrowserRouter>
				<ParallaxProvider>

					{/* <WalkThrough /> */}
					<TopLoadingBar />
					<NotificationModal />
					<Routes>
						{/* <Routes>
						<Route path='/demo'
						element={
							<Demo />
							}
							/> */}





						{/* START - Auth redirects */}
						<Route path="/token/verify/:redirectToken" element={
							<React.Suspense fallback={<>...</>}>
								<Auth />
							</React.Suspense>
						} />
						<Route path="/session/delete" element={
							<React.Suspense fallback={<>...</>}>
								<Logout />
							</React.Suspense>
						} />
						{/* END - Auth redirects */}

						{/* login for local environment */}
						{
							process.env.REACT_APP_MODE == 'development' &&
							<>
								<Route path="/guest/user/register" element={
									<React.Suspense fallback={<>...</>}>
										<Register />
									</React.Suspense>
								} />
								<Route path="/guest/user/register-verify-otp" element={
									<React.Suspense fallback={<>...</>}>
										<RegisterVerifyOtp />
									</React.Suspense>
								} />
								<Route path="/guest/user/login" element={
									<React.Suspense fallback={<>...</>}>
										<Login />
									</React.Suspense>
								} />
							</>
						}

						{/* START - pricing */}
						<Route path="/" element={
							<RequireAuth>
								<React.Suspense fallback={<>...</>}>
									<PlanLayout />
								</React.Suspense>
							</RequireAuth>
						}>
							<Route path="/user/plans" element={
								<React.Suspense fallback={<>...</>}>
									<Plans />
								</React.Suspense>
							} />
						</Route>
						{/* END - pricing */}

						<Route path="/" element={
							<RequireAuth>
								<React.Suspense fallback={<>...</>}>
									<UserLayout />
								</React.Suspense>
							</RequireAuth>
						}>
							<Route index element={
								<React.Suspense fallback={<>...</>}>
									<User_Overview />
								</React.Suspense>
							} />
							<Route path="/user/sites" element={
								<React.Suspense fallback={<>...</>}>
									<SitesAll />
								</React.Suspense>
							} />

							{/* START - team */}
							<Route path="/user/team" element={
								<React.Suspense fallback={<>...</>}>
									<Team />
								</React.Suspense>
							} />
							<Route path="/user/team/roles" element={
								<React.Suspense fallback={<>...</>}>
									<RolePermission />
								</React.Suspense>
							} />
							{/* END - team */}

							{/* START - user right popover */}
							<Route path="/user/profile" element={
								<React.Suspense fallback={<>...</>}>
									<UserProfile />
								</React.Suspense>
							} />
							<Route path="/user/security" element={
								<React.Suspense fallback={<>...</>}>
									<UserAccountSecurity />
								</React.Suspense>
							} />
							<Route path="/user/notification" element={
								<React.Suspense fallback={<>...</>}>
									<UserNotificationPreferences />
								</React.Suspense>
							} />
							{/* END - user right popover */}

							{/* START - tickets */}
							<Route path="/user/tickets" element={
								<React.Suspense fallback={<>...</>}>
									<Tickets />
								</React.Suspense>
							} />
							<Route path="/user/new/tickets/:id" element={
								<React.Suspense fallback={<>...</>}>
									<Ticket />
								</React.Suspense>
							} />
							{/* END - tickets */}

							{/* START - billing */}
							<Route path="/user/subscriptions" element={
								<React.Suspense fallback={<>...</>}>
									<Subscriptions />
								</React.Suspense>
							} />
							<Route path="/user/billing" element={
								<React.Suspense fallback={<>...</>}>
									<Billing />
								</React.Suspense>
							} />
							<Route path="/user/billing/details" element={
								<React.Suspense fallback={<>...</>}>
									<BllingDetails />
								</React.Suspense>
							} />
							{/* END - billing */}

							<Route path="/user/settings" element={
								<React.Suspense fallback={<>...</>}>
									<Settings />
								</React.Suspense>
							} />

							{/* received user notifications */}
							<Route path="/user/all-notifications" element={
								<React.Suspense fallback={<>...</>}>
									<AllNotifications />
								</React.Suspense>
							} />

							{/* START - social login callbacks */}
							<Route path="/user/auth/google/connect/callback" element={
								<React.Suspense fallback={<>...</>}>
									<ConnectGoogleCallback />
								</React.Suspense>
							} />
							<Route path="/user/auth/facebook/connect/callback" element={
								<React.Suspense fallback={<>...</>}>
									<ConnectFacebookCallback />
								</React.Suspense>
							} />
							<Route path="my-contracts" element={
								<React.Suspense fallback={<>...</>}>
									<MyContracts />
								</React.Suspense>
							} />
							<Route path="contract/:id" element={
								<React.Suspense fallback={<>...</>}>
									<Contract />
								</React.Suspense>
							} />
						</Route>


						{/* START - marketplace */}

						<Route path="/marketplace/"
							element={
								// <RequireAuth>
								<React.Suspense fallback={<>...</>}>
									<MarketplaceLayout />
								</React.Suspense>
								// </RequireAuth>
							}
						>
							<Route path="profile/:id" element={
								<RequireAuth>
									<React.Suspense fallback={<>...</>}>
										<Profile />
									</React.Suspense>
								</RequireAuth>
							} />
							<Route path="partner/profile/:id" element={
								<React.Suspense fallback={<>...</>}>
									<Profile />
								</React.Suspense>
							} />
							<Route path="profiles/:role" element={
								<RequireAuth>
									<React.Suspense fallback={<>...</>}>
										<MarketPlace />
									</React.Suspense>
								</RequireAuth>
							} />
							<Route path="all-categories" element={
								<RequireAuth>
									<React.Suspense fallback={<>...</>}>
										<AllCategories />
									</React.Suspense>
								</RequireAuth>
							} />
							<Route path="send-contract-offer/:id" element={
								<RequireAuth>
									<React.Suspense fallback={<>...</>}>
										<SendContractOffer />
									</React.Suspense>
								</RequireAuth>
							} />
							<Route path="/marketplace/chat/:id?" element={
								<RequireAuth>
									<React.Suspense fallback={<>...</>}>
										<Chat />
									</React.Suspense>
								</RequireAuth>
							} />
						</Route>
						{/* END - marketplace */}


						<Route path="/user/editor/" element={
							<RequireAuth>
								<React.Suspense fallback={<>...</>}>
									<NewEditorLayout />
								</React.Suspense>
							</RequireAuth>
						}>
							<Route index path=":siteId/:pageName/:modalName?" element={
								<React.Suspense fallback={<>...</>}>
									<Editor />
								</React.Suspense>
							} />
						</Route>

						{/* <Route path="/user/new-editor/:siteId/:pageName" element={
							<RequireAuth>
								<React.Suspense fallback={<>...</>}>
									<Builder />
								</React.Suspense>
							</RequireAuth>
						}>
						</Route> */}

						{/* preview */}

						<Route path='/user/preview/:siteId/:pageName/:id?/:platform'
							element={
								<React.Suspense fallback={<>...</>}>

									<RequireAuth >
										<Preview />
									</RequireAuth>
								</React.Suspense>
							}
						>
						</Route>

						<Route path="/site/" element={
							<RequireAuth>
								<React.Suspense fallback={<>...</>}>
									<AdminLayout />
								</React.Suspense>
							</RequireAuth>
						}>
							<Route path="admin/:siteId/general" element={
								<React.Suspense fallback={<>...</>}>
									<General />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/seo" element={
								<React.Suspense fallback={<>...</>}>
									<SEO />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/domain" element={
								<React.Suspense fallback={<>...</>}>
									<Domain />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/analytics" element={
								<React.Suspense fallback={<>...</>}>
									<Analytics />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/notification" element={
								<React.Suspense fallback={<>...</>}>
									<Notification />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/coupon" element={
								<React.Suspense fallback={<>...</>}>
									<Coupan />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/setting" element={
								<React.Suspense fallback={<>...</>}>
									<GlobalSetting />
								</React.Suspense>
							} />

							{/* START - Products */}
							<Route path="admin/:siteId/products" element={
								<React.Suspense fallback={<>...</>}>
									<AllProducts />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/products/add" element={
								<React.Suspense fallback={<>...</>}>
									<AddProduct />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/products/:id?" element={
								<React.Suspense fallback={<>...</>}>
									<AddProduct />
								</React.Suspense>
							} />
							{/* END - Products */}

							<Route path="admin/:siteId/dashboard" element={
								<React.Suspense fallback={<>...</>}>
									<Dashboard />
								</React.Suspense>
							} />
							<Route
								path="admin/:siteId/mailchimp"
								element={
									<React.Suspense fallback={<>...</>}>
										<MailChimp />
									</React.Suspense>
								}
							/>
							<Route
								path="admin/:siteId/mailchimp/:type/:id?"
								element={
									<React.Suspense fallback={<>...</>}>
										<MailchimpUtil />
									</React.Suspense>
								}
							/>
							<Route path="admin/:siteId/payment" element={
								<React.Suspense fallback={<>...</>}>
									<Payment />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/delivery" element={
								<React.Suspense fallback={<>...</>}>
									<Delivery />
								</React.Suspense>
							} />

							{/* START - site multi-language */}
							<Route path="admin/:siteId/multi-language" element={
								<React.Suspense fallback={<> ... </>}>
									<Language />
								</React.Suspense>
							} />

							{/* <Route path="admin/:siteId/features" element={
								<React.Suspense fallback={<> ... </>}>
									<Features />
								</React.Suspense>
							} /> */}
							<Route path="admin/:siteId/site-content" element={
								<React.Suspense fallback={<> ... </>}>
									<SitePage />
								</React.Suspense>
							} />
							{/* END - site multi-language */}

							{/* START - strapi collection */}
							<Route path="admin/:siteId/collection/:pluralName/:singularName" element={
								<React.Suspense fallback={<>...</>}>
									<Collection />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/collection/:pluralName/:singularName/add" element={
								<React.Suspense fallback={<>...</>}>
									<AddCollection />
								</React.Suspense>
							} />
							<Route path="admin/:siteId/collection/:pluralName/:singularName/:recordId" element={
								<React.Suspense fallback={<>...</>}>
									<EditCollection />
								</React.Suspense>
							} />
							{/* END - strapi collection */}

							{/* START - sqlite collection */}
							<Route path="static/admin/:siteId/collection/:tableDisplayName/:tableName" element={
								<React.Suspense fallback={<>...</>}>
									<StaticCollection />
								</React.Suspense>
							} />
							<Route path="static/admin/:siteId/collection/:tableDisplayName/:tableName/add" element={
								<React.Suspense fallback={<>...</>}>
									<AddRecord />
								</React.Suspense>
							} />
							<Route path="static/admin/:siteId/collection/:tableDisplayName/:tableName/:recordId" element={
								<React.Suspense fallback={<>...</>}>
									<EditRecord />
								</React.Suspense>
							} />
							{/* END - sqlite collection */}

						</Route>
						{/* END - user site */}

						{/* START - create new site */}
						<Route path="/templates/:userPlanId" element={
							<React.Suspense fallback={<>...</>}>
								<Template />
							</React.Suspense>
						} />
						<Route path="/templates/:userPlanId/:templateId/pages" element={
							<React.Suspense fallback={<>...</>}>
								<TemplatePages />
							</React.Suspense>
						} />
						{/* END - create new site */}

					</Routes>

					<ToastContainer />
				</ParallaxProvider>
			</BrowserRouter>
		</ContextProvider >

	</ErrorBoundary >
);

reportWebVitals();