import { useEffect, useContext, useState, useCallback } from "react";
import { context } from "../../../context/Context";
import { Listbox, Popover } from "@headlessui/react";
import { ChevronUpDownIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router";
import { dbCall } from "../../../common/db";
const Events = () => {
	const [addedTasksToEvents, setAddedTasksEvents] = useState({});
	const [addedEvents, setAddedEvents] = useState([]);
	const [newEvent, setNewEvent] = useState(null);
	const [pages, setPages] = useState([]);
	const params = useParams();
	const eventsDisplayNames = {
		onClick: "On Click",
		onDoubleClick: "On Double Click",
		onContextMenu: "On Right Click",
		onMouseOver: "On Mouse Over",
		onMouseLeave: "On Mouse Leave",
		onChange: "On Change",
		onSubmit: "On Submit"
	};
	const allowedTasksCorrespondingToEvents = {
		"On Click": ["Show Alert", "Show Element", "Animate", "Hide Element", "Toggle Element", "Navigate"],
		"On Double Click": ["Show Alert", "Show Element", "Animate", "Hide Element", "Toggle Element"],
		"On Right Click": ["Show Alert", "Show Element", "Animate", "Hide Element", "Toggle Element"],
		"On Mouse Over": ["Show Alert", "Show Element", "Animate", "Hide Element"],
		"On Mouse Leave": ["Show Alert", "Show Element", "Animate", "Hide Element"]
	};

	const {
		elementId,
		currentPlatform,
		setWorkingOnEvent,
		Bdispatch
	} = useContext(context);
	useEffect(() => {
		let obj = {};
		let arr = [];
		for (let x in elementId?.events) {
			if (Array.isArray(elementId.events[x])) {
				for (let i = 0; i < elementId.events[x].length; i++) {
					if (obj[eventsDisplayNames[x]]) {
						obj[eventsDisplayNames[x]] = [...obj[eventsDisplayNames[x]], elementId.events[x][i].task]
					} else {
						obj[eventsDisplayNames[x]] = [elementId.events[x][i].task];
					}
					arr.push({ ...elementId.events[x][i], event: eventsDisplayNames[x] });
				}
			} else {
				obj[eventsDisplayNames[x]] = [elementId.events[x].task];
				arr.push({ ...elementId.events[x], event: eventsDisplayNames[x] });
			}
		}
		setAddedTasksEvents(obj);
		setAddedEvents(arr);
	}, [elementId])
	const getAllPagesData = async () => {
		try {
			const response = await dbCall.get(`/site/get-all-pages/${params.siteId}/${currentPlatform}`);
			const arr = [];
			for (let i = 0; i < response.data.pagesData.length; i++) {
				if (response.data.pagesData[i].staticRoute == true) {
					arr.push({
						pageName: response.data.pagesData[i].pageName,
						path: response.data.pagesData[i].path
					})
				}
			}
			setPages(arr);
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		getAllPagesData();
	}, []);

	const listenerFunction = useCallback(
		function animationFunc(e) {
			setNewEvent((prev) => ({
				...prev,
				data: {
					...prev.data,
					id: e.target.id
				}
			}));

		}, []);

	useEffect(() => {

		if (newEvent?.task && (newEvent.task == 'Animate' || newEvent.task == 'Show Element' || newEvent.task == 'Hide Element' || newEvent.task == 'Toggle Element' || newEvent.task == 'Navigate')) {
			document.getElementById('droparea').addEventListener('click', listenerFunction);
			setWorkingOnEvent(true);
		} else {
			setWorkingOnEvent(false);
			document.getElementById('droparea')?.removeEventListener('click', listenerFunction);

		}

		return () => {
			document.getElementById('droparea')?.removeEventListener('click', listenerFunction);

		}
	}, [newEvent?.task]);

	const saveEventToSchema = () => {
		try {
			let error = false;
			if (newEvent.task == 'Animate') {
				if (newEvent.data.id == '' || newEvent.data.id == null || newEvent.data.id == undefined) {
					setNewEvent((prev) => ({ ...prev, error: { ...prev.error, id: 'Select element from canvas' } }));
					error = true;
				} else {
					setNewEvent((prev) => ({ ...prev, error: { ...prev.error, id: null } }));
				}
				if (newEvent.data.name == '' || newEvent.data.name == null || newEvent.data.name == undefined) {
					setNewEvent((prev) => ({ ...prev, error: { ...prev.error, name: 'Select animation' } }));
					error = true;
				} else {
					setNewEvent((prev) => ({ ...prev, error: { ...prev.error, name: null } }));
				}

			} else if (newEvent.task == 'Show Element' || newEvent.task == 'Hide Element' || newEvent.task == 'Toggle Element') {
				if (newEvent.data.id == '' || newEvent.data.id == null || newEvent.data.id == undefined) {
					setNewEvent((prev) => {

						return {
							...prev,
							error: {
								...prev.error,
								id: 'Select element from canvas'
							}
						}
					});
					error = true;
				} else {
					setNewEvent((prev) => {

						return {
							...prev,
							error: {
								...prev.error,
								id: null
							}
						}
					});
				}
			} else if (newEvent.task == 'Show Alert') {
				if (newEvent.data.message == '' || newEvent.data.message == null || newEvent.data.message == undefined) {
					setNewEvent((prev) => {
						return {
							...prev,
							error: {
								...prev.error,
								message: 'Enter message'
							}
						}
					});
					error = true;
				} else {
					setNewEvent((prev) => {

						return {
							...prev,
							error: {
								...prev.error,
								message: null
							}
						}
					});
				}
			} else if (newEvent.task == 'Navigate') {
				if (newEvent.data.navigationType == '' || newEvent.data.navigationType == null || newEvent.data.navigationType == undefined) {
					setNewEvent((prev) => {

						return {
							...prev,
							error: {
								...prev.error,
								navigationType: 'Enter details'
							}
						}
					});
					error = true;

				} else {
					setNewEvent((prev) => {

						return {
							...prev,
							error: {
								...prev.error,
								navigationType: null
							}
						}
					});
				}
				if (newEvent.data.navigationType == 'Page') {
					if (newEvent.data.pageName == '' || newEvent.data.pageName == null || newEvent.data.pageName == undefined) {
						setNewEvent((prev) => {

							return {
								...prev,
								error: {
									...prev.error,
									pageName: 'Select Page'
								}
							}
						});
						error = true;
					} else {
						setNewEvent((prev) => {

							return {
								...prev,
								error: {
									...prev.error,
									pageName: null
								}
							}
						});
					}
				} else if (newEvent.data.navigationType == 'External Link') {
					if (newEvent.data.link == '' || newEvent.data.link == null || newEvent.data.link == undefined) {
						setNewEvent((prev) => {

							return {
								...prev,
								error: {
									...prev.error,
									link: 'Enter link'
								}
							}
						});
						error = true;
					} else {
						setNewEvent((prev) => {

							return {
								...prev,
								error: {
									...prev.error,
									link: null
								}
							}
						});
					}

				} else if (newEvent.data.navigationType == 'Section') {
					if (newEvent.data.id == '' || newEvent.data.id == null || newEvent.data.id == undefined) {
						setNewEvent((prev) => {

							return {
								...prev,
								error: {
									...prev.error,
									id: 'Select element from canvas'
								}
							}
						});
						error = true;
					} else {
						setNewEvent((prev) => {

							return {
								...prev,
								error: {
									...prev.error,
									id: null
								}
							}
						});
					}
				}
			}
			if (error == true) {
				return;
			}
			if (newEvent.type == 'add') {
				if (elementId.events && elementId.events[newEvent.event]) {
					setAddedTasksEvents((prev) => {

						return {
							...prev,
							[newEvent.event]: [...prev[newEvent.event], newEvent.task]
						}
					});
				} else {
					setAddedTasksEvents((prev) => {
						return {
							...prev,
							[newEvent.event]: [newEvent.task]
						}
					});
				}
				Bdispatch({ type: "ADD_EVENT", id: elementId.id, newEvent });
				setAddedEvents((prev) => {
					return [...prev, { task: newEvent.task, parameters: { ...newEvent.data }, event: newEvent.event }]
				});
			} else if (newEvent.type == 'edit') {
				Bdispatch({ type: "EDIT_EVENT", id: elementId.id, newEvent });
				setAddedEvents((prev) => {
					for (let i = 0; i < prev.length; i++) {
						if (prev[i].event == newEvent.event && prev[i].task == newEvent.task) {
							prev[i].parameters = {
								...newEvent.data
							}
							break;
						}
					}
					return prev;
				});
			}
			setNewEvent(null);
		} catch (error) {
			console.log(error);
		}
	};

	const deleteEventFromSchema = (event, task) => {
		try {
			Bdispatch({ type: "DELETE_EVENT", id: elementId.id, event, task })
			setAddedTasksEvents((prev) => {
				let index;
				for (let i = 0; i < prev[event].length; i++) {
					if (prev[event][i] == task) {
						index = i;
						break;
					}
				}
				prev[event].splice(index, 1)
				return prev;
			});
			setAddedEvents((prev) => {
				let index;
				for (let i = 0; i < prev.length; i++) {
					if (prev[i].event == event && prev[i].task == task) {
						index = i;
						break
					}
				}
				prev.splice(index, 1)
				return prev;
			})
			setNewEvent(null);
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<div className='p-4 h-full space-y-2 overflow-y-auto no-scrollbar text-sm'>
			<div className="relative w-full">
				<Popover className={'relative'}>
					<Popover.Button className={'w-full flex items-center justify-center space-x-3 px-4 py-2 rounded-md font-medium bg-indigo-600 text-white'}>
						<div>Add Event</div>
						<span class="mdi mdi-plus text-xl"></span>

					</Popover.Button>
					<Popover.Panel className={'absolute z-[51] top-12 rounded-md shadow w-full border border-gray-300 divide-y divide-gray-300 bg-white'}>
						{
							({ close }) => (
								["On Click", "On Double Click", "On Right Click", "On Mouse Over", "On Mouse Leave"].map((value, index) => {

									if (allowedTasksCorrespondingToEvents[value].map((val) => {
										if (addedTasksToEvents[value]) {
											return addedTasksToEvents[value].includes(val);
										} else {
											return false;
										}
									}).includes(false)) {

										return (
											<div className="p-2 w-full hover:bg-gray-100 cursor-pointer" key={index} onClick={() => {
												setNewEvent(() => {
													return {
														type: 'add',
														event: value,
														task: "",
														data: {},
														error: {}
													}
												});
												close();
											}}>{value}</div>
										)
									}

								})
							)
						}

					</Popover.Panel>
				</Popover>
				{
					(newEvent && newEvent.type == 'add') &&
					<div className='flex flex-col space-y-4 border border-gray-200 p-2 rounded-lg absolute z-10 bg-white mt-2 w-full'>

						<div className='flex items-center justify-between space-x-1'>

							<div className='text-sm font-medium text-gray-900'>{newEvent.event}</div>

							<Listbox value={newEvent.task} onChange={(value) => {
								setNewEvent((prev) => {

									return {
										...prev,
										task: value,
										data: {},
										error: {}
									}
								})
							}} >
								<div className="relative w-full max-w-[150px]">
									<Listbox.Button className="w-full cursor-pointer rounded-lg bg-white py-1.5 pl-3 pr-2 border border-gray-300 shadow flex items-center justify-between">
										<span className='capitalize'>{newEvent.task == '' ? 'Select Task' : newEvent.task}</span>
										<ChevronUpDownIcon
											className="h-5 w-5 text-gray-400"
										/>
									</Listbox.Button>
									<Listbox.Options
										className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300"
									>
										{
											allowedTasksCorrespondingToEvents[newEvent.event].map((value, index) => {
												if (!addedTasksToEvents[newEvent.event]?.includes(value)) {
													return (
														<Listbox.Option
															key={index}
															className={({ active }) =>
																`${active ? 'bg-gray-100' : ''} cursor-pointer`
															}
															value={value}
														>
															{({ selected }) => (
																<>
																	<div className={`py-1.5 px-3 text-gray-900 capitalize${selected ? " bg-indigo-100 font-medium" : ""}`}>{value}</div>
																</>
															)}
														</Listbox.Option>
													)
												}
											})
										}
									</Listbox.Options>
								</div>
							</Listbox>



						</div>
						{
							newEvent.task == 'Show Alert' &&
							<div>
								<div className='text-sm font-medium mb-2 text-gray-900'>Enter message to show in alert box <span>{newEvent.event.toLowerCase()}</span></div>

								<input type="text" placeholder='Enter message' className='w-full p-2 border border-gray-300 rounded text-sm focus:ring-indigo-600 text-gray-700' onChange={(e) => setNewEvent((prev) => ({
									...prev,
									data: {
										...prev.data,
										message: e.target.value
									}
								}
								))} />
								{
									newEvent.error.message &&
									<div className='text-sm text-red-600 mt-1'>*{newEvent.error.message}</div>
								}
							</div>

						}
						{
							(newEvent.task == 'Show Element' || newEvent.task == 'Hide Element' || newEvent.task == 'Toggle Element') &&
							<div>
								<div className='text-sm font-medium text-gray-900 mb-1'>Select Element on canvas which you want to show <span>{newEvent.event.toLowerCase()}</span></div>

								{
									newEvent.error.id &&
									<div className='text-sm text-red-600'>*{newEvent.error.id}</div>
								}
							</div>
						}
						{
							newEvent.task == 'Animate' &&
							<div>
								<div>

									<div className='text-sm font-medium text-gray-900'>Select Element on canvas which you want to animate <span>{newEvent.event.toLowerCase()}</span></div>
									{
										newEvent.error.id &&
										<div className='text-sm text-red-600 mt-1'>*{newEvent.error.id}</div>
									}
								</div>
								<div >

									<Listbox
										onChange={(value) => {
											setNewEvent((prev) => {

												return {
													...prev,
													data: {
														...prev.data,
														name: value.displayName,
														class: value.class
													},
													error: {
														...prev.error,
														name: null
													}
												}
											})
										}}
										as={'div'}
										className={'relative w-full'}
									>
										<Listbox.Button
											className="w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-2 border border-gray-300 shadow flex items-center justify-between"
										>
											<span>{(newEvent.data.name == "" || newEvent.data.name == null) ? 'Select Animation' : newEvent.data.name}</span>
											<ChevronUpDownIcon
												className="h-5 w-5 text-gray-400"
											/>
										</Listbox.Button>
										<Listbox.Options
											className="absolute z-[51] h-60 mt-1 w-full overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 flex flex-wrap"
										>

											{
												[
													{
														class: 'animate-wiggle',
														hoverClass: "group-hover:animate-wiggle",
														displayName: "Wiggle"
													},
													{
														class: 'animate-flash',
														hoverClass: "group-hover:animate-flash",
														displayName: "Flash"
													},
													{
														class: 'animate-fadein',
														hoverClass: "group-hover:animate-fadein",
														displayName: "Fade In"
													},
													{
														class: 'animate-fadeout',
														hoverClass: "group-hover:animate-fadeout",
														displayName: "Fade Out"
													},
													{
														class: 'animate-flip',
														hoverClass: "group-hover:animate-flip",
														displayName: "Flip"
													},
													{
														class: 'animate-backinleft',
														hoverClass: "group-hover:animate-backinleft",
														displayName: "Back In left"
													},
													{
														class: 'animate-bouncein',
														hoverClass: "group-hover:animate-bouncein",
														displayName: "Bouncein"
													},
													{
														class: 'animate-lightinspeedleft',
														hoverClass: "group-hover:animate-lightinspeedleft",
														displayName: "light In Speed left"
													},
													{
														class: 'animate-slideindown',
														hoverClass: "group-hover:animate-slideindown",
														displayName: "Slide In Down"
													},
													{
														class: 'animate-rotatein',
														hoverClass: "group-hover:animate-rotatein",
														displayName: "Rotate In"
													},
													{
														class: 'animate-slideindown',
														hoverClass: "group-hover:animate-slideindown",
														displayName: "slide In Down"
													},
													{
														class: 'animate-slideinleft',
														hoverClass: "group-hover:animate-slideinleft",
														displayName: "slide In left"
													},
													{
														class: 'animate-slideinup',
														hoverClass: "group-hover:animate-slideinup",
														displayName: "slide In Up"
													},
													{
														class: 'animate-slideoutdown',
														hoverClass: "group-hover:animate-slideoutdown",
														displayName: "slide In Down"
													}, {
														class: 'animate-slideinright',
														hoverClass: "group-hover:animate-slideinright",
														displayName: "slide In Right"
													}, {
														class: 'animate-slideoutleft',
														hoverClass: "group-hover:animate-slideoutleft",
														displayName: "slide Out Left"
													}, {
														class: 'animate-slideoutright',
														hoverClass: "group-hover:animate-slideoutright",
														displayName: "slide Out Right"
													}, {
														class: 'animate-slideoutup',
														hoverClass: "group-hover:animate-slideoutup",
														displayName: "slide Out Up"
													}, {
														class: 'animate-jackinthebox',
														hoverClass: "group-hover:animate-jackinthebox",
														displayName: "jack In The Box"
													}, {
														class: 'animate-rollout',
														hoverClass: "group-hover:animate-rollout",
														displayName: "Roll Out"
													}, {
														class: 'animate-rollin',
														hoverClass: "group-hover:animate-rollin",
														displayName: "Roll In"
													},
													{
														class: 'animate-zoomin',
														hoverClass: "group-hover:animate-zoomin",
														displayName: "Zoom In"
													},
													{
														class: 'animate-zoomindown',
														hoverClass: "group-hover:animate-zoomindown",
														displayName: "Zoom In Down"
													},
													{
														class: 'animate-zoominleft',
														hoverClass: "group-hover:animate-zoominleft",
														displayName: "Zoom In Left"
													},
													{
														class: 'animate-zoominright',
														hoverClass: "group-hover:animate-zoominright",
														displayName: "Zoom In Right"
													},
													{
														class: 'animate-zoominup',
														hoverClass: "group-hover:animate-zoominup",
														displayName: "Zoom In up"
													},
													{
														class: 'animate-zoomout',
														hoverClass: "group-hover:animate-zoomout",
														displayName: "Zoom Out"
													},
													{
														class: 'animate-zoomoutdown',
														hoverClass: "group-hover:animate-zoomoutdown",
														displayName: "Zoom Out Down"
													},
													{
														class: 'animate-zoomoutleft',
														hoverClass: "group-hover:animate-zoomoutleft",
														displayName: "Zoom Out Left"
													},
													{
														class: 'animate-zoomoutright',
														hoverClass: "group-hover:animate-zoomoutright",
														displayName: "Zoom Out Right"
													},
													{
														class: 'animate-zoomoutup',
														hoverClass: "group-hover:animate-zoomoutup",
														displayName: "Zoom Out Up"
													},
													{
														class: 'animate-rotateindownleft',
														hoverClass: "group-hover:animate-rotateindownleft",
														displayName: "Rotate In Down Left"
													},
													{
														class: 'animate-rotateindownright',
														hoverClass: "group-hover:animate-rotateindownright",
														displayName: "Rotate In Down Right"
													},
													{
														class: 'animate-rotateinupleft',
														hoverClass: "group-hover:animate-rotateinupleft",
														displayName: "Rotate In Up Left"
													},
													{
														class: 'animate-rotateinupright',
														hoverClass: "group-hover:animate-rotateinupright",
														displayName: "Rotate In Up Right"
													},
													{
														class: 'animate-rotateout',
														hoverClass: "group-hover:animate-rotateout",
														displayName: "Rotate Out"
													},
													{
														class: 'animate-rotateoutdownleft',
														hoverClass: "group-hover:animate-rotateoutdownleft",
														displayName: "Rotate Out Down Left"
													},
													{
														class: 'animate-rotateoutdownright',
														hoverClass: "group-hover:animate-rotateoutdownright",
														displayName: "Rotate Out Down Right"
													},
													{
														class: 'animate-rotateoutupleft',
														hoverClass: "group-hover:animate-rotateoutupleft",
														displayName: "Rotate Out Up Left"
													},
													{
														class: 'animate-rotateoutupright',
														hoverClass: "group-hover:animate-rotateoutupright",
														displayName: "Rotate Out Up Right"
													},
													{
														class: 'animate-lightspeedinright',
														hoverClass: "group-hover:animate-lightspeedinright",
														displayName: "Light Speed In Right"
													},
													{
														class: 'animate-lightspeedoutleft',
														hoverClass: "group-hover:animate-lightspeedoutleft",
														displayName: "Light Speed Out Left"
													},

													{
														class: 'animate-lightspeedoutright',
														hoverClass: "group-hover:animate-lightspeedoutright",
														displayName: "Light Speed Out Right"
													},
													{
														class: 'animate-flipinx',
														hoverClass: "group-hover:animate-flipinx",
														displayName: "Flip in X"
													},
													{
														class: 'animate-flipouty',
														hoverClass: "group-hover:animate-flipouty",
														displayName: "Flip Out Y"
													},
													{
														class: 'animate-flipiny',
														hoverClass: "group-hover:animate-flipiny",
														displayName: "Flip In Y"
													},
													{
														class: 'animate-fadeoutbottomleft',
														hoverClass: "group-hover:animate-fadeoutbottomleft",
														displayName: "Flip In Y"
													},
													{
														class: 'animate-fadeoutbottomright',
														hoverClass: "group-hover:animate-fadeoutbottomright",
														displayName: "Fade Out Bottom Right"
													},
													{
														class: 'animate-fadeoutdown',
														hoverClass: "group-hover:animate-fadeoutdown",
														displayName: "Fade Out Down"
													},
													{
														class: 'animate-hinge',
														hoverClass: "group-hover:animate-hinge",
														displayName: "Hinge"
													},
													{
														class: 'animate-fadeoutdownbig',
														hoverClass: "group-hover:animate-fadeoutdownbig",
														displayName: "Fade Out Down Big"
													},
													{
														class: 'animate-fadeoutleft',
														hoverClass: "group-hover:animate-fadeoutleft",
														displayName: "Fade Out Left"
													},
													{
														class: 'animate-fadeouttopright',
														hoverClass: "group-hover:animate-fadeouttopright",
														displayName: "Flip In Y"
													},
													{
														class: 'animate-fadeoutup',
														hoverClass: "group-hover:animate-fadeoutup",
														displayName: "Fade Out Up"
													},
													{
														class: 'animate-fadeoutupbig',
														hoverClass: "group-hover:animate-fadeoutupbig",
														displayName: "Fade Out Up Big"
													},
													{
														class: 'animate-fadeinbottomleft',
														hoverClass: "group-hover:animate-fadeinbottomleft",
														displayName: "Fade In Bottom left"
													},
													{
														class: 'animate-fadeinbottomright',
														hoverClass: "group-hover:animate-fadeinbottomright",
														displayName: "Fade In Bottom Right "
													},
													{
														class: 'animate-fadeindown',
														hoverClass: "group-hover:animate-fadeindown",
														displayName: "Fade In Down"
													},
													{
														class: 'animate-fadeindownbig',
														hoverClass: "group-hover:animate-fadeindownbig",
														displayName: "Fade In Down Big"
													},
													{
														class: 'animate-fadeinleft',
														hoverClass: "group-hover:animate-fadeinleft",
														displayName: "Fade In Left"
													},
													{
														class: 'animate-fadeinleftbig',
														hoverClass: "group-hover:animate-fadeinleftbig",
														displayName: "Fade In Left Big"
													},
													{
														class: 'animate-fadeinrightbig',
														hoverClass: "group-hover:animate-fadeinrightbig",
														displayName: "Fade In Right Big"
													},
													{
														class: 'animate-fadeintopleft',
														hoverClass: "group-hover:animate-fadeintopleft",
														displayName: "Fade In Top Left"
													},
													{
														class: 'animate-fadeinup',
														hoverClass: "group-hover:animate-fadeinup",
														displayName: "Fade In Up"
													},
													{
														class: 'animate-fadeinupbig',
														hoverClass: "group-hover:animate-fadeinupbig",
														displayName: "Fade In Up Big"
													},
													{
														class: 'animate-bounceindown',
														hoverClass: "group-hover:animate-bounceindown",
														displayName: "Bounce In Down"
													},

													{
														class: 'animate-bounceinleft',
														hoverClass: "group-hover:animate-bounceinleft",
														displayName: "Bounce In Left"
													},
													{
														class: 'animate-bounceinright',
														hoverClass: "group-hover:animate-bounceinright",
														displayName: "Bunce In Right"
													},
													{
														class: 'animate-bounceinup',
														hoverClass: "group-hover:animate-bounceinup",
														displayName: "Bounce In Up"
													},
													{
														class: 'animate-bounceout',
														hoverClass: "group-hover:animate-bounceout",
														displayName: "Bounce Out"
													},
													{
														class: 'animate-bounceoutdown',
														hoverClass: "group-hover:animate-bounceoutdown",
														displayName: "Bounce Out Down"
													},

													{
														class: 'animate-bounceoutleft',
														hoverClass: "group-hover:animate-bounceoutleft",
														displayName: "Bounce Out Left"
													},
													{
														class: 'animate-bounceoutright',
														hoverClass: "group-hover:animate-bounceoutright",
														displayName: "Bounce Out Right"
													},
													{
														class: 'animate-bounceoutup',
														hoverClass: "group-hover:animate-bounceoutup",
														displayName: "Bounce Out Up"
													},
													{
														class: 'animate-backindown',
														hoverClass: "group-hover:animate-backindown",
														displayName: "Back In Down"
													},
													{
														class: 'animate-backinright',
														hoverClass: "group-hover:animate-backinright",
														displayName: "Back In Right"
													},
													{
														class: 'animate-backinup',
														hoverClass: "group-hover:animate-backinup",
														displayName: "Back In Up"
													},
													{
														class: 'animate-backoutright',
														hoverClass: "group-hover:animate-backoutright",
														displayName: "Back Out Right"
													},
													{
														class: 'animate-backoutup',
														hoverClass: "group-hover:animate-backoutup",
														displayName: "Back Out Up"
													},
													{
														class: 'animate-spin',
														hoverClass: "group-hover:animate-spin",
														displayName: "Spin"
													},
													{
														class: 'animate-bounce',
														hoverClass: "group-hover:animate-bounce",
														displayName: "Bounce"
													},
													{
														class: 'animate-pulse',
														hoverClass: "group-hover:animate-pulse",
														displayName: "Pulse"
													},
													{
														class: 'animate-ping',
														hoverClass: "group-hover:animate-ping",
														displayName: "Ping"
													},
												].map((value, index) => {
													return (
														<Listbox.Option
															key={index}
															className={({ active }) =>
																`${active ? 'bg-gray-100' : ''} cursor-pointer w-1/2 p-1 flex flex-col items-center group  text-gray-900 border-gray-300 ${index % 2 == 0 ? 'border-b border-r' : "border-b"}`
															}
															value={value}
														>
															<div className={`p-2 overflow-hidden`}>
																<img src='/animation.jpeg' className={`w-10 h-10 ${value.hoverClass}`} />
															</div>
															<div className="text-center">{value.displayName}</div>
														</Listbox.Option>

													)
												})
											}
										</Listbox.Options>
									</Listbox>
									{
										newEvent.error.name &&
										<div className='text-sm text-red-600'>*{newEvent.error.name}</div>
									}
								</div>
							</div>
						}
						{
							newEvent.task == 'Navigate' &&
							<>
								<div>

									<div className='flex items-center justify-center'>
										<div className='text-sm font-medium text-gray-900'>Where you want to navigate</div>
										<Listbox value={newEvent?.data?.navigationType} onChange={(value) => {
											setNewEvent((prev) => {

												return {
													...prev,
													data: {
														...prev.data,
														navigationType: value
													}
												}
											})
										}} >
											<div className="relative w-full max-w-[150px]">
												<Listbox.Button className="w-full cursor-pointer rounded-lg bg-white py-1.5 pl-3 pr-2 border border-gray-300 shadow flex items-center justify-between">
													<span className='capitalize'>{(newEvent.data.navigationType == '' || newEvent.data.navigationType == null || newEvent.data.navigationType == undefined) ? 'Select Type' : newEvent.data.navigationType}</span>
													<ChevronUpDownIcon
														className="h-5 w-5 text-gray-400"
													/>
												</Listbox.Button>
												<Listbox.Options
													className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300"
												>
													{
														['Page', 'External Link', 'Top Of Page', 'Bottom Of Page', 'Section', 'Go Back'].map((value, index) => {
															return (
																<Listbox.Option
																	key={index}
																	className={({ active }) =>
																		`${active ? 'bg-gray-100' : ''} cursor-pointer`
																	}
																	value={value}
																>
																	{({ selected }) => (
																		<>
																			<div className={`py-1.5 px-3 text-gray-900 capitalize${selected ? " bg-indigo-100 font-medium" : ""}`}>{value}</div>
																		</>
																	)}
																</Listbox.Option>
															)
														})
													}
												</Listbox.Options>
											</div>
										</Listbox>
									</div>
									{
										newEvent.error.navigationType &&
										<div className='text-sm text-red-600 mt-1'>*{newEvent.error.navigationType}</div>
									}
								</div>

								{
									newEvent.data.navigationType == 'Page' &&
									<>
										<div>

											<div className='flex justify-between items-center'>
												<div className='text-sm font-medium text-gray-900'>Select Page</div>
												<Listbox onChange={(value) => {
													setNewEvent((prev) => {

														return {
															...prev,
															data: {
																...prev.data,
																pageName: value.pageName,
																path: value.path
															}
														}
													})
												}} >
													<div className="relative w-full max-w-[150px]">
														<Listbox.Button className="w-full cursor-pointer rounded-lg bg-white py-1.5 pl-3 pr-2 border border-gray-300 shadow flex items-center justify-between">
															<span className='capitalize'>{(newEvent.data.pageName == '' || newEvent.data.pageName == null || newEvent.data.pageName == undefined) ? 'Select Type' : newEvent.data.pageName}</span>
															<ChevronUpDownIcon
																className="h-5 w-5 text-gray-400"
															/>
														</Listbox.Button>
														<Listbox.Options
															className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300"
														>
															{
																pages.map((value, index) => {
																	return (
																		<Listbox.Option
																			key={index}
																			className={({ active }) =>
																				`${active ? 'bg-gray-100' : ''} cursor-pointer`
																			}
																			value={value}
																		>
																			{({ selected }) => (
																				<>
																					<div className={`py-1.5 px-3 text-gray-900 capitalize${selected ? " bg-indigo-100 font-medium" : ""}`}>{value.pageName}</div>
																				</>
																			)}
																		</Listbox.Option>
																	)
																})
															}
														</Listbox.Options>
													</div>
												</Listbox>

											</div>
											{
												newEvent.error.pageName &&
												<div className='text-sm text-red-600 mt-1'>*{newEvent.error.pageName}</div>
											}
										</div>

										<div className='flex items-center justify-between'>
											<div className='text-sm font-medium text-gray-900'>Open in new tab</div>
											<input type="checkbox" onChange={(e) => {
												setNewEvent((prev) => ({
													...prev, data: {
														...prev.data,
														newTab: e.target.checked
													}
												}));
											}} className='text-indigo-600 focus:ring-0 rounded w-5 h-5 cursor-pointer' />

										</div>
									</>
								}
								{
									newEvent.data.navigationType == 'External Link' &&
									<>
										<div className='text-sm font-medium text-gray-900 mb-1'>Enter Link</div>
										<div className='w-full'>

											<input type="text" placeholder='https://www.example.com' className='p-1 text-sm text-gray-900 w-full border border-gray-300 rounded focus:ring-indigo-600' onChange={(e) => setNewEvent((prev) => ({
												...prev, data: {
													...prev.data, link: e.target.value
												}
											}))} />
										</div>
										{
											newEvent.error.link &&
											<div className='text-sm text-red-600 mt-1'>*{newEvent.error.link}</div>
										}
										<div className='flex items-center justify-between '>
											<div className='text-sm font-medium text-gray-900'>Open in new tab:</div>
											<input type="checkbox" onChange={(e) => {
												setNewEvent((prev) => ({
													...prev, data: {
														...prev.data,
														newTab: e.target.checked
													}
												}));
											}} className='text-indigo-600 focus:ring-0 rounded cursor-pointer w-5 h-5 ' />

										</div>
									</>
								}
								{
									newEvent.data.navigationType == 'Section' &&
									<div>
										<div className='text-sm font-medium text-gray-900'>Select element on canvas to which you want to navigate {newEvent.event.toLowerCase()}</div>
										{
											newEvent.error.id &&
											<div className='text-sm text-red-600 mt-1'>*{newEvent.error.id}</div>
										}
									</div>
								}

							</>
						}
						<div className={`flex items-center ${newEvent.task == '' ? 'justify-end' : 'justify-between'}`}>
							{
								newEvent.task != '' &&
								<button
									type="button"
									className='text-white bg-indigo-600 font-medium rounded-lg px-3 p-2 text-sm border border-indigo-600'
									onClick={() => saveEventToSchema()}
								>
									Save
								</button>
							}
							<button
								type="button"
								className='text-indigo-600 bg-white border border-indigo-600 px-3 p-2 text-sm rounded-lg font-medium'
								onClick={() => setNewEvent(null)}
							>
								Cancel
							</button>
						</div>
					</div>

				}
			</div>

			{
				addedEvents.map((value, index) => {
					if (allowedTasksCorrespondingToEvents[value.event].includes(value.task)) {
						return (
							<div className="relative">
								<div className='flex items-center justify-between p-3 bg-white hover:bg-gray-100/90 group cursor-pointer border border-gray-300 rounded-lg space-x-1' key={index}
									onClick={() => {
										if (newEvent && newEvent.selectedEventIndex == index) {

											setNewEvent(null);
										} else {
											setNewEvent(() => {
												return {
													type: 'edit',
													event: value.event,
													task: value.task,
													data: {
														...value.parameters
													},
													error: {},
													selectedEventIndex: index
												}
											});
										}

									}}
								>

									<div className='text-sm font-medium text-gray-900'>{value.event}</div>
									<div className='flex items-center space-x-2 justify-between'>

										<span className="inline-flex items-center rounded-md bg-gray-200/60 px-2 py-1  text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/30">
											{value.task}
										</span>

									</div>

								</div>
								{
									(newEvent && newEvent.selectedEventIndex == index) &&
									<div className='flex flex-col space-y-4 border border-gray-200 p-2 rounded-lg absolute z-10 bg-white mt-2'>
										{
											newEvent.task == 'Show Alert' &&
											<div>
												<div className='text-sm font-medium mb-2 text-gray-900'>Enter message to show in alert box <span>{newEvent.event.toLowerCase()}</span></div>

												<input type="text" placeholder='Enter message' className='w-full p-2 border border-gray-300 rounded text-sm focus:ring-indigo-600 text-gray-700' onChange={(e) => setNewEvent((prev) => ({
													...prev,
													data: {
														...prev.data,
														message: e.target.value
													}
												}
												))} value={newEvent?.data?.message} />
												{
													newEvent.error.message &&
													<div className='text-sm text-red-600 mt-1'>*{newEvent.error.message}</div>
												}
											</div>

										}
										{
											(newEvent.task == 'Show Element' || newEvent.task == 'Hide Element' || newEvent.task == 'Toggle Element') &&
											<div>
												<div className='text-sm font-medium text-gray-900 mb-1'>Select Element on canvas which you want to show <span>{newEvent.event.toLowerCase()}</span></div>

												{
													newEvent.error.id &&
													<div className='text-sm text-red-600'>*{newEvent.error.id}</div>
												}
											</div>
										}
										{
											newEvent.task == 'Animate' &&
											<div>
												<div>

													<div className='text-sm font-medium text-gray-900'>Select Element on canvas which you want to animate <span>{newEvent.event.toLowerCase()}</span></div>
													{
														newEvent.error.id &&
														<div className='text-sm text-red-600 mt-1'>*{newEvent.error.id}</div>
													}
												</div>
												<div >

													<Listbox
														onChange={(value) => {
															setNewEvent((prev) => {

																return {
																	...prev,
																	data: {
																		...prev.data,
																		name: value.displayName,
																		class: value.class
																	},
																	error: {
																		...prev.error,
																		name: null
																	}
																}
															})
														}}
														as={'div'}
														className={'relative w-full'}
													>
														<Listbox.Button
															className="w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-2 border border-gray-300 shadow flex items-center justify-between"
														>
															<span>{(newEvent.data.name == "" || newEvent.data.name == null) ? 'Select Animation' : newEvent.data.name}</span>
															<ChevronUpDownIcon
																className="h-5 w-5 text-gray-400"
															/>
														</Listbox.Button>
														<Listbox.Options
															className="absolute z-[51] h-60 mt-1 w-full overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 flex flex-wrap"
														>

															{
																[

																	{
																		class: 'animate-wiggle',
																		hoverClass: "group-hover:animate-wiggle",
																		displayName: "Wiggle"
																	},
																	{
																		class: 'animate-flash',
																		hoverClass: "group-hover:animate-flash",
																		displayName: "Flash"
																	},

																	{
																		class: 'animate-fadein',
																		hoverClass: "group-hover:animate-fadein",
																		displayName: "Fade In"
																	},
																	{
																		class: 'animate-fadeout',
																		hoverClass: "group-hover:animate-fadeout",
																		displayName: "Fade Out"
																	},
																	{
																		class: 'animate-flip',
																		hoverClass: "group-hover:animate-flip",
																		displayName: "Flip"
																	},
																	{
																		class: 'animate-backinleft',
																		hoverClass: "group-hover:animate-backinleft",
																		displayName: "Back In left"
																	},
																	{
																		class: 'animate-bouncein',
																		hoverClass: "group-hover:animate-bouncein",
																		displayName: "Bouncein"
																	},
																	{
																		class: 'animate-lightinspeedleft',
																		hoverClass: "group-hover:animate-lightinspeedleft",
																		displayName: "light In Speed left"
																	},
																	{
																		class: 'animate-slideindown',
																		hoverClass: "group-hover:animate-slideindown",
																		displayName: "Slide In Down"
																	},
																	{
																		class: 'animate-rotatein',
																		hoverClass: "group-hover:animate-rotatein",
																		displayName: "Rotate In"
																	},
																	{
																		class: 'animate-slideindown',
																		hoverClass: "group-hover:animate-slideindown",
																		displayName: "slide In Down"
																	},
																	{
																		class: 'animate-slideinleft',
																		hoverClass: "group-hover:animate-slideinleft",
																		displayName: "slide In left"
																	},
																	{
																		class: 'animate-slideinup',
																		hoverClass: "group-hover:animate-slideinup",
																		displayName: "slide In Up"
																	},
																	{
																		class: 'animate-slideoutdown',
																		hoverClass: "group-hover:animate-slideoutdown",
																		displayName: "slide In Down"
																	}, {
																		class: 'animate-slideinright',
																		hoverClass: "group-hover:animate-slideinright",
																		displayName: "slide In Right"
																	}, {
																		class: 'animate-slideoutleft',
																		hoverClass: "group-hover:animate-slideoutleft",
																		displayName: "slide Out Left"
																	}, {
																		class: 'animate-slideoutright',
																		hoverClass: "group-hover:animate-slideoutright",
																		displayName: "slide Out Right"
																	}, {
																		class: 'animate-slideoutup',
																		hoverClass: "group-hover:animate-slideoutup",
																		displayName: "slide Out Up"
																	}, {
																		class: 'animate-jackinthebox',
																		hoverClass: "group-hover:animate-jackinthebox",
																		displayName: "jack In The Box"
																	}, {
																		class: 'animate-rollout',
																		hoverClass: "group-hover:animate-rollout",
																		displayName: "Roll Out"
																	}, {
																		class: 'animate-rollin',
																		hoverClass: "group-hover:animate-rollin",
																		displayName: "Roll In"
																	},
																	{
																		class: 'animate-zoomin',
																		hoverClass: "group-hover:animate-zoomin",
																		displayName: "Zoom In"
																	},
																	{
																		class: 'animate-zoomindown',
																		hoverClass: "group-hover:animate-zoomindown",
																		displayName: "Zoom In Down"
																	},
																	{
																		class: 'animate-zoominleft',
																		hoverClass: "group-hover:animate-zoominleft",
																		displayName: "Zoom In Left"
																	},
																	{
																		class: 'animate-zoominright',
																		hoverClass: "group-hover:animate-zoominright",
																		displayName: "Zoom In Right"
																	},
																	{
																		class: 'animate-zoominup',
																		hoverClass: "group-hover:animate-zoominup",
																		displayName: "Zoom In up"
																	},
																	{
																		class: 'animate-zoomout',
																		hoverClass: "group-hover:animate-zoomout",
																		displayName: "Zoom Out"
																	},
																	{
																		class: 'animate-zoomoutdown',
																		hoverClass: "group-hover:animate-zoomoutdown",
																		displayName: "Zoom Out Down"
																	},
																	{
																		class: 'animate-zoomoutleft',
																		hoverClass: "group-hover:animate-zoomoutleft",
																		displayName: "Zoom Out Left"
																	},
																	{
																		class: 'animate-zoomoutright',
																		hoverClass: "group-hover:animate-zoomoutright",
																		displayName: "Zoom Out Right"
																	},
																	{
																		class: 'animate-zoomoutup',
																		hoverClass: "group-hover:animate-zoomoutup",
																		displayName: "Zoom Out Up"
																	},
																	{
																		class: 'animate-rotateindownleft',
																		hoverClass: "group-hover:animate-rotateindownleft",
																		displayName: "Rotate In Down Left"
																	},
																	{
																		class: 'animate-rotateindownright',
																		hoverClass: "group-hover:animate-rotateindownright",
																		displayName: "Rotate In Down Right"
																	},
																	{
																		class: 'animate-rotateinupleft',
																		hoverClass: "group-hover:animate-rotateinupleft",
																		displayName: "Rotate In Up Left"
																	},
																	{
																		class: 'animate-rotateinupright',
																		hoverClass: "group-hover:animate-rotateinupright",
																		displayName: "Rotate In Up Right"
																	},
																	{
																		class: 'animate-rotateout',
																		hoverClass: "group-hover:animate-rotateout",
																		displayName: "Rotate Out"
																	},
																	{
																		class: 'animate-rotateoutdownleft',
																		hoverClass: "group-hover:animate-rotateoutdownleft",
																		displayName: "Rotate Out Down Left"
																	},
																	{
																		class: 'animate-rotateoutdownright',
																		hoverClass: "group-hover:animate-rotateoutdownright",
																		displayName: "Rotate Out Down Right"
																	},
																	{
																		class: 'animate-rotateoutupleft',
																		hoverClass: "group-hover:animate-rotateoutupleft",
																		displayName: "Rotate Out Up Left"
																	},
																	{
																		class: 'animate-rotateoutupright',
																		hoverClass: "group-hover:animate-rotateoutupright",
																		displayName: "Rotate Out Up Right"
																	},
																	{
																		class: 'animate-lightspeedinright',
																		hoverClass: "group-hover:animate-lightspeedinright",
																		displayName: "Light Speed In Right"
																	},
																	{
																		class: 'animate-lightspeedoutleft',
																		hoverClass: "group-hover:animate-lightspeedoutleft",
																		displayName: "Light Speed Out Left"
																	},

																	{
																		class: 'animate-lightspeedoutright',
																		hoverClass: "group-hover:animate-lightspeedoutright",
																		displayName: "Light Speed Out Right"
																	},
																	{
																		class: 'animate-flipinx',
																		hoverClass: "group-hover:animate-flipinx",
																		displayName: "Flip in X"
																	},
																	{
																		class: 'animate-flipouty',
																		hoverClass: "group-hover:animate-flipouty",
																		displayName: "Flip Out Y"
																	},
																	{
																		class: 'animate-flipiny',
																		hoverClass: "group-hover:animate-flipiny",
																		displayName: "Flip In Y"
																	},
																	{
																		class: 'animate-fadeoutbottomleft',
																		hoverClass: "group-hover:animate-fadeoutbottomleft",
																		displayName: "Flip In Y"
																	},
																	{
																		class: 'animate-fadeoutbottomright',
																		hoverClass: "group-hover:animate-fadeoutbottomright",
																		displayName: "Fade Out Bottom Right"
																	},
																	{
																		class: 'animate-fadeoutdown',
																		hoverClass: "group-hover:animate-fadeoutdown",
																		displayName: "Fade Out Down"
																	},
																	{
																		class: 'animate-hinge',
																		hoverClass: "group-hover:animate-hinge",
																		displayName: "Hinge"
																	},
																	{
																		class: 'animate-fadeoutdownbig',
																		hoverClass: "group-hover:animate-fadeoutdownbig",
																		displayName: "Fade Out Down Big"
																	},
																	{
																		class: 'animate-fadeoutleft',
																		hoverClass: "group-hover:animate-fadeoutleft",
																		displayName: "Fade Out Left"
																	},
																	{
																		class: 'animate-fadeouttopright',
																		hoverClass: "group-hover:animate-fadeouttopright",
																		displayName: "Flip In Y"
																	},
																	{
																		class: 'animate-fadeoutup',
																		hoverClass: "group-hover:animate-fadeoutup",
																		displayName: "Fade Out Up"
																	},
																	{
																		class: 'animate-fadeoutupbig',
																		hoverClass: "group-hover:animate-fadeoutupbig",
																		displayName: "Fade Out Up Big"
																	},
																	{
																		class: 'animate-fadeinbottomleft',
																		hoverClass: "group-hover:animate-fadeinbottomleft",
																		displayName: "Fade In Bottom left"
																	},
																	{
																		class: 'animate-fadeinbottomright',
																		hoverClass: "group-hover:animate-fadeinbottomright",
																		displayName: "Fade In Bottom Right "
																	},
																	{
																		class: 'animate-fadeindown',
																		hoverClass: "group-hover:animate-fadeindown",
																		displayName: "Fade In Down"
																	},
																	{
																		class: 'animate-fadeindownbig',
																		hoverClass: "group-hover:animate-fadeindownbig",
																		displayName: "Fade In Down Big"
																	},
																	{
																		class: 'animate-fadeinleft',
																		hoverClass: "group-hover:animate-fadeinleft",
																		displayName: "Fade In Left"
																	},
																	{
																		class: 'animate-fadeinleftbig',
																		hoverClass: "group-hover:animate-fadeinleftbig",
																		displayName: "Fade In Left Big"
																	},
																	{
																		class: 'animate-fadeinrightbig',
																		hoverClass: "group-hover:animate-fadeinrightbig",
																		displayName: "Fade In Right Big"
																	},
																	{
																		class: 'animate-fadeintopleft',
																		hoverClass: "group-hover:animate-fadeintopleft",
																		displayName: "Fade In Top Left"
																	},
																	{
																		class: 'animate-fadeinup',
																		hoverClass: "group-hover:animate-fadeinup",
																		displayName: "Fade In Up"
																	},
																	{
																		class: 'animate-fadeinupbig',
																		hoverClass: "group-hover:animate-fadeinupbig",
																		displayName: "Fade In Up Big"
																	},
																	{
																		class: 'animate-bounceindown',
																		hoverClass: "group-hover:animate-bounceindown",
																		displayName: "Bounce In Down"
																	},

																	{
																		class: 'animate-bounceinleft',
																		hoverClass: "group-hover:animate-bounceinleft",
																		displayName: "Bounce In Left"
																	},
																	{
																		class: 'animate-bounceinright',
																		hoverClass: "group-hover:animate-bounceinright",
																		displayName: "Bunce In Right"
																	},
																	{
																		class: 'animate-bounceinup',
																		hoverClass: "group-hover:animate-bounceinup",
																		displayName: "Bounce In Up"
																	},
																	{
																		class: 'animate-bounceout',
																		hoverClass: "group-hover:animate-bounceout",
																		displayName: "Bounce Out"
																	},
																	{
																		class: 'animate-bounceoutdown',
																		hoverClass: "group-hover:animate-bounceoutdown",
																		displayName: "Bounce Out Down"
																	},

																	{
																		class: 'animate-bounceoutleft',
																		hoverClass: "group-hover:animate-bounceoutleft",
																		displayName: "Bounce Out Left"
																	},
																	{
																		class: 'animate-bounceoutright',
																		hoverClass: "group-hover:animate-bounceoutright",
																		displayName: "Bounce Out Right"
																	},
																	{
																		class: 'animate-bounceoutup',
																		hoverClass: "group-hover:animate-bounceoutup",
																		displayName: "Bounce Out Up"
																	},
																	{
																		class: 'animate-backindown',
																		hoverClass: "group-hover:animate-backindown",
																		displayName: "Back In Down"
																	},
																	{
																		class: 'animate-backinright',
																		hoverClass: "group-hover:animate-backinright",
																		displayName: "Back In Right"
																	},
																	{
																		class: 'animate-backinup',
																		hoverClass: "group-hover:animate-backinup",
																		displayName: "Back In Up"
																	},
																	{
																		class: 'animate-backoutright',
																		hoverClass: "group-hover:animate-backoutright",
																		displayName: "Back Out Right"
																	},
																	{
																		class: 'animate-backoutup',
																		hoverClass: "group-hover:animate-backoutup",
																		displayName: "Back Out Up"
																	},
																	{
																		class: 'animate-spin',
																		hoverClass: "group-hover:animate-spin",
																		displayName: "Spin"
																	},
																	{
																		class: 'animate-bounce',
																		hoverClass: "group-hover:animate-bounce",
																		displayName: "Bounce"
																	},
																	{
																		class: 'animate-pulse',
																		hoverClass: "group-hover:animate-pulse",
																		displayName: "Pulse"
																	},
																	{
																		class: 'animate-ping',
																		hoverClass: "group-hover:animate-ping",
																		displayName: "Ping"
																	},
																].map((value, index) => {
																	return (
																		<Listbox.Option
																			key={index}
																			className={({ active }) =>
																				`${active ? 'bg-gray-100' : ''} cursor-pointer w-1/2 p-1 flex flex-col items-center group  text-gray-900 border-gray-300 ${index % 2 == 0 ? 'border-b border-r' : "border-b"}`
																			}
																			value={value}
																		>
																			<div className={`p-2 overflow-hidden`}>
																				<img src='/animation.jpeg' className={`w-10 h-10 ${value.hoverClass}`} />
																			</div>
																			<div className="text-center">{value.displayName}</div>
																		</Listbox.Option>

																	)
																})
															}
														</Listbox.Options>
													</Listbox>
													{
														newEvent.error.name &&
														<div className='text-sm text-red-600'>*{newEvent.error.name}</div>
													}
												</div>
											</div>
										}
										{
											newEvent.task == 'Navigate' &&
											<>
												<div>

													<div className='flex items-center justify-center'>
														<div className='text-sm font-medium text-gray-900'>Where you want to navigate</div>
														<Listbox value={newEvent?.data?.navigationType} onChange={(value) => {
															setNewEvent((prev) => {

																return {
																	...prev,
																	data: {
																		...prev.data,
																		navigationType: value
																	}
																}
															})
														}} >
															<div className="relative w-full max-w-[150px]">
																<Listbox.Button className="w-full cursor-pointer rounded-lg bg-white py-1.5 pl-3 pr-2 border border-gray-300 shadow flex items-center justify-between">
																	<span className='capitalize'>{(newEvent.data.navigationType == '' || newEvent.data.navigationType == null || newEvent.data.navigationType == undefined) ? 'Select Type' : newEvent.data.navigationType}</span>
																	<ChevronUpDownIcon
																		className="h-5 w-5 text-gray-400"
																	/>
																</Listbox.Button>
																<Listbox.Options
																	className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300"
																>
																	{
																		['Page', 'External Link', 'Top Of Page', 'Bottom Of Page', 'Section', 'Go Back'].map((value, index) => {
																			return (
																				<Listbox.Option
																					key={index}
																					className={({ active }) =>
																						`${active ? 'bg-gray-100' : ''} cursor-pointer`
																					}
																					value={value}
																				>
																					{({ selected }) => (
																						<>
																							<div className={`py-1.5 px-3 text-gray-900 capitalize${selected ? " bg-indigo-100 font-medium" : ""}`}>{value}</div>
																						</>
																					)}
																				</Listbox.Option>
																			)
																		})
																	}
																</Listbox.Options>
															</div>
														</Listbox>
													</div>
													{
														newEvent.error.navigationType &&
														<div className='text-sm text-red-600 mt-1'>*{newEvent.error.navigationType}</div>
													}
												</div>

												{
													newEvent.data.navigationType == 'Page' &&
													<>
														<div>

															<div className='flex justify-between items-center'>
																<div className='text-sm font-medium text-gray-900'>Select Page</div>
																<Listbox onChange={(value) => {
																	setNewEvent((prev) => {

																		return {
																			...prev,
																			data: {
																				...prev.data,
																				pageName: value.pageName,
																				path: value.path
																			}
																		}
																	})
																}} >
																	<div className="relative w-full max-w-[150px]">
																		<Listbox.Button className="w-full cursor-pointer rounded-lg bg-white py-1.5 pl-3 pr-2 border border-gray-300 shadow flex items-center justify-between">
																			<span className='capitalize'>{(newEvent.data.pageName == '' || newEvent.data.pageName == null || newEvent.data.pageName == undefined) ? 'Select Type' : newEvent.data.pageName}</span>
																			<ChevronUpDownIcon
																				className="h-5 w-5 text-gray-400"
																			/>
																		</Listbox.Button>
																		<Listbox.Options
																			className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300"
																		>
																			{
																				pages.map((value, index) => {
																					return (
																						<Listbox.Option
																							key={index}
																							className={({ active }) =>
																								`${active ? 'bg-gray-100' : ''} cursor-pointer`
																							}
																							value={value}
																						>
																							{({ selected }) => (
																								<>
																									<div className={`py-1.5 px-3 text-gray-900 capitalize${selected ? " bg-indigo-100 font-medium" : ""}`}>{value.pageName}</div>
																								</>
																							)}
																						</Listbox.Option>
																					)
																				})
																			}
																		</Listbox.Options>
																	</div>
																</Listbox>

															</div>
															{
																newEvent.error.pageName &&
																<div className='text-sm text-red-600 mt-1'>*{newEvent.error.pageName}</div>
															}
														</div>

														<div className='flex items-center justify-between'>
															<div className='text-sm font-medium text-gray-900'>Open in new tab</div>
															<input type="checkbox" onChange={(e) => {
																setNewEvent((prev) => ({
																	...prev, data: {
																		...prev.data,
																		newTab: e.target.checked
																	}
																}));

															}} value={newEvent?.data?.newTab} className='text-indigo-600 focus:ring-0 rounded w-5 h-5 cursor-pointer' />

														</div>
													</>
												}
												{
													newEvent.data.navigationType == 'External Link' &&
													<>
														<div className='text-sm font-medium text-gray-900 mb-1'>Enter Link</div>
														<div className='w-full'>

															<input type="text" placeholder='https://www.example.com' className='p-1 text-sm text-gray-900 w-full border border-gray-300 rounded focus:ring-indigo-600' onChange={(e) => setNewEvent((prev) => ({
																...prev, data: {
																	...prev.data, link: e.target.value
																}
															}))} value={newEvent?.data?.link} />
														</div>
														{
															newEvent.error.link &&
															<div className='text-sm text-red-600 mt-1'>*{newEvent.error.link}</div>
														}
														<div className='flex items-center justify-between '>
															<div className='text-sm font-medium text-gray-900'>Open in new tab:</div>
															<input type="checkbox" onChange={(e) => {
																setNewEvent((prev) => ({
																	...prev, data: {
																		...prev.data,
																		newTab: e.target.checked
																	}
																}));
															}} value={newEvent?.data?.newTab} className='text-indigo-600 focus:ring-0 rounded cursor-pointer w-5 h-5 ' />

														</div>
													</>
												}
												{
													newEvent.data.navigationType == 'Section' &&
													<div>
														<div className='text-sm font-medium text-gray-900'>Select element on canvas to which you want to navigate {newEvent.event.toLowerCase()}</div>
														{
															newEvent.error.id &&
															<div className='text-sm text-red-600 mt-1'>*{newEvent.error.id}</div>
														}
													</div>
												}

											</>
										}
										<div className={`flex items-center justify-between`}>

											<button
												type="button"
												className='text-white bg-indigo-600 font-medium rounded-lg px-3 p-2 text-sm border border-indigo-600'
												onClick={() => saveEventToSchema()}
											>
												Save
											</button>
											{
												newEvent.type == 'edit' &&
												<button
													type="button"
													className='p-2 border border-red-600 rounded-lg hover:bg-red-600 group'
													onClick={() => deleteEventFromSchema(value.event, value.task)}
												>
													<TrashIcon className='w-5 h-5 text-red-600 group-hover:text-white' />
												</button>
											}
											<button
												type="button"
												className='text-indigo-600 bg-white border border-indigo-600 px-3 p-2 text-sm rounded-lg font-medium'
												onClick={() => setNewEvent(null)}
											>
												Cancel
											</button>
										</div>
									</div>
								}
							</div>

						)
					}
				})
			}
		</div >
	);
}
export default Events;