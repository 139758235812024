const eventsRealNames = {
	"On Click": "onClick",
	"On Double Click": "onDoubleClick",
	"On Right Click": "onContextMenu",
	"On Mouse Over": "onMouseOver",
	"On Mouse Leave": "onMouseLeave",
	"On Change": "onChange",
	"On Submit": "onSubmit"
};

export const addEvent = ({ schema, id, newEvent }) => {
	try {
		for (let i = 0; i < schema?.length; i++) {
			if (schema[i].id == id) {

				if (schema[i].events) {
					if (schema[i].events[eventsRealNames[newEvent.event]]) {
						if (Array.isArray(schema[i].events[eventsRealNames[newEvent.event]])) {
							schema[i].events[eventsRealNames[newEvent.event]] = [...schema[i].events[eventsRealNames[newEvent.event]], {
								task: newEvent.task,
								parameters: {
									...newEvent.data
								}
							}];
						} else {
							schema[i].events[eventsRealNames[newEvent.event]] = [schema[i].events[eventsRealNames[newEvent.event]], {
								task: newEvent.task,
								parameters: {
									...newEvent.data
								}
							}]
						}
					} else {
						schema[i].events = {
							...schema[i].events,
							[eventsRealNames[newEvent.event]]: [{
								task: newEvent.task,
								parameters: {
									...newEvent.data
								}
							}]
						}
					}
				} else {
					schema[i].events = {
						[eventsRealNames[newEvent.event]]: [{
							task: newEvent.task,
							parameters: {
								...newEvent.data
							}
						}]
					}
				}
				break;
			} else if (schema[i].children) {
				addEvent({ schema: schema[i].children, id, newEvent });
			}
		}
	} catch (error) {
		console.log(error);
	}
}

export const editEvent = ({ schema, id, newEvent }) => {
	try {
		for (let i = 0; i < schema.length; i++) {
			if (schema[i].id == id) {
				if (Array.isArray(schema[i].events[eventsRealNames[newEvent.event]])) {
					for (let j = 0; j < schema[i].events[eventsRealNames[newEvent.event]].length; j++) {
						if (schema[i].events[eventsRealNames[newEvent.event]][j].task == newEvent.task) {
							schema[i].events[eventsRealNames[newEvent.event]][j].parameters = {
								...newEvent.data
							}
							break;
						}
					}
				} else {
					schema[i].events[eventsRealNames[newEvent.event]] = [{
						task: newEvent.task,
						parameters: {
							...newEvent.data
						}
					}]
				}
			} else if (schema[i].children) {
				editEvent({ schema: schema[i].children, id, newEvent });
			}
		}
	} catch (error) {
		console.log(error);
	}
}

export const deleteEvent = ({ schema, id, event, task }) => {
	try {
		for (let i = 0; i < schema.length; i++) {
			if (schema[i].id == id) {
				if (Array.isArray(schema[i].events[eventsRealNames[event]])) {
					let index;
					for (let j = 0; j < schema[i].events[eventsRealNames[event]].length; j++) {
						if (schema[i].events[eventsRealNames[event]][j].task == task) {
							index = j;
							break;
						}
					}
					schema[i].events[eventsRealNames[event]].splice(index, 1);
				} else {
					delete schema[i].events[eventsRealNames[event]];
				}
			} else if (schema[i].children) {
				deleteEvent({ schema: schema[i].children, id, event, task });
			}
		}
	} catch (error) {
		console.log(error);
	}
}