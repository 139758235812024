import { useMemo, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Buffer } from "buffer";
import { Tab } from "@headlessui/react";
import {
	CircleStackIcon,
	PhotoIcon,
	ArrowDownTrayIcon,
	ArrowUpTrayIcon,
	DocumentDuplicateIcon,
	HeartIcon,
	VideoCameraIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import Masonry from "react-masonry-css";
import {
	IconEdit,
	IconTrack,
	IconTrash,
	IconTrashFilled,
} from "@tabler/icons-react";
import { toast } from "react-toastify";

import { context } from "../../../context/Context";
import { dbCall } from "../../../common/db";
import { Filerobot } from "./imageeditor";
import { classNames, randomString } from "../../../libraries/utility";
import { AddToUndo } from "../../../screens/account/editor";
import { processChange } from "../../../layouts/NewEditor";

const Category = ["All", "Images", "Gifs"];

export function Media() {
	const [search, setSearch] = useState("");
	const [images, setImages] = useState([]);
	const [imagePaginationCount, setImagePaginationCount] = useState(1);
	const [videoPaginationCount, setVideoPaginationCount] = useState(1);
	const [gifPaginationCount, setGifPaginationCount] = useState(10);
	const [userMedia, setUserMedia] = useState([]);
	const [video, setVideo] = useState([]);
	const [gif, setGifs] = useState([]);
	const [postHovered, setPostHovered] = useState(false);
	const [isImgEditorShown, setIsImgEditorShown] = useState(false);
	const [currentImgUrl, setCurrentImgUrl] = useState("");
	const [userMediaName, setUserMediaName] = useState([]);
	const [currentImageName, setCurrentImageName] = useState("");
	const [currentCategory, setCurrentCategory] = useState("Images");

	const { siteId } = useParams();


	const [files, setFiles] = useState(null);

	const {
		elementRef,
		setElement,
		setIsRender,
		elementId,
		setIsAnimating,
		blocks,
		Bdispatch,
		undo,
		setUndo,
		setRedo,
		sqliteSchema,
		strapiSchema,
		selectedSiteData,
		isDropped,
		setIsDropped,
		formElementRef,
		componentRef,
		setCordinates,
		user,
		isSiteTrialActive,
		openedRightSidebarSection,
		setOpenedRightSidebarSection,
		openedLeftSidebarSection,
		setOpenedLeftSidebarSection
	} = useContext(context)


	const fetchImages = async (keyword, page) => {
		const rawRespose = await axios.get(
			`https://pixabay.com/api/?key=38124138-c3486bd4e659ae457fa3634c0&q=${keyword}&image_type=photo&pretty=true&per_page=20&page=${page}`
		);

		const data = await rawRespose.data;

		setImages([...data.hits]);
		return data.hits;
	};

	const fetchVideo = async (keyword, page) => {
		const rawRespose = await axios.get(
			`https://pixabay.com/api/videos/?key=38124138-c3486bd4e659ae457fa3634c0&q=${keyword}&pretty=true&per_page=3&page=${page}`
		);

		const data = await rawRespose.data;

		console.log(data);
		setVideo([...data.hits]);
		return data.hits;
	};

	const fetchGifs = async (keyword, page) => {
		const rawRespose = await axios(
			`https://api.giphy.com/v1/gifs/search?api_key=vVTtvab9A2KBeiwwrCzjwu7a5oISFo1p&q=${keyword}&limit=${page}&offset=0&rating=g&lang=en&bundle=messaging_non_clips`
		);

		const data = await rawRespose.data;

		console.log(data);
		// setGifs([...gif, ...data.data]);
		setGifs([...data.data]);
	};

	const handleFetchData = (currentCategory, page) => {
		if (currentCategory == "All") {
			fetchImages(search, page);
			// fetchVideo(search, page);
			fetchGifs(search, gifPaginationCount);
		} else if (currentCategory == "Images") {
			fetchImages(search, page);
		} else if (currentCategory == "Videos") {
			fetchVideo(search, page);
		} else {
			fetchGifs(search, gifPaginationCount);
		}
	};

	const uploadFile = (e) => {
		e.preventDefault();
		let file = e.target.files;

		if (file) {
			const filesData = new FormData(); // use FormData else it will give data too long error
			filesData.append("file", e.target.files[0]);

			axios
				.post(
					`${process.env.REACT_APP_BACKEND_URL}/api/user/upload-image`,
					filesData,
					{
						headers: {
							userId: 1,
							username: "divyansh",
						},
					}
				)
				.then((res) => {
					console.log("res : ", res);
					alert("upload Successfully");
				})
				.catch((err) => console.log("err : ", err));
		}
	};

	const uploadMediafromDevice = async (profilePic) => {
		// console.log("files : ", profilePic);
		console.log("This is siteId", siteId);

		if (profilePic == null) {
			toast.error("Select Image to change your Profil picture.");
			return;
		}

		for (let i = 0; i < profilePic.length; i++) {
			if (
				profilePic[i].type != "image/jpeg" ||
				profilePic[i].type != "image/png" ||
				profilePic[i].type != "video/mp4"
			) {
				if (
					profilePic[i].type.includes("video") &&
					profilePic[i].size > 5120000
				) {
					toast.error("Upload only mp4 file with max size of 5 mb.");
					return;
				} else if (
					profilePic[i].type.includes("image") &&
					profilePic[i].size > 512000
				) {
					toast.error("Upload only JPEG & PNG file with max size of 500 Kb.");
					return;
				}
			}
		}

		// setUpdatingImage(false);
		setIsAnimating(true);

		const filesData = new FormData(); // use FormData else it will give 'data too long' error

		Array.from(profilePic).forEach((image) => {
			filesData.append("profilePic", image);
		});

		filesData.append("siteId", siteId);

		console.log("filedata",filesData)

		await dbCall.post(`/user/upload-user-media`, filesData)
			.then((response) => {
				// console.log('profile pic response : ', response);
				toast.success(response.data.message);
				getUserMedia();
			})
			.catch((error) => {
				// console.log('profile pic error : ', error);
				if (error.response) {
					toast.error(error.response.data.message);
				} else {
					toast.error("Something went wrong!");
				}
			})
			.finally(() => {
				setIsAnimating(false);
			});
	};

	const uploadImageToS3 = async (url) => {
		axios
			.post(`${process.env.REACT_APP_BACKEND_URL}/api/user/upload-url`, {
				url: url,
			})
			.then((res) => {
				console.log("res : ", res);
				alert("upload Successfully");
			})
			.catch((err) => console.log("err : ", err));
	};

	// const uploadGIF = async (url) => {
	// 	try {

	// 		console.log("Url", url)
	// 		const response = await fetch(url);
	// 		const arrayBuffer = await response.arrayBuffer();

	// 		console.log("Hell", arrayBuffer);

	// 		// Convert the GIF data to base64
	// 		const base64String = Buffer.from(arrayBuffer).toString("base64");
	// 		console.log("Hell2", base64String);
	// 		await dbCall
	// 			.post("/user/upload-user-edited-media", {
	// 				pictures: base64String,
	// 				currentImage: currentImageName,
	// 				size: blob?.size,
	// 			})
	// 			.then((response) => {
	// 				const isChangeMedia = sessionStorage.getItem("setMedia");
	// 				if (elementId.type == "img" && isChangeMedia) {
	// 					Bdispatch({
	// 						type: "UPDATE_MEDIA",
	// 						id: elementId.id,
	// 						element: response?.data?.cdn_url[0],
	// 					});
	// 				}

	// 				sessionStorage.setItem("setMedia", false);
	// 				toast.success(response.data.message);
	// 			})
	// 			.catch((error) => {
	// 				// console.log('profile pic error : ', error);
	// 				if (error.response) {
	// 					toast.error(error.response.data.message);
	// 				} else {
	// 					toast.error("Something went wrong!");
	// 				}
	// 			})
	// 			.finally(() => {
	// 				setIsAnimating(false);
	// 				getUserMedia();
	// 			});

	// 		// const filesData = new FormData();

	// 		// // Prepare the data for uploading to S3
	// 		// const base64GIF = Buffer.from(base64Image, "base64");
	// 		// console.log("Base64", base64GIF);

	// 		// // Convert the base64 string to a Blob object
	// 		// const byteString = atob(base64GIF.split(",")[1]); // Decode base64 to binary string
	// 		// const mimeString = base64GIF.split(",")[0].split(":")[1].split(";")[0]; // Extract the MIME type

	// 		// const ab = new ArrayBuffer(byteString.length);
	// 		// const ia = new Uint8Array(ab);
	// 		// for (let i = 0; i < byteString.length; i++) {
	// 		// 	ia[i] = byteString.charCodeAt(i);
	// 		// }

	// 		// const blob = new Blob([ab], { type: mimeString });
	// 		// const file = new File([blob], "yourGif.gif", { type: mimeString });

	// 		// // Append the GIF file to FormData
	// 		// filesData.append("profilePic", file);
	// 		// filesData.append("siteId", siteId);

	// 		// await dbCall
	// 		// 	.post(`/user/upload-user-media`, filesData)
	// 		// 	.then((response) => {
	// 		// 		// console.log('profile pic response : ', response);
	// 		// 		toast.success(response?.data);
	// 		// 		getUserMedia();
	// 		// 	})
	// 		// 	.catch((error) => {
	// 		// 		console.log('Gif', error);
	// 		// 		if (error.response) {
	// 		// 			toast.error(error.response.data.message);
	// 		// 		} else {
	// 		// 			toast.error("Something went wrong!");
	// 		// 		}
	// 		// 	})
	// 		// 	.finally(() => {
	// 		// 		setIsAnimating(false);
	// 		// 	});
	// 	} catch (error) {
	// 		console.log("Base64", error)
	// 	}
	// }

	const getUserMedia = async () => {
		await dbCall
			.get("/user/get-user-media")
			.then((response) => {
				// console.log("get user media response", response);
				if (response.data?.message != "No media uploaded yet.") {
					console.log(response.data, "THis is image Data");

					let cdnUrl = [];
					for (let i = 0; i < response.data.data.length; i++) {
						cdnUrl.push({
							url: `https://usercdn.qafto.com/${user?.uniqueId}/${response.data.userMedia[i]}`,
							key: response.data.userMedia[i],
						});
						// cdnUrl.push(`https://usercdn.qafto.com/ls8sm6pwphj335to0j/${response.data.userMedia[i]}`)
					}
					setUserMedia([...cdnUrl]);
					setUserMediaName(response.data.userMedia);
				}
			})
			.catch((error) => {
				// console.log('get user media error : ', error);
				if (error.response) {
					toast.error(error.response.data.message);
				} else {
					toast.error("Something went wrong!");
				}
			})
			.finally(() => {
				setIsAnimating(false);
			});
	};

	useEffect(() => {
		getUserMedia();
	}, []);

	useMemo(() => {
		if (search.length == 0) {
			setImages([]);
			setVideo([]);
			setGifs([]);
		}
	}, [search]);

	const tabs = [
		{ name: "Upload Media" },
		{ name: "Search Media" }
	]
	return (
		<div>
			<Filerobot
				isImgEditorShown={isImgEditorShown}
				setIsImgEditorShown={setIsImgEditorShown}
				currentImgUrl={currentImgUrl}
				setCurrentImgUrl={setCurrentImgUrl}
				currentImageName={currentImageName}
				getUserMedia={getUserMedia}
				siteId={siteId}
			/>

			<button
				type="button"
				className={classNames(
					openedLeftSidebarSection == 'media' ? "bg-[#393939]" : "hover:bg-[#393939]",
					"rounded-md px-3 py-2 font-semibold text-white my-other-step"
				)}
				id="step-five"
				onClick={(e) => {
					// e.stopPropagation()
					// setIsMedia(!IsMedia)
					processChange()
					setOpenedRightSidebarSection(openedLeftSidebarSection ? openedRightSidebarSection : false)
					setOpenedLeftSidebarSection((prev) => {
						if (prev == 'media') {
							return null;
						} else {
							return 'media';
						}
					})



					// setIsLeftPanelOpen(!openMedia ? true : false);
					// setOpenRightSideBar(openMedia ? openRightSideBar : false);
					// setOpenMedia(!openMedia);
					// setOpenData(false);
					// setOpenPage(false);
					// setOpenSection(false);
					// setOpenTheme(false);

					// // show form elements tab
					// setOpenFormElements(false);
					setSearch("");

					if (elementRef.current != null) {
						elementRef.current.style.outline = "none";
						elementRef.current = null;
					}
					if (formElementRef.current != null) {
						formElementRef.current.style.outline = "none";
						formElementRef.current = null;
					}
					if (componentRef.current != null) {
						componentRef.current.style.outline = "none";
						componentRef.current = null;
					}

					setCordinates({
						cordinatesValue: {
							top: 0,
							left: 0,
						},
					});
				}}
				title="Media"
			>
				<PhotoIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
				<span className="sr-only">Media</span>
			</button>
			{
				<div
					id="Media_div"
					className={`${openedLeftSidebarSection == 'media' ? "w-full opacity-100 z-[55]" : "w-0 opacity-0"
						} duration-500 flex flex-col bg-white fixed inset-y-0 left-20 top-[103px] max-w-sm`}
				>
					<div className="px-4 border-b-2 py-3">
						<div className="flex items-start justify-between">
							<div className="text-base font-semibold leading-6 text-gray-900">
								Media
							</div>

							<div className={`${openedLeftSidebarSection != 'media' && "hidden"} duration-700 ml-3 flex h-7 items-center`}>
								<button
									type="button"
									className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									onClick={() => {
										setOpenedLeftSidebarSection(null)
									}}
								>
									<span className="sr-only">Close panel</span>
									<XMarkIcon className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
					<div className={`${openedLeftSidebarSection != 'media' && "hidden"} duration-500`}>
						<Tab.Group>
							<div className="border-b border-gray-200">
								<Tab.List className="flex justify-center space-x-10 my-3">
									{tabs.map((tab, index) => (
										<Tab key={`tab${index}`}>
											{({ selected }) => {
												return (
													<div
														// onClick={() => setSearch('')}
														className={classNames(
															selected
																? "bg-indigo-100 text-indigo-700"
																: "text-gray-500 hover:text-gray-700",
															"rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
														)}
													>
														{tab.name}
													</div>
												);
											}}
										</Tab>
									))}
								</Tab.List>
							</div>

							<Tab.Panels>
								{/* START - Upload Media Section  */}
								<Tab.Panel className='rounded-xl bg-white'>
									<div className={`flex items-center justify-center cursor-pointer py-3 px-4 my-2 ${!isSiteTrialActive && "opacity-[.5]"}`}
										title={!isSiteTrialActive && "Your Free trial Expire"}
									>
										<div className="w-10 h-10 rounded-full border-2 border-gray-200 grid place-content-center mr-4 hover:bg-gray-200 duration-500">
											<ArrowUpTrayIcon className="w-6 h-6" />
											<input
												type="file"
												className="absolute opacity-0"
												multiple="true"
												disabled={!isSiteTrialActive ? true : false}
												accept="image/png, image/gif, image/jpeg, video/mp4,video/x-m4v,video/*"
												onChange={async (e) => {
													//   setFiles(e.target.files);
													// uploadFile(e);
													uploadMediafromDevice(e.target.files);
												}}
											/>
										</div>
										<p className="text-xl font-medium text-gray-600 font-[Raleway]">
											UPLOAD YOUR MEDIA
										</p>
									</div>

									<hr className="border-b border-gray-100" />

									{/* Start - Uploaded media */}
									<div className="w-full h-[65vh] overflow-y-auto no-scrollbar py-3 px-4">
										<div className="flex flex-wrap justify-center">
											<Masonry
												className="flex animate-slide-fwd gap-1"
												breakpointCols={{
													default: 3,
													3000: 3,
													2000: 2,
													1000: 1,
												}}
											>
												{
													openedLeftSidebarSection == 'media' &&
													userMedia?.map((image, i) => {
														return (
															<div key={i} className="mx-1.5 my-2">
																{image?.url?.includes("video") ?
																	(
																		<div
																			class="relative group block overflow-hidden rounded-md transition-all duration-500 "
																			onClick={async (e) => {
																				if (elementId.type == "player") {
																					await Bdispatch({
																						type: "UPDATE_MEDIA",
																						id: elementId.id,
																						element: image?.url,
																					});
																				}

																				// add schemas to undo state for replacing video on click
																				AddToUndo(
																					selectedSiteData.static,
																					blocks,
																					sqliteSchema,
																					strapiSchema,
																					undo,
																					setUndo,
																					setRedo
																				);
																			}}
																			onDragStart={() => {
																				const config = {
																					id: randomString(),
																					type: "video",
																					data: {
																						src: `${image?.url}`,
																						autoplay: true,
																						controls: true,
																					},
																					style: {
																						width: "100%",
																						height: "100%",
																					},
																					css: "w-full h-full",
																				};
																				setElement(config);
																				setIsRender(false);

																				setIsDropped(false);
																			}}
																			onDragEndCapture={() => {
																				if (elementRef.current != null) {
																					elementRef.current.style.outline =
																						"none";
																					elementRef.current.style.opacity = "1";
																				}

																				if (isDropped) {
																					// add schemas to undo state
																					AddToUndo(
																						selectedSiteData.static,
																						blocks,
																						sqliteSchema,
																						strapiSchema,
																						undo,
																						setUndo,
																						setRedo
																					);
																				}
																			}}
																		>
																			<a
																				href="#"
																				className="lightbox w-full h-full transition-all duration-500 group-hover:scale-105 tobii-zoom"
																				title=""
																			>
																				<video
																					alt="gallery"
																					className="block h-full w-full rounded-lg object-cover object-center"
																					controls
																					controlsList="nodownload"
																					src={image?.url}
																				></video>
																				<div class="absolute -top-12 group-hover:top-0 right-0 transition-all duration-500 bg-none  p-1 rounded  text-white flex items-center bg-slate-700">

																					<IconTrash
																						className="h-6 w-6 ml-2 text-red-400 cursor-pointer"
																						onClick={async () => {
																							await dbCall.post(
																								`/user/delete-user-media`,
																								{
																									imageKey: image?.key,
																								}
																							);
																							var filtered = userMedia?.filter(
																								function (el) {
																									return el?.key != image?.key;
																								}
																							);
																							setUserMedia([...filtered]);
																						}}
																					/>
																				</div>
																			</a>
																		</div>
																	) : (
																		<div
																			class="group relative block overflow-hidden rounded-md transition-all duration-500 "
																			onClick={async (e) => {
																				if (
																					elementId.type == "img" &&
																					!elementId?.data?.backgroundImage
																				) {
																					await Bdispatch({
																						type: "UPDATE_MEDIA",
																						id: elementId.id,
																						element: image?.url,
																					});
																				} else if (
																					elementId?.data?.backgroundImage
																				) {
																					// console.log("Click", elementId)
																					await Bdispatch({
																						type: "UPDATE_BACKGROUND_MEDIA",
																						id: elementId.id,
																						element: image?.url,
																					});
																				}

																				// add schemas to undo state for replacing image on click
																				AddToUndo(
																					selectedSiteData.static,
																					blocks,
																					sqliteSchema,
																					strapiSchema,
																					undo,
																					setUndo,
																					setRedo
																				);
																			}}
																			onDragStart={() => {
																				const config = {
																					id: randomString(),
																					type: "img",
																					data: {
																						src: `${image?.url}`,
																					},
																					style: {
																						width: "100%",
																						height: "100%",
																					},
																					css: "w-full h-full",
																				};
																				setElement(config);
																				setIsRender(false);

																				setIsDropped(false);
																			}}
																			onDragEndCapture={() => {
																				if (elementRef.current != null) {
																					elementRef.current.style.outline =
																						"none";
																					elementRef.current.style.opacity = "1";
																				}

																				if (isDropped) {
																					// add schemas to undo state
																					AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
																				}
																			}}
																		>
																			<a href="#" class="lightbox transition-all duration-500 group-hover:scale-105 tobii-zoom" title="">
																				<img
																					alt="gallery"
																					src={image?.url}
																					loading='lazy'
																				/>
																			</a>
																			<div class="absolute -top-52 group-hover:top-0 right-0 transition-all duration-500 bg-none  p-1 rounded  text-white flex items-center bg-slate-700">
																				<IconEdit className='h-6 w-6 cursor-pointer'
																					onClick={async () => {
																						setIsImgEditorShown(true)
																						setOpenedLeftSidebarSection(null);
																						setCurrentImgUrl(`${image?.url}?timestamp=${new Date()}`)
																						setCurrentImageName(userMediaName[i]?.url?.split('/')[1])
																						setOpenedLeftSidebarSection(null)

																					}} />
																				<IconTrash className='h-6 w-6 ml-2 text-red-400 cursor-pointer'
																					onClick={async () => {
																						await dbCall.post(`/user/delete-user-media`, {
																							imageKey: image?.key
																						})
																						var filtered = userMedia?.filter(function (el) { return el?.key != image?.key });
																						setUserMedia([...filtered])
																					}}
																				/>
																			</div>

																		</div>
																	)
																}
															</div>
														);
													})}
											</Masonry>
										</div>
										{userMedia?.length == 0 && (
											<p class="text-md text-gray-500 font-medium mt-6 text-center">
												Currently No media uploaded
											</p>
										)}
									</div>
									{/* END - Uploaded media */}
								</Tab.Panel>
								{/* END - Your media Sectiom */}

								{/* START - Search Media */}
								<Tab.Panel className="rounded-xl bg-white">
									<div className="w-full flex justify-center my-3">
										<div className="w-[90%] relative">
											<div className="w-full absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
												<svg
													className="w-4 h-4 text-gray-500 dark:text-gray-400"
													aria-hidden="true"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 20 20"
												>
													<path
														stroke="currentColor"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
													/>
												</svg>
											</div>

											<input
												type="search"
												id="search"
												className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-200 rounded-lg bg-gray-50 shadow-sm outline-none"
												placeholder="Search"
												value={search}
												onChange={(e) => setSearch(e.target.value)}
												onKeyUp={(e) => {
													if (e.key == "Enter") {
														handleFetchData(currentCategory, 1);
													}
												}}
											/>

											<div className="text-white absolute right-2.5 bottom-2 ">
												<button
													type="button"
													className="text-white  bg-indigo-500 hover:bg-indigo-800 focus:ring-4 font-medium rounded-md text-sm px-4 py-2"
													onClick={() => {
														handleFetchData(currentCategory, 1);
													}}
												>
													Search
												</button>
											</div>
										</div>
									</div>

									<hr className="border-b border-gray-100" />

									{/* Start - Category (All, Images, Gifs) */}
									{(images.length > 0 ||
										video.length > 0 ||
										gif.length > 0) && (
											<div className="flex flex-wrap items-center justify-center my-3">
												<div className="w-[90%]">
													{Category.map((category, i) => (
														<button
															type="button"
															className={`mr-6 mb-2 px-4 py-1 lg:px-5 border border-gray-600 rounded-full ${currentCategory == category &&
																"text-white bg-blue-600"
																} font-medium tracking-wide`}
															onClick={() => {
																setCurrentCategory(category);
																handleFetchData(category, 1);
															}}
														>
															{category}
														</button>
													))}
												</div>
											</div>
										)}
									{/* End - Category (All, Images, Gifs) */}

									{images.length == 0 &&
										video.length == 0 &&
										gif.length == 0 && (
											<p class="text-md text-gray-500 font-medium mt-6 text-center">
												Search Media to See results
											</p>
										)}

									{/* START - Search Result */}
									{(images.length > 0 ||
										video.length > 0 ||
										gif.length > 0) && (
											<div className="min-h-[65vh] max-h-[65vh] w-full overflow-y-auto overflow-x-hidden no-scrollbar">
												<div className="flex justify-center ">
													<div className="px-3 mb-16">
														{(currentCategory == "All" ||
															currentCategory == "Images") && (
																<>
																	{currentCategory == "All" &&
																		images.length != 0 && (
																			<div className="flex items-center justify-center">
																				<div className="w-[100%]">
																					<p className="text-lg font-semibold text-gray-600">
																						Images
																					</p>
																					<hr />
																				</div>
																			</div>
																		)}

																	{currentCategory == "Images" &&
																		images.length == 0 && (
																			<p className="text-center text-sm text-gray-600 my-4">
																				Nothing Found related to your search in
																				Images
																			</p>
																		)}

																	<Masonry
																		className="flex animate-slide-fwd"
																		breakpointCols={{ default: 3, 3000: 3, 2000: 2, 1000: 1 }}
																	>
																		{images.map((data, i) => (
																			// <div key={i} className="m-2">
																			// 	<div
																			// 		className="relative cursor-grab-in w-auto hover:shadow-lg rounded-lg overflow-hidden transaction-all duration-500 ease-in-out group"
																			// 		draggable={true}
																			// 		onDragStart={() => {
																			// 			const config = {
																			// 				id: Date.now().toString(),
																			// 				type: "img",
																			// 				data: {
																			// 					src: `${data.largeImageURL}`
																			// 				},
																			// 				css: "w-full"
																			// 			}
																			// 			setElement(config)
																			// 			setIsRender(false)
																			// 		}}
																			// 		onDragEndCapture={() => {
																			// 			if (elementRef.current != null) {
																			// 				elementRef.current.style.outline = "none"
																			// 				elementRef.current.style.opacity = "1"
																			// 			}
																			// 		}}																			>
																			// 		<img
																			// 			className="absolute inset-0 object-cover 
																			// 			w-full h-full group-hover:opacity-50"
																			// 			src={data.largeImageURL}
																			// 			alt=""
																			// 		/>
																			// 		<div class="relative ">
																			// 			<div >
																			// 				<div
																			// 					class="transition-all transform  opacity-0 group-hover:opacity-100 group-hover:translate-y-0 flex items-center"
																			// 				>
																			// 					<div class="p-2">
																			// 						<button
																			// 							type="button"
																			// 							class="my-2 px-4 py-2 text-sm text-white bg-green-600"
																			// 							onClick={() => {
																			// 								setIsImgEditorShown(true)
																			// 								setCurrentImgUrl(data.largeImageURL)
																			// 							}}
																			// 						>
																			// 							Use this
																			// 						</button>

																			// 					</div>
																			// 				</div>
																			// 			</div>
																			// 		</div>
																			// 	</div>
																			// </div>
																			<div key={i} class={`group relative block overflow-hidden rounded-md transition-all duration-500 ${i % 2 == 0 ? 'mr-1' : 'ml-1'} my-2`}
																				onDragStart={() => {
																					const config = {
																						id: Date.now().toString(),
																						type: "img",
																						data: {
																							src: `${data.largeImageURL}`
																						},
																						css: "w-full"
																					}
																					setElement(config)
																					setIsRender(false)
																				}}
																				onDragEndCapture={() => {
																					if (elementRef.current != null) {
																						elementRef.current.style.outline = "none"
																						elementRef.current.style.opacity = "1"
																					}
																				}}
																			>
																				<a href="#" class="lightbox transition-all duration-500 group-hover:scale-105 tobii-zoom" title="">
																					<img
																						alt="gallery"
																						src={data.largeImageURL}
																					/>
																				</a>
																				<div class="absolute  group-hover:top-0  transition-all duration-500 bg-none  p-1 rounded  text-white flex items-center justify-center bg-[rgba(0,0,0,.5)] h-full w-full ">
																					<button
																						type="button"
																						class="my-2 px-4 py-2 text-white bg-blue-600 font-medium text-sm rounded-md"
																						onClick={() => {
																							setIsImgEditorShown(true)
																							setOpenedLeftSidebarSection(null)
																							setCurrentImgUrl(data.largeImageURL)
																						}}
																					>
																						Use This
																					</button>
																				</div>

																			</div>
																		))}
																	</Masonry>

																	{images.length != 0 && (
																		<p
																			className="text-center text-sm text-blue-700 cursor-pointer"
																			onClick={async () => {
																				const data = await fetchImages(
																					search,
																					imagePaginationCount + 1
																				);
																				setImages([...images, ...data]);
																				setImagePaginationCount(
																					imagePaginationCount + 1
																				);
																			}}
																		>
																			Show More
																		</p>
																	)}
																</>
															)}

														{/* {
																		(currentCategory == "All" || currentCategory == "Videos") &&
																		<>
																			{
																				currentCategory == "Vidoes" && video.length != 0 &&
																				<div className="flex items-center justify-center mt-4">
																					<div className="w-[100%]">
																						<p className="text-lg font-semibold text-gray-600 ">
																							Videos
																						</p>
																						<hr />
																					</div>
																				</div>
																			}
																			{
																				currentCategory == "Vidoes" && video.length == 0 && (
																				<p className="text-center text-sm text-gray-600 my-4">
																					Nothing Found related to your search in Videos
																				</p>
																			)}

																			<Masonry
																				className="flex animate-slide-fwd"
																				breakpointCols={{ default: 3, 3000: 3, 2000: 2, 1000: 1 }}
																			>
																				{video.map((video, i) => (
																					<div key={i} className="m-2">
																						<div
																							className="relative cursor-zoom-in w-auto hover:shadow-lg rounded-lg overflow-hidden transaction-all duration-500 ease-in-out "
																						>
																							<video className="block w-full rounded-md object-contain object-center hover:shadow-2xl">
																								<source
																									src={video.videos.large.url}
																									type="video/mp4"
																								/>
																							</video>
																						</div>
																					</div>
																				))}
																			</Masonry>

																			{
																				video.length != 0 &&
																				<p
																					className="text-center text-sm text-blue-700 cursor-pointer"
																					onClick={async () => {
																						const data = await fetchVideo(search, videoPaginationCount + 1);
																						setVideo([...video, ...data]);
																						setVideoPaginationCount(videoPaginationCount + 1);
																					}}
																				>
																					Show More
																				</p>
																			}
																		</>
																	} */}

														{(currentCategory == "All" ||
															currentCategory == "Gifs") && (
																<>
																	{currentCategory == "All" && gif.length != 0 && (
																		<div
																			className={`flex items-center justify-center ${gif.length != 0 && "mt-4"
																				}`}
																		>
																			<div className="w-[100%]">
																				<p className="text-lg font-semibold text-gray-600">
																					Gifs
																				</p>
																				<hr />
																			</div>
																		</div>
																	)}

																	{currentCategory == "Gifs" && gif.length == 0 && (
																		<p className="text-center text-sm text-gray-600 my-4">
																			Nothing Found related to your search in Gifs
																		</p>
																	)}

																	<Masonry
																		className="flex animate-slide-fwd"
																		breakpointCols={{
																			default: 3,
																			3000: 3,
																			2000: 2,
																			1000: 1,
																		}}
																	>
																		{gif?.map((gif, i) => (
																			<div
																				key={i}
																				class={`group relative block overflow-hidden rounded-md transition-all duration-500 ${i % 2 == 0 ? "mr-1" : "ml-1"
																					} my-2`}
																				draggable={true}
																				onDragStart={() => {
																					const config = {
																						id: randomString(),
																						type: "img",
																						data: {
																							src: `${gif.images.original.url}`,
																						},
																						style: {
																							width: "100%",
																							height: "100%",
																						},
																						css: "w-full h-full",
																					};
																					setElement(config);
																					setIsRender(false);
																				}}
																				onDragEndCapture={() => {
																					if (elementRef.current != null) {
																						elementRef.current.style.outline =
																							"none";
																						elementRef.current.style.opacity = "1";
																					}
																				}}
																			>
																				<LazyLoadImage
																					alt="gallery"
																					className="inline w-full rounded-md object-cover object-center hover:shadow-2xl"
																					src={gif.images.original.url}
																				/>
																				<div class="absolute  group-hover:top-0  transition-all duration-500 bg-none  p-1 rounded  text-white flex items-center justify-center bg-[rgba(0,0,0,.5)] h-full w-full ">
																					<button
																						type="button"
																						class="my-2 px-4 py-2 text-white bg-blue-600 font-medium text-sm rounded-md"
																						onClick={async () => {
																							// setOpenMedia(false);
																							setCurrentImgUrl(
																								gif.images.original.url
																							);
																							// await uploadGIF(
																							// 	gif.images.original.url
																							// );
																						}}
																					>
																						Use This
																					</button>
																				</div>
																			</div>
																		))}
																	</Masonry>

																	{gif.length != 0 && (
																		<p
																			className="text-center text-sm text-blue-700 cursor-pointer mb-5"
																			// onClick={async () => {
																			// TODO: add fetch gif
																			// const data = await fetchVideo(search, videoPaginationCount + 1);
																			// setVideo([...video, ...data]);
																			// setVideoPaginationCount(videoPaginationCount + 1);
																			// }}
																			onClick={async () => {
																				console.log(gif, "RUN");
																				const data = await fetchGifs(
																					search,
																					gifPaginationCount + 10
																				);
																				// console.log(data.data)
																				// setGifs([...gif, ...data.data]);
																				setGifPaginationCount(
																					gifPaginationCount + 10
																				);
																			}}
																		>
																			Show More
																		</p>
																	)}
																</>
															)}
													</div>
												</div>
											</div>
										)}
									{/* END - Search Result */}
								</Tab.Panel>
								{/* END - Search Media */}
							</Tab.Panels>
						</Tab.Group>
					</div>
				</div>
			}
		</div>
	);
}
