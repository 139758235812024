import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Menu, Switch, Transition } from "@headlessui/react";

import { context } from "../../../../context/Context";
import { adminDBCall } from "../../../../common/admin-db";
import { toast } from "react-toastify";

import { PencilIcon } from "@heroicons/react/24/solid";
import {
	ArrowDownTrayIcon,
	MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { IconCircleCheck, IconCross, IconEdit, IconLink, IconNumber, IconPhoto, IconTextSize, IconTrash, IconX } from "@tabler/icons-react";
import { dbCall } from "../../../../common/db";
import { Formik } from "formik";

const Fields = [
	{
		name: "String",
		description: "Titles, Paragrapher"
	},
	{
		name: "Number",
		description: "ID, rating, order number"
	},
	{
		name: "URL",
		description: "Links"
	},
	{
		name: "Boolean",
		description: "Yes or no, true or false"
	},
	{
		name: "Image",
		description: "Upload a single image"
	},
	{
		name: "Media",
		description: "A gallery of images and videos"
	},
	{
		name: "Object",
		description: "Javascript Object"
	},
	{
		name: "Array",
		description: "Javascript Array"
	}
];



let fieldNames = [];
const ComponentSettings = () => {
	// collection type name
	const [collectionType, setCollectionType] = useState("");
	const [collectionFieldData, setCollectionFieldData] = useState({});
	const [currentBlock, setCurrentBlock] = useState({});
	let [currentStaticData, setCurrentStaticData] = useState([])
	const [isMedia, setIsMedia] = useState(false)
	const [media, setMedia] = useState([])
	// field data
	const [attributes, setAttributes] = useState([]);

	// map dynamic fields
	const [fieldsToMap, setFieldsToMap] = useState(true);
	const [mapFields, setMapFields] = useState([]); // { name: "", mapTo: "" }

	// error msg
	const [fieldIndex, setFieldIndex] = useState(null);
	// const [strapiFieldIndex, setStrapiFieldIndex] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [strapiErrorMessage, setStrapiErrorMessage] = useState(null);
	const [checkJsonValid, setCheckJsonValid] = useState(-1);
	const [showArray, setShowArray] = useState(-1);
	const [arrayValue, setArrayValue] = useState("");
	const [minMax, setMinMax] = useState(false)
	let [addField, setAddField] = useState(false);
	let [fieldType, setFieldType] = useState(false);
	let [currentField, setCurrentField] = useState({ index: -1 });
	let [fieldName, setFieldName] = useState(-1);
	const [currentFieldObjeect, setCurrentFieldObject] = useState({})
	const [validationError, setValidationError] = useState({})
	const {
		blocks,
		Bdispatch,
		componentRef,
		strapiCollectionNames,
		setStrapiCollectionNames,
		adminURL,
		adminToken,
		selectedSiteData,
		updateStaticData,
		setUpdateStaticData,
		setIsAnimating,
		isListDynamic,
		setIsListDynamic,
		isOpenStaticTable,
		setIsOpenStaticTable,
		setIsStaticDataUpdate,
		user,
		elementId
	} = useContext(context);

	function closeModal() {
		setIsOpenStaticTable(false);
		setAddField(false);
	}

	useEffect(() => {
		// get strapi collections
		if (adminToken.length > 0 && adminURL.length > 0) {
			if (
				strapiCollectionNames.length == 0 &&
				selectedSiteData.static == false
			) {
				getStrapiCollections();
			}
		}
	}, [componentRef?.current?.id]);

	useEffect(() => {
		// if(adminToken.length > 0 && adminURL.length > 0){
		console.log(componentRef.current.id, "component REF")
		// setAttributes([])
		if (componentRef?.current) {
			fieldNames = []
			getComponentSchema(blocks);
		}
		// }
	}, [componentRef?.current.id]);
	// }, [JSON.stringify(blocks)]);

	useEffect(() => {
		if (collectionType != null) {
			getCollectionAndFields(collectionType);
		}
	}, [collectionType]);


	// get strapi collections
	const getStrapiCollections = async () => {
		await adminDBCall(adminURL, adminToken)
			.get("/content-type-builder/content-types")
			.then((response) => {
				// console.log("collections : ", response.data.data);

				const userCollection = [];

				response.data.data.map((item) => {
					// users table
					if (item.uid == "plugin::users-permissions.user") {
						userCollection.push(item);
					}

					// user created collections
					if (item.uid.includes("api::")) {
						userCollection.push(item);
					}
				});

				setStrapiCollectionNames(userCollection);
			})
			.catch((error) => {
				console.log("error : ", error);
				if (error?.response?.data?.error?.message) {
					toast.error(error.response?.data.error.message);
				} else {
					toast.error("Something went wrong!");
				}
			})
			.finally(() => {
				// setIsAnimating(false);
			});
	};

	// get fields of collection stored in schema
	const getCollectionAndFields = async (collectionName) => {
		try {
			let collection = collectionName.toLowerCase();
			let collection2 = collectionName.toLowerCase();
			console.log(collection, collection2, "COLLECTIONS")
			// if (collection == "categoryproduct" || collection == "categoryProduct" || collection == "products" || collection == "product") {
			// 	collection = "products";
			// 	collection2 = "product";
			// }

			currentBlock?.data?.collection &&
				(currentBlock.data.collection = collection);
			let uid = null; // api::x.x
			if (collection2 == "user") {
				uid = "plugin::users-permissions.user";
			} else {
				uid = `api::${collection2}.${collection2}`;
				// uid = `api::product.product`;
			}

			const response = await adminDBCall(adminURL, adminToken).get(
				`/content-type-builder/content-types/${uid}`
			);

			const allFields = response.data.data.schema.attributes;
			console.log(allFields, "reposnse");
			let newArray = [];
			console.log(allFields, "ALL FIELDS");
			setCollectionFieldData({ ...allFields });
		} catch (error) {
			console.log("error", error);
		}
	};

	// get component data from schema
	const getComponentSchema = async (blocks) => {
		const componentID = componentRef?.current?.id;
		console.log(componentID, "componetID")
		for (let i = 0; i < blocks?.length; i++) {
			// loop to the right component

			if (blocks[i].id == componentID) {
				setAttributes([])
				setCurrentBlock(blocks[i]);
				setCurrentStaticData(JSON.parse(JSON.stringify(blocks[i].data.staticData)))
				await updateSchema(blocks[i]);
				// const collection =
				// 	await (blocks?.[i]?.data?.collection == "product" || blocks?.[i]?.data?.collection == "products")
				// 		? "product"
				// 		: blocks[i]?.data?.collection;
				const collection = blocks[i]?.data?.collection;
				if (collection) {
					getCollectionAndFields(collection);
					// setCollectionType(collection == "products" ? "categoryProduct" : collection);
					setCollectionType(collection);

					setFieldsToMap(true);
				}
				return;
			} else {
				getComponentSchema(blocks[i]?.children);
			}
		}
	};
	const updateSchema = async (schema) => {
		if (schema?.dynamic || schema.fieldName) {
			fieldNames.push(schema);
			const unique2 = await fieldNames?.filter((obj, index) => {
				return index === fieldNames?.findIndex(o => obj.id === o.id);
			});
			console.log(unique2, "unique2");
			// fieldNames = c;
			setAttributes(unique2);
			return true;
		} else if (schema?.children) {
			for (let i = 0; i < schema?.children.length; i++) {
				updateSchema(schema?.children[i]);
			}
		}
		return false;
	};
	// update selected component data in schema
	const updateComponentSchema = async (id, element, type) => {
		if (type == "UPDATE_STATIC_DATA") {
			await Bdispatch({
				type: "UPDATE_STATIC_DATA",
				id: id,
				element: parseInt(element),
			});
			setUpdateStaticData(!updateStaticData);
		} else if (type == "UPDATE_STATIC_DATA_ARRAY") {
			await Bdispatch({
				type: "UPDATE_STATIC_DATA_ARRAY",
				id: id,
				element: element,
			});
		}
		else {
			const updateElement =
				(element?.replace("block", ""),
					element?.replace("inline", ""),
					element?.replace("inline-block", ""));
			var updateCss = element?.includes("hidden")
				? element.replace("hidden", "")
				: updateElement + " " + "hidden";

			Bdispatch({
				type: "UPDATE_CSS",
				id: id,
				element: updateCss,
			});
		}
	};

	function isImage(url) {
		console.log(url, typeof (url), "This is URL")
		if (url == null || url.length == 0 || url == undefined || typeof (url) == 'number') return
		if (url?.includes("https://cdn.qafto.com", "qafto-testing.s3.ap-south-1.amazonaws.com", "https://usercdn.qafto.com")) {
			return true;
		}
		return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
	}

	const getUserMedia = async (image, index, key) => {
		await dbCall
			.get("/user/get-user-media", {
				image: image,
			})
			.then(async (response) => {
				console.log("get user media response", response);
				currentBlock.data.staticData[index][key] = `${process.env.REACT_APP_USER_CDN}/${user?.uniqueId}/${response.data.userMedia[0]}`;
				// currentBlock.data.staticData[index][key] = `https://cdn.qafto.com/${user?.uniqueId}/${response.data.userMedia[0]}`;
				setCurrentBlock(...currentBlock);
			})
			.catch((error) => {
				// console.log('get user media error : ', error);
				if (error.response) {
					toast.error(error.response.data.message);
				} else {
					toast.error("Something went wrong!");
				}
			})
			.finally(() => {
				setIsAnimating(false);
			});
	};

	const uploadMediafromDevice = async (profilePic, index, key, isMedia) => {

		if (profilePic == null) {
			toast.error("Select Image to change your Profil picture.");
			return;
		}

		// setUpdatingImage(false);
		setIsAnimating(true);

		const filesData = new FormData(); // use FormData else it will give 'data too long' error

		Array.from(profilePic).forEach((image) => {
			filesData.append("profilePic", image);
		});

		await dbCall.post('/user/upload-user-media', filesData).then(async (response) => {
			console.log('profile pic response : ', response);
			toast.success(response.data.message);
			if (index == undefined && key == undefined && isMedia == undefined) {
				const image = `${process.env.REACT_APP_USER_CDN}/${user?.uniqueId}/${response.data.keys[0]}`
				console.log("asdadasda uploda", image)
				setCurrentFieldObject({
					...currentFieldObjeect,
					defaultValue: image
				})
				return image
			}
			if (isMedia) {
				const length = currentBlock.data.staticData[index][key].length;
				currentStaticData[index][key].splice(length, 0, `${process.env.REACT_APP_USER_CDN}/${user?.uniqueId}/${response.data.keys[0]}`);
				currentBlock.data.staticData[index][key].splice(length, 0, `${process.env.REACT_APP_USER_CDN}/${user?.uniqueId}/${response.data.keys[0]}`);
				let newArr = [...media]
				newArr.splice(length)
				media.splice(length, 0, `${process.env.REACT_APP_USER_CDN}/${user?.uniqueId}/${response.data.keys[0]}`)
				setMedia([...media])
			} else {
				console.log(currentStaticData[index][key], "CUrrent bock")
				currentStaticData[index][key] = `${process.env.REACT_APP_USER_CDN}/${user?.uniqueId}/${response.data.keys[0]}`;
				currentBlock.data.staticData[index][key] = await `${process.env.REACT_APP_USER_CDN}/${user?.uniqueId}/${response.data.keys[0]}`;
				setCurrentBlock({ ...currentBlock });
			}

		})
			.catch((error) => {
				console.log(error)
				// console.log('profile pic error : ', error);
				if (error.response) {
					toast.error(error.response.data.message);
				} else {
					toast.error("Something went wrong!");
				}
			})
			.finally(() => {
				console.log("AKSF")
				setIsAnimating(false);
			});
	};

	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
	const handleValidation = (userInput) => {
		// Check if the user input is a valid JSON array
		try {
			const parsedArray = JSON.parse(userInput);

			// Check if the parsed value is an array
			if (Array.isArray(parsedArray)) {
				return true
			} else {
				return false
			}
		} catch (error) {
			return false
		}
	};

	return (
		<div className="w-full p-4 h-full overflow-y-auto no-scrollbar">
			{/* START - collection type */}
			<p >Show Attributes</p>

			{addField && (
				<div
					className={`${addField ? "fixed" : "hidden"
						} top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center bg-[rgba(0,0,0,0.5)] h-screen w-screen z-50`}
				>
					<div className="w-[50%] h-[50%] bg-white rounded-xl relative overflow-hidden">
						{/* Header */}
						<div className="border-b p-4 h-[20%]">
							<div className="flex items-center justify-between ">
								<h1 className="text-xl font-medium">Choose field type</h1>
								<IconX
									onClick={() => {
										setIsOpenStaticTable(true);
										setAddField(false);
										setFieldType(true);
										setMinMax(false)
										setCheckJsonValid(-1)
									}}
								/>
							</div>
							<p className="text-gray-500">
								You can connect each field to a page element to display its
								content on your site.
							</p>
						</div>
						{fieldType ? (
							<>
								<div className="min-h-[70%] max-h-[70%] overflow-auto no-scrollbar">
									<div className="grid grid-cols-3 gap-4 p-4 ">
										{
											Fields?.map((data, index) => (
												<div
													className={`shadow-lg border-[1px] rounded-md pl-4 pb-6 pt-2 cursor-pointer ${currentField?.index == index &&
														"border-blue-400 shadow-blue-100"
														}`}
													onClick={() => {
														setCurrentField({ ...data, index });
													}}
												>
													<p className="text-sm">{data?.name}</p>
													<p className="text-gray-500 text-xs font-normal">
														{data?.description}
													</p>
												</div>
											))}
									</div>
								</div>
								<div className="flex items-center justify-end absolute bottom-1 right-4 h-[10%] bg-white w-full">
									<button
										type="button"
										className="text-blue-400 border border-blue-400 rounded-full px-4 py-1 text-sm"
										onClick={() => {
											setIsOpenStaticTable(true);
											setAddField(false);
											setCurrentField({})
											setCurrentField({ index: -1 })
										}}
									>
										Cancel
									</button>
									<button
										type="button"
										className="bg-blue-500 border text-white text-sm border-blue-400 rounded-full py-1 px-4 ml-2"
										onClick={() => {
											currentField?.index != -1 && setFieldType(false);
											setCurrentFieldObject({
												...currentFieldObjeect,
												type: currentField?.name
											})
										}}
									>
										Choose Field Type
									</button>
								</div>
							</>
						) : (
							<>
								<div className="min-h-[70%] max-h-[70%] overflow-auto no-scrollbar p-4">
									<div className="flex w-full items-center mb-4">
										<div className="basis-[40%]">
											<label className="text-sm text-gray-800 mt-4 font-medium mb-2">
												Field type <span className="text-blue-700">*</span>{" "}
											</label>
											<div class="relative">
												<div class="absolute inset-y-0 start-0 flex items-center ps-3  pointer-events-none">
													{
														currentField.name == "String" ?
															<IconTextSize className="text-xl font-thin" />
															:
															currentField.name == "Number" ?
																<IconNumber className="text-xl font-thin" />
																:
																currentField.name == "URL" ?
																	<IconLink className="text-xl font-thin" />
																	:
																	currentField.name == "Boolean" ?
																		<IconCircleCheck className="text-xl font-thin text-gray-600" />
																		:
																		currentField.name == "Image" ?
																			<IconPhoto className="text-xl font-thin text-gray-600" />
																			:
																			currentField.name == "Array" ?
																				<p className="prevent-select">[...]</p>
																				:
																				currentField.name == "Object" &&
																				<p className="prevent-select">{"{...}"}</p>
													}
												</div>
												<input
													type="text"
													id="default-search"
													class="block w-[100%] text-sm font-normal p-4 ps-10 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
													value={currentField?.name}
													required
												/>
											</div>
										</div>
										<div className="basis-[40%] ml-10">
											<label className="text-sm text-gray-800 mt-4 font-medium mb-2">
												Field Name <span className="text-blue-700">*</span>{" "}
											</label>
											<div class="relative">
												{/* <div class="absolute inset-y-0 start-0 flex items-center ps-3  pointer-events-none">
													<IconTextSize className="text-xl font-thin" />
												</div> */}
												<input
													type="text"
													id="default-search"
													class="block w-[100%] text-sm font-normal p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
													placeholder="Enter Field Name"
													// value=""
													required
													onChange={(e) => {
														setFieldName(e.target.value)
													}}
												/>
											</div>
										</div>
									</div>
									<hr />
									{
										currentField.name != "Media" &&
										<div className="flex flex-col mb-4">
											<label className="text-sm text-gray-800 mt-4 font-medium mb-2">
												Default Value
											</label>
											<div class="relative">
												{
													currentField?.name == "Boolean" ?
														<select onChange={(e) => {
															setCurrentFieldObject({
																...currentFieldObjeect,
																defaultValue: e.target.value
															})
														}} className="rounded-lg">
															<option value="none" selected disabled hidden>Choose a value</option>
															<option>true</option>
															<option>false</option>
														</select>
														:
														currentField?.name == "Image" ?
															<div className="w-10 h-10 ">
																{/* <ArrowUpTrayIcon className="w-6 h-6" /> */}
																<input
																	type="file"
																	className="absolute"
																	multiple="false"
																	accept="image/png, image/gif, image/jpeg"
																	onChange={async (e) => {
																		await uploadMediafromDevice(
																			e.target.files
																		);
																	}}
																/>
															</div>
															:
															currentField?.name == "Array" ?
																<div
																	className={`flex items-center relative gap-3 z-0 w-full`}
																>
																	<textarea
																		className="font-bold blue-gray border-none outline-none truncate bg-blue-50 rounded-lg w-full"
																		type="text"
																		onChange={async (e) => {
																			const checkJson = handleValidation(e.target.value)
																			setCurrentFieldObject({
																				...currentFieldObjeect,
																				defaultValue: e.target.value,
																				type: "Array"
																			})
																			if (checkJson) {
																				setCheckJsonValid(false)
																			} else {
																				setCheckJsonValid(true)
																			}
																		}}
																	/>
																	{
																		checkJsonValid &&
																		<img src="https://gcdnb.pbrd.co/images/VHClgajkFjMG.png?o=1" class="absolute right-0 mr-2 w-5 top-2 animate-" id="alert" alt="Search Icon" title={`Input is Not a valid Array. Array syntax is [1,2,3]`} />
																	}
																</div>
																:
																currentField?.name == "Object" ?
																	<div
																		className={`flex items-center relative gap-3 z-0 w-full`}
																	>
																		<textarea
																			className="font-bold blue-gray border-none outline-none truncate bg-blue-50 rounded-lg w-full"
																			type="text"
																			onChange={async (e) => {
																				const checkJson = isJsonString(e.target.value)
																				setCurrentFieldObject({
																					...currentFieldObjeect,
																					defaultValue: e.target.value,
																					type: "Json"
																				})
																				if (checkJson) {
																					setCheckJsonValid(false)
																				} else {
																					setCheckJsonValid(true)
																				}
																			}}
																		/>
																		{
																			checkJsonValid &&
																			<img src="https://gcdnb.pbrd.co/images/VHClgajkFjMG.png?o=1" class="absolute right-0 mr-2 w-5 top-2 animate-" id="alert" alt="Search Icon" title={`Input is Not a valid Array. Array syntax is [1,2,3]`} />
																		}
																	</div>
																	:
																	<>
																		<input
																			type={(currentField.name == "String" || currentField.name == "URL") ? "text" : "number"}
																			id="default-search"
																			class="block w-[30%] text-sm font-normal p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
																			placeholder="Enter Default Value"
																			// value=""
																			required
																			onChange={(e) => {
																				setCurrentFieldObject({
																					...currentFieldObjeect,
																					defaultValue: e.target.value
																				})
																			}}
																		/>
																	</>
												}
											</div>
										</div>
									}
									<hr />
									<div className="flex flex-col mb-4">
										<label className="text-base	 text-gray-800 mt-4 font-medium mb-2">
											Validation
										</label>
										<div class="flex items-center ">
											<input type="checkbox" className="rounded-xl mr-2" onClick={(e) => {
												setCurrentFieldObject({
													...currentFieldObjeect,
													required: e?.target?.checked
												})
											}} />
											<p className="text-gray-700">Make this field Required</p>
										</div>
										{
											currentField.name == "String" &&
											<div class="flex items-center my-2">
												<input type="checkbox" className="rounded-xl mr-2" onChange={(e) => {
													setMinMax(!minMax)
												}} />
												<p className="text-gray-700">Set the limit of character</p>
											</div>
										}
										{
											minMax &&
											<div class="flex items-center justify-start">
												<div className="flex flex-col w-[10%] ">
													<label className="mt-4 font-medium mb-2 text-blue-500">
														Min
													</label>
													<input type="number" className="rounded-xl mr-2 w-[100%]" min="0"
														onChange={(e) => {
															setCurrentFieldObject({
																...currentFieldObjeect,
																minValue: e.target.value
															})
														}}
													/>
												</div>
												<p className="text-sm text-gray-700 mt-12 mx-2">and</p>
												<div className="flex flex-col">
													<label className="mt-4 font-medium mb-2 text-blue-500">
														Max
													</label>
													<input type="number" className="rounded-xl mr-2 w-[30%]"
														onChange={(e) => {
															setCurrentFieldObject({
																...currentFieldObjeect,
																maxValue: e.target.value
															})
														}} />
												</div>
											</div>
										}
									</div>
								</div>
								<div className="flex items-center justify-end absolute bottom-1 right-4 h-[10%] bg-white w-full">
									<button
										type="button"
										className="text-blue-400 border border-blue-400 rounded-full px-4 py-1 text-sm"
										onClick={() => {
											setFieldType(true);
											setMinMax(false)
											setCheckJsonValid(-1)
										}}
									>
										Back
									</button>
									<button
										type="button"
										className="bg-blue-500 border text-white text-sm border-blue-400 rounded-full py-1 px-4 ml-2"
										onClick={async () => {
											if (fieldName.length > 0) {
												await currentBlock?.data?.staticData?.forEach(function (
													item
												) {
													(currentFieldObjeect.type == "Array" || currentFieldObjeect.type == "Media") ?
														item[`${fieldName}`] = currentFieldObjeect.defaultValue ? JSON.parse(currentFieldObjeect?.defaultValue) : []
														:
														item[`${fieldName}`] = currentFieldObjeect.defaultValue ? currentFieldObjeect?.defaultValue : "";
												});
												if (!currentBlock.data["staticDataType"]) {
													currentBlock.data["staticDataType"] = {}
												}
												currentBlock.data.staticDataType[fieldName] = { ...currentFieldObjeect }
												// console.log(currentFieldObjeect,currentBlock)
												currentStaticData = currentBlock.data.staticData
												setCurrentBlock({ ...currentBlock });
												setCurrentBlock({ ...currentBlock })
												setFieldType(false);
												setIsOpenStaticTable(true);
												setMinMax(false)
												setCheckJsonValid(-1)
											}
										}}
									>
										Save
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			)
			}

			{/* {!isListDynamic && ( */}
			<Transition
				appear
				show={isOpenStaticTable ? isOpenStaticTable : false}
				as={Fragment}
			>
				<Dialog as="div" className="relative z-[51]" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-60" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center w-[100vw]">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="relative w-[90vw] h-[90vh] transform overflow-auto rounded-2xl bg-white px-6 text-left align-middle shadow-xl transition-all ">
									<Transition appear show={isMedia} as={Fragment}>
										<Dialog as="div" className="relative z-[52]" onClose={() => setIsMedia(false)}>
											<Transition.Child
												as={Fragment}
												enter="ease-out duration-300"
												enterFrom="opacity-0"
												enterTo="opacity-100"
												leave="ease-in duration-200"
												leaveFrom="opacity-100"
												leaveTo="opacity-0"
											>
												<div className="fixed inset-0 bg-black bg-opacity-60" />
											</Transition.Child>

											<div className="fixed inset-0 overflow-y-auto">
												<div className="flex min-h-full items-center justify-center p-4 text-center w-[100vw]">
													<Transition.Child
														as={Fragment}
														enter="ease-out duration-300"
														enterFrom="opacity-0 scale-95"
														enterTo="opacity-100 scale-100"
														leave="ease-in duration-200"
														leaveFrom="opacity-100 scale-100"
														leaveTo="opacity-0 scale-95"
													>
														<Dialog.Panel className="w-[50vw] h-[50vh] bg-white px-6 text-left align-middle shadow-xl transition-all">
															<Dialog.Title
																as="h3"
																className="text-lg font-medium leading-6 text-gray-900"
															>
																<div className="flex items-center justify-between my-4">
																	<h1 className="inline">
																		Your Media
																	</h1>
																	<div className="flex items-center justify-center cursor-pointer w-[10%] mr-10">
																		<div className="w-[50px] h-[50px] ">
																			{/* <ArrowUpTrayIcon className="w-6 h-6" /> */}
																			<input
																				type="file"
																				className="absolute opacity-0 w-16 "
																				multiple="true"
																				accept="image/png, image/gif, image/jpeg"
																				onChange={async (e) => {
																					//   setFiles(e.target.files);
																					// uploadFile(e);
																					const index = await media[media.length - 1].split("_")[0];
																					const key = await media[media.length - 1].split("_")[1];
																					uploadMediafromDevice(
																						e.target.files,
																						index,
																						key,
																						true
																					);
																				}}
																			/>
																			<button type="button" className="bg-blue-400 p-2  rounded-lg text-white text-sm border-none outline-none ">AddMedia</button>
																		</div>
																		{/* <IconEdit className="h-5 w-5 text-gray-700 cursor-pointer" /> */}
																	</div>
																</div>

															</Dialog.Title>
															<div class="grid grid-cols-2 md:grid-cols-4 gap-4 h-[70%] w-full overflow-auto no-scrollbar">
																{
																	media?.map((data, i) => (
																		<>
																			{
																				i != media?.length - 1 &&
																				<div className="group relative lightbox transition-all duration-500 group-hover:scale-105 tobii-zoom">
																					<img className="w-full aspect-[1.3] rounded-md lightbox transition-all duration-500 group-hover:scale-105 tobii-zoom object-cover" src={data} alt="" />
																					<div class="absolute -top-52 group-hover:top-0 right-0 transition-all duration-500 bg-none  p-1 rounded  text-white flex items-center justify-center bg-slate-700">
																						{/* <IconEdit className='h-6 w-6 cursor-pointer'
																							onClick={async () => {


																							}} /> */}
																						<IconTrash className='h-6 w-6 ml-2 text-red-400 cursor-pointer'
																							onClick={async () => {
																								const updateMedia = await media.splice(i, 1)
																								setMedia([...media])
																								const index = await media[media.length - 1].split("_")[0];
																								const key = await media[media.length - 1].split("_")[1];
																								// currentBlock?.data?.staticData[index][key].splice(i, 1)
																								const sice = await currentStaticData[index][key].splice(i, 1)

																								console.log(sice, currentStaticData, "INDEX")
																							}}
																						/>
																					</div>
																				</div>
																			}
																		</>
																	))
																}
															</div>
															<div className="flex items-center justify-end sticky bottom-0 left-[90%] h-[10%] bg-white w-fit ">
																<button
																	type="button"
																	className="text-blue-400 border border-blue-400 rounded-full px-4 py-1 text-sm"
																	onClick={() => setIsMedia(false)}
																>
																	Cancel
																</button>
																<button
																	type="button"
																	className="bg-blue-500  border text-white text-sm border-blue-400 rounded-full py-1 px-4 ml-2"
																	onClick={() => {
																		// currentBlock.data.staticData = JSON.parse(JSON.stringify(currentStaticData))
																		console.log(currentStaticData, "currentStaticData")
																		updateComponentSchema(
																			currentBlock?.id,
																			JSON.parse(JSON.stringify(currentStaticData)),
																			"UPDATE_STATIC_DATA_ARRAY"
																		);
																		// setUpdateStaticData(!updateStaticData);
																		setIsMedia(false)
																	}}
																>
																	Save
																</button>
															</div>
														</Dialog.Panel>
													</Transition.Child>
												</div>
											</div>
										</Dialog>
									</Transition>
									<div className="h-full w-full">
										<div
											floated={false}
											shadow={false}
											className="rounded-none"
										></div>
										<div className=" px-0 "
											onClick={() => {
												setShowArray(-1)
											}}
										>
											{/* <IconX className="text-gray-700 translate-x-4"
													onClick={closeModal}
												/> */}

											<p className="text-xs sticky left-0 z-50 w-fit inline"><span className="text-red-500 text-lg">*</span> Click to edit any value.</p>
											{/* <div className="flex items-center justify-between my-2 sticky left-[90%] z-50 inline ">
												<IconX className="text-gray-700  "
													onClick={closeModal}
												/>
											</div> */}
											<table className="w-full table-auto text-left mr-4 max-h-[50%]">
												<thead className="bg-blue-50 sticky z-50 top-0 w-full">
													<tr className="w-full">
														{(currentBlock?.data?.staticData && currentBlock?.data?.staticDataType) &&
															Object?.keys(
																// Object.keys(lastRecod).length > 0 ? lastRecod : currentBlock?.data?.staticData?.[0]
																currentBlock?.data?.staticDataType
															)?.map((key, index) => (
																<th
																	key={key}
																	className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 border-r-[1px] border-r-gray border-l"
																>
																	<div className="flex items-center justify-between ">
																		<p
																			variant="small"
																			color="blue-gray"
																			className="font-normal leading-none opacity-70"
																		>
																			{key}<span className="text-xs ml-2">{currentBlock?.data?.staticDataType?.[key] && "("} {currentBlock?.data?.staticDataType?.[key]?.type} {currentBlock?.data?.staticDataType?.[key] && ")"}</span>
																			<span className="text-red-500">{currentBlock?.data?.staticDataType?.[key]?.required && "*"}</span>
																		</p>
																		<IconTrash
																			className="text-red-500 cursor-pointer text-[10px] font-thin w-5 h-5"
																			onClick={() => {
																				// currentBlock?.data?.staticData.splice(index, 1)

																				currentBlock?.data?.staticData?.forEach(
																					function (item) {
																						delete item[`${key}`];
																					}
																				);

																				delete currentBlock.data.staticDataType[key]
																				console.log(currentBlock.data.staticData)
																				setCurrentBlock({ ...currentBlock });
																				currentStaticData = currentBlock.data.staticData
																				// setCurrentStaticData(currentBlock?.data?.staticData)
																			}}
																		/>
																	</div>
																</th>
															))}
														<th
															className="border-y border-blue-gray-100 bg-blue-gray-50/50 border-r-[1px] border-r-gray text-center bg-blue-100 cursor-pointer"
															onClick={async () => {
																console.log("run");
																setAddField(true);
																setIsOpenStaticTable(false);
																setFieldType(true);
															}}
														>
															<div className=" flex items-center justify-center text-center  w-full">{/*w-[100px]*/}
																<p className="font-light leading-none text-4xl text-gray-500 ">
																	+
																</p>
															</div>
														</th>
													</tr>
												</thead>
												{/* <AddPageModal isOpen2={isOpen2} closeModal2={closeModal2} /> */}

												<tbody className="h-[90%]">
													{
														currentBlock?.data?.staticData?.length > 0 &&
														currentBlock?.data?.staticData?.map((data, index) => {
															// {[...currentStaticData]?.map((data, index) => {
															const isLast =
																index ===
																currentBlock?.data?.staticData.length - 1;
															const classes = isLast
																? "p-4 border-b border-blue-gray-50 border-l border-r-[1px] "
																: "p-4 border-b border-blue-gray-50 border-l border-r-[1px] w-1/4";

															return (
																<tr key={data.name}>
																	{currentBlock?.data?.staticData &&
																		Object?.keys(
																			currentBlock?.data?.staticData?.[0]
																		)?.map((key, i) => {
																			// const imageCheck = isImage(
																			// 	currentBlock?.data?.staticData[index][key]
																			// );
																			const type = currentBlock?.data?.staticDataType?.[key]?.type
																			return (
																				<td
																					// className={`${classes} ${imageCheck && "w-[100px]"
																					// 	}`}
																					className={`${classes}`}
																					onClick={() => {
																						console.log("akjbfhja");
																					}}
																				>

																					{
																						// !imageCheck ? (
																						(type == "String" || type == "Number") && (
																							<div
																								className={`flex flex-col`}
																							>
																								<input
																									//   className="font-bold blue-gray border-none outline-none focus:bg-blue-100 truncate w-full"
																									className="font-bold blue-gray border-none outline-none focus:bg-blue-100 truncate bg-blue-50 rounded-lg"
																									type={type == "String" ? "text" : "number"}
																									value={currentStaticData?.[index]?.[key]}
																									onChange={(e) => {
																										console.log(e.target.value)
																										if (key == "id") return
																										console.log("akjbfhjaasdjhjhhj");
																										const value = currentBlock.data.staticDataType[key]
																										if (value?.maxValue && e.target.value.length > parseInt(value?.maxValue)) {
																											setValidationError({
																												...validationError,
																												[key]: `you can not enter greater then ${value?.maxValue} words_${index}`
																											})
																										} else {
																											currentStaticData[index][key] = e.target.value;
																											setCurrentStaticData([...currentStaticData])
																											// currentBlock.data.staticData[index][key] = e.target.value
																											// setCurrentStaticData([...currentBlock.data.staticData])
																											delete validationError[key]
																											setValidationError({ ...validationError })
																											if (value?.minValue && e.target.value.length < parseInt(value?.minValue)) {
																												setValidationError({
																													...validationError,
																													[key]: `minimum character  ${value?.minValue} required_${index}`
																												})
																											}
																										}

																									}}
																								/>
																								{
																									(validationError[key] && validationError[key]?.split("_")[1] == index) &&
																									<p className="text-xs text-red-500">*{validationError[key].split("_")[0]}</p>
																								}
																							</div>
																						)}
																					{
																						// !imageCheck ? (
																						!type && (
																							<div
																								className={`flex items-center gap-3`}
																							>
																								<input
																									//   className="font-bold blue-gray border-none outline-none focus:bg-blue-100 truncate w-full"
																									className="font-bold blue-gray border-none outline-none focus:bg-blue-100 truncate bg-blue-50 rounded-lg"
																									type="text"
																									value={currentStaticData?.[index]?.[key]}
																									onChange={(e) => {
																										if (key == "id") return
																										currentStaticData[index][key] = e.target.value;
																										setCurrentStaticData([...currentStaticData])

																									}}
																								/>
																							</div>
																						)}
																					{
																						// !imageCheck ? (
																						type == "Object" && (
																							<div
																								className={`flex justify-end items-center relative gap-3 z-0`}
																							>
																								<textarea
																									//   className="font-bold blue-gray border-none outline-none focus:bg-blue-100 truncate w-full"
																									className="font-bold blue-gray border-none outline-none focus:bg-blue-100 truncate bg-blue-50 rounded-lg"
																									type="text"
																									value={currentStaticData?.[index]?.[key]}
																									// value={currentBlock.data.staticData[index][key]}
																									onChange={async (e) => {
																										const checkJson = isJsonString(e.target.value)
																										currentStaticData[index][key] = e.target.value;
																										setCurrentStaticData([...currentStaticData])
																										// console.log(checkJson, "CheckJSON")
																										if (checkJson) {
																											setCheckJsonValid(-1)
																										} else {
																											setCheckJsonValid(index)
																										}
																									}}
																								/>
																								{
																									checkJsonValid == index &&
																									<img src="https://gcdnb.pbrd.co/images/VHClgajkFjMG.png?o=1" class="absolute mr-2 w-5 top-2 animate-" id="alert" alt="Search Icon" title={`Input is Not a valid Json. JSon syntax is {"key":"Value"}`} />
																								}
																							</div>
																						)}
																					{
																						type == "Array" && (
																							<div className="gap-3">
																								<button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" class="font-medium text-sm truncate w-[150px]" type="button"
																									onDoubleClick={() => {
																										setShowArray(showArray == index ? -1 : index)
																									}}
																								>
																									{JSON.stringify(currentBlock.data.staticData?.[index]?.[key])}
																								</button>
																								<div id="dropdown" class={`z-10 ${showArray != index && 'hidden'} absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-[250px] bg-gray-500`}
																									onClick={(e) => {
																										e.stopPropagation()
																									}}>
																									<ul class={`py-2 ${currentBlock?.data?.staticData?.[index]?.[key] == null && 'hidden'} text-sm text-gray-700 overflow-hidden`}>
																										{
																											currentBlock?.data?.staticData?.[index]?.[key]?.map((data, i) => (
																												<li className="p-2 flex items-center justify-between">
																													<input type="text" value={data} class="block w-full text-white bg-gray-700 rounded-lg border-none outline-none"
																														onChange={(e) => {
																															currentBlock.data.staticData[index][key][i] = e.target.value
																															currentStaticData[index][key][i] = e.target.value
																															setCurrentBlock({ ...currentBlock })
																														}}
																													/>
																													<IconX className="w-5 h-5 ml-2 text-gray-200" onClick={() => {
																														currentBlock.data.staticData[index][key].splice(i, 1)
																														currentStaticData[index][key].splice(i, 1)
																														setCurrentBlock({ ...currentBlock })
																													}} />
																												</li>
																											))
																										}
																									</ul>
																									<ul class={`py-2 text-sm text-gray-700 overflow-hidden`}>
																										<li className="p-2 flex items-center justify-between">
																											<input type="text" class="block w-full text-white bg-gray-700 rounded-lg border-none outline-none"
																												value={arrayValue}
																												onChange={(e) => {
																													setArrayValue(e.target.value)
																												}}
																											/>
																											<button
																												type="button"
																												className="bg-blue-500 text-white rounded-lg px-4 py-2 ml-4"
																												onClick={() => {
																													if (currentBlock.data.staticData[index][key] == null) {
																														currentBlock.data.staticData[index][key] = [arrayValue]
																														// currentStaticData[index][key] = [arrayValue]
																														setCurrentBlock({ ...currentBlock })
																														setArrayValue("")
																													} else {
																														currentBlock.data.staticData[index][key].push(arrayValue)
																														// currentStaticData[index][key].push(arrayValue)
																														setCurrentBlock({ ...currentBlock })
																														setArrayValue("")
																													}
																												}}
																											>
																												Add
																											</button>
																										</li>
																									</ul>
																								</div>
																							</div>
																						)}
																					{
																						type == "Image" &&
																						<div className={`flex items-center gap-3 w-[150px]`}>
																							<div className="flex items-center justify-between w-full ">
																								<img
																									src={
																										currentBlock?.data
																											?.staticData[index][key]
																									}
																									className="h-[60px] w-[60px] rounded-full"
																								/>
																								<div className="flex items-center justify-center cursor-pointer">
																									<div className="w-10 h-10 ">
																										{/* <ArrowUpTrayIcon className="w-6 h-6" /> */}
																										<input
																											type="file"
																											className="absolute opacity-0 w-16 "
																											multiple="true"
																											accept="image/png, image/gif, image/jpeg"
																											onChange={async (e) => {
																												//   setFiles(e.target.files);
																												// uploadFile(e);
																												uploadMediafromDevice(
																													e.target.files,
																													index,
																													key,
																													false
																												);
																											}}
																										/>
																									</div>
																									<IconEdit className="h-5 w-5 text-gray-700 cursor-pointer" />
																								</div>
																							</div>
																						</div>
																					}
																					{
																						type == "Media" &&
																						<div className={`flex items-center gap-3 w-[200px]`}>
																							<div className="flex items-center justify-between w-full ">
																								{
																									currentBlock?.data?.staticData?.[index]?.[key]?.[0] &&
																									<img
																										src={
																											currentBlock?.data
																												?.staticData?.[index]?.[key]?.[0]
																										}
																										className="h-[60px] w-[60px] rounded-full"
																									/>
																								}
																								{
																									currentBlock?.data?.staticData?.[index]?.[key]?.[1] ?
																										<img
																											src={
																												currentBlock?.data
																													?.staticData?.[index]?.[key]?.[1]
																											}
																											className="h-[60px] w-[60px] rounded-full"
																										/>
																										:
																										<div className="h-[60px] w-[60px] rounded-full"></div>
																								}
																								<div className="flex items-center justify-center cursor-pointer">
																									<div className="w-10 h-10 ">
																									</div>
																									<IconEdit className="h-5 w-5 text-gray-700 cursor-pointer"
																										onClick={() => {
																											setIsMedia(true)
																											console.log(currentBlock?.data?.staticDataType[key], "Current Media")
																											setMedia([...currentBlock?.data?.staticData[index][key], `${index}_${key}`])
																										}}
																									/>
																								</div>
																							</div>
																						</div>
																					}
																					{
																						type == "Boolean" &&
																						<div className={`flex items-center gap-3 w-[200px]`}>
																							<div className="flex items-center justify-between w-full ">
																								<div className="flex items-center justify-center cursor-pointer">
																									<input type="checkbox"
																										checked={currentBlock?.data?.staticData[index][key]}
																										// checked="true"
																										onChange={(e) => {
																											currentBlock.data.staticData[index][key] = e.target.checked
																											currentStaticData[index][key] = e.target.checked
																											setCurrentBlock({ ...currentBlock })
																										}}
																									/>
																								</div>
																							</div>
																						</div>
																					}
																				</td>
																			);
																		})}
																	<td className={classes}>
																		<div className="flex items-center justify-center w-full">
																			<IconTrash className="text-red-500 cursor-pointer text-[10px] font-thin w-5 h-5"
																				onClick={() => {
																					currentBlock?.data?.staticData.splice(index, 1)
																					setCurrentBlock({ ...currentBlock });
																				}} />
																		</div>
																	</td>
																</tr>
															);
														})}
												</tbody>
												<button
													type="button"
													className=" border-x-2 border-b-2 p-4 bg-blue-50 w-full"
													onClick={() => {
														const length = currentBlock.data.staticData.length;
														const currentData = currentBlock.data.staticData

														const newData = {}
														const dataType = currentBlock?.data?.staticDataType
														for (let [key, value] of Object.entries(dataType)) {
															console.log(key, value)
															if (value?.type == "Media" || value?.type == "Array") {
																newData[key] = []
															} else {
																newData[key] = ""
															}
														}
														currentData.push({
															...newData,
															id: length + 1
														})
														currentStaticData.push({
															...newData,
															id: length + 1
														})
														setCurrentBlock({ ...currentBlock });
													}}
												>Add Record +</button>
											</table>
										</div>
									</div>
									<div className="flex items-center justify-end sticky bottom-0 left-[90%] h-[10%] bg-white w-fit ">
										<button
											type="button"
											className="text-blue-400 border border-blue-400 rounded-full px-4 py-1 text-sm"
											onClick={closeModal}
										>
											Cancel
										</button>
										<button
											type="button"
											className="bg-blue-500  border text-white text-sm border-blue-400 rounded-full py-1 px-4 ml-2"
											onClick={() => {
												console.log(currentBlock, currentStaticData, "THis is currentStaticData")
												updateComponentSchema(
													currentBlock?.id,
													JSON.parse(JSON.stringify(currentBlock.data.staticData)),
													"UPDATE_STATIC_DATA_ARRAY"
												);
												setUpdateStaticData(!updateStaticData);
												setIsStaticDataUpdate(true);
												closeModal()
											}}
										>
											Save
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
			{/* )} */}

			<div className="mt-2 mb-4">
				<label
					htmlFor="collectionType"
					className="font-medium text-base text-gray-500"
				>
					Collection Type
				</label>
				<div className="mt-2">
					<select
						id="collectionType"
						name="collectionType"
						className=" block w-full rounded-md border-0 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
						onChange={(e) => {
							Bdispatch({
								type: "UPDATE_IS_DYNAMIC_DATA",
								id: currentBlock?.id,
								element: e.target.value ? true : false,
							});
							setCollectionType(e.target.value);
							setMapFields([]);
							setFieldsToMap(true);
							if (e.target.value == "product") {
								currentBlock.data.collection = "products";
							} else {
								currentBlock.data.collection = e.target.value;
							}
							if (e.target.value == "") {
								currentBlock.data.collection = "";
								updateComponentSchema("collectionType", e.target.value, false);
								updateComponentSchema("collectionDataOrder", "asc", false);
								setIsListDynamic({
									dynamicData: false,
								});
								setIsOpenStaticTable(false);
							}
							setIsListDynamic({
								dynamicData: true,
							});
							setUpdateStaticData(!updateStaticData);
							console.log(e.target.value)
						}}
					>
						<option
							value=""
							className=" flex items-center p-2 m-3 border border-gray-300 rounded-lg hover:bg-gray-200 cursor-pointer"
						>
							{/* {collectionType?.length > 0 ? collectionType : "None"} */}
							None
						</option>

						{strapiCollectionNames.map((item, index) => (
							<option
								key={index}
								value={item.schema.displayName}
								selected={
									item.schema.displayName === collectionType ? true : false
								}
								className=" flex items-center p-2 m-3 border border-gray-300 rounded-lg hover:bg-gray-200 cursor-pointer"
							>
								{item.schema.displayName}
							</option>
						))}
					</select>
				</div>
			</div>
			{/* END - collection type */}
			<hr className="border-t-[1px] border-gray-300" />
			{/* START - map strapi fields with components */}
			{
				fieldsToMap && (
					<>
						<div className="my-4">
							<p className="block text-base font-medium leading-6 text-gray-500">
								Map Fields
							</p>
							{attributes?.map((field, index) => (
								<div id={index} className="my-4 flex flex-col justify-between">
									<div className="flex justify-between items-center">
										<label
											htmlFor={field?.fieldName}
											className="capitalize block text-base font-medium leading-6 text-gray-500"
										>
											{field?.fieldName}
										</label>
										<select
											id={field.fieldName}
											name={field.fieldName}
											className="capitalize block rounded-md border-0 py-1.5 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
											onChange={async (e) => {
												console.log(e.target.value);
												await Bdispatch({
													type: "UPDATE_CONTENT",
													id: field?.id,
													element: e.target.value,
												});
												await Bdispatch({
													type: "UPDATE_IS_DYNAMIC",
													id: field?.id,
													element: e.target.value ? true : false,
												});
												// e.target.value.toLocaleLowerCase() == "" ? (field["dynamic"] = false) : (field["dynamic"] = true)
												// console.log(currentBlock)
											}}
										>
											<option
												value=""
												className="capitalize flex items-center p-2 m-3 border border-gray-300 rounded-lg hover:bg-gray-200 cursor-pointer"
											>
												None
											</option>

											{currentBlock?.data?.dynamicData ? (
												<>
													{Object?.keys(collectionFieldData)?.map(
														(key, index) => (
															<>
																{
																	(field.type == "img" && collectionFieldData[key].sub_type == "media_cdn") ?
																		<option
																			key={index}
																			value={key}
																			selected={key == field?.src ? true : false}
																			className="capitalize p-2 m-3 border border-gray-300 rounded-lg hover:bg-gray-200 cursor-pointer"
																		>
																			{key}
																		</option>
																		:
																		field.type != "img" &&
																		<option
																			key={index}
																			value={key}
																			selected={key == field.content ? true : false}
																			className="capitalize p-2 m-3 border border-gray-300 rounded-lg hover:bg-gray-200 cursor-pointer"
																		>
																			{key}
																		</option>
																}
															</>
														)
													)}
												</>
											) : (
												<>
													{(currentBlock?.data?.staticData && currentBlock?.data?.staticDataType) &&
														Object?.keys(
															// Object.keys(lastRecod).length > 0 ? lastRecod : currentBlock?.data?.staticData?.[0]
															currentBlock?.data?.staticDataType
														)?.map((key, index) => (
															<option
																key={index}
																value={key}
																selected={key == field.content ? true : false}
																className="capitalize p-2 m-3 border border-gray-300 rounded-lg hover:bg-gray-200 cursor-pointer"
															>
																{key}
															</option>
														))}
												</>
											)}
											{/* {
												collectionFieldData?.map((item, index) => (
													<option 
														key={index} 
														value={item?.name}
														// selected={item?.name == field.mapTo ? true : false}
														className='capitalize p-2 m-3 border border-gray-300 rounded-lg hover:bg-gray-200 cursor-pointer'
													>
														{item?.name}
													</option>
												))
											} */}
										</select>
									</div>
								</div>
							))}
							{strapiErrorMessage && (
								<p className="mt-1 text-red-600 text-sm">{strapiErrorMessage}</p>
							)}

							<hr className="border-t-[1px] border-gray-300" />
						</div>
					</>
				)
			}
			{/* END - map strapi fields with components */}
			{
				attributes?.map((field, index) => (
					<>
						<div key={index} className="my-4 flex flex-col justify-between">
							<div className="flex justify-between items-center">
								<label className="font-medium text-base text-gray-500 capitalize">
									{field?.fieldName}
								</label>
								{true && (
									<Switch
										checked={field?.style?.display == "hidden" ? false : true}
										onChange={() => {
											console.log(field);
											updateComponentSchema(
												field?.id,
												field["css"] ? field["css"] : "",
												"UPDATE_CSS"
											);
										}}
										className={`${field?.style?.display != "hidden"
											? "bg-indigo-600"
											: "bg-gray-200"
											} relative inline-flex h-5 w-11 items-center rounded-full`}
									>
										<span
											className={`${field?.style?.display != "hidden"
												? "translate-x-[27px]"
												: "translate-x-1"
												} inline-block h-[14px] w-[14px] transform rounded-full bg-white transition`}
										/>
									</Switch>
								)}
							</div>

							{errorMessage && fieldIndex == index && (
								<p className="mt-1 text-red-600 text-sm">{errorMessage}</p>
							)}
						</div>

						<hr className="border-t-[1px] border-gray-300" />
					</>
				))
			}
			{/* START - number of items */}
			{
				currentBlock?.data?.query &&
				<div className="mt-2 mb-4 flex items-start justify-between flex-col">
					<label
						htmlFor="numberOfItems"
						className="font-medium text-base text-gray-500"
					>
						Query
					</label>
					{
						// Object.key(currentBlock?.data?.query).length > 0 &&
						[currentBlock?.data?.query].map((data) => (
							<div className="flex items-center justify-between flex flex-col">
								<div className="flex items-center justify-between">
									<p>fieldName</p>
									<input
										type="text"
										className="w-[30%] border-[1px] border-gray-500 rounded-md pl-2 outline-none"
										placeholder={Object.keys(data)}
										value={Object.keys(data)}
										onChange={(e) => {
											Bdispatch({
												type: "UPDATE_QUERY",
												id: currentBlock.id,
												element: [e.target.value, data[Object.keys(data)], Object.keys(data)],
											});
											setUpdateStaticData(!updateStaticData);
										}}
									/>
								</div>
								<div className="flex items-center justify-between mt-2">
									<p>fieldValue</p>
									<input
										type="text"
										className="w-[30%] border-[1px] border-gray-500 rounded-md pl-2 outline-none"
										placeholder={data[Object.keys(data)]}
										value={currentBlock?.data?.dynamicData ? data[Object.keys(data)]?.["$contains"] : data[Object.keys(data)]}
										onChange={(e) => {
											Bdispatch({
												type: "UPDATE_QUERY",
												id: currentBlock.id,
												element: [Object.keys(data)[0], e.target.value],
											});
										}}
									/>
								</div>
							</div>
						))
					}

				</div>
			}
			<hr className="border-t-[1px] border-gray-300" />

			{
				isListDynamic?.isShowNumberOfItems &&
				<div className="mt-2 mb-4 flex items-center justify-between">
					<label
						htmlFor="numberOfItems"
						className="font-medium text-base text-gray-500"
					>
						Number of Items
					</label>
					<input
						type="number"
						className="w-[30%] border-[1px] border-gray-500 rounded-md pl-2 outline-none"
						placeholder={currentBlock?.data?.staticData?.length}
						onChange={(e) => {
							updateComponentSchema(
								currentBlock?.id,
								e.target.value,
								"UPDATE_STATIC_DATA"
							);
						}}
					/>

				</div>
			}
			{/* END - number of items */}

			<hr className="border-t-[1px] border-gray-300" />

			<div className="mt-2 mb-4 flex items-center justify-between">
				<label
					htmlFor="numberOfItems"
					className="font-medium text-base text-gray-500"
				>
					Show Discount
				</label>

				<input
					type="checkbox"
					name="discount"
					checked={elementId?.data?.showDiscount ? true : false}
					onChange={(e) => {
						Bdispatch({
							type: "CHANGE_ELEMENT_SCHEMA",
							id: elementId?.id,
							element: {
								showDiscount: e.target.checked
							},
						});
					}}
				/>

			</div>
			<div className="mt-2 mb-4 flex items-center justify-between">
				<label
					htmlFor="numberOfItems"
					className="font-medium text-base text-gray-500"
				>
					Show Rating
				</label>

				<input
					type="checkbox"
					name="discount"
					checked={elementId?.data?.showRating ? true : false}
					onChange={(e) => {
						Bdispatch({
							type: "CHANGE_ELEMENT_SCHEMA",
							id: elementId?.id,
							element: {
								showRating: e.target.checked
							},
						});
					}}
				/>

			</div>

			<hr className="border-t-[1px] border-gray-300" />

			<div className="mt-2 mb-4 flex items-center justify-between">
				<label
					htmlFor="numberOfItems"
					className="font-medium text-base text-gray-500"
				>
					Show Add To Cart
				</label>

				<input
					type="checkbox"
					name="discount"
					checked={elementId?.data?.addToCart}
					onChange={(e) => {
						Bdispatch({
							type: "CHANGE_ELEMENT_SCHEMA",
							id: elementId?.id,
							element: {
								addToCart: e.target.checked
							},
						});
					}}
				/>

			</div>
			<hr className="border-t-[1px] border-gray-300" />

			{
				isListDynamic?.isShowNavigateToPage &&
				<div className="mt-2 mb-4 flex items-center justify-between">
					<label
						htmlFor="numberOfItems"
						className="font-medium text-base text-gray-500"
					>
						Navigate to Single Page
					</label>

					<input
						type="checkbox"
						name="discount"
						onChange={(e) => {
							if (e.target.checked) {
								if (elementId?.children?.[0]) {
									if (!elementId?.children?.[0]?.events) {
										elementId.children[0].events = {};
										elementId.children[0].events.onClick = [
											{
												"task": "NavigateToProduct",
												"parameters": {
													"url": "product"
												}
											}
										];
									} else {
										if (!elementId.children[0]?.events?.onClick) {
											elementId.children[0].events.onClick = [
												{
													"task": "NavigateToProduct",
													"parameters": {
														"url": "product"
													}
												}
											]
										} else {
											elementId.children[0].events?.onClick.push({
												"task": "NavigateToProduct",
												"parameters": {
													"url": "product"
												}
											})
										}
									}
								}
							} else {
								if (elementId?.children?.[0]?.events?.onClick) {
									const task = elementId?.children?.[0]?.events?.onClick.filter(obj => obj.task != "NavigateToProduct");
									elementId.children[0].events.onClick = task;
								}
							}
						}}
					/>

				</div>
			}
		</div >
	);
};

export default ComponentSettings;

const AddPageModal = (isOpen2, closeModal2) => {
	return (
		<Transition appear show={true} as={Fragment}>
			<Dialog as="div" className="relative z-50" onClose={closeModal2}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-60" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center w-[100vw]">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-[80vw] h-[80vh] transform overflow-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ">
								<Dialog.Title
									as="h3"
									className="text-lg font-medium leading-6 text-gray-900"
								>
									Configure Static Data
								</Dialog.Title>
								<div className="h-full w-full">
									<h1>Helloo</h1>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
