import { useState } from "react";
import { Listbox } from "@headlessui/react";
import Field from "./Field";
const Dimension = ({ setOpenedSections, openedSections, setElementBreakpointSpecificStyleObject, updateDesign, elementBreakpointSpecificStyleObject, valueTypes, setValueTypes }) => {
	const [accordionOpen, setAccordianOpen] = useState(false);
	return (
		<>
			<div
				className={`flex items-center justify-between py-1.5 px-3 border-t bg-[#F3F4F6] text-[#373C47]  border-[#D9D9D9]  hover:bg-gray-200 cursor-pointer font-medium${openedSections.dimensions == true
					? " border-b"
					: ""
					}`}
				onClick={() => {
					setOpenedSections((prev) => {
						return {
							...prev,
							dimensions: !prev.dimensions,
						};
					});
				}}
			>
				<div >
					Dimensions
				</div>

				{openedSections.dimensions == false ? (
					<span class="mdi mdi-chevron-right text-xl"></span>
				) : (
					<span class="mdi mdi-chevron-down text-xl"></span>
				)}
			</div>
			{openedSections.dimensions == true && (
				<>
					<div className="divide-y divide-[#D9D9D9]">

						{[
							{
								displayName: "Width",
								propertyName: "width",
								options: [
									"auto",
									"min-content",
									"max-content",
									"fit-content",
									"px",
									"%",
									"vh",
									"vw"
								],
								logicalOptions: ["auto", 'min-content', "max-content", "fit-content"]
							},
							{
								displayName: "Height",
								propertyName: "height",
								options: [
									"auto",
									"min-content",
									"max-content",
									"fit-content",
									"px",
									"%",
									"vh",
									"vw"
								],
								logicalOptions: ["auto", 'min-content', "max-content", "fit-content"]
							},
						].map((propertyData, index) => {
							return (
								<Property key={index} propertyData={propertyData} valueTypes={valueTypes} setValueTypes={setValueTypes} updateDesign={updateDesign} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} />
							);
						})}
					</div>
					<div
						className={`flex items-center justify-center text-sm py-0.5	border-t bg-[#F3F4F6]  border-[#D9D9D9] text-[#373C47] hover:bg-gray-200 cursor-pointer font-medium${accordionOpen == true
							? " border-b"
							: ""
							}`}
						onClick={() => {
							setAccordianOpen(!accordionOpen);
						}}
					>
						<div>
							More options
						</div>

						{accordionOpen == false ? (
							<span class="mdi mdi-chevron-right text-xl"></span>
						) : (
							<span class="mdi mdi-chevron-down text-xl"></span>
						)}
					</div>
					{accordionOpen == true && (
						<div className="divide-y divide-[D9D9D9]">
							<div>

								{[
									{
										displayName: "Max Width",
										propertyName: "maxWidth",
										options: [
											"none",
											"min-content",
											"max-content",
											"fit-content",
											"px",
											"%",
											"vh",
											"vw"
										],
										logicalOptions: ["none", 'min-content', "max-content", "fit-content"]

									},
									{
										displayName: "Max Height",
										propertyName: "maxHeight",
										options: [
											"none",
											"min-content",
											"max-content",
											"fit-content",
											"px",
											"%",
											"vh",
											"vw"
										],
										logicalOptions: ["none", 'min-content', "max-content", "fit-content"]
									},
								].map((propertyData, index) => {
									return (
										<Property key={index} propertyData={propertyData} valueTypes={valueTypes} setValueTypes={setValueTypes} updateDesign={updateDesign} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} />
									);
								})}
							</div>
							<div>

								{[
									{
										displayName: "Min Width",
										propertyName: "minWidth",
										options: [
											"min-content",
											"max-content",
											"fit-content",
											"px",
											"%",
											"vh",
											"vw"
										],
										logicalOptions: ['min-content', "max-content", "fit-content"]
									},
									{
										displayName: "Min Height",
										propertyName: "minHeight",
										options: [
											"min-content",
											"max-content",
											"fit-content",
											"px",
											"%",
											"vh",
											"vw"
										],
										logicalOptions: ['min-content', "max-content", "fit-content"]
									},
								].map((propertyData, index) => {
									return (
										<Property key={index} propertyData={propertyData} valueTypes={valueTypes} setValueTypes={setValueTypes} updateDesign={updateDesign} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} />
									);
								})}
							</div>

						</div>
					)
					}
				</>
			)}
		</>
	)
}
export default Dimension;

const Property = ({ propertyData, elementBreakpointSpecificStyleObject, updateDesign, setElementBreakpointSpecificStyleObject, valueTypes, setValueTypes }) => {

	return (
		<div className="flex items-center justify-between relative py-2.5 px-3" >
			<div className="text-[#373C47]">
				{propertyData.displayName}
			</div>
			<Field propertyData={propertyData} valueTypes={valueTypes} setValueTypes={setValueTypes} updateDesign={updateDesign} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} />
		</div>
	)
}