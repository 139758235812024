import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { useContext } from 'react'
import { context } from '../../../../context/Context'
import { BlockWrapper } from '../Sortable'
import { randomString } from '../../../../libraries/utility'

const sortableOptions = {
	animation: 700,
	fallbackOnBody: false,
	swapThreshold: .5,

	ghostClass: "bg-blue-200",
	group: "bg-blue-600",
	forceFallback: false,
};
export default function Accordian({ block, blockIndex, handleClick, handleMouseOver }) {
	const { setElementId, Bdispatch, blocks, elementRef } = useContext(context)
	return (
		<div id={randomString()} className={`${block?.css}`}
			onClick={(event) => {
				event.stopPropagation();
				const id = event.target.id;
				console.log(id)
				handleClick(event, id, true)
			}}
			data-name="component"
			onMouseOver={(e) => handleMouseOver(e)}
		>
			{
				block?.children?.map((item, i) => {
					const itemJSON = JSON.parse(JSON.stringify(item))
					delete itemJSON["children"]
					delete itemJSON["css"]
					return (
						<Disclosure>
							{({ open }) => (
								<>
									<Disclosure.Button id={itemJSON?.id} className={`${item?.css}`}
										onKeyDown={(e) => {
											console.log(e.code)
											if (e.code == "Space") {
												e.preventDefault()
												return
											}
										}}
										onClick={(event) => {
											event.stopPropagation();
											const id = event.target.id;
											console.log(id)
											handleClick(event, itemJSON?.id, true, i)
										}}
									>
										<BlockWrapper block={itemJSON} blockIndex={[...blockIndex, i]} index={i} />
										<ChevronUpIcon
											className={`${open ? 'rotate-180 transform' : ''
												} h-5 w-5 text-purple-500`}
										/>
									</Disclosure.Button>
									<Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
										<div>
											{/* {
										item?.children?.map((item, i) => (
											<>
													<BlockWrapper block={item} blockIndex={[...blockIndex, i]} index={i} />
													</>
													))
												} */}
											<BlockWrapper block={item} blockIndex={[...blockIndex, i]} index={i} />
										</div>
									</Disclosure.Panel>
								</>
							)}
						</Disclosure>
					)
				})
			}
		</div>
	)
}
