import React, { useContext, useEffect, useState } from 'react'
import { ReactSortable } from 'react-sortablejs';
import { flushSync } from 'react-dom';
import "../../../../src/animate.css"
// form components
import FormikComponent from './form/FormikComponent';
import {
	EmailField,
	ShortTextField,
	LongTextField,
	SelectField,
	PasswordField,
	UploadField,
	RadioField,
	CheckboxField,
	DateTimeField,
	DateField,
	TimeField,
} from "./form/FormComponents";

// custom components
import Tabs from './customComponent/Tabs';
// import Table from './customComponent/Table';
import Accordian from './customComponent/Accordian';
import DataTable from './customComponent/DataTable';
import Dropdown from './customComponent/Dropdown';
import ProductList from './customComponent/ProductList';
import Product from './customComponent/Product';
import Carousal from './customComponent/Carousal';
import Map from './customComponent/Map';
import VideoPlayer from './customComponent/VideoPlayer';
import PopOver from './customComponent/PopOver';
import SingleProduct from './customComponent/SingleProduct';

import { changeId, randomString } from '../../../libraries/utility';
import { AddToUndo } from '../editor';

import { context } from '../../../context/Context';
import ScrollAnimations from './customComponent/ScrollAnimations';
import ScrollAnimation from 'react-animate-on-scroll';
import Carousal2 from './customComponent/Carousel2';
import DisclosureComponent from './customComponent/Disclosure';
import GoogleMap from './customComponent/GoogleMap';
import SocialLinks from './customComponent/SocialLinks';
import DisclosureButton from './customComponent/DisclosureButton';
import DisclosurePanel from './customComponent/DisclosurePanel';
import TabGroup from './customComponent/TabGroup';
import TabList from './customComponent/TabList';
import TabComponent from './customComponent/Tab';
import TabPanels from './customComponent/TabPanels';
import TabPanel from './customComponent/TabPanel';
import PopoverComponent from './customComponent/Popover';
import PopoverButton from './customComponent/PopoverButton';
import PopoverPanel from './customComponent/PopoverPanel';
import TimerPopup from './customComponent/TimerPopup';
import { useLocation, useParams } from 'react-router-dom';
import { dbCall } from '../../../common/db';
import { processChange } from '../../../layouts/NewEditor';
import { TypeAnimation } from 'react-type-animation';
import Form from './customComponent/Form';
import Input from './customComponent/Input';
import Ternary from './customComponent/Ternary';
import LogicalAnd from './customComponent/LogicalAnd';
import FieldError from './customComponent/FieldError';
import DynamicCarousal from './customComponent/DynamicCarousal';
import DynamicComponent from './customComponent/DynamicComponent';
import { generateContent, generateCss, generateSrc } from './functions/sortableFunctions';
import Loop from './customComponent/Loop';
import AComponent from './customComponent/A';
import Rating from './customComponent/Rating';
const sortableOptions = {
	animation: 700,
	fallbackOnBody: false,
	swapThreshold: .5,
	ghostClass: "bg-blue-200",
	group: "bg-blue-600",
	forceFallback: false,
};

const sortableOptions2 = {
	animation: 500,
	swapThreshold: .5,
	ghostClass: 'bg-blue-200'
};
// const componentsWithSettings = ['Carousel2', 'Navbar', 'Social Links'];

// Available CDN 

const qafto_CDN = {
	pictogrammer: '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@7.3.67/css/materialdesignicons.min.css" />',
	material: '<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@24,400,1,0" />'
}


export function deleteObjectById(arr, id) {
	for (let i = 0; i < arr?.length; i++) {
		const obj = arr[i];
		if (obj.id == id) {
			// If the object is found, remove it from the array
			arr.splice(i, 1);
			return arr;
		} else if (obj.children && obj?.children?.length > 0) {
			deleteObjectById(obj.children, id);
			// if (deleted) {
			//  return true;
			// }
		}
	}
	return false;
}

export default function RSortable() {
	const [key, setKey] = useState(0)
	const [isSort, setIsSort] = useState(true)
	const {
		grab,
		blocks,
		Bdispatch,
		elementId,
		currentTheme
	} = useContext(context)

	useEffect(() => {
		setIsSort(false)
	}, [grab])

	if (blocks.length == 0 || !blocks) {
		return <></>
	}
	return (
		<>
			<ReactSortable list={[...blocks]}
				setList={(current, sortable) => {
					if (sortable) {
						flushSync(() => {
							setTimeout(async () => {
								if (elementId.id) {
									const flexObj = {
										id: Date.now(),
										isSort: true,
										type: "div",
										css: "w-[100%] flex items-center justify-between bg-white",
										sort: true,
										children: [
											elementId
										]
									}
									Bdispatch({ type: "CLEAN" })
									Bdispatch({ type: "UPDATE", payload: flexObj, index: key })
								}
							}, 10)
						})
					}
				}
				}
				{...sortableOptions}
				sort={isSort}
				handle=".my-handle"
				onChange={(e) => {
					setKey(e.newDraggableIndex)
				}}
			>
				{blocks?.map((block, blockIndex) => (
					<BlockWrapper
						key={block.id}
						block={block}
						blockIndex={[blockIndex]}
						currentTheme={currentTheme}
					/>
				))}
			</ReactSortable>
		</>
	);
}

let currentArr = []
export function Container({ block, blockIndex, ...props }) {
	const {
		elementRef,
		blocks, Bdispatch,
		undo, setUndo
	} = useContext(context);
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (
		<>
			{
				props.dragOutsideNotAllowed ?

					< ReactSortable
						key={block.id}
						id={block.id}
						list={block.children}
						setList={(currentList, sortable) => {
							if (sortable) {
								flushSync(() => {
									const tempList = [...blocks];
									const _blockIndex = [...blockIndex];
									const lastIndex = _blockIndex?.pop();
									const lastArr = _blockIndex?.reduce(
										(arr, i) => arr?.[i]?.["children"],
										blocks
									);
									if (lastArr != undefined) {
										lastArr[lastIndex]["children"] = currentList;
										Bdispatch({ type: "ADD", payload: tempList })
										currentArr = tempList
									} else {
										const value = deleteObjectById(tempList, _blockIndex)
									}
								})
							}
						}}
						{...sortableOptions2}
						direction="vertical"
						className={generateCss({
							contextVariables: contextVariables,
							props: {
								...props,
								block: block
							},
							params: params,
							location: location,
							container: true
						})}
						onChange={(e) => {
							elementRef.current != null && (elementRef.current.style.outline = "none")
							elementRef.current = e.to;
							elementRef.current.style.outline = "1px solid #6366F1";
						}}
						onEnd={(e, sortable) => {
							if (undo?.length > 10) {
								undo.shift();
								setUndo({
									ui: [...undo.ui, JSON.parse(JSON.stringify(currentArr))],
									backend: [...undo.backend, ...undo.backend]
								});
							} else {
								setUndo({
									ui: [...undo.ui, JSON.parse(JSON.stringify(currentArr))],
									backend: [...undo.backend, ...undo.backend]
								});
							}
						}}
					>
						{block?.children &&
							block?.children?.map((childBlock, index) => {
								return (
									<BlockWrapper
										{...props}
										key={childBlock.id}
										block={childBlock}
										blockIndex={[...blockIndex, index]}
										index={index}

									/>
								);
							})}
					</ReactSortable >
					:
					<ReactSortable
						key={block.id}
						id={block.id}
						list={block.children}
						// setList={(sourceList) => {
						setList={(currentList, sortable) => {
							if (sortable) {
								flushSync(() => {
									const tempList = [...blocks];
									const _blockIndex = [...blockIndex];
									const lastIndex = _blockIndex?.pop();
									const lastArr = _blockIndex?.reduce(
										(arr, i) => arr?.[i]?.["children"],
										blocks
									);
									if (lastArr != undefined) {
										lastArr[lastIndex]["children"] = currentList;
										Bdispatch({ type: "ADD", payload: tempList })
										currentArr = tempList
									} else {
										const value = deleteObjectById(tempList, _blockIndex)
									}
								})
							}
						}}
						{...sortableOptions}
						direction="vertical"
						className={generateCss({
							contextVariables: contextVariables,
							props: {
								...props,
								block: block
							},
							params: params,
							location: location,
							container: true
						})}
						onChange={(e) => {
							elementRef.current != null && (elementRef.current.style.outline = "none")
							elementRef.current = e.to;
							elementRef.current.style.outline = "1px solid #6366F1"
						}}
						onEnd={(e, sortable) => {
							if (undo?.length > 10) {
								undo.shift();
								setUndo({
									ui: [...undo.ui, JSON.parse(JSON.stringify(currentArr))],
									backend: [...undo.backend, ...undo.backend]
								});
							} else {
								setUndo({
									ui: [...undo.ui, JSON.parse(JSON.stringify(currentArr))],
									backend: [...undo.backend, ...undo.backend]
								});
							}
						}}
					>
						{block?.children &&
							block?.children?.map((childBlock, index) => {
								return (
									<BlockWrapper
										{...props}
										key={childBlock.id}
										block={childBlock}
										blockIndex={[...blockIndex, index]}
										index={index}

									/>
								);
							})}
					</ReactSortable>
			}

		</>
	);
}

var valueY = 0
export function BlockWrapper({ block, blockIndex, ...props }) {
	const { href, for: ajd, ...rest } = block.data || {};
	const [currentId, setCurrentId] = useState({})
	const [addTop, setAddTop] = useState(false)
	const {
		element,
		elementRef,
		formElementRef,
		componentRef,
		grab,
		elementId,
		setElementId,
		setCordinates,
		Bdispatch,
		isRender,
		setIsRender,
		setEditableValue,
		formSelected,
		setFormSelected,
		setSelectedFormElementId,
		setElementIdOnMouseOver,
		formId,
		setFormId,
		setFormName,
		setDisplayDataTab,
		setAddNewFormElementCordinates,
		workingOnEvent,
		setDisplayEventTab,
		setComponentElement,
		selectedSiteData,
		sqliteSchema,
		sqliteDispatch,
		strapiSchema,
		strapiDispatch,
		setIsStrapiChanged,
		setIsListDynamic,
		blocks,
		undo, setUndo,
		setRedo,
		setIsDropped,
		// componentWithSettingsRef,
		setComponent,
		cdn, setCdn,
		onHoverCoordinates,
		setOnHoverCoordinates,
		content,
		setContent,
		currentLanguage,
		displayEventTab,
		displayDataTab,
		openedRightSidebarSection,
		setOpenedRightSidebarSection,
		setOpenedLeftSidebarSection
	} = useContext(context)
	const params = useParams();
	const location = useLocation();
	const { siteId } = params;
	const contextVariables = useContext(context);
	// find form_id fo all froms whose parent div is clicked
	// const findForm_id = (blocks, formInsideIds, arr) => {
	// 	for (let i = 0; i < blocks.length; i++) {
	// 		if (formInsideIds.includes(blocks[i].id)) {
	// 			arr.push(blocks[i].data.form_id)
	// 			// Note: don't break here
	// 		} else if (blocks[i]?.children) {
	// 			findForm_id(blocks[i].children, formInsideIds, arr)
	// 		}
	// 	}
	// };

	const handleClick = (event, id, customComp = false, index = -1, customComponent = false) => {
		event.stopPropagation();
		setIsListDynamic({})

		if (!grab) {
			processChange()
			const el = document.getElementById(id);
			if (!customComponent && !props.customComponentChildren) {
				// if (componentWithSettingsRef.current != null) {
				setComponent(null);
				// componentWithSettingsRef.current.style.outline = 'none';
				// componentWithSettingsRef.current = null;
				// }
			}
			const cordinatesValue = el?.getBoundingClientRect()
			const data = {
				cordinatesValue,
				id: id,
				isCustom: customComp,
				index: blockIndex[0],
				elementId: customComp ? block : {},
				componentName: block?.data?.componentName ? block?.data?.componentName : ""
			}
			setCordinates(data)

			if (workingOnEvent == false) {
				index != -1 ? setElementId(block?.children?.[index]) : setElementId(block);
				if (!openedRightSidebarSection) {
					setOpenedRightSidebarSection("style")
				}
			}

			// don't display duplicate & delete button on form submit button
			// block?.data?.formButton && setCordinates(null);

			if (elementRef.current != null) {
				elementRef.current.classList.remove("outlineHover")
				elementRef.current.style.outline = "none"
			}
			if (!customComponent) {
				elementRef.current = event.target;
				// elementRef.current.style.outline = "1px solid red"
			}
			block.content?.length >= 0 && setEditableValue(event.target.innerText)
			if (!displayEventTab) {
				setDisplayEventTab(true);
			}
			// check if form is selected or not
			// form is in upper layer i.e form > div
			// const closestSection = el?.closest('form');
			// // NOTE: closest only return value if the element (form) which we are finding is inside other element (el) exists, else it will return null

			// // form is inside other element i.e. div > form
			// const formInside = el?.querySelector(`#${id}`)?.getElementsByTagName('form');

			// // console.log('formElementRef', el, closestSection, formInside, formElementRef.current, elementRef.current);

			// if ((closestSection == null || closestSection == undefined || formInside == null || formInside == undefined || formInside?.length == 0) && formElementRef.current != null) {
			// 	formElementRef.current.style.outline = "none";
			// 	formElementRef.current = null;

			// } 
			// else if (closestSection?.contains(elementRef.current)) {

			// 	// other elements of form is selected, i.e. div (used for styling) which means form is selected
			// 	// console.log('formElementRef 2', el.closest('[data-name="form-div"]'), closestSection.closest('[data-name="form-div"]'));

			// 	// if (closestSection.closest('[data-name="form-div"]')) {
			// 	// console.log('formElementRef 3')


			// 	// selected form id in ui schema
			// 	setFormId(closestSection.id);


			// 	// if (formElementRef.current != null) {
			// 	// 	formElementRef.current.style.outline = "none";
			// 	// 	formElementRef.current = null;
			// 	// }
			// 	// }

			// } 
			// else if (formInside?.length > 0) {
			// 	// form is inside other elements

			// 	const formInsideIds = [];
			// 	for (let i = 0; i < formInside.length; i++) {
			// 		formInsideIds.push(formInside[i].id)
			// 	}

			// 	const arr = []
			// 	findForm_id(blocks, formInsideIds, arr);

			// 	if (formInside.length == 1) {
			// 		setFormId(...formInsideIds);
			// 	} else {
			// 		setFormId(formInsideIds);
			// 	}
			// }

			// form not selected
			// if ((closestSection == null || closestSection == undefined) && (formInside == null || formInside == undefined || formInside?.length == 0)) {
			// 	// hide data tab in right side panel
			// 	setDisplayDataTab(false);
			// 	setFormSelected(false);
			// 	setFormName('');
			// 	setSelectedFormElementId(null);

			// 	setAddNewFormElementCordinates({
			// 		cordinatesValue: {
			// 			top: 0,
			// 			left: 0
			// 		}
			// 	});

			// 	// show event tab in right side bar

			// } else {
			// 	if (selectedSiteData.static == false) {

			// 		// if (selectedSiteData.static == false) {
			// 		setDisplayDataTab(true);
			// 		// }
			// 	} else {
			// 	}

			// 	setFormSelected(true);
			// 	setSelectedFormElementId(null);

			// 	// show event tab in right side bar
			// 	setDisplayEventTab(false);
			// }

			// component ref = null
			if (componentRef.current != null) {
				componentRef.current.style.outline = "none";
				componentRef.current = null;
			}

		}

	};

	const handleComponent = (event, id) => {
		event.stopPropagation();
		// close all left side popups
		setOpenedRightSidebarSection(null)

		if (!grab) {
			// used to update children's css & style, css object inside data
			if (workingOnEvent == false) {
				setElementId(block);
				setComponentElement(block);
			}

			// get outer element id
			const el = document.getElementById(id);
			// console.log('inputDiv', id, event.target, el, el?.dataset?.name)
			const componentElement = el.closest('[data-name="component"]');

			const cordinatesValue = componentElement.getBoundingClientRect()
			setCordinates({
				cordinatesValue,
				id: componentElement.id,
				isCustom: false,
				componentName: block.data.componentName
				// componentName:  "cart"
			});

			// console.log('i', componentElement, cordinatesValue)

			// highlight selected component to display data properties
			componentRef.current != null && (componentRef.current.style.outline = "none")
			componentRef.current = componentElement;
			componentRef.current.style.outline = "1px solid #6366F1"

			setDisplayDataTab(true);
			setOpenedRightSidebarSection('data');

			// hide event tab in right side bar
			setDisplayEventTab(false);

			if (el?.dataset?.name == "component") {

				// hide element
				if (elementRef.current != null) {
					elementRef.current.style.outline = "none";
					elementRef.current = null;
				}
				if (formElementRef.current != null) {

					formElementRef.current.style.outline = "none";
					formElementRef.current = null;
				}

			} else if (el?.dataset?.name != undefined) {

				if (formElementRef.current != null) {
					formElementRef.current.style.outline = "none";
					formElementRef.current = null;
				}

				// highlight selected element in component to display style properties

				elementRef.current != null && (elementRef.current.style.outline = "none")
				elementRef.current = el;
				elementRef.current.style.outline = "1px solid #6366F1"

			} else {

				if (elementRef.current != null) {

					elementRef.current.style.outline = "none";
					elementRef.current = null;
				}
				if (formElementRef.current != null) {

					formElementRef.current.style.outline = "none";
					formElementRef.current = null;
				}
			}

		}
	}

	function dragStart(ev) {
		// console.log('drAG start', ev.target.id);

		ev.stopPropagation()
		valueY = sessionStorage.getItem("valueY")
		setCordinates({
			cordinatesValue: {
				top: 0,
				left: 0
			}
		})
		setElementId(block)
		setIsRender(true)
	}

	function drag(ev) {
		// console.log('drAG', ev.target.id);
		ev.preventDefault()
		// const wave = document.querySelector(".wave1")
		// 	wave.classList.remove("wave")
		// 	// console.log(wave.className)
		// 	setTimeout(() => {
		// 		wave.classList.add("wave")
		// 	}, 1000)
		// }
		const scrollDiv = document.getElementById("scrollDiv")
		const valueX = sessionStorage.getItem("valueX")
		if (ev.clientY < window.innerHeight && ev.clientY > window.innerHeight - 50) {
			valueY -= 4
		} else if (ev.clientY >= 10 && ev.clientY <= 100) {
			valueY -= 1
			valueY += 4
		}
		sessionStorage.setItem("valueY", valueY)
		scrollDiv.style.transform = "translateY(" + valueY + "px)";
	}

	// const debounce = (func, delay) => {
	// 	let debounceTimer	// const [isRender, setIsRender] = useState(true);

	// 	return function () {
	// 		const context = this
	// 		const args = arguments
	// 		clearTimeout(debounceTimer)
	// 		debounceTimer
	// 			= setTimeout(() => func.apply(context, args), delay)
	// 	}
	// }

	// display border when a particular element or section is not dropped
	async function allowDrop(ev) {
		// console.log('allow drop', ev.target.id);

		ev.preventDefault();
		if (ev.target.id?.includes("popover")) {
			return
		}
		if (isRender == false) {
			if (ev.target.id.length == 0 || ev.target.id == null) return

			const getElement = document.getElementById(ev.target.id);

			if (elementRef.current != null) {
				elementRef?.current?.classList?.remove('bottom-outline', 'top-outline', "opacityLow")
			}

			// let notDataNames = [
			// 	"form",
			// 	"form-div",
			// 	"label",
			// 	"help",
			// 	"input",
			// 	"warning",
			// 	"uploadBlock",
			// ]

			// if (!ev?.target?.form && !ev?.target?.id?.includes("input_") &&
			// 	!notDataNames.includes(ev?.target?.attributes?.["data-name"]) &&
			// 	ev?.target.closest('[data-name="form-div"]') == null &&					// element is inside form component
			// 	!ev?.target?.attributes?.["for"]
			// ) {
			elementRef.current = ev.target;
			// elementRef.current.classList.add('bottom-outline')
			// elementRef.current.style.opacity = ".7"

			setCurrentId({
				...currentId, id: ev?.target?.id
			})
			const cY = ev.clientY;
			const tempCenter =
				(getElement.getBoundingClientRect().bottom +
					getElement.getBoundingClientRect().top) /
				2;

			// const height = getElement.getBoundingClientRect().bottom + getElement.getBoundingClientRect().top
			const height = getElement.getBoundingClientRect().height


			const tags = ["p", "h1", "h2", "h3", "h4", "h5", "h6", "span"]
			// console.log(tags.includes(ev.target?.tagName?.toLowerCase()))
			if (tags.includes(ev.target?.tagName?.toLowerCase())) {
				if (tempCenter > cY) {
					console.log("inside/outside")
					elementRef?.current?.classList?.add('top-outline')
					elementRef?.current?.classList?.remove('bottom-outline');

					setAddTop(true)
				} else if (tempCenter < cY) {
					elementRef?.current?.classList?.add('bottom-outline')
					elementRef?.current?.classList?.remove('top-outline');

					setAddTop(false)
				}
			} else {
				const topEl = getElement.getBoundingClientRect().top;
				const bottomEl = getElement.getBoundingClientRect().bottom;
				// console.log(ev/)
				// console.log(ev.target.children.length)
				// && ev?.target?.children?.length > 0
				// if (cY > Math.floor(topEl + (height / 3) / 2) && cY < Math.floor(bottomEl - (height / 3) / 2)) {
				if (cY > Math.floor(topEl + (height / 3)) && cY < Math.floor(bottomEl - (height / 3))) {
					// console.log("CENTER")
					setAddTop("center")
					elementRef?.current?.classList?.add("opacityLow")
					elementRef?.current?.classList?.remove('top-outline')
					elementRef?.currendt?.classList?.remove('bottom-outline');
				}
				else if (Math.floor(topEl + (height / 3) / 2) > cY) {
					elementRef?.current?.classList?.add('top-outline')
					elementRef?.current?.classList?.remove("opacityLow")
					elementRef?.currendt?.classList?.remove('bottom-outline');

					setAddTop(true)
				} else if (Math.floor(bottomEl - (height / 3) / 2) < cY) {
					elementRef?.current?.classList?.add('bottom-outline')
					elementRef?.current?.classList?.remove("opacityLow")
					elementRef?.current?.classList?.remove('top-outline');

					setAddTop(false)
				}
			}
			// }

		}
	}


	// Function to search for CSS class
	function searchCss(obj, searchString) {
		if (obj?.hasOwnProperty('css') && obj?.['css']?.includes(searchString)) {
			return true;
		}

		for (let key in obj) {
			if (typeof obj[key] === 'object') {
				if (searchCss(obj?.[key], searchString)) {
					return true;
				}
			}
		}

		return false;
	}


	// called when a particular element or section is dropped
	async function drop(ev) {
		// console.log('drop', ev.target.id);
		ev.preventDefault()
		ev.stopPropagation();

		if (ev.target.id?.includes("popover")) {
			return
		}
		// console.log("kanfaj")
		// Add CDN on drop
		if (searchCss(element, "mdi mdi-")) {
			if (!cdn.includes(qafto_CDN?.pictogrammer)) {
				try {
					await dbCall.post(process.env.REACT_APP_BACKEND_URL + '/api/site/cdn', { uniqueId: siteId, cdn: [...cdn, qafto_CDN?.pictogrammer] })
					setCdn([...cdn, qafto_CDN?.pictogrammer]);
				} catch (error) {
					console.log("Something went wrong while updating CDN", error)
				}
			}
		}
		else if (searchCss(element, 'material-symbols')) {
			if (!cdn.includes(qafto_CDN?.material)) {
				try {
					await dbCall.post(process.env.REACT_APP_BACKEND_URL + '/api/site/cdn', { uniqueId: siteId, cdn: [...cdn, qafto_CDN?.material] })
					setCdn([...cdn, qafto_CDN?.material]);
				} catch (error) {
					console.log("Something went wrong while updating CDN", error)
				}
			}
		}
		if (isRender == false) {
			if ('UISchema' in element) {

				if (selectedSiteData.static) {
					const uniqueFormId = randomString();
					const uiSchema = element?.UISchema(uniqueFormId, selectedSiteData.static);
					const newComponentSchema = changeId(uiSchema);
					const addedFormSqliteSchema = element?.SqliteSchema(uniqueFormId);
					if (addTop == "center") {
						await Bdispatch({ type: "ADDELEMENTCENTER", id: ev.target.id, index: blockIndex[0], element: newComponentSchema });
					}
					else if (addTop) {
						await Bdispatch({ type: "ADDELEMENTTOP", id: ev.target.id, index: blockIndex[0], element: newComponentSchema });
					}
					else {
						await Bdispatch({ type: "ADDELEMENT", id: ev.target.id, index: blockIndex[0], element: newComponentSchema });
					}
					let index;
					let match = false;
					for (let i = 0; i < sqliteSchema?.length; i++) {
						if (sqliteSchema[i].name == addedFormSqliteSchema.name) {
							index = i;
							match = true;
							break;
						}
					}
					if (match) {
						if (Array.isArray(sqliteSchema[index].formId)) {
							sqliteSchema[index].formId = [...sqliteSchema[index].formId, addedFormSqliteSchema.formId];
						} else {
							sqliteSchema[index].formId = [sqliteSchema[index].formId, addedFormSqliteSchema.formId];
						}
						await sqliteDispatch({ type: "ADD", payload: sqliteSchema });
					} else {
						await sqliteDispatch({ type: "ADD", payload: [...sqliteSchema, addedFormSqliteSchema] });
					}
				} else {

				}
			} else {
				let addUIComponent = element?.schema ? changeId(JSON.parse(JSON.stringify(element.schema))) : element;
				// setIsDropped(true);

				if (addTop == "center") {
					await Bdispatch({ type: "ADDELEMENTCENTER", id: ev.target.id, index: blockIndex[0], element: addUIComponent });
				}
				else if (addTop) {
					await Bdispatch({ type: "ADDELEMENTTOP", id: ev.target.id, index: blockIndex[0], element: addUIComponent });
				}
				else {
					await Bdispatch({ type: "ADDELEMENT", id: ev.target.id, index: blockIndex[0], element: addUIComponent });
				}
			}
			AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);

			if (elementRef.current) {
				elementRef.current.style.outline = "none"
				elementRef.current.style.opacity = "1"
				elementRef?.current?.classList?.remove('bottom-outline', 'top-outline', "opacityLow")
			}
			setIsRender(true);
		}
		// console.log(element, "drop", formSelected)
	}

	// get id of element in template which is hoverd
	const handleMouseOver = (event) => {
		event.stopPropagation();
		if (!grab) {
			if (setOpenedLeftSidebarSection != 'elements') {
				if (document.getElementById(blocks[blockIndex?.[0]]?.id)) {
					document.getElementById(blocks[blockIndex?.[0]]?.id).style.outline = "3px solid #ad80ff"
				}
				const el = document.getElementById(blocks[blockIndex?.[0]]?.id);
				const cordinatesValue = el?.getBoundingClientRect();

				const data = {
					cordinatesValue,
					id: blocks[blockIndex?.[0]]?.id,
					addTop: onHoverCoordinates?.addTop
				}
				setOnHoverCoordinates({ ...data })
			}
			// setOnHoverCoordinates({
			// 	...onHoverCoordinates,
			// 	cordinatesValue: {
			// 		top: -1000,
			// 		right: -1000,
			// 		width: -1000
			// 	}
			// })

			const value = document.getElementById(event?.target?.id)

			if (event?.target?.id != blocks[blockIndex?.[0]]?.id && elementRef.current?.id != event.target.id) {
				value?.style && (value.style.outline = "1.5px solid #ad80ff")
			}


			setElementIdOnMouseOver(blockIndex?.[0]);		// index of outer section
		}
	}
	const handleMouseOut = (event) => {
		event.stopPropagation();
		if (!grab) {
			if (setOpenedLeftSidebarSection == 'elements') {
				setOnHoverCoordinates({
					...onHoverCoordinates,
					id: onHoverCoordinates.id,
					// cordinatesValue: {
					// 	top: -1000,
					// 	right: -1000,
					// 	width: -1000
					// }
				})
			} else {
				if (document.getElementById(blocks[blockIndex?.[0]]?.id)) {

					document.getElementById(blocks[blockIndex?.[0]]?.id).style.outline = "none"
				}
				setOnHoverCoordinates({
					id: null,
					cordinatesValue: {
						top: -1000,
						right: -1000,
						width: -1000
					}
				})
			}
			const value = document.getElementById(event?.target?.id)

			if (!elementRef.current?.id) {
				value?.style && (value.style.outline = "none");
			}
			else if (elementRef.current && elementRef.current?.id != event?.target?.id) {
				value?.style && (value.style.outline = "none");
			}
		}

	}

	const findFormData = (blocks, formId) => {
		for (let i = 0; i < blocks?.length; i++) {

			if (blocks[i].id == formId) {
				return blocks[i];

			} else if (blocks[i]?.children) {
				const form_data = findFormData(blocks[i].children, formId)

				if (form_data != undefined && form_data != null) {
					return form_data;
				}
			}
		}
	}

	// handle form click
	const handleInputClick = (event, id) => {
		event.stopPropagation();

		setOpenedLeftSidebarSection(null)


		// hide event tab in right side bar
		setDisplayEventTab(false);

		// close form elements side bar


		// highlight selected element in form to display style properties
		elementRef.current != null && (elementRef.current.style.outline = "none")
		elementRef.current = event.target;
		if (elementRef.current?.dataset?.name != undefined) {
			elementRef.current.style.outline = "1px solid #6366F1";
		}

		// used to duplicate form elements
		if (workingOnEvent == false) {
			setElementId(block);
		}

		// get outer element id
		const inputDiv = document.getElementById(`input_div_${id}`);
		// console.log('inputDiv', inputDiv, id, elementRef.current?.dataset?.name)

		// find closest form element & save its id
		setFormId(inputDiv?.closest('form').id);

		// is not static site
		let display_border_id;
		if (selectedSiteData.static == false) {

			// display border
			formElementRef.current != null && (formElementRef.current.style.outline = "none")
			formElementRef.current = inputDiv;
			formElementRef.current.style.outline = "1px solid #6366F1";
			formElementRef.current.style.borderRadius = "2px";

			setDisplayDataTab(true);

			// store id of selected input element
			setSelectedFormElementId(id);

			// open data tab & close rest
			setOpenedRightSidebarSection("data");

			display_border_id = `input_div_${id}`;

		} else {
			display_border_id = inputDiv?.closest('form').id;
		}

		// display duplicate, delete btn
		// const cordinatesValue = document.getElementById(display_border_id).getBoundingClientRect()
		// setCordinates({
		// 	cordinatesValue,
		// 	id: display_border_id,
		// 	isCustom: false
		// });

		// component ref = null
		if (componentRef.current != null) {

			componentRef.current.style.outline = "none";
			componentRef.current = null;
		}

		// START - don't display form element properties if the form is of auth type
		const form_data = findFormData(blocks, inputDiv?.closest('form').id);
		// console.log('form_data', form_data);

		let auth_Form_types = ["login", "register", "verify", "otp", "forgot", "forget", "reset"]

		if (form_data?.data?.form_type === "auth" || form_data?.data?.form_name.toLowerCase().includes(auth_Form_types)) {
			// whether form is selected or not
			setFormSelected(true);
		} else {
			setFormSelected(false);
		}
		// END - don't display form element properties if the form is of auth type

	}

	/*
	// handle form element blur
	const blurInputField = (event, id) => {
		event.stopPropagation();

		const inputDiv = document.getElementById(`input_div_${id}`);
		console.log('inputDivinputDiv', inputDiv)

		formElementRef.current != null && (formElementRef.current.style.outline = "none")
		formElementRef.current = inputDiv;
		formElementRef.current.style.outline = "none";
		formElementRef.current.style.borderRadius = "0";
	}
	*/

	const blockTypes = [
		"list",
		"map",

		// form elements
		"FormikComponent",
		"EmailField",
		"ShortTextField",
		"LongTextField",
		"SelectField",
		"PasswordField",
		"NumberField",
		"UploadField",
		"UploadVideoField",
		"RadioField",
		"CheckboxField",
		"DateTimeField",
		"DateField",
		"TimeField",
		"Tabs",
		"Table",
		"Accordion",
		"DataTable",
		"Dropdown",
		"Products",
		"Carousal",
		"productList",
		"player",
		"PopOver",
		"ScrollAnimation",
		"Carousel2",
		"Disclosure",
		"DisclosureButton",
		"DisclosurePanel",
		"gmap",
		"SocialLinks",
		"TabGroup",
		"TabList",
		"Tab",
		"TabPanels",
		"TabPanel",
		"Popover",
		"PopoverButton",
		"PopoverPanel",
		"TimerPopup",
		'input',
		'select',
		'textarea',
		'form',
		"LogicalAnd",
		"Ternary",
		"FieldError",
		"SingleProduct",
		"DynamicCarousal",
		"DynamicComponent",
		"Map",
		"a",
		"Link",
		"Rating",
		"img"
	]

	if (!block) return null;
	if (props.platformsToShow && block.data?.platform && !props.platformsToShow.includes(block.data.platform)) {
		return null;
	}
	if (block.children && !block?.data?.IsAnimation && !block?.data?.isCircularText && !block?.data?.isTypingEffect && !blockTypes.includes(block.type)) {
		return (
			<>
				{
					React.createElement(
						block?.type,
						{
							key: block.id,
							id: block?.id,
							className: generateCss({
								props: {
									...props,
									block: block
								},
								contextVariables: contextVariables,
								params: params,
								location: location,
								elementWithChildren: true
							}),
							style: {
								fontFamily: block?.style?.fontFamily
							},
							...rest,
							onClick: (e) => {
								if (props?.onClick) {
									props?.onClick();
								}

								if (block?.events?.onClick?.applyInBuilder || block?.events?.onClick?.applyInBuilderAndUserWebsite) {
									if (block.events.onClick.task == 'custom') {
										eval(block.events.onClick.parameters.function);
									}
								}
								if (block.data?.nonSelectableInBuilder) {
									e.stopPropagation();
									return;
								}

								handleClick(e, block.id);
								if (props.customComponentChildren) {
									props.handleComponentWithSettingsClick();
								}
							},
							onMouseOverCapture: (e) => handleMouseOver(e, block?.id),
							onMouseOut: (e) => handleMouseOut(e),
							draggable: !props.noSort,
							onDragOver: allowDrop,
							onDrag: drag,
							onDragStart: dragStart,
							suppressContentEditableWarning: true,
							onDrop: drop,
						},
						<>
							<Container
								{...props}
								block={block}
								blockIndex={blockIndex}
							/>
						</>
					)
				}
			</>
		);
	} else if (block.type == "list") {
		return <Map block={block} />
	}

	else if (block.type == "DynamicCarousal") {
		return <DynamicCarousal {...props} block={block} handleClick={handleClick} handleMouseOver={handleMouseOver} />
	}

	else if (block.type == "Rating") {
		return <Rating {...props} block={block} handleClick={handleClick} handleMouseOver={handleMouseOver} />;
	}

	// START - form components

	else if (block.type == "FormikComponent") {
		return <FormikComponent {...props} block={block} blockIndex={blockIndex} handleClick={handleClick} handleMouseOver={handleMouseOver} />

	} else if (block.type == "ShortTextField" || block.type == "NumberField") {
		return <ShortTextField {...props} block={block} handleInputClick={handleInputClick} />

	} else if (block.type == "SelectField") {
		return <SelectField {...props} block={block} handleInputClick={handleInputClick} />

	} else if (block.type == "PasswordField") {
		return <PasswordField {...props} block={block} handleInputClick={handleInputClick} />

	} else if (block.type == "UploadField" || block.type == "UploadVideoField") {
		return <UploadField {...props} block={block} handleInputClick={handleInputClick} />

	} else if (block.type == "EmailField") {
		return <EmailField {...props} block={block} handleInputClick={handleInputClick} />

	} else if (block.type == "LongTextField") {
		return <LongTextField {...props} block={block} handleInputClick={handleInputClick} />

	} else if (block.type == "RadioField") {
		return <RadioField {...props} block={block} handleInputClick={handleInputClick} />

	} else if (block.type == "CheckboxField") {
		return <CheckboxField {...props} block={block} handleInputClick={handleInputClick} />

	} else if (block.type == "DateTimeField") {
		return <DateTimeField {...props} block={block} handleInputClick={handleInputClick} />

	} else if (block.type == "DateField") {
		return <DateField {...props} block={block} handleInputClick={handleInputClick} />

	} else if (block.type == "TimeField") {
		return <TimeField {...props} block={block} handleInputClick={handleInputClick} />
	} else if (block.type == "img") {
		return (
			<>
				{
					React.createElement(
						block?.type,
						{
							key: block.id,
							id: block?.id,
							className: generateCss({
								props: {
									...props,
									block: block
								},
								contextVariables: contextVariables,
								params: params,
								location: location
							}),
							...rest,
							src: generateSrc({
								props: {
									...props,
									block: block
								},
								contextVariables: contextVariables,
								params: params,
								location: location
							}),
							onClick: (e) => {
								if (props?.onClick) {
									props?.onClick();
								}
								handleClick(e, block.id);
								if (props.customComponentChildren) {
									props.handleComponentWithSettingsClick();
								}
							},
							onMouseOver: (e) => handleMouseOver(e),
							onDragOver: allowDrop,
							onDrag: drag,
							onDragStart: dragStart,
							onDrop: drop,
						},
						null
					)
				}
			</>
		)
	} else if (block.type == "PopOver") {
		return <PopOver {...props} block={block} blockIndex={blockIndex} handleClick={handleClick} />

	} else if (block.type == "Tabs") {
		return <Tabs block={block} blockIndex={blockIndex} handleClick={handleClick} handleMouseOver={handleMouseOver} />
	} else if (block.type == "Accordion") {
		return <Accordian block={block} blockIndex={blockIndex} handleClick={handleClick} handleMouseOver={handleMouseOver} />

	} else if (block.type == "DataTable") {
		return <DataTable block={block} blockIndex={blockIndex} handleClick={handleClick} handleMouseOver={handleMouseOver} />

	} else if (block.type == "Dropdown") {
		return <Dropdown block={block} blockIndex={blockIndex} handleClick={handleClick} />
	} else if (block.type == 'player') {
		return <VideoPlayer block={block} handleClick={handleClick} handleMouseOver={handleMouseOver} />
	} else if (block?.data?.IsAnimation && block?.children) {
		return <ScrollAnimations {...props} block={block} blockIndex={blockIndex} handleClick={handleClick} handleMouseOver={handleMouseOver} />
	}
	
	else if (block?.data?.IsAnimation && !block?.children) {
		console.log("AnimatedBlock", block);
		return (
			<ScrollAnimation
				animateIn={block.data.animationIn}
				animateOut="fadeOut"
				duration={block?.data?.Duration ?? 1}
				delay={block?.data?.Delay ?? 0}
				animateOnce={block?.data?.animateOnce}
				style={{ "opacity": 1 }}
			>
				{
					React.createElement(
						block?.type,
						{
							key: block.id,
							id: block?.id,
							className: generateCss({
								props: {
									...props,
									block: block
								},
								contextVariables: contextVariables,
								params: params,
								location: location
							}),
							style: {
								fontFamily: block?.style?.fontFamily
							},
							...rest,
							onClick: (e) => {
								if (block?.events?.onClick?.applyInBuilder || block?.events?.onClick?.applyInBuilderAndUserWebsite) {
									if (block.events.onClick.task == 'custom') {
										eval(block.events.onClick.parameters.function);
									}
								}
								if (block.data?.nonSelectableInBuilder) {
									e.stopPropagation();
									return;
								}
								handleClick(e, block.id);
								if (props.customComponentChildren) {
									props.handleComponentWithSettingsClick();
								}
							},
							onMouseEnter: (e) => handleMouseOver(e, block.id),
							onMouseOut: () => {
								document.getElementById(blocks[blockIndex?.[0]]?.id).style.outline = "none"
							},
							draggable: !props.noSort,
							onInput: (e) => {
								e.stopPropagation()
								setEditableValue(e.target.innerText)
							},
							onDragOver: allowDrop,
							onDrag: drag,
							onDragStart: dragStart,
							onDrop: drop,
							onDoubleClick: (e) => e.target.contentEditable = true,
							onBlur: async (e) => {
								await Bdispatch({
									type: "UPDATE_CONTENT", id: block.id, element: e.target.innerText
								});

								// add schemas to undo state
								AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
							},
							suppressContentEditableWarning: true
						},
						block.content
					)
				}

			</ScrollAnimation>
		);
	}
	
	else if (block?.data?.isCircularText == true) {
		return (
			<>
				{
					React.createElement(
						block?.type,
						{
							id: block?.id,
							style: {
								fontFamily: block.style?.fontFamily
									? block.style.fontFamily
									: null,
							},
							className: `relative !h-[${block?.data?.radius * 2}px] !w-[${block?.data?.radius * 2}px] ${generateCss({
								props: {
									...props,
									block: block
								},
								contextVariables: contextVariables,
								params: params,
								location: location
							})}`,
							onClick: (e) => {
								if (block?.events?.onClick?.applyInBuilder || block?.events?.onClick?.applyInBuilderAndUserWebsite) {
									if (block.events.onClick.task == 'custom') {
										eval(block.events.onClick.parameters.function);
									}
								}
								if (block.data?.nonSelectableInBuilder) {
									e.stopPropagation();
									return;
								}
								handleClick(e, block.id);
								if (props.customComponentChildren) {
									props.handleComponentWithSettingsClick();
								}
							},
							onMouseEnter: (e) => handleMouseOver(e, block.id),
							onMouseOut: () => {
								document.getElementById(blocks[blockIndex?.[0]]?.id).style.outline = "none"
							},
							draggable: !props.noSort,
							onInput: (e) => {
								e.stopPropagation()
								setEditableValue(e.target.innerText)
							},
							onDragOver: allowDrop,
							onDrag: drag,
							onDragStart: dragStart,
							onDrop: drop,
							onDoubleClick: (e) => e.target.contentEditable = true,
							onBlur: async (e) => {
								await Bdispatch({
									type: "UPDATE_CONTENT", id: block.id, element: e.target.innerText
								});

								// add schemas to undo state
								AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
							},
							suppressContentEditableWarning: true
						},

						block?.content?.split('')?.map((char, i) => {
							return (
								<>
									<span className={`rotate-[${i * (360 / block?.content?.length)}deg] absolute left-1/2 `} style={{ transformOrigin: `0 ${block?.data?.radius}px` }}>{char}</span>
								</>
							)
						})

					)

				}

			</>
		)
	} else if (block?.data?.isTypingEffect == true) {
		const temp = [];

		if (block?.data?.sequence) {
			let n = block?.data?.sequence.length;
			for (let i = 0; i < n; i++) {
				temp.push(parseInt(block?.data?.sequence[i].delay));
				temp.push(block?.data?.sequence[i].value);
			}
			temp.push(parseInt(block?.data?.sequence[n - 1].delay));

		}
		return (
			React.createElement(
				block?.type,
				{
					id: block?.id,
					style: {
						fontFamily: block.style?.fontFamily
							? block.style.fontFamily
							: null,
					},
					className: generateCss({
						props: {
							...props,
							block: block
						},
						contextVariables: contextVariables,
						params: params,
						location: location
					}),
					onClick: (e) => {
						if (block?.events?.onClick?.applyInBuilder || block?.events?.onClick?.applyInBuilderAndUserWebsite) {
							if (block.events.onClick.task == 'custom') {
								eval(block.events.onClick.parameters.function);
							}
						}
						if (block.data?.nonSelectableInBuilder) {
							e.stopPropagation();
							return;
						}
						handleClick(e, block.id);
						if (props.customComponentChildren) {
							props.handleComponentWithSettingsClick();
						}
					},
					onMouseEnter: (e) => handleMouseOver(e, block.id),
					onMouseOut: () => {
						document.getElementById(blocks[blockIndex?.[0]]?.id).style.outline = "none"
					},
					draggable: !props.noSort,
					onInput: (e) => {
						e.stopPropagation()
						setEditableValue(e.target.innerText)
					},
					onDragOver: allowDrop,
					onDrag: drag,
					onDragStart: dragStart,
					onDrop: drop,
					onDoubleClick: (e) => e.target.contentEditable = true,
					onBlur: async (e) => {
						await Bdispatch({
							type: "UPDATE_CONTENT", id: block.id, element: e.target.innerText
						});

						// add schemas to undo state
						AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
					},
					suppressContentEditableWarning: true
				},
				<>
					<TypeAnimation
						key={JSON.stringify(block?.data?.speed) + JSON.stringify(block?.data?.sequence) + JSON.stringify(block?.css ? block?.css : "")}	// Force Re-Render
						sequence={temp}
						speed={{ type: 'keyStrokeDelayInMs', value: parseInt(block?.data?.speed) }}
						style={{ ...block?.style }}
						repeat={Infinity}
						wrapper="span"
						className={block?.css}
					/>
				</>
			)

		)
	}
	
	else if (block.type == "SingleProduct") {
		return <SingleProduct {...props} block={block} handleComponent={handleComponent} handleMouseOver={handleMouseOver} handleClick={handleClick} blockData={block} />;
	} else if (block.type == "productList") {
		return <ProductList {...props} block={block} handleComponent={handleComponent} handleMouseOver={handleMouseOver} handleClick={handleClick} />

	} else if (block.type == "Carousal") {
		return <Carousal {...props} block={block} blockIndex={blockIndex} handleMouseOver={handleMouseOver} />

	} else if (block.type == "Carousel2") {
		return <Carousal2 {...props} block={block} blockIndex={blockIndex} handleMouseOver={handleMouseOver} handleClick={handleClick} />

	} else if (block.type == "Disclosure") {
		return <DisclosureComponent {...props} block={block} blockIndex={blockIndex} handleMouseOver={handleMouseOver} handleClick={handleClick} />

	} else if (block.type == "DisclosureButton") {
		return <DisclosureButton {...props} block={block} handleMouseOver={handleMouseOver} blockIndex={blockIndex} handleClick={handleClick} />
	} else if (block.type == "DisclosurePanel") {
		return <DisclosurePanel {...props} block={block} handleMouseOver={handleMouseOver} blockIndex={blockIndex} handleClick={handleClick} />

	} else if (block.type == "GoogleMap") {
		return <GoogleMap {...props} block={block} handleMouseOver={handleMouseOver} handleClick={handleClick} />

	} else if (block.type == "SocialLinks") {
		return <SocialLinks {...props} block={block} handleMouseOver={handleMouseOver} blockIndex={blockIndex} handleClick={handleClick} />

	} else if (block.type == "TabGroup") {
		return <TabGroup {...props} block={block} handleMouseOver={handleMouseOver} blockIndex={blockIndex} handleClick={handleClick} />

	} else if (block.type == "TabList") {
		return <TabList {...props} block={block} handleMouseOver={handleMouseOver} blockIndex={blockIndex} handleClick={handleClick} />

	} else if (block.type == "Tab") {
		return <TabComponent {...props} block={block} handleMouseOver={handleMouseOver} blockIndex={blockIndex} handleClick={handleClick} />

	} else if (block.type == "TabPanels") {
		return <TabPanels {...props} block={block} handleMouseOver={handleMouseOver} blockIndex={blockIndex} handleClick={handleClick} />

	} else if (block.type == "TabPanel") {
		return <TabPanel {...props} block={block} handleMouseOver={handleMouseOver} blockIndex={blockIndex} handleClick={handleClick} />

	}
	else if (block.type === "Popover") {
		return <PopoverComponent {...props} block={block} blockIndex={blockIndex} handleMouseOver={handleMouseOver} handleClick={handleClick} />

	} else if (block.type == "PopoverButton") {
		return <PopoverButton {...props} block={block} handleMouseOver={handleMouseOver} blockIndex={blockIndex} handleClick={handleClick} />

	}
	else if (block.type == "PopoverPanel") {
		return
		// return <PopoverPanel {...props} block={block} handleMouseOver={handleMouseOver} blockIndex={blockIndex} handleClick={handleClick}  />

	} else if (block.type === "TimerPopup") {
		return <TimerPopup {...props} block={block} blockIndex={blockIndex} handleMouseOver={handleMouseOver} handleClick={handleClick} />

	} else if (block.type == "form") {
		return <Form {...props} block={block} blockIndex={blockIndex} onMouseOverCapture={handleMouseOver} onClick={handleClick} onMouseOut={handleMouseOut} onDragOver={allowDrop} onDrag={drag} onDragStart={dragStart} onDrop={drop} />
	} else if (block.type == "FieldError") {
		return <FieldError {...props} block={block} blockIndex={blockIndex} />
	} else if (block.type == "input" || block.type == 'textarea' || block.type == 'select') {
		return <Input {...props} block={block} blockIndex={blockIndex} onClick={handleClick} onMouseOut={handleMouseOut} onDragOver={allowDrop} onDrag={drag} onDragStart={dragStart} onDrop={drop} />
	} else if (block.type == "LogicalAnd") {
		return <LogicalAnd {...props} block={block} blockIndex={blockIndex} />
	} else if (block.type == "Ternary") {
		return <Ternary {...props} block={block} blockIndex={blockIndex} />
	} else if (block.type == "DynamicComponent") {
		return <DynamicComponent {...props} block={block} blockIndex={blockIndex} />
	} else if (block.type == "Map") {
		return <Loop {...props} block={block} blockIndex={blockIndex} />
	} else if (block.type == "a" || block.type == 'Link') {
		return <AComponent {...props} block={block} blockIndex={blockIndex} handleMouseOver={handleMouseOver} handleClick={handleClick} />
	} else {
		return (
			<>
				{
					React.createElement(
						block?.type,
						{
							key: block.id,
							id: block?.id,
							className: generateCss({
								props: {
									...props,
									block: block
								},
								contextVariables: contextVariables,
								params: params,
								location: location
							}),
							style: {
								fontFamily: block?.style?.fontFamily
							},
							...rest,
							onClick: props.onClickHandle ? props.onClickHandle : (e) => {
								if (block?.events?.onClick?.applyInBuilder || block?.events?.onClick?.applyInBuilderAndUserWebsite) {
									if (block.events.onClick.task == 'custom') {
										eval(block.events.onClick.parameters.function);
									}
								}
								if (block.data?.nonSelectableInBuilder) {
									e.stopPropagation();
									return;
								}
								handleClick(e, block.id);
								if (props.customComponentChildren) {
									props.handleComponentWithSettingsClick();
								}
							},
							onMouseOverCapture: (e) => handleMouseOver(e),
							onMouseOut: (e) => handleMouseOut(e),
							draggable: !props.noSort,
							onInput: (e) => {
								e.stopPropagation()
								setEditableValue(e.target.innerText)
							},
							onDragOver: allowDrop,
							onDrag: drag,
							onDragStart: dragStart,
							onDrop: drop,
							onDoubleClick: (e) => e.target.contentEditable = true,
							onBlur: async (e) => {

								Bdispatch({
									type: "UPDATE_CONTENT", id: block.id, value: e.target.innerText, currentLanguage, setContent, selectedSiteData
								});

								// add schemas to undo state
								AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
							},
							suppressContentEditableWarning: true
						},
						generateContent({
							contextVariables: contextVariables,
							props: {
								...props,
								block: block,
								blockIndex
							},
							params: params,
							location: location
						})

					)
				}

			</>
		);
	}
}