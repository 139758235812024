import React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useParams } from "react-router-dom";
import { dbCall } from "../../../../common/db";
import { toast } from "react-toastify";

const validate = (values) => {
  const errors = {};
  if (!values.clientid) {
    errors.clientid = "Client id is required";
  } 
//   else if (values.clientid.length > 15) {
//     errors.clientid = "Must be 15 characters or less";
//   }

  if (!values.apikey) {
    errors.apikey = "Api key is required";
  }
//    else if (values.apikey.length > 20) {
//     errors.apikey = "Must be 20 characters or less";
//   }

  return errors;
};

const Features = () => {
	const { siteId } = useParams()
  const formik = useFormik({
    initialValues: {
      clientid: "",
      apikey: "",
    },
    validate,
    onSubmit: async(values) => {
		await dbCall.post('/site/addFeature' , {siteId:siteId , credentials:values ,name:"feature"} )
    },
  });
  const handleClick = () => {
	toast.success('data stored successfully')
  }

  return (

	<div className="bg-gray-100">
    <div className="outline-none p-6 min-h-screen">
      <div className="shadow bg-white px-4 py-5 sm:px-6 rounded-lg">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Add Calendar credentials
        </h2>

        <p className="mt-1 text-sm leading-6 text-gray-600">
       
          
        </p>
      </div>
      <div className="m-5 mx-auto ">
        <form
          onSubmit={formik.handleSubmit}
          className="bg-white shadow-md rounded-md px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label
              htmlFor="clientid"
              className="block text-gray-900 text-sm font-bold mb-2"
            >
              CLIENT ID
            </label>
            <input
              id="clientid"
              name="clientid"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.clientid}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {formik.touched.clientid && formik.errors.clientid ? (
              <div className="text-red-500 text-xs mt-2">
                {formik.errors.clientid}
              </div>
            ) : null}
          </div>

          <div className="mb-4">
            <label
              htmlFor="apikey"
              className="block text-gray-900 text-sm font-bold mb-2"
            >
              API KEY
            </label>
            <input
              id="apikey"
              name="apikey"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.apikey}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {formik.touched.apikey && formik.errors.apikey ? (
              <div className="text-red-500 text-xs mt-2">
                {formik.errors.apikey}
              </div>
            ) : null}
          </div>

          <div className="flex items-center justify-end">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-md focus:outline-none focus:shadow-outline" onClick={handleClick}
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
	</div>
  );
};


export default Features;