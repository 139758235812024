import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// import { Renderer } from '../Home';
import { context } from '../../../../context/Context';
import { BlockWrapper } from '../Sortable';
// import { findFormElements } from './FormComponents';

export const FormElementTypes = [
	"ShortTextField",
	"LongTextField",
	"SelectField",
	"PasswordField",
	"UploadField",
	"UploadVideoField",
	"EmailField",
	"NumberField",
	"RadioField",
	"CheckboxField",
	"DateTimeField",
	"DateField",
	"TimeField",
]

const FormikComponent = ({ block, blockIndex = [], handleClick, handleMouseOver }) => {

	const {
		blocks,
		setFormSelected,
		formId,
		setFormId,
		setAddNewFormElementCordinates,
		setOpenedRightSidebarSection,
		strapiSchema,
		formElementRef,
		setDisplayDataTab,
		setSelectedFormElementId,
	} = useContext(context);

	const [initialValues, setInitialValues] = useState({});
	const [validationSchema, setValidationSchema] = useState({});

	const findFormChildren = (block) => {
		const fieldType = [];
		for (let i = 0; i < block?.length; i++) {
			if (FormElementTypes.includes(block[i].type)) {
				fieldType.push(block[i]);
			} else if (block[i]?.children) {
				abc(block[i]?.children, fieldType);
			}
		}
		return fieldType;
	};

	const abc = (block, fieldType) => {
		for (let i = 0; i < block?.length; i++) {
			if (FormElementTypes.includes(block[i].type)) {
				fieldType.push(block[i]);
			} else if (block[i]?.children) {
				abc(block[i]?.children, fieldType);
			}
		}
	};

	useEffect(() => {
		if (formId != null) {
			// get initial state values
			let formik_initialstates = block.data.formik_state;

			let validationsFields = {};

			let formElements = findFormChildren(block.children);

			for (let j = 0; j < formElements.length; j++) {
				if (FormElementTypes.includes(formElements[j].type)) {
					validationsFields[formElements[j].data.stateName] = generateValidations(formElements[j].data.validations);
				}
			}
			// console.log('validationsFields', validationsFields)

			setInitialValues(formik_initialstates);
			setValidationSchema(Yup.object({ ...validationsFields }));
		}
	}, [strapiSchema, formId]);

	// generate element validations
	const generateValidations = (fieldValidations) => {

		let schema = Yup[fieldValidations.typeValue ? fieldValidations.typeValue : 'string']()

		for (const rule of fieldValidations) {
			switch (rule.type) {
				case 'isTrue': schema = (schema).isTrue(rule.message); break;

				case 'isEmail': schema = (schema).email(rule.message); break;

				case 'minLength':
				case 'min':
					schema = (schema).min(rule?.value, rule.message); break;

				case 'maxLength':
				case 'max':
					schema = (schema).max(rule?.value, rule.message); break;

				default: schema = schema.required(rule.message); break;
			}
		}

		return schema;
	}

	/* Note: update when using - find "+ Add Element" cordinates
	const findAddElementCordinates = (blocks, formId) => {
		for (let i = 0; i < blocks?.length; i++) {
			
			if (blocks[i].id === formId) {
				let secondLastElementID = null;

				let formElements = findFormElements(blocks[i].children);

				if (formElements.length > 1) {		// btn + other element
					const secondLastElement = formElements[formElements.length - 2];
					secondLastElementID = `input_div_${secondLastElement.id}`;
					
				} else {									// btn only
					secondLastElementID = formElements[formElements.length - 1].id;
				}
				
				const cordinatesValue = document.getElementById(secondLastElementID).getBoundingClientRect();
				
				return { cordinatesValue, id: secondLastElementID };
				
			} else if (blocks[i]?.children) {
				const coordinates = findAddElementCordinates(blocks[i].children, formId);

				if (coordinates != undefined && coordinates != null) {
					return coordinates;
				}
			}
		}
	}
	*/

	// display add new form element button
	const showAddNewElementButton = (formId) => {
		setFormId(formId);

		// form is selected
		setFormSelected(true);
		setSelectedFormElementId(null);

		// display data tab in right side panel
		setDisplayDataTab(true);
		setOpenedRightSidebarSection(() => "data");

		// add style to form sections
		const formElement = document.getElementById(formId);

		formElementRef.current != null && (formElementRef.current.style.outline = "none")
		formElementRef.current = formElement;
		formElementRef.current.style.outline = "1px solid #6366F1";
		formElementRef.current.style.borderRadius = "2px";

		/* Hide for now
		// get cordinates to display "+ Add Element" btn
		const coordinates = findAddElementCordinates(blocks, formId);
		setAddNewFormElementCordinates(coordinates);
		// console.log('coordinates', coordinates, formId)
		*/
	}

	// console.log('formId', formId, strapiSchema, initialValues, validationSchema)

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={Object.keys(validationSchema).length == 0 ? Yup.object({}) : validationSchema}
			onSubmit={(values) => { console.log('values submitted', values) }}
		>
			{
				(formik) => (
					<Form
						id={block.id}
						className={block.css}
						onClick={(event) => {
							showAddNewElementButton(block.id)
							handleClick(event, block.id)
						}}
						onMouseOver={(e) => handleMouseOver(e)}
					>
						{/* <div
							id={`form_div_${block.id}`}
							className='w-full sm:w-[50%] h-full flex flex-col justify-center'
						> */}
						{
							block?.children?.map((block, index) => {
								// console.log('block inside', block)
								return <BlockWrapper key={index} block={block} blockIndex={[...blockIndex, index]} formik={formik} isForm={true} />
							})
						}
						{/* </div> */}

						{/* {console.log('formik', formik)} */}

						{/* <span className="ml-4 flex-shrink-0 p-1">
							<button
								type="submit"
								disabled={formik.isSubmitting}
								className="bg-white font-medium text-purple-600 hover:text-purple-500 focus:ring-0"
							>
								Save
							</button>
						</span> */}


					</Form>
				)
			}
		</Formik>
	)
}

export default FormikComponent;