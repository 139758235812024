import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { BlockWrapper } from '../Sortable';


export default function PopOver({ block, blockIndex, handleClick, currentTheme }) {
	const [visible, setVisible] = useState(false);

	function onClickHandle(e) {
		e.preventDefault();
		setVisible(!visible);
	}
	return (
		<Fragment>
			<Popover className={`relative ${block?.data?.css} `}>
				<Popover.Button
					className={block?.children?.[0]?.css}
					onClick={(e) => {
						e.stopPropagation();
						handleClick(e, block?.children?.[0]?.id, false)
					}}
				>
					{/* {block?.children?.[0]?.children ? {<BlockWrapper block={block?.children?.[0]} blockIndex={blockIndex} /> }: block?.children?.[0]?.content} */}

					{block?.children?.[0]?.children ? (
						<>
							{
								block?.children?.[0]?.children?.map((item, index) => {
									return (
										<BlockWrapper key={block?.children?.[0]?.id} block={item} blockIndex={[...blockIndex, index]} onClickHandle={onClickHandle} />
									)
								})
							}
						</>) : block?.children?.[0]?.content}

				</Popover.Button>
				<Transition
					show={visible}
				>
					{visible && (
						<Popover.Panel
							id={block?.children?.[1]?.id}
							// className={`absolute z-10 ${block?.children?.[1]?.css} bg-primary_color`}
							className={`absolute z-10 ${block?.children?.[1]?.css
								?.replaceAll("secondary_color", `[${currentTheme?.secondary_color}]`)
								?.replaceAll("bg_color", `[${currentTheme?.bg_color}]`)
								?.replaceAll("textColor", `[${currentTheme?.textColor}]`)
								?.replaceAll("primary_color", `[${currentTheme?.primary_color}]`)
								?.replaceAll("primary_font_size", `[${currentTheme?.primary_font_size}]`)
								?.replaceAll("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
								?.replaceAll("font_size", `[${currentTheme?.font_size}]`)
								?.replaceAll("cursor-pointer", "").split(" ")
								.map(property => `!${property}`)
								.join(" ")} ${currentTheme.font}`}
						>
							{/* <BlockWrapper key={block?.children?.[1]?.id} block={block?.children?.[1]} blockIndex={blockIndex} /> */}
							{
								block?.children?.[1]?.children?.map((item, index) => {
									return (
										<BlockWrapper key={block?.children?.[1]?.id} block={item} blockIndex={[...blockIndex, index]} />
									)
								})
							}
						</Popover.Panel>
					)}
				</Transition>
			</Popover>
		</Fragment>
	)
}
