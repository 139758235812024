
import React, { useEffect, useState } from 'react';

export default function Rating({ block, handleClick }) {
	const [rating, setRating] = useState(0)
	const [isFloat, setIsFloat] = useState(false)

	let ratingArray = [0, 0, 0, 1, 1];


	const getRating = async () => {
		try {
			const calculateRating = 1 * ratingArray[0] + 2 * ratingArray[1] + 3 * ratingArray[2] + 4 * ratingArray[3] + 5 * ratingArray[4]
			const totalRating = ratingArray[0] + ratingArray[1] + ratingArray[2] + ratingArray[3] + ratingArray[4]

			if (totalRating > 0) {
				const rating = calculateRating / totalRating
				const float = checkFloat(rating)
				setIsFloat(float)
				setRating(rating)
			}

		} catch (e) {

		}
	}

	function checkFloat(n) {
		return Number(n) === n && n % 1 !== 0;
	}

	useEffect(() => {
		getRating()
	}, [])

	return (
		<div className={`${block?.css}`}
			id={block?.id}
			onClick={(event) => {
				handleClick(event, `${block.id}`, block)
			}}
		>
			{
				[... new Array(isFloat ? Math.floor(rating) : rating)].map(() => {
					return <>
						<span className="mdi mdi-star"></span>
					</>
				}
				)
			}
			{
				isFloat && <span className="mdi mdi-star-half-full"></span>

			}
		</div>
	)
}
