import axios from 'axios';
import { AuthService } from '../common/auth.service';
import { API_URL } from '../config';
const instance = axios.create();

instance.defaults.baseURL = API_URL;

instance.defaults.headers.get['Accept'] = 'application/json';
instance.defaults.headers.post['Accept'] = 'application/json';
instance.defaults.onDownloadProgress = function (progressEvent) {
};
instance.defaults.onUploadProgress = function (progressEvent) {
};

instance.defaults.withCredentials = true;

instance.interceptors.request.use(function (request) {

	return request;
}, function (error) {
	alert("unable to load");
});

instance.interceptors.response.use(function (response) {

	return response;
}, function (error) {


	if (error.response) {
		if (error.response.status == 401) {
			AuthService.logout();
			alert('Session expired, please login again');
			throw "Session expired, please login again";
		}
	}
	throw error;
});


export const dbCall = instance;