import { useState, useEffect, useContext, Fragment } from "react";
import AnimationEffects from './Effects';
import { context } from "../../../context/Context";
import removeDuplicateClasses from "./removeDuplicateClasses";
import { Dialog, Menu, Switch, Tab, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {
	IconDotsVertical

} from "@tabler/icons-react";
import { saveProject } from "../../../layouts/NewEditor";

const animationsArray = [
	{
		class: "animated-bounce",
		hoverClass: "group-hover:animated-bounce",
		displayName: "bounce",
	},
	{
		class: "animated-flash",
		hoverClass: "group-hover:animated-flash",
		displayName: "Flash",
	},
	{
		class: "animated-pulse",
		hoverClass: "group-hover:animated-pulse",
		displayName: "Pulse",
	},
	{
		class: "animated-rubberBand",
		hoverClass: "group-hover:animated-rubberBand",
		displayName: "rubber Band",
	},
	{
		class: "animated-shake",
		hoverClass: "group-hover:animated-shake",
		displayName: "shake",
	},
	{
		class: "animated-headShake",
		hoverClass: "group-hover:animated-headShake",
		displayName: "head Shake",
	},
	{
		class: "animated-swing",
		hoverClass: "group-hover:animated-swing",
		displayName: "swing",
	},
	{
		class: "animated-tada",
		hoverClass: "group-hover:animated-tada",
		displayName: "tada",
	},
	{
		class: "animated-wobble",
		hoverClass: "group-hover:animated-wobble",
		displayName: "wobble",
	},
	{
		class: "animated-jello",
		hoverClass: "group-hover:animated-jello",
		displayName: "jello",
	},
	{
		class: "animated-heartBeat",
		hoverClass: "group-hover:animated-heartBeat",
		displayName: "heartBeat",
	},
	{
		class: "animated-bounceIn",
		hoverClass: "group-hover:animated-bounceIn",
		displayName: "bounce In",
	},
	{
		class: "animated-bounceInDown",
		hoverClass: "group-hover:animated-bounceInDown",
		displayName: "bounce In Down",
	},
	{
		class: "animated-bounceInLeft",
		hoverClass: "group-hover:animated-bounceInLeft",
		displayName: "bounce In Left",
	},
	{
		class: "animated-bounceInRight",
		hoverClass: "group-hover:animated-bounceInRight",
		displayName: "bounce In Right",
	},
	{
		class: "animated-bounceInUp",
		hoverClass: "group-hover:animated-bounceInUp",
		displayName: "bounce In Up",
	},
	{
		class: "animated-bounceOut",
		hoverClass: "group-hover:animated-bounceOut",
		displayName: "bounce Out",
	},
	{
		class: "animated-bounceOutDown",
		hoverClass: "group-hover:animated-bounceOutDown",
		displayName: "bounce Out Down",
	},
	{
		class: "animated-bounceOutLeft",
		hoverClass: "group-hover:animated-bounceOutLeft",
		displayName: "bounce Out Left",
	},
	{
		class: "animated-bounceOutRight",
		hoverClass: "group-hover:animated-bounceOutRight",
		displayName: "bounce Out Right",
	},
	{
		class: "animated-bounceOutUp",
		hoverClass: "group-hover:animated-bounceOutUp",
		displayName: "bounce Out Up",
	},
	{
		class: "animated-fadeIn",
		hoverClass: "group-hover:animated-fadeIn",
		displayName: "fade In",
	},
	{
		class: "animated-fadeInDown",
		hoverClass: "group-hover:animated-fadeInDown",
		displayName: "fade In Down",
	},
	{
		class: "animated-fadeInDownBig",
		hoverClass: "group-hover:animated-fadeInDownBig",
		displayName: "fade In Down Big",
	},
	{
		class: "animated-fadeInLeft",
		hoverClass: "group-hover:animated-fadeInLeft",
		displayName: "fade In Left",
	},
	{
		class: "animated-fadeInLeftBig",
		hoverClass: "group-hover:animated-fadeInLeftBig",
		displayName: "fade In Left Big",
	},
	{
		class: "animated-fadeInRight",
		hoverClass: "group-hover:animated-fadeInRight",
		displayName: "fade In Right",
	},
	{
		class: "animated-fadeInRightBig",
		hoverClass: "group-hover:animated-fadeInRightBig",
		displayName: "fade In Right Big",
	},
	{
		class: "animated-fadeInUp",
		hoverClass: "group-hover:animated-fadeInUp",
		displayName: "fade In Up",
	},
	{
		class: "animated-fadeInUpBig",
		hoverClass: "group-hover:animated-fadeInUpBig",
		displayName: "fade In Up Big",
	},
	{
		class: "animated-fadeOut",
		hoverClass: "group-hover:animated-fadeOut",
		displayName: "fade Out",
	},
	{
		class: "animated-fadeOutDown",
		hoverClass: "group-hover:animated-fadeOutDown",
		displayName: "fade Out Down",
	},
	{
		class: "animated-fadeOutDownBig",
		hoverClass: "group-hover:animated-fadeOutDownBig",
		displayName: "fadeOut Down Big",
	},
	{
		class: "animated-fadeOutLeft",
		hoverClass: "group-hover:animated-fadeOutLeft",
		displayName: "fade Out Left",
	},
	{
		class: "animated-fadeOutLeftBig",
		hoverClass: "group-hover:animated-fadeOutLeftBig",
		displayName: "fade Out Left Big",
	},
	{
		class: "animated-fadeOutRight",
		hoverClass: "group-hover:animated-fadeOutRight",
		displayName: "fade Out Right",
	},
	{
		class: "animated-fadeOutRightBig",
		hoverClass: "group-hover:animated-fadeOutRightBig",
		displayName: "fade Out Right Big",
	},
	{
		class: "animated-fadeOutUp",
		hoverClass: "group-hover:animated-fadeOutUp",
		displayName: "fade Out Up",
	},
	{
		class: "animated-fadeOutUpBig",
		hoverClass: "group-hover:animated-fadeOutUpBig",
		displayName: "fade Out Up Big",
	},
	{
		class: "animated-flip",
		hoverClass: "group-hover:animated-flip",
		displayName: "flip",
	},
	{
		class: "animated-flipInX",
		hoverClass: "group-hover:animated-flipInX",
		displayName: "flip In X",
	},
	{
		class: "animated-flipInY",
		hoverClass: "group-hover:animated-flipInY",
		displayName: "flip In Y",
	},

	{
		class: "animated-flipOutX",
		hoverClass: "group-hover:animated-flipOutX",
		displayName: "flip Out X",
	},
	{
		class: "animated-flipOutY",
		hoverClass: "group-hover:animated-flipOutY",
		displayName: "flip Out Y",
	},
	{
		class: "animated-lightSpeedIn",
		hoverClass: "group-hover:animated-lightSpeedIn",
		displayName: "light Speed In",
	},
	{
		class: "animated-lightSpeedOut",
		hoverClass: "group-hover:animated-lightSpeedOut",
		displayName: "light Speed Out",
	},
	{
		class: "animated-rotateIn",
		hoverClass: "group-hover:animated-rotateIn",
		displayName: "rotate In",
	},
	{
		class: "animated-rotateInDownLeft",
		hoverClass: "group-hover:animated-rotateInDownLeft",
		displayName: "rotate In Down Left",
	},
	{
		class: "animated-rotateInDownRight",
		hoverClass: "group-hover:animated-rotateInDownRight",
		displayName: "rotate In Down Right",
	},
	{
		class: "animated-rotateInUpRight",
		hoverClass: "group-hover:animated-rotateInUpRight",
		displayName: "rotate In Up Right",
	},
	{
		class: "animated-rotateOut",
		hoverClass: "group-hover:animated-rotateOut",
		displayName: "rotate Out",
	},
	{
		class: "animated-rotateOutDownLeft",
		hoverClass: "group-hover:animated-rotateOutDownLeft",
		displayName: "rotate Out Down Left",
	},
	{
		class: "animated-rotateOutDownRight",
		hoverClass: "group-hover:animated-rotateOutDownRight",
		displayName: "rotate Out Down Right",
	},
	{
		class: "animated-rotateOutUpLeft",
		hoverClass: "group-hover:animated-rotateOutUpLeft",
		displayName: "rotate Out Up Left",
	},
	{
		class: "animated-rotateOutUpRight",
		hoverClass: "group-hover:animated-rotateOutUpRight",
		displayName: "rotate Out Up Right",
	},
	{
		class: "animated-hinge",
		hoverClass: "group-hover:animated-hinge",
		displayName: "hinge",
	},
	{
		class: "animated-jackInTheBox",
		hoverClass: "group-hover:animated-jackInTheBox",
		displayName: "jack In The Box",
	},
	{
		class: "animated-rollIn",
		hoverClass: "group-hover:animated-rollIn",
		displayName: "roll In",
	},
	{
		class: "animated-rollOut",
		hoverClass: "group-hover:animated-rollOut",
		displayName: "roll Out",
	},
	{
		class: "animated-zoomIn",
		hoverClass: "group-hover:animated-zoomIn",
		displayName: "zoom In",
	},
	{
		class: "animated-zoomInDown",
		hoverClass: "group-hover:animated-zoomInDown",
		displayName: "zoom In Down",
	},
	{
		class: "animated-zoomInLeft",
		hoverClass: "group-hover:animated-zoomInLeft",
		displayName: "zoom In Left",
	},
	{
		class: "animated-zoomInRight",
		hoverClass: "group-hover:animated-zoomInRight",
		displayName: "zoom In Right",
	},
	{
		class: "animated-zoomInUp",
		hoverClass: "group-hover:animated-zoomInUp",
		displayName: "zoom In Up",
	},
	{
		class: "animated-zoomOut",
		hoverClass: "group-hover:animated-zoomOut",
		displayName: "zoom Out",
	},
	{
		class: "animated-zoomOutDown",
		hoverClass: "group-hover:animated-zoomOutDown",
		displayName: "zoom Out Down",
	},

	{
		class: "animated-zoomOutLeft",
		hoverClass: "group-hover:animated-zoomOutLeft",
		displayName: "zoom Out Left",
	},
	{
		class: "animated-zoomOutRight",
		hoverClass: "group-hover:animated-zoomOutRight",
		displayName: "zoom Out Right",
	},
	{
		class: "animated-zoomOutUp",
		hoverClass: "group-hover:animated-zoomOutUp",
		displayName: "zoom Out Up",
	},
	{
		class: "animated-slideInDown",
		hoverClass: "group-hover:animated-slideInDown",
		displayName: "slide In Down",
	},
	{
		class: "animated-slideInLeft",
		hoverClass: "group-hover:animated-slideInLeft",
		displayName: "slide In Left",
	},

	{
		class: "animated-slideInRight",
		hoverClass: "group-hover:animated-slideInRight",
		displayName: "slide In Right",
	},
	{
		class: "animated-slideInUp",
		hoverClass: "group-hover:animated-slideInUp",
		displayName: "slide In Up",
	},
	{
		class: "animated-slideOutDown",
		hoverClass: "group-hover:animated-slideOutDown",
		displayName: "slide Out Down",
	},
	{
		class: "animated-slideOutLeft",
		hoverClass: "group-hover:animated-slideOutLeft",
		displayName: "slide Out Left",
	},
	{
		class: "animated-slideOutRight",
		hoverClass: "group-hover:animated-slideOutRight",
		displayName: "slide Out Right",
	},
	{
		class: "animated-slideOutUp",
		hoverClass: "group-hover:animated-slideOutUp",
		displayName: "slide Out Up",
	},
	{
		class: "animated-backoutright",
		hoverClass: "group-hover:animated-backoutright",
		displayName: "Back Out Right",
	},
	{
		class: "animated-backoutup",
		hoverClass: "group-hover:animated-backoutup",
		displayName: "Back Out Up",
	}
];

const ParallaxEffects = [
	{
		name: "Rotate 360",
		data: {
			parallaxIndex: 0,
			isParallax: true,
			isParallaxBanner: false,
			applyEffects: true,
			isRotate: true,
			rotate: {
				start: 0,
				end: 360,
			},

		}
	},
	{
		name: "Forward FadeSlide",
		data: {
			parallaxIndex: 1,
			isParallax: true,
			isParallaxBanner: false,
			applyEffects: true,
			isTranslateX: true,
			translateX: {
				start: 0,
				end: 120,
			},
			isOpacity: true,
			opacity: {
				start: 1,
				end: 0
			}

		}
	},
	{
		name: "Backward FadeSlide",
		data: {
			parallaxIndex: 2,
			isParallax: true,
			isParallaxBanner: false,
			applyEffects: true,
			isTranslateX: true,
			translateX: {
				start: 0,
				end: -80,
			},
			isOpacity: true,
			opacity: {
				start: 0.9,
				end: 0
			}

		}
	},
	{
		name: "Up FadeSlide",
		data: {
			parallaxIndex: 3,
			isParallax: true,
			isParallaxBanner: false,
			applyEffects: true,
			isTranslateY: true,
			translateY: {
				start: 0,
				end: -120,
			},
			isOpacity: true,
			opacity: {
				start: 1,
				end: 0
			}

		}
	},
	{
		name: "Down FadeSlide",
		data: {
			parallaxIndex: 4,
			isParallax: true,
			isParallaxBanner: false,
			applyEffects: true,
			isTranslateY: true,
			translateY: {
				start: 0,
				end: 120,
			},
			isOpacity: true,
			opacity: {
				start: 1,
				end: 0
			}

		}
	},
	{
		name: "Zoom In Fade",
		data: {
			parallaxIndex: 5,
			isParallax: true,
			isParallaxBanner: false,
			applyEffects: true,
			isScale: true,
			scale: {
				start: 0.7,
				end: 3,
			},
			isOpacity: true,
			opacity: {
				start: 1,
				end: 0.1
			}

		}
	},
	{
		name: "Zoom Out Fade",
		data: {
			parallaxIndex: 6,
			isParallax: true,
			isParallaxBanner: false,
			applyEffects: true,
			isScale: true,
			scale: {
				start: 3,
				end: 1,
			},
			isOpacity: true,
			opacity: {
				start: 1,
				end: 0.1
			}

		}
	},

];

const Animation_Parallax = ({ updateDesign, valueTypes, setValueTypes, timerref, selectedState, classes, AddToUndo, setElementAllBreakpointsStyleObject, addNewStyleClass, addImportant, params, setClasses }) => {

	const {
		blocks,
		elementRef,
		elementId,
		formElementRef,
		undo,
		setUndo,
		currentPlatform,
		Bdispatch,
		selectedSiteData,
		sqliteSchema,
		strapiSchema,
		setRedo,
		pageName,
		elementBreakpointSpecificStyleObject,
		setElementBreakpointSpecificStyleObject,
		selectedBreakpoint,
		openedSections,
		setOpenedSections,
		setIsAnimating,
		layout,
		content,
		pageId
	} = useContext(context);

	const [dropDown, setDropDown] = useState(false);
	const [dropDownParallax, setDropDownParallax] = useState(false);
	const [currentAnimation, setCurrentAnimation] = useState({
		index: -1,
		showModal: false,
		switchScroll: false,
		switchFirstTime: false,
		switchContinuously: false,
		switchRefresh: true,
		Duration: 5,
		Delay: 0,
	});

	const AllParllaxEasings = ["ease", "easeIn", "easeOut", "easeInOut", "easeInQuad", "easeInCubic", "easeInSine", "easeOutQuad", "easeOutCubic", "easeOutQuart", "easeInOutQuad", "easeInOutCubic", "easeInOutQuart", "easeInOutSine", "easeInBack", "easeOutBack", "easeInOutBack"];
	const [isOpenParallaxModal, setIsOpenParallaxModal] = useState(false);
	const [currentParallax, setCurrentParallax] = useState({
		parallaxIndex: elementId?.data?.parallaxIndex ?? -1,
		isParallax: true,
		isRotate: elementId?.data?.isRotate ?? false,
		rotate: {
			start: elementId?.data?.rotate?.start ?? 0,
			end: elementId?.data?.rotate?.end ?? 360,
			active: "start"
		},
		isRotateY: elementId?.data?.isRotateY ?? false,
		rotateY: {
			start: elementId?.data?.rotateY?.start ?? 0,
			end: elementId?.data?.rotateY?.end ?? 0,
			active: "start"
		},
		isRotateX: elementId?.data?.isRotateX ?? false,
		rotateX: {
			start: elementId?.data?.rotateX?.start ?? 0,
			end: elementId?.data?.rotateX?.end ?? 0,
			active: "start"
		},
		isRotateZ: elementId?.data?.isRotateZ ?? false,
		rotateZ: {
			start: elementId?.data?.rotateZ?.start ?? 0,
			end: elementId?.data?.rotateZ?.end ?? 0,
			active: "start"
		},
		isTranslateX: elementId?.data?.isTranslateX ?? false,
		translateX: {
			start: elementId?.data?.translateX?.start ?? 0,
			end: elementId?.data?.translateX?.end ?? 0,
			active: "start"
		},
		isTranslateY: elementId?.data?.isTranslateY ?? false,
		translateY: {
			start: elementId?.data?.translateY?.start ?? 0,
			end: elementId?.data?.translateY?.end ?? 0,
			active: "start"
		},
		isScale: elementId?.data?.isScale ?? false,
		scale: {
			start: elementId?.data?.scale?.start ?? 1,
			end: elementId?.data?.scale?.end ?? 1,
			active: "start"
		},
		isScaleX: elementId?.data?.isScaleX ?? false,
		scaleX: {
			start: elementId?.data?.scaleX?.start ?? 1,
			end: elementId?.data?.scaleX?.end ?? 1,
			active: "start"
		},
		isScaleY: elementId?.data?.isScaleY ?? false,
		scaleY: {
			start: elementId?.data?.scaleY?.start ?? 1,
			end: elementId?.data?.scaleY?.end ?? 1,
			active: "start"
		},
		isScaleZ: elementId?.data?.isScaleZ ?? false,
		scaleZ: {
			start: elementId?.data?.scaleZ?.start ?? 1,
			end: elementId?.data?.scaleZ?.end ?? 1,
			active: "start"
		},
		isOpacity: elementId?.data?.isOpacity ?? false,
		opacity: {
			start: elementId?.data?.opacity?.start ?? 1,
			end: elementId?.data?.opacity?.end ?? 1,
			active: "start"
		},
		isChangeTime: elementId?.data?.isChangeTime ?? false,
		ChangeTime: {
			start: elementId?.data?.ChangeTime?.start ?? 0,
			end: elementId?.data?.ChangeTime?.end ?? 0
		},
		isSpeed: elementId?.data?.isSpeed ?? false,
		speed: elementId?.data?.speed ?? 0,
		easing: 0,
		isParallaxBanner: elementId?.data?.isParallaxBanner ?? false,
		applyEffects: (elementId?.data && Object.keys(elementId.data).includes('applyEffects')) ? elementId?.data?.applyEffects : true

	});
	const [activeTab, setActiveTab] = useState("demo");
	const [reloadIFrame, setReloadIFrame] = useState(0);
	const [inputs, setInputs] = useState([]);
	const [circularText, setCircularText] = useState(elementId?.data?.isCircularText ?? false);
	const [circularTextRadius, setCircularTextRadius] = useState(elementId?.data?.radius ?? 100);
	const [isTypingEffect, setIsTypingEffect] = useState(elementId?.data?.isTypingEffect ?? false);
	const [typingSpeed, setTypingSpeed] = useState(elementId?.data?.speed ?? 0);

	let [isOpen, setIsOpen] = useState(false);
	const resetParallax = async () => {
		await new Promise((resolve) => {
			setCurrentParallax({
				parallaxIndex: -1,
				isParallax: true,
				isRotate: false,
				rotate: {
					start: 0,
					end: 360,
					active: "start",
				},
				isRotateY: false,
				rotateY: {
					start: 0,
					end: 0,
					active: "start",
				},
				isRotateX: false,
				rotateX: {
					start: 0,
					end: 0,
					active: "start",
				},
				isRotateZ: false,
				rotateZ: {
					start: 0,
					end: 0,
					active: "start",
				},
				isTranslateX: false,
				translateX: {
					start: 0,
					end: 0,
					active: "start",
				},
				isTranslateY: false,
				translateY: {
					start: 0,
					end: 0,
					active: "start",
				},
				isScale: false,
				scale: {
					start: 1,
					end: 1,
					active: "start",
				},
				isScaleX: false,
				scaleX: {
					start: 1,
					end: 1,
					active: "start",
				},
				isScaleY: false,
				scaleY: {
					start: 1,
					end: 1,
					active: "start",
				},
				isScaleZ: false,
				scaleZ: {
					start: 1,
					end: 1,
					active: "start",
				},
				isOpacity: false,
				opacity: {
					start: 1,
					end: 1,
					active: "start",
				},
				isChangeTime: false,
				ChangeTime: {
					start: 0,
					end: 0,
				},
				isSpeed: false,
				speed: 0,
				easing: 0,
				isParallaxBanner: false,
				applyEffects: false,
			});
			resolve();
		});

	}

	function closeModal() {
		setCurrentAnimation({
			index: -1,
			showModal: false,
			switchScroll: false,
			switchFirstTime: false,
			switchContinuously: false,
			switchRefresh: true,
			Duration: 5,
			Delay: 0,
		});
		setIsOpen(false);
		setIsOpenParallaxModal(false);
		setCurrentParallax({
			parallaxIndex: elementId?.data?.parallaxIndex ?? -1,
			isParallax: true,
			isRotate: elementId?.data?.isRotate ?? false,
			rotate: {
				start: elementId?.data?.rotate?.start ?? 0,
				end: elementId?.data?.rotate?.end ?? 360,
				active: "start"
			},
			isRotateY: elementId?.data?.isRotateY ?? false,
			rotateY: {
				start: elementId?.data?.rotateY?.start ?? 0,
				end: elementId?.data?.rotateY?.end ?? 0,
				active: "start"
			},
			isRotateX: elementId?.data?.isRotateX ?? false,
			rotateX: {
				start: elementId?.data?.rotateX?.start ?? 0,
				end: elementId?.data?.rotateX?.end ?? 0,
				active: "start"
			},
			isRotateZ: elementId?.data?.isRotateZ ?? false,
			rotateZ: {
				start: elementId?.data?.rotateZ?.start ?? 0,
				end: elementId?.data?.rotateZ?.end ?? 0,
				active: "start"
			},
			isTranslateX: elementId?.data?.isTranslateX ?? false,
			translateX: {
				start: elementId?.data?.translateX?.start ?? 0,
				end: elementId?.data?.translateX?.end ?? 0,
				active: "start"
			},
			isTranslateY: elementId?.data?.isTranslateY ?? false,
			translateY: {
				start: elementId?.data?.translateY?.start ?? 0,
				end: elementId?.data?.translateY?.end ?? 0,
				active: "start"
			},
			isScale: elementId?.data?.isScale ?? false,
			scale: {
				start: elementId?.data?.scale?.start ?? 1,
				end: elementId?.data?.scale?.end ?? 1,
				active: "start"
			},
			isScaleX: elementId?.data?.isScaleX ?? false,
			scaleX: {
				start: elementId?.data?.scaleX?.start ?? 1,
				end: elementId?.data?.scaleX?.end ?? 1,
				active: "start"
			},
			isScaleY: elementId?.data?.isScaleY ?? false,
			scaleY: {
				start: elementId?.data?.scaleY?.start ?? 1,
				end: elementId?.data?.scaleY?.end ?? 1,
				active: "start"
			},
			isScaleZ: elementId?.data?.isScaleZ ?? false,
			scaleZ: {
				start: elementId?.data?.scaleZ?.start ?? 1,
				end: elementId?.data?.scaleZ?.end ?? 1,
				active: "start"
			},
			isOpacity: elementId?.data?.isOpacity ?? false,
			opacity: {
				start: elementId?.data?.opacity?.start ?? 1,
				end: elementId?.data?.opacity?.end ?? 1,
				active: "start"
			},
			isChangeTime: elementId?.data?.isChangeTime ?? false,
			ChangeTime: {
				start: elementId?.data?.ChangeTime?.start ?? 0,
				end: elementId?.data?.ChangeTime?.end ?? 0
			},
			isSpeed: elementId?.data?.isSpeed ?? false,
			speed: elementId?.data?.speed ?? 0,
			easing: 0,
			isParallaxBanner: elementId?.data?.isParallaxBanner ?? false,
			applyEffects: (elementId?.data && Object.keys(elementId.data).includes('applyEffects')) ? elementId?.data?.applyEffects : true

		});
	}

	const SaveParallax = async () => {
		let data = {
			applyEffects: currentParallax?.applyEffects,
			isParallax: currentParallax?.isParallaxBanner ? false : true,
			easing: AllParllaxEasings[currentParallax?.easing],
			isParallaxBanner: currentParallax?.isParallaxBanner,
			isOpacity: currentParallax?.isOpacity,
			opacity: {
				start: Number(currentParallax?.opacity?.start),
				end: Number(currentParallax?.opacity?.end)
			},
			isSpeed: currentParallax?.isSpeed,
			speed: Number(currentParallax?.speed),
			isRotate: currentParallax?.isRotate,
			rotate: {
				start: Number(currentParallax?.rotate?.start),
				end: Number(currentParallax?.rotate?.end),
			},
			isRotateX: currentParallax?.isRotateX,
			rotateX: {
				start: Number(currentParallax?.rotateX?.start),
				end: Number(currentParallax?.rotateX?.end)
			},
			isRotateY: currentParallax?.isRotateY,
			rotateY: {
				start: Number(currentParallax?.rotateY?.start),
				end: Number(currentParallax?.rotateY?.end)
			},
			isRotateZ: currentParallax?.isRotateZ,
			rotateZ: {
				start: Number(currentParallax?.rotateZ?.start),
				end: Number(currentParallax?.rotateZ?.end)
			},
			isScale: currentParallax?.isScale,
			scale: {
				start: Number(currentParallax?.scale?.start),
				end: Number(currentParallax?.scale?.end)
			},
			isScaleX: currentParallax?.isScaleX,
			scaleX: {
				start: Number(currentParallax?.scaleX?.start),
				end: Number(currentParallax?.scaleX?.end)
			},
			isScaleY: currentParallax?.isScaleY,
			scaleY: {
				start: Number(currentParallax?.scaleY?.start),
				end: Number(currentParallax?.scaleY?.end)
			},
			isScaleZ: currentParallax?.isScaleZ,
			scaleZ: {
				start: Number(currentParallax?.scaleZ?.start),
				end: Number(currentParallax?.scaleZ?.end)
			},
			isTranslateX: currentParallax?.isTranslateX,
			translateX: {
				start: Number(currentParallax?.translateX?.start),
				end: Number(currentParallax?.translateX?.end)
			},
			isTranslateY: currentParallax?.isTranslateY,
			translateY: {
				start: Number(currentParallax?.translateY?.start),
				end: Number(currentParallax?.translateY?.end)
			},
			isChangeTime: currentParallax?.isChangeTime,
			ChangeTime: {
				start: Number(currentParallax?.ChangeTime?.start),
				end: Number(currentParallax?.ChangeTime?.end)
			}

		};


		if (!currentParallax?.applyEffects) {
			data = {
				...data,
				isParallax: false,
				isParallaxBanner: false,
				applyEffects: false
			}
		}

		if (elementId?.data?.backgroundImage) {
			const coordinates = document.getElementById(elementId.id).getBoundingClientRect();
			console.log("Height is ", coordinates)
			if (!elementId.css.includes('h-')) {
				elementId.css += ` h-[${coordinates.height}px]`
			}
		}

		await Bdispatch(
			{
				type: "CHANGE_ELEMENT_SCHEMA",
				id: elementId.id,
				element: data,
			}
		);


		saveProject({
			setIsAnimating: setIsAnimating,
			layout: layout,
			blocks: blocks,
			content: content,
			pageId: pageId,
			selectedSiteData: selectedSiteData,
			sqliteSchema: sqliteSchema,
			strapiSchema: strapiSchema,
			params: params
		});
		setReloadIFrame((prev) => prev + 1);
	}

	useEffect(() => {
		setCircularText(elementId?.data?.isCircularText ?? false);
		setCircularTextRadius(elementId?.data?.radius ?? 100);
		setIsTypingEffect(elementId?.data?.isTypingEffect ?? false);
		setTypingSpeed(elementId?.data?.speed ?? 250);

		if (elementId?.data?.sequence) {
			setInputs(elementId?.data?.sequence);
		}
		else {
			setInputs([{ id: 1, value: `${elementId?.content ? elementId?.content : "Hello"}`, delay: 500 }])
		}

	}, [elementId.id])

	return (
		<>
			{openedSections.effects == true && (
				<div className="p-3 space-y-3">
					<AnimationEffects openedSections={openedSections} setOpenedSections={setOpenedSections} updateDesign={updateDesign} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} valueTypes={valueTypes} setValueTypes={setValueTypes} />

					{
						elementId?.content && (
							<>

								<div className="flex items-center justify-between gap-1">
									<p className="text-sm text-[#373C47]">
										Circular Text
									</p>
									<Switch
										checked={
											circularText
										}
										onChange={async () => {
											setCircularText(!circularText)

											const data = {
												isCircularText: !circularText,
												radius: circularTextRadius
											}

											await Bdispatch(
												{
													type: "CHANGE_ELEMENT_SCHEMA",
													id: elementId.id,
													element: data,
												}
											);
										}}

										className={`${circularText
											? "bg-teal-900"
											: "bg-teal-700"
											}
                                            relative inline-flex h-[18px] w-[39px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
									>
										<span className="sr-only">
											Use
											setting
										</span>
										<span
											aria-hidden="true"
											className={`${circularText
												? "translate-x-6"
												: "translate-x-0"
												}
                                                    pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
										/>
									</Switch>

								</div>
								{
									circularText && (
										<div className="flex items-center gap-3 ml-3">
											<p className="text-sm text-[#373C47]">
												Radius
											</p>
											<input type='range' min={0} max={800} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
												value={parseInt(
													circularTextRadius
												)}

												onChange={async (e) => {

													setCircularTextRadius(e.target.value);


													const data = {
														isCircularText: true,
														radius: e.target.value
													}

													await Bdispatch(
														{
															type: "CHANGE_ELEMENT_SCHEMA",
															id: elementId.id,
															element: data,
														}
													);
												}} />

											<input
												type="number"
												className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
												value={parseInt(
													circularTextRadius
												)}

												onChange={async (e) => {

													setCircularTextRadius(e.target.value);


													const data = {
														isCircularText: true,
														radius: e.target.value
													}

													await Bdispatch(
														{
															type: "CHANGE_ELEMENT_SCHEMA",
															id: elementId.id,
															element: data,
														}
													);
												}}
												onKeyUp={async (
													e
												) => {
													if (
														e.key == "Enter"
													) {
														const data = {
															isCircularText: true,
															radius: e.target.value
														}

														await Bdispatch(
															{
																type: "CHANGE_ELEMENT_SCHEMA",
																id: elementId.id,
																element: data,
															}
														);
													}
												}}
												onBlur={async (e) => {

													const data = {
														isCircularText: true,
														radius: e.target.value
													}

													await Bdispatch(
														{
															type: "CHANGE_ELEMENT_SCHEMA",
															id: elementId.id,
															element: data,
														}
													);
												}}
											/>

										</div>
									)

								}
							</>
						)
					}

					{
						elementId?.content && (
							<>
								<div className="flex items-center justify-between gap-2">
									<p className="text-sm text-[#373C47]">Typing Effect </p>
									<Switch
										checked={
											isTypingEffect
										}
										onChange={async () => {
											setIsTypingEffect(!isTypingEffect);
											const data = {
												isTypingEffect: !isTypingEffect,
												speed: typingSpeed,
												sequence: inputs
											}

											await Bdispatch(
												{
													type: "CHANGE_ELEMENT_SCHEMA",
													id: elementId.id,
													element: data,
												}
											);
										}}

										className={`${isTypingEffect
											? "bg-teal-900"
											: "bg-teal-700"
											}
                                            relative inline-flex h-[18px] w-[39px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
									>
										<span className="sr-only">
											Use
											setting
										</span>
										<span
											aria-hidden="true"
											className={`${isTypingEffect
												? "translate-x-6"
												: "translate-x-0"
												}
                                                    pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
										/>
									</Switch>
								</div>


								{
									isTypingEffect && (

										<div className="flex flex-col items-center gap-y-2 text-[#373C47]">
											<div className="flex items-center gap-3 ml-3">
												<p className="text-sm text-[#373C47]">
													Speed
												</p>
												<input type='range' min={0} max={100} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
													value={parseInt(
														typingSpeed
													)}

													onChange={async (e) => {

														setTypingSpeed(e.target.value);

														clearTimeout(timerref.current);
														timerref.current = setTimeout(
															async () => {

																const data = {
																	sequence: inputs,
																	isTypingEffect,
																	speed: e.target.value
																}

																await Bdispatch(
																	{
																		type: "CHANGE_ELEMENT_SCHEMA",
																		id: elementId.id,
																		element: data,
																	}
																);

															},
															1000
														);




													}} />

												<div className="flex items-center">

													<input
														type="number"
														className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
														value={parseInt(
															typingSpeed
														)}

														onChange={async (e) => {

															setTypingSpeed(e.target.value);

														}}
														onKeyUp={async (
															e
														) => {
															if (
																e.key == "Enter"
															) {
																setTypingSpeed(e.target.value);

																const data = {
																	sequence: inputs,
																	isTypingEffect,
																	speed: e.target.value
																}

																await Bdispatch(
																	{
																		type: "CHANGE_ELEMENT_SCHEMA",
																		id: elementId.id,
																		element: data,
																	}
																);
															}
														}}
														onBlur={async (e) => {

															setTypingSpeed(e.target.value);

															const data = {
																sequence: inputs,
																isTypingEffect,
																speed: e.target.value
															}

															await Bdispatch(
																{
																	type: "CHANGE_ELEMENT_SCHEMA",
																	id: elementId.id,
																	element: data,
																}
															);
														}}
													/>
													<span className="px-2 py-1 border border-l-0 shadow text-xs rounded-r border-gray-300">ms</span>
												</div>


											</div>

											<button
												type="button"
												onClick={() => {
													setInputs([...inputs, { id: inputs.length + 1, value: 'Hello', delay: 250 }]);
												}}
												className="outline-none rounded text-sm border py-1 px-2 w-max ml-auto">Add Text
											</button>

											<div className="mb-[0.7px] text-xs w-full text-[#373C47]">
												<span className="mr-auto">Text</span>
												<span className="ml-44">Delay (ms)</span>
											</div>
											<div className="max-h-[150px] overflow-y-auto overflow-x-hidden w-full flex flex-col gap-y-2 no-scrollbar">
												{
													inputs.map((oneInput, i) => {
														return (
															<>
																<div key={i} className="flex items-center gap-1">
																	<input
																		type="text"
																		value={oneInput.value}
																		onChange={(e) => {
																			setInputs((prevInputs) => {
																				const newInputs = [...prevInputs];
																				newInputs[i].value = e.target.value;
																				return newInputs;
																			});
																		}}
																		className="outline-none rounded-l px-2 py-1 border border-gray-300 focus:ring-0  focus:border-[#D9D9D9]  text-xs" />

																	<input
																		type='number'
																		value={oneInput.delay}
																		onChange={(e) => {
																			setInputs((prevInputs) => {
																				const newInputs = [...prevInputs];
																				newInputs[i].delay = e.target.value;
																				return newInputs;
																			});
																		}}
																		className="w-[50px] outline-none rounded-l px-2 py-1 border border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs" />
																	{
																		i != 0 && (

																			<span class="mdi mdi-delete text-lg text-[#373C47] cursor-pointer hover:text-red-600" title="Remove Text" onClick={async () => {
																				const arr = inputs.filter((_, index) => i !== index)
																				setInputs(arr);

																				const data = {
																					sequence: arr,
																					isTypingEffect: true,
																					speed: typingSpeed
																				}

																				await Bdispatch(
																					{
																						type: "CHANGE_ELEMENT_SCHEMA",
																						id: elementId.id,
																						element:
																							data,
																					}
																				);
																			}}></span>
																		)
																	}
																</div>

															</>
														)
													})
												}
											</div>

											<button
												type="button"
												className="w-max ml-3` mr-auto rounded-md border border-transparent text-blue-900 bg-blue-200  px-2 py-1 text-sm font-medium  hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
												onClick={async () => {
													const data = {
														sequence: inputs,
														isTypingEffect: true,
														speed: typingSpeed
													}

													await Bdispatch(
														{
															type: "CHANGE_ELEMENT_SCHEMA",
															id: elementId.id,
															element:
																data,
														}
													);
													console.log("TypesEffect data: ", data)
												}}>Done</button>

										</div>
									)
								}


							</>
						)
					}

					<div className={`relative`}>
						<div
							className="my-4 flex  justify-between gap-2 items-center w-full rounded-md bg-white  text-center px-2.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer hover:text-indigo-600 "
							onClick={() => setDropDown(!dropDown)}
						>
							<div className="">Add Animations</div>
							<div className="mt-2">
								{" "}
								<ChevronUpDownIcon className="h-5  inline-block" />
							</div>
						</div>

						{dropDown == true && (
							<div className="w-72 text-center flex flex-wrap  border border-b-gray-200 overflow-x-hidden rounded-lg shadow bg-white h-80 overflow-y-scroll no-scrollbar">
								<button
									type="button"
									className={`relative group w-[40%]  border-b border-gray-200  hover:border-gray-800  flex flex-col items-center group mx-2 py-1 ${elementBreakpointSpecificStyleObject.animation == "none" ? "bg-gray-200" : ""}`}
									onClick={async () => {
										setElementAllBreakpointsStyleObject(
											(prev) => {
												return {
													...prev,
													[selectedBreakpoint.name]:
													{
														...prev[
														selectedBreakpoint
															.name
														],
														[selectedState.key]:
														{
															...prev[
															selectedBreakpoint
																.name
															][
															selectedState
																.key
															],
															animation:
																"none",
														},
													},
												};
											}
										);
										const classesArray =
											classes.split(" ");
										const newClasses =
											removeDuplicateClasses(
												classesArray,
												"animation",
												selectedBreakpoint.classPrefix,
												selectedState.value
											);
										if (
											formElementRef?.current &&
											formElementRef?.current
												.id !== elementId.id
										) {
											elementId.data.css[
												elementRef.current?.dataset?.name
											] = newClasses;
										} else {
											elementId.css =
												newClasses;
										}
										setClasses(newClasses);
										AddToUndo(
											selectedSiteData.static,
											blocks,
											sqliteSchema,
											strapiSchema,
											undo,
											setUndo,
											setRedo
										);
										console.log(
											currentAnimation
										);
										if (
											elementId?.data
												?.IsAnimation
										) {
											const data = {
												IsAnimation: false,
												Duration:
													currentAnimation.Duration,
												Delay: currentAnimation.Delay,
												AnimateFirstTime:
													currentAnimation.switchFirstTime,
												animationIn:
													currentAnimation?.animationIn
											};
											console.log(
												data,
												"UPDATECLASSES"
											);
											await Bdispatch({
												type: "CHANGE_ELEMENT_SCHEMA",
												id: elementId.id,
												element: data,
											});
										}
									}}
								>
									<div
										className={`p-2 rounded-md overflow-hidden`}
									>
										<img
											src="/animation.jpeg"
											className={`w-10 h-10 `}
										/>
									</div>
									<div className="text-sm">
										None
									</div>
								</button>

								{animationsArray.map(
									(value, index) => {
										return (
											<button
												type="button"
												className={`relative group w-[40%]  border-b border-gray-200  hover:border-gray-800  flex flex-col items-center group mx-2 py-1 ${elementBreakpointSpecificStyleObject.animation == value.class.split("-")[1] ? "bg-gray-200" : ""}`}
												onClick={async () => {
													setCurrentAnimation(
														{
															...currentAnimation,
															index: index,
															animationIn:
																value.class,
														}
													);

													setElementAllBreakpointsStyleObject(
														(
															prev
														) => {
															return {
																...prev,
																[selectedBreakpoint.name]:
																{
																	...prev[
																	selectedBreakpoint
																		.name
																	],
																	[selectedState.key]:
																	{
																		...prev[
																		selectedBreakpoint
																			.name
																		][
																		selectedState
																			.key
																		],
																		animation:
																			value.class.split(
																				"-"
																			)[1],
																	},
																},
															};
														}
													);
													const classesArray =
														classes.split(
															" "
														);
													const updatedClasses =
														removeDuplicateClasses(
															classesArray,
															"animation",
															selectedBreakpoint.classPrefix,
															selectedState.value
														);
													const newClasses =
														addNewStyleClass(
															updatedClasses,
															"animation",
															`${value.class}`,
															selectedBreakpoint.classPrefix,
															selectedState.value,
															addImportant
														);
													if (
														formElementRef?.current &&
														formElementRef
															?.current
															.id !==
														elementId.id
													) {
														elementId.data.css[
															elementRef.current?.dataset?.name
														] =
															newClasses;
													} else {
														elementId.css =
															newClasses;
													}
													setClasses(
														newClasses
													);
													AddToUndo(
														selectedSiteData.static,
														blocks,
														sqliteSchema,
														strapiSchema,
														undo,
														setUndo,
														setRedo
													);

													const data =
													{
														IsAnimation: true,
														animateOnce: currentAnimation.switchFirstTime,
														animationIn: value.class,
														AnimateInfinite: currentAnimation.switchContinuously
													};
													await Bdispatch(
														{
															type: "CHANGE_ELEMENT_SCHEMA",
															id: elementId.id,
															element: data,
														}
													);
												}}
												onMouseEnter={(
													e
												) => {
													e.stopPropagation();
													setCurrentAnimation(
														{
															...currentAnimation,
															index: index,
															animationIn:
																value.class,
														}
													);
												}}
											>
												<div
													className={`p-2 rounded-md overflow-hidden`}
												>
													{currentAnimation.index ==
														index && (
															<IconDotsVertical
																className="absolute right-0 text-right align-right cursor-pointer"
																onClick={(
																	e
																) => {
																	e.stopPropagation();
																	setIsOpen(
																		true
																	);
																	console.log(
																		elementId,
																		"THIS IS ELEMENT ID"
																	);
																	setCurrentAnimation(
																		{
																			Duration: elementId?.data?.Duration
																				? elementId?.data?.Duration
																				: currentAnimation?.Duration,

																			Delay: elementId?.data?.Delay
																				? elementId?.data?.Delay
																				: currentAnimation?.Delay,

																			switchFirstTime: elementId?.data?.AnimateFirstTime
																				? elementId?.data?.AnimateFirstTime
																				: currentAnimation?.switchFirstTime,

																			animationIn: elementId?.data?.animationIn
																				? elementId?.data?.animationIn
																				: false,

																			switchContinuously: elementId?.data?.AnimateInfinite ? elementId?.data?.AnimateInfinite : false
																		}
																	);
																	// setUpdate
																}}
															/>
														)}
													<img
														src="/animation.jpeg"
														className={`w-10 h-10 ${value.hoverClass}`}
													/>
												</div>

												<div className="text-sm">
													{
														value.displayName
													}
												</div>
											</button>
										);
									}
								)}
								<Transition
									appear
									show={isOpen}
									as={Fragment}
								>
									<Dialog
										as="div"
										className="relative z-50"
										onClose={closeModal}
									>
										<Transition.Child
											as={Fragment}
											enter="ease-out duration-300"
											enterFrom="opacity-0"
											enterTo="opacity-100"
											leave="ease-in duration-200"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
										>
											<div className="fixed inset-0 bg-black bg-opacity-60" />
										</Transition.Child>

										<div className="fixed inset-0 overflow-y-auto">
											<div className="flex min-h-full items-center justify-center p-4 text-center">
												<Transition.Child
													as={Fragment}
													enter="ease-out duration-300"
													enterFrom="opacity-0 scale-95"
													enterTo="opacity-100 scale-100"
													leave="ease-in duration-200"
													leaveFrom="opacity-100 scale-100"
													leaveTo="opacity-0 scale-95"
												>
													<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
														<Dialog.Title
															as="h3"
															className="inline text-lg font-medium leading-6 text-gray-900 border-b-2 border-blue-400 p-2 rounded-full px-4"
														>
															Animation
															Setting
														</Dialog.Title>

														<div className="mt-6 flex flex-col justify-	between ">
															{/* Animate Only Once */}
															<div className="flex items-center justify-between my-4">
																<p className="text-xl text-gray-700">
																	Animate only Once
																</p>
																<Switch
																	checked={
																		currentAnimation.switchFirstTime
																	}
																	onChange={() => {

																		setCurrentAnimation(
																			{
																				...currentAnimation,
																				switchFirstTime:
																					!currentAnimation.switchFirstTime
																			}
																		);
																	}}
																	className={`${currentAnimation.switchFirstTime
																		? "bg-teal-900"
																		: "bg-teal-700"
																		}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																>
																	<span className="sr-only">
																		Use
																		setting
																	</span>
																	<span
																		aria-hidden="true"
																		className={`${currentAnimation.switchFirstTime
																			? "translate-x-9"
																			: "translate-x-0"
																			}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																	/>
																</Switch>
															</div>
															<hr />

															{/* Animate Continuously*/}
															<div className="flex items-center justify-between my-4">
																<p className="text-xl text-gray-700">
																	Animate Infinitly
																</p>
																<Switch
																	checked={
																		currentAnimation.switchContinuously
																	}
																	onChange={() => {

																		setCurrentAnimation(
																			{
																				...currentAnimation,
																				switchContinuously:
																					!currentAnimation.switchContinuously
																			}
																		);
																	}}
																	className={`${currentAnimation.switchContinuously
																		? "bg-teal-900"
																		: "bg-teal-700"
																		}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																>
																	<span className="sr-only">
																		Use
																		setting
																	</span>
																	<span
																		aria-hidden="true"
																		className={`${currentAnimation.switchContinuously
																			? "translate-x-9"
																			: "translate-x-0"
																			}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																	/>
																</Switch>
															</div>
															<hr />

															{/* Duration */}
															<div className="flex flex-col items-start justify-between my-4">
																<p className="text-xl text-gray-700">
																	Duration{" "}
																	<strong className="text-xs">
																		(in
																		Seconds)
																	</strong>
																</p>
																<div className="flex items-center justify-between">
																	<input
																		type="range"
																		min="1"
																		max="200"
																		className="w-[80%] h-[2px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																		value={
																			currentAnimation.Duration
																		}
																		onChange={(
																			e
																		) => {
																			setCurrentAnimation(
																				{
																					...currentAnimation,
																					Duration: e.target.value,
																				}
																			);
																		}}
																	/>
																	<input
																		type="number"
																		value={
																			currentAnimation.Duration
																		}
																		className="ml-2 w-[20%] rounded-xl ring-0 outline-0"
																	/>
																</div>
															</div>
															<hr />

															{/* Delay */}
															<div className="flex flex-col items-start justify-between my-4">
																<p className="text-xl text-gray-700">
																	Delay{" "}
																	<strong className="text-xs">
																		(Milli
																		Seconds)
																	</strong>
																</p>
																<div className="flex items-center justify-between">
																	<input
																		type="range"
																		value={
																			currentAnimation.Delay
																		}
																		min="1"
																		max="20000"
																		className="w-[80%] h-[2px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																		onChange={(
																			e
																		) => {
																			setCurrentAnimation(
																				{
																					...currentAnimation,
																					Delay: e.target.value,
																				}
																			);
																		}}
																	/>
																	<input
																		type="number"
																		value={
																			currentAnimation.Delay
																		}
																		className="ml-2 w-[20%] rounded-xl ring-0 outline-0"
																	/>
																</div>
															</div>
															<hr />



														</div>

														<div className="mt-4">
															<button
																type="button"
																className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
																onClick={() => {
																	closeModal();
																}}
															>
																Cancel
															</button>
															<button
																type="button"
																className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
																onClick={async () => {

																	let animateValue = `${currentAnimation.animationIn}`;
																	if (currentAnimation.switchContinuously) {
																		animateValue += " infinite";
																	}

																	setElementAllBreakpointsStyleObject(
																		(
																			prev
																		) => {
																			return {
																				...prev,
																				[selectedBreakpoint.name]:
																				{
																					...prev[
																					selectedBreakpoint
																						.name
																					],
																					[selectedState.key]:
																					{
																						...prev[
																						selectedBreakpoint
																							.name
																						][
																						selectedState
																							.key
																						],
																						animation:
																							currentAnimation.animationIn?.split("-")[1],
																					},
																				},
																			};
																		}
																	);
																	const classesArray =
																		classes.split(
																			" "
																		);
																	let updatedClasses =
																		removeDuplicateClasses(
																			classesArray,
																			"animation",
																			selectedBreakpoint.classPrefix,
																			selectedState.value
																		);

																	if (!currentAnimation.switchContinuously) {
																		updatedClasses = removeDuplicateClasses(
																			updatedClasses,
																			"infinite",
																			selectedBreakpoint.classPrefix,
																			selectedState.value
																		);
																	}

																	const newClasses =
																		addNewStyleClass(
																			updatedClasses,
																			"animation",
																			animateValue,
																			selectedBreakpoint.classPrefix,
																			selectedState.value,
																			addImportant
																		);
																	if (
																		formElementRef?.current &&
																		formElementRef?.current.id !== elementId.id
																	) {
																		elementId.data.css[
																			elementRef.current?.dataset?.name
																		] =
																			newClasses;
																	} else {
																		elementId.css = newClasses;
																	}
																	setClasses(
																		newClasses
																	);
																	AddToUndo(
																		selectedSiteData.static,
																		blocks,
																		sqliteSchema,
																		strapiSchema,
																		undo,
																		setUndo,
																		setRedo
																	);

																	const data =
																	{
																		IsAnimation: true,
																		Duration: parseInt(currentAnimation.Duration),
																		Delay: parseInt(currentAnimation.Delay),
																		animateOnce: currentAnimation.switchFirstTime,
																		animationIn: currentAnimation?.animationIn,
																		AnimateInfinite: currentAnimation.switchContinuously
																	};

																	await Bdispatch(
																		{
																			type: "CHANGE_ELEMENT_SCHEMA",
																			id: elementId.id,
																			element: data,
																		}
																	);

																	console.log("This is data", data)
																	closeModal();
																}}
															>
																Done
															</button>
														</div>
													</Dialog.Panel>
												</Transition.Child>
											</div>
										</div>
									</Dialog>
								</Transition>
							</div>
						)}

						<div
							className="my-4 flex  justify-between gap-2 items-center w-full rounded-md bg-white  text-center px-2.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer hover:text-indigo-600 "
							onClick={() =>
								setDropDownParallax(
									!dropDownParallax
								)
							}
						>
							<div className="">
								Add Parallax Effects
							</div>
							<div className="mt-2">
								{" "}
								<ChevronUpDownIcon className="h-5  inline-block" />
							</div>
						</div>

						{dropDownParallax === true && (
							<div className="w-72 text-center flex flex-wrap  border border-b-gray-200 overflow-x-hidden rounded-lg shadow bg-white h-80 overflow-y-scroll no-scrollbar">
								<button
									type="button"
									className={`relative group w-[40%]  border-b border-gray-200  hover:border-gray-800  flex flex-col items-center group mx-2 py-1 ${(elementId?.data?.parallaxIndex ?? -1) == -1 ? "bg-gray-200" : "bg-white"}`}
									onClick={async () => {

										await resetParallax();

										await Bdispatch(
											{
												type: "CHANGE_ELEMENT_SCHEMA",
												id: elementId.id,
												element: currentParallax,
											}
										);
									}}
								>
									<div
										className={`p-2 rounded-md overflow-hidden`}
									>
										<img
											src="/animation.jpeg"
											className={`w-10 h-10 `}
										/>
									</div>
									<div className="text-sm">
										None
									</div>
								</button>
								{ParallaxEffects.map(
									(effect, i) => {
										return (
											<button
												type="button"
												className={`relative group w-[40%]  border-b border-gray-200  hover:border-gray-800  flex flex-col items-center group mx-2 py-1 ${(elementId?.data?.parallaxIndex ?? -1) == i ? "bg-gray-200" : "bg-white"}`}
												onClick={async () => {

													await resetParallax();

													const data = {
														...currentParallax,
														...effect?.data
													}
													console.log("Onclickdata: ", data);
													await Bdispatch(
														{
															type: "CHANGE_ELEMENT_SCHEMA",
															id: elementId.id,
															element:
																data,
														}
													);

												}}
												onMouseEnter={(
													e
												) => {
													e.stopPropagation();
													setCurrentParallax(
														{
															...currentParallax,
															parallaxIndex: i,
														}
													);
												}}
											>
												<div
													className={`p-2 rounded-md overflow-hidden`}
												>
													{currentParallax.parallaxIndex ==
														i && (
															<IconDotsVertical
																className="absolute right-0 text-right align-right cursor-pointer"
																onClick={(
																	e
																) => {
																	e.stopPropagation();
																	setCurrentParallax(
																		{
																			parallaxIndex: elementId?.data?.parallaxIndex ?? -1,
																			isParallax: true,
																			isRotate: elementId?.data?.isRotate ?? false,
																			rotate: {
																				start: elementId?.data?.rotate?.start ?? 0,
																				end: elementId?.data?.rotate?.end ?? 360,
																				active: "start"
																			},
																			isRotateY: elementId?.data?.isRotateY ?? false,
																			rotateY: {
																				start: elementId?.data?.rotateY?.start ?? 0,
																				end: elementId?.data?.rotateY?.end ?? 0,
																				active: "start"
																			},
																			isRotateX: elementId?.data?.isRotateX ?? false,
																			rotateX: {
																				start: elementId?.data?.rotateX?.start ?? 0,
																				end: elementId?.data?.rotateX?.end ?? 0,
																				active: "start"
																			},
																			isRotateZ: elementId?.data?.isRotateZ ?? false,
																			rotateZ: {
																				start: elementId?.data?.rotateZ?.start ?? 0,
																				end: elementId?.data?.rotateZ?.end ?? 0,
																				active: "start"
																			},
																			isTranslateX: elementId?.data?.isTranslateX ?? false,
																			translateX: {
																				start: elementId?.data?.translateX?.start ?? 0,
																				end: elementId?.data?.translateX?.end ?? 0,
																				active: "start"
																			},
																			isTranslateY: elementId?.data?.isTranslateY ?? false,
																			translateY: {
																				start: elementId?.data?.translateY?.start ?? 0,
																				end: elementId?.data?.translateY?.end ?? 0,
																				active: "start"
																			},
																			isScale: elementId?.data?.isScale ?? false,
																			scale: {
																				start: elementId?.data?.scale?.start ?? 1,
																				end: elementId?.data?.scale?.end ?? 1,
																				active: "start"
																			},
																			isScaleX: elementId?.data?.isScaleX ?? false,
																			scaleX: {
																				start: elementId?.data?.scaleX?.start ?? 1,
																				end: elementId?.data?.scaleX?.end ?? 1,
																				active: "start"
																			},
																			isScaleY: elementId?.data?.isScaleY ?? false,
																			scaleY: {
																				start: elementId?.data?.scaleY?.start ?? 1,
																				end: elementId?.data?.scaleY?.end ?? 1,
																				active: "start"
																			},
																			isScaleZ: elementId?.data?.isScaleZ ?? false,
																			scaleZ: {
																				start: elementId?.data?.scaleZ?.start ?? 1,
																				end: elementId?.data?.scaleZ?.end ?? 1,
																				active: "start"
																			},
																			isOpacity: elementId?.data?.isOpacity ?? false,
																			opacity: {
																				start: elementId?.data?.opacity?.start ?? 1,
																				end: elementId?.data?.opacity?.end ?? 1,
																				active: "start"
																			},
																			isChangeTime: elementId?.data?.isChangeTime ?? false,
																			ChangeTime: {
																				start: elementId?.data?.ChangeTime?.start ?? 0,
																				end: elementId?.data?.ChangeTime?.end ?? 0
																			},
																			isSpeed: elementId?.data?.isSpeed ?? false,
																			speed: elementId?.data?.speed ?? 0,
																			easing: 0,
																			isParallaxBanner: elementId?.data?.isParallaxBanner ?? false,
																			applyEffects: (elementId?.data && Object.keys(elementId.data).includes('applyEffects')) ? elementId?.data?.applyEffects : true

																		}
																	)
																	setActiveTab('demo')
																	setIsOpenParallaxModal(!isOpenParallaxModal)
																	console.log(
																		elementId,
																		"THIS IS ELEMENT ID"
																	);

																}}
															/>
														)}
													<img
														src="/animation.jpeg"
														className={`w-10 h-10`}
													/>
												</div>
												<div className="text-sm">
													{effect.name}
												</div>
											</button>
										);
									}
								)}

								<Transition
									appear
									show={isOpenParallaxModal}
									as={Fragment}
								>
									<Dialog
										as="div"
										className="relative z-50"
										onClose={closeModal}
									>
										<Transition.Child
											as={Fragment}
											enter="ease-out duration-300"
											enterFrom="opacity-0"
											enterTo="opacity-100"
											leave="ease-in duration-200"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
										>
											<div className="fixed inset-0 bg-black bg-opacity-60" />
										</Transition.Child>

										<div className="fixed inset-0 overflow-y-auto">
											<div className="flex min-h-full items-center justify-center p-4 text-center">
												<Transition.Child
													as={Fragment}
													enter="ease-out duration-300"
													enterFrom="opacity-0 scale-95"
													enterTo="opacity-100 scale-100"
													leave="ease-in duration-200"
													leaveFrom="opacity-100 scale-100"
													leaveTo="opacity-0 scale-95"
												>
													<Dialog.Panel className="w-full lg:h-[700px] md:h-[600px] h-96 overflow-hidden transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all relative">


														<Dialog.Title
															as="div"
															className="text-lg font-medium leading-6 text-gray-900 p-2 w-full px-4 flex items-center justify-between shadow-md my-1"
														>
															<div>
																<h1 className="font-medium">Parallax Settings</h1>
																<div className="my-2 flex transition-all">
																	<button type="button" className={`py-1 px-2 rounded-l-md  ${activeTab === 'demo' ? "bg-teal-500 text-white" : "text-black bg-gray-200"}`} onClick={() => setActiveTab("demo")}>Demo</button>
																	<button type="button" className={`py-1 px-2 rounded-r-md  ${activeTab === 'preview' ? "bg-teal-500 text-white" : "text-black bg-gray-200"}`} onClick={() => setActiveTab("preview")}>Preview</button>
																</div>

															</div>
															<XMarkIcon className="text-sm h-10 w-10 cursor-pointer hover:bg-rose-600 rounded-md hover:text-white transition-all" onClick={closeModal} />



														</Dialog.Title>

														<div className="grid grid-cols-2 w-full h-full gap-2">
															<div className="overflow-hidden w-full lg:h-[603px] md:h-[550px] h-96 mb-20 m-3 border-2 bg-gray-200">
																{
																	activeTab === 'demo' && (
																		<div
																			className="w-full h-full flex items-center justify-center text-3xl overflow-hidden overflow-x-hidden"
																			style={{
																				transform: `
            ${currentParallax?.isRotate ? `rotate(${currentParallax.rotate[currentParallax.rotate.active]}deg)` : ''}
            ${currentParallax?.isRotateX ? `rotateX(${currentParallax.rotateX[currentParallax.rotateX.active]}deg)` : ''}
            ${currentParallax?.isRotateY ? `rotateY(${currentParallax.rotateY[currentParallax.rotateY.active]}deg)` : ''}
            ${currentParallax?.isRotateZ ? `rotateZ(${currentParallax.rotateZ[currentParallax.rotateZ.active]}deg)` : ''}
            ${currentParallax?.isScale ? `scale(${currentParallax.scale[currentParallax.scale.active]})` : ''}
            ${currentParallax?.isScaleX ? `scaleX(${currentParallax.scaleX[currentParallax.scaleX.active]})` : ''}
            ${currentParallax?.isScaleY ? `scaleY(${currentParallax.scaleY[currentParallax.scaleY.active]})` : ''}
            ${currentParallax?.isScaleZ ? `scaleZ(${currentParallax.scaleZ[currentParallax.scaleZ.active]})` : ''}
            ${currentParallax?.isTranslateX ? `translateX(${currentParallax.translateX[currentParallax.translateX.active]}%)` : ''}
            ${currentParallax?.isTranslateY ? `translateY(${currentParallax.translateY[currentParallax.translateY.active]}%)` : ''}
            ${currentParallax?.isSpeed ? `translateY(${currentParallax.speed}%)` : ''}
          `.trim(),
																				opacity: currentParallax?.isOpacity ? currentParallax.opacity[currentParallax.opacity.active] : 1,
																				transition: 'transform 0.5s ease',
																			}}
																		>
																			Hello World!

																		</div>
																	)
																}
																{
																	activeTab === 'preview' && (
																		<iframe
																			key={reloadIFrame}
																			src={`${process.env.REACT_APP_REDIRECT_SOCIAL_URL}/user/preview/${params.siteId}/${pageName}/${currentPlatform} `} className="w-full h-full" />
																	)
																}
															</div>

															<div className="overflow-y-auto p-6 mb-20">
																{/* harsh */}
																<div className="mt-6 flex flex-col justify-between h-full w-full ">
																	{/* Start -- Visibility */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Visibility
																		</p>

																		<Switch
																			checked={
																				currentParallax?.isOpacity
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isOpacity: !currentParallax?.isOpacity
																					}
																				);
																			}}
																			className={`${currentParallax?.isOpacity
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isOpacity
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isOpacity && (


																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className=" flex items-center gap-1 text-xl text-gray-700">
																					Start

																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="0"
																						max="1"
																						step="0.1"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.opacity?.start

																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									opacity:
																									{
																										...currentParallax?.opacity,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.opacity?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									opacity:
																									{
																										...currentParallax?.opacity,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0  focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="flex items-center gap-1 text-xl text-gray-700">
																					End

																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.opacity?.end
																						}
																						min="0"
																						max="1"
																						step="0.1"
																						className="w-1/2 h-[2px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									opacity:
																									{
																										...currentParallax?.opacity,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.opacity?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									opacity:
																									{
																										...currentParallax?.opacity,
																										end: e.target.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0  focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}

																	{/* Start - Speed */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Speed
																		</p>

																		<Switch
																			checked={
																				currentParallax?.isSpeed
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isSpeed: !currentParallax?.isSpeed
																					}
																				);
																			}}
																			className={`${currentParallax?.isSpeed
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isSpeed
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isSpeed && (


																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className=" flex items-center gap-1 text-xl text-gray-700">
																					Set Speed  <span className="text-sm">(in %)</span>
																				</p>

																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-100"
																						max="100"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.speed

																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									speed: e.target.value
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.speed
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									speed: e.target.value
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}

																	{/* Start - Easing */}
																	<div className=" my-3">
																		<p className=" text-left text-xl text-gray-700 my-2">Easing</p>
																		<Tab.Group manual as={Fragment}>
																			<div className="relative">
																				<Menu>
																					<Menu.Button className="w-full p-1 mt-1 block shadow-md rounded-lg ring-gray-300 bg-white focus:ring-2 focus:ring-inset focus:ring-indigo-500 outline-none">
																						<div className="w-full flex justify-between items-center rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 sm:leading-6">
																							<div className="font-medium">
																								{AllParllaxEasings[currentParallax?.easing ? currentParallax?.easing : 0]}{" "}
																							</div>
																							<ChevronDownIcon className="h-6 w-6 font-semibold" />
																						</div>
																					</Menu.Button>
																					<Menu.Items className="bg-white h-40 overflow-auto border-x border-b border-gray-300 rounded-lg p-1 w-full absolute z-40">
																						<Tab.List>
																							{AllParllaxEasings?.map((value, index) => (
																								<Menu.Item
																									key={`menu${index}`}
																									className="block py-2.5 px-3"
																								>
																									<Tab
																										className={`${currentParallax?.easing === index
																											? "text-indigo-600"
																											: "text-gray-500  hover:text-gray-700"
																											} whitespace-nowrap text-base font-medium block w-full text-left`}
																										onClick={(e) => {
																											e.preventDefault();
																											setCurrentParallax({ ...currentParallax, easing: index })
																										}}
																									>
																										{value}
																									</Tab>
																								</Menu.Item>
																							))}
																						</Tab.List>
																					</Menu.Items>
																				</Menu>
																			</div>
																		</Tab.Group>

																	</div>


																	{/* Start - Rotate */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Rotate
																		</p>
																		<Switch
																			checked={
																				currentParallax?.isRotate
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isRotate:
																							!currentParallax?.isRotate,
																					}
																				);
																			}}
																			className={`${currentParallax?.isRotate
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isRotate
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isRotate && (
																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="text-xl text-gray-700 flex items-center gap-1">
																					<p>Start</p>
																					<p className="text-xs">
																						(in
																						deg)
																					</p>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-360"
																						max="360"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.rotate?.start
																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotate: {
																										...currentParallax?.rotate,
																										start: e
																											.target
																											.value,
																										active: "start"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.rotate?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotate: {
																										...currentParallax?.rotate,
																										start: e
																											.target
																											.value,
																										active: "start"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="text-xl text-gray-700 flex items-center gap-1">
																					<p>End</p>
																					<p className="text-xs">
																						(in
																						deg)
																					</p>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.rotate?.end
																						}
																						min="0"
																						max="360"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotate: {
																										...currentParallax?.rotate,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.rotate?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotate: {
																										...currentParallax?.rotate,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>

																	)}



																	{/* Start - RotateX */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			RotateX
																		</p>

																		<Switch
																			checked={
																				currentParallax?.isRotateX
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isRotateX: !currentParallax?.isRotateX,

																					}
																				);
																			}}
																			className={`${currentParallax?.isRotateX
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isRotateX
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isRotateX && (
																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="text-xl text-gray-700 flex items-center gap-1">
																					<p>Start</p>
																					<p className="text-xs">
																						(in
																						deg)
																					</p>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-360"
																						max="360"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.rotateX?.start

																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateX:
																									{
																										...currentParallax?.rotateX,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.rotateX?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateX:
																									{
																										...currentParallax?.rotateX,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="text-xl text-gray-700 flex items-center gap-1">
																					<p>End</p>
																					<p className="text-xs">
																						(in
																						deg)
																					</p>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.rotateX?.end
																						}
																						min="0"
																						max="360"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateX:
																									{
																										...currentParallax?.rotateX,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.rotateX?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateX:
																									{
																										...currentParallax?.rotateX,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}

																	{/* Start -  RotateY */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			RotateY
																		</p>

																		<Switch
																			checked={
																				currentParallax?.isRotateY
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isRotateY: !currentParallax?.isRotateY,

																					}
																				);
																			}}
																			className={`${currentParallax?.isRotateY
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isRotateY
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isRotateY && (
																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="text-xl text-gray-700 flex items-center gap-1">
																					<p>Start</p>
																					<p className="text-xs">
																						(in
																						deg)
																					</p>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-360"
																						max="360"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.rotateY?.start

																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateY:
																									{
																										...currentParallax?.rotateY,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.rotateY?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateY:
																									{
																										...currentParallax?.rotateY,
																										start: e.target.value,
																										ative: "start"

																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="text-xl text-gray-700 flex items-center gap-1">
																					<p>End</p>
																					<p className="text-xs">
																						(in
																						deg)
																					</p>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.rotateY?.end
																						}
																						min="0"
																						max="360"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateY:
																									{
																										...currentParallax?.rotateY,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.rotateY?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateY:
																									{
																										...currentParallax?.rotateY,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}

																	{/* Start - RotateZ */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			RotateZ
																		</p>
																		<Switch
																			checked={
																				currentParallax?.isRotateZ
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isRotateZ:
																							!currentParallax?.isRotateZ,
																					}
																				);
																			}}
																			className={`${currentParallax?.isRotateZ
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isRotateZ
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isRotateZ && (
																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="text-xl text-gray-700 flex items-center gap-1">
																					<p>Start</p>
																					<p className="text-xs">
																						(in
																						deg)
																					</p>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-360"
																						max="360"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.rotate?.start
																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateZ: {
																										...currentParallax?.rotateZ,
																										start: e
																											.target
																											.value,
																										active: "start"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.rotateZ?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateZ: {
																										...currentParallax?.rotateZ,
																										start: e
																											.target
																											.value,
																										active: "start"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="text-xl text-gray-700 flex items-center gap-1">
																					<p>End</p>
																					<p className="text-xs">
																						(in
																						deg)
																					</p>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.rotateZ?.end
																						}
																						min="0"
																						max="360"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateZ: {
																										...currentParallax?.rotateZ,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.rotateZ?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									rotateZ: {
																										...currentParallax?.rotateZ,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>

																	)}

																	{/* Start - Scale */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Zoom
																		</p>

																		<Switch
																			checked={
																				currentParallax?.isScale
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isScale: !currentParallax?.isScale,
																						isScaleX: false,
																						isScaleY: false

																					}
																				);
																			}}
																			className={`${currentParallax?.isScale
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isScale
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isScale && (


																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className=" flex items-center gap-1 text-xl text-gray-700">
																					Start
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-2"
																						max="5"
																						step="0.1"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.scale?.start

																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scale:
																									{
																										...currentParallax?.scale,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.scale?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scale:
																									{
																										...currentParallax?.scale,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="flex items-center gap-1 text-xl text-gray-700">
																					End
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.scale?.end
																						}
																						min="-2"
																						max="5"

																						step="0.1"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scale:
																									{
																										...currentParallax?.scale,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.scale?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scale:
																									{
																										...currentParallax?.scale,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}

																	{/* Start - ScaleX */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Zoom-X
																		</p>

																		<Switch
																			checked={
																				currentParallax?.isScaleX
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isScaleX: !currentParallax?.isScaleX,
																						isScale: false

																					}
																				);
																			}}
																			className={`${currentParallax?.isScaleX
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax.isScaleX
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isScaleX && (
																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className=" flex items-center gap-1 text-xl text-gray-700">
																					Start
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-1"
																						max="3"
																						step="0.1"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.scaleX?.start

																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleX:
																									{
																										...currentParallax?.scaleX,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.scaleX?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleX:
																									{
																										...currentParallax?.scaleX,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="flex items-center gap-1 text-xl text-gray-700">
																					End
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.scaleX?.end
																						}
																						min="-1"
																						max="3"
																						step="0.1"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleX:
																									{
																										...currentParallax?.scaleX,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.scaleX?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleX:
																									{
																										...currentParallax?.scaleX,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}

																	{/* Start - ScaleY */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Zoom-Y
																		</p>

																		<Switch
																			checked={
																				currentParallax?.isScaleY
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isScaleY: !currentParallax?.isScaleY,
																						isScale: false

																					}
																				);
																			}}
																			className={`${currentParallax?.isScaleY
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isScaleY
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isScaleY && (
																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className=" flex items-center gap-1 text-xl text-gray-700">
																					Start
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-1"
																						max="3"
																						step="0.1"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.scaleY?.start

																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleY:
																									{
																										...currentParallax?.scaleY,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.scaleY?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleY:
																									{
																										...currentParallax?.scaleY,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="flex items-center gap-1 text-xl text-gray-700">
																					End
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.scaleY?.end
																						}
																						min="-1"
																						max="3"
																						step="0.1"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleY:
																									{
																										...currentParallax?.scaleY,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.scaleY?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleY:
																									{
																										...currentParallax?.scaleY,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}

																	{/* Start - ScaleZ */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Zoom-Z
																		</p>

																		<Switch
																			checked={
																				currentParallax?.isScaleZ
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isScaleZ: !currentParallax?.isScaleZ,
																						isScale: false

																					}
																				);
																			}}
																			className={`${currentParallax?.isScaleZ
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isScaleZ
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isScaleZ && (
																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className=" flex items-center gap-1 text-xl text-gray-700">
																					Start
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-1"
																						max="3"
																						step="0.1"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.scaleZ?.start

																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleZ:
																									{
																										...currentParallax?.scaleZ,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.scaleZ?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleZ:
																									{
																										...currentParallax?.scaleZ,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="flex items-center gap-1 text-xl text-gray-700">
																					End
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.scaleZ?.end
																						}
																						min="-1"
																						max="3"
																						step="0.1"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleZ:
																									{
																										...currentParallax?.scaleZ,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.scaleZ?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									scaleZ:
																									{
																										...currentParallax?.scaleZ,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}

																	{/* Start - TranslateX */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Slide-X
																		</p>

																		<Switch
																			checked={
																				currentParallax?.isTranslateX
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isTranslateX: !currentParallax?.isTranslateX
																					}
																				);
																			}}
																			className={`${currentParallax?.isTranslateX
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isTranslateX
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax?.isTranslateX && (


																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className=" flex items-center gap-1 text-xl text-gray-700">
																					<span>Start</span>
																					<span className="text-xs">
																						(in %)
																					</span>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-300"
																						max="300"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.translateX?.start

																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									translateX:
																									{
																										...currentParallax?.translateX,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.translateX?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									translateX:
																									{
																										...currentParallax?.translateX,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className=" flex items-center gap-1 text-xl text-gray-700">
																					<span>End</span>
																					<span className="text-xs">
																						(in %)
																					</span>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.translateX?.end
																						}
																						min="-300"
																						max="300"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									translateX:
																									{
																										...currentParallax?.translateX,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.translateX?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									translateX:
																									{
																										...currentParallax?.translateX,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}

																	{/* Start - TranslateY */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Slide-Y
																		</p>

																		<Switch
																			checked={
																				currentParallax?.isTranslateY
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isTranslateY: !currentParallax?.isTranslateY
																					}
																				);
																			}}
																			className={`${currentParallax?.isTranslateY
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isTranslateY
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{currentParallax.isTranslateY && (
																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className=" flex items-center gap-1 text-xl text-gray-700">
																					<span>Start</span>
																					<span className="text-xs">
																						(in %)
																					</span>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="-300"
																						max="300"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.translateY?.start

																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									translateY:
																									{
																										...currentParallax?.translateY,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.translateY?.start
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									translateY:
																									{
																										...currentParallax?.translateY,
																										start: e.target.value,
																										active: "start"

																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className=" flex items-center gap-1 text-xl text-gray-700">
																					<span>End</span>
																					<span className="text-xs">
																						(in %)
																					</span>
																				</p>
																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.translateY?.end
																						}
																						min="-300"
																						max="300"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									translateY:
																									{
																										...currentParallax?.translateY,
																										end: e.target.value,
																										active: "end"
																									}
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.translateY?.end
																						}
																						onChange={(e) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									translateY:
																									{
																										...currentParallax?.translateY,
																										end: e
																											.target
																											.value,
																										active: "end"
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}
																	{/* Start - Start Animation */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Change Parallax Time

																		</p>

																		<Switch
																			checked={
																				currentParallax?.isChangeTime
																			}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						isChangeTime: !currentParallax?.isChangeTime,
																						isParallaxBanner: false
																					}
																				);
																			}}
																			className={`${currentParallax?.isChangeTime
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                        relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.isChangeTime
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>
																	<hr />
																	{(currentParallax.isChangeTime) && (
																		<div className="flex flex-col">
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="text-xl text-gray-700">
																					Start Scroll
																				</p>

																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						min="0"
																						max="400"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						value={
																							currentParallax?.ChangeTime?.start
																						}
																						onChange={(
																							e
																						) => {
																							setCurrentParallax(
																								{
																									...currentParallax,
																									ChangeTime:
																									{
																										...currentParallax?.ChangeTime,
																										start: e.target.value
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.ChangeTime?.start
																						}
																						onChange={(e) => {

																							let value = e.target.value;
																							setCurrentParallax(
																								{
																									...currentParallax,
																									ChangeTime:
																									{
																										...currentParallax?.ChangeTime,
																										start: value
																									},
																								}
																							);

																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																				{
																					currentParallax?.ChangeTime?.start > currentParallax?.ChangeTime?.end && (
																						<span className="text-red-500 text-xs ml-3 mt-1 transition-all delay-1">* Keep End Scroll greater than Start Scroll for parallax to work</span>
																					)
																				}
																			</div>
																			<hr />
																			<div className="flex flex-col items-start justify-between my-4">
																				<p className="text-xl text-gray-700">
																					End Scroll
																				</p>

																				<div className="flex items-center w-full gap-3 ml-3">
																					<input
																						type="range"
																						value={
																							currentParallax?.ChangeTime?.end
																						}
																						min="0"
																						max="800"
																						className="w-1/2 h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm"
																						onChange={(
																							e
																						) => {

																							setCurrentParallax(
																								{
																									...currentParallax,
																									ChangeTime:
																									{
																										...currentParallax?.ChangeTime,
																										end: e.target.value
																									},
																								}
																							);
																						}}
																					/>
																					<input
																						type="number"
																						value={
																							currentParallax?.ChangeTime?.end
																						}
																						onChange={(e) => {

																							setCurrentParallax(
																								{
																									...currentParallax,
																									ChangeTime:
																									{
																										...currentParallax?.ChangeTime,
																										end: e.target.value
																									},
																								}
																							);
																						}}
																						className="w-[60px] px-2 py-1 border border-gray-300 rounded focus:ring-0 focus:border-[#D9D9D9]"
																					/>
																				</div>
																			</div>
																			<hr />
																		</div>
																	)}


																	{/* Start - Apply on Images */}
																	{(elementId?.type === 'img' || elementId?.data?.backgroundImage) && (
																		<>
																			<div className="flex items-center justify-between my-4 w-full">
																				<p className="text-xl text-gray-700">
																					Apply Effects on Image
																				</p>

																				<Switch
																					checked={
																						currentParallax?.isParallaxBanner
																					}
																					onChange={() => {
																						setCurrentParallax(
																							{
																								...currentParallax,
																								isParallaxBanner: !currentParallax?.isParallaxBanner,
																								isChangeTime: false
																							}
																						);
																					}}
																					className={`${currentParallax?.isParallaxBanner
																						? "bg-teal-900"
																						: "bg-teal-700"
																						}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																				>
																					<span className="sr-only">
																						Use
																						setting
																					</span>
																					<span
																						aria-hidden="true"
																						className={`${currentParallax?.isParallaxBanner
																							? "translate-x-9"
																							: "translate-x-0"
																							}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																					/>
																				</Switch>

																			</div>
																			<hr />
																		</>
																	)}

																	{/* Start - Apply Effects */}
																	<div className="flex items-center justify-between my-4">
																		<p className="text-xl text-gray-700">
																			Apply Effects
																		</p>

																		<Switch
																			checked={currentParallax?.applyEffects}
																			onChange={() => {
																				setCurrentParallax(
																					{
																						...currentParallax,
																						applyEffects: !currentParallax?.applyEffects
																					}
																				);
																			}}
																			className={`${currentParallax?.applyEffects
																				? "bg-teal-900"
																				: "bg-teal-700"
																				}
                                relative inline-flex h-[23px] w-[59px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																		>
																			<span className="sr-only">
																				Use
																				setting
																			</span>
																			<span
																				aria-hidden="true"
																				className={`${currentParallax?.applyEffects
																					? "translate-x-9"
																					: "translate-x-0"
																					}
                                        pointer-events-none inline-block h-[19px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																			/>
																		</Switch>
																	</div>

																	<div className="flex items-center gap-2 justify-start py-3 h-max sticky -bottom-2 bg-white z-50 ">
																		<button
																			type="button"
																			className="rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
																			onClick={() => {
																				closeModal();
																			}}
																		>
																			Cancel
																		</button>

																		<button
																			type="button"
																			className={`rounded-md border border-transparent text-blue-900 bg-blue-200 px-4 py-2 text-sm font-medium  hover:bg-blue-800 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
																			onClick={async () => {
																				await SaveParallax();
																				setActiveTab('preview')
																			}}

																		>
																			Save & Preview
																		</button>

																		<button
																			type="button"
																			className="rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-800 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
																			onClick={async () => {
																				await SaveParallax();
																				closeModal();
																			}}
																		>
																			Save & Close
																		</button>
																	</div>

																</div>

															</div>
														</div>
													</Dialog.Panel>
												</Transition.Child>
											</div>
										</div>
									</Dialog>
								</Transition>
							</div>
						)}
					</div>
				</div>
			)}
		</>




	)
}

export default Animation_Parallax