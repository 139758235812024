import { Tab } from "@headlessui/react";
import { BlockWrapper } from "../Sortable";
import { createElement } from "react";
import { useContext } from "react";
import { context } from "../../../../context/Context";
import { useLocation, useParams } from "react-router-dom";
import { generateCss } from "../functions/sortableFunctions";
const TabGroup = ({ block, ...props }) => {
	const { componentWithSettingsRef, setComponent } = useContext(context);
	const handleComponentWithSettingsClick = () => {
		const component = document.getElementById(block.id);
		if (componentWithSettingsRef.current != component) {
			setComponent(block);
			if (componentWithSettingsRef.current) {
				componentWithSettingsRef.current.style.outline = 'none';
			}
			componentWithSettingsRef.current = component;
			componentWithSettingsRef.current.style.outline = '2px dashed #4F46E5';
		}
	}
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (
		createElement(
			Tab.Group,
			{
				style: {
					fontFamily: block?.style?.fontFamily
				},
				className: generateCss({
					contextVariables: contextVariables,
					props: {
						...props,
						block: block
					},
					params: params,
					location: location
				}),
				id: block.id,
				...block.data,
				as: block.data?.type ? block.data.type : 'div',
				onMouseOver: (e) => props.handleMouseOver(e),
				onClick: (e) => {
					props.handleClick(e, block.id, false, -1, true);
					handleComponentWithSettingsClick();
				}
			},
			block.children.map((value, index) => {
				return <BlockWrapper
					{...props}
					key={index}
					block={value}
					blockIndex={[...props.blockIndex, index]}
					customComponentChildren={true}
					handleComponentWithSettingsClick={handleComponentWithSettingsClick}
				/>
			})
		)
	)
}
export default TabGroup;