import { Popover } from '@headlessui/react';
import { IconBucketDroplet } from '@tabler/icons-react';
import { useRef } from 'react'
import { SketchPicker } from 'react-color'


const presetColors = [
	'#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE',
	'#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'
];


const ColorPicker2 = ({ elementBreakpointSpecificStyleObject, setElementBreakpointSpecificStyleObject, updateDesign, field, customIcon }) => {
	const timerref = useRef();

	return (
		<>

			<Popover className="relative">
				<Popover.Button
					className={`flex items-center${ !customIcon ? " bg-[#ffffff] rounded-[2px] cursor-pointer shadow border border-gray-300" : "outline-none border-none focus:outline-none"}`}
				>
					{
						customIcon ?
							<span class={`${customIcon}`}></span>
							// <IconBucketDroplet className='h-6 w-6' stroke="2" />
							:
							<div
								className={`w-[15px] h-[15px] rounded-[3px] bg-[${elementBreakpointSpecificStyleObject[field]}]`}
							/>
					}
				</Popover.Button>

				<Popover.Panel
					className={'absolute z-[51] top-8 left-0'}
				>
					<SketchPicker
						color={elementBreakpointSpecificStyleObject[field]}
						onChange={(color) => {
							const value = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
							setElementBreakpointSpecificStyleObject((prev) => {

								return {
									...prev,
									[field]: value
								}
							});
							clearTimeout(timerref.current);
							timerref.current = setTimeout(() => {
								updateDesign(field, value);
							}, 1000);
						}}
						presetColors={presetColors}
					/>
				</Popover.Panel>
			</Popover>
		</>
	)
}

export default ColorPicker2;