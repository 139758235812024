import React, { Fragment, useEffect, useState } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { dbCall } from "../../../../common/db";
import { Menu, Tab } from "@headlessui/react";
import { Form, Formik, Field } from "formik";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";

const MailchimpUtil = () => {
  const { type, id, siteId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  // In case of create the campaign we will not be having ListId, TemplateId
  const ListId = queryParams.get("listId") ?? "";
  const TemplateId = queryParams.get("templateId") ?? "";

  let paramsType = type.split("-");
  // Here based on PageType we are differentiate the Page Contents from Update or Create campaign
  const [PageType, setPageType] = useState(paramsType[0]);
  const [Title, setTitle] = useState(paramsType[1]);
  const [CampaignData, setCampiagnData] = useState(undefined);

  const [selectedlistId, setSelectedListId] = useState(ListId ?? "");
  const [selectedListName, setSelectedListName] = useState("");
  const [allLists, setAllLists] = useState([]);

  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    TemplateId ?? ""
  );
  const [selectedTemplateName, setSelectedTemplateName] = useState("");

  // Format Date
  function formatDate(dateString) {
    // Parse the date string
    const date = new Date(dateString);

    // Extract the components
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits (getMonth is 0-indexed)
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      const res = await dbCall.post(`/mailchimp/delete-campaign/${id}`, {
        apiKey: localStorage.getItem("mailchimp_token"),
        server: localStorage.getItem("mailchimp_server"),
        provider: "MarketMailChimp",
      });
      toast.success("Campaign Deleted");
      navigate(`/site/admin/${siteId}/mailchimp`);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const FetchData = async () => {
      try {
        if (PageType === "Campaigns") {
          const { data } = await dbCall.post(
            `/mailchimp/fetch-campaigns/${id}`,
            {
              apiKey: localStorage.getItem("mailchimp_token"),
              server: localStorage.getItem("mailchimp_server"),
              provider: "MarketMailChimp",
            }
          );

          setCampiagnData(data);
        }

        if (PageType === "Campaigns" || PageType === "Create Campaign") {
          const res = await dbCall.post("/mailchimp/fetch-templates", {
            apiKey: localStorage.getItem("mailchimp_token"),
            server: localStorage.getItem("mailchimp_server"),
            provider: "MarketMailChimp",
          });
          setAllTemplates([...res.data.templates]);

          // These loops will set the default templates in template Menu as we sent their id in Query in URL
          if (PageType == "Campaigns") {
            for (let i = 0; i < res.data.templates.length; i++) {
              if (res.data.templates[i].id == TemplateId) {
                setSelectedTemplateName(res.data.templates[i]?.name);
                break;
              }
            }
            // In case of create Campaign we didin't send the TemplateId, ListId so we just set the first result in default
          } else if (PageType == "Create Campaign") {
            setSelectedTemplateName(res.data?.templates[0]?.name);
            setSelectedTemplateId(res.data?.templates[0]?.id);
          }

          const listData = await dbCall.post("/mailchimp/fetch-lists", {
            apiKey: localStorage.getItem("mailchimp_token"),
            server: localStorage.getItem("mailchimp_server"),
            provider: "MarketMailChimp",
          });
          setAllLists([...listData.data.lists]);

          // Same reason as above for templates
          if (PageType == "Campaigns") {
            for (let i = 0; i < listData?.data?.lists.length; i++) {
              if (listData?.data?.lists[i].id == ListId) {
                setSelectedListName(listData?.data?.lists[i]?.name);
                break;
              }
            }
          } else if (PageType == "Create Campaign") {
            setSelectedListName(listData?.data?.lists[0]?.name);
            setSelectedListId(listData?.data?.lists[0]?.id);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [PageType]);

  const Update_Create_Details = async (values, setSubmitting) => {
    console.log("Submitted Data is: ", values);
    try {
      setSubmitting(true);
      if (PageType == "Campaigns") {
        const { data } = await dbCall.patch(
          `/mailchimp/update-campaign/${id}`,
          {
            apiKey: localStorage.getItem("mailchimp_token"),
            server: localStorage.getItem("mailchimp_server"),
            provider: "MarketMailChimp",
            ...values,
          }
        );

        console.log("Updated Data: ", data);
        toast.success("Campaign Updated");
        navigate(`/site/admin/${siteId}/mailchimp`);
      } else if (PageType == "Create Campaign") {
        toast.success("Campaign Created");
        const { data } = await dbCall.post("/mailchimp/create-campaign", {
          apiKey: localStorage.getItem("mailchimp_token"),
          server: localStorage.getItem("mailchimp_server"),
          provider: "MarketMailChimp",
          ...values,
        });
        console.log("Created Data: ", data);
      }
      setSubmitting(false);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  return (
    <div className="outline-none p-6 min-h-screen bg-gray-100">
      {(PageType === "Campaigns" || PageType === "Create Campaign") && (
        <>
          {PageType === "Create Campaign" ? (
            ""
          ) : (
            <div className="shadow bg-white px-4 py-5 mb-3 sm:px-6 rounded-lg flex items-center justify-between gap-2">
              <h1 className="">
                Title: <span className="font-semibold">{Title}</span>
              </h1>
              <div className="">
                <p className="mb-1 bg-gray-200 rounded-md px-2 w-max ml-auto">
                  {CampaignData?.status}
                </p>

                <p className="text-sm w-max ml-auto">
                  {formatDate(CampaignData?.create_time)}
                </p>
              </div>
            </div>
          )}
          <div className="shadow bg-white px-4 py-5 mb-3 sm:px-6 rounded-lg">
            <h3 className="font-medium text-base mb-3 text-center">
              {PageType === "Create Campaign"
                ? "Create new Campaign"
                : "Update Campaign Details"}
            </h3>

            <Formik
              enableReinitialize
              initialValues={{
                list_id: selectedlistId ?? "",
                subject_line: CampaignData?.settings?.subject_line ?? "",
                preview_text: CampaignData?.settings?.preview_text ?? "",
                title: CampaignData?.settings?.title ?? "",
                from_name: CampaignData?.settings?.from_name ?? "",
                reply_to: CampaignData?.settings?.reply_to ?? "",
                // to_name: CampaignData?.settings?.to_name,
                template_id: selectedTemplateId ?? "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.subject_line) {
                  errors.from = "Required";
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                await Update_Create_Details(values, setSubmitting);
              }}
            >
              {(formik) => (
                <Form className="sm:pb-0">
                  <div className="grid sm:grid-cols-2 gap-2 sm:py-2 mb-3">
                    <div className="">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Campaign Title..."
                        className="outline-none block border border-gray-300 focus:border-2 focus:border-indigo-600 w-full rounded-lg  p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.title}
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="subject_line"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                      >
                        Subject
                      </label>
                      <input
                        type="text"
                        name="subject_line"
                        id="subject_line"
                        placeholder="Campaign Subject"
                        className="outline-none block border border-gray-300 focus:border-2 focus:border-indigo-600 w-full rounded-lg  p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.subject_line}
                      />
                    </div>
                  </div>

                  <div className="grid sm:grid-cols-2 gap-2 sm:py-2 mb-3">
                    <div className="">
                      <label
                        htmlFor="from_name"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                      >
                        From Name
                      </label>
                      <input
                        type="text"
                        name="from_name"
                        id="from_name"
                        placeholder="From name campaign"
                        className="outline-none block border border-gray-300 focus:border-2 focus:border-indigo-600 w-full rounded-lg  p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.from_name}
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="subject_line"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                      >
                        Preview Text
                      </label>
                      <input
                        type="text"
                        name="preview_text"
                        id="preview_text"
                        placeholder="Campaign Preview text"
                        className="outline-none block border border-gray-300 focus:border-2 focus:border-indigo-600 w-full rounded-lg  p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.preview_text}
                      />
                    </div>
                  </div>

                  <div className="grid sm:grid-cols-2 gap-2 sm:py-2 mb-3">
                    <div className="">
                      <label
                        htmlFor="reply_to"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                      >
                        Email Recieves Reply
                      </label>
                      <input
                        type="email"
                        name="reply_to"
                        id="reply_to"
                        placeholder="Recieve"
                        className="outline-none block border border-gray-300 focus:border-2 focus:border-indigo-600 w-full rounded-lg  p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.reply_to}
                      />
                    </div>
                    <Tab.Group manual as={Fragment}>
                      <div className="relative mt-6">
                        <Menu>
                          <Menu.Button className="w-full p-1 mt-1 block shadow rounded-lg ring-gray-300 bg-white focus:ring-2 focus:ring-inset focus:ring-indigo-500 outline-none">
                            <div className="w-full flex justify-between items-center rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 sm:text-sm sm:leading-6">
                              <div className="font-medium">
                                Template: {selectedTemplateName}{" "}
                              </div>
                              <ChevronDownIcon className="h-6 w-6 font-semibold" />
                            </div>
                          </Menu.Button>
                          <Menu.Items className="bg-white h-32 overflow-auto border-x border-b border-gray-300 rounded-lg p-1 w-full absolute z-40">
                            <Tab.List>
                              {allTemplates?.map((template, index) => (
                                <Menu.Item
                                  key={`menu${template?.id}`}
                                  className="block py-2.5 px-3"
                                >
                                  <Tab
                                    className={`${
                                      selectedTemplateId == template?.id
                                        ? "text-indigo-600"
                                        : "text-gray-500  hover:text-gray-700"
                                    } whitespace-nowrap text-sm font-medium block w-full text-left`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedTemplateId(template?.id);
                                      setSelectedTemplateName(template?.name);
                                    }}
                                  >
                                    {template?.name}
                                  </Tab>
                                </Menu.Item>
                              ))}
                            </Tab.List>
                          </Menu.Items>
                        </Menu>
                      </div>
                    </Tab.Group>
                  </div>

                  <div className="grid sm:grid-cols-2 gap-2 sm:py-2 mb-3">
                    <Tab.Group manual as={Fragment}>
                      <div className="relative ">
                        <Menu>
                          <Menu.Button className="w-full p-1 mt-1 block shadow rounded-lg ring-gray-300 bg-white focus:ring-2 focus:ring-inset focus:ring-indigo-500 outline-none">
                            <div className="w-full flex justify-between items-center rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 sm:text-sm sm:leading-6">
                              <div className="font-medium">
                                Current List: {selectedListName}{" "}
                              </div>
                              <ChevronDownIcon className="h-6 w-6 font-semibold" />
                            </div>
                          </Menu.Button>
                          <Menu.Items className="bg-white overflow-auto border-x border-b border-gray-300 rounded-lg p-1 w-full absolute z-40">
                            <Tab.List>
                              {allLists?.map((list, index) => (
                                <Menu.Item
                                  key={`menu${list?.id}`}
                                  className="block py-2.5 px-3"
                                >
                                  <Tab
                                    className={`${
                                      selectedlistId == list?.id
                                        ? "text-indigo-600"
                                        : "text-gray-500  hover:text-gray-700"
                                    } whitespace-nowrap text-sm font-medium block w-full text-left`}
                                    href={`#${list?.name
                                      .toLowerCase()
                                      .replace(" ", "_")}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedListId(list?.id);
                                      selectedListName(list?.name);
                                    }}
                                  >
                                    {list?.name}
                                  </Tab>
                                </Menu.Item>
                              ))}
                            </Tab.List>
                          </Menu.Items>
                        </Menu>
                      </div>
                    </Tab.Group>

                    <div className="flex items-center gap-2">
                      {PageType == "Create Campaign" ? (
                        <button
							type="button"
                          disabled={formik.isSubmitting}
                          className="inline-flex justify-center rounded-md bg-gray-100 px-8 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Link to={`/site/admin/${siteId}/mailchimp`}>
                            Cancel
                          </Link>
                        </button>
                      ) : (
                        <button
							type="button"
                          disabled={formik.isSubmitting}
                          className="inline-flex justify-center rounded-md bg-gray-100 px-8 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                        >
                          <Link to={`/site/admin/${siteId}/mailchimp`}>
                            {CampaignData?.status != "sent" ? "Cancel" : "Back"}
                          </Link>
                        </button>
                      )}
                      {PageType == "Create Campaign" && (
                        <button
                          type="submit"
                          disabled={formik.isSubmitting}
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Create
                        </button>
                      )}
                      {CampaignData?.status != "sent" &&
                        PageType == "Campaigns" && (
                          <button
                            type="submit"
                            disabled={formik.isSubmitting}
                            className="inline-flex justify-center rounded-md bg-indigo-600 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Update
                          </button>
                        )}
                      {PageType == "Campaigns" && (
                        <button
							type="button"
							onClick={(event) => handleDelete(event)}
							disabled={formik.isSubmitting}
							className="inline-flex justify-center rounded-md bg-rose-500 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                        >
                          	Delete
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};

export default MailchimpUtil;
