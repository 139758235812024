import {
	ExclamationCircleIcon,
	DocumentIcon,
	ListBulletIcon,
	MegaphoneIcon,
} from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { dbCall } from "../../../../common/db";
import { Menu, Tab } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

const MailChimp = () => {
	const { siteId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const hash = location.hash;

	const [isUser, setIsUser] = useState(false);
	const [userData, setUserData] = useState({});

	const [selectedMenu, setSelectedMenu] = useState(
		hash !== "" ? hash : "Templates"
	);
	const [selectedTemplateTab, setSelectedTemplateTab] =
		useState("All Templates");
	const [selectedCampaignTab, setSelectedCampaignTab] =
		useState("All Campaigns");
	const [selectedAudienceTab, setSelectedAudienceTab] = useState("All Lists");

	// Send Campaign required Data
	const [selectedCampaign, setSelectedCampaign] = useState({
		name: "",
		id: "",
	});

	const [filteredCampaign, setFilteredCampaign] = useState([]);

	// Major States Holds data
	const [TemplatesData, setTemplatesData] = useState([]);
	const [CampaignsData, setCampaignsData] = useState([]);
	const [ListData, setListData] = useState([]);

	const menus = [
		{
			name: "Templates",
			icon: DocumentIcon,
		},
		{
			name: "Campaigns",
			icon: MegaphoneIcon,
		},
		{
			name: "Audience",
			icon: ListBulletIcon,
		},
	];
	const templateTabs = [
		{
			name: "All Templates",
		},
		{
			name: "Create Template",
		},
	];
	const campaignTabs = [
		{
			name: "All Campaigns",
		},
		{
			name: "Create Campaign",
		},
		{
			name: "Send Campaign",
		},
	];
	const audienceTabs = [
		{
			name: "All Lists",
		},
		{
			name: "Create Lists",
		},
		{
			name: "Add/Update Member",
		},
	];

	// Format Date
	function formatDate(dateString) {
		// Parse the date string
		const date = new Date(dateString);

		// Extract the components
		const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
		const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits (getMonth is 0-indexed)
		const year = date.getFullYear();

		return `${day}-${month}-${year}`;
	}

	const handleClick = (str) => {
		navigate(`${location.pathname}/${str}`, { replace: false });
	};

	// Validating User As component Mounts
	useEffect(() => {
		const Validating_User = async () => {
			const res = await dbCall.get(`site/get-notification-credentials/${siteId}`);

			for (let i = 0; i < res?.data?.data.length; i++) {
				if (res?.data?.data[i].provider == "MarketMailChimp") {
					let token = res?.data?.data[i]?.credentials.apiKey;
					let server_prefix = res?.data?.data[i]?.credentials.server;
					localStorage.setItem("mailchimp_token", token);
					localStorage.setItem("mailchimp_server", server_prefix);

					setIsUser(true);
					break;
				}
			}
		};

		Validating_User();

		const Fetch_userData = async () => {
			const { data } = await dbCall.post("/mailchimp/fetch-user", {
				apiKey: localStorage.getItem("mailchimp_token"),
				server: localStorage.getItem("mailchimp_server"),
				provider: "MarketMailChimp",
			});
			console.log("Returnde Data is: ", data);
			setUserData({
				name: data.first_name + " " + data.last_name,
				img: data.avatar_url,
				account_name: data.account_name,
				total_subscribers: data.total_subscribers,
				role: data.role,
			});
		};
		Fetch_userData();
		if (hash) {
			setSelectedMenu(hash);
		}

		console.log("Users data: ", userData);
	}, []);

	const Perform_Operation = async () => {
		if (selectedMenu == "Templates") {
			if (selectedTemplateTab == "All Templates") {
				const { data } = await dbCall.post("/mailchimp/fetch-templates", {
					apiKey: localStorage.getItem("mailchimp_token"),
					server: localStorage.getItem("mailchimp_server"),
					provider: "MarketMailChimp",
				});

				setTemplatesData([...data.templates]);

				// console.log("Returned Template Data is: ", data);
			} else {
			}
		} else if (selectedMenu == "Campaigns") {
			console.log("Selected CampaignTab is: ", selectedCampaignTab);
			if (selectedCampaignTab == "All Campaigns") {
				const { data } = await dbCall.post("/mailchimp/fetch-campaigns", {
					apiKey: localStorage.getItem("mailchimp_token"),
					server: localStorage.getItem("mailchimp_server"),
					provider: "MarketMailChimp",
				});
				setCampaignsData([...data.campaigns]);
				// console.log("Returned Data is: ", data);
			} else if (selectedCampaignTab == "Send Campaign") {
				const campaigns = await dbCall.post("/mailchimp/fetch-campaigns", {
					apiKey: localStorage.getItem("mailchimp_token"),
					server: localStorage.getItem("mailchimp_server"),
					provider: "MarketMailChimp",
				});
				const filteredCampaign = campaigns.data?.campaigns.filter(
					(campaign) => campaign.status != "sent"
				);

				setFilteredCampaign([...filteredCampaign]);
				setSelectedCampaign({
					name: filteredCampaign[0]?.settings?.title,
					id: filteredCampaign[0].id,
				});

				console.log(
					"Returned Data in Send-Campaign is Campaign: \n",
					campaigns.data?.campaigns
				);
			}
		} else {
			if (selectedAudienceTab == "All Lists") {
				const { data } = await dbCall.post("/mailchimp/fetch-lists", {
					apiKey: localStorage.getItem("mailchimp_token"),
					server: localStorage.getItem("mailchimp_server"),
					provider: "MarketMailChimp",
				});
				setListData([...data?.lists]);
				console.log("Returned Data is: ", data?.lists);
			} else if (selectedAudienceTab == "Create Lists") {
			} else if (selectedAudienceTab == "Add/Update Member") {
			}
		}
	};
	useEffect(() => {
		console.log("Menu selected is: ", selectedMenu);

		Perform_Operation();
	}, [
		selectedMenu,
		selectedTemplateTab,
		selectedCampaignTab,
		selectedAudienceTab,
	]);

	const SendCampaign = async () => {
		console.log(`sending data:`, selectedCampaign);
		try {
			const res = await dbCall.post(
				`/mailchimp/send-campaign/${selectedCampaign?.id}`,
				{
					apiKey: localStorage.getItem("mailchimp_token"),
					server: localStorage.getItem("mailchimp_server"),
					provider: "MarketMailChimp",
				}
			);
			console.log("Sent Successfully", res.data);
			toast.success("Campaign Sent Successfully");
		} catch (error) {
			toast.error("Something Went Wrong!");
			console.log(error);
		}
	};

	return (
		<div className="outline-none p-6 min-h-screen bg-gray-100">
			{isUser ? (
				<div className="flex flex-col sm:flex-row w-full">
					<Tab.Group manual as={Fragment}>
						<div className="shadow bg-white py-2 px-4 mt-5 sm:px-6 rounded-lg sm:h-44 md:w-48 lg:w-64">
							<Tab.List className="-mb-px flex flex-row sm:flex-col space-x-4 sm:space-x-0">
								{menus.map((tab, index) => (
									<Tab
										key={`notification${index}`}
										className="focus:outline-none"
										onClick={() => {
											setSelectedAudienceTab("All Lists");
											setSelectedCampaignTab("All Campaigns");
											setSelectedTemplateTab("All Templates");
											setSelectedMenu(tab.name);
										}}
									>
										{({ selected }) => (
											<a
												className={`${selected
													? "text-indigo-600"
													: "border-transparent text-gray-500 hover:text-gray-700"
													} whitespace-nowrap border-b-2 sm:py-4 text-sm font-medium flex border-none`}
												href={`#${tab.name}`}
											>
												<tab.icon
													className={`${selected ? "text-indigo-600" : "text-gray-500"
														} -ml-0.5 mr-2 h-5 w-5`}
												/>
												{tab.name}
											</a>
										)}
									</Tab>
								))}
							</Tab.List>
						</div>

						<Tab.Panels className="w-full ml-0 sm:ml-5 mt-5">
							{/* START - TEMPLATES */}
							<Tab.Panel className="outline-none mb-20">
								<div className="shadow bg-white px-4 py-3 sm:px-6 rounded-lg mb-3">
									{userData?.name && userData?.img && userData?.account_name ? (
										<div className="flex items-center gap-2 justify-between">
											<div className="flex gap-2 items-center">
												<img
													src={userData?.img}
													className="h-6 w-6 rounded-full"
													alt="User Image"
												/>
												<p>
													{userData?.name} ({userData?.role})
												</p>
											</div>
											<div className="flex flex-col text-sm">
												<p className="font-medium mb-1">
													Account Name: {userData?.account_name}
												</p>
												<p className="font-medium mb-1">
													Subscribers:{" "}
													{userData?.total_subscribers
														? userData?.total_subscribers
														: 0}
												</p>
											</div>
										</div>
									) : (
										<p className="text-base font-semibold leading-7 text-gray-900">
											Select an Option to perform on Template
										</p>
									)}
								</div>

								<Tab.Group manual as={Fragment}>
									<div className="relative mb-3">
										<Menu>
											<Menu.Button className="w-full p-1 mt-1 block shadow rounded-lg ring-gray-300 bg-white focus:ring-2 focus:ring-inset focus:ring-indigo-500 outline-none">
												<div className="w-full flex justify-between items-center rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 sm:text-sm sm:leading-6">
													<div className="font-medium">
														{selectedTemplateTab}{" "}
													</div>
													<ChevronDownIcon className="h-6 w-6 font-semibold" />
												</div>
											</Menu.Button>
											<Menu.Items className="bg-white border-x border-b border-gray-300 rounded-lg p-1 w-full absolute z-40">
												<Tab.List>
													{templateTabs.map((value, index) => (
														<Menu.Item
															key={`menu${index}`}
															className="block py-2.5 px-3"
														>
															<Tab
																className={`${selectedTemplateTab == value.name
																	? "text-indigo-600"
																	: "text-gray-500  hover:text-gray-700"
																	} whitespace-nowrap text-sm font-medium block w-full text-left`}
																href={`#${value.name
																	.toLowerCase()
																	.replace(" ", "_")}`}
																onClick={() => {
																	setSelectedTemplateTab(value.name);
																}}
															>
																{value.name}
															</Tab>
														</Menu.Item>
													))}
												</Tab.List>
											</Menu.Items>
										</Menu>
									</div>

									<Tab.Panels className="mt-5">
										{/* Start - All Templates */}
										<Tab.Panel>
											<div className="flex items-center justify-between shadow bg-white px-4 py-3 sm:px-6 rounded-lg mb-3">
												<p>All Templates</p>
												<p>Total: {TemplatesData?.length} </p>
											</div>

											<div className="">
												{TemplatesData?.length == 0 ? (
													<p> No Templates </p>
												) : (
													<div className="bg-white w-full  shadow-md rounded-lg">
														{TemplatesData?.map((template, i) => {
															return (
																<div
																	className="w-full hover:bg-gray-50 py-2 px-4 flex gap-2 justify-between items-center cursor-pointer bg-white border-y"
																	key={template.id}
																	onClick={() => {
																		const str = `${selectedMenu}-${template.name}/${template.id}`;
																		handleClick(str);
																	}}
																>
																	<p className="font-medium truncate lg:w-80 sm:w-56 w-44 ">
																		{template.name}
																	</p>
																	<div className="">
																		<p className="mb-1 w-max ml-auto">
																			{template.created_by == ""
																				? "Default"
																				: `Created by: ${template.created_by}`}
																		</p>

																		<p className="text-sm w-max ml-auto">
																			Date: {formatDate(template.date_created)}
																		</p>
																	</div>
																</div>
															);
														})}
													</div>
												)}
											</div>
										</Tab.Panel>
										{/* End - All Templates */}

										{/* Start - Create Templates */}
										<Tab.Panel>
											<div className="">Create Templates</div>
										</Tab.Panel>
										{/* End - Create Templates */}
									</Tab.Panels>
								</Tab.Group>
							</Tab.Panel>
							{/* END - TEMPLATES */}

							{/* START - CAMPAIGN */}
							<Tab.Panel className="outline-none mb-20">
								<div className="shadow bg-white px-4 py-3 sm:px-6 rounded-lg mb-3">
									{userData?.name && userData?.img && userData?.account_name ? (
										<div className="flex items-center gap-2 justify-between">
											<div className="flex gap-2 items-center">
												<img
													src={userData?.img}
													className="h-6 w-6 rounded-full"
													alt="User Image"
												/>
												<p>
													{userData?.name} ({userData?.role})
												</p>
											</div>
											<div className="flex flex-col text-sm">
												<p className="font-medium mb-1">
													Account Name: {userData?.account_name}
												</p>
												<p className="font-medium mb-1">
													Subscribers:{" "}
													{userData?.total_subscribers
														? userData?.total_subscribers
														: 0}
												</p>
											</div>
										</div>
									) : (
										<p className="text-base font-semibold leading-7 text-gray-900">
											Select an Option to perform on Campaigns
										</p>
									)}
								</div>

								<Tab.Group manual as={Fragment}>
									<div className="relative mb-5">
										<Menu>
											<Menu.Button className="w-full p-1 mt-1 block shadow rounded-lg ring-gray-300 bg-white focus:ring-2 focus:ring-inset focus:ring-indigo-500 outline-none">
												<div className="w-full flex justify-between items-center rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 sm:text-sm sm:leading-6">
													<div className="font-medium">
														{selectedCampaignTab}{" "}
													</div>
													<ChevronDownIcon className="h-6 w-6 font-semibold" />
												</div>
											</Menu.Button>
											<Menu.Items className="bg-white border-x border-b border-gray-300 rounded-lg p-1 w-full absolute z-40">
												<Tab.List>
													{campaignTabs.map((value, index) => (
														<Menu.Item
															key={`menu${index}`}
															className="block py-2.5 px-3"
														>
															<Tab
																className={`${selectedCampaignTab == value.name
																	? "text-indigo-600"
																	: "text-gray-500  hover:text-gray-700"
																	} whitespace-nowrap text-sm font-medium block w-full text-left`}
																href={`#${value.name
																	.toLowerCase()
																	.replace(" ", "_")}`}
																onClick={() => {
																	setSelectedCampaignTab(value.name);

																	if (value.name == "Create Campaign") {
																		const str = `Create Campaign`;
																		handleClick(str);
																	}
																}}
															>
																{value.name}
															</Tab>
														</Menu.Item>
													))}
												</Tab.List>
											</Menu.Items>
										</Menu>
									</div>
									<Tab.Panels className="mt-5">
										{/* START - All Campaigns */}
										<Tab.Panel>
											<div className="flex items-center justify-between shadow bg-white px-4 py-3 sm:px-6 rounded-lg mb-3">
												<p>All Campaigns</p>
												<p>Total: {CampaignsData?.length} </p>
											</div>

											<div className="">
												{CampaignsData?.length == 0 ? (
													<p> No Campaigns </p>
												) : (
													<div className="bg-white w-full  shadow-md rounded-lg">
														{CampaignsData?.map((campaign, i) => {
															return (
																<div
																	className="w-full hover:bg-gray-50 py-2 px-4 flex gap-2 justify-between items-center cursor-pointer bg-white border-y overflow-x-auto"
																	key={campaign.id}
																	onClick={() => {
																		const str = `${selectedMenu}-${campaign?.settings?.title == ""
																			? "Campaign"
																			: campaign?.settings?.title
																			}/${campaign.id}?listId=${campaign?.recipients?.list_id
																			}&templateId=${campaign?.settings?.template_id
																			}`;
																		handleClick(str);
																	}}
																>
																	<p className="font-medium truncate lg:w-80 sm:w-56 w-44 ">
																		{campaign?.settings?.title == ""
																			? "Campaign"
																			: campaign?.settings?.title}
																	</p>

																	<div className="">
																		<p className="text-sm">
																			Opens:{" "}
																			{campaign?.report_summary?.opens
																				? campaign?.report_summary?.opens
																				: 0}
																		</p>
																		<p className="text-sm">
																			Open Rate:{" "}
																			{campaign?.report_summary?.open_rate
																				? campaign?.report_summary?.open_rate.toFixed(
																					2
																				)
																				: 0}
																		</p>
																	</div>
																	<div className="">
																		<p className="mb-1 bg-gray-200 rounded-md px-2 w-max ml-auto">
																			{campaign.status}
																		</p>

																		<p className="text-sm w-max ml-auto">
																			{formatDate(campaign.create_time)}
																		</p>
																	</div>
																</div>
															);
														})}
													</div>
												)}
											</div>
										</Tab.Panel>
										{/* END - All Campaigns */}
										{/* START - CREATE Campaign */}
										<Tab.Panel></Tab.Panel>
										{/* END - Create Campaign */}

										{/* START - SEND Campaigns */}
										<Tab.Panel>
											<div className="grid sm:grid-cols-2 gap-2 items-center">
												<Tab.Group manual as={Fragment}>
													<div className="relative mb-5">
														<p className="font-medium">Campaign: </p>
														<Menu>
															<Menu.Button className="w-full p-1 mt-1 block shadow rounded-lg ring-gray-300 bg-white focus:ring-2 focus:ring-inset focus:ring-indigo-500 outline-none">
																<div className="w-full flex justify-between items-center rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 sm:text-sm sm:leading-6">
																	<div className="font-medium">
																		{selectedCampaign.name}{" "}
																	</div>
																	<ChevronDownIcon className="h-6 w-6 font-semibold" />
																</div>
															</Menu.Button>
															<Menu.Items className="bg-white border-x border-b border-gray-300 rounded-lg p-1 w-full absolute z-40">
																<Tab.List>
																	{filteredCampaign?.map((campaign, index) => (
																		<Menu.Item
																			key={`menu${campaign.id}`}
																			className="block py-2.5 px-3"
																		>
																			<Tab
																				className={`${selectedCampaign.name ==
																					campaign?.settings?.title
																					? "text-indigo-600"
																					: "text-gray-500  hover:text-gray-700"
																					} whitespace-nowrap text-sm font-medium block w-full text-left`}
																				href={`#${campaign?.settings?.title
																					.toLowerCase()
																					.replace(" ", "_")}`}
																				onClick={() => {
																					setSelectedCampaign({
																						name: campaign?.settings?.title,
																						id: campaign.id,
																					});
																				}}
																			>
																				{campaign?.settings?.title}
																			</Tab>
																		</Menu.Item>
																	))}
																</Tab.List>
															</Menu.Items>
														</Menu>
													</div>

													<div className="flex items-end">
														<button
															type="button"
															className="rounded-md bg-indigo-600 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-auto w-max mb-3"
															disabled={CampaignsData.length == 0}
															onClick={SendCampaign}
														>
															Send
														</button>
													</div>
												</Tab.Group>
											</div>
										</Tab.Panel>
										{/* END - SEND Campaigns */}
									</Tab.Panels>
								</Tab.Group>
							</Tab.Panel>
							{/* END - CAMPAIGN */}

							{/* START - Audience */}
							<Tab.Panel className="outline-none mb-20">
								<div className="shadow bg-white px-4 py-3 sm:px-6 rounded-lg mb-3">
									{userData?.name && userData?.img && userData?.account_name ? (
										<div className="flex items-center gap-2 justify-between">
											<div className="flex gap-2 items-center">
												<img
													src={userData?.img}
													className="h-6 w-6 rounded-full"
													alt="User Image"
												/>
												<p>
													{userData?.name} ({userData?.role})
												</p>
											</div>
											<div className="flex flex-col text-sm">
												<p className="font-medium mb-1">
													Account Name: {userData?.account_name}
												</p>
												<p className="font-medium mb-1">
													Subscribers:{" "}
													{userData?.total_subscribers
														? userData?.total_subscribers
														: 0}
												</p>
											</div>
										</div>
									) : (
										<p className="text-base font-semibold leading-7 text-gray-900">
											Select an Option to perform on Audiences
										</p>
									)}
								</div>

								<Tab.Group manual as={Fragment}>
									<div className="relative mb-5">
										<Menu>
											<Menu.Button className="w-full p-1 mt-1 block shadow rounded-lg ring-gray-300 bg-white focus:ring-2 focus:ring-inset focus:ring-indigo-500 outline-none">
												<div className="w-full flex justify-between items-center rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 sm:text-sm sm:leading-6">
													<div className="font-medium">
														{selectedAudienceTab}{" "}
													</div>
													<ChevronDownIcon className="h-6 w-6 font-semibold" />
												</div>
											</Menu.Button>
											<Menu.Items className="bg-white border-x border-b border-gray-300 rounded-lg p-1 w-full absolute z-40">
												<Tab.List>
													{audienceTabs.map((value, index) => (
														<Menu.Item
															key={`menu${index}`}
															className="block py-2.5 px-3"
														>
															<Tab
																className={`${selectedAudienceTab == value.name
																	? "text-indigo-600"
																	: "text-gray-500  hover:text-gray-700"
																	} whitespace-nowrap text-sm font-medium block w-full text-left`}
																href={`#${value.name
																	.toLowerCase()
																	.replace(" ", "_")}`}
																onClick={() => {
																	setSelectedAudienceTab(value.name);
																}}
															>
																{value.name}
															</Tab>
														</Menu.Item>
													))}
												</Tab.List>
											</Menu.Items>
										</Menu>
									</div>
									<Tab.Panels className="mt-5">
										<Tab.Panel>
											<div className="flex items-center justify-between shadow bg-white px-4 py-3 sm:px-6 rounded-lg mb-3">
												<p>All Lists</p>
												<p>Total: {ListData?.length ?? 0} </p>
											</div>

											<div className="">
												{ListData?.length == 0 ? (
													<p> No Lists (Create new one) </p>
												) : (
													<div className="bg-white w-full  shadow-md rounded-lg">
														{ListData?.map((list, i) => {
															return (
																<div
																	className="w-full hover:bg-gray-50 py-2 px-4 flex gap-2 justify-between items-center cursor-pointer bg-white border-y overflow-x-auto"
																	key={list.id}
																>
																	<p className="font-medium truncate lg:w-80 sm:w-56 w-44 ">
																		{list?.name == ""
																			? "[No name]"
																			: list?.name}
																	</p>

																	<div className="">
																		<p className="text-sm">
																			Open Rate:{" "}
																			{list?.stats?.open_rate
																				? list?.stats?.open_rate
																				: 0}
																		</p>
																		<p className="text-sm">
																			Click Rate:{" "}
																			{list?.stats?.click_rate
																				? list?.stats?.click_rate.toFixed(2)
																				: 0}
																		</p>
																	</div>
																	<div className="">
																		<p className="mb-1 bg-gray-200 rounded-md px-2 w-max ml-auto">
																			Member: {list?.stats?.member_count ?? 0}
																		</p>

																		<p className="text-sm w-max ml-auto">
																			{formatDate(list?.date_created)}
																		</p>
																	</div>
																</div>
															);
														})}
													</div>
												)}
											</div>
										</Tab.Panel>

										<Tab.Panel></Tab.Panel>
										<Tab.Panel>
											<div className="">
												<button type="button" className="rounded-md bg-indigo-600 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-auto w-max mb-3"></button>
											</div>
										</Tab.Panel>
									</Tab.Panels>
								</Tab.Group>
							</Tab.Panel>
							{/* END - Audience */}
						</Tab.Panels>
					</Tab.Group>
				</div>
			) : (
				<div className="shadow bg-white px-4 py-5 sm:px-6 rounded-lg">
					<div className="flex items-center gap-2">
						<ExclamationCircleIcon
							className="h-5 w-5 text-red-400"
							aria-hidden="true"
						/>
						<h2 className="text-base font-semibold leading-7 text-gray-900">
							Your are not Authenticated User.
						</h2>
					</div>
					<p className="mt-1  text-sm leading-6 text-gray-600">
						We need your API key from Mailchimp in Notification Section
					</p>
				</div>
			)}
		</div>
	);
};

export default MailChimp;
