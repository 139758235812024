import { Listbox } from "@headlessui/react";
const Field = ({ valueTypes, elementBreakpointSpecificStyleObject, setElementBreakpointSpecificStyleObject, updateDesign, setValueTypes, propertyData }) => {

	return (
		<div className="text-xs flex items-center">
			{
				(
					valueTypes[propertyData.propertyName].unit == 'px' || valueTypes[propertyData.propertyName].unit == '%' || valueTypes[propertyData.propertyName].unit == 'vh' || valueTypes[propertyData.propertyName].unit == 'vw'
				) &&
				<input
					type="number"
					className="w-[47px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9]"
					value={parseInt(elementBreakpointSpecificStyleObject[propertyData.propertyName])}
					onChange={(
						e
					) => {
						setElementBreakpointSpecificStyleObject(
							(
								prev
							) => {
								return {
									...prev,
									[propertyData.propertyName]: `${e
										.target
										.value
										}${valueTypes[propertyData.propertyName].unit}`,
								};
							}
						);
					}}
					onKeyUp={(
						e
					) => {
						if (
							e.key ==
							"Enter"
						) {
							updateDesign(
								propertyData.propertyName,
								`${e.target.value}${valueTypes[propertyData.propertyName].unit}`
							);
						}
					}}
					onBlur={(e) => {
						updateDesign(
							propertyData.propertyName,
							`${e
								.target
								.value
							}${valueTypes[propertyData.propertyName].unit}`
						);
					}}
				/>
			}

			<Listbox
				as={'div'}
				className={'relative'}
				value={
					valueTypes[propertyData.propertyName].unit
				}
				onChange={(value) => {
					setValueTypes(
						(prev) => {
							return {
								...prev,
								[propertyData.propertyName]: {
									...prev[propertyData.propertyName],
									unit: value
								}
							};
						}
					);
					if (value == 'px' || value == '%' || value == 'vh' || value == 'vw') {
						updateDesign(
							propertyData.propertyName,
							propertyData.logicalOptions.map((opt) => {
								if (elementBreakpointSpecificStyleObject[propertyData.propertyName] == opt) {
									return false;
								} else {
									return true;
								}
							}).includes(false) ? `0${value}` : `${parseInt(elementBreakpointSpecificStyleObject[propertyData.propertyName])}${value}`

						);
					} else {
						updateDesign(
							propertyData.propertyName,
							value
						);
					}

				}}
			>
				<Listbox.Button className={`cursor-pointer py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 ${(valueTypes[propertyData.propertyName].unit == 'px' || valueTypes[propertyData.propertyName].unit == '%' || valueTypes[propertyData.propertyName].unit == 'vh' || valueTypes[propertyData.propertyName].unit == 'vw') ? "uppercase rounded-r" : "capitalize rounded"}`}>
					<span>
						{
							valueTypes[propertyData.propertyName].unit
						}
					</span>
					<span class="mdi mdi-chevron-down"></span>

				</Listbox.Button>
				<Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
					{
						propertyData.options.map(
							(
								value,
								index
							) => (
								<Listbox.Option
									key={index}
									className={({
										active,
									}) => `${active ? "bg-gray-100" : ""} cursor-pointer`
									}
									value={value}
								>
									{({ selected }) => (
										<div className={`flex items-center gap-1 px-2 py-1.5 text-gray-900${selected ? " bg-indigo-100 font-medium" : ""
											} ${(value == 'px' || value == '%' || value == 'vh' || value == 'vw') ? "uppercase" : "capitalize"}`}>
											<div>{value}</div>
											{
												valueTypes[propertyData.propertyName].unit == value &&
												<span class="mdi mdi-check"></span>
											}
										</div>
									)}
								</Listbox.Option>
							)
						)}
				</Listbox.Options>
			</Listbox>
		</div>
	)
}
export default Field;