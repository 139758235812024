import React, { useState, createContext, useReducer, useRef } from "react";
import { Breducer, strapiReducer, sqliteReducer } from "./Reducer";

export const context = createContext(null);

export const ContextProvider = ({ children }) => {

	// User data

	const [user, setUser] = useState(null)

	// strapi schema
	const [strapiSchema, strapiDispatch] = useReducer(strapiReducer, []);

	// sqlite schema
	const [sqliteSchema, sqliteDispatch] = useReducer(sqliteReducer, []);

	// For user website render 
	const [blocks, Bdispatch] = useReducer(Breducer, [])
	const [pageId, setPageId] = useState(null)

	// const [blocks, Bdispatch] = useReducer(Breducer, blockd)

	// END - reducers


	// Undo State, { ui: [], backend: [] }
	const [undo, setUndo] = useState({
		ui: [],
		backend: []
	});

	// Redo state
	const [redo, setRedo] = useState({
		ui: [],
		backend: []
	});

	// loading state
	const [isAnimating, setIsAnimating] = useState(false);

	// current left sidebar section tab i.e. Element, Icon, Section
	const [currentLeftSidebarSectionTAB, setCurrentLeftSidebarSectionTAB] = useState("element");

	const [pageName, setPageName] = useState("")

	// create new page

	// to display duplicate & delete btn
	const [cordinates, setCordinates] = useState({
		x: -100,
		y: -100
	})

	// to prevent user from clicking on Publish btn multiple times
	const [publish, setPublish] = useState(false)

	// selected site theme
	const [currentTheme, setCurrentTheme] = useState({})

	// selected site data
	const [selectedSiteData, setSelectedSiteData] = useState({});

	// to re-render editor schema 
	const [forceRender, setForceRender] = useState(false);

	// to solve Duplicate issue after dropping a particular element or section,
	// without this element or section is dropped but when trying to move it, it is duplicated
	const [isRender, setIsRender] = useState(true);

	// used to update content value directly in schema
	const [editableValue, setEditableValue] = useState("")

	// ui schema is added when a particular element or section is dragged
	const [element, setElement] = useState({});

	// strapi schema to add
	const [strapiElement, setStrapiElement] = useState({});

	// site admin states
	const [adminURL, setAdminURL] = useState("");
	const [adminToken, setAdminToken] = useState("");

	const [grab, setGrab] = useState(false);

	// selcted element schema
	const [elementId, setElementId] = useState("")

	const [isDrag, setIsDrag] = useState(true)

	// is elements dropped from left side bars (element, icon, setion, media, form)
	const [isDropped, setIsDropped] = useState(false);

	// used for events
	const [workingOnEvent, setWorkingOnEvent] = useState(false);

	// refs
	const elementRef = useRef(null);
	const formElementRef = useRef(null);
	const componentRef = useRef(null);
	// START - Form States

	// display form data


	// element id when mouse is hovered upon the selected template
	const [elementIdOnMouseOver, setElementIdOnMouseOver] = useState(null);

	// whether form is selected or not
	const [formSelected, setFormSelected] = useState(false);

	// form name
	const [formName, setFormName] = useState('');

	// selected form id in ui schema
	const [formId, setFormId] = useState(null);

	// selected form element id
	const [selectedFormElementId, setSelectedFormElementId] = useState(null);

	// cordinates of button (add new form element button) 
	const [addNewFormElementCordinates, setAddNewFormElementCordinates] = useState({
		cordinatesValue: {
			top: 0,
			left: 0
		},
		id: 0
	});

	const [updateStaticData, setUpdateStaticData] = useState(false)
	// END - Form States

	// display data tab in editor
	const [displayDataTab, setDisplayDataTab] = useState(false);

	// display events tab in right side bar
	const [displayEventTab, setDisplayEventTab] = useState(false);

	// display components data

	// selected conponent element schema just like elementId
	const [componentElement, setComponentElement] = useState();

	// all strapi collection names
	const [strapiCollectionNames, setStrapiCollectionNames] = useState([]);

	const [notificationRerender, setNotificationRerender] = useState(false);

	const [isSrapiChanged, setIsStrapiChanged] = useState(false)

	const [isListDynamic, setIsListDynamic] = useState({})

	const [isOpenStaticTable, setIsOpenStaticTable] = useState(false);

	const [isPublish, setIsPublish] = useState(false)
	const [currentPlatform, setCurrentPlatform] = useState(null);
	const [theme, setTheme] = useState(null);

	const [pageLayoutChanged, setPageLayoutChanged] = useState(false);
	const [component, setComponent] = useState(null);
	const componentWithSettingsRef = useRef(null);

	// check the staticData update or note
	const [isStaticDataUpdate, setIsStaticDataUpdate] = useState(false)


	// State to manage CDN 
	const [cdn, setCdn] = useState([]);

	// set the Coordinates for the Section on Mouse over
	const [onHoverCoordinates, setOnHoverCoordinates] = useState()

	const [layout, setLayout] = useState({})
	const [elementBreakpointSpecificStyleObject, setElementBreakpointSpecificStyleObject] = useState({});

	const [openedSections, setOpenedSections] = useState({
		layout: false,
		dimensions: false,
		spaceBorder: false,
		spacing: false,
		position: false,
		typography: false,
		borders: false,
		background: false,
		media: false,
		overflow: false,
		effects: false,
		customCss: false,
		content: false,
	});

	const [totalMsg, setTotalMsg] = useState(0)
	const [selectedBreakpoint, setSelectedBreakpoint] = useState({
		classPrefix: "",
		value: "0px",
		name: "base",
		displayName: "0 & above",
		width: "350px"
	});

	const [isLayerChange, setIsLayerChange] = useState("firstTime")
	const [currentLanguage, setCurrentLanguage] = useState(null);
	const [content, setContent] = useState(null);
	const [isSiteTrialActive, setIsSiteTrialActive] = useState(true);
	const [currentPlan, setCurrenntPlan] = useState(null);
	const [globalVariable, setGlobalVariable] = useState({});
	const [openedLeftSidebarSection, setOpenedLeftSidebarSection] = useState(null);
	const [openedRightSidebarSection, setOpenedRightSidebarSection] = useState(null);

	return (
		<context.Provider
			value={{

				// user reducer
				user, setUser,

				// page reducer
				pageName, setPageName,


				// ui reducer
				blocks, Bdispatch,

				// strapi reducer
				strapiSchema, strapiDispatch,

				// sqlite reducer
				sqliteSchema, sqliteDispatch,

				// references
				elementRef,
				formElementRef,
				componentRef,

				// loading state
				isAnimating, setIsAnimating,

				// current left sidebar section tab i.e. Element, Icon, Section
				currentLeftSidebarSectionTAB, setCurrentLeftSidebarSectionTAB,

				isDrag, setIsDrag,

				// is elements dropped from left side bars (element, icon, setion, media, form)
				isDropped, setIsDropped,

				// to display duplicate & delete btn
				cordinates, setCordinates,

				// selected site theme
				currentTheme, setCurrentTheme,

				// selected site data
				selectedSiteData, setSelectedSiteData,

				// to solve Duplicate issue after dropping a particular element or section,
				// without this element or section is dropped but when trying to move it, it is duplicated
				isRender, setIsRender,

				// ui schema is added when a particular element or section is dragged
				element, setElement,

				// strapi schema to add
				strapiElement, setStrapiElement,

				// used to update content value directly in schema
				editableValue, setEditableValue,

				// to prevent user from clicking on Publish btn multiple times
				publish, setPublish,

				// used for events
				workingOnEvent, setWorkingOnEvent,

				// element id when mouse is hovered upon the selected template
				elementIdOnMouseOver, setElementIdOnMouseOver,

				grab, setGrab,

				// selcted element schema
				elementId, setElementId,

				// site admin states
				adminURL, setAdminURL,
				adminToken, setAdminToken,

				// display data tab in editor
				displayDataTab, setDisplayDataTab,

				// display events tab in right side bar
				displayEventTab, setDisplayEventTab,

				// whether form is selected or not
				formSelected, setFormSelected,

				// form name
				formName, setFormName,

				// selected form id
				formId, setFormId,

				// selected form element id
				selectedFormElementId, setSelectedFormElementId,

				// cordinates of button (add new form element button) 
				addNewFormElementCordinates, setAddNewFormElementCordinates,


				// all strapi collection names
				strapiCollectionNames, setStrapiCollectionNames,

				// to re-render editor schema
				forceRender, setForceRender,

				notificationRerender, setNotificationRerender,

				// undo state
				undo, setUndo,

				// redo state
				redo, setRedo,

				// selected conponent element schema just like elementId
				componentElement, setComponentElement,

				//check site is published first Time or not
				isPublish, setIsPublish,
				currentPlatform, setCurrentPlatform,
				theme, setTheme,
				pageId, setPageId,

				//Check if strapi Schema is Changed or not
				isSrapiChanged,
				setIsStrapiChanged,

				updateStaticData, setUpdateStaticData,

				//store the product List data isDynamic or not
				isListDynamic, setIsListDynamic,

				isOpenStaticTable, setIsOpenStaticTable,

				//for update the layout(header and footer) of page in real time
				pageLayoutChanged, setPageLayoutChanged,
				componentWithSettingsRef,
				component, setComponent,
				// check the staticData update or note
				isStaticDataUpdate, setIsStaticDataUpdate,

				// CDN state
				cdn, setCdn,
				onHoverCoordinates,
				setOnHoverCoordinates,

				elementBreakpointSpecificStyleObject,
				setElementBreakpointSpecificStyleObject,
				openedSections,
				setOpenedSections,
				totalMsg, setTotalMsg,
				layout,
				setLayout,
				selectedBreakpoint,
				setSelectedBreakpoint,
				isLayerChange, setIsLayerChange,
				currentLanguage, setCurrentLanguage,
				content, setContent,
				isSiteTrialActive, setIsSiteTrialActive,
				currentPlan, setCurrenntPlan,
				globalVariable, setGlobalVariable,
				openedLeftSidebarSection, setOpenedLeftSidebarSection,
				openedRightSidebarSection, setOpenedRightSidebarSection
			}}
		>
			{children}
		</context.Provider>
	);
};