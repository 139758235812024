import { Disclosure } from "@headlessui/react";
import { BlockWrapper } from "../Sortable";
import { useContext } from "react";
import { context } from "../../../../context/Context";
import { useLocation, useParams } from "react-router-dom";
import { generateCss } from "../functions/sortableFunctions";
const DisclosureComponent = ({ block, ...props }) => {
	const { componentWithSettingsRef, setComponent } = useContext(context);
	const handleComponentWithSettingsClick = () => {
		const component = document.getElementById(block.id);
		if (componentWithSettingsRef.current != component) {
			setComponent(block);
			if (componentWithSettingsRef.current) {
				componentWithSettingsRef.current.style.outline = 'none';
			}
			componentWithSettingsRef.current = component;
			componentWithSettingsRef.current.style.outline = '2px dashed #4F46E5';
		}
	}
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (
		<Disclosure
			{...block.data}
			as={block.data?.type ? block.data.type : 'div'}
			id={block.id}
			className={generateCss({
				contextVariables: contextVariables,
				props: {
					...props,
					block: block
				},
				params: params,
				location: location
			})}
			style={{
				fontFamily: block?.style?.fontFamily
			}}
			onMouseOver={(e) => props.handleMouseOver(e)}
			onClick={(e) => {
				props.handleClick(e, block.id, false, -1, true);
				handleComponentWithSettingsClick();
			}}
		>
			{({ open }) => (
				<>
					{
						block.children.map((value, index) => {
							return <BlockWrapper
								{...props}
								key={index}
								block={value}
								open={open}
								blockIndex={[...props.blockIndex, index]}
								customComponentChildren={true}
								handleComponentWithSettingsClick={handleComponentWithSettingsClick}
							/>
						})
					}
				</>
			)}
		</Disclosure>
	)
}
export default DisclosureComponent;