import { Dialog, Disclosure, Popover, Switch, Transition } from "@headlessui/react";
import {
	ExclamationTriangleIcon,
	XMarkIcon
} from "@heroicons/react/24/outline";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from 'yup';

import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import { dbCall } from "../../../common/db";
import { context } from "../../../context/Context";
import { processChange } from "../../../layouts/NewEditor";
import { classNames } from "../../../libraries/utility";
import { saveProject } from "../../../layouts/NewEditor";
export default function Pages({ setZoomDivHeight, pages, fetchPages }) {
	const [modals, setmodals] = useState([]);
	const [setting, setSetting] = useState({
		settingOpen: false
	});
	const [redirectMethod, setRedirectMethod] = useState("")
	const [edit, setEdit] = useState(false);
	const [createNewModal, setCreateNewModal] = useState(false);
	const [createNewPageDialogOpen, setCreateNewPageDialogOpen] = useState(false);

	const {
		setPageName,
		setIsAnimating,
		currentPlatform,
		undo,
		setUndo,
		setRedo,
		openedRightSidebarSection,
		setOpenedRightSidebarSection,
		openedLeftSidebarSection,
		setOpenedLeftSidebarSection,
		layout,
		blocks,
		content,
		pageId,
		selectedSiteData,
		sqliteSchema,
		strapiSchema
	} = useContext(context);

	const params = useParams();
	const navigate = useNavigate();

	const getAllModals = async () => {
		try {
			const response = await dbCall.get(`/site/get-all-modal/${params.siteId}/${currentPlatform}`);
			setmodals((prev) => {
				return response.data.pagesData;
			})
			return response.data.pagesData;
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else {
				toast.error("Something went wrong!")
			}
		}

	};


	useEffect(() => {
		getAllModals();
	}, []);

	const updatePageName = async ({ e, page }) => {
		try {
			if (e.target.innerText.trim() == page.pageName) {
				setEdit(null);
				document.getElementById(`pagenameEdit${page?.id}`).innerHTML = page.pageName;
			} else if (e.target.innerText.trim() != "") {
				setIsAnimating(true)
				await dbCall.put("/site/update-page-name", {
					id: page?.id,
					pageName: e.target.innerText.trim().replace(/\n/g, ' '),
					platform: currentPlatform
				});
				setEdit(null)
				toast.success('Page name updated')
				document.getElementById(`pagenameEdit${page?.id}`).innerHTML = e.target.innerText.trim().replace(/\n/g, ' ');
				setPageName(e.target.innerText.trim().replace(/\n/g, ' '));
				fetchPages();
			} else if (e.target.innerText.trim() == "") {
				setEdit(null)
				document.getElementById(`pagenameEdit${page?.id}`).innerHTML = page.pageName;
				toast.error("Page name cannot be empty")
			}
		} catch (error) {
			console.log(error);
			setEdit(null)
			document.getElementById(`pagenameEdit${page?.id}`).innerHTML = page.pageName;
			if (error.response) {
				toast.error(error.response.data.message);
			} else {
				toast.error('Something went wrong!');
			}
		} finally {
			setIsAnimating(false)
		}
	}

	return (
		<>
			<div>
				<button
					type="button"
					className={classNames(
						openedLeftSidebarSection == 'pages'
							? "bg-[#393939]"
							: "hover:bg-[#393939]",
						"rounded-md px-3 py-2 font-semibold text-white"
					)}
					id="step-one"
					onClick={() => {
						processChange()
						setOpenedRightSidebarSection(openedLeftSidebarSection ? openedRightSidebarSection : false)
						setOpenedLeftSidebarSection((prev) => {
							if (prev == 'pages') {
								return null;
							} else {
								return 'pages';
							}
						})
					}}
					title="Pages"
				>
					<span class="mdi mdi-note text-2xl shrink-0"></span>

				</button>

				{openedLeftSidebarSection == 'pages' &&
					<div className={`w-full opacity-100 z-[40] opacity-0 transition max-h-screen overflow-y-auto duration-300 flex flex-col bg-white fixed inset-y-0 left-20 top-[103px] max-w-sm`}>
						<div className="flex items-center w-full justify-between px-4 py-3 border-b">
							<span className="text-[#373C47] font-medium">Pages<span className="font-[400] ml-1">({pages?.length})</span></span>
							<div className="flex items-start gap-2	">
								<button
									type="button"
									onClick={() => setCreateNewPageDialogOpen(true)}
									className=" border border-black px-2 py-0.5 rounded-md"
								>
									+ Add Page
								</button>
								<button
									type="button"
									onClick={() => {
										setOpenedLeftSidebarSection(null)
									}}
								>
									<span className="mdi mdi-close text=2xl text-[#757575]"></span>
								</button>
							</div>
						</div>
						<div className=" px-3 py-3 text-[#757575] border-b">
							<span>User Pages</span>
							<div className=" mt-3 space-y-3 w-full ">
								{pages.map((page, index) => (
									<div onClick={async (e) => {
										try {
											e.stopPropagation()
											setTimeout(() => {
												let element = document.getElementById("zoomDiv");
												let clientRect = element.getBoundingClientRect();
												setZoomDivHeight({ height: clientRect.height })
											}, 1000)

											if (undo?.ui?.length > 1) {
												if (window?.confirm('Are you wanted to save your Changes?')) {
													setIsAnimating(true);
													await saveProject({
														setIsAnimating: setIsAnimating,
														layout: layout,
														blocks: blocks,
														content: content,
														pageId: pageId,
														selectedSiteData: selectedSiteData,
														sqliteSchema: sqliteSchema,
														strapiSchema: strapiSchema,
														params: params
													});
												}
											}
											setUndo({ ui: [], backend: [] });
											setRedo({ ui: [], backend: [] });
											navigate(`/user/editor/${params.siteId}/${page.pageName}`);
										} catch (error) {
											console.log(error);
											toast.error("Something went wrong!");
										} finally {
											setIsAnimating(false);
										}
									}} className="group px-2 py-1 rounded flex items-center border justify-between cursor-pointer">
										<div className="flex items-center gap-3">
											{
												page.path == '/' ?
													<span class="mdi mdi-home-outline text-xl"></span> :
													<span className="mdi mdi-note-outline text-xl"></span>
											}
											<div className="text-start flex items-center">
												<p
													onDoubleClick={() => setEdit(index)}
													contentEditable={edit == index}
													onKeyUp={async (e) => {
														if (e.key == "Enter") {
															await updatePageName({
																e: e,
																page: page
															});
														}
													}}
													onBlur={async (e) => {
														await updatePageName({
															e: e,
															page: page
														});
													}}
													title="Double Click to update Page Name"
													id={`pagenameEdit${page?.id}`}
													className="truncate max-w-[100px] focus:outline-none outline-none ring-0 border-none focus:ring-0"
												>{page?.pageName}</p>
											</div>
										</div>
										<div className="flex items-center gap-2">
											{
												currentPlatform == 'website' &&
												<PageSEOSetting siteId={params?.siteId} page={page} redirectMethod={redirectMethod} setRedirectMethod={setRedirectMethod} setSetting={setSetting} setting={setting} pages={pages} />
											}
											<PageSetting page={page} key={page.id} fetchPages={fetchPages} />
											{
												!page?.isAuthPage &&
												<PageOptions key={index} page={page} fetchPages={fetchPages}
												/>
											}
										</div>
									</div>
								))}
							</div>
						</div>

						<div className=" px-3 py-3 text-[#757575] border-b">
							<div className="flex items-center justify-between">
								<span>Popups</span>
								<button onClick={() => setCreateNewModal(true)} className=" border border-black px-2 py-0.5 rounded-md">
									+ Add Popup
								</button>
							</div>
							<div className=" mt-3 space-y-3 w-full ">
								{modals?.map((page, index) => (
									<div
										onClick={async (e) => {
											try {
												e.stopPropagation()
												setTimeout(() => {
													let element = document.getElementById("zoomDiv");
													let clientRect = element.getBoundingClientRect();
													setZoomDivHeight({ height: clientRect.height })
												}, 1000)

												if (undo?.ui?.length > 1) {
													if (window?.confirm('Are you wanted to save your Changes?')) {
														setIsAnimating(true);
														await saveProject({
															setIsAnimating: setIsAnimating,
															layout: layout,
															blocks: blocks,
															content: content,
															pageId: pageId,
															selectedSiteData: selectedSiteData,
															sqliteSchema: sqliteSchema,
															strapiSchema: strapiSchema,
															params: params
														});
													}
												}

												setPageName(page.ModalName);
												setUndo({ ui: [], backend: [] });
												setRedo({ ui: [], backend: [] });
												navigate(`/user/editor/${params.siteId}/${page?.ModalName}/true`);
											} catch (error) {
												console.log(error);
												toast.error("Something went wrong!");
											} finally {
												setIsAnimating(false);
											}
										}} className="group px-2 py-1 rounded flex items-center border justify-between cursor-pointer">
										<div className="flex items-center gap-3">
											{page.path == '/' ? <span class="mdi mdi-home-outline text-xl"></span> :
												<span className="mdi mdi-note-outline text-xl">
												</span>}
											<div className="text-start flex items-center">
												<p

													className="truncate max-w-[100px] focus:outline-none outline-none ring-0 border-none focus:ring-0"
												>{page?.ModalName
													}</p>
											</div>
										</div>
										<div className="flex items-center gap-2">
											<PageOptions page={page} isModal={true} getAllModals={getAllModals} />
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				}
			</div>
			<NewPage fetchPages={fetchPages} createNewPageDialogOpen={createNewPageDialogOpen} setCreateNewPageDialogOpen={setCreateNewPageDialogOpen} />
			<NewModal createNewModal={createNewModal} setCreateNewModal={setCreateNewModal} />
		</>
	)
}

const NewPage = ({ fetchPages, createNewPageDialogOpen, setCreateNewPageDialogOpen }) => {
	const { currentPlatform } = useContext(context)

	const params = useParams()
	const navigate = useNavigate();
	const createNewPageFn = async (pagename) => {
		if (pagename.length == 0) return;

		await dbCall.post("site/create-page", {
			siteId: params.siteId,
			pageName: pagename,
			platform: currentPlatform
		})
			.then(response => {
				fetchPages();
				toast.success("Page Created Successfully!")
				setCreateNewPageDialogOpen(false)
				navigate(`/user/editor/${params?.siteId}/${pagename}`)
			})
			.catch(err => console.log(err))
	}
	return (
		<Dialog
			open={createNewPageDialogOpen}
			onClose={() => setCreateNewPageDialogOpen(false)}
			className="fixed inset-0 w-screen h-screen z-50 bg-gray-200/50 flex items-center justify-center"
		>
			<Dialog.Panel className="bg-white w-full max-w-[600px] py-3 shadow-lg rounded-lg border border-gray-300">
				<Dialog.Title className="text-lg font-medium text-gray-900 flex items-center justify-between px-3">
					Create Page
					<button
						type="button"
						className="rounded-md bg-white text-gray-700 hover:text-gray-500 outline-none"
						onClick={() => setCreateNewPageDialogOpen(false)}
					>
						<XMarkIcon className="h-6 w-6" aria-hidden="true" />
					</button>
				</Dialog.Title>

				<hr className="text-gray-800 w-full my-2" />

				<Formik
					className="w-full"
					initialValues={{ username: "" }}
					onSubmit={async (values) => {
						createNewPageFn(values.username)
					}}
				>
					{({ errors, touched, isValidating }) => (
						<Form className="bg-white rounded px-3 pt-1">
							<div className="mb-4">
								<label
									className="block text-gray-700 text-sm font-bold mb-2"
									for="username"
								>
									New Page Name
								</label>
								<Field
									className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 placeholer:text-gray-500"
									id="username"
									type="text"
									name="username"
									placeholder="Page Name"
								/>
							</div>
							<div className="mt-3 w-full">
								<button
									type="submit"
									className="bg-green-600 text-white py-1.5 px-7 rounded-lg font-medium"
								>
									Create
								</button>
							</div>

						</Form>
					)}
				</Formik>
			</Dialog.Panel>
		</Dialog>
	)
}

const NewModal = ({ createNewModal, setCreateNewModal }) => {

	const params = useParams()
	const navigate = useNavigate();
	const createNewPageFn = async (pagename) => {
		if (pagename.length == 0) return

		await dbCall.post("site/create-site-modal", {
			siteId: params.siteId,
			pageName: pagename,
			required: false,
		})
			.then(response => {
				toast.success("Page Created Successfully!")
				setCreateNewModal(false)
				navigate(`/user/editor/${params?.siteId}/${pagename}/true`)
			})
			.catch(err => console.log(err))
	}
	return (
		<Dialog
			open={createNewModal}
			onClose={() => setCreateNewModal(false)}
			className="fixed inset-0 w-screen h-screen z-50 bg-gray-200/50 flex items-center justify-center"
		>
			<Dialog.Panel className="bg-white w-full max-w-[600px] py-3 shadow-lg rounded-lg border border-gray-300">
				<Dialog.Title className="text-lg font-medium text-gray-900 flex items-center justify-between px-3">
					Create Modal
					<button
						type="button"
						className="rounded-md bg-white text-gray-700 hover:text-gray-500 outline-none"
						onClick={() => setCreateNewModal(false)}
					>
						<XMarkIcon className="h-6 w-6" aria-hidden="true" />
					</button>
				</Dialog.Title>

				<hr className="text-gray-800 w-full my-2" />

				<Formik
					className="w-full"
					initialValues={{ username: "" }}
					onSubmit={async (values) => {
						await new Promise((resolve) => setTimeout(resolve, 500));
						createNewPageFn(values.username)
					}}
				>
					{({ errors, touched, isValidating }) => (
						<Form className="bg-white rounded px-3 pt-1">
							<div className="mb-4">
								<label
									className="block text-gray-700 text-sm font-bold mb-2"
									for="username"
								>
									New Page Name
								</label>
								<Field
									className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 placeholer:text-gray-500"
									id="username"
									type="text"
									name="username"
									placeholder="Page Name"
								/>
							</div>
							<div className="mt-3 w-full">
								<button
									type="submit"
									className="bg-green-600 text-white py-1.5 px-7 rounded-lg font-medium"
								>
									Create
								</button>
							</div>

						</Form>
					)}
				</Formik>
			</Dialog.Panel>
		</Dialog>
	)
}

function PageOptions({ page, isModal, fetchPages, getAllModals }) {
	const [confirmDelete, setConfirmDelete] = useState(false);
	const {
		setPageName,
		setIsAnimating,
		currentPlatform
	} = useContext(context);

	const navigate = useNavigate();
	const params = useParams();
	const createDuplicatePage = async () => {
		const newPageName = `${page.pageName}_${Math.floor(Math.random() * 8999 + 1000)}`;
		try {
			setIsAnimating(true);
			const response = await dbCall.get(`/site/get-page-data/${page.pageName}/${params.siteId}/${currentPlatform}`);
			const schema = response?.data?.data?.pageData.schema
			await dbCall.post("/site/create-page", {
				siteId: params.siteId,
				pageName: `${newPageName}`,
				schema: schema,
				platform: currentPlatform
			});
			fetchPages();
			navigate(`/user/editor/${params?.siteId}/${newPageName}`)
		} catch (error) {
			toast.error("Something went wrong!");
		} finally {
			setIsAnimating(false)

		}
	};

	const deletePage = async () => {
		try {
			setIsAnimating(true);
			if (!isModal) {
				await dbCall.delete(`/site/delete-page/${page.id}`);
				toast.success("Page deleted successfully");
				if (params?.pageName == page?.pageName) {
					navigate(`/user/editor/${params?.siteId}/Default`)
				}
				fetchPages();
			} else {
				await dbCall.delete(`/site/deleteSiteModal/${page.id}`);
				const modalName = await getAllModals()
				if (modalName.length > 0) {
					setPageName(modalName?.[0]?.ModalName)
					navigate(`/user/editor/${params?.siteId}/${modalName?.[0]?.ModalName}/true`)
				} else {
					setPageName("")
				}
			}
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!");
		} finally {
			setIsAnimating(false);
		}
	};

	const createDuplicateModal = async (e, pagename) => {
		e.stopPropagation();
		const newPageName = `${pagename}_${Math.floor(Math.random() * 8999 + 1000)}`;
		try {
			setIsAnimating(true);
			const response = await dbCall.get(`site/get-modal-data/${pagename}/${params.siteId}/${currentPlatform}`);
			const schema = response?.data?.data?.pageData.schema
			await dbCall
				.post("site/create-site-modal", {
					siteId: params.siteId,
					pageName: `${newPageName}`,
					schema: schema,
				})
				.then((response) => {
					setPageName(newPageName)
					navigate(`/user/editor/${params?.siteId}/${newPageName}/true`)
				})
				.catch((err) => console.log(err));
		} catch (error) {
			if (error.response) {
				if (error.response.status != 500) {
					toast.error(error.response.data.message);
				}
			}
		} finally {
			setIsAnimating(false)

		}
	};
	return (
		<>
			<Popover as="div" className="relative">
				<Popover.Button>
					<span className="mdi mdi-dots-vertical text-xl"></span>
				</Popover.Button>
				<Popover.Panel className={'absolute z-10 top-7 right-0'}>
					{
						({ close }) => (
							<>
								<div className=" mt-2 w-52 max-w-md transform overflow-hidden rounded border bg-white p-2 text-left align-middle shadow-xl transition-all">
									<div className="rounded p-2 text-[#757575] space-y-2">
										<button onClick={async (e) => {
											e.stopPropagation();
											if (isModal) {
												createDuplicateModal(e, page?.ModalName)
											} else {
												await createDuplicatePage();
											}
											close();
										}} className="flex items-center gap-1 hover:text-black w-full">
											<span className="mdi mdi-content-copy" /><span>Copy</span>
										</button>
										{
											!isModal && page.path != '/' && page.staticRoute == true && page.isAuthPage == false &&
											<>
												<button onClick={async (e) => {
													try {
														e.stopPropagation();
														await dbCall.put("/site/change-homepage", {
															id: page.id
														})
														close();
														fetchPages();
														toast.success('Homepage updated');
													} catch (error) {
														console.log(error);
														toast.error('Something went wrong!')
													}
												}} className="flex items-center gap-1 hover:text-black w-full">
													<span className="mdi mdi-home-outline" /><span>Set as Home Page</span>
												</button>
											</>
										}
										{
											page.path != '/' &&
											<button onClick={async (e) => {
												e.stopPropagation();
												setConfirmDelete(true);
											}} className="flex items-center gap-1 text-red-500 w-full">
												<span className="mdi mdi-delete" /><span>Delete</span>
											</button>
										}
									</div>
								</div>
								<Dialog onClose={() => setConfirmDelete(false)} open={confirmDelete} className="fixed inset-0  z-50  bg-gray-500 bg-opacity-75 flex items-center justify-center p-3">
									<Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5  shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6">
										<div className="sm:flex sm:items-start">
											<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
												<ExclamationTriangleIcon
													className="h-6 w-6 text-red-600"
													aria-hidden="true"
												/>
											</div>
											<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
												<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
													Delete {isModal ? "PopUp" : "Page"} : {isModal ? page.modalName : page.pageName}
												</Dialog.Title>
												<div className="mt-2">
													<p className="text-sm text-gray-500">
														Are you sure you want to Delete your {isModal ? "PopUp" : "Page"}? This action cannot be undone.
													</p>
												</div>
											</div>
										</div>
										<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
											<button
												type="button"
												className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
												onClick={() => {
													deletePage()
													setConfirmDelete(false)
												}}
											>
												Delete
											</button>
											<button
												type="button"
												className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
												onClick={() => setConfirmDelete(false)}
											>
												Cancel
											</button>
										</div>
									</Dialog.Panel>

								</Dialog>
							</>
						)
					}
				</Popover.Panel>
			</Popover>
		</>
	)
}

const PageSetting = ({ page, fetchPages }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [pageSettings, setPageSettings] = useState(null);
	const { pageLayoutChanged, setPageLayoutChanged } = useContext(context);
	function closeModal() {
		setIsOpen(false)
	}

	useEffect(() => {
		setPageSettings({
			isHeader: page.isHeader,
			isFooter: page.isFooter,
			path: page.path
		})
	}, []);
	return (
		<>
			<button
				type="button"
				onClick={(e) => {
					e.stopPropagation();
					setIsOpen(true);
				}}
			>
				<span class="mdi mdi-cog text-xl"></span>
			</button>

			<Transition show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-[1001]" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-60" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-xl transition-all">
									<div className="w-full divide-y">
										<div className="p-3 px-6 flex justify-between w-full">
											<span className="text-lg font-medium">Page Settings</span>
											<button type="button" onClick={closeModal}><span className="mdi mdi-close text-xl" /></button>
										</div>
										<div className="w-full divide-y">
											<div className="p-3 px-6 w-full">
												<span className="font-normal">Layout</span>
												<p className="text-sm text-[#757575] mt-1">Select whether you want to show header and footer in this page. </p>
												<div className="flex items-center justify-between mt-2">
													<span>Show header</span>
													<Switch
														checked={pageSettings?.isHeader}
														onChange={(checked) => {
															setPageSettings((prev) => {
																return {
																	...prev,
																	isHeader: checked
																}
															});
														}}
														className={`${pageSettings?.isHeader ? 'bg-indigo-600' : 'bg-[#757575]'}
			relative inline-flex h-[16px] w-[32px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
													>
														<span
															aria-hidden="true"
															className={`${pageSettings?.isHeader ? 'translate-x-4' : 'translate-x-0'}
			  pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
														/>
													</Switch>
												</div>
												<div className="flex items-center justify-between mt-2">
													<span>Show Footer</span>
													<Switch
														checked={pageSettings?.isFooter}
														onChange={(checked) => {
															setPageSettings((prev) => {
																return {
																	...prev,
																	isFooter: checked
																}
															});
														}}
														className={`${pageSettings?.isFooter ? 'bg-indigo-600' : 'bg-[#757575]'}
			relative inline-flex h-[16px] w-[32px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
													>
														<span
															aria-hidden="true"
															className={`${pageSettings?.isFooter ? 'translate-x-4' : 'translate-x-0'}
			  pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
														/>
													</Switch>
												</div>
											</div>
											{
												page?.staticRoute && page?.path != "/" &&
												<div div className="p-3 px-6 w-full">
													<span>Page URL</span>
													<span class="mdi mdi-information-outline ml-2 text-[#757575]"></span>
													<input value={pageSettings?.path}
														onChange={(e) => {
															setPageSettings((prev) => {
																return {
																	...prev,
																	path: e.target.value
																}
															});
														}} className={`border border-[#757575] text-sm px-1 w-full rounded flex items-center gap-4 justify-between mt-1`} placeholder="page-url" />
													<span className="text-xs text-[#757575]">https://yourdomain.com{pageSettings?.path}</span>
												</div>
											}
											<div className="mt-5 sm:mt-4 p-2 px-6 sm:flex sm:flex-row-reverse">
												<button
													type="button"
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
													onClick={async () => {
														try {
															if (page.path != '/' && pageSettings.path == '/') {
																toast.error("You cannot set path to this");
																return;
															}
															await dbCall.put(`/site/update-page/${page.id}`, {
																isHeader: pageSettings?.isHeader,
																isFooter: pageSettings?.isFooter,
																path: pageSettings?.path
															})
															setPageLayoutChanged(!pageLayoutChanged)
															fetchPages();
															closeModal();
														} catch (e) {
															toast.error("Something went wrong!")
														}
													}}
												>
													Done
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
													onClick={() => {
														setPageSettings({
															isHeader: page.isHeader,
															isFooter: page.isFooter,
															path: page.path
														})
														closeModal()
													}}
												>
													Cancel
												</button>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition >
		</>
	)
}

const PageSEOSetting = ({ page, siteId, setRedirectMethod, redirectMethod, setSetting, setting, pages }) => {
	let [isOpen, setIsOpen] = useState(false);
	let [enabled, setEnabled] = useState(false);
	let [searchIndex, setSearchIndex] = useState(true);
	const [seoSite, setSeoSite] = useState({});
	const [robotMeta, setRobotMeta] = useState({});
	const [sameTitle, setSameTitle] = useState(false);
	const [sameDescription, setSameDiscription] = useState(false);
	const [xsameTitle, setXSameTitle] = useState(false);
	const [xsameDescription, setXSameDiscription] = useState(false);
	const [sameX, setSameX] = useState(false);
	const { setIsAnimating } = useContext(context);
	const [reupdate, setReupdate] = useState(false);
	const [preview, setPreview] = useState('')
	const [previewx, setPreviewx] = useState('')
	useEffect(() => {
		const getPageSEOData = async () => {
			await dbCall.get(`site/seo-page-data/${page?.id}`)
				.then((response) => {
					if (response.data.message == "page seo data does not exists") {
						setSeoSite({
							id: 0,
							title: "",
							description: "",
							keywords: "",
							metaType: "",
							metaTitle: "",
							metaDescription: "",

						});
					} else {
						setSeoSite({ ...response.data.data });
						if (response.data.data?.meta_data?.logo)
							setPreview('https://usercdn.qafto.com/' + seoSite?.meta_data?.logo)
						if (response.data.data?.xmeta_data?.logo)
							setPreviewx('https://usercdn.qafto.com/' + seoSite?.xmeta_data?.logo)
						setSearchIndex(response.data.data.searchIndex);
						setRobotMeta({ ...response?.data?.data?.robotMetaTag })
					}

				}).catch((error) => {
					if (error.response) {
						if (error.response.status != 500) {
							toast.error(error.response.data.message);
						}
						else {
							// toast.error("Something went wrong");
						}
					} else {
						// toast.error('Something went wrong');
					}
				})
		};
		getPageSEOData();
	}, [page?.id, isOpen, reupdate])

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}


	const [initialValuesX, setInitialValuesX] = useState({
		id: seoSite.id ?? 0,
		title: seoSite?.xmeta_data?.title ?? '',
		description: seoSite?.xmeta_data?.description ?? '',
		logo: seoSite?.xmeta_data?.logo ?? '',
		cardSize: seoSite?.xmeta_data?.cardSize ?? 'large',
	});

	useEffect(() => {
		if (sameX) {
			setInitialValuesX((prevValues) => ({
				...prevValues,
				title: seoSite?.meta_data?.title ?? '',
				description: seoSite?.meta_data?.description ?? '',
				logo: seoSite?.meta_data?.logo ?? '',
			}));
			setPreviewx(preview ?? null)
		} else {
			setInitialValuesX({
				id: seoSite.id ?? 0,
				title: seoSite?.xmeta_data?.title ?? '',
				description: seoSite?.xmeta_data?.description ?? '',
				logo: seoSite?.xmeta_data?.logo ?? '',
				cardSize: seoSite?.xmeta_data?.cardSize ?? 'large',
			});
			setPreviewx(seoSite?.meta_data?.logo ? 'https://usercdn.qafto.com/' + seoSite?.xmeta_data?.logo : null)
		}
	}, [sameX, seoSite]);

	return (
		<>
			<button
				type="button"
				onClick={(e) => {
					e.stopPropagation();
					openModal()
				}}
			>
				<img src="https://img.icons8.com/ios-filled/50/757575/window-search.png" className="h-5 w-5" alt="SEO" />
			</button>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-[1001] text-[#373C47]" onClick={(e) => e.stopPropagation()} onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-60" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-xl transition-all">
									<div className="w-full divide-y-2">
										<div className="p-3 px-6 flex justify-between w-full">
											<span className="text-lg font-medium">Page SEO</span>
											<button type="button" onClick={closeModal}><span className="mdi mdi-close text-xl" /></button>
										</div>
										<Disclosure as={'div'}>
											{({ open }) => (
												<>
													<Disclosure.Button className="flex w-full justify-between bg-[#D9D9D9] px-6 py-2 text-left text-sm font-medium ">
														<span>SEO setting</span>
														<ChevronUpIcon
															className={`${open ? 'rotate-180 transform' : ''
																} h-6 w-6`}
														/>
													</Disclosure.Button>
													<Disclosure.Panel className="px-6 pb-2 pt-4 ">
														<Formik
															enableReinitialize
															initialValues={{
																id: seoSite.id,
																title: seoSite.title,
																description: seoSite.description,
																keywords: seoSite.keywords,
																includeBusinessName: seoSite.includeBusinessName
															}}
															validationSchema={
																yup.object().shape({
																	title: yup.string().max(70, 'Title must not exceed 70 characters'),
																	description: yup.string().required().max(145, 'Description must not exceed 145 characters'),
																	keywords: yup.string().required()
																		.test('is-valid-keywords', 'Invalid format for keywords', (value) => {
																			if (value) {
																				return /^(?:\s*[\w-]+\s*,\s*)*[\w-]+\s*$/.test(value);
																			}
																			return true;
																		})
																		.max(100, 'Keywords must not exceed 100 characters'),
																})
															}
															onSubmit={async (values, { setSubmitting }) => {
																setSubmitting(true);
																setIsAnimating(true);
																await dbCall.post('site/update-seo-page-data', { pageId: page?.id, ...values })
																	.then((response) => {
																		// setSeoSite(response.data)
																		toast.success('Updated');
																		setReupdate(!reupdate);
																	}).catch((error) => {
																		if (error.response) {
																			if (error.response.status != 500) {
																				toast.error(error.response.data.message);
																			}
																			else {
																				toast.error('Something went wrong')
																			}
																		} else {
																			toast.error('something went wrong')
																		}
																	}).finally(() => {
																		setSubmitting(false);
																		setIsAnimating(false);
																	})

															}}
														>
															{({ isSubmitting, dirty, errors, values }) => (
																<Form className="divide-y space-y-3">
																	<span className="text-[#373C47]">
																		It takes time before changes you make to your page title and description are updated on Google.
																	</span>
																	<div className="pt-3 text-[#373C47]">
																		<span>Preview on search engines</span>
																		<span className="mdi mdi-information-outline ml-2" />
																		<div className="mt-2 p-2 w-full border rounded flex flex-col">
																			<span className="text-[#4F1E9F]">{values?.title && values?.title?.length > 0 ? values?.title : 'Home'}</span>
																			<span className="text-[#008000] text-sm">https://yourdomain.com/{values?.title && values?.title?.length > 0 ? values?.title : 'Home'}</span>
																			<div className="border-b w-full my-2" />
																			<span className="text-xs text-[#757575]">{values?.description && values?.description?.length > 0 ? values?.description : 'Search engines will create a default description for your page, but to override that description, type your own below. This is what viewers see in search result...'}</span>
																		</div>
																	</div>
																	<div className="w-full">
																		<div className="mt-2">
																			<span>Title Tag</span>
																			<span className="mdi mdi-information-outline text-[#757575] ml-2" />
																		</div>
																		<Field
																			name="title"
																			className="border border-[#757575] text-sm px-2 py-1 w-full rounded flex items-center gap-4 justify-between mt-1"
																			placeholder="Home"
																		/>
																		<ErrorMessage name="title" component="div" className="text-red-500 text-xs" />
																		<div className="flex items-center gap-2 ml-6 mt-2">
																			<Field type="checkbox" name="includeBusinessName" className="w-3 h-3 focus:ring-0 rounded-sm" />
																			<span className="text-sm">Include your Business name in the page title</span>
																		</div>
																	</div>
																	<div className="w-full">
																		<div className="mt-2">
																			<span>Meta Description</span>
																			<span className="mdi mdi-information-outline text-[#757575] ml-2" />
																		</div>
																		<Field
																			as="textarea"
																			name="description"
																			rows={3}
																			className="border border-[#757575] text-sm px-2 py-1 w-full rounded flex items-center gap-4 justify-between mt-1 placeholder:text-[#757575]"
																			placeholder="Search engines may show a different description"
																		/>
																		<ErrorMessage name="description" component="div" className="text-red-500 text-xs" />
																	</div>
																	<div className="w-full">
																		<div className="mt-2">
																			<span>Meta Keywords (Optional)</span>
																			<span className="mdi mdi-information-outline text-[#757575] ml-2" />
																		</div>
																		<Field
																			as="textarea"
																			name="keywords"
																			rows={3}
																			className="border border-[#757575] text-sm px-2 py-1 w-full rounded flex items-center gap-4 justify-between mt-1 placeholder-[#757575]::placeholder"
																			placeholder="Example: Keyword 1, Keyword 2, Keyword 3, etc"
																		/>
																		<span className="text-xs text-[#757575]">Enter keyword by seperating with commas</span>
																		<ErrorMessage name="keywords" component="div" className="text-red-500 text-xs" />
																	</div>
																	{dirty && <div className="mt-5 sm:mt-4 p-2 sm:flex sm:flex-row-reverse">
																		<button
																			type="submit"
																			className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
																		>
																			Save
																		</button>
																		<button
																			type="reset"
																			disabled={isSubmitting}
																			className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
																		>
																			Discard
																		</button>
																	</div>}
																</Form>
															)}
														</Formik>
													</Disclosure.Panel>
												</>
											)}
										</Disclosure>
										<Disclosure as="div" className="">
											{({ open }) => (
												<>
													<Disclosure.Button className="flex w-full justify-between bg-[#D9D9D9] px-6 py-2 text-left text-sm font-medium ">
														<span>Social share settings</span>
														<ChevronUpIcon
															className={`${open ? 'rotate-180 transform' : ''
																} h-6 w-6`}
														/>
													</Disclosure.Button>
													<Disclosure.Panel className="px-6 pb-2 pt-4 ">
														<div className="divide-y space-y-3">
															<span className="text-[#373C47]">
																Open graph (og) tags are used by social networks like Facebook
																& Pinterest to display text and an image when the page is shared.
															</span>
															<Formik
																enableReinitialize
																initialValues={{
																	id: seoSite.id ?? 0,
																	title: seoSite.meta_data?.title || '',
																	description: seoSite.meta_data?.description || '',
																	logo: seoSite?.meta_data?.logo,
																}}
																validationSchema={
																	yup.object().shape({
																		title: yup.string().required().max(70, 'Title must not exceed 70 characters'),
																		description: yup.string().required('Description is required').max(145, 'Description must not exceed 145 characters'),
																		logo: yup.mixed()
																			.test('fileSize', 'Image size must be 200kb max', (value) => {
																				if (!value || value?.length > 0) return true; // Accept empty value
																				return value?.length > 0 ? false : value.size <= 200000; // 200000 bytes = 200KB
																			})
																			.test('fileType', 'Unsupported file format', (value) => {
																				if (!value || value?.length > 0) return true; // Accept empty value
																				return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
																			}),
																	})}
																onSubmit={async (values, { setSubmitting }) => {
																	const formData = new FormData();
																	formData.append('pageId', page?.id);
																	formData.append('id', values.id)
																	formData.append('title', values.title);
																	formData.append('description', values.description);
																	formData.append('siteId', siteId)
																	if (values?.logo) {
																		formData.append('logo', values.logo);
																	}
																	try {
																		setIsAnimating(true)
																		await dbCall.post('site/update-og-seo-page-data', formData, {
																			headers: {
																				'Content-Type': 'multipart/form-data',
																			},
																		});
																		toast.success('Updated');
																		setReupdate(!reupdate);
																	} catch (error) {
																		toast.error("Something went wrong")
																	} finally {
																		setSubmitting(false);
																		setIsAnimating(false)
																	}
																}}
															>
																{({ isSubmitting, setFieldValue, values, setFieldTouched, dirty, errors }) => {
																	const handleFileChange = (event) => {
																		event.preventDefault();
																		const file = event.target.files[0];

																		if (file?.size) {
																			setFieldValue("logo", file);
																			setFieldTouched("logo", true);
																			const reader = new FileReader();
																			reader?.readAsDataURL(file);
																			reader.onload = () => {
																				setPreview(reader.result);
																			};
																		}
																	};

																	// Function to handle title checkbox change
																	const handleTitleCheckboxChange = () => {
																		const newValue = !sameTitle;
																		setSameTitle(newValue);

																		if (newValue) {
																			setFieldValue('title', seoSite?.title ?? '');
																		} else {
																			setFieldValue('title', seoSite?.meta_data?.title ?? '');
																		}
																	};

																	// Function to handle description checkbox change
																	const handleDescriptionCheckboxChange = () => {
																		const newValue = !sameDescription;
																		setSameDiscription(newValue);

																		if (newValue) {
																			setFieldValue('description', seoSite?.description ?? '');
																		} else {
																			setFieldValue('description', seoSite?.meta_data?.description ?? '');
																		}
																	};

																	return (
																		<Form className="divide-y space-y-3">
																			<div className="pt-3 text-[#373C47]">
																				<span>Preview on search engines</span>
																				<span className="mdi mdi-information-outline ml-2" />
																				<div className="border rounded-sm p-4 mt-2">
																					<div className="relative flex items-center justify-center border h-[120px] w-full">
																						{values?.logo ? (
																							<img
																								src={preview}
																								className="max-w-full w-full max-h-[120px] self-center rounded-md object-fill"
																								alt="upload"
																							/>
																						) : (
																							<span className="mdi mdi-cloud-upload text-7xl mx-auto text-[#D9D9D9]"></span>
																						)}
																					</div>
																					<div>
																						<span>{values?.title && values?.title?.length > 0 ? values?.title : 'Home'}</span>
																						<p className="text-xs text-[#757575]">
																							{values?.description && values?.description?.length > 0 ? values?.description : 'Search engines will create a default description for your page, but to override that description, type your own below. This is what viewers see in search result...'}																						</p>
																						<span className="text-sm">https://yourdomain.com/{values?.title && values?.title?.length > 0 ? values?.title : 'Home'}</span>
																					</div>
																				</div>
																			</div>
																			<div className="pt-3 text-[#373C47]">
																				<span>og:Image (image on social media)</span>
																				<div className="relative flex flex-col items-center justify-center border h-[120px] w-full mt-2">
																					<div className="px-2 py-0.5 w-fit mx-auto border border-[#1E429F] text-[#1E429F] text-sm rounded-full">
																						<span className="mdi mdi-tray-arrow-up text-[#1E429F]"></span> Upload Image
																					</div>
																					<div><span className="text-sm">Image must be PNG or JPG</span></div>
																					<label htmlFor="file-upload" className="absolute inset-0 cursor-pointer">
																						<span className="sr-only">Upload Image</span>
																					</label>
																					<input
																						id="file-upload"
																						name="logo"
																						type="file"
																						onChange={handleFileChange}
																						multiple={false}
																						accept="image/*"
																						className="sr-only"
																					/>
																					<ErrorMessage name="logo" component="div" className="text-red-500 text-xs" />
																				</div>
																				<div className="mt-2">
																					<span>og:title (title on social media)</span>
																					<span className="mdi mdi-information-outline ml-2" />
																					<Field
																						type="text"
																						name="title"
																						value={sameTitle ? seoSite?.title : values?.title}
																						disabled={sameTitle}
																						className="border-[#757575] placeholder:text-[#757575] text-sm px-2 py-0.5 w-full rounded flex items-center gap-4 justify-between mt-1"
																						placeholder="Home"
																					/>
																					<ErrorMessage name="title" component="div" className="text-red-500 text-xs" />
																					<div className="flex items-center gap-2 ml-6 mt-2">
																						<input
																							type="checkbox"
																							value={sameTitle}
																							onChange={handleTitleCheckboxChange}
																							className="w-3 h-3 focus:ring-0 rounded-sm"
																						/>
																						<span className="text-sm">Same as SEO Title Tag</span>
																					</div>
																				</div>
																				<div className="mt-2">
																					<span>og:description (description on social media)</span>
																					<span className="mdi mdi-information-outline ml-2" />
																					<Field
																						as="textarea"
																						name="description"
																						value={sameDescription ? seoSite?.description : values?.description}
																						disabled={sameDescription}
																						rows={2}
																						className="border-[#757575] placeholder:text-[#757575] text-sm px-2 py-0.5 w-full rounded flex items-center gap-4 justify-between mt-1"
																						placeholder="Search engines may show a different description"
																					/>
																					<ErrorMessage name="description" component="div" className="text-red-500 text-xs" />
																					<div className="flex items-center gap-2 ml-6 mt-2">
																						<input
																							type="checkbox"
																							value={sameDescription}
																							onChange={handleDescriptionCheckboxChange}
																							className="w-3 h-3 focus:ring-0 rounded-sm"
																						/>
																						<span className="text-sm">Same as SEO Description</span>
																					</div>
																				</div>
																			</div>
																			{dirty && <div className="mt-5 sm:mt-4 p-2 sm:flex sm:flex-row-reverse">
																				<button
																					type="submit"
																					className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
																				>
																					Save
																				</button>
																				<button
																					type="reset"
																					disabled={isSubmitting}
																					className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
																				>
																					Discard
																				</button>
																			</div>}
																		</Form>
																	)
																}}
															</Formik>
															<Disclosure as={'div'} className='mt-4'>
																{({ open }) => (
																	<>
																		<Disclosure.Button className="flex w-full justify-between bg-[#D9D9D9] px-6 py-2 text-left text-sm font-medium ">
																			<span>X setting</span>
																			<ChevronUpIcon
																				className={`${open ? 'rotate-180 transform' : ''
																					} h-6 w-6`}
																			/>
																		</Disclosure.Button>
																		<Disclosure.Panel className="w-full divide-y space-y-3">
																			<Formik
																				enableReinitialize
																				initialValues={initialValuesX}
																				validationSchema={
																					yup.object().shape({
																						title: yup.string().required().max(70, 'Title must not exceed 70 characters'),
																						description: yup.string().required('Description is required').max(145, 'Description must not exceed 145 characters'),
																						logo: yup.mixed()
																							.test('fileSize', 'Image size must be 200kb max', (value) => {
																								if (!value || value?.length > 0) return true; // Accept empty value
																								return value?.length > 0 ? false : value.size <= 200000; // 200000 bytes = 200KB
																							})
																							.test('fileType', 'Unsupported file format', (value) => {
																								if (!value || value?.length > 0) return true; // Accept empty value
																								return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
																							}),
																					})}
																				onSubmit={async (values, { setSubmitting }) => {
																					const formData = new FormData();
																					formData.append('pageId', page?.id);
																					formData.append('id', values.id)
																					formData.append('title', values.title);
																					formData.append('description', values.description);
																					formData.append('siteId', siteId);
																					formData.append('cardSize', values?.cardSize)
																					if (values?.logo) {
																						formData.append('logo', values.logo);
																					}
																					try {
																						setIsAnimating(true)
																						await dbCall.post('site/update-x-seo-page-data', formData, {
																							headers: {
																								'Content-Type': 'multipart/form-data',
																							},
																						});
																						toast.success('Updated');
																						setReupdate(!reupdate);
																					} catch (error) {
																						toast.error("Something went wrong")
																					} finally {
																						setSubmitting(false);
																						setIsAnimating(false)
																					}
																				}}
																			>
																				{({ setFieldValue, setFieldTouched, values, dirty, isSubmitting }) => {
																					const handleFileChange = (event) => {
																						event.preventDefault();
																						const file = event.target.files[0];

																						if (file?.size) {
																							setFieldValue("logo", file);
																							setFieldTouched("logo", true);
																							const reader = new FileReader();
																							reader?.readAsDataURL(file);
																							reader.onload = () => {
																								setPreviewx(reader.result);
																							};
																						}
																					};

																					// Function to handle title checkbox change
																					const handleTitleCheckboxChange = () => {
																						const newValue = !sameTitle;
																						setSameTitle(newValue);

																						if (newValue) {
																							setFieldValue('title', seoSite?.meta_data?.title ?? '');
																						} else {
																							setFieldValue('title', seoSite?.meta_data?.title ?? '');
																						}
																					};

																					// Function to handle description checkbox change
																					const handleDescriptionCheckboxChange = () => {
																						const newValue = !sameDescription;
																						setSameDiscription(newValue);

																						if (newValue) {
																							setFieldValue('description', seoSite?.meta_data?.description);
																						} else {
																							setFieldValue('description', seoSite?.xmeta_data?.description ?? '');
																						}
																					};
																					return (
																						<Form>
																							<div className="flex items-center gap-2 ml-6 mt-2">
																								<input
																									onClick={() => setSameX(!sameX)}
																									type="checkbox"
																									className="w-3 h-3 focus:ring-0 rounded-sm"
																								/>
																								<span className="text-sm">Same as above</span>
																							</div>
																							<div className="pt-2">
																								<span>Preview on X</span>
																								<span className="mdi mdi-information-outline ml-2" />
																								<div className="border rounded-sm p-4 mt-2">
																									<div className="relative flex items-center justify-center border h-[120px] w-full">
																										{(previewx || sameX) ? (
																											<img
																												src={sameX ? preview : previewx}
																												className="max-w-full w-full max-h-[120px] self-center rounded-md object-fill"
																												alt="upload"
																											/>
																										) : (
																											<span className="mdi mdi-cloud-upload text-7xl mx-auto text-[#D9D9D9]"></span>
																										)}
																									</div>
																									<div>
																										<span>{values?.title && values?.title?.length > 0 ? values?.title : 'Home'}</span>
																										<p className="text-xs text-[#757575]">
																											{values?.description && values?.description?.length > 0 ? values?.description : 'Search engines will create a default description for your page, but to override that description, type your own below. This is what viewers see in search result...'}																						</p>
																										<span className="text-sm">https://yourdomain.com/{values?.title && values?.title?.length > 0 ? values?.title : 'Home'}</span>
																									</div>
																								</div>
																							</div>
																							<div className="pt-2">
																								<span>Card size to display on X</span>
																								<span className="mdi mdi-information-outline ml-2" />
																								<div className="flex items-center gap-6 mt-2">
																									<div className="flex items-center">
																										<Field
																											id="large-card-size"
																											type="radio"
																											name="cardSize"
																											value="large"
																											className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
																										/>
																										<label htmlFor="large-card-size" className="ms-2 text-sm font-medium text-gray-900">
																											Large
																										</label>
																									</div>
																									<div className="flex items-center">
																										<Field
																											id="small-card-size"
																											type="radio"
																											name="cardSize"
																											value="small"
																											className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
																										/>
																										<label htmlFor="small-card-size" className="ms-2 text-sm font-medium text-gray-900">
																											Small
																										</label>
																									</div>
																								</div>
																							</div>
																							<div className="pt-2">
																								<span>x:Image (image on X)</span>
																								<span className="mdi mdi-information-outline ml-2" />
																								<div className="relative flex flex-col items-center justify-center border h-[120px] w-full mt-2">
																									<div className="px-2 py-0.5 w-fit mx-auto border border-[#1E429F] text-[#1E429F] text-sm rounded-full">
																										<span className="mdi mdi-tray-arrow-up text-[#1E429F]"></span> Upload Image
																									</div>
																									<div><span className="text-sm">Image must be PNG or JPG</span></div>
																									<label htmlFor="file-upload11" className="absolute inset-0 cursor-pointer">
																										<span className="sr-only">Upload Image</span>
																									</label>
																									<input
																										id="file-upload11"
																										name="logo"
																										type="file"
																										onChange={handleFileChange}
																										multiple={false}
																										accept="image/*"
																										className="sr-only"
																									/>
																									<ErrorMessage name="logo" component="div" className="text-red-500 text-xs" />
																								</div>
																								<div className="mt-2">
																									<span>x: title (title on X)</span>
																									<span className="mdi mdi-information-outline ml-2" />
																									<Field
																										name="title"
																										className="border-[#757575] placeholder:text-[#757575] text-sm px-2 py-0.5 w-full rounded flex items-center gap-4 justify-between mt-1"
																										placeholder="Home"
																									/>
																									<ErrorMessage name="title" component="div" className="text-red-500 text-xs" />
																									<div className="flex items-center gap-2 ml-6 mt-2">
																										<input type="checkbox" onChange={handleTitleCheckboxChange} className="w-3 h-3 focus:ring-0 rounded-sm" />
																										<span className="text-sm">Same as SEO Title Tag</span>
																									</div>
																								</div>
																								<div className="mt-2">
																									<span>x: description (description on X)</span>
																									<span className="mdi mdi-information-outline ml-2" />
																									<Field
																										name="description"
																										className="border-[#757575] placeholder:text-[#757575] text-sm px-2 py-0.5 w-full rounded flex items-center gap-4 justify-between mt-1"
																										placeholder="Search engines may show a different description"
																									/>
																									<ErrorMessage name="description" component="div" className="text-red-500 text-xs" />
																									<div className="flex items-center gap-2 ml-6 mt-2">
																										<input type="checkbox" onChange={handleDescriptionCheckboxChange} className="w-3 h-3 focus:ring-0 rounded-sm" />
																										<span className="text-sm">Same as SEO Description</span>
																									</div>
																								</div>
																							</div>
																							{(dirty || sameX) && <div className="mt-5 sm:mt-4 p-2 sm:flex sm:flex-row-reverse">
																								<button
																									type="submit"
																									className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
																								>
																									Save
																								</button>
																								<button
																									type="reset"
																									disabled={isSubmitting}
																									className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
																								>
																									Discard
																								</button>
																							</div>}
																						</Form>
																					)
																				}}
																			</Formik>

																		</Disclosure.Panel>
																	</>)}
															</Disclosure>
															<div>
															</div>
														</div>
													</Disclosure.Panel>
												</>
											)}
										</Disclosure>
										<Disclosure as="div" className="">
											{({ open }) => (
												<>
													<Disclosure.Button className="flex w-full justify-between bg-[#D9D9D9] px-6 py-2 text-left text-sm font-medium ">
														<span>Search Visibility</span>
														<ChevronUpIcon
															className={`${open ? 'rotate-180 transform' : ''
																} h-6 w-6`}
														/>
													</Disclosure.Button>
													<Disclosure.Panel className="px-6 pb-2 pt-4 ">
														<div className="space-y-2">
															<div className="flex items-center justify-between">
																<span>Let search engines index this page</span>
																<Switch
																	checked={searchIndex}
																	onChange={() => {
																		try {
																			async function fetch() {
																				await dbCall.post('site/update-search-index-seo-page-data', { id: seoSite?.id, searchIndex: !searchIndex })
																			}
																			fetch();
																			setSearchIndex(!searchIndex);
																			toast.success('updated');
																		} catch (error) { toast.error("Something went wrong") }
																	}}
																	className={`${searchIndex ? 'bg-indigo-600' : 'bg-[#757575]'}
			relative inline-flex h-[16px] w-[32px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
																>
																	<span className="sr-only">Use setting</span>
																	<span
																		aria-hidden="true"
																		className={`${searchIndex ? 'translate-x-4' : 'translate-x-0'}
			  pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
																	/>
																</Switch>
															</div>
															<div><span>Allow your page to be indexed by Search Engines.</span></div>
														</div>
													</Disclosure.Panel>
												</>)}
										</Disclosure>
										<Disclosure as="div" className="">
											{({ open }) => (
												<>
													<Disclosure.Button className="flex w-full justify-between bg-[#D9D9D9] px-6 py-2 text-left text-sm font-medium ">
														<span>Redirect Page</span>
														<ChevronUpIcon
															className={`${open ? 'rotate-180 transform' : ''
																} h-6 w-6`}
														/>
													</Disclosure.Button>
													<Disclosure.Panel className="px-6 pb-2 pt-4 ">
														<div className="space-y-2">
															<div className="ml-4">
																<div className="w-full ">
																	<p className=" text-gray-700">
																		Redirect Page to Another page :
																	</p>
																	{
																		!redirectMethod &&
																		<select className="rounded-lg mt-2"
																			onChange={(e) => {
																				setSetting({
																					...setting,
																					redirectionPath: e.target.value
																				})
																			}}
																		>
																			{
																				pages?.map((page, i) => (
																					<option key={i}
																						selected={setting?.redirectionPath == page?.pageName ? true : false}
																					>
																						{page?.pageName}
																					</option>
																				))
																			}
																			<option
																				selected={(!setting?.redirectionPath || setting?.redirectionPath?.length == 0) ? true : false}
																			>
																				None
																			</option>
																		</select>
																	}
																	{/* <p>or</p> */}
																	<div class="flex items-center mt-2">
																		<label class="relative flex items-center rounded-full cursor-pointer" htmlFor="check">
																			<input type="checkbox"
																				class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
																				id="check"
																				onChange={(e) => {
																					setRedirectMethod(e.target.checked)
																				}}
																			/>
																		</label>
																		<label class="mt-px font-light text-gray-700 cursor-pointer select-none ml-2" htmlFor="check">
																			Redirect To Another Domain Page
																		</label>
																	</div>

																	{
																		redirectMethod &&
																		<input type="text" className="mt-2 rounded-lg w-full ml-2"
																			placeholder="ex: https://example.com/page1"
																			onChange={(e) => {
																				let redirectUrl = e?.target?.value
																				const url = redirectUrl?.match(/^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
																				if (url == null) {
																					redirectUrl = `https://${e.target.value}`
																				}
																				setSetting({
																					...setting,
																					redirectionPath: redirectUrl
																				})
																			}}
																		/>
																	}
																</div>
															</div>
															<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
																<button
																	type="button"
																	className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
																	onClick={async () => {
																		try {
																			await dbCall.put("/site/update-page-layout-status", {
																				id: page?.id,
																				path: setting?.path,
																				defaultPage: setting?.defaultPage,
																				previousPath: setting?.previousPath,
																				redirectionPath: setting?.redirectionPath
																			})
																			setSetting({ ...setting, settingOpen: false })
																			toast.success('updated')
																		} catch (e) {
																			toast.error('Something went wrong')
																		}
																	}}
																>
																	Done
																</button>
																<button
																	type="button"
																	className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
																	onClick={() => setSetting({ ...setting, settingOpen: false })}
																>
																	Cancel
																</button>
															</div>
														</div>
													</Disclosure.Panel>
												</>)}
										</Disclosure>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition >
		</>
	)
}