import { Fragment, useState, useEffect, useContext, useRef } from 'react'
import { Outlet, Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Dialog, Listbox, Transition, Menu } from '@headlessui/react';
import { Field, Form, Formik } from 'formik';
import {
	CursorArrowRaysIcon,
	DocumentDuplicateIcon,
	HandRaisedIcon,
	MinusIcon,
	PlusIcon,
	XMarkIcon,
	ArrowsPointingInIcon,
	DocumentPlusIcon,
	WrenchScrewdriverIcon
} from '@heroicons/react/24/outline';
import { ComputerDesktopIcon, DeviceTabletIcon, DevicePhoneMobileIcon, EyeIcon } from '@heroicons/react/24/solid'
import {
	ChevronUpIcon,
	ChevronDownIcon,
} from '@heroicons/react/20/solid';
import {
	IconAlignBoxCenterMiddle,
	IconSettings,
	IconSwitch3
} from '@tabler/icons-react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Elements } from './../components/editor/leftSidebar/elements';
import { Theme } from '../components/editor/leftSidebar/theme';
import { Media } from '../components/editor/leftSidebar/media';
import Pages from '../components/editor/leftSidebar/pages';
import { context } from '../context/Context';
import { dbCall } from '../common/db';
import RightSideBar from '../components/editor/rightSideBar/RightSideBar';

// form
import { uiSchema, strapiFormSchemas } from '../screens/account/editor/form/FormSchemas';
import { findFormikComponent } from '../screens/account/editor/form/FormComponents';

import { changeId, randomString } from '../libraries/utility';
import Tooltip, { ImageSettingModal } from './tooltip';
import { IconArrowsExchange } from '@tabler/icons-react';
import { AddToUndo } from '../screens/account/editor';
import Layers from './layers';

const breakpoints = [
	{
		classPrefix: "",
		value: "0px",
		name: "base",
		displayName: "0 & above",
		width: "350px"
	},
	{
		classPrefix: "@xs:",
		value: "420px",
		name: "xs",
		displayName: "420 & above",
		width: "420px"

	},
	{
		classPrefix: "@sm:",
		value: "640px",
		name: "sm",
		displayName: "640 & above",
		width: "640px"
	},
	{
		classPrefix: "@md:",
		value: "768px",
		name: "md",
		displayName: "768 & above",
		width: "768px"
	},
	{
		classPrefix: "@lg:",
		value: "1024px",
		name: "lg",
		displayName: "1024 & above",
		width: "1024px"
	},
	{
		classPrefix: "@xl:",
		value: "1280px",
		name: "xl",
		displayName: "1280 & above",
		width: "1280px"
	},
	{
		classPrefix: "@2xl:",
		value: "1536px",
		name: "twoXl",
		displayName: "1536 & above",
		width: "1536px"
	},
];

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}
let valueX = 0
let valueY = 0
let pointX = 0;
let pointY = 0;
let scale = 1

const devices = [
	{
		icon: ComputerDesktopIcon,
		width: "100%",
		title: "Desktop View"
	},
	{
		icon: DeviceTabletIcon,
		width: "768px",
		title: "Tablet View"
	},
	{
		icon: DevicePhoneMobileIcon,
		width: "350px",
		title: "Mobile View"
	}
]


let myContext = {}

function debounce(func, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}
function saveInput() {
	const {
		coordinatesRef,
		setOnHoverCoordinates,
		cordinates,
		setCordinates,
		elementId
	} = myContext
	const deepdive2 = document.getElementById("deepdive2")
	const deepdive3 = document.getElementById("deepdive3")
	const onHoverCordinatesValue = document.getElementById(coordinatesRef?.current?.id)?.getBoundingClientRect()
	const onHoverData = {
		...coordinatesRef?.current,
		cordinatesValue: onHoverCordinatesValue,
		id: coordinatesRef?.current?.id
	}

	setOnHoverCoordinates({ ...onHoverData });
	deepdive2?.style && (deepdive2.style.opacity = 1)
	deepdive3?.style && (deepdive3.style.opacity = 1)
	// }

	if (elementId?.content) {
		const tooltipDiv = document.getElementById("tooltipDiv")
		const cordinatesValue = document.getElementById(cordinates?.id)?.getBoundingClientRect()
		const data = {
			cordinatesValue,
			id: cordinates?.id,
			isCustom: cordinates?.isCustom,
			index: cordinates?.index,
			componentName: cordinates?.componentName
		}
		setCordinates({ ...data });
		tooltipDiv?.style && (tooltipDiv.style.opacity = 1)
	}

	const deepdive = document.getElementById("deepdive")
	deepdive?.style && (deepdive.style.transform = "translate(" + 0 + "px," + 0 + "px)")
	const cordinatesValue = document.getElementById(cordinates?.id)?.getBoundingClientRect()
	const data = {
		cordinatesValue,
		id: cordinates?.id,
		isCustom: cordinates?.isCustom,
		index: cordinates?.index,
		componentName: cordinates?.componentName
	}
	setCordinates({ ...data });
	deepdive?.style && (deepdive.style.opacity = 1)

}

export const processChange = debounce(() => saveInput());
export const saveProject = async ({ layout, blocks, content, pageId, selectedSiteData, sqliteSchema, strapiSchema, params }) => {
	try {
		let header;
		let footer;
		if (params.modalName) {
			await dbCall.put("/site/update-site-modal", {
				id: pageId,
				schema: blocks,
				isSchema: true
			});
		} else {
			const pagename_deep_copy = JSON.parse(JSON.stringify(blocks))
			if (layout.header || layout.footer) {
				for (let i = 0; i < pagename_deep_copy?.length; i++) {
					if (pagename_deep_copy[i]?.layout == "header" || pagename_deep_copy[i]?.layout == "footer") {
						if (pagename_deep_copy?.[i]?.layout == "header") {
							header = pagename_deep_copy[i];
						} else if (pagename_deep_copy?.[i]?.layout == "footer") {
							footer = pagename_deep_copy[i];
						}
						pagename_deep_copy.splice(i, 1)
					}
				}
			}
			await dbCall.put(`/site/save-project/${pageId}`, {
				schema: pagename_deep_copy,
				content: content,
				header: header,
				footer: footer,
				backendSchema: selectedSiteData.static ? sqliteSchema : strapiSchema
			});
		}

	} catch (error) {
		throw error
	}
}

// let zoomdivHeight = 7436.828125
export default function NewEditorLayout() {
	const [pages, setPages] = useState([]);
	let [isOpen, setIsOpen] = useState(false)
	const [zoomdivHeight, setZoomDivHeight] = useState({
		height: "auto"
	});
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [showCopyButton, setShowCopyButton] = useState(false);

	const {
		elementRef,
		pageName,
		blocks,
		grab,
		setGrab,
		setIsDrag,
		publish,
		setPublish,
		cordinates,
		setCordinates,
		elementId,
		Bdispatch,
		formElementRef,
		setCurrentTheme,
		setSelectedSiteData,
		setAddNewFormElementCordinates,
		formSelected,
		formId,
		strapiDispatch,
		selectedFormElementId,
		setFormSelected,
		setFormName,
		setSelectedFormElementId,
		setAdminURL,
		setAdminToken,
		componentRef,
		undo,
		setUndo,
		redo,
		setRedo,
		setIsAnimating,
		selectedSiteData,
		sqliteDispatch,
		currentLeftSidebarSectionTAB,
		currentPlatform,
		setCurrentPlatform,
		theme,
		setTheme,
		pageId,
		isSrapiChanged,
		setIsStrapiChanged,
		strapiSchema,
		sqliteSchema,
		isListDynamic,
		setIsOpenStaticTable,
		isStaticDataUpdate,
		component,
		componentWithSettingsRef,
		user,
		setCdn,
		onHoverCoordinates,
		setOnHoverCoordinates,
		setCurrentLeftSidebarSectionTAB,
		layout,
		selectedBreakpoint,
		setSelectedBreakpoint,
		isLayerChange,
		setIsLayerChange,
		currentLanguage,
		setCurrentLanguage,
		content,
		isSiteTrialActive,
		openedLeftSidebarSection,
		setOpenedLeftSidebarSection,
		openedRightSidebarSection,
		setOpenedRightSidebarSection,
		setElementId
	} = useContext(context);

	const params = useParams();
	const navigate = useNavigate();
	const [hoveredBreakpoint, setHoveredBreakpoint] = useState(breakpoints[0]);
	const [width, setWidth] = useState("100%");
	const [firstTime, setFirstTime] = useState(true);
	const newQueryParameters = new URLSearchParams();


	useEffect(() => {
		fetchdata();
		// TODO
		// if no site found (or not owned by user) (redirect to sites page)

		// return() => {
		// 	setSiteInfo(null);
		// }

		return () => {
			setOpenedLeftSidebarSection(null);
			setOpenedRightSidebarSection(null);
			setSelectedBreakpoint(breakpoints[0]);
			setCurrentLanguage(null);
			setSelectedSiteData({});
			setPublish(false);
			setCdn([]);
			setAdminToken("");
			setAdminURL("");
			setCurrentPlatform(null);
			setTheme(null);
			setCurrentTheme({});
			setUndo({
				ui: [],
				backend: []
			});
			setRedo({
				ui: [],
				backend: []
			});
			setElementId("");
			elementRef.current = null;
		}

	}, []);

	useEffect(() => {
		if (!firstTime) {
			setWidth(selectedBreakpoint.width);
		}
	}, [selectedBreakpoint])

	const fetchdata = async () => {
		try {
			setIsAnimating(true);
			const response = await dbCall.get(`/site/site-data/${params.siteId}`);
			setCdn(response.data.cdn);
			setCurrentLanguage(response.data.defaultLang);
			setSelectedSiteData(response.data);
			setPublish(!response.data.IsReady);
			setAdminURL(response.data.admin_url ?? "");
			setAdminToken(response.data.admin_token ?? "");
			if (response.data.platforms != 'Web&App') {
				setCurrentPlatform(response.data.platforms == 'Mobile' ? "app" : "website")
			} else {
				setCurrentPlatform('website');
			}
			setTheme({ theme: response.data.SiteGlobal.theme, appTheme: response.data.SiteGlobal.appTheme });
			const abc = { theme: response.data.SiteGlobal.theme, appTheme: response.data.SiteGlobal.appTheme };
			for (let x in abc) {
				for (let i = 0; i < abc[x]?.fonts?.length; i++) {
					const myFont = new FontFace(abc[x].fonts[i].name, abc[x].fonts[i].url.includes('https') ? `url(${abc[x].fonts[i].url})` : `url('https${abc[x].fonts[i].url.slice(4)}')`);
					await myFont.load();
					document.fonts.add(myFont);
				}
			}
		} catch (error) {
			console.log(error);
			toast.error('Something went wrong!');
		} finally {
			setIsAnimating(false)
		}
	}


	useEffect(() => {
		if (currentPlatform) {
			fetchPages()
		}
	}, [currentPlatform]);
	const fetchPages = async () => {
		try {
			setIsAnimating(true);
			const response = await dbCall.get(`/site/get-all-pages/${params.siteId}/${currentPlatform}`);
			setPages(() => {
				return response.data.pagesData;
			})
		} catch (error) {
			console.log(error);
			toast.error('Something went wrong!');
		} finally {
			setIsAnimating(false)

		}
	}

	useEffect(() => {
		if (theme && currentPlatform) {
			setCurrentTheme(() => {

				return {
					...theme[currentPlatform == "app" ? "appTheme" : "theme"]
				};
			})
		}
	}, [currentPlatform, theme]);

	const coordinatesRef = useRef(onHoverCoordinates);

	useEffect(() => {
		myContext = {
			coordinatesRef,
			setOnHoverCoordinates,
			cordinates,
			setCordinates,
			debounce,
			grab,
			elementId,
			blocks,
			zoomdivHeight
		}
	}, [elementId?.id])

	useEffect(() => {
		coordinatesRef.current = onHoverCoordinates;
	}, [onHoverCoordinates?.id])

	useEffect(() => {
		if (elementId) {
			const containUndeletable = containUndeletableElement(elementId);
			setShowDeleteButton(!containUndeletable);
			const containUnduplicable = containUnduplicableElement(elementId);
			setShowCopyButton(!containUnduplicable)
		} else {
			setShowDeleteButton(false);
			setShowCopyButton(false);
		}
	}, [elementId]);

	const containUndeletableElement = (schema) => {
		if (selectedSiteData?.static === true) {
			if (schema.layout === 'header' || schema.layout === 'footer' || ["input", 'select'].includes(schema.type) || (schema.type == 'button' ? schema?.data?.type ? schema.data.type === 'submit' : false : false)) {
				return true;
			} else {
				if (schema.type != 'form' && schema.children) {
					for (let i = 0; i < schema.children.length; i++) {
						if (schema.children[i].type == 'form') {
							return false;
						} else {
							const abc = containUndeletableElement(schema.children[i]);
							if (abc == true) {
								return true;
							}

						}
					}
					return false;
				} else {
					return false
				}
			}
		} else if (selectedSiteData?.static === false) {

		}
	}
	const containUnduplicableElement = (schema) => {
		if (selectedSiteData?.static === true) {
			if (schema.layout === 'header' || schema.layout === 'footer' || schema.productList || ["input", 'select'].includes(schema.type) || (schema.type == 'button' ? schema?.data?.type ? schema.data.type === 'submit' : false : false)) {
				return true;
			} else {
				if (schema.children) {
					for (let i = 0; i < schema.children.length; i++) {
						const abc = containUnduplicableElement(schema.children[i]);
						if (abc == true) {
							return true;
						}
					}
					return false;
				} else {
					return false
				}
			}
		} else if (selectedSiteData?.static === false) {

		}
	}
	const func = (e, value) => {
		valueY = sessionStorage.getItem("valueY") - 1
		var panning = false;

		var start = { x: 0, y: 0 };
		const zoom = document.getElementById("zoom");

		const zoomDiv = document.getElementById("zoomDiv")
		const scrollDiv = document.getElementById("scrollDiv")
		const deepdive = document.getElementById("deepdive")
		const deepdive2 = document.getElementById("deepdive2")
		const deepdive3 = document.getElementById("deepdive3")
		const tooltipDiv = document.getElementById("tooltipDiv")
		const addFormElement = document.getElementById("addFormElement")

		if (value == "plus") {
			var xs = (e.clientX - pointX) / scale,
				ys = (e.clientY - pointY) / scale,
				delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);
			scale <= .3 && (scale = .3);
			if (scale >= .3 && scale <= 8.5) {
				(scale *= 1.2);
				pointX = e.clientX - xs * scale;
				pointY = e.clientY + 100 - ys * scale;
				(scale >= .3 && scale <= 8) && (zoomDiv.style.transform = "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")");
			}

			if (deepdive?.style) {
				deepdive.style.opacity = 0
			}

			if (deepdive2?.style) {
				deepdive2.style.opacity = 0
			}
			if (deepdive3?.style) {
				deepdive3.style.opacity = 0
			}
			(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)

			if (addFormElement) {
				addFormElement.style.opacity = 0;
			}

			processChange()
		}
		// else if (value == "125") {
		else if (value == "75") {
			var xs = (e.clientX - pointX) / scale,
				ys = (e.clientY - pointY) / scale;
			delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);

			scale >= 8.5 && (scale = 8.5);
			if (scale >= .3 && scale <= 8.5) {
				(scale /= 1.2);
				pointX = e.clientX - xs * scale;
				pointY = e.clientY - 100 - ys * scale;
				(scale >= .3 && scale <= 8) && (zoomDiv.style.transform = "translate(" + 10 + "%, " + 0 + "px) scale(" + .8 + ")");
			}

			if (deepdive?.style) {
				deepdive.style.opacity = 0
			}

			if (deepdive2?.style) {
				deepdive2.style.opacity = 0
			}
			if (deepdive3?.style) {
				deepdive3.style.opacity = 0
			}
			(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)

			if (addFormElement) {
				addFormElement.style.opacity = 0;
			}

			processChange()
		}
		else if (value == "50") {
			var xs = (e.clientX - pointX) / scale,
				ys = (e.clientY - pointY) / scale;
			delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);

			scale >= 8.5 && (scale = 8.5);
			if (scale >= .3 && scale <= 8.5) {
				(scale /= 1.2);
				pointX = e.clientX - xs * scale;
				pointY = e.clientY - 100 - ys * scale;
				(scale >= .3 && scale <= 8) && (zoomDiv.style.transform = "translate(" + 27 + "%, " + 0 + "px) scale(" + .5 + ")");
			}

			if (deepdive?.style) {
				deepdive.style.opacity = 0
			}

			if (deepdive2?.style) {
				deepdive2.style.opacity = 0
			}
			if (deepdive3?.style) {
				deepdive3.style.opacity = 0
			}
			(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)

			if (addFormElement) {
				addFormElement.style.opacity = 0;
			}

			processChange()
		}
		else if (value == "25") {
			var xs = (e.clientX - pointX) / scale,
				ys = (e.clientY - pointY) / scale;
			delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);

			scale >= 8.5 && (scale = 8.5);
			if (scale >= .3 && scale <= 8.5) {
				(scale /= 1.2);
				pointX = e.clientX - xs * scale;
				pointY = e.clientY - 100 - ys * scale;
				(scale >= .3 && scale <= 8) && (zoomDiv.style.transform = "translate(" + 40 + "%, " + 0 + "px) scale(" + .2 + ")");
			}

			if (deepdive?.style) {
				deepdive.style.opacity = 0
			}

			if (deepdive2?.style) {
				deepdive2.style.opacity = 0
			}
			if (deepdive3?.style) {
				deepdive3.style.opacity = 0
			}
			(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)

			if (addFormElement) {
				addFormElement.style.opacity = 0;
			}

			processChange()
		}
		else if (value == "minus") {
			var xs = (e.clientX - pointX) / scale,
				ys = (e.clientY - pointY) / scale;
			delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);

			scale >= 8.5 && (scale = 8.5);
			if (scale >= .3 && scale <= 8.5) {
				(scale /= 1.2);
				pointX = e.clientX - xs * scale;
				pointY = e.clientY - 100 - ys * scale;
				(scale >= .3 && scale <= 8) && (zoomDiv.style.transform = "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")");
			}

			if (deepdive?.style) {
				deepdive.style.opacity = 0
			}

			if (deepdive2?.style) {
				deepdive2.style.opacity = 0
			}
			if (deepdive3?.style) {
				deepdive3.style.opacity = 0
			}
			(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)

			if (addFormElement) {
				addFormElement.style.opacity = 0;
			}

			processChange()
		}
		// }
		else if (value == "reset") {
			// console.log(pointY)
			valueX = 0
			valueY = 0
			pointX = 0;
			pointY = 0;
			scale = 1
			sessionStorage.setItem("valueY", valueY)
			scrollDiv.style.transform = "translate(" + valueX + "px, " + valueY + "px) scale(" + 1 + ")"
			zoomDiv.style.transform = "translate(" + valueX + "px," + valueY + "px)";

			if (deepdive?.style) {
				deepdive.style.opacity = 0
			}

			if (deepdive2?.style) {
				deepdive2.style.opacity = 0
			}

			if (deepdive3?.style) {
				deepdive3.style.opacity = 0
			}

			(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)

			if (addFormElement) {
				addFormElement.style.opacity = 0;
			}

			processChange()
		}
		else if (value == "ScrollIntoView") {
			const el = document.getElementById(elementId?.id)
			const elRect = el?.getBoundingClientRect()
			const outerDiv = document.getElementById("619")
			const div619 = outerDiv?.getBoundingClientRect()

			valueY = div619?.top - elRect?.top + 20
			scrollDiv.style.transform = "translate(" + valueX + "px," + valueY + "px) ";
			sessionStorage.setItem("valueY", valueY)
			processChange()
		}
		zoom.onmousedown = function (e) {
			// e.preventDefault();
			start = { x: e.clientX - pointX, y: e.clientY - pointY };
			panning = true;
			if (grab) return false
		}

		zoom.onmouseup = function (e) {
			panning = false;
		}
		const setTransform = () => {
			const zoomDiv = document.getElementById("zoomDiv")
			zoomDiv.style.transform = "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")";
			// deepdive.style.transform = "translate(" + tooltipX + "px," + tooltipY + "px) ";
		}

		zoom.onmousemove = function (e) {
			// e.preventDefault();
			if (!panning) {
				return;
			}
			pointX = (e.clientX - start.x);
			pointY = (e.clientY - start.y);
			if (grab) {
				setTransform();
				scrollDiv.style.transform = "translate(" + valueX + "px," + valueY + "px) ";

				if (deepdive?.style) {
					deepdive.style.opacity = 0
				}
				if (deepdive2?.style) {
					deepdive2.style.opacity = 0
				}
				if (deepdive3?.style) {
					deepdive3.style.opacity = 0
				}
				(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)
				processChange()
			}
		}

		zoom.onwheel = function (e) {
			const zoomDiv = document.getElementById("zoomDiv")
			const scrollDiv = document.getElementById("scrollDiv")
			var isTrackpad = false;
			if (e.wheelDeltaY) {
				if (e.wheelDeltaY === (e.deltaY * -3)) {
					isTrackpad = true;
				}
			}
			else if (e.deltaMode === 0) {
				isTrackpad = true;
			}
			if (e.ctrlKey) {
				e.preventDefault()
				// if (zoomdivHeight?.height == "auto") {
				// 	let element =  document.getElementById("zoomDiv");
				// 	let clientRect =  element.getBoundingClientRect();
				// 	setZoomDivHeight({ height: clientRect.height })
				// }

				if (scale >= .3 && scale <= 8) {
					var xs = (e.clientX - pointX) / scale,
						ys = (e.clientY - pointY) / scale,
						delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);
					(delta > 0) ? (scale *= 1.04) : (scale /= 1.04);
					pointX = e.clientX - xs * scale;
					pointY = e.clientY - ys * scale;
					zoomDiv.style.transform = "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")";
					// scrollDiv.style.transform = "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")";

				}
				var xs = (e.clientX - pointX) / scale,
					ys = (e.clientY - pointY) / scale,
					delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);
				// (delta > 0) ? (scale *= 1.1) : (scale /= 1.1);
				(delta < 0 && scale <= .3) && (scale *= 1.1);
				(delta > 0 && scale >= 8) && (scale /= 1.1);
				pointX = e.clientX - xs * scale;
				pointY = e.clientY - ys * scale;

				if (deepdive?.style) {
					deepdive.style.opacity = 0
				}
				if (deepdive2?.style) {
					deepdive2.style.opacity = 0
				}
				if (deepdive3?.style) {
					deepdive3.style.opacity = 0
				}
				(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)

				if (addFormElement) {
					addFormElement.style.opacity = 0;
				}


				processChange()
			}
			else if (e.shiftKey && !isTrackpad) {
				// e.preventDefault()
				// if (e.wheelDelta < 0) {
				// 	valueX -= 40
				// }
				// else {
				// 	valueX += 40
				// }
				// scrollDiv.style.transform = "translate(" + valueX + "px," + valueY + "px) ";
				// deepdive?.style && (deepdive.style.opacity = 0)
				if (deepdive2?.style) {
					deepdive2.style.opacity = 0
				}
				if (deepdive3?.style) {
					deepdive3.style.opacity = 0
				}
				(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)
				// if (addFormElement) {
				// 	addFormElement.style.opacity = 0;
				// }

				// processChange()

			} else if (isTrackpad) {
				// e.preventDefault()
				// if (e.wheelDelta < 0) {
				// 	valueX -= 15
				// }
				// else {
				// 	valueX += 15
				// }
				// scrollDiv.style.transform = "translate(" + valueX + "px," + valueY + "px)";

				// deepdive.style.opacity = 0
				if (deepdive2?.style) {
					deepdive2.style.opacity = 0
				}
				if (deepdive3?.style) {
					deepdive3.style.opacity = 0
				}
				(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)
				// if (addFormElement) {
				// 	addFormElement.style.opacity = 0;
				// }

				// processChange()
			}
			else {
				e.preventDefault()

				if (valueY > 500) {
					valueY = 499
					return
				} else {
					// let clientRect
					const element = document?.getElementById(blocks?.[blocks?.length - 1]?.id)
					const clientRect = element?.getBoundingClientRect();
					// if (zoomdivHeight?.height == "auto") {

					// 	let element = document.getElementById("zoomDiv");
					// 	let clientRect = element.getBoundingClientRect();
					// 	setZoomDivHeight({ height: clientRect.height })
					// }
					// }
					// else {
					// 	clientRect = { height: zoomdivHeight?.height }
					// }


					// valueY += e.wheelDelta

					// console.log(clientRect?.bottom)
					if (clientRect?.bottom > 0) {
						valueY += e.wheelDelta
					} else {
						// valueY = -(zoomdivHeight?.height - clientRect?.height)
						// console.log(zoomdivHeight)
						valueY = -zoomdivHeight?.height
					}

					// console.log(zoomdivHeight, valueY, scale)
					// if (Math.floor(-zoomdivHeight?.height) <= valueY) {
					// 	valueY += e.wheelDelta
					// } else {
					// 	var element = document?.getElementById(blocks?.[blocks?.length - 1]?.id);
					// 	var clientVal = element?.getBoundingClientRect();
					// 	// valueY = -(clientRect?.height - clientVal?.height)
					// 	valueY = -zoomdivHeight?.height
					// }

				}


				sessionStorage.setItem("valueY", valueY)

				scrollDiv.style.transform = "translate(" + valueX + "px," + valueY + "px) ";
				if (deepdive?.style) {
					deepdive.style.opacity = 0
				}
				if (deepdive2?.style) {
					deepdive2.style.opacity = 0
				}
				if (deepdive3?.style) {
					deepdive3.style.opacity = 0
				}
				(elementId?.content && tooltipDiv?.style) && (tooltipDiv.style.opacity = 0)

				if (addFormElement) {
					addFormElement.style.opacity = 0;
				}

				processChange()
			}

		}
	}

	const undoFunc = () => {
		if (undo.ui.length > 1) {
			const popUi = undo.ui.pop();
			const popBackend = undo.backend.pop();

			setRedo({
				ui: [...redo.ui, _.cloneDeep(popUi)],
				backend: [...redo.backend, _.cloneDeep(popBackend)]
			});

			// update ui schema
			Bdispatch({ type: "ADD", payload: [...undo.ui[undo.ui.length - 1]] });

			// update sqlite / strapi schema
			if (undo.backend[undo.backend.length - 1].length > 0) {
				if (selectedSiteData.static) {
					sqliteDispatch({ type: "UPDATE_FROM_UNDO", payload: [...undo.backend[undo.backend.length - 1]], uiSchema: [...undo.ui[undo.ui.length - 1]], undo, setUndo, setRedo });
				} else {
					strapiDispatch({ type: "UPDATE_FROM_UNDO", payload: [...undo.backend[undo.backend.length - 1]], uiSchema: [...undo.ui[undo.ui.length - 1]], undo, setUndo, setRedo });
				}

			} else {
				// delete form schema from strapi / sqlite schema
				if (selectedSiteData.static) {
					sqliteDispatch({ type: "EMPTY_SQLITE_SCHEMA" });
				} else {
					strapiDispatch({ type: "EMPTY_STRAPI_SCHEMA" });
				}
			}
		}
	}

	const redoFunc = () => {
		if (redo.ui.length != 0) {
			// update ui schema
			Bdispatch({ type: "ADD", payload: [...JSON.parse(JSON.stringify(redo.ui[redo.ui.length - 1]))] });

			// update sqlite / strapi schema
			if (redo.backend[redo.backend.length - 1].length > 0) {

				if (selectedSiteData.static) {
					sqliteDispatch({ type: "ADD", payload: [..._.cloneDeep(redo.backend[redo.backend.length - 1])], uiSchema: [...redo.ui[redo.ui.length - 1]], undo, setUndo, setRedo });
				} else {
					strapiDispatch({ type: "ADD", payload: [..._.cloneDeep(redo.backend[redo.backend.length - 1])], uiSchema: [...redo.ui[redo.ui.length - 1]], undo, setUndo, setRedo });
				}

			} else {
				// delete form schema from strapi / sqlite schema
				if (selectedSiteData.static) {
					sqliteDispatch({ type: "EMPTY_SQLITE_SCHEMA" });
				} else {
					strapiDispatch({ type: "EMPTY_STRAPI_SCHEMA" });
				}
			}

			const popUi = redo.ui.pop();
			const popBackend = redo.backend.pop();

			setUndo({
				ui: [...undo.ui, _.cloneDeep(popUi)],
				backend: [...undo.backend, _.cloneDeep(popBackend)]
			});
		}
	}

	window.onkeydown = async function (e) {
		// console.log(e.ctrlKey, e.shiftKey)
		if (e.key == "Tab") {
			e.preventDefault()
			return
		} else if (e.ctrlKey && e.key == "0") {
			func(e, "reset")
		}
		else if (e.ctrlKey && e.key == "z") {
			undoFunc()
		}
		else if (e.ctrlKey && e.key == "s") {
			try {
				setIsAnimating(true);
				e.preventDefault()
				await saveProject({
					setIsAnimating: setIsAnimating,
					layout: layout,
					blocks: blocks,
					content: content,
					pageId: pageId,
					selectedSiteData: selectedSiteData,
					sqliteSchema: sqliteSchema,
					strapiSchema: strapiSchema,
					params: params
				});
			} catch (error) {
				console.log(error);
				toast.error("Something went wrong!");
			} finally {
				setIsAnimating(false);
			}
		}
		else if (e.ctrlKey && e.shiftKey && e.code == "KeyZ") {
			redoFunc()
		}
		else if (!e.ctrlKey) {

			setIsDrag(true)
		}
		else if (e.ctrlKey) {
			// console.log("true")
			setIsDrag(false)
		}
	}

	window.onkeyup = function (e) {
		setIsDrag(true)
	}

	useEffect(() => {
		setTimeout(() => {
			if (zoomdivHeight?.height == "auto") {
				let element = document.getElementById("zoomDiv");
				let clientRect = element?.getBoundingClientRect();
				// console.log(clientRect.height, "Height")
				if (clientRect?.height != 0) {
					setZoomDivHeight({ height: clientRect?.height })
				}
			}
		}, 2000)
	}, [])

	useEffect(() => {
		if (isLayerChange != "firstTime") {
			func({}, "ScrollIntoView")
		}
	}, [isLayerChange])

	useEffect(() => {
		func()
	}, [pageName, grab, blocks, cordinates]);

	useEffect(() => {
		func({}, "reset")
	}, [pageName]);

	useEffect(() => {
		document.getElementsByTagName('html')[0].setAttribute('className', 'h-full bg-gray-50');
		document.body.classList = 'h-full overflow-hidden';

		return () => {
			document.getElementsByTagName('html')[0].setAttribute('className', 'h-full bg-white');
			document.body.classList = 'h-full';
		}
	});

	useEffect(() => {
		if (isSrapiChanged) {
			dbCall.post("/site/update-form-changed", {
				siteId: params.siteId
			})
		}
	}, [isSrapiChanged])

	// find form_id fo all froms whose parent div is clicked
	const find_form_id_inside_form = (blocks, formIds, arr) => {
		for (let i = 0; i < blocks.length; i++) {
			if (formIds.includes(blocks[i].id)) {
				arr.push(blocks[i].data.form_id)
				// Note: don't break here
			} else if (blocks[i]?.children) {
				find_form_id_inside_form(blocks[i].children, formIds, arr)
			}
		}
	};
	const componentNames = {
		Carousel2: "Carousel",
		SocialLinks: "Social Links",
		GoogleMap: "Map",
		Disclosure: "Disclosure",
		TabGroup: "Tabs",
		// Popover: "Popover",
		TimerPopup: "Timer Popup",
		form: "Form"
	};
	const componentsWithSettings = ["Carousel2", "SocialLinks", "GoogleMap", "TimerPopup"];

	const steps = [
		{
			target: 'body',
			placement: 'center',
			content: 'This another awesome feature!',
		},
		{
			target: '.my-first-step',
			content: 'This is my awesome feature!',
		},
	]

	const containForm = (schema, formIds) => {
		if (schema.type == 'form') {
			formIds.push(schema.id);
		} else if (schema.children) {
			for (let i = 0; i < schema.children.length; i++) {
				containForm(schema.children[i], formIds);
			}
		}
	}

	const formIdCorrespondingToTableName = (schema, formIds) => {
		if (schema.type == 'form') {
			formIds.push({ id: schema.id, tableName: schema.data?.tableName });
		} else if (schema.children) {
			for (let i = 0; i < schema.children.length; i++) {
				formIdCorrespondingToTableName(schema.children[i], formIds);
			}
		}
	}

	return (
		<div className='font-inter'>
			{
				component &&
				<div className='absolute z-10 flex'
					style={{
						top: (document.getElementById(component.id)?.getBoundingClientRect()?.top - 28) + 'px',
						left: document.getElementById(component.id)?.getBoundingClientRect()?.left + 'px',
						width: document.getElementById(component.id)?.getBoundingClientRect()?.width + 'px'
					}}
				>
					<button
						type="button"
						className='font-medium px-3 p-1 text-sm bg-indigo-600 rounded-t-md text-white space-x-3 flex items-center'
						onClick={() => {
							if (componentsWithSettings.includes(component.type)) {
								setOpenedRightSidebarSection("settings")
							}
						}}
					>
						<span>{componentNames[component.type]}</span>
						{
							componentsWithSettings.includes(component.type) &&
							<WrenchScrewdriverIcon className="w-4 h-4 text-white" />
						}
					</button>
				</div>
			}

			<div
				className={`flex flex-col text-gray-600  duration-0 ease-out`}
				id="move"
				style={{
					position: "absolute",
					top: document.getElementById(blocks[cordinates?.index]?.id)?.getBoundingClientRect()?.top + "px",
					left: document.getElementById(blocks[cordinates?.index]?.id)?.getBoundingClientRect()?.right + "px",
					// zIndex: 999,
				}}
			>
				{/*
				 <button title='MoveUp'
				 	type="button"
					onClick={async () => {
						await Bdispatch({ type: "MOVE_UP", index: cordinates?.index, element: elementId })

						// add schemas to undo state
						AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);

						setCordinates({
							cordinates: {
								top: cordinates?.top,
								right: cordinates?.right,
							},
							id: cordinates?.id,
							index: cordinates?.index - 1
						})
					}}
				>
					<IconArrowNarrowUp className='h-5 w-5 hover:text-green-700 cursor-pointer duration-200' />
				</button>
				 */}

				{/* <button title='MoveDown'
					type="button"
					onClick={async () => {
						await Bdispatch({ type: "MOVE_DOWN", index: cordinates?.index, element: elementId })

						// add schemas to undo state
						AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);

						setCordinates({
							cordinates: {
								top: cordinates?.top,
								right: cordinates?.right,
							},
							id: cordinates?.id,
							index: cordinates?.index + 1
						})
					}}
				>
					<IconArrowNarrowDown className='h-5 w-5 hover:text-green-700 cursor-pointer duration-200' />
				</button> */}
			</div>
			{/* END - move section up / down btns */}
			{
				!params?.modalName &&
				<>
					<div
						className={`flex flex-col  ease-out z-50 p-2 rounded-md bg-indigo-500 text-white cursor-pointer `}
						style={{
							position: "absolute",
							top: onHoverCoordinates?.cordinatesValue?.top - 22 + "px",
							// left: cordinates?.cordinatesValue?.right - document?.getElementById("deepdive")?.getBoundingClientRect()?.width + "px",
							left: onHoverCoordinates?.cordinatesValue?.right - document?.getElementById("deepdive2")?.getBoundingClientRect()?.width / 2 - onHoverCoordinates?.cordinatesValue?.width / 2 + "px",
						}}
						id="deepdive2"
						onMouseEnter={() => {
							if (onHoverCoordinates?.id) {
								setOnHoverCoordinates({ ...onHoverCoordinates })
								const element = document.getElementById(onHoverCoordinates?.id);
								if (element) {
									element.style.outline = "3px solid #ad80ff"
								}
							}
						}}
						onMouseOut={() => {
							if (onHoverCoordinates?.id) {
								const element = document.getElementById(onHoverCoordinates?.id);
								if (element) {
									element.style.outline = "none"
								}
								if (openedLeftSidebarSection != 'elements') {
									setOnHoverCoordinates({
										id: null,
										cordinatesValue: {
											top: -1000,
											right: -1000,
											width: -1000
										}
									})
								}
							}
						}}
						onClick={() => {
							processChange()
							setOpenedLeftSidebarSection('elements')
							const data = {
								...onHoverCoordinates,
								addTop: true
							}

							setOnHoverCoordinates({
								...data
							})
							coordinatesRef.current = data
							setTimeout(() => {
								setCurrentLeftSidebarSectionTAB("Section")
							}, 500)
						}}
					>
						Add Section +
					</div>
					<div
						className={`text-sm flex flex-col ease-out z-10 p-2 rounded-md bg-indigo-500 text-white cursor-pointer `}
						style={{
							position: "absolute",
							top: onHoverCoordinates?.cordinatesValue?.top + onHoverCoordinates?.cordinatesValue?.height - 22 + "px",
							// left: cordinates?.cordinatesValue?.right - document?.getElementById("deepdive")?.getBoundingClientRect()?.width + "px",
							left: onHoverCoordinates?.cordinatesValue?.right - document?.getElementById("deepdive3")?.getBoundingClientRect()?.width / 2 - onHoverCoordinates?.cordinatesValue?.width / 2 + "px",
						}}
						id="deepdive3"
						onMouseEnter={() => {
							// setCordinates({ ...cordinates })
							if (onHoverCoordinates?.id) {
								setOnHoverCoordinates({ ...onHoverCoordinates })
								const element = document.getElementById(onHoverCoordinates?.id);
								if (element) {
									element.style.outline = "3px solid #ad80ff"
								}
							}
						}}
						onMouseOut={() => {
							// setCordinates({ ...cordinates })
							if (onHoverCoordinates?.id) {
								const element = document.getElementById(onHoverCoordinates?.id);
								if (element) {
									element.style.outline = "none"
								}
								if (openedLeftSidebarSection != 'elements') {
									setOnHoverCoordinates({
										id: null,
										cordinatesValue: {
											top: -1000,
											right: -1000,
											width: -1000
										}
									})
								}
							}
						}}
						onClick={() => {
							processChange()
							setOpenedLeftSidebarSection('elements');
							const data = {
								...onHoverCoordinates,
								addTop: false
							}
							setOnHoverCoordinates({
								...data
							})
							coordinatesRef.current = data
							setTimeout(() => {
								setCurrentLeftSidebarSectionTAB("Section")
							}, 500)
						}}
					>
						Add Section +
					</div>
				</>
			}

			{
				elementId && elementId?.content && (elementId?.type == 'button' ? elementId?.data?.type ? elementId?.data?.type != 'submit' : false : true) && elementId.type != "label" &&
				<Tooltip />
			}

			{
				elementId?.id &&
				<div
					className={`flex items-center justify-between space-x-2 text-gray-700 rounded-sm p-1 z-30 duration-0 ease-out ${elementId?.productList && "hidden"}`}
					id="deepdive"
					style={{
						position: "absolute",
						top: elementId?.content ? cordinates?.cordinatesValue?.bottom + "px" : cordinates?.cordinatesValue?.top - document?.getElementById("deepdive")?.getBoundingClientRect()?.height - 2 + "px",
						// top: cordinates?.cordinatesValue?.bottom + "px",
						left: cordinates?.cordinatesValue?.right - document?.getElementById("deepdive")?.getBoundingClientRect()?.width + "px",
					}}
				>

					{
						(cordinates?.componentName || component) &&
						<button className='bg-white p-2 py-2 rounded-lg font-semibold '
							onClick={() => {
								if (component) {
									newQueryParameters.set("section", `${component?.data?.name}`);
								} else {
									newQueryParameters.set("section", `${cordinates?.componentName}`);
								}
								setOpenedLeftSidebarSection('elements');
							}}
						>Change layout</button>
					}
					{/* to display plus btn i.e. + */}
					{
						(cordinates?.isCustom || elementId.type == "column") &&
						<PlusIcon className='h-7 w-7 hover:text-green-700 text-gray-600 cursor-pointer duration-200 bg-white  px-[2px] py-[1px] rounded-md' title='Add a new'
							onClick={async () => {
								if (elementId.type == "column") {
									const duplicate = JSON.parse(JSON.stringify(elementId?.children[0]))
									const getValu = await changeId(duplicate)
									Bdispatch({ type: "DUPLICATE", id: elementId?.children[elementId?.children.length - 1].id, element: getValu })
								} else {
									const customComp = await cordinates?.elementId
									// const duplicate = JSON.parse(JSON.stringify(elementId?.children[0]))
									// const getValu = await changeId(duplicate)
									const duplicate = JSON.parse(JSON.stringify(customComp?.children[0]))
									const getValu = await changeId(duplicate)
									// console.log(getValu)
									// Bdispatch({ type: "DUPLICATE", id: elementId?.children[elementId?.children.length - 1].id, element: getValu })
									Bdispatch({ type: "DUPLICATE", id: customComp?.children[customComp?.children.length - 1].id, element: getValu })
								}
							}}
						/>
					}

					{/* component settings / data btn */}
					{/* {
					componentRef.current &&
					<IconSettings2 className='h-5 w-5 hover:text-green-700 cursor-pointer duration-200' title='Settings'
						onClick={() => {

							setDisplayDataTab(true);


							setDisplayEventTab(false);
						}}
					/>
				} */}

					{/* Plus Icon For Add Element */}
					{
						selectedSiteData.static == true &&
						//   && elementId && !["input", 'select'].includes(elementId.type) && (elementId.type == 'button' ? elementId.data.type ? elementId.data.type != 'submit' : false : true) &&
						<span
							class="mdi mdi-plus text-xl hover:text-green-700 cursor-pointer duration-200 bg-white px-[4px] p-[2px] rounded-md"
							title='Add Elements'
							onClick={async () => {
								setOpenedLeftSidebarSection('elements');

								setOpenedRightSidebarSection(null);
							}}
						/>
					}

					{/* change image btn */}
					{
						(elementId.type == "img" || elementId.type == "player" || elementId?.data?.backgroundImage) &&
						<>
							<span title='Media settings'>
								<IconSettings
									className='h-7 w-7 hover:text-blue-700 cursor-pointer duration-200 text-gray-600 cursor-pointer duration-200 bg-white  px-[2px] py-[1px] rounded-md'
									onClick={() => {
										setIsOpen(true)
										setOpenedLeftSidebarSection(null);
										setOpenedRightSidebarSection(null);
										// sessionStorage.setItem("setMedia", true)
									}}
								/>
							</span>
							{
								elementId.type == "img" &&
								<ImageSettingModal isOpen={isOpen} setIsOpen={setIsOpen} elementId={elementId} />
							}
							{
								elementId.type == "player" &&
								<VideoLinkModal isOpen={isOpen} setIsOpen={setIsOpen} elementId={elementId} />
							}

							<span title='Change media'>
								<IconArrowsExchange
									className='h-7 w-7 hover:text-blue-700 cursor-pointer duration-200 text-gray-600 cursor-pointer duration-200 bg-white  px-[2px] py-[1px] rounded-md'
									onClick={() => {
										setOpenedLeftSidebarSection("media")
										setOpenedRightSidebarSection(null);
										sessionStorage.setItem("setMedia", true)
									}}
								/>
							</span>
						</>
					}
					{/* {

					<IconArrowsShuffle className='h-5 w-5 hover:text-blue-700 cursor-pointer duration-200 mr-2' title='Change Id'
						onClick={async () => {
							const getValue = await changeId(elementId)
							setElementId(getValue)
						}}
					/>
				} */}

					{/* change icon */}
					{
						(elementId?.data?.icon == "true" || elementId?.data?.icon == true || elementId.type == "icon") &&
						<span title='Change Icon'>
							<IconSwitch3
								className='h-7 w-7 hover:text-blue-700 cursor-pointer duration-200 text-gray-600 cursor-pointer duration-200 bg-white  px-[2px] py-[1px] rounded-md'
								onClick={() => {
									setOpenedLeftSidebarSection("elements")
									setOpenedRightSidebarSection(null);
									setCurrentLeftSidebarSectionTAB("Icon");
								}}
							/>
						</span>
					}
					{/* Duplicate btn */}
					{
						showCopyButton &&
						<span class="mdi mdi-content-copy text-xl hover:text-green-700 cursor-pointer duration-200 bg-white px-[4px] p-[2px] rounded-md"
							title='Duplicate'
							onClick={async () => {

								const originalValue = JSON.parse(JSON.stringify(elementId));
								const duplicateValue = await changeId(originalValue);
								// console.log('getValu', duplicateValue.id, duplicateValue, elementId, elementId.data.form_id)

								// normal duplication
								await Bdispatch({ type: "DUPLICATE", id: elementId.id, element: duplicateValue })
								const formIds = []
								formIdCorrespondingToTableName(elementId, formIds);
								if (formIds.length > 0) {
									if (selectedSiteData.static) {
										await sqliteDispatch({ type: "DUPLICATE_FORM", formIds: formIds })
									} else {

									}
								}
								// let formDuplicated = false;
								// // duplicate whole form's formik_state schema in ui & strapi
								// if (formSelected && selectedFormElementId == null) {
								// 	formDuplicated = true;
								// 	let formIds = [];
								// 	if (Array.isArray(formId)) {
								// 		for (let i = 0; i < formId.length; i++) {
								// 			formIds.push(formId[i])
								// 		}
								// 	} else {
								// 		formIds = [formId]
								// 	}
								// 	const arr = [];
								// 	find_form_id_inside_form(blocks, formIds, arr);
								// 	await Bdispatch({
								// 		type: "DUPLICATE_FORM",
								// 		originalForm_id: arr,
								// 		duplicateID: duplicateValue.id,
								// 		strapiDispatch,
								// 		uiSchema: blocks,
								// 		undo,
								// 		setUndo,
								// 		setRedo
								// 	});

								// 	setIsStrapiChanged(true);

								// } else if (formSelected == false && selectedFormElementId != null) {
								// 	formDuplicated = true;

								// 	// duplicate single formik_state schema in ui & strapi
								// 	await Bdispatch({
								// 		type: "DUPLICATE_FORM_ELEMENT",
								// 		formId,
								// 		duplicateID: duplicateValue.id,
								// 		strapiDispatch,
								// 		uiSchema: blocks,
								// 		undo,
								// 		setUndo,
								// 		setRedo
								// 	});

								// 	setIsStrapiChanged(true);
								// }

								// // Note: if backendSchema is present, update undo state in reducer else update it here
								// if (formDuplicated == false) {
								// 	// add schemas to undo state
								// 	AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
								// }
								AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);

							}}
						/>
					}

					{
						((isListDynamic?.dynamicData != null && isListDynamic?.dynamicData == false)) &&
						<span title='Settings'>
							<IconSettings
								className='h-8 w-8 hover:text-blue-500 cursor-pointer duration-200 text-gray-600 cursor-pointer duration-200 bg-white  px-[2px] py-[1px] rounded-md'
								onClick={() => {
									if (elementId?.type == "player") {
										setIsOpen(true)
									} else {
										setOpenedRightSidebarSection("data");
										setIsOpenStaticTable(true)
									}
								}}
							/>
						</span>
					}

					{/* Delete btn  */}
					{
						showDeleteButton &&
						// <TrashIcon className='h-5 w-5 hover:text-red-500 cursor-pointer duration-200' title='Delete'
						<span class="mdi mdi-delete text-2xl  hover:text-red-500 text-gray-600 cursor-pointer duration-200 bg-white  px-[2px] py-[1px] rounded-md" title='Delete'
							onClick={async () => {
								setOpenedRightSidebarSection(null);
								if (elementRef.current != null) {
									elementRef.current.style.outline = "none";
									elementRef.current = null;
								}
								setCordinates({
									id: null,
									cordinatesValue: {
										bottom: -1000,
										right: -1000
									}
								});
								await Bdispatch({ type: "DELETE", id: elementId.id });
								const formIds = []
								containForm(elementId, formIds);
								if (formIds.length > 0) {
									if (selectedSiteData.static) {
										await sqliteDispatch({ type: "DELETE_SQLITE_FORM_SCHEMA", formIds: formIds });
									} else {

									}
								}
								AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
							}}
						/>
					}
				</div>
			}
			{/* <VideoLinkModal isOpen={isOpen} setIsOpen={setIsOpen} elementId={elementId} /> */}

			{/* START - top navigation */}
			<div className="bg-white sticky top-0 z-[51] h-[103px] border-b border-[#D9D9D9]">

				{/* START - top bar */}
				<div className='flex h-[58px] items-center px-4 sm:px-6 lg:px-2 h-full justify-between w-full'>
					<div className='flex items-center h-full justify-between'>
						<Link to={'/'} className='text-4xl border-r border-[#D9D9D9] h-full px-3 flex items-center'>
							<img src='https://qafto.com/Qafto-logo.svg' className='w-full max-w-[100px] h-10' alt='logo' />
						</Link>
						<div className='text-[22px] capitalize h-full px-3 flex items-center'>
							{selectedSiteData.name}
						</div>
					</div>

					{/* Change device type */}
					<div>
						{
							devices.map((data, index) =>
								<button
									type="button"
									key={index}
									title={data.title}
									className={
										classNames(
											'mx-1 px-3 py-2 text-sm font-semibold hover:bg-indigo-600 hover:text-white rounded-md',
											(
												index == 0 ?
													(width == "100%" || parseInt(width) >= 1024)
													:
													index == 1 ?
														(width != "100%" && (parseInt(width) >= 640 && parseInt(width) < 1024))
														:
														index == 2 ? (width != '100%' && parseInt(width) < 640)
															:
															false
											) ? 'bg-[#D0DAF3] text-indigo-700' : 'bg-gray-100 text-gray-500'
										)
									}
									onClick={(e) => setWidth(data.width)}
								>
									<data.icon className={`h-5 w-5`} />
								</button>
							)
						}
					</div>

					<div className="flex gap-5 px-3">
						{/* Live website url button */}
						{
							selectedSiteData.domain &&
							<a target='_blank' href={`https://${selectedSiteData.domain}`} className="grow my-auto text-lg font-bold text-blue-800">Live</a>
						}

						<div className="flex gap-3 text-base font-medium leading-5 whitespace-nowrap">

							<a target='_blank' href={`/user/preview/${params.siteId}/${pageName}/${currentPlatform}`} className={`${params?.modalName && "pointer-events-none opacity-50"} flex items-center gap-1.5 px-3.5 py-2 text-blue-800 rounded-md border border-blue-800 border-solid`}
								title={params?.modalName && "Only Pages Can preview"}
								disabled={params?.modalName ? true : false}
							>
								<div className="grow">Preview</div>
								<EyeIcon className="h-5 w-5" />
							</a>
							<button className={`px-5 text-white bg-blue-800 rounded-md ${!isSiteTrialActive && "opacity-[.7]"}`}
								disabled={!isSiteTrialActive ? isSiteTrialActive : publish}
								title={!isSiteTrialActive ? "Free trial Expired" : publish ? "Initializing your app" : "Publish your site for the world to see"}
								onClick={async (e) => {
									try {
										if (!isSiteTrialActive) return;
										e.stopPropagation();
										if (pageId != null || pageName != "") {
											setPublish(true)
											setIsAnimating(true)
											await saveProject({
												setIsAnimating: setIsAnimating,
												layout: layout,
												blocks: blocks,
												content: content,
												pageId: pageId,
												selectedSiteData: selectedSiteData,
												sqliteSchema: sqliteSchema,
												strapiSchema: strapiSchema,
												params: params
											});
											if (selectedSiteData.static == true) {
												sqliteDispatch({ type: "RESET" });
											} else {
											}
											if (currentPlatform == 'app') {
												await dbCall.put(`${process.env.REACT_APP_BACKEND_URL}/deploy/updateRepoCodeAppUI`, {
													siteId: params?.siteId,
													email: user.email
												})
											} else if (currentPlatform == 'website') {
												const response = await dbCall.put(`${process.env.REACT_APP_BACKEND_URL}/deploy/updateRepoCodeUI`, {
													siteId: params?.siteId,
													email: user.email,
													isStaticDataUpdate: isStaticDataUpdate
												})
												if (response?.data?.message == "Published Site Successfully") {
													setIsAnimating(false)
													toast.success("Published Site Successfully")
													setPublish(false)
												}
											} else {
												toast.error("Something went wrong");
											}
										}
									} catch (error) {
										setPublish(false)
										console.log(error)
										if (error.response) {
											toast.error(error.response.data.message);
										} else {
											toast.error("Something went wrong!");
										}
									}
								}}
							>
								{
									publish ?
										<div className='flex items-center'>
											<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
												<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
											</svg>
											Publishing
										</div>
										:
										'Publish'
								}
							</button>
						</div>
					</div>
				</div>
				{/* END - top bar */}

				<div className='shrink border-b w-full' />

				{/* START - bottom bar */}
				<div className='flex h-[45px] justify-between border-b border-gray-200 h-full w-full'>
					<div className='flex items-center divide-x h-full'>
						<button
							type="button"
							className='flex items-center px-7 justify-center h-full'
							onClick={() => navigate('/user/sites')}
							title="Go Back"
						>
							<span class="mdi mdi-arrow-left text-2xl text-[#757575]"></span>
						</button>

						{/* START - list all pages */}
						<div className='flex items-center justify-center px-3 h-full'>
							<Listbox
								as={'div'}
								className={'relative'}
								onChange={async (value) => {
									try {
										if (value.pageName != pageName) {
											if (undo?.ui?.length > 1) {
												const save = window.confirm("Do you want to save changes in the current page?");
												if (save) {
													setIsAnimating(true)
													await saveProject({
														setIsAnimating: setIsAnimating,
														layout: layout,
														blocks: blocks,
														content: content,
														pageId: pageId,
														selectedSiteData: selectedSiteData,
														sqliteSchema: sqliteSchema,
														strapiSchema: strapiSchema,
														params: params
													});
												}
											}
											setUndo({ ui: [], backend: [] });
											setRedo({ ui: [], backend: [] });
											navigate(`/user/editor/${params.siteId}/${value.pageName}`);
										}
									} catch (error) {
										console.log(error);
										toast.error("Something went wrong!");
									} finally {
										setIsAnimating(false)
									}
								}}
							>
								{({ open }) => (
									<>
										<Listbox.Button
											className={`flex items-center py-1 px-2 min-w-[130px] rounded-md border border-gray-300 justify-between text-sm font-medium`}
											title="All Pages"
										>
											<span>{pageName}</span>
											{
												open ?
													<ChevronUpIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
													:
													<ChevronDownIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
											}
										</Listbox.Button>
										<Listbox.Options className="absolute max-h-60 mt-1 w-full overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
											{
												pages.map(
													(
														value,
														index
													) => (
														<Listbox.Option
															key={index}
															as={"button"}
															className={({
																active
															}) => `${pageName == value.pageName ? "text-[#1E429F] font-semibold bg-indigo-100 " : active ? "bg-gray-100 text-gray-900 font-medium " : "text-gray-900 font-medium "}w-full flex items-center gap-1 px-2 py-1.5  text-sm`
															}
															value={value}
														>
															<span>{value.pageName}</span>
															{
																pageName == value.pageName &&
																<span class="mdi mdi-check"></span>
															}
														</Listbox.Option>
													)
												)}
										</Listbox.Options>
									</>
								)}
							</Listbox>
						</div>
						{/* END - list all pages */}

						{/* START - show all selected languages */}
						{
							selectedSiteData.multiLanguageEnabled &&
							<div className='flex items-center justify-center px-3 h-full'>
								<Listbox
									as={'div'}
									className={'relative'}
									onChange={(value) => {
										setCurrentLanguage(value)
									}}
									value={currentLanguage}
								>
									{({ open }) => (
										<>
											<Listbox.Button className={`flex items-center py-1 px-2 min-w-[130px] rounded-md border border-gray-300 justify-between text-sm font-medium`}>
												<span className='space-x-1'>
													<span>{currentLanguage?.flag}</span>
													<span>{currentLanguage?.name}</span>
												</span>
												{
													open ?
														<ChevronUpIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
														:
														<ChevronDownIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
												}
											</Listbox.Button>
											<Listbox.Options className="absolute z-10 max-h-60 mt-1 w-full overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
												{
													selectedSiteData?.addedLanguages.map(
														(
															value,
															index
														) => (
															<Listbox.Option
																key={index}
																as={"button"}
																className={({
																	active
																}) => `${currentLanguage?.name == value.name ? "text-[#1E429F] font-semibold bg-indigo-100 " : active ? "bg-gray-100 text-gray-900 font-medium " : "text-gray-900 font-medium "}w-full flex items-center gap-1 px-2 py-1.5  text-sm`
																}
																value={value}
															>
																<span>{value.flag}</span>
																<span>{value.name}</span>
															</Listbox.Option>
														)
													)}
											</Listbox.Options>
										</>
									)}
								</Listbox>
							</div>
						}
						{/* END - show all selected languages */}

						<div className='flex items-center justify-center px-3 h-full !border-r'>
							{
								selectedSiteData.platforms != "Web&App" ?
									<span>
										<span className='text-[#757575]'>Type:</span><span className='font-medium capitalize'> {currentPlatform}</span>
									</span>
									:
									<Listbox
										as={'div'}
										className={'relative'}
										onChange={async (value) => {
											try {
												if (value != currentPlatform) {
													if (undo?.ui?.length > 1) {
														const save = window.confirm("Do you want to save changes in the current page?");
														if (save) {
															setIsAnimating(true);
															await saveProject({
																setIsAnimating: setIsAnimating,
																layout: layout,
																blocks: blocks,
																content: content,
																pageId: pageId,
																selectedSiteData: selectedSiteData,
																sqliteSchema: sqliteSchema,
																strapiSchema: strapiSchema,
																params: params
															});
														}
													}
													setUndo({ ui: [], backend: [] });
													setRedo({ ui: [], backend: [] });
													setCurrentPlatform(value);
												}
											} catch (error) {
												console.log(error);
												toast.error("Something went wrong!");
											} finally {
												setIsAnimating(false)
											}


										}}
									>
										{({ open }) => (
											<>
												<Listbox.Button className={'space-x-2 flex items-center justify-between w-[140px]'}>
													<span>
														<span className='text-[#757575]'>Type:</span><span className='font-semibold capitalize'> {currentPlatform}</span>
													</span>
													{
														open ?
															<ChevronUpIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
															:
															<ChevronDownIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
													}
												</Listbox.Button>
												<Listbox.Options className="absolute z-10 max-h-60 mt-1 w-full overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
													{
														["website", "app"].map(
															(
																value,
																index
															) => (
																<Listbox.Option
																	key={index}
																	className={({
																		active
																	}) => `${currentPlatform == value ? "text-[#1E429F] font-semibold bg-indigo-100 " : active ? "bg-gray-100 text-gray-900 font-medium " : "text-gray-900 font-medium "}cursor-pointer font-medium  flex items-center gap-1 px-2 py-1.5 text-gray-900 text-sm`
																	}
																	value={value}
																>
																	<span className='capitalize'>{value}</span>
																	{
																		currentPlatform == value &&
																		<span class="mdi mdi-check"></span>
																	}
																</Listbox.Option>
															)
														)}
												</Listbox.Options>
											</>
										)}
									</Listbox>
							}
						</div>
					</div>

					<div className='flex items-center divide-x h-full'>
						<div className='flex items-center justify-center gap-1 text-[#757575] px-3 h-full !border-l'>
							<Layers />
						</div>

						{/* Save button */}
						<div className='flex items-center justify-center gap-1 text-[#757575] px-3 h-full !border-l'>
							<button
								type="button"
								className='px-1 rounded-lg hover:bg-[#D0DAF3] hover:text-indigo-700'
								onClick={async () => {
									try {
										setIsAnimating(true);
										await saveProject({
											setIsAnimating: setIsAnimating,
											layout: layout,
											blocks: blocks,
											content: content,
											pageId: pageId,
											selectedSiteData: selectedSiteData,
											sqliteSchema: sqliteSchema,
											strapiSchema: strapiSchema,
											params: params
										});
									} catch (error) {
										console.log(error);
										toast.error("Something went wrong!")
									} finally {
										setIsAnimating(false)
									}
								}}
								title='Save'
							>
								<span class="mdi mdi-content-save-outline text-xl"></span>
							</button>
						</div>

						{/* undo / redo button */}
						<div className='flex items-center gap-1 text-[#757575] justify-center px-3 h-full !border-l'>
							<button
								type="button"
								className='px-1 rounded-lg hover:bg-[#D0DAF3] hover:text-indigo-700'
								title='Undo'
								onClick={(e) => {
									undoFunc();
									setOpenedLeftSidebarSection(null)

									if (elementRef.current != null) {
										elementRef.current.style.outline = "none";
										elementRef.current = null;
									}
									if (formElementRef.current != null) {
										formElementRef.current.style.outline = "none";
										formElementRef.current = null;
									}
									if (componentRef.current != null) {
										componentRef.current.style.outline = "none";
										componentRef.current = null;
									}

									setCordinates({
										cordinatesValue: {
											top: 0,
											left: 0
										}
									});
								}}
							>
								<span class="mdi mdi-arrow-u-left-top text-xl"></span>
							</button>

							<button
								type="button"
								className='px-1 rounded-lg hover:bg-[#D0DAF3] hover:text-indigo-700'
								title='Redo'
								onClick={() => {
									redoFunc();
									setOpenedLeftSidebarSection(null)

									if (elementRef.current != null) {
										elementRef.current.style.outline = "none";
										elementRef.current = null;
									}
									if (formElementRef.current != null) {
										formElementRef.current.style.outline = "none";
										formElementRef.current = null;
									}
									if (componentRef.current != null) {
										componentRef.current.style.outline = "none";
										componentRef.current = null;
									}

									setCordinates({
										cordinatesValue: {
											top: 0,
											left: 0
										}
									});
								}}
							>
								<span class="mdi mdi-arrow-u-right-top text-xl"></span>
							</button>
						</div>

						{/* pointer / grab button */}
						<div className='flex items-center gap-1 text-[#757575] justify-center px-3 h-full !border-l'>
							<button
								type="button"
								className={`${!grab && "bg-[#D0DAF3]"} px-1 rounded-lg hover:bg-[#D0DAF3] hover:text-indigo-700`}
								onClick={() => setGrab(false)}
								title='Edit Page'
							>
								<span class="mdi mdi-cursor-default text-xl"></span>
							</button>

							<button
								type="button"
								className={`${grab && "bg-[#D0DAF3]"} px-1 rounded-lg hover:bg-[#D0DAF3] hover:text-indigo-700`}
								onClick={() => setGrab(true)}
								title='Move Page'
							>
								<span class="mdi mdi-hand-front-right-outline text-xl"></span>
							</button>
						</div>

						<div className='flex items-center justify-center px-3 h-full !border-l'>
							<button
								type="button"
								className='p-1 rounded-lg hover:bg-[#D0DAF3] hover:text-indigo-700'
								onClick={(e) => func(e, "reset")}
								title='Show Full Page'
							>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
									<path strokeLinecap="round" strokeLinejoin="round" d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25" />
								</svg>
							</button>
						</div>


						<div className='flex items-center justify-center px-3 h-full'>
							<Listbox
								as={'div'}
								className={'relative text-sm'}
								onChange={(value) => {
									if (firstTime == true) {
										setFirstTime(false);
									}
									if (selectedBreakpoint.displayName != value.displayName) {
										setSelectedBreakpoint(value);
									}
								}}
							>
								{({ open }) => (
									<>
										<Listbox.Button
											className={'relative gap-2 w-[180px] flex items-center justify-between border border-gray-300 rounded-[5px] px-3 py-1 font-medium'}
										>
											<span>{selectedBreakpoint.displayName}</span>
											{
												open ?
													<ChevronUpIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
													:
													<ChevronDownIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
											}
										</Listbox.Button>
										<Listbox.Options className="absolute z-50 mt-1 w-full rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-[#ECECEC]">
											{
												breakpoints.map(
													(
														value,
														index
													) => (
														<Listbox.Option
															key={index}
															className={({
																active
															}) => `${selectedBreakpoint.displayName == value.displayName ? "text-[#1E429F] bg-indigo-100 " : active ? "bg-gray-100 text-gray-900 " : "text-[#757575] "}cursor-pointer flex items-center gap-2 px-2 py-1.5 cursor-pointer`
															}
															value={value}
															onMouseOver={() => {
																if (hoveredBreakpoint.displayName != value.displayName) {
																	setHoveredBreakpoint(value);
																}
															}}
														>
															<span>{value.displayName}</span>
															{
																selectedBreakpoint.displayName == value.displayName &&
																<span class="mdi mdi-check"></span>
															}
														</Listbox.Option>
													)
												)}
											<div className='p-2'>
												<div className='text-black'>{hoveredBreakpoint.displayName}</div>
												<div className='text-xs text-[#757575]'>Styles added will be applied on devices with screen size {hoveredBreakpoint.value} & above, unless there is a larger breakpoint added.</div>
											</div>
										</Listbox.Options>
									</>
								)}
							</Listbox>
						</div>
						{/* END - breakpoints */}

					</div>
				</div>
				{/* END - bottom bar */}
			</div>
			{/* END - top navigation */}

			{/* START - left sidebar */}
			<div className="fixed inset-y-0 top-[103px] left-0 z-50 block w-20 overflow-y-auto bg-[#000000] pb-4">
				<div className="mt-3 flex flex-col items-center space-y-3">
					<Pages setZoomDivHeight={setZoomDivHeight} pages={pages} setPages={setPages} fetchPages={fetchPages} />
					<Elements />
					<Theme />
					<Media />

					{/*
							<li>
								<button
									type="button"
									className={classNames(
										'text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold'
									)}
									title="Mind Map"
								>
									<MapIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
									<span className="sr-only">Mind Map</span>
								</button>
							</li>
							*/}

					<Link
						to={`/site/admin/${params.siteId}/general`}
						target="_blank"
						className='text-white hover:bg-[#393939] rounded-md px-3 py-2 font-semibold'
						title="Admin"
					>
						<span class="mdi mdi-cog-outline text-2xl"></span>
					</Link>

				</div>
			</div>
			{/* END - left sidebar */}

			{/* START - editor */}
			<main
				className={`${openedRightSidebarSection ? 'lg:pr-80 ' : ""} bg-gray-200 scroll-smooth`}
				style={{ height: '100vh' }}
				id="zoom"
				onClick={(e) => {
					e.stopPropagation()
					if (!grab) {
						elementRef.current != null && (elementRef.current.style.outline = "none")
						elementRef.current != null && (elementRef.current = null)
						setCordinates({
							cordinatesValue: {
								top: 0,
								left: 0
							}
						});
					}
				}}
			>
				<div
					id="zoomDiv"
					className={`${openedLeftSidebarSection ? openedLeftSidebarSection == 'elements' && currentLeftSidebarSectionTAB === 'Section' ? "pl-[36rem]" : "pl-[24rem]" : ""} ${grab ? "cursor-pointer " : ""}  duration-300 ease-out scroll-smooth`}
					onClick={() => {
						setOpenedLeftSidebarSection(null)
						setOpenedRightSidebarSection(null);
					}}
				>
					<div className="px-4 py-10 sm:px-6 lg:px-6 pl-14 lg:py-6 duration-300 ease-out ml-20" id="scrollDiv">
						<Outlet context={[width]} />
					</div>
				</div>
			</main>
			{/* END - editor */}

			{/* START - icon to display right sidebar */}
			{
				elementId?.id ?
					openedRightSidebarSection ?
						<button
							type="button"
							className='bg-white p-1 rounded-bl-lg shadow absolute right-80 top-[103px] z-10'
							onClick={(e) => {
								setOpenedRightSidebarSection(null)
								processChange();
							}}
						>
							<span class="mdi mdi-chevron-double-right text-lg text-[#757575]"></span>
						</button>
						:
						<button
							type="button"
							className='bg-white p-1 rounded-bl-lg shadow absolute right-0 top-[103px] z-10'
							onClick={(e) => {
								setOpenedRightSidebarSection("style")
								processChange();
							}}
						>
							<span class="mdi mdi-chevron-double-left text-lg text-[#757575]"></span>
						</button>
					:
					null
			}
			{/* END - icon to display right sidebar */}

			{/* START - left sidebar */}
			{
				openedRightSidebarSection &&
				<div className={`w-80 duration-500 fixed bottom-0 right-0  hidden  bg-white  border-l border-gray-300 lg:block top-[103px] z-40`}>
					{
						elementRef.current || componentRef.current || componentWithSettingsRef.current ?
							<RightSideBar />
							:
							<div className='flex items-center justify-center h-full text-gray-800'>
								<p className='w-56 text-center'>Please select anything to start editing</p>
							</div>
					}
				</div>
			}
			{/* END - left sidebar */}

		</div>
	)
}

const VideoLinkModal = ({ isOpen, setIsOpen, elementId }) => {
	const [link, setLink] = useState("");

	const { Bdispatch } = useContext(context);

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-60" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded bg-white text-left align-middle shadow-xl transition-all">
								<div className="flex gap-5 justify-between self-center px-5 py-2 w-full text-lg leading-6 text-black">
									<p className='text-xl leading-9 text-black font-medium'>Enter Media Link Here</p>
									<button type="button" onClick={() => setIsOpen(false)}>
										<XMarkIcon height={25} />
									</button>
								</div>

								<div className="flex flex-col w-full text-[#5C5C5C] space-y-2 px-4">
									<input
										className="text-sm text-gray-500 w-full border-gray-200 rounded-lg py-2.5"
										placeholder='https://youtu.be/dOoVFyKNynY?si=w1eUvtv5c_pimXi0'
										onChange={(e) => setLink(e.target.value)}
									/>
								</div>

								<div className="flex gap-4 w-full items-center justify-end pb-4 px-4 text-base leading-4 whitespace-nowrap mt-4">
									<button
										type='button'
										className="2 text-indigo-600 font-semibold"
										onClick={() => setIsOpen(false)}
									>
										Cancel
									</button>
									<button
										type='button'
										className="px-6 py-2 w-fit text-base text-white bg-blue-800 font-semibold rounded-md"
										onClick={async () => {
											setIsOpen(false)
											await Bdispatch({ type: "UPDATE_MEDIA", id: elementId.id, element: link })
										}}
									>
										Save
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}