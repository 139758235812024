import React, { useContext, useEffect, useRef, useState } from 'react'
import ColorPicker from './ColorPicker'
import Property from './Property'
import { Listbox } from '@headlessui/react'
import { context } from '../../../context/Context'
import { updateDesign } from './Style'

function AnimationEffects({ valueTypes, setValueTypes, setElementBreakpointSpecificStyleObject, setElementAllBreakpointsStyleObject, elementBreakpointSpecificStyleObject, updateDesign, elementId }) {
	const [openedSections, setOpenedSections] = useState({
		boxShadow: false,
		filters: false
	})

	const [filterType, setFilterType] = useState({
		displayName: "Blur",
		key: "blur",
		unit: "px"
	})

	const [boxShadow, setBoxShadow] = useState({
		X: '0px',
		Y: '0px',
		S: '0px',
		B: '0px',
		C: 'rgba(0,0,0,1)',
	})
	const filterTypes = [{
		displayName: "Blur",
		key: "blur",
		unit: "px"
	}, {
		displayName: "Contrast",
		key: "contrast",
		unit: ""
	},
	{
		displayName: "GrayScale",
		key: "grayscale",
		unit: "%"
	},
	{
		displayName: "Hue Rotate",
		key: "hueRotate",
		unit: "deg"
	},
	{
		displayName: "Invert",
		key: "invert",
		unit: "%"
	},
	{
		displayName: "Saturate",
		key: "saturate",
		unit: ""
	},
	{
		displayName: "Sepia",
		key: "sepia",
		unit: "%"
	},
	{
		displayName: "Backdrop Blur",
		key: "backdropBlur",
		unit: "px"
	},
	{
		displayName: "Bacdrop Contrast",
		key: "backdropContrast",
		unit: ""
	},
	{
		displayName: "Backdrop Grayscale",
		key: "backdropGrayscale",
		unit: "%"
	},
	{
		displayName: "Backdrop Hue Rotate",
		key: "backdropHueRotate",
		unit: "deg"
	},
	{
		displayName: "Backdrop Invert",
		key: "backdropInvert",
		unit: "%"
	},
	{
		displayName: "Backdrop Opacity",
		key: "backdropOpacity",
		unit: ""
	},
	{
		displayName: "Backdrop Saturate",
		key: "backdropSaturate",
		unit: ""
	},
	{
		displayName: "Backdrop Sepia",
		key: "backdropSepia",
		unit: "%"
	},]

	const timerref = useRef();
	// tabs for shadow box
	const [boxType, setBoxType] = useState('');

	const timerRef = useRef();

	useEffect(() => {

		const shadow = elementBreakpointSpecificStyleObject?.["boxShadow"]?.split("_")
		const Y = shadow?.[0] || "0px"
		const X = shadow?.[1] || "0px"
		const S = shadow?.[2] || "0px"
		const B = shadow?.[3] || "0px"
		const C = shadow?.[4] || "rgba(0,0,0,1)"
		setBoxShadow({
			X, Y, S, B, C
		})

		if(elementBreakpointSpecificStyleObject?.["boxShadow"]?.includes("inset")){
			setBoxType("inset")
		}
	}, [])
	return (
		<div className=''>
			<div className='p-3 flex items-center justify-between gap-4'>
				<span>Opacity</span>
				<input type='range' min={0} max={100}
					onChange={(e) => {
						setElementBreakpointSpecificStyleObject(
							(prev) => {
								return {
									...prev,
									opacity:
										e.target.value /
										100,
								};
							}
						);
						clearTimeout(timerref.current);
						timerref.current = setTimeout(
							() => {
								updateDesign(
									"opacity",
									e.target.value / 100
								);
							},
							1000
						);
					}}
					value={
						elementBreakpointSpecificStyleObject?.opacity *
						100
					}
					className='w-full h-0.5 mb-6 ml-6 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
				<div className='flex items-center'>
					<input
						type="number"
						max={100}
						onChange={(e) => {
							setElementBreakpointSpecificStyleObject(
								(prev) => {
									return {
										...prev,
										opacity:
											e.target.value /
											100,
									};
								}
							);
							clearTimeout(timerref.current);
							timerref.current = setTimeout(
								() => {
									updateDesign(
										"opacity",
										e.target.value / 100
									);
								},
								1000
							);
						}}
						value={
							elementBreakpointSpecificStyleObject?.opacity *
							100
						}
						className="w-[36px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9]"
					/>
					<div className='py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 text-xs rounded-r'>%</div>
				</div>
			</div>
			<div>
				<div
					className={`flex items-center justify-between py-1.5 px-3 border border-gray-300 cursor-pointer ${openedSections.boxShadow == false
						? " border-b"
						: ""
						}`}
					onClick={() => {
						setOpenedSections((prev) => {
							return {
								...prev,
								boxShadow: !prev.boxShadow,
							};
						});
					}}
				>
					<div className=" text-[#757575">
						Box Shadows
					</div>

					{openedSections.boxShadow == false ? (
						<span class="mdi mdi-plus text-lg"></span>
					) : (
						<span class="mdi mdi-minus text-lg"></span>
					)}
				</div>
				{
					openedSections.boxShadow &&
					<div className='p-3 pl-6 flex flex-col gap-2'>
						<div className=''>
							<div className='flex items-center justify-between'>
								<span>Type</span>
								<div className='flex gap-1 items-center'>
									<button
										type="button"
										onClick={() => setBoxType('inset')}
										className={`flex items-center gap-1 py-1 w-full text-xs w-fit leading-5 px-2 rounded-md border border-[#D9D9D9] ${boxType == 'inset' ? 'bg-[#D9D9D9]' : 'bg-white'}`}
									>
										Inside
									</button>
									<button
										type="button"
										onClick={() => (setBoxType(''))}
										className={`flex items-center gap-1 py-1 w-full text-xs w-fit leading-5 px-2 rounded-md border border-[#D9D9D9] ${boxType != 'inset' ? 'bg-[#D9D9D9]' : 'bg-white'}`}
									>
										Outside
									</button>
								</div>
							</div>
						</div>
						<div className="flex items-center justify-between  ">
							<div className="font-medium text-gray-900">
								Color
							</div>
							<ColorPicker
								setElementBreakpointSpecificStyleObject={
									setElementBreakpointSpecificStyleObject
								}
								setElementAllBreakpointsStyleObject={
									setElementAllBreakpointsStyleObject
								}
								elementBreakpointSpecificStyleObject={
									elementBreakpointSpecificStyleObject
								}
								updateDesign={updateDesign}
								field={"boxShadow"}
								boxShadow={boxShadow}
								setBoxShadow={setBoxShadow}
								boxType={boxType}
							/>
						</div>
						<div className='flex gap-2 justify-between items-center'>
							<span>X</span>
							<input type='range' min={-100} max={100} className='w-full h-0.5 mb-6 ml-9 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto'

								value={parseInt(boxShadow?.["X"]?.replace(/[!@#$%^&*az]/g, ""))}
								onChange={(e) => {
									console.log(parseInt(boxShadow["X"]?.replace(/[!@#$%^&*az]/g, "")))
									setElementBreakpointSpecificStyleObject((prev) => {
										return {
											...prev,
											["boxShadow"]: `${boxShadow?.Y}_${e.target.value}px_${boxShadow?.S}_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
										};
									}
									);
									setBoxShadow({
										...boxShadow,
										X: `${e.target.value}px`
									})
									clearTimeout(timerRef.current);
									timerRef.current = setTimeout(
										() => {
											updateDesign(
												"boxShadow",
												`${boxShadow?.Y}_${e.target.value}px_${boxShadow?.S}_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
											);
										},
										1000
									);
								}}
							/>
							<InputForBoxShadow keyName="X" value="" unit="px" boxShadow={boxShadow} setBoxShadow={setBoxShadow} boxType={boxType} />
						</div>
						<div className='flex gap-2 justify-between items-center'>
							<span>Y</span>
							<input type='range' min={-100} max={100} className='w-full h-0.5 mb-6 ml-9 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto'
								value={parseInt(boxShadow["Y"]?.replace(/[!@#$%^&*az]/g, ""))}
								onChange={(e) => {
									setElementBreakpointSpecificStyleObject((prev) => {
										return {
											...prev,
											["boxShadow"]: `${e.target.value}px_${boxShadow?.X}_${boxShadow?.S}_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
										};
									}
									);
									setBoxShadow({
										...boxShadow,
										Y: `${e.target.value}px`
									})
									clearTimeout(timerRef.current);
									timerRef.current = setTimeout(
										() => {
											updateDesign(
												"boxShadow",
												`${e.target.value}px_${boxShadow?.X}_${boxShadow?.S}_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
											);
										},
										1000
									);
								}}
							/>
							<InputForBoxShadow keyName="Y" value="" unit="px" boxShadow={boxShadow} setBoxShadow={setBoxShadow} boxType={boxType} />
						</div>
						<div className='flex gap-2 justify-between items-center'>
							<span>Spread</span>
							<input type='range' min={-100} max={100} className='w-full h-0.5 mb-6 ml-5 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto'
								value={parseInt(boxShadow["S"]?.replace(/[!@#$%^&*az]/g, ""))}
								onChange={(e) => {
									setElementBreakpointSpecificStyleObject((prev) => {
										return {
											...prev,
											["boxShadow"]: `${boxShadow?.Y}_${boxShadow?.X}_${boxShadow?.S}_${e.target.value}px_${boxShadow?.C}_${boxType}`
										};
									}
									);
									setBoxShadow({
										...boxShadow,
										S: `${e.target.value}px`
									})
									clearTimeout(timerRef.current);
									timerRef.current = setTimeout(
										() => {
											updateDesign(
												"boxShadow",
												`${boxShadow?.Y}_${boxShadow?.X}_${boxShadow?.S}_${e.target.value}px_${boxShadow?.C}_${boxType}`
											);
										},
										1000
									);
								}}
							/>
							<InputForBoxShadow keyName="S" value="" unit="px" boxShadow={boxShadow} setBoxShadow={setBoxShadow} boxType={boxType} />
						</div>
						<div className='flex gap-2 justify-between items-center'>
							<span>Blur</span>
							<input type='range' min={-100} max={100} className='w-full h-0.5 mb-6 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto'
								value={parseInt(boxShadow["B"]?.replace(/[!@#$%^&*az]/g, ""))}
								onChange={(e) => {
									setElementBreakpointSpecificStyleObject((prev) => {
										return {
											...prev,
											["boxShadow"]: `${boxShadow?.Y}_${boxShadow?.X}_${e.target.value}px_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
										};
									}
									);
									setBoxShadow({
										...boxShadow,
										B: `${e.target.value}px`
									})
									clearTimeout(timerRef.current);
									timerRef.current = setTimeout(
										() => {
											updateDesign(
												"boxShadow",
												`${boxShadow?.Y}_${boxShadow?.X}_${e.target.value}px_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
											);
										},
										1000
									);
								}}
							/>
							<InputForBoxShadow keyName="B" value="" unit="px" boxShadow={boxShadow} setBoxShadow={setBoxShadow} boxType={boxType} />
						</div>
					</div>
				}
				<div className='p-3 pl-9 border-t'>
					<ol className='list-disc mx-3'>
						<>
							{
								elementBreakpointSpecificStyleObject["boxShadow"] &&
								<li>
									<div className='flex gap-2 justify-between'>
										<div className='flex items-center gap-2 '>
											<span className='text-xs'>Box Shadow: {boxShadow["Y"]}_{boxShadow["X"]}_{boxShadow["S"]}_{boxShadow["B"]}_{boxShadow["C"]} </span>
											{/* <div className='w-3 h-3 rounded bg-[#D9D9D9]' /> */}
										</div>
										<button type="button">
											<span class="mdi mdi-delete text-base text-[#757575]"
												onClick={(e) => {
													delete elementBreakpointSpecificStyleObject["boxShadow"]
													updateDesign(
														"boxShadow",
														""
													);
													setElementBreakpointSpecificStyleObject({
														...elementBreakpointSpecificStyleObject
													});
													setBoxShadow({
														X: "0px",
														Y: "0px",
														S: "0px",
														B: "0px",
														C: "rgba(0, 0, 0, 1)"
													})
												}}
											></span>
										</button>
									</div>
								</li>
							}</>
					</ol>
				</div>
			</div>
			<div>
				<div
					className={`flex items-center justify-between py-1.5 px-3 border border-gray-300 cursor-pointer ${openedSections.filters == true
						? " border-b"
						: ""
						}`}
					onClick={() => {
						setOpenedSections((prev) => {
							return {
								...prev,
								filters: !prev.filters,
							};
						});
					}}
				>
					<div className=" text-[#757575">
						Filters
					</div>

					{openedSections.filters == false ? (
						<span class="mdi mdi-plus text-lg"></span>
					) : (
						<span class="mdi mdi-minus text-lg"></span>
					)}
				</div>
				{
					openedSections.filters &&
					<div className='p-3 pl-6 flex flex-col gap-2'>
						<div className='w-full flex items-center justify-between'>
							<span>Filter</span>
							<select onChange={(e) => setFilterType(JSON.parse(e.target.value))} id="countries" class="w-fit px-2 py-1 border border-[#D9D9D9] text-xs rounded text-[#373C47] focus:ring-0  focus:border-[#D9D9D9]">
								{filterTypes?.map(item => <option value={JSON.stringify(item)}>{item?.displayName}</option>)}
							</select>
						</div>
						{(filterType?.displayName !== 'Drop Shadow' && filterType?.displayName !== 'Backdrop Opacity') && <div className=''>
							{/* <div className='flex items-center justify-between'>
								<span>Type</span>
								<div className='flex gap-1 items-center'>
									<button type="button" onClick={() => (setBoxType('inside'))} className={
										`flex items-center gap-1 py-1 w-full text-xs w-fit leading-5 px-2 rounded-md border border-[#D9D9D9] ${boxType == 'inside' ? 'bg-[#D9D9D9]' : 'bg-white'}`
									}
									>Element</button>
									<button type="button" onClick={() => (setBoxType('outside'))} className={
										`flex items-center gap-1 py-1 w-full text-xs w-fit leading-5 px-2 rounded-md border border-[#D9D9D9] ${boxType == 'outside' ? 'bg-[#D9D9D9]' : 'bg-white'}`
									}
									>Backdrop</button>
								</div>
							</div> */}
						</div>}
						{filterType?.displayName !== 'Drop Shadow' && filterType?.displayName !== 'Backdrop Opacity' ?
							<div className='flex gap-2 justify-between items-center'>
								<span>{filterType?.displayName == 'Blur' ? "Radius" : "Amount"}</span>
								<input
									type='range'
									min={0}
									max={(filterType?.displayName === 'Hue Rotate' || filterType?.displayName === 'Backdrop Hue Rotate') ? 360 : 100} className='w-full h-0.5 mb-6 ml-2 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto'
									value={parseInt(
										elementBreakpointSpecificStyleObject?.[
											filterType?.key
										]?.replace(/[!@#$%^&*a-z-]/g, "") || 0
									)}
									onChange={(e) => {
										console.log(filterType, "filterType")
										setElementBreakpointSpecificStyleObject((prev) => {
											return {
												...prev,
												[filterType?.key]: `${e
													.target
													.value
													}`
											};
										}
										);
										clearTimeout(timerRef.current);
										timerRef.current = setTimeout(
											() => {
												updateDesign(
													filterType?.key,
													`${e.target.value}${filterType?.unit}`
												);
											},
											1000
										);
									}}
								/>
								<div className="text-xs flex items-center">
									{
										<input
											type="number"
											className="w-[50px] px-2 py-1 border  border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9]"
											value={parseInt(
												elementBreakpointSpecificStyleObject?.[
													filterType?.key
												]?.replace(/[!@#$%^&*a-z-]/g, "") || 0
											)}

											onChange={(
												e
											) => {
												setElementBreakpointSpecificStyleObject(
													(
														prev
													) => {
														return {
															...prev,
															[filterType?.key]: `${e
																.target
																.value
																}${filterType?.unit}`,
														};
													}
												);
											}}
											onKeyUp={(
												e
											) => {
												if (
													e.key ==
													"Enter"
												) {
													updateDesign(
														filterType?.key,
														`${e
															.target
															.value
														}${filterType?.unit}`
													);
												}
											}}
											onBlur={(e) => {
												updateDesign(
													filterType?.key,
													`${e
														.target
														.value
													}${filterType?.unit}`
												);
											}}
										/>
									}

									{filterType?.unit &&
										<div className='cursor-pointer py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1  rounded-r'>
											{filterType?.unit}
										</div>
									}
								</div>
							</div>
							: <>{
								filterType?.displayName === 'Drop Shadow' &&
								<div className='flex flex-col gap-2'>
									<div className="flex items-center justify-between  ">
										<div className="font-medium text-gray-900">
											Color
										</div>
										<ColorPicker
											setElementBreakpointSpecificStyleObject={
												setElementBreakpointSpecificStyleObject
											}
											setElementAllBreakpointsStyleObject={
												setElementAllBreakpointsStyleObject
											}
											elementBreakpointSpecificStyleObject={
												elementBreakpointSpecificStyleObject
											}
											updateDesign={updateDesign}
											field={"color"}
										/>
									</div>
									<div className='flex gap-2 justify-between items-center'>
										<span>X</span>
										<input type='range' min={0} max={100} className='w-full h-0.5 mb-6 ml-9 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
										<Property elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} propertyData={{
											// displayName: "Height",
											propertyName: "height",
											options: [
												"auto",
												"min-content",
												"max-content",
												"fit-content",
												"px",
												"%",
												"vh",
												"vw"
											],
											logicalOptions: ["auto", 'min-content', "max-content", "fit-content"]
										}} setElementBreakpointSpecificStyleObject={setElementAllBreakpointsStyleObject}
											setValueTypes={setValueTypes} updateDesign={updateDesign} valueTypes={valueTypes} />
									</div>
									<div className='flex gap-2 justify-between items-center'>
										<span>Y</span>
										<input type='range' min={0} max={100} className='w-full h-0.5 mb-6 ml-9 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
										<Property elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} propertyData={{
											// displayName: "Height",
											propertyName: "height",
											options: [
												"auto",
												"min-content",
												"max-content",
												"fit-content",
												"px",
												"%",
												"vh",
												"vw"
											],
											logicalOptions: ["auto", 'min-content', "max-content", "fit-content"]
										}} setElementBreakpointSpecificStyleObject={setElementAllBreakpointsStyleObject}
											setValueTypes={setValueTypes} updateDesign={updateDesign} valueTypes={valueTypes} />
									</div>
									<div className='flex gap-2 justify-between items-center'>
										<span>Blur</span>
										<input type='range' min={0} max={100} className='w-full h-0.5 mb-6 ml-5 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
										<Property elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} propertyData={{
											// displayName: "Height",
											propertyName: "height",
											options: [
												"auto",
												"min-content",
												"max-content",
												"fit-content",
												"px",
												"%",
												"vh",
												"vw"
											],
											logicalOptions: ["auto", 'min-content', "max-content", "fit-content"]
										}} setElementBreakpointSpecificStyleObject={setElementAllBreakpointsStyleObject}
											setValueTypes={setValueTypes} updateDesign={updateDesign} valueTypes={valueTypes} />
									</div>
								</div>
							}
								{
									filterType?.displayName === 'Backdrop Opacity' && <div className='flex gap-2 justify-between items-center'>
										<span></span>
										<input type='range' min={0} max={100} className='w-full h-0.5 mb-6 ml-10 bg-[#757575] rounded-lg appearance-none cursor-pointer range-sm !my-auto' />
										<div className='flex items-center '>
											<input
												type="number"
												max={100}
												className="w-[36px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9]"
											/>
											<div className='py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 text-xs rounded-r'>%</div>
										</div>
									</div>
								}
							</>}
					</div>
				}
				<div className='p-3 pl-9 border-t'>
					<ol className='list-disc mx-3'>
						{/* {console.log(elementBreakpointSpecificStyleObject, "SABF")} */}
						{
							filterTypes?.map((data, i) => (
								<>
									{
										elementBreakpointSpecificStyleObject[data?.key] &&
										<li>
											<div className='flex gap-2 justify-between'>
												<div className='flex items-center gap-2 '>
													<span className='text-xs'>{data?.displayName}: {elementBreakpointSpecificStyleObject[data?.key]} </span>
													<div className='w-3 h-3 rounded bg-[#D9D9D9]' />
												</div>
												<button type="button">
													<span
														class="mdi mdi-delete text-base text-[#757575]"
														onClick={(e) => {
															delete elementBreakpointSpecificStyleObject[data?.key]
															updateDesign(
																filterType?.key,
																""
															);
															setElementBreakpointSpecificStyleObject({
																...elementBreakpointSpecificStyleObject
															});
														}}
													></span>
												</button>
											</div>
										</li>
									}</>
							))
						}
					</ol>
				</div>
			</div>
		</div >
	)
}

export default AnimationEffects



const InputForBoxShadow = ({ keyName, value, unit, boxShadow, setBoxShadow, boxType }) => {
	const {
		elementBreakpointSpecificStyleObject,
		setElementBreakpointSpecificStyleObject
	} = useContext(context)

	return (
		<div className="text-xs flex items-center">
			{
				<input
					type="number"
					className="w-[50px] px-2 py-1 border  border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9]"
					// value={parseInt(
					// 	elementBreakpointSpecificStyleObject?.[
					// 		key
					// 	]?.replace(/[!@#$%^&*a-z-]/g, "") || 0
					// )}
					value={parseInt(boxShadow[keyName]?.replace(/[!@#$%^&*az]/g, ""))}

					onChange={(
						e
					) => {
						if (keyName == "X") {
							setElementBreakpointSpecificStyleObject({
								...elementBreakpointSpecificStyleObject,
								"boxShadow":
									`${boxShadow?.Y}_${e?.target?.value}px_${boxShadow?.S}_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
							});
						}
						else if (keyName == "Y") {
							setElementBreakpointSpecificStyleObject({
								...elementBreakpointSpecificStyleObject,
								"boxShadow":
									`${e.target.value}px_${boxShadow?.X}_${boxShadow?.S}_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
							}
							);
						}
						else if (keyName == "S") {
							setElementBreakpointSpecificStyleObject({
								...elementBreakpointSpecificStyleObject,
								"boxShadow":
									`${boxShadow?.Y}_${boxShadow?.X}_${e.target.value}px_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
							}
							);
						}
						else if (keyName == "B") {
							setElementBreakpointSpecificStyleObject({
								...elementBreakpointSpecificStyleObject,
								"boxShadow":
									`${boxShadow?.Y}_${boxShadow?.X}_${boxShadow?.S}_${e.target.value}px_${boxShadow?.C}_${boxType}`
							}
							);
						}

						setElementBreakpointSpecificStyleObject(
							(
								prev
							) => {
								return {
									...prev,
									["boxShadow"]: `${value}`,
								};
							}
						);

						setBoxShadow({
							...boxShadow,
							[keyName]: `${e.target.value}${unit}`
						})
					}}
					onKeyUp={(
						e
					) => {
						if (
							e.key ==
							"Enter"
						) {
							if (keyName == "X") {
								updateDesign(
									"boxShadow",
									`${boxShadow?.Y}_${e?.target?.value}px_${boxShadow?.S}_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
								);
							}
							else if (keyName == "Y") {
								updateDesign(
									"boxShadow",
									`${e.target.value}px_${boxShadow?.X}_${boxShadow?.S}_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
								);
							}
							else if (keyName == "S") {
								updateDesign(
									"boxShadow",
									`${boxShadow?.Y}_${boxShadow?.X}_${e.target.value}px_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
								);
							}
							else if (keyName == "B") {
								updateDesign(
									"boxShadow",
									`${boxShadow?.Y}_${boxShadow?.X}_${boxShadow?.S}_${e.target.value}px_${boxShadow?.C}_${boxType}`
								);
							}
						}
					}}
					onBlur={(e) => {
						if (keyName == "X") {
							updateDesign(
								"boxShadow",
								`${boxShadow?.Y}_${e?.target?.value}px_${boxShadow?.S}_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
							);
						}
						else if (keyName == "Y") {
							updateDesign(
								"boxShadow",
								`${e.target.value}px_${boxShadow?.X}_${boxShadow?.S}_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
							);
						}
						else if (keyName == "S") {
							updateDesign(
								"boxShadow",
								`${boxShadow?.Y}_${boxShadow?.X}_${e.target.value}px_${boxShadow?.B}_${boxShadow?.C}_${boxType}`
							);
						}
						else if (keyName == "B") {
							updateDesign(
								"boxShadow",
								`${boxShadow?.Y}_${boxShadow?.X}_${boxShadow?.S}_${e.target.value}px_${boxShadow?.C}_${boxType}`
							);
						}
					}}
				/>
			}

			<div className='cursor-pointer py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1  rounded-r'>
				{unit}
			</div>
		</div>
	)
}