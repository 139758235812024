import React, { useState, useEffect } from 'react';
import { BlockWrapper } from '../Sortable';
function DynamicComponent({ block, ...props }) {
	const [singleData, setSingleData] = useState(null);
	useEffect(() => {
		if (block?.data?.type == 'props') {
			eval(block?.data?.singleData);
		}
	}, [props.fileFieldValues, props.singleData, props.allData]);
	useEffect(() => {
		if (block?.data?.type == 'dynamic') {
			setSingleData(block.data.staticData);
		}
	}, []);
	return (
		<>
			{
				block.children.map((value, index) => {
					return <BlockWrapper {...props} block={value} singleData={singleData} setSingleData={setSingleData} key={index} />;
				})
			}
		</>

	);
}

export default DynamicComponent;