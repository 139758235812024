import { useContext, useEffect, useRef, useState } from 'react';
import { classNames } from '../../../libraries/utility';
import { Popover, Listbox } from '@headlessui/react';
import { XMarkIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { SketchPicker } from 'react-color';
import { context } from '../../../context/Context';
import { dbCall } from '../../../common/db';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { processChange } from '../../../layouts/NewEditor';


export function Theme() {
	const [search, setSearch] = useState([]);
	const [defaultTheme, setDefaultTheme] = useState({});
	const {
		setTheme,
		currentTheme,
		setCurrentTheme,
		currentPlatform,
		elementRef,
		formElementRef,
		componentRef,
		setCordinates,
		openedRightSidebarSection,
		setOpenedRightSidebarSection,
		openedLeftSidebarSection,
		setOpenedLeftSidebarSection
	} = useContext(context)
	const [workingOnCustomTheme, setWorkingOnCustomTheme] = useState(false);
	const [currentEditingTheme, setCurrentEditingTheme] = useState(null);
	const [showEditScreen, setShowEditScreen] = useState(false);
	const predefinedThemes = [
		{
			name: "Energetic",
			textColor: "#A084E8",
			primary_color: "#6F61C0",
			secondary_color: "#8BE8E5",
			bg_color: "#D5FFE4",
			primary_font_size: '48px',
			secondary_font_size: "32px",
			font_size: "16px",
			primary_font_family: ["ABeeZee"],
			url: "https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tKukbcHCpE.ttf"
		},
		{
			name: "Vibrant",
			textColor: "#5C8374",
			primary_color: "#183D3D",
			secondary_color: "#040D12",
			bg_color: "#93B1A6",
			primary_font_size: '48px',
			secondary_font_size: "32px",
			font_size: "16px",
			primary_font_family: ["ABeeZee"],
			url: "https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tKukbcHCpE.ttf"
		},
		{
			name: "Soft",
			textColor: "#79155B",
			primary_color: "#C23373",
			secondary_color: "#F6635C",
			bg_color: "#FFBA86",
			primary_font_size: '48px',
			secondary_font_size: "32px",
			font_size: "16px",
			primary_font_family: ["ABeeZee"],
			url: "https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tKukbcHCpE.ttf"
		},
		{
			name: "Glarimous",
			textColor: "#FBA1B7",
			primary_color: "#FFD1DA",
			secondary_color: "#FFF0F5",
			bg_color: "#FFDBAA",
			primary_font_size: '48px',
			secondary_font_size: "32px",
			font_size: "16px",
			primary_font_family: ["ABeeZee"],
			url: "https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tKukbcHCpE.ttf"
		},
		{
			name: "Portfolio",
			textColor: "#322653",
			primary_color: "#8062D6",
			secondary_color: "#9288F8",
			bg_color: "#FFD2D7",
			primary_font_size: '48px',
			secondary_font_size: "32px",
			font_size: "16px",
			primary_font_family: ["ABeeZee"],
			url: "https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tKukbcHCpE.ttf"
		}
	];
	const [customThemes, setCustomThemes] = useState([]);
	const [count, setCount] = useState(0);
	const [isSearchingFonts, setIsSearchingFonts] = useState(false);
	const [fonts, setFonts] = useState([]);
	const [fontsToDisplay, setFontsToDisplay] = useState(10);
	const [filteredFonts, setFilteredFonts] = useState([]);
	const params = useParams()
	const timerref = useRef();
	useEffect(() => {
		fetchData();
	}, [])
	const fetchData = async () => {
		try {
			const response = await dbCall.get(`/site/get-default-theme/${params.siteId}`);
			const response1 = await dbCall.get(`/site/get-custom-themes/${params.siteId}`);
			const response2 = await axios.get(
				"https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCYpcvr0oikaQbdx2XR-cYBXPFx4X52Wc4"
			);
			setDefaultTheme(response.data);
			setCustomThemes(response1.data.customThemes ? response1.data.customThemes : []);
			setFonts((prev) => {
				return response2.data.items;
			});
			setCount(response1.data.customThemes ? response1.data.customThemes.length : 0)
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!")
		}
	}

	const deleteCustomTheme = async (index, value) => {
		try {
			const newArray = [];
			for (let i = 0; i < customThemes.length; i++) {
				if (i != index) {
					newArray.push(customThemes[i]);
				}
			}
			await dbCall.put('/site/update-custom-themes', {
				siteId: params.siteId,
				customThemes: newArray
			})
			setCustomThemes((prev) => {
				return newArray;
			})
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!")
		}
	}
	const updateTheme = async (value) => {
		try {
			const { url, ...rest } = value;
			let newTheme;
			if (url) {
				let match = false;
				for (let i = 0; i < currentTheme.fonts?.length; i++) {
					if (value.primary_font_family[0] == currentTheme.fonts[i].name) {
						match = true;
						break;
					}
				}
				if (match == false) {
					if (!currentTheme.fonts) {
						currentTheme.fonts = [];
					}
					const fonts = currentTheme.fonts;
					fonts.push({
						name: value.primary_font_family[0],
						url: url
					});
					newTheme = {
						fonts: fonts,
						...rest
					}
				} else {
					newTheme = {
						fonts: currentTheme.fonts,
						...rest
					}
				}
			} else {
				newTheme = {
					...rest,
					fonts: currentTheme.fonts
				}
			}
			await dbCall.put('/site/update-theme', {
				siteId: params.siteId,
				theme: newTheme,
				platform: currentPlatform
			});
			setCurrentTheme(newTheme)

			setTheme((prev) => {
				return {
					...prev,
					[currentPlatform == "app" ? "appTheme" : "theme"]: newTheme
				}
			});
			setShowEditScreen(false);
			setWorkingOnCustomTheme(false)
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!")
		}
	}

	const updateCustomTheme = async () => {
		try {
			if (currentEditingTheme.name) {
				const newArray = [];
				for (let i = 0; i < customThemes.length; i++) {
					if (customThemes[i].name == currentEditingTheme.name) {
						newArray.push({
							...currentEditingTheme
						})
					} else {
						newArray.push(customThemes[i]);
					}
				}
				await dbCall.put('/site/update-custom-themes', {
					siteId: params.siteId,
					customThemes: newArray
				});
				setCustomThemes((prev) => {
					return newArray;
				});


			} else {
				const newArray = [...customThemes, {
					name: `Custom ${count + 1}`,
					...currentEditingTheme
				}]

				await dbCall.put('/site/update-custom-themes', {
					siteId: params.siteId,
					customThemes: newArray
				});
				setCustomThemes((prev) => {
					return newArray;
				})
				setCount(count + 1)
			}
			setShowEditScreen(false);
			setWorkingOnCustomTheme(false);
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!")
		}
	}

	return (
		<>
			<div>

				<button
					type="button"
					className={classNames(
						openedLeftSidebarSection == 'theme'
							? "bg-[#393939]"
							: "hover:bg-[#393939]",
						"rounded-md px-3 py-2 font-semibold text-white"
					)}
					id="step-three"
					onClick={() => {
						processChange()
						setOpenedRightSidebarSection(openedLeftSidebarSection ? openedRightSidebarSection : false)
						setOpenedLeftSidebarSection((prev) => {
							if (prev == 'theme') {
								return null;
							} else {
								return 'theme';
							}
						})

						if (elementRef.current != null) {
							elementRef.current.style.outline = "none";
							elementRef.current = null;
						}
						if (formElementRef.current != null) {
							formElementRef.current.style.outline = "none";
							formElementRef.current = null;
						}
						if (componentRef.current != null) {
							componentRef.current.style.outline = "none";
							componentRef.current = null;
						}

						setCordinates({
							cordinatesValue: {
								top: 0,
								left: 0
							}
						});
					}}
					title="Theme"
				>
					<span class="mdi mdi-brush text-2xl shrink-0"></span>

					<span className="sr-only">Theme</span>
				</button>
				{
					<div className={`${openedLeftSidebarSection == 'theme' ? "w-full opacity-100 z-[55]" : "w-0 opacity-0"} duration-500 flex max-w-sm flex-col bg-white shadow-xl fixed bottom-0 left-20 top-[103px]`}>
						<div className={`${openedLeftSidebarSection != 'theme' ? "hidden" : ""} px-4 py-3`}>
							<div className="flex items-start justify-between">
								<div className="text-base font-semibold leading-6 text-gray-900">
									Theme
								</div>
								<div className={`${openedLeftSidebarSection != 'theme' && "hidden opacity-0"} duration-700 flex items-center space-x-3`}>
									{
										showEditScreen == true ?
											<div className='flex items-center space-x-3'>
												<button
													type="button"
													className='text-white bg-indigo-600 py-1 px-2 rounded font-semibold text-sm'
													onClick={() => {
														if (workingOnCustomTheme == true) {
															updateCustomTheme();
														} else {
															updateTheme(currentEditingTheme);
														}
													}}
												>
													Save
												</button>
												<button
													type="button"
													className='rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
													onClick={() => {
														if (workingOnCustomTheme == true) {
															setWorkingOnCustomTheme(false);
														}
														const { fonts, ...theme } = currentTheme;
														setCurrentEditingTheme(theme);
														setShowEditScreen(false)
													}}
												>
													Cancel
												</button>
											</div>
											:
											<button
												type="button"
												className='rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
												onClick={() => {
													setWorkingOnCustomTheme(true);
													const { fonts, ...theme } = currentTheme;
													let url = ''
													for (let i = 0; i < fonts?.length; i++) {
														if (currentTheme.primary_font_family[0] == fonts[i].name) {
															url = fonts[i].url;
														}
													}
													setCurrentEditingTheme({
														...theme,
														url: url
													});
													setShowEditScreen(true);
												}}
											>
												Create Theme
											</button>
									}

									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										onClick={() => {
											setOpenedLeftSidebarSection(null)
										}}
									>
										<span className="sr-only">Close panel</span>
										<XMarkIcon className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
							</div>
						</div>
						<hr />
						<div className={`${openedLeftSidebarSection != 'theme' && "hidden"} flex-auto overflow-y-auto no-scrollbar p-4`}>
							{
								showEditScreen == true ?
									<div className="space-y-5">

										<div className='flex items-center justify-between'>
											<h2 className='font-semibold text-sm text-gray-700'>Text Color</h2>
											<ColorPicker field={'textColor'} setCurrentEditingTheme={setCurrentEditingTheme} currentEditingTheme={currentEditingTheme} />
										</div>

										<div className='flex items-center justify-between'>
											<h2 className='font-semibold text-sm  text-gray-700'>Background Color</h2>
											<ColorPicker field={'bg_color'} setCurrentEditingTheme={setCurrentEditingTheme} currentEditingTheme={currentEditingTheme} />
										</div>
										<div className='flex items-center justify-between'>
											<h2 className='font-semibold text-sm  text-gray-700'>Primary Color</h2>
											<ColorPicker field={'primary_color'} setCurrentEditingTheme={setCurrentEditingTheme} currentEditingTheme={currentEditingTheme} />
										</div>
										<div className='flex items-center justify-between '>
											<h2 className='font-semibold text-sm  text-gray-700'>Secondary Color</h2>
											<ColorPicker field={'secondary_color'} setCurrentEditingTheme={setCurrentEditingTheme} currentEditingTheme={currentEditingTheme} />
										</div>

										<div className='flex items-center justify-between '>
											<h2 className='font-semibold text-sm  text-gray-700'>Font Family</h2>
											<Listbox
												as={'div'}
												className={'relative text-sm'}
												value={currentEditingTheme?.primary_font_family?.[0]}
												onChange={(value) => {
													setCurrentEditingTheme({
														...currentEditingTheme,
														primary_font_family: [value.family],
														url: value?.files?.regular?.includes('https') ? `${value.files.regular}` : `https${value?.files?.regular?.slice(4)}`

													})
												}}
											>
												<Listbox.Button className="rounded py-1.5 px-2 border border-gray-300 flex items-center gap-3">
													<span
														style={{
															fontFamily:
																currentEditingTheme?.primary_font_family?.[0]
														}}
														className='whitespace-nowrap'
													>
														{currentEditingTheme?.primary_font_family?.[0]}
													</span>
													<span className='mdi mdi-chevron-down text-gray-400' />
												</Listbox.Button>

												<Listbox.Options
													className="absolute z-[51] max-h-60 mt-1 w-full overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300"
													onScroll={(e) => {
														if (isSearchingFonts == true) {
															if (filteredFonts.length > fontsToDisplay) {
																if (e.currentTarget.offsetHeight + e.currentTarget.scrollTop == e.currentTarget.scrollHeight + 2) {
																	if (
																		fontsToDisplay +
																		10 <=
																		filteredFonts.length
																	) {
																		setFontsToDisplay(
																			fontsToDisplay +
																			5
																		);
																	} else {
																		if (
																			(filteredFonts.length -
																				fontsToDisplay) %
																			10 !=
																			0
																		) {
																			setFontsToDisplay(
																				fontsToDisplay +
																				((filteredFonts.length -
																					fontsToDisplay) %
																					10)
																			);
																		} else {
																			setFontsToDisplay(
																				fontsToDisplay +
																				10
																			);
																		}
																	}
																}
															}
														} else {
															if (
																fonts.length >
																fontsToDisplay
															) {
																if (
																	e.currentTarget
																		.offsetHeight +
																	e
																		.currentTarget
																		.scrollTop ==
																	e.currentTarget
																		.scrollHeight +
																	2
																) {
																	if (
																		fontsToDisplay +
																		10 <=
																		fonts.length
																	) {
																		setFontsToDisplay(
																			fontsToDisplay +
																			10
																		);
																	} else {
																		if (
																			(fonts.length -
																				fontsToDisplay) %
																			10 !=
																			0
																		) {
																			setFontsToDisplay(
																				fontsToDisplay +
																				((fonts.length -
																					fontsToDisplay) %
																					10)
																			);
																		} else {
																			setFontsToDisplay(
																				fontsToDisplay +
																				10
																			);
																		}
																	}
																}
															}
														}
													}}
												>
													<div className="sticky top-0 bg-white z-[52]">
														<div className="relative shadow-lg">
															<svg
																className="w-4 h-4 text-gray-700 absolute left-1.5 top-1/4 pointer-events-none"
																aria-hidden="true"
																xmlns="http://www.w3.org/2000/svg"
																fill="none"
																viewBox="0 0 20 20"
															>
																<path
																	stroke="currentColor"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth="2"
																	d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
																/>
															</svg>
															<input
																type="search"
																className="border-0 bg-gray-100 focus:ring-0 w-full py-1.5 pr-2 pl-7 text-sm"
																placeholder="Search font"
																value={search}
																onChange={(e) => {
																	if (
																		fontsToDisplay !=
																		10
																	) {
																		setFontsToDisplay(
																			10
																		);
																	}
																	setSearch(
																		e.target
																			.value
																	);
																	if (
																		e.target
																			.value ==
																		""
																	) {
																		setIsSearchingFonts(
																			false
																		);
																		setFilteredFonts(
																			[]
																		);
																	} else {
																		setIsSearchingFonts(
																			true
																		);
																		clearTimeout(
																			timerref.current
																		);
																		timerref.current =
																			setTimeout(
																				() => {
																					const filterSection =
																						fonts.filter(
																							(
																								item
																							) =>
																								item.family
																									.toLowerCase()
																									?.includes(
																										e.target.value.toLowerCase()
																									)
																						);
																					setFilteredFonts(
																						filterSection
																					);
																				},
																				1000
																			);
																	}
																}}
																onKeyUp={(e) => {
																	if (
																		e.key ==
																		"Enter"
																	) {
																		clearTimeout(
																			timerref.current
																		);
																		const filterSection =
																			fonts.filter(
																				(
																					item
																				) =>
																					item.family
																						.toLowerCase()
																						?.includes(
																							e.target.value.toLowerCase()
																						)
																			);
																		setFilteredFonts(
																			filterSection
																		);
																	}
																}}
															/>
														</div>
													</div>
													{(isSearchingFonts == true
														? filteredFonts.slice(
															0,
															fontsToDisplay
														)
														: fonts.slice(
															0,
															fontsToDisplay
														)
													).map((value, index) => {
														if (value.files.regular) {
															const myFont =
																new FontFace(
																	value.family,
																	value?.files?.regular?.includes(
																		"https"
																	)
																		? `url(${value.files.regular})`
																		: `url('https${value?.files?.regular?.slice(
																			4
																		)}')`
																);
															myFont.load();
															document.fonts.add(
																myFont
															);
															return (
																<Listbox.Option
																	key={`breakpointmap_${index}`}
																	className={({
																		active
																	}) =>
																		`${active ? "bg-gray-100" : ""
																		} cursor-pointer`
																	}
																	value={value}
																>
																	{({
																		selected,
																	}) => (
																		<>
																			<div
																				className={`py-1.5 px-3 text-gray-900${selected
																					? " bg-indigo-100 font-medium"
																					: ""
																					}`}
																				style={{
																					fontFamily:
																						value.family,
																				}}
																			>
																				{
																					value.family
																				}
																			</div>
																		</>
																	)}
																</Listbox.Option>
															);
														}
													})}
												</Listbox.Options>
											</Listbox>
										</div>
										<div className='flex items-center justify-between '>
											<h2 className='font-semibold text-sm text-gray-700'>Font Size {currentEditingTheme.font_size != 'px' && `(${currentEditingTheme.font_size})`}</h2>
											<input onChange={(e) => {
												setCurrentEditingTheme({
													...currentEditingTheme,
													font_size: e.target.value + 'px'
												})

											}} type="number" placeholder='px' className='rounded h-8 w-[100px] border border-gray-300 focus:ring-indigo-600 placeholder:text-end text-gray-700 placeholder:text-gray-600 text-sm' value={currentEditingTheme.font_size.slice(0, currentEditingTheme.font_size.length - 2)} />
										</div>

										<div className='flex items-center justify-between '>
											<h2 className='font-semibold text-sm text-gray-700'>Primary Font Size {currentEditingTheme.primary_font_size != 'px' && `(${currentEditingTheme.primary_font_size})`}</h2>
											<input onChange={(e) => {
												setCurrentEditingTheme({
													...currentEditingTheme,
													primary_font_size: e.target.value + 'px'
												})

											}} type="number" placeholder='px' className='rounded h-8 w-[100px] border border-gray-300 focus:ring-indigo-600 placeholder:text-end text-gray-700 placeholder:text-gray-600 text-sm' value={currentEditingTheme.primary_font_size.slice(0, currentEditingTheme.primary_font_size.length - 2)} />
										</div>

										<div className='flex items-center justify-between '>
											<h2 className='font-semibold text-sm text-gray-700'>Secondary Font Size {currentEditingTheme.secondary_font_size != 'px' && `(${currentEditingTheme.secondary_font_size})`}</h2>
											<input onChange={(e) => {
												setCurrentEditingTheme({
													...currentEditingTheme,
													secondary_font_size: e.target.value + 'px'
												})

											}} type="number" placeholder='px' className='rounded h-8 w-[100px] border border-gray-300 focus:ring-indigo-600 placeholder:text-end text-gray-700 placeholder:text-gray-600 text-sm' value={currentEditingTheme.secondary_font_size.slice(0, currentEditingTheme.secondary_font_size.length - 2)} />
										</div>
									</div>
									:
									<div className=' space-y-7 '>
										<div className='space-y-2'>
											<div className='font-medium bg-indigo-600 text-white text-sm p-1 px-2 inline-block rounded'>Active theme</div>
											<div className='flex items-center space-x-2 w-full'>
												<div className='h-14 flex items-center w-[95%] rounded-lg overflow-hidden cursor-pointer ring ring-gray-300'>
													<div className={`ring ring-gray-300 w-1/4 bg-[${currentTheme.textColor}] h-full`}></div>
													<div className={`ring ring-gray-300 w-1/4 bg-[${currentTheme.bg_color}] h-full`}></div>
													<div className={`ring ring-gray-300 w-1/4 bg-[${currentTheme.primary_color}] h-full`}></div>
													<div className={`ring ring-gray-300 w-1/4 bg-[${currentTheme.secondary_color}] h-full`}></div>
												</div>
												<Popover className="relative ">
													<Popover.Button className={'w-full'}>
														<EllipsisVerticalIcon height={25} width={15} />
													</Popover.Button>

													<Popover.Panel className="absolute z-50 top-6 right-2 w-[100px] bg-white flex flex-col rounded-md shadow overflow-hidden border border-gray-300">
														<button
															type="button"
															className='text-sm font-medium	text-gray-900 hover:bg-gray-100 p-2 text-start'
															onClick={() => {
																const { fonts, ...theme } = currentTheme;
																setCurrentEditingTheme(theme);
																setWorkingOnCustomTheme(false)
																setShowEditScreen(true);
															}}
														>
															Edit
														</button>
													</Popover.Panel>
												</Popover>
											</div>

										</div>
										<div className='space-y-2'>
											<div className='font-medium bg-gray-200 text-sm p-1 px-2 inline-block rounded text-gray-900' >Default theme</div>
											<div className='h-14 flex items-center w-[95%] rounded-lg overflow-hidden cursor-pointer' onClick={() => {
												const { fonts, ...theme } = defaultTheme[`${currentPlatform == 'website' ? 'theme' : "appTheme"}`];
												updateTheme(theme);
											}} >
												<div className={`w-1/4 bg-[${defaultTheme[`${currentPlatform == 'website' ? 'theme' : "appTheme"}`]?.textColor}] h-full`}></div>
												<div className={`w-1/4 bg-[${defaultTheme[`${currentPlatform == 'website' ? 'theme' : "appTheme"}`]?.bg_color}] h-full`}></div>
												<div className={`w-1/4 bg-[${defaultTheme[`${currentPlatform == 'website' ? 'theme' : "appTheme"}`]?.primary_color}] h-full`}></div>
												<div className={`w-1/4 bg-[${defaultTheme[`${currentPlatform == 'website' ? 'theme' : "appTheme"}`]?.secondary_color}] h-full`}></div>
											</div>

										</div>
										<div className='space-y-2'>
											<div className='font-medium bg-gray-200 text-sm p-1 px-2 inline-block rounded text-gray-900'>Predefinded themes</div>
											<div className='space-y-5'>

												{
													predefinedThemes.map((value, index) => {

														return (
															<>
																<div className='space-y-2' key={index}>
																	<div className='font-medium text-sm text-gray-900'>{value.name}</div>
																	<div className='h-14 flex items-center w-[95%] rounded-lg overflow-hidden cursor-pointer' onClick={() => {
																		const { name, ...theme } = value;
																		const myFont = new FontFace(value.primary_font_family[0], `url(${value.url})`);
																		myFont.load();
																		document.fonts.add(myFont);
																		updateTheme(theme);
																	}}>
																		<div className={`w-1/4 bg-[${value.textColor}] h-full`}></div>
																		<div className={`w-1/4 bg-[${value.bg_color}] h-full`}></div>
																		<div className={`w-1/4 bg-[${value.primary_color}] h-full`}></div>
																		<div className={`w-1/4 bg-[${value.secondary_color}] h-full`}></div>
																	</div>

																</div>
															</>
														)
													})
												}
											</div>
										</div>
										{
											customThemes?.length > 0 &&
											<div className='space-y-2'>
												<div className='font-medium bg-gray-200 text-sm p-1 px-2 inline-block rounded text-gray-900'>Custom themes</div>
												<div className='space-y-5'>

													{
														customThemes.map((value, index) => {

															return (
																<>
																	<div className='space-y-2' key={index}>
																		<div className='font-medium text-sm text-gray-900'>{value.name}</div>
																		<div className='flex items-center space-x-2 w-full'>
																			<div className='h-14 flex items-center w-[95%] rounded-lg overflow-hidden cursor-pointer'
																				onClick={() => {
																					const { name, ...theme } = value;
																					const myFont = new FontFace(value.primary_font_family[0], `url(${value.url})`);
																					myFont.load();
																					document.fonts.add(myFont);
																					updateTheme(theme);
																				}}
																			>
																				<div className={`w-1/4 bg-[${value.textColor}] h-full`}></div>
																				<div className={`w-1/4 bg-[${value.bg_color}] h-full`}></div>
																				<div className={`w-1/4 bg-[${value.primary_color}] h-full`}></div>
																				<div className={`w-1/4 bg-[${value.secondary_color}] h-full`}></div>
																			</div>
																			<Popover className="relative">
																				<Popover.Button className={'w-full'}>
																					<EllipsisVerticalIcon height={25} width={15} />
																				</Popover.Button>

																				<Popover.Panel className="absolute z-50 top-6 right-2 w-[100px] rounded-md overflow-hidden bg-white border border-gray-300 text-sm font-medium	 text-gray-900">
																					<button
																						type="button"
																						className='w-full hover:bg-gray-100 p-2 text-start'
																						onClick={() => {
																							setWorkingOnCustomTheme(true);
																							setCurrentEditingTheme(value);
																							setShowEditScreen(true);
																						}}
																					>
																						Edit
																					</button>
																					<button
																						type="button"
																						className='w-full hover:bg-gray-100 p-2 text-start'
																						onClick={() => deleteCustomTheme(index, value)}
																					>
																						Delete
																					</button>
																				</Popover.Panel>
																			</Popover>
																		</div>
																	</div>
																</>
															)
														})
													}
												</div>
											</div>
										}
									</div>
							}
						</div>
					</div>
				}
			</div>

		</>
	);
}

const ColorPicker = ({ currentEditingTheme, setCurrentEditingTheme, field }) => {
	const colors = [
		"#F97316",
		"#EAB308",
		"#86EFAC",
		"#22C55E",
		"#BFDBFE",
		"#3B82F6",
		"#D0D4DA",
		"#F87171",
		"#A855F7"
	];

	return (
		<Popover className={'relative'}>
			<Popover.Button className={'p-[3px] bg-white rounded-[2px] border border-gray-300 '}>
				<div className={`w-[40px] h-[15px] bg-[${currentEditingTheme[field]}] rounded-[3px]`} />
			</Popover.Button>
			<Popover.Panel className={'absolute z-10 right-0'}>
				<SketchPicker
					color={currentEditingTheme[field]}
					onChange={(color) => {
						const value = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
						setCurrentEditingTheme({
							...currentEditingTheme, [field]: value
						});

					}}
					presetColors={colors}
				/>
			</Popover.Panel>

		</Popover>
	)
}