import React, { useContext, useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams } from 'react-router-dom';

import { context } from "../../../../context/Context";
import SingleProduct from './SingleProduct';
import { createElement } from 'react';


function Icon({ style, name }) {
    return (
        //@ts-ignore
        <span icon="true" name={name.replace("mdi mdi-", "")} source="MaterialCommunityIcons" className={`${name} ${style} flex items-center`} ></span>
    )
}

const responsive = (item) => {
    return {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: item.largeDesktop,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: item.desktop,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: item.tablet,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: item.mobile,
        },
    }
};

const Renderer = ({ schema, currentTheme }) => {
    return (
        createElement(
            schema.type,
            {
                id: schema.id,
                className: schema.css?.replaceAll("secondary_color", `[${currentTheme?.secondary_color}]`)
                    ?.replaceAll("bg_color", `[${currentTheme?.bg_color}]`)
                    ?.replaceAll("textColor", `[${currentTheme?.textColor}]`)
                    ?.replaceAll("primary_color", `[${currentTheme?.primary_color}]`)
                    ?.replaceAll("primary_font_size", `[${currentTheme?.primary_font_size}]`)
                    ?.replaceAll("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
                    ?.replaceAll("font_size", `[${currentTheme?.font_size}]`),
                ...schema.data,
                onClick: () => {

                    if (schema.events?.onClick) {
                        eval(schema.events.onClick);
                    } else {
                        return null;
                    }
                },
                style: {
                    fontFamily: schema.style?.fontFamily
                }
            },
            schema.children ? schema.children.map((value, index) => {
                return (
                    <Renderer schema={value} currentTheme={currentTheme} key={index} />
                )
            }) : schema.content
        )
    )
}


const CustomDot = ({ onClick, active }) => {
    return (
        <button type='button' onClick={() => onClick()} className={` w-4 h-4 mx-1 rounded-full ${active ? 'bg-[#7DB800]' : 'bg-gray-800'}`}>
        </button>
    )
}

const CustomLeftArrow = ({ onClick }) => {
    return (
        <button type='button' onClick={() => onClick()} className="absolute left-0">
            <Icon name="mdi mdi-arrow-left" style="text-2xl" />

        </button>
    );
};

const CustomRightArrow = ({ onClick }) => {
    return (
        <button type='button' onClick={() => onClick()} className="absolute right-0">
            <Icon name="mdi mdi-arrow-right" style="text-2xl" />
        </button>
    )
}


const CommonLeftArrow = ({ onClick, buttonCSS, children, currentTheme, ...props }) => {
    return (
        <button type='button' onClick={() => onClick()} className={buttonCSS?.replaceAll("secondary_color", `[${currentTheme?.secondary_color}]`)
            ?.replaceAll("bg_color", `[${currentTheme?.bg_color}]`)
            ?.replaceAll("textColor", `[${currentTheme?.textColor}]`)
            ?.replaceAll("primary_color", `[${currentTheme?.primary_color}]`)
            ?.replaceAll("primary_font_size", `[${currentTheme?.primary_font_size}]`)
            ?.replaceAll("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
            ?.replaceAll("font_size", `[${currentTheme?.font_size}]`)}>
            {children &&
                children?.map((childBlock) => {
                    return (
                        <Renderer key={childBlock.id} schema={childBlock} currentTheme={currentTheme} />
                    );
                })}
        </button>
    )
}

const CommonRightArrow = ({ onClick, buttonCSS, children, currentTheme, ...props }) => {

    return (
        <button type='button' onClick={() => onClick()} className={buttonCSS?.replaceAll("secondary_color", `[${currentTheme?.secondary_color}]`)
            ?.replaceAll("bg_color", `[${currentTheme?.bg_color}]`)
            ?.replaceAll("textColor", `[${currentTheme?.textColor}]`)
            ?.replaceAll("primary_color", `[${currentTheme?.primary_color}]`)
            ?.replaceAll("primary_font_size", `[${currentTheme?.primary_font_size}]`)
            ?.replaceAll("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
            ?.replaceAll("font_size", `[${currentTheme?.font_size}]`)}>
            {children &&
                children?.map((childBlock) => {
                    return (
                        <Renderer key={childBlock.id} schema={childBlock} currentTheme={currentTheme} />
                    );
                })}
        </button>
    )
}

const CommonDots = ({ onClick, active, activeCSS = "", inactiveCSS = "", commonCSS = "" }) => {
    return (
        <button type='button' onClick={() => onClick()} className={` @xs:hidden @sm:block ${commonCSS} ${active ? activeCSS : inactiveCSS}`}>
        </button>
    )
}



export default function DynamicCarousal({ block, currentTheme, handleClick, handleMouseOver, ...props }) {
    const [data, setData] = useState([]);

    //@ts-ignore
    const params = useParams()

    const { currentCategory } = useContext(context)

    const response = responsive(block?.data?.responsive ? block.data.responsive : {
        "largeDesktop": 1,
        "desktop": 1,
        "tablet": 1,
        "mobile": 1
    })

    //@ts-ignore
    const fetchData = async () => {
        try {
            const data = await fetch(`${process.env.API_URL}/${block?.data?.url}/${JSON.stringify({ [block?.data?.query?.type]: currentCategory })}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const response = await data.json();
            //@ts-ignore
            setData([...response])
        }
        catch (err) {
            return err
        }
    }

    useEffect(() => {
        if (block?.data?.dynamicData) {
            fetchData();
        } else {
            setTimeout(() => {
                setData(block?.data?.staticData);
            }, 100);
        }
    }, [block]);

    return (
        <div id={block?.id} onClick={(e) => {
            handleClick(e, block.id, false, -1, true);
        }}
            onMouseOver={(e) => {
                handleMouseOver(e);
            }}>

            <Carousel
                ssr
                containerClass="carousel-container"
                // containerClass="w-full"
                autoPlay={(block.data?.autoPlay != null || block.data?.autoPlay != undefined) ? block.data.autoPlay : false}
                autoPlaySpeed={(block.data?.autoPlaySpeed != null || block.data?.autoPlay != undefined) ? block.data.autoPlaySpeed : 3000}
                dotListClass=""
                arrows={block?.data?.showArrows}
                responsive={response}
                centerMode={false}
                className={block?.css}
                itemClass={block?.data?.itemClass}
                draggable={(block.data?.draggable != null || block.data?.draggable != undefined) ? block.data.draggable : true}
                focusOnSelect={false}
                rtl={(block.data?.rtl != null || block.data?.rtl != undefined) ? block.data.rtl : false}
                infinite
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                showDots={block?.data?.showDots}
                sliderClass=""
                slidesToSlide={1}
                swipeable={(block.data?.swipeable != null || block.data?.swipeable != undefined) ? block.data.swipeable : true}
                customRightArrow={
                    block?.data?.CommonRightArrow ?
                        <CommonRightArrow buttonCSS={block?.data?.CommonRightArrow?.buttonCSS} children={block?.data?.CommonRightArrow?.children} currentTheme={currentTheme} {...props} />
                        :
                        <CustomRightArrow />
                }
                customLeftArrow={
                    block?.data?.CommonLeftArrow ?
                        <CommonLeftArrow buttonCSS={block?.data?.CommonLeftArrow?.buttonCSS} children={block?.data?.CommonLeftArrow?.children} currentTheme={currentTheme} {...props} />
                        :
                        <CustomLeftArrow />
                }
                customDot={
                    block?.data?.customDot ?
                        <CommonDots activeCSS={block?.data?.customDot?.activeCSS} inactiveCSS={block?.data?.customDot?.inactiveCSS} commonCSS={block?.data?.customDot?.commonCSS} />
                        :
                        <CustomDot />
                }
            >
                {
                    data.length != 0 ?
                        data?.map((item, i) => <SingleProduct key={i} block={block} schemaData={item} setProduct={setData} index={item?.id} product={data} currentTheme={currentTheme} />)
                        :
                        <div />
                }
            </Carousel>
        </div>
    )
}