import React, { useContext, useState } from 'react'
import { Tab } from '@headlessui/react'
import { context } from '../../../../context/Context';
import { ReactSortable } from 'react-sortablejs';
import { flushSync } from 'react-dom';
import { BlockWrapper, deleteObjectById } from '../Sortable';
import { randomString } from '../../../../libraries/utility'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}
const sortableOptions = {
	animation: 700,
	fallbackOnBody: false,
	swapThreshold: .5,

	ghostClass: "bg-blue-200",
	group: "bg-blue-600",
	forceFallback: false,
};
export default function Tabs({ block, blockIndex, handleClick, handleMouseOver }) {
	const { blocks, Bdispatch, elementRef, setElementId } = useContext(context
	);
	return (

		// <div id={randomString()} className='flex items-center justify-center m-1'
		// 	onClick={(event) => {
		// 		event.stopPropagation();
		// 		const id = event.target.id;
		// 		console.log(id)
		// 		handleClick(event, id,true)
		// 	}}
		// 	data-name="component"
		// 	onMouseOver={(e) => handleMouseOver(e)}
		// >
		<div id={randomString()}
			onClick={(event) => {
				event.stopPropagation();
				const id = event.target.id;
				console.log(id)
				handleClick(event, id, true)
			}}
			data-name="component"
			onMouseOver={(e) => handleMouseOver(e)}
		>
			<Tab.Group >
				<Tab.List className={`${block?.css}`}>
					{block?.children?.map((category, i) => {
						const itemJSON = JSON.parse(JSON.stringify(category))
						delete itemJSON["children"]
						delete itemJSON["css"]
						return (
							<Tab
								id={category.id}
								key={category?.id}
								className={({ selected }) =>
									classNames(
										'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
										'ring-white ',
										selected
											? 'bg-white shadow'
											: 'text-gray-500 hover:bg-white/[0.12] hover:text-gray-700'
									)
								}
								onKeyDown={(e) => {
									console.log(e.code)
									if (e.code == "Space") {
										e.preventDefault()
										return
									}
								}}
								onClick={(event) => {
									event.stopPropagation();
									const id = event.target.id;
									console.log(id)
									handleClick(event, category.id, true, i)
								}}
							>
								<BlockWrapper block={itemJSON} blockIndex={[...blockIndex, i]} index={i} />
								{/* {category?.content} */}
							</Tab>
						)
					})}
				</Tab.List>
				<Tab.Panels className="mt-2 outline-none border-none"
					id={randomString()}
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					{block?.children &&
						block?.children?.map((childBlock, index) => {
							return (
								<>
									<Tab.Panel
										id="qafto_169564700161144408"
										className={classNames(
											'h-full rounded-xl bg-white p-0 m-0',
										)}
									>
										<BlockWrapper
											key={childBlock.id}
											block={childBlock}
											blockIndex={[...blockIndex, index]}
										/>

									</Tab.Panel>
								</>
							);
						})}
				</Tab.Panels>
			</Tab.Group>
		</div>
		// </div>
	)
}
