import { useState, useContext } from "react";
import { IconBorderSides, IconSquare } from "@tabler/icons-react";
import Field from "./Field";
import removeDuplicateClasses from "./removeDuplicateClasses";
import addNewStyleClass from "./addNewStyleClass";
import { context } from "../../../context/Context";
import { AddToUndo } from "../../../screens/account/editor";
import { Listbox } from "@headlessui/react";
import { IconArrowBarToUp, IconArrowBarToRight, IconArrowBarToDown, IconArrowBarToLeft, IconRadiusTopLeft, IconRadiusTopRight, IconRadiusBottomLeft, IconRadiusBottomRight } from "@tabler/icons-react";
import Slider from "./Slider";
import Dropdown from "./Dropdown";
import ColorPicker from "./ColorPicker";
const SpacingBorder = ({ setOpenedSections, openedSections, setElementBreakpointSpecificStyleObject, updateDesign, elementBreakpointSpecificStyleObject, valueTypes, setValueTypes, selectedBreakpoint, selectedState, setElementAllBreakpointsStyleObject, classes, setClasses, addImportant }) => {
	const [accordionOpen, setAccordianOpen] = useState(false);
	const [selectedProperty, setSelectedProperty] = useState(null)

	return (
		<>
			<div
				className={`flex items-center justify-between py-1.5 px-3 border-t bg-[#F3F4F6] text-[#373C47]  border-[#D9D9D9]  hover:bg-gray-200 cursor-pointer font-medium${openedSections.spaceBorder == true
					? " border-b"
					: ""
					}`}
				onClick={() => {
					setOpenedSections((prev) => {
						return {
							...prev,
							spaceBorder: !prev.spaceBorder
						};
					});
				}}
			>
				<div >
					Spacing & Border
				</div>

				{openedSections.spaceBorder == false ? (
					<span class="mdi mdi-chevron-right text-xl"></span>
				) : (
					<span class="mdi mdi-chevron-down text-xl"></span>
				)}
			</div>
			{openedSections.spaceBorder == true && (
				<>
					<div className="divide-y divide-[#D9D9D9]">

						<div className="p-3">
							<button
								type="button"
								className="relative p-8 bg-[#C29D71] w-full border border-dashed border-black rounded text-xs text-[#373C47]"
								onClick={() => setSelectedProperty("margin")}
							>
								<div className="absolute top-2 left-1">Margin</div>
								<div className="absolute top-2 inset-x-0">{elementBreakpointSpecificStyleObject.marginTop ? elementBreakpointSpecificStyleObject.marginTop : elementBreakpointSpecificStyleObject.margin}</div>
								<div className="absolute right-1 inset-y-0 flex items-center truncate">{elementBreakpointSpecificStyleObject.marginRight ? elementBreakpointSpecificStyleObject.marginRight : elementBreakpointSpecificStyleObject.margin}</div>
								<div className="absolute bottom-2 inset-x-0">{elementBreakpointSpecificStyleObject.marginBottom ? elementBreakpointSpecificStyleObject.marginBottom : elementBreakpointSpecificStyleObject.margin}</div>
								<div className="absolute left-1 inset-y-0 flex items-center truncate">{elementBreakpointSpecificStyleObject.marginLeft ? elementBreakpointSpecificStyleObject.marginLeft : elementBreakpointSpecificStyleObject.margin}</div>
								<button
									type="button"
									className="relative p-8 bg-[#F6E7C0] w-full border border-black rounded"
									onClick={(e) => {
										e.stopPropagation();
										setSelectedProperty("border");
									}}
								>
									<div className="absolute top-2 left-1">Border</div>
									<div className="absolute top-2 inset-x-0">{elementBreakpointSpecificStyleObject.borderTopWidth ? elementBreakpointSpecificStyleObject.borderTopWidth : elementBreakpointSpecificStyleObject.borderWidth}</div>
									<div className="absolute right-1 inset-y-0 flex items-center truncate">{elementBreakpointSpecificStyleObject.borderRightWidth ? elementBreakpointSpecificStyleObject.borderTopWidth : elementBreakpointSpecificStyleObject.borderWidth}</div>
									<div className="absolute bottom-2 inset-x-0">{elementBreakpointSpecificStyleObject.borderBottomWidth ? elementBreakpointSpecificStyleObject.borderBottomWidth : elementBreakpointSpecificStyleObject.borderWidth}</div>
									<div className="absolute left-1 inset-y-0 flex items-center truncate">{elementBreakpointSpecificStyleObject.borderLeftWidth ? elementBreakpointSpecificStyleObject.borderLeftWidth : elementBreakpointSpecificStyleObject.borderWidth}</div>
									<button
										type="button"
										className="relative p-8 bg-[#ABCEAB] w-full border border-dashed border-black rounded"
										onClick={(e) => {
											e.stopPropagation();
											setSelectedProperty("padding");
										}}
									>
										<div className="absolute top-2 left-1">Padding</div>
										<div className="absolute top-2 inset-x-0">{elementBreakpointSpecificStyleObject.paddingTop ? elementBreakpointSpecificStyleObject.paddingTop : elementBreakpointSpecificStyleObject.padding}</div>
										<div className="absolute right-1 inset-y-0 flex items-center truncate">{elementBreakpointSpecificStyleObject.paddingRight ? elementBreakpointSpecificStyleObject.paddingRight : elementBreakpointSpecificStyleObject.padding}</div>
										<div className="absolute bottom-2 inset-x-0">{elementBreakpointSpecificStyleObject.paddingBottom ? elementBreakpointSpecificStyleObject.paddingBottom : elementBreakpointSpecificStyleObject.padding}</div>
										<div className="absolute left-1 inset-y-0 flex items-center truncate">{elementBreakpointSpecificStyleObject.paddingLeft ? elementBreakpointSpecificStyleObject.paddingLeft : elementBreakpointSpecificStyleObject.padding}</div>
										<div className="bg-white p-1 w-full border border-black rounded cursor-auto">Content</div>
									</button>
								</button>
							</button>
						</div>

						{
							selectedProperty == 'margin' &&
							<Property property={"margin"} propertyDisplayName={"Margin"} options={["auto", "px", "vh", "%", "vw"]} logicalOptions={["auto"]} individualProperties={[
								{
									propertyName: "marginLeft",
									icon: IconArrowBarToLeft
								},
								{
									propertyName: "marginTop",
									icon: IconArrowBarToUp
								},
								{
									propertyName: "marginRight",
									icon: IconArrowBarToRight

								},
								{
									propertyName: "marginBottom",
									icon: IconArrowBarToDown
								}
							]} valueTypes={valueTypes} setValueTypes={setValueTypes} setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} selectedBreakpoint={selectedBreakpoint} selectedState={selectedState} classes={classes} setClasses={setClasses} addImportant={addImportant} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} updateDesign={updateDesign} />
						}

						{
							selectedProperty == 'padding' &&
							<Property property={"padding"} propertyDisplayName={"Padding"} options={["px", "vh", "%", "vw"]} logicalOptions={[]} individualProperties={[
								{
									propertyName: "paddingLeft",
									icon: IconArrowBarToLeft
								},
								{
									propertyName: "paddingTop",
									icon: IconArrowBarToUp
								},
								{
									propertyName: "paddingRight",
									icon: IconArrowBarToRight

								},
								{
									propertyName: "paddingBottom",
									icon: IconArrowBarToDown
								}
							]} valueTypes={valueTypes} setValueTypes={setValueTypes} setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} selectedBreakpoint={selectedBreakpoint} selectedState={selectedState} classes={classes} setClasses={setClasses} addImportant={addImportant} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} updateDesign={updateDesign} />
						}

						{
							selectedProperty == 'border' &&
							<div>
								<Property property={"borderWidth"} propertyDisplayName={"Border"} options={["px", "vh", "%", "vw"]} logicalOptions={[]} individualProperties={[
									{
										propertyName: "borderLeftWidth",
										icon: IconArrowBarToLeft
									},
									{
										propertyName: "borderTopWidth",
										icon: IconArrowBarToUp
									},
									{
										propertyName: "borderRightWidth",
										icon: IconArrowBarToRight

									},
									{
										propertyName: "borderBottomWidth",
										icon: IconArrowBarToDown
									}
								]} valueTypes={valueTypes} setValueTypes={setValueTypes} setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} selectedBreakpoint={selectedBreakpoint} selectedState={selectedState} classes={classes} setClasses={setClasses} addImportant={addImportant} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} updateDesign={updateDesign} />
								<div className="flex items-center justify-between relative py-1.5 pr-3 pl-[44px]" >
									<div className="text-[#373C47]">
										Style
									</div>
									<Dropdown elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} updateDesign={updateDesign} propertyData={{
										propertyName: "borderStyle",
										options: [
											"solid",
											"dashed",
											"dotted",
											"double",
											"hidden",
											"none"
										]
									}} />

								</div>

								<div className="flex items-center justify-between relative py-1.5 pr-3 pl-[44px]" >
									<div className="text-[#373C47]">
										Color
									</div>
									<ColorPicker
										setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject}
										elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject}
										updateDesign={updateDesign}
										field={"borderColor"}
									/>

								</div>

								<Property property={"borderRadius"} propertyDisplayName={"Border Radius"} options={["px", "vh", "%", "vw"]} logicalOptions={[]} individualProperties={[
									{
										propertyName: "borderTopLeftRadius",
										icon: IconRadiusTopLeft
									},
									{
										propertyName: "borderTopRightRadius",
										icon: IconRadiusTopRight
									},
									{
										propertyName: "borderBottomLeftRadius",
										icon: IconRadiusBottomLeft
									},
									{
										propertyName: "borderBottomRightRadius",
										icon: IconRadiusBottomRight

									}
								]} valueTypes={valueTypes} setValueTypes={setValueTypes} setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} selectedBreakpoint={selectedBreakpoint} selectedState={selectedState} classes={classes} setClasses={setClasses} addImportant={addImportant} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} updateDesign={updateDesign} />
							</div>
						}

					</div>
					<div
						className={`flex items-center justify-center text-sm py-0.5	border-t bg-[#F3F4F6]  border-[#D9D9D9] text-[#373C47] hover:bg-gray-200 cursor-pointer font-medium${accordionOpen == true
							? " border-b"
							: ""
							}`}
						onClick={() => {
							setAccordianOpen(!accordionOpen);
						}}
					>
						<div>
							More options
						</div>

						{accordionOpen == false ? (
							<span class="mdi mdi-chevron-right text-xl"></span>
						) : (
							<span class="mdi mdi-chevron-down text-xl"></span>
						)}
					</div>
					{accordionOpen == true && (
						<div>
							<div className="py-1.5 px-3 text-[#373C47]">Outline</div>
							<div className="flex items-center justify-between gap-5 relative py-1.5 pr-3 pl-[44px]" >
								<div className="text-[#373C47]">
									Width
								</div>
								<Slider property={'outlineWidth'} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} valueTypes={valueTypes} updateDesign={updateDesign} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} />
								<Field propertyData={{
									propertyName: "outlineWidth",
									logicalOptions: [],
									options: [
										"px",
										"%",
										"vh",
										"vw"
									]
								}}
									valueTypes={valueTypes} setValueTypes={setValueTypes} updateDesign={updateDesign} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} />

							</div>
							<div className="flex items-center justify-between relative py-1.5 pr-3 pl-[44px]" >
								<div className="text-[#373C47]">
									Style
								</div>
								<Dropdown elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} updateDesign={updateDesign} propertyData={{
									propertyName: "outlineStyle",
									options: [
										"solid",
										"dashed",
										"dotted",
										"double",
										"none"
									]
								}} />

							</div>

							<div className="flex items-center justify-between relative py-1.5 pr-3 pl-[44px]" >
								<div className="text-[#373C47]">
									Color
								</div>
								<ColorPicker
									setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject}
									elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject}
									updateDesign={updateDesign}
									field={"outlineColor"}
								/>

							</div>
							<div className="flex items-center justify-between relative py-1.5 pr-3 pl-[44px]" >
								<div className="text-[#373C47]">
									Offset
								</div>
								<Field propertyData={{
									propertyName: "outlineOffset",
									logicalOptions: [],
									options: [
										"px",
										"%",
										"vh",
										"vw"
									]
								}}
									valueTypes={valueTypes} setValueTypes={setValueTypes} updateDesign={updateDesign} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} />

							</div>
						</div>
					)
					}
				</>
			)}
		</>
	)
}
export default SpacingBorder;
const Property = ({ property, propertyDisplayName, individualProperties, valueTypes, setElementAllBreakpointsStyleObject, selectedBreakpoint, selectedState, classes, setClasses, setValueTypes, options, logicalOptions, updateDesign, setElementBreakpointSpecificStyleObject, elementBreakpointSpecificStyleObject, addImportant }) => {
	const {
		elementId,
		formElementRef,
		elementRef,
		selectedSiteData,
		blocks,
		sqliteSchema,
		strapiSchema,
		undo,
		setUndo,
		setRedo
	} = useContext(context);
	return (
		<div className="py-2.5 px-3 space-y-2">
			<div className="flex items-center justify-between relative" >
				<div className="text-[#373C47]">
					{propertyDisplayName}
				</div>
				<div className="flex items-center gap-3">
					<button
						type="button"
						className={`p-1 rounded ${valueTypes[property].type == 'common' ? "bg-[#D9D9D9] text-black" : "text-[#757575]"}`}
						onClick={() => {
							if (valueTypes[property].type != 'common') {
								setElementAllBreakpointsStyleObject((prev) => {
									for (let i = 0; i < individualProperties.length; i++) {
										delete prev[selectedBreakpoint.name][selectedState.key][individualProperties[i].propertyName];
									}
									return {
										...prev,
										[selectedBreakpoint.name]: {
											...prev[selectedBreakpoint.name],
											[selectedState.key]: {
												...prev[selectedBreakpoint.name][selectedState.key],
												[property]: '0px'
											}
										}
									};
								});
								let updatedClasses = classes;
								for (let i = 0; i < individualProperties.length; i++) {
									updatedClasses = removeDuplicateClasses(
										updatedClasses?.split(" "),
										individualProperties[i].propertyName,
										selectedBreakpoint.classPrefix,
										selectedState.value
									);
								}
								updatedClasses = removeDuplicateClasses(
									updatedClasses?.split(" "),
									property,
									selectedBreakpoint.classPrefix,
									selectedState.value
								);
								const newClasses = addNewStyleClass(
									updatedClasses,
									property,
									'0px',
									selectedBreakpoint.classPrefix,
									selectedState.value,
									addImportant
								);
								if (
									formElementRef?.current &&
									formElementRef?.current.id !== elementId.id
								) {
									elementId.data.css[elementRef.current?.dataset?.name] = newClasses;
								} else {
									elementId.css = newClasses;
								}
								setClasses(newClasses);
								AddToUndo(
									selectedSiteData.static,
									blocks,
									sqliteSchema,
									strapiSchema,
									undo,
									setUndo,
									setRedo
								);
							}
						}}
					>
						<IconSquare stroke={2} className={`w-4 h-4`} />
					</button>
					<button
						type="button"
						className={`p-1 rounded ${valueTypes[property].type == 'individual' ? "bg-[#D9D9D9] text-black" : "text-[#757575]"}`}
						onClick={() => {
							if (valueTypes[property].type != 'individual') {
								setValueTypes((prev) => {
									return {
										...prev,
										[property]: {
											...prev[property],
											type: "individual"
										}
									}
								});
							}
						}}
					>
						<IconBorderSides stroke={2} className={`w-4 h-4`} />
					</button>
				</div>
			</div>
			{
				valueTypes[property].type == 'common' ?
					<div className="flex items-center justify-end pl-8 gap-5">
						{
							(
								valueTypes[property].unit == 'px' || valueTypes[property].unit == '%' || valueTypes[property].unit == 'vh' || valueTypes[property].unit == 'vw'
							) &&
							<Slider property={property} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} valueTypes={valueTypes} updateDesign={updateDesign} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} />
						}
						<Field setValueTypes={setValueTypes} valueTypes={valueTypes} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} updateDesign={updateDesign} propertyData={{
							propertyName: property,
							options: options,
							logicalOptions: logicalOptions
						}} />
					</div>
					:
					<div className="pl-5 gap-1 justify-items-end grid grid-cols-2">
						{
							individualProperties.map((propertyData, index) => {
								return (
									<IndividualField setValueTypes={setValueTypes} valueTypes={valueTypes} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} updateDesign={updateDesign} propertyData={propertyData} options={options} logicalOptions={logicalOptions} property={property} key={index} />
								)

							})
						}
					</div>
			}
		</div>
	)
}

const IndividualField = ({ valueTypes, elementBreakpointSpecificStyleObject, setElementBreakpointSpecificStyleObject, updateDesign, setValueTypes, propertyData, options, logicalOptions, property }) => {

	return (
		<div className="flex items-center gap-2">
			<propertyData.icon stroke={2} className="text-[#757575] w-5 h-5" />
			<div className="text-xs flex items-center">
				{
					(
						valueTypes[propertyData.propertyName].unit ?
							(
								valueTypes[propertyData.propertyName].unit == 'px' || valueTypes[propertyData.propertyName].unit == '%' || valueTypes[propertyData.propertyName].unit == 'vh' || valueTypes[propertyData.propertyName].unit == 'vw'
							)
							:
							(
								valueTypes[property].unit == 'px' || valueTypes[property].unit == '%' || valueTypes[property].unit == 'vh' || valueTypes[property].unit == 'vw'
							)
					) &&
					<input
						type="number"
						className="w-[47px] px-2 py-1 border border-r-0 border-[#D9D9D9] text-xs rounded-l text-[#373C47] focus:ring-0  focus:border-[#D9D9D9]"
						value={elementBreakpointSpecificStyleObject[propertyData.propertyName] ? parseInt(elementBreakpointSpecificStyleObject[propertyData.propertyName]) : parseInt(elementBreakpointSpecificStyleObject[property])}
						onChange={(
							e
						) => {
							setElementBreakpointSpecificStyleObject(
								(
									prev
								) => {
									return {
										...prev,
										[propertyData.propertyName]: `${e
											.target
											.value
											}${valueTypes[propertyData.propertyName].unit ? valueTypes[propertyData.propertyName].unit : valueTypes[property].unit}`,
									};
								}
							);
						}}
						onKeyUp={(
							e
						) => {
							if (
								e.key ==
								"Enter"
							) {
								updateDesign(
									propertyData.propertyName,
									`${e.target.value}${valueTypes[propertyData.propertyName].unit ? valueTypes[propertyData.propertyName].unit : valueTypes[property].unit}`
								);
							}
						}}
						onBlur={(e) => {
							updateDesign(
								propertyData.propertyName,
								`${e
									.target
									.value
								}${valueTypes[propertyData.propertyName].unit ? valueTypes[propertyData.propertyName].unit : valueTypes[property].unit}`
							);
						}}
					/>
				}

				<Listbox
					as={'div'}
					className={'relative'}
					value={
						valueTypes[propertyData.propertyName].unit ? valueTypes[propertyData.propertyName].unit : valueTypes[property].unit
					}
					onChange={(value) => {
						setValueTypes(
							(prev) => {
								return {
									...prev,
									[propertyData.propertyName]: {
										unit: value
									}
								};
							}
						);
						if (value == 'px' || value == '%' || value == 'vh' || value == 'vw') {
							updateDesign(
								propertyData.propertyName,
								logicalOptions?.map((opt) => {
									if (elementBreakpointSpecificStyleObject[propertyData.propertyName]) {
										if (elementBreakpointSpecificStyleObject[propertyData.propertyName] == opt) {
											return false;
										} else {
											return true;
										}
									} else {
										if (elementBreakpointSpecificStyleObject[property] == opt) {
											return false;
										} else {
											return true;
										}
									}
								}).includes(false) ? `0${value}` : `${elementBreakpointSpecificStyleObject[propertyData.propertyName] ? parseInt(elementBreakpointSpecificStyleObject[propertyData.propertyName]) : parseInt(elementBreakpointSpecificStyleObject[property])}${value}`);
						} else {
							updateDesign(
								propertyData.propertyName,
								value
							);
						}

					}}
				>
					<Listbox.Button className={`cursor-pointer py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 ${(valueTypes[propertyData.propertyName].unit ?
						(
							valueTypes[propertyData.propertyName].unit == 'px' || valueTypes[propertyData.propertyName].unit == '%' || valueTypes[propertyData.propertyName].unit == 'vh' || valueTypes[propertyData.propertyName].unit == 'vw'
						)
						:
						(
							valueTypes[property].unit == 'px' || valueTypes[property].unit == '%' || valueTypes[property].unit == 'vh' || valueTypes[property].unit == 'vw'
						)) ? "uppercase rounded-r" : "capitalize rounded"}`}>
						<span>
							{
								valueTypes[propertyData.propertyName].unit ? valueTypes[propertyData.propertyName].unit : valueTypes[property].unit
							}
						</span>
						<span class="mdi mdi-chevron-down"></span>

					</Listbox.Button>
					<Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
						{
							options.map(
								(
									value,
									index
								) => (
									<Listbox.Option
										key={index}
										className={({
											active,
										}) => `${active ? "bg-gray-100" : ""} cursor-pointer`
										}
										value={value}
									>
										{({ selected }) => (
											<div className={`flex items-center gap-1 px-2 py-1.5 text-gray-900${selected ? " bg-indigo-100 font-medium" : ""
												} ${(value == 'px' || value == '%' || value == 'vh' || value == 'vw') ? "uppercase" : "capitalize"}`}>
												<div>{value}</div>
												{
													(
														valueTypes[propertyData.propertyName].unit ? valueTypes[propertyData.propertyName].unit == value : valueTypes[property].unit == value
													) &&
													<span class="mdi mdi-check"></span>
												}
											</div>
										)}
									</Listbox.Option>
								)
							)}
					</Listbox.Options>
				</Listbox>
			</div>
		</div >
	)
}