// maximum records fetched in one call
export const STRAPI_MAX_RECORDS = 100;

export function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

// generate random (unique) string
export const randomString = () => {
	return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

// change or add random id in schema
export function changeId(arr) {
	if (arr.type != 'form') {
		arr["id"] = randomString();
	}
	// arr.id && (arr.id = randomString())

	// loop childrens
	if (arr?.children?.length > 0) {
		for (let i = 0; i < arr?.children?.length; i++) {
			changeId(arr?.children[i]);
		}
	}

	return arr;
}

// capitalize first letter of string or word
export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const statesInIndia = [
	'Andaman and Nicobar Islands',
	'Andhra Pradesh',
	'Arunachal Pradesh',
	'Assam',
	'Bihar',
	'Chandigarh',
	'Chhattisgarh',
	'Dadra and Nagar Haveli and Daman and Diu',
	'Delhi',
	'Goa',
	'Gujarat',
	'Haryana',
	'Himachal Pradesh',
	'Jammu and Kashmir',
	'Jharkhand',
	'Karnataka',
	'Kerala',
	'Ladakh',
	'Lakshadweep',
	'Madhya Pradesh',
	'Maharashtra',
	'Manipur',
	'Meghalaya',
	'Mizoram',
	'Nagaland',
	'Odisha',
	'Puducherry',
	'Punjab',
	'Rajasthan',
	'Sikkim',
	'Tamil Nadu',
	'Telangana',
	'Tripura',
	'Uttar Pradesh',
	'Uttarakhand',
	'West Bengal'
];

export function timeDiff(tstart, tend) {
	// Example Unix timestamps (in seconds)
	let timestamp1 = tstart; // Example timestamp 1
	let timestamp2 = tend; // Example timestamp 2

	// Calculate the difference in seconds
	let differenceInSeconds = Math.abs(timestamp2 - timestamp1);

	// Convert the difference to days
	let differenceInDays = differenceInSeconds / (24 * 60 * 60);

	return Math.floor(differenceInDays)
}
