import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState } from "react";
import FilerobotImageEditor, {
	TABS,
	TOOLS,
} from "react-filerobot-image-editor";
import { toast } from "react-toastify";
import { context } from "../../../context/Context";
import { dbCall } from "../../../common/db";

export function Filerobot({
	isImgEditorShown,
	setIsImgEditorShown,
	currentImgUrl,
	setCurrentImgUrl,
	currentImageName,
	getUserMedia,
	siteId
}) {
	const { setIsAnimating, elementId, Bdispatch } = useContext(context);
	const openImgEditor = () => {
		setIsImgEditorShown(true);
	};

	const closeImgEditor = () => {
		console.log("closeImgEditor");
		setIsImgEditorShown(false);
	};

	const uploadProfilePic = async (profilePic) => {
		setIsAnimating(true);
		if (profilePic == null) {
			toast.error("Select Image to change your Profil picture.");
			return;
		}

		const img = new Image();
		//   const byteCharacters = window.atob(profilePic.imageBase64);
		console.log("ProfileHere:",profilePic);
		const base64Response = await fetch(`${profilePic.imageBase64}`);
		const blob = await base64Response.blob();

		console.log(blob, "this is blob");
		img.src = window.URL.createObjectURL(blob);
		img.onload = () =>
			resize(img, "jpeg").then((blob) => {
				resize(img, "webp").then(async (blob) => {
					console.log("Final blob size", blob, blob.size);
					const convertBlobToBase64 = (blob) =>
						new Promise((resolve, reject) => {
							const reader = new FileReader();
							reader.onerror = reject;
							reader.onload = () => {
								resolve(reader.result);
							};
							reader.readAsDataURL(blob);
						});

					const base64String = await convertBlobToBase64(blob);
					console.log("final base64", base64String);
					await dbCall
						.post("/user/upload-user-edited-media", {
							pictures: base64String,
							currentImage: currentImageName,
							size: blob?.size,
							siteId:siteId
						})
						.then((response) => {

							const isChangeMedia = sessionStorage.getItem("setMedia");
							if (elementId.type == "img" && isChangeMedia) {

								Bdispatch({
									type: "UPDATE_MEDIA",
									id: elementId.id,
									element: response?.data?.cdn_url[0],
								});
							}

							sessionStorage.setItem("setMedia", false);
							toast.success(response.data.message);
						})
						.catch((error) => {
							// console.log('profile pic error : ', error);
							if (error.response) {
								toast.error(error.response.data.message);
							} else {
								toast.error("Something went wrong!");
							}
						})
						.finally(() => {
							setIsAnimating(false);
							getUserMedia();
						});
				});
			});

		const MAX_WIDTH = 1280;
		const MAX_HEIGHT = 720;
		const MAX_SIZE = 100000; // 100kb

		async function resize(img, type = "jpeg") {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");

			ctx.drawImage(img, 0, 0);

			let width = img.width;
			let height = img.height;
			let start = 0;
			let end = 1;
			let last, accepted, blob;

			// keep portration
			if (width > height) {
				if (width > MAX_WIDTH) {
					height *= MAX_WIDTH / width;
					width = MAX_WIDTH;
				}
			} else {
				if (height > MAX_HEIGHT) {
					width *= MAX_HEIGHT / height;
					height = MAX_HEIGHT;
				}
			}
			canvas.width = width;
			canvas.height = height;
			ctx.drawImage(img, 0, 0, width, height);

			accepted = blob = await new Promise((rs) =>
				canvas.toBlob(rs, "image/" + type, 1)
			);

			if (blob.size < MAX_SIZE) {
				return blob;
			} else {
				console.log(`Image size after scaling ${blob.size} bytes`);
				console.log(
					"Image sample after resizeing with losseless compression:",
					window.URL.createObjectURL(blob)
				);
			}

			// Binary search for the right size
			while (true) {
				const mid = Math.round(((start + end) / 2) * 100) / 100;
				if (mid === last) break;
				last = mid;
				blob = await new Promise((rs) =>
					canvas.toBlob(rs, "image/" + type, mid)
				);
				if (blob.size > MAX_SIZE) {
					end = mid;
				}
				if (blob.size < MAX_SIZE) {
					start = mid;
					accepted = blob;
				}
			}

			return accepted;
		}
	};
	return (
		<>
			{isImgEditorShown && (
				<div
					className="h-[100vh] w-[100vw] bg-[rgba(0,0,0,.3)] fixed flex items-center justify-center top-2 left-0 z-[999]"
					onClick={(e) => {
						e.preventDefault();
						setIsImgEditorShown(false);
					}}
				>
					<div
						className="absolute top-[52%] -translate-y-[50%] z-[999]"
						style={{
							height: "80vh",
							width: "70vw",
						}}
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<FilerobotImageEditor
							language="en"
							translations={null}
							useBackendTranslations={false}
							source={`${currentImgUrl}`}
							// source="https://images.unsplash.com/photo-1569017388730-020b5f80a004?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
							onSave={async (editedImageObject, designState) => {
								console.log("saved", editedImageObject, designState);
								// await uploadProfilePic(editedImageObject)
								setIsImgEditorShown(false);

								await uploadProfilePic(editedImageObject);

							}}
							onClose={closeImgEditor}
							annotationsCommon={{
								fill: "#000000",
							}}
							Text={{ text: "Filerobot..." }}
							Rotate={{ angle: 90, componentType: "slider" }}
							Crop={{
								presetsItems: [
									{
										titleKey: "classicTv",
										descriptionKey: "4:3",
										ratio: 4 / 3,
										// icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
									},
									{
										titleKey: "cinemascope",
										descriptionKey: "21:9",
										ratio: 21 / 9,
										// icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
									},
								],
								presetsFolders: [
									{
										titleKey: "socialMedia", // will be translated into Social Media as backend contains this translation key
										// icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
										groups: [
											{
												titleKey: "facebook",
												items: [
													{
														titleKey: "profile",
														width: 380,
														height: 380,
														descriptionKey: "fbProfileSize",
													},
													{
														titleKey: "coverPhoto",
														width: 820,
														height: 612,
														descriptionKey: "fbCoverPhotoSize",
													},
												],
											},
										],
									},
								],
							}}
							tabsIds={[
								TABS.ADJUST,
								TABS.FINETUNE,
								TABS.FILTERS,
								TABS.ANNOTATE,
								TABS.WATERMARK,
							]} // or {['Adjust', 'Annotate', 'Watermark']}
							defaultTabId={TABS.ADJUST} // or 'Annotate'
							defaultToolId={TOOLS.TEXT} // or 'Text'
						/>
					</div>
				</div>
			)}
		</>
	);
}
{
	/* <Transition appear show={isImgEditorShown} as={Fragment} className="bg-gray-400">
	<Dialog as="div" className="relative z-10 bg-black w-[100vw]" onClose={closeModal}>
		<Transition.Child
			as={Fragment}
			enter="ease-out duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			<div className="fixed inset-0 bg-black bg-opacity-60" />
		</Transition.Child>
    
		<div className="fixed inset-0 overflow-y-auto z-50">
			<div className="flex min-h-full items-center justify-center p-4 text-center ">
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<Dialog.Panel className="w-[70vw] h-[70vh] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
						<Dialog.Title
							as="h3"
							className="text-lg font-medium leading-6 text-gray-900"
						>
							Editor
						</Dialog.Title>
						<div style={{
							height: "50vh"
						}}>
							<FilerobotImageEditor
								source="https://plus.unsplash.com/premium_photo-1675280973827-a81419cfdc73?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
								onSave={(editedImageObject, designState) =>
									console.log('saved', editedImageObject, designState)
								}
								onClose={closeImgEditor}
								annotationsCommon={{
									fill: '#000000'
								}}
								Text={{ text: 'Filerobot...' }}
								Rotate={{ angle: 90, componentType: 'slider' }}
								Crop={{
									presetsItems: [
										{
											titleKey: 'classicTv',
											descriptionKey: '4:3',
											ratio: 4 / 3,
											// icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
										},
										{
											titleKey: 'cinemascope',
											descriptionKey: '21:9',
											ratio: 21 / 9,
											// icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
										},
									],
									presetsFolders: [
										{
											titleKey: 'socialMedia', // will be translated into Social Media as backend contains this translation key
											// icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
											groups: [
												{
													titleKey: 'facebook',
													items: [
														{
															titleKey: 'profile',
															width: 380,
															height: 380,
															descriptionKey: 'fbProfileSize',
														},
														{
															titleKey: 'coverPhoto',
															width: 820,
															height: 612,
															descriptionKey: 'fbCoverPhotoSize',
														},
													],
												},
											],
										},
									],
								}}
								tabsIds={[TABS.ADJUST, TABS.FINETUNE, TABS.FILTERS, TABS.ANNOTATE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
								defaultTabId={TABS.ANNOTATE} // or 'Annotate'
								defaultToolId={TOOLS.TEXT} // or 'Text'
							/>
						</div>
					</Dialog.Panel>
				</Transition.Child>
			</div>
		</div>
	</Dialog>
	</Transition> */
}
