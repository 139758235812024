// import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import axios from 'axios';
import { AUTH_URL } from './../config';

// const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser'));


async function verifyToken(redirectToken) {

	return await axios.post(AUTH_URL + "/verify-token", {
		redirectToken
	}, {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}, withCredentials : true
	}).then(function (response) {
		// currentUserSubject.next(user);
		return response?.data;
	}).catch((error) => {

		console.log(error);

		throw error;
	});
}

async function login(email, password) {

	return await axios.post(AUTH_URL + "/login", {
		email: email, password: password
	}, {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}, withCredentials: true
	}).then((response) => {


		const { message, redirectToken, ...user } = response.data;
		return { redirectToken, ...user };

	}).catch((error) => {
		console.log(error);

		if (error.response) {
			if (error.response.data.message == 'Max attempts reached') {
				const message = `Max OTP attempts limit reached, please try after ${moment.unix(error.response.data.tryAfter).format('MMMM Do YYYY, h:mm:ss a')}`
				throw message;
			} else if (error.response.data.message == 'Too many failed login attempts') {
				const message = `Too many failed login attempts, please try after ${moment.unix(error.response.data.tryAfter).format('MMMM Do YYYY, h:mm:ss a')}`
				throw message;
			} else {
				throw error.response.data.message;
			}
		} else {
			throw 'Something went wrong';
		}
	});
}

async function register(first_name, last_name, email, mobile, password) {
	return await axios.post(AUTH_URL + "/register", {
		first_name,
		last_name,
		email,
		mobile: `91${mobile}`,
		password
	}, {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	}).then(function (response) {
		sessionStorage.setItem('registererId', response.data.uniqueId);
		return;


	}).catch((error) => {
		console.log('register error : ', error);

		if (error.response) {
			throw error.response.data.message;
		} else {
			throw 'Something went wrong!';
		}
	});
}

async function verifyRegisterEmailVerificationOTP(uniqueId, otp) {
	return await axios.post(AUTH_URL + "/verify-register-email-verification-otp", {
		uniqueId, otp
	}, {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}, withCredentials: true
	}).then((response) => {
		const { redirectToken, ...user } = response.data;
		sessionStorage.removeItem('registererId');

		// currentUserSubject.next(user);
		return redirectToken;

	}).catch((error) => {
		console.log(error);

		if (error.response) {
			throw error.response.data.message;
		} else {
			throw 'Something went wrong';
		}
	});
}

async function resendRegisterEmailVerificationOTP(uniqueId) {
	return await axios.post(AUTH_URL + "/resend-register-email-verification-otp", {
		uniqueId
	}, {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	}).then((response) => {
		return response.data.message;

	}).catch((error) => {
		console.log(error);

		if (error.response) {
			if (error.response.data.message == 'Max attempts reached') {
				const message = `Max OTP attempts limit reached, please try after ${moment.unix(error.response.data.tryAfter).format('MMMM Do YYYY, h:mm:ss a')}`
				throw message;
			} else {

				throw error.response.data.message;
			}
		} else {
			throw 'Something went wrong';
		}
	});
}


async function userVerify() {
	try {
		const response = await axios.get(AUTH_URL + '/verify/user', {withCredentials : true});
		// .then( async response => {
			// console.log("akjshdjgasdgahsd", response?.data)
			return response?.data
		// });
	} catch (error) {
		console.log(error);
		if (error.response) {
			throw error.response.data.message;
		} else {
			throw 'Something went wrong';
		}
	}
}

async function logout() {
	axios.get(AUTH_URL + "/logout",{withCredentials:true});
	if (process.env.REACT_APP_MODE == 'development') {
		window.location.href = '/guest/user/login';
	} else {
		window.location.href = `${process.env.REACT_APP_LANDING_PAGES_URL}/session/delete`;
	}
}

export const AuthService = {
	// currentUser: currentUserSubject.asObservable(),
	// get currentUserValue() { return currentUserSubject.value },
	verifyToken,
	userVerify,
	logout,
	login,
	register,
	verifyRegisterEmailVerificationOTP,
	resendRegisterEmailVerificationOTP
};