import { useContext, useEffect, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { PencilIcon } from "@heroicons/react/24/outline"
import { toast } from "react-toastify"
import _ from "lodash";

import { dbCall } from "../../common/db"
import { context } from "../../context/Context"
import RSortable from "./editor/Sortable"
import { timeDiff } from "../../libraries/utility";
import axios from "axios";

export const AddToUndo = (isStatic, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo) => {
	let backendSchemaToUpdate = isStatic ? sqliteSchema : strapiSchema;

	if (undo?.length > 10) {
		undo.ui.shift();
		undo.backend.shift();
	}

	setUndo({
		ui: [...undo.ui, JSON.parse(JSON.stringify(blocks))],
		backend: [...undo.backend, [..._.cloneDeep(backendSchemaToUpdate)]]
	});

	setRedo({ ui: [], backend: [] });
}

export default function Editor() {
	const {
		setPageName,
		Bdispatch,
		formId,
		strapiDispatch,
		undo, setUndo,
		setRedo,
		selectedSiteData,
		sqliteDispatch,
		setIsAnimating,
		currentPlatform,
		elementRef,
		formElementRef,
		componentRef,
		setPageId,
		setCordinates,
		currentTheme,
		pageLayoutChanged,
		setLayout,
		setContent,
		setIsSiteTrialActive,
		setCurrenntPlan,
		setOpenedRightSidebarSection
	} = useContext(context)

	const params = useParams()
	const [width] = useOutletContext() || [];

	const changeDynamicData = async (schema, path) => {
		for (let i = 0; i < schema?.length; i++) {
			const data = schema[i]
			if (data?.data?.notRunOnBuilder) {
				data.data.dynamicData = false
			}

			if (data?.children) {
				changeDynamicData(data?.children, path)
			}
		}
	}

	const getCurrentPlan = async (planId) => {
		try {
			const currentPlan = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/planById`, {
				id: planId
			})

			setCurrenntPlan(currentPlan?.data?.data?.name)
		} catch (e) {
			console.log(e)
		}
	}
	const getPageData = async () => {
		try {
			setIsAnimating(true);
			await Bdispatch({ type: "REMOVE" })

			const response = await dbCall.get(`/site/get-page-data/${params.pageName}/${params.siteId}/${currentPlatform}`);

			const timeDif = timeDiff(response?.data?.data?.pageData?.sites?.createdAt, (Date.now() / 1000).toFixed())

			await getCurrentPlan(response?.data?.data?.pageData?.sites?.userPlan[0]?.planId)
			if (timeDif > 7 && response?.data?.data?.pageData?.sites?.userPlan[0]?.isFreeTrial) {
				setIsSiteTrialActive(false)
			} else {
				setIsSiteTrialActive(true)
			}
			setPageId(response?.data?.data?.pageData?.id)
			setPageName(response.data?.data?.pageData?.pageName);
			setContent(response.data?.data?.pageData?.content);

			const header = response?.data?.data?.header;
			const footer = response?.data?.data?.footer;
			const schema = response?.data?.data?.pageData.schema;
			const backendSchema = response?.data?.data?.backendSchema;
			const pageLayout = {
				header: header ? Object?.keys(header)?.length > 0 : false,
				footer: footer ? Object?.keys(footer)?.length > 0 : false
			}
			setLayout(pageLayout)
			let updateUISchema = [];


			if (pageLayout.header && pageLayout.footer) {
				updateUISchema = [header, ...schema, footer];

			} else if (!pageLayout.header && pageLayout.footer) {
				updateUISchema = [...schema, footer];

			} else if (pageLayout.header && !pageLayout.footer) {
				updateUISchema = [header, ...schema];

			} else if (!pageLayout.header && !pageLayout.footer) {
				updateUISchema = [...schema];
			}

			await Bdispatch({ type: "ADD", payload: updateUISchema });

			if (backendSchema?.length > 0) {
				selectedSiteData?.static ?
					await sqliteDispatch({ type: "INITIALIZE", payload: backendSchema, uiSchema: updateUISchema, undo, setUndo, setRedo }) :
					await strapiDispatch({ type: "INITIALIZE", payload: backendSchema, uiSchema: updateUISchema, undo, setUndo, setRedo })
			} else {
				// add schemas to undo state
				AddToUndo(false, updateUISchema, [], [], undo, setUndo, setRedo);
			}
		} catch (error) {
			console.log(error);
			setPageName("");
			setPageId(null);
			await Bdispatch({ type: "ADD", payload: [] });
			toast.error("Something went wrong!");
		} finally {
			setIsAnimating(false)
		}
	}

	const getModalData = async () => {
		try {
			setIsAnimating(true);
			await Bdispatch({ type: "REMOVE" })

			const response = await dbCall.get(`site/get-modal-data/${params.pageName}/${params.siteId}/${currentPlatform}`);

			if (response.data.message && response.data.message == "No page found") {
				setPageName("");
				setPageId(null);
				await Bdispatch({ type: "ADD", payload: [] });
			} else {
				if (response?.data?.data?.pageData) {
					setPageId(response?.data?.data?.pageData?.id)
					setPageName(response.data?.data?.pageData?.ModalName);

					const schema = response?.data?.data?.pageData.schema;

					let updateUISchema = [...schema];

					await Bdispatch({ type: "ADD", payload: updateUISchema });

					AddToUndo(false, updateUISchema, [], [], undo, setUndo, setRedo);
				}
			}
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!");

		} finally {
			setIsAnimating(false)
		}
	}



	useEffect(() => {
		if (params.modalName) {
			getModalData()
			return
		}
		if (currentPlatform) {
			getPageData();
		}
	}, [params.pageName, currentPlatform, pageLayoutChanged]);

	useEffect(() => {
		elementRef.current = null;
		formElementRef.current = null;
		componentRef.current = null;
		setOpenedRightSidebarSection(null)
		setCordinates({
			cordinatesValue: {
				top: 0,
				right: 0
			}
		});
		document.getElementById("move").style.top = '-999px';
	}, [currentPlatform])

	return (
		<>
			<div className="flex max-w-[100%] min-h-full">
				<div className={`duration-700 mx-auto w-[${width}] max-w-[100%]`}>

					<div className="divide-y divide-gray-200  rounded-sm shadow no-scrollbar" id="droparea"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<div
							id="619"
							className={`@container text-[${currentTheme?.textColor}] bg-[${currentTheme?.bg_color}] `}
							style={{
								fontFamily: currentTheme?.primary_font_family ? currentTheme.primary_font_family[0] : null
							}}
						>
							<RSortable />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
