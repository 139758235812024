import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import SingleProduct from "./SingleProduct";
import { context } from "../../../../context/Context";
// import { context } from "../../context/context";

const ProductList = ({ block, currentTheme, handleMouseOver,handleComponent,handleClick}) => {
    const [searchData, setSearchData] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(10)
    // const navigate = useNavigate()
    const params = useParams()
   
	const {  updateStaticData, setIsListDynamic} = useContext(context);
    const fetchData = async () => {
		setData([])
		if (block?.data?.url ) {
            let collection = block?.data?.collection?.toLowerCase();
            const paramQuery = block?.data?.paramQuery?.fieldName ? block?.data?.paramQuery?.fieldName : "type";

            let query = block?.data?.query ? block?.data?.query : params.id ? { [paramQuery]: params.id } : { type: "All" };

            if (block?.data?.paramQuery?.fieldName) {
                query[paramQuery] = params.id;
            }
            if(query.type != "All" && query.type != "" && query?.type?.["$contains"] != "All" && query?.type?.["$contains"] != ""){
                for (const property in query) {
                    if (typeof (query[property]) == "string" || typeof (query[property]) == "number") {
                        query[property] = {
                            $contains: query[property]
                        }
                    }
                }
            }

            if(collection[collection.length -1] == "s"){
                // var lastIndex = collection.lastIndexOf(" ");
                collection = collection.substring(0, collection.length-1);
            }

            if (collection != "cart" && collection != "wishlist" && collection != "order") {
                try {
                    const fetchValue = await fetch(`https://${params.siteId}-strapi.qafto.com/api/${block?.data?.url}/${JSON.stringify(query)}/${collection ? collection : "product"}`, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json"
                        },
                    })

                    const response = await fetchValue.json();
                    if (block?.data?.limit) {
                        const limitedData = response.slice(0, block?.data?.limit) || [];
                        setData([...limitedData])
                    } else {
                        setData([...response])
                    }
                } catch (err) {
                    return err
                }
            } else {
                try {
                    const fetchValue = await fetch(`https://${params.siteId}-strapi.qafto.com/api/${block?.data?.url}/"kajbf"`, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            // "Authorization": `Bearer ${token}`
                        },
                    })

                    let response = await fetchValue.json();
                    if (block.data.fieldName) {
                        response = response?.[block?.data?.fieldName]
                    }

                    if (block?.data?.url?.includes("Cart")) {
                        let total = 0
                        for (let i = 0; i < response?.[0].category_products?.length; i++) {
                            total += parseInt(response?.[0]?.category_products[i]?.price) * parseInt(response?.[0]?.quantity?.[parseInt(response?.[0]?.category_products[i]?.id)])
                        }                    }
						setData(response)
                } catch (err) {
                    return err
                }

            }
        } else {
        }
    }

	
    useEffect(() => {
		console.log(block?.data?.dynamicData,"fetching")
		if (block?.data?.dynamicData && !block.data.notRunOnBuilder) {
			fetchData();
        }
        else{
			setData([])
			setTimeout(() => {
				if (block?.data?.limit && Array.isArray(block?.data?.staticData)) {
					const limitedData = block?.data?.staticData.slice(0, block?.data?.limit)
					if (block.data.fieldName) {
						setData([...limitedData?.[block.data.fieldName]]);
					}else{
						setData([...limitedData])
					}
				} else {
					if (block.data.fieldName) {
						setData([...block?.data?.staticData?.[block.data.fieldName]]);
                    }else{
						setData([...block?.data?.staticData]);
					}
				}
            }, 0);
		}
    }, [updateStaticData])

    return (
        <div className={`${block.css}`}
		id={block.id}
			data-name="component"
			onClick={(event) => {
				console.log("n dfjafjabhfjabhjabsjhfbbjhash bhshajhsbfhd")
				// if(Array.isArray(block?.data?.staticData)){
					console.log("n dfjafjabhfjabhjabsjhfbbjhash bhshajhsbfhd2")
					handleComponent(event, block.id)
					setIsListDynamic({
						dynamicData: Array.isArray(block?.data?.staticData) ? block?.data?.dynamicData : true,
						isShowNumberOfItems: true,
						isShowNavigateToPage: true
					})
				// }
			}}
			onMouseOver={(e) => handleMouseOver(e)}
			>
            {
                data?.length > 0 ?
				block?.data.oneTimeRender ? 
						[data?.[0]]?.map((product,index) => (
                        	<SingleProduct block={block} currentTheme={currentTheme} schemaData={product} setProduct={setData} index={index} product={data} total={total} setTotal={setTotal}  handleComponent={handleClick} handleMouseOver={handleMouseOver} blockData={block}/>
                    		))
						:
                    	data?.map((product,index) => (
                        	<SingleProduct block={block} currentTheme={currentTheme} schemaData={product} setProduct={setData} index={index} product={data} total={total} setTotal={setTotal}  handleComponent={handleClick} handleMouseOver={handleMouseOver} blockData={block}/>
                    	))
                    :
				<div className="flex items-center justify-center">
                        <h1 className="text-2xl text-gray-500">{block?.data?.failedResponse ? block?.data?.failedResponse == "false" ? "" :  block?.data?.failedResponse : "No Products"}</h1>
                </div>
            }
        </div>
    );
};
export default ProductList;



// import React, { useContext, useEffect, useState } from 'react'
// import { context } from '../../../../context/Context';
// import { adminDBCall } from '../../../../common/admin-db';
// import { randomString } from '../../../../libraries/utility';
// import { toast } from 'react-toastify';

// export default function ProductList({ block, handleMouseOver, handleComponent }) {
// 	console.log("THIASI SI PRODUCTLIST")
// 	const [numberOfItemsToDisplay, setNumberOfItemsToDisplay] = useState(null);
// 	const [componentData, setComponentData] = useState(null);
// 	const [collectionType, setCollectionType] = useState('');
	
// 	const [name, setName] = useState(false);
// 	const [description, setDescription] = useState(false);
// 	const [price, setPrice] = useState(false);
// 	const [css, setCss] = useState({});
	
// 	const {  adminURL, adminToken } = useContext(context);
	
// 	useEffect(() => {
// 		getAttributes();
// 	}, [block.data.attributes, block.data.collectionType]);
	
// 	useEffect(() => {
// 		setCss(block.data?.css);
// 	}, [block.data.css]);
	
// 	const getAttributes = async () => {
// 		setCollectionType(block.data.collectionType);
		
// 		for (let i = 0; i < block.data?.attributes?.length; i++) {
			
// 			if (block.data.attributes[i].key === "name") {
// 				setName(block.data.attributes[i].value);

// 			} else if (block.data.attributes[i].key === "description") {
// 				setDescription(block.data.attributes[i].value);

// 			} else if (block.data.attributes[i].key === "price") {
// 				setPrice(block.data.attributes[i].value);

// 			} else if (block.data.attributes[i].key === "numberOfItems") {
// 				setNumberOfItemsToDisplay(block.data.attributes[i].value);
				
// 				if (block.data.collectionType == "" || block.data.collectionType == null) {
// 					if (block.data.attributes[i].value < block.data.data?.length) {
// 						const items = block.data.data.slice(0, block.data.attributes[i].value);
// 						setComponentData(items);
// 					} else {
// 						setComponentData(block.data.data);
// 					}
					
// 				} else {
				
// 					try {
// 						const fieldList = await getFieldData();	
// 						const dataFromStrapi = await fetchRecords(block.data.collectionType, fieldList, block.data.attributes[i].value);
// 						setComponentData(dataFromStrapi);
// 					} catch (error) {
// 						if (error.response?.data.error.message) {
// 							toast.error(error.response.data.error.message);
// 						} else {
// 							toast.error('Something went wrong!');
// 						}
// 						break;
// 					}
// 				}				
// 			}
			
// 		}
// 	} 	
		
// 	useEffect(() => {
// 		if (block.data.collectionType != "" && block.data.collectionType != null) {
// 			getAttributes();
// 		}
// 	}, []);

// 	// get collection fields
// 	const getFieldData = async (uid) => {
// 		try {

// 			// setLoading(true);
// 			// setIsAnimating(true);
			
// 			const collection_name = block.data.collectionType.toLowerCase();

// 			let schemaAttributes = block.data.attributes;
			
// 			if (collection_name == 'user') {
// 				uid = 'plugin::users-permissions.user';
// 			} else {
// 				uid = `api::${collection_name}.${collection_name}`;
// 			}
			
// 			const response = await adminDBCall(adminURL, adminToken).get(`/content-type-builder/content-types/${uid}`);
// 			// console.log('response f', response)
			
// 			const allFields = response.data.data.schema.attributes;
			
// 			let fieldList = [];
			
// 			// loop strapi data
// 			for (const x in allFields) {
// 				// loop attributes from schema
// 				for (let i = 0; i < schemaAttributes.length; i++) {
// 					// match field								
// 					if ("mapTo" in schemaAttributes[i] && schemaAttributes[i].mapTo == x) {
// 						fieldList.push({name: schemaAttributes[i].key, mapTo: x, type: allFields[x].type})
// 						break;
// 					}
// 				}
// 			}
			
// 			return fieldList;

// 		} catch (error) {
// 			console.log('error', error)
// 			// setLoading(false);
// 			// setIsAnimating(false);

// 			// if (error.response) {
// 			// 	toast.error(error.response.data.error.message);
// 			// } else {
// 			// 	toast.error('Something went wrong!');
// 			// }
// 		}

// 	};
	
// 	const fetchRecords = async (collectionType, fieldList, numberOfItemsToDisplay) => {

// 		let pageNumber = 1;
// 		let pageSize = 100;
		
// 		const response = await adminDBCall(adminURL, adminToken).get(`/${collectionType}s/?pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&sort=id:${block.data.collectionDataOrder}&populate=*`);
		
// 		if (!response.data.data) {								// default strapi User collection
// 			const apiData = response.data.splice(0, numberOfItemsToDisplay);
// 			// console.log('response 1', apiData, fieldList)
			
// 			let dataFromStrapi = [];
			
// 			apiData.map(item => {
// 				let data = {};
				
// 				// loop strapi data
// 				for (const x in item) {
					
// 					// loop field list
// 					for (let i = 0; i < fieldList.length; i++) {
// 						// match field
// 						if (x == fieldList[i].mapTo) {
							
// 							if (fieldList[i].type == 'media') {
// 								let images = [];

// 								for (let j = 0; j < item[x]?.length; j++) {
// 									images.push(item[x][j].formats.small.url);
// 								}

// 								data = {
// 									...data,
// 									[fieldList[i].name]: fieldList[i].type == "media" ? images : item.attributes[x]
// 								}
								
// 							} else {
// 								data = {
// 									...data,
// 									[fieldList[i].name]: item[x]
// 								}
// 							}	
							
// 							break;
// 						}
// 					}
// 				}

// 				dataFromStrapi.push(data);
// 			});

// 			return dataFromStrapi;
			
// 		} else {								// custom collections created by user 
			
// 			const apiData = response.data.data.splice(0, numberOfItemsToDisplay);
// 			// console.log('response 2', apiData)
			
// 			let dataFromStrapi = [];
			
// 			apiData.map(item => {
// 				let data = {};
				
// 				// loop strapi data
// 				for (const x in item.attributes) {
					
// 					// loop field list
// 					for (let i = 0; i < fieldList.length; i++) {
// 						// match field
// 						if (x == fieldList[i].name) {
							
// 							if (fieldList[i].type == 'media') {
// 								let images = [];
// 								for (let j = 0; j < item.attributes[x].data?.length; j++) {
// 									images.push(item.attributes[x].data[j].attributes.formats.small.url);
// 								}
								
// 								data = {
// 									...data,
// 									[fieldList[i].name]: fieldList[i].type == "media" ? images : item.attributes[x]
// 								}
								
// 							} else {
// 								data = {
// 									...data,
// 									[fieldList[i].name]: item.attributes[x]
// 								}
// 							}	
							
// 							break;
// 						}
// 					}
// 				}

// 				dataFromStrapi.push(data);
// 			});

// 			return dataFromStrapi;
// 		}
// 	}
	
// 	return (
// 		<div 
// 			id={block.id}
// 			class={`grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 my-2 ${block.css}`}
// 			data-name="component"
// 			onClick={(event) => {
// 				handleComponent(event, block.id)
// 			}}
// 			onMouseOver={(e) => handleMouseOver(e)}
// 		>
// 			{
// 				componentData?.map((item, index) =>
// 					<div id={`${block.id}_${index}6`} key={index} class="group relative" onClick={(event) => handleComponent(event, `${block.id}_${index}6`)}>
						
// 						<div id={randomString()} class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
// 							<img id={`${block.id}_${index}0`} src={item?.media ? item?.media[0] : ""} alt="Front of men&#039;s Basic Tee in black." class={css.media} data-name="image" onClick={(event) => handleComponent(event, `${block.id}_${index}0`)} />
// 						</div>
						
// 						{
// 							(name || description || price) &&
// 							<div id={`${block.id}_${index}5`} class="mt-4 flex justify-between" onClick={(event) => handleComponent(event, `${block.id}_${index}5`)}>
// 								{	
// 									(name || description) &&
// 									<div>
// 										{
// 											name &&
// 											<h3
// 												id={`${block.id}_${index}1`}
// 												class={css.name}
// 												data-name="name"
// 												onClick={(event) => handleComponent(event, `${block.id}_${index}1`)}
// 												onDoubleClick={(e) => {
// 													e.target.contentEditable = collectionType == "" ? true : false
// 												}}
// 												onBlur={async (e) => {
// 													if (collectionType == "") {
// 														block.data.data[index].name = e.target.innerText
// 													}
// 												}}	
// 											>
// 												{item.name}
// 											</h3>
// 										}
										
// 										{
// 											description &&
// 											<p
// 												id={`${block.id}_${index}2`}
// 												class={`${name && 'mt-1'} ${css.description}`}
// 												data-name="description"
// 												onClick={(event) => handleComponent(event, `${block.id}_${index}2`)}
// 												onDoubleClick={(e) => {
// 													e.target.contentEditable = collectionType == "" ? true : false
// 												}}
// 												onBlur={async (e) => {
// 													if (collectionType == "") {
// 														block.data.data[index].description = e.target.innerText
// 													}
// 												}}	
// 											>
// 												{item.description}
// 											</p>
// 										}
// 									</div>
// 								}
								
// 								{
// 									price &&
// 									<p
// 										id={`${block.id}_${index}3`}
// 										class={css.price}
// 										data-name="price"
// 										onClick={(event) => handleComponent(event, `${block.id}_${index}3`)}
// 										onDoubleClick={(e) => {
// 											e.target.contentEditable = collectionType == "" ? true : false
// 										}}
// 										onBlur={async (e) => {
// 											if (collectionType == "") {
// 												block.data.data[index].price = e.target.innerText
// 											}
// 										}}	
// 									>
// 										{/* ₹ */}
// 										{item.price}
// 									</p>
// 								}
// 							</div>
// 						}
// 					</div>
// 				)
// 			}
// 		</div>
// 	)
// }
