import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { context } from '../../../../context/Context'
import { ReactSortable } from 'react-sortablejs'
import { flushSync } from 'react-dom'
import Rating from './Rating'
import ProductImageSlider from './ProductImageSlider'


export default function SingleProduct({ block, schemaData, currentTheme, setProduct, index, product, handleMouseOver, handleComponent, blockData }) {
	const [data, setData] = useState([])
	const params = useParams()
	const { updateStaticData } = useContext(context);
	const [updateState, setUpdateState] = useState(0)

	//@ts-ignore
	const fetchData = async () => {
		if (block?.data?.url == "customapi/getCart") {
			try {
				const localData = JSON.parse(localStorage.getItem("currentUser"))
				const token = localData?.token
				const userId = localData?.userId
				const fetchValue = await fetch(`${process.env.API_URL}/${block?.data?.url}/${params.id ? params.id : userId}`, {
					method: 'GET',
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${token}`
					},
				})
				const response = await fetchValue?.json();
				if (block?.data?.url?.includes("Cart")) {
					let total = 0
					for (let i = 0; i < response?.length; i++) {
						total += parseInt(response[i].price) * parseInt(response[i].quantity)
					}
				}
			} catch (err) {
				console.log(err);

				alert(err)
				return err
			}
		} else {
			try {
				const id = params?.id?.split('_')[1]
				const collection = params?.id?.split('_')[0]

				// const userToken = JSON.parse(localStorage.getItem("currentUser")!)
				const data = await fetch(`${process.env.API_URL}/${collection}/${id}`, {
					method: "GET",
					headers: {
						'Content-Type': 'application/json'
					}
				})
				const response = await data.json();
				setData(response?.data?.attributes)
			}
			catch (err) {
				return err
			}
		}
	}

	useEffect(() => {
		if (schemaData == undefined) {
			setTimeout(() => {
				setData(block?.data?.staticData);
			}, 100);
		}
		else {
			setData(schemaData)
		}
		setUpdateState(updateState + 1)
	}, [updateStaticData]);

	return (
		<Fragment>
			<RSortable blocks={block} currentTheme={currentTheme} data={data} setData={setProduct} index={index} product={product} handleMouseOver={handleMouseOver} handleComponent={handleComponent} blockData={blockData} />
		</Fragment>
	)
}

function RSortable({ blocks, currentTheme, data, setData, index, product, handleMouseOver, handleComponent, blockData }) {
	const { setIsListDynamic } = useContext(context);
	return (
		<Fragment>
			<div className={`${blocks.type == "SingleProduct" && blocks?.css
				?.replaceAll(
					"secondary_color",
					`[${currentTheme?.secondary_color}]`
				)
				?.replaceAll(
					"bg_color",
					`[${currentTheme?.bg_color}]`
				)
				?.replaceAll(
					"textColor",
					`[${currentTheme?.textColor}]`
				)
				?.replaceAll(
					"primary_color",
					`[${currentTheme?.primary_color}]`
				)
				?.replaceAll(
					"primary_font_size",
					`[${currentTheme?.primary_font_size}]`
				)
				?.replaceAll(
					"secondary_font_size",
					`[${currentTheme?.secondary_font_size}]`
				)
				?.replaceAll(
					"font_size",
					`[${currentTheme?.font_size}]`
				)}`}
				id={blocks.id}
				data-name={blocks.type == "SingleProduct" && "component"}
				onClick={(event) => {
					if (blocks.type == "SingleProduct") {

						console.log(blockData, "BLOCK DATA")
						// if (Array.isArray(blockData?.data?.staticData)) {
						handleComponent(event, blockData?.id)
						setIsListDynamic({
							dynamicData: true,
							isShowNumberOfItems: false

						})
						// }
					}
				}}
			>
				{blocks?.children?.map((block, blockIndex) => (
					<BlockWrapper
						key={blocks.id}
						block={block}
						currentTheme={currentTheme}
						value={data}
						setData={setData}
						index={index}
						product={product}
						handleMouseOver={handleMouseOver} handleComponent={handleComponent}
						blocks={blocks}
						blockData={blockData}
						blockIndex={[blockIndex]}
					/>
				))}
			</div>
		</Fragment>
	);
}
const sortableOptions = {
	animation: 500,
	swapThreshold: .5,
	ghostClass: 'bg-blue-200'
};

let currentArr = []
export function deleteObjectById(arr, id) {
	for (let i = 0; i < arr?.length; i++) {
		const obj = arr[i];
		if (obj.id == id) {
			// If the object is found, remove it from the array
			arr.splice(i, 1);
			return arr;
		} else if (obj.children && obj?.children?.length > 0) {
			deleteObjectById(obj.children, id);
			// if (deleted) {
			//  return true;
			// }
		}
	}
	return false;
}
function Container({ block, currentTheme, blockIndex, css, data, setData, index, product, handleMouseOver, handleComponent, blockData }) {
	const {
		elementRef, Bdispatch,
		undo, setUndo,
		blocks
	} = useContext(context)
	return (
		<>
			{/* <ReactSortable
				key={block.id}
				id={block.id}
				list={block.children}
				setList={(currentList, sortable) => {
					if (sortable) {
						flushSync(() => {
							const tempList = [...blocks];
							const _blockIndex = [...blockIndex];
							const lastIndex = _blockIndex?.pop();
							const lastArr = _blockIndex?.reduce(
								(arr, i) => arr?.[i]?.["children"],
								blocks
							);
							console.log(lastArr, lastIndex)
							if (lastArr != undefined) {
								lastArr[lastIndex]["children"] = currentList;
								Bdispatch({ type: "ADD", payload: tempList })
								currentArr = tempList
							} else {
								const value = deleteObjectById(tempList, _blockIndex)
							}
						})
					}
				}}
				{...sortableOptions}
				// {...sortableOptions2}
				direction="vertical"
				className={`${css?.includes("hidden") ? css?.replace("hidden", "") : css} !w-full !p-0 !m-0 !border-0 !static !shadow-none !transform-none !animate-none !bg-none !bg-transparent !h-full !max-w-none`}
				onChange={(e) => {
					elementRef.current != null && (elementRef.current.style.outline = "none")
					elementRef.current = e.to;
					elementRef.current.style.outline = "1px solid #6366F1"
				}}
				onEnd={(e, sortable) => {
					if (undo?.length > 10) {
						undo.shift();
						setUndo({
							ui: [...undo.ui, JSON.parse(JSON.stringify(currentArr))],
							backend: [...undo.backend, ...undo.backend]
						});
					} else {
						setUndo({
							ui: [...undo.ui, JSON.parse(JSON.stringify(currentArr))],
							backend: [...undo.backend, ...undo.backend]
						});
					}
				}}
			> */}
			<div className={`${css?.includes("hidden") ? css?.replace("hidden", "") : css} !w-full !p-0 !m-0 !border-0 !static !shadow-none !transform-none !animate-none !bg-none !bg-transparent !h-full !max-w-none`}			>
				{block?.children &&
					block?.children?.map((childBlock, index) => {
						return (
							<BlockWrapper
								key={childBlock.id}
								block={childBlock}
								currentTheme={currentTheme}
								value={data}
								setData={setData}
								index={index}
								product={product}
								handleMouseOver={handleMouseOver}
								handleComponent={handleComponent}
								blockIndex={[...blockIndex, index]}
								blockData={blockData}
							/>
						);
					})}
				{/* </ReactSortable> */}
			</div>
		</>
	);
}

function BlockWrapper({ block, currentTheme, blockIndex, value, setData, index, product, handleMouseOver, handleComponent, blocks, blockData }) {
	const {
		elementRef,
		formElementRef,
		componentRef,
		grab,
		setElementId,
		setCordinates,
		setEditableValue,
		setFormSelected,
		setSelectedFormElementId,
		setFormId,
		setFormName,
		setDisplayDataTab,
		setAddNewFormElementCordinates,
		workingOnEvent,
		setDisplayEventTab,
		selectedSiteData,
		Bdispatch,
		setOpenedRightSidebarSection,
		setOpenedLeftSidebarSection
	} = useContext(context)

	const handleClick = (event, id, customComp = false) => {
		event.stopPropagation();
		setOpenedLeftSidebarSection(null);



		if (!grab) {
			const el = document.getElementById(id);
			if (workingOnEvent == false) {
				block["productList"] = true
				setElementId(block);
				setOpenedRightSidebarSection('style');
			}

			// don't display duplicate & delete button on form submit button
			block?.data?.formButton && setCordinates(null);



			elementRef.current != null && (elementRef.current.style.outline = "none")
			elementRef.current = event.target;
			elementRef.current.style.outline = "1px solid #6366F1"
			block.content?.length >= 0 && setEditableValue(event.target.innerText)

			// display style tab in right side panel

			// check if form is selected or not
			const closestSection = el?.closest('form');
			// NOTE: closest only return value if the element (form) which we are finding is inside other element (el) exists, else it will return null

			// console.log('formElementRef', closestSection, formElementRef.current, elementRef.current);

			if ((closestSection == null || closestSection == undefined) && formElementRef.current != null) {
				formElementRef.current.style.outline = "none";
				formElementRef.current = null;

			} else if (closestSection?.contains(elementRef.current)) {

				setFormSelected(true);
				setSelectedFormElementId(null);

				// selected form id in ui schema
				setFormId(closestSection.id);

				// if (selectedSiteData.static == false) {
				setDisplayDataTab(true);
				// }

			}

			if (closestSection == null || closestSection == undefined) {			// not selected
				// hide data tab in right side panel
				setDisplayDataTab(false);

				setFormSelected(false);
				setFormName('');
				setSelectedFormElementId(null);

				setAddNewFormElementCordinates({
					cordinatesValue: {
						top: 0,
						left: 0
					}
				});

				// show event tab in right side bar
				setDisplayEventTab(true);

			} else {
				if (selectedSiteData.static == false) {
					setOpenedRightSidebarSection('data');
				} else {
					setOpenedRightSidebarSection('style');
				}

				// show event tab in right side bar
				setDisplayEventTab(false);
			}

			// component ref = null
			if (componentRef.current != null) {
				componentRef.current.style.outline = "none";
				componentRef.current = null;
			}
		}
	};

	const blockTypes = [
		"Rating",
		"ProductImageSlider"
	]

	if (!block) {
		return null;
	}
	if (block.type == "Rating" && !blockData?.data?.showRating) {
		return null;
	}
	if (block.type == "Discount" && !blockData?.data?.showDiscount) {
		return null;
	}
	if (block.type == "addToCart" && !blockData?.data?.addToCart) {
		return null;
	}


	// if (block.isSort) {
	if (block.children && !blockTypes.includes(block.type)) {
		return (
			<Fragment>
				{
					React.createElement(
						block?.type,
						{
							key: block.id,
							id: block?.id,
							className: `${block?.css
								?.replaceAll(
									"secondary_color",
									`[${currentTheme?.secondary_color}]`
								)
								?.replaceAll(
									"bg_color",
									`[${currentTheme?.bg_color}]`
								)
								?.replaceAll(
									"textColor",
									`[${currentTheme?.textColor}]`
								)
								?.replaceAll(
									"primary_color",
									`[${currentTheme?.primary_color}]`
								)
								?.replaceAll(
									"primary_font_size",
									`[${currentTheme?.primary_font_size}]`
								)
								?.replaceAll(
									"secondary_font_size",
									`[${currentTheme?.secondary_font_size}]`
								)
								?.replaceAll(
									"font_size",
									`[${currentTheme?.font_size}]`
								)} ${block?.css?.includes('grid') && ' !block'} `,
							src: block.src && block?.src,
							...block.data,
							onClick: (event) => {
								console.log(index, blocks)
								handleClick(event, `${block.id}`, block)
							},
						},
						<Container
							block={block}
							currentTheme={currentTheme}
							css={block?.css}
							data={value}
							setData={setData}
							index={index}
							product={product}
							handleMouseOver={handleMouseOver}
							handleComponent={handleComponent}
							blocks={blocks}
							blockData={blockData}
							blockIndex={blockIndex}
						/>
					)
				}
			</Fragment>
		);
	}
	// else if (block.type == "Dropdown") {
	//     return <Dropdown block={block} />
	// }
	// else if (block.type == "Quantity") {
	//     return <Quantity block={block} data={value} />
	// }
	// else if (block.type == "DynamicCarousal") {
	//     return <DynamicCarousal block={block} value={value} />
	// }
	// else if (block.type == "Dynamic") {
	//     return <Dynamic block={block} data={value[`${block?.data?.type}`]} />
	// }
	else if (block.type == "Rating") {
		console.log("Rating RUNNING")
		return <Rating block={block} value={value} handleClick={handleClick} />;
	}
	else if (block.type == "ProductImageSlider") {
		return <ProductImageSlider currentTheme={currentTheme} block={block} data={value} handleClick={handleClick} handleMouseOver={handleMouseOver} blockIndex={blockIndex} />
	}
	// else if (block.type == "Carousal") {
	//     return <Carousal block={block} />
	// }
	// else if (block.type == "ProductImage") {
	//     return <ProductImage block={block} data={value} />
	// }
	else {
		return (
			<Fragment>
				{
					React.createElement(
						block.type,
						{
							key: block.id,
							id: block?.id,
							className: `${block?.css
								?.replaceAll(
									"secondary_color",
									`[${currentTheme?.secondary_color}]`
								)
								?.replaceAll(
									"bg_color",
									`[${currentTheme?.bg_color}]`
								)
								?.replaceAll(
									"textColor",
									`[${currentTheme?.textColor}]`
								)
								?.replaceAll(
									"primary_color",
									`[${currentTheme?.primary_color}]`
								)
								?.replaceAll(
									"primary_font_size",
									`[${currentTheme?.primary_font_size}]`
								)
								?.replaceAll(
									"secondary_font_size",
									`[${currentTheme?.secondary_font_size}]`
								)
								?.replaceAll(
									"font_size",
									`[${currentTheme?.font_size}]`
								)}`,
							// src: value[block?.data.src],
							onClick: (event) => {
								console.log(index, blocks)
								handleClick(event, `${block.id}`, block)
							},
							onDoubleClick: (e) => {
								e.target.contentEditable = true
							},
							onBlur: (e) => {
								if (!block?.dynamic) {
									Bdispatch({
										type: "UPDATE_CONTENT", id: block.id, element: e.target.innerText,
									})
								} else {
									blocks.data.staticData[index][block?.content] = e?.target?.innerText
								}
							},
							src: block?.src && Array.isArray(value?.[block?.src]) ? value?.[block?.src]?.[0] : value?.[block?.src],
							...block.data,
						},
						// block.content
						block.dynamic ? value?.[`${block.content}`] : block?.content
					)
				}

			</Fragment>
		);
	}
}
