import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { BlockWrapper } from "../Sortable";

// TODO: check why arrows not working properly in builder only (may be due to BlockWrapper)

function Icon({ style, name }) {
	return (
		<span
			icon={true}
			className={`${name} ${style} flex items-center`}
		></span>
	);
}

const responsive = (item) => {
	return {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: item.largeDesktop,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: item.desktop,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: item.tablet,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: item.mobile,
		},
	};
};

const CustomDot = ({ onMove, index, onClick, active }) => {
	return (
		<button
			type="button"
			onClick={() => onClick()}
			className={` w-4 h-4 mx-1 rounded-full ${active ? "bg-[#7DB800]" : "bg-gray-800"
				}`}
		></button>
	);
};

const CommonDots = ({
	onClick,
	active,
	activeCSS = "",
	inactiveCSS = "",
	commonCSS = "",
	currentTheme,
}) => {
	return (
		<button
			type="button"
			onClick={() => onClick()}
			className={` @xs:hidden @sm:block ${commonCSS
				?.replace(/secondary_color/g, `[${currentTheme?.secondary_color}]`)
				?.replace(/bg_color/g, `[${currentTheme?.bg_color}]`)
				?.replace(/textColor/g, `[${currentTheme?.textColor}]`)
				?.replace(/primary_color/g, `[${currentTheme?.primary_color}]`)
				?.replace(/primary_font_size/g, `[${currentTheme?.primary_font_size}]`)
				?.replace(/secondary_font_size/g, `[${currentTheme?.secondary_font_size}]`)
				?.replace(/font_size/g, `[${currentTheme?.font_size}]`)} ${active ? activeCSS?.replace(/secondary_color/g, `[${currentTheme?.secondary_color}]`)
					?.replace(/bg_color/g, `[${currentTheme?.bg_color}]`)
					?.replace(/textColor/g, `[${currentTheme?.textColor}]`)
					?.replace(/primary_color/g, `[${currentTheme?.primary_color}]`)
					?.replace(/primary_font_size/g, `[${currentTheme?.primary_font_size}]`)
					?.replace(/secondary_font_size/g, `[${currentTheme?.secondary_font_size}]`)
					?.replace(/font_size/g, `[${currentTheme?.font_size}]`)
					:
					inactiveCSS?.replace(/secondary_color/g, `[${currentTheme?.secondary_color}]`)
						?.replace(/bg_color/g, `[${currentTheme?.bg_color}]`)
						?.replace(/textColor/g, `[${currentTheme?.textColor}]`)
						?.replace(/primary_color/g, `[${currentTheme?.primary_color}]`)
						?.replace(/primary_font_size/g, `[${currentTheme?.primary_font_size}]`)
						?.replace(/secondary_font_size/g, `[${currentTheme?.secondary_font_size}]`)
						?.replace(/font_size/g, `[${currentTheme?.font_size}]`)
				}`}
		></button>
	);
};

const CustomLeftArrow = ({ onClick }) => {
	return (
		<button type="button" onClick={() => onClick()} className=" absolute left-0">
			<Icon name="mdi mdi-arrow-left" style="text-2xl" />
		</button>
	);
};

const CustomRightArrow = ({ onClick }) => {
	return (
		<button type="button" onClick={() => onClick()} className="absolute right-0">
			<Icon name="mdi mdi-arrow-right" style="text-2xl" />
		</button>
	);
};

const CommonLeftArrow = ({
	onClick,
	buttonCSS,
	children,
	blockIndex,
	currentTheme,
}) => {
	return (
		<button
			type="button"
			onClick={() => onClick()}
			className={buttonCSS
				?.replace(/secondary_color/g, `[${currentTheme?.secondary_color}]`)
				?.replace(/bg_color/g, `[${currentTheme?.bg_color}]`)
				?.replace(/textColor/g, `[${currentTheme?.textColor}]`)
				?.replace(/primary_color/g, `[${currentTheme?.primary_color}]`)
				?.replace(/primary_font_size/g, `[${currentTheme?.primary_font_size}]`)
				?.replace(/secondary_font_size/g, `[${currentTheme?.secondary_font_size}]`)
				?.replace(/font_size/g, `[${currentTheme?.font_size}]`)}
		>
			{children &&
				children?.map((childBlock, i) => {
					return (
						<BlockWrapper
							key={childBlock.id}
							block={childBlock}
							blockIndex={[...blockIndex, i]}
							index={i}
							currentTheme={currentTheme}
						/>
					);
				})}
		</button>
	);
};

const CommonRightArrow = ({
	onClick,
	buttonCSS,
	children,
	blockIndex,
	currentTheme,
}) => {
	return (
		<button
			type="button"
			onClick={() => onClick()}
			className={buttonCSS
				?.replace(/secondary_color/g, `[${currentTheme?.secondary_color}]`)
				?.replace(/bg_color/g, `[${currentTheme?.bg_color}]`)
				?.replace(/textColor/g, `[${currentTheme?.textColor}]`)
				?.replace(/primary_color/g, `[${currentTheme?.primary_color}]`)
				?.replace(/primary_font_size/g, `[${currentTheme?.primary_font_size}]`)
				?.replace(/secondary_font_size/g, `[${currentTheme?.secondary_font_size}]`)
				?.replace(/font_size/g, `[${currentTheme?.font_size}]`)}
		>
			{children &&
				children?.map((childBlock, i) => {
					return (
						<BlockWrapper
							key={childBlock.id}
							block={childBlock}
							index={i}
							blockIndex={[...blockIndex, i]}
							currentTheme={currentTheme}
						/>
					);
				})}
		</button>
	);
};

export default function Carousal({
	block,
	blockIndex,
	handleMouseOver,
	currentTheme = { currentTheme },
}) {
	const response = responsive(
		block?.data?.responsive
			? block.data.responsive
			: {
				largeDesktop: 1,
				desktop: 1,
				tablet: 1,
				mobile: 1,
			}
	);

	return (
		<Carousel
			containerClass="carousel-container"
			itemClass={block?.data?.itemClass}
			arrows={block?.data?.showArrows}
			responsive={response}
			centerMode={false}
			className={block?.css}
			dotListClass=""
			draggable={false}
			focusOnSelect={false}
			infinite={block?.data?.infinite ?? false}
			keyBoardControl
			minimumTouchDrag={80}
			renderButtonGroupOutside={false}
			renderDotsOutside={false}
			autoPlay={block?.data?.autoPlay || false}
			autoPlaySpeed={block?.data?.autoPlaySpeed || 3000}
			showDots={block?.data?.showDots}
			sliderClass=""
			slidesToSlide={1}
			swipeable
			customRightArrow={
				block?.data?.CommonRightArrow ? (
					<CommonRightArrow
						buttonCSS={block?.data?.CommonRightArrow?.buttonCSS}
						children={block?.data?.CommonRightArrow?.children}
						blockIndex={blockIndex}
						currentTheme={currentTheme}
					/>
				) : (
					<CustomRightArrow />
				)
			}
			customLeftArrow={
				block?.data?.CommonLeftArrow ? (
					<CommonLeftArrow
						buttonCSS={block?.data?.CommonLeftArrow?.buttonCSS}
						children={block?.data?.CommonLeftArrow?.children}
						blockIndex={blockIndex}
						currentTheme={currentTheme}
					/>
				) : (
					<CustomLeftArrow />
				)
			}
			customDot={
				block?.data?.customDot ? (
					<CommonDots
						activeCSS={block?.data?.customDot?.activeCSS}
						inactiveCSS={block?.data?.customDot?.inactiveCSS}
						commonCSS={block?.data?.customDot?.commonCSS}
						blockIndex={blockIndex}
						currentTheme={currentTheme}
					/>
				) : (
					<CustomDot />
				)
			}
			onMouseOver={(e) => handleMouseOver(e)}
		>
			{block?.children?.map((item, i) => (
				<BlockWrapper
					block={item}
					currentTheme={currentTheme}
					blockIndex={[...blockIndex, i]}
					index={i}
				/>
			))}
		</Carousel>
	);
}
