import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BlockWrapper } from "../Sortable";
import React, { createElement } from "react";
import { useContext } from "react";
import { context } from "../../../../context/Context";

const CustomDot = ({ onClick, active, activeCSS, inactiveCSS, commonCSS, currentTheme }) => {
	return (
		<button
			type="button"
			onClick={() => {
				onClick();
			}
			}
			className={`${commonCSS
				?.replace(/secondary_color/g, `[${currentTheme?.secondary_color}]`)
				?.replace(/bg_color/g, `[${currentTheme?.bg_color}]`)
				?.replace(/textColor/g, `[${currentTheme?.textColor}]`)
				?.replace(/primary_color/g, `[${currentTheme?.primary_color}]`)
				?.replace(/primary_font_size/g, `[${currentTheme?.primary_font_size}]`)
				?.replace(/secondary_font_size/g, `[${currentTheme?.secondary_font_size}]`)
				?.replace(/font_size/g, `[${currentTheme?.font_size}]`)} ${active ? activeCSS?.replace(/secondary_color/g, `[${currentTheme?.secondary_color}]`)
					?.replace(/bg_color/g, `[${currentTheme?.bg_color}]`)
					?.replace(/textColor/g, `[${currentTheme?.textColor}]`)
					?.replace(/primary_color/g, `[${currentTheme?.primary_color}]`)
					?.replace(/primary_font_size/g, `[${currentTheme?.primary_font_size}]`)
					?.replace(/secondary_font_size/g, `[${currentTheme?.secondary_font_size}]`)
					?.replace(/font_size/g, `[${currentTheme?.font_size}]`)
					:
					inactiveCSS?.replace(/secondary_color/g, `[${currentTheme?.secondary_color}]`)
						?.replace(/bg_color/g, `[${currentTheme?.bg_color}]`)
						?.replace(/textColor/g, `[${currentTheme?.textColor}]`)
						?.replace(/primary_color/g, `[${currentTheme?.primary_color}]`)
						?.replace(/primary_font_size/g, `[${currentTheme?.primary_font_size}]`)
						?.replace(/secondary_font_size/g, `[${currentTheme?.secondary_font_size}]`)
						?.replace(/font_size/g, `[${currentTheme?.font_size}]`)
				}`}
		></button>
	)
}

const CustomArrow = ({ onClick, schema, blockIndex, currentTheme, handleClick }) => {
	return (
		<>
		{
			schema?.map((value, index) =>{
				return (
					<BlockWrapper block={value} blockIndex={[...blockIndex, index]} onClick={onClick} />
				)
			})
		}
		</>
		// React.createElement(
		// 	schema.type,
		// 	{
		// 		id: schema.id,
		// 		className: schema.css?.replaceAll("secondary_color", `[${currentTheme?.secondary_color}]`)
		// 		?.replaceAll("bg_color", `[${currentTheme?.bg_color}]`)
		// 		?.replaceAll("textColor", `[${currentTheme?.textColor}]`)
		// 		?.replaceAll("primary_color", `[${currentTheme?.primary_color}]`)
		// 		?.replaceAll("primary_font_size", `[${currentTheme?.primary_font_size}]`)
		// 		?.replaceAll("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
		// 		?.replaceAll("font_size", `[${currentTheme?.font_size}]`),

		// 		onClick: (e) => {
		// 			onClick();
		// 			console.log("schemaId",schema)
		// 			// handleClick(e, schema.id, false, -1, true);
		// 		},
		// 		style: {
		// 			fontFamily: schema.style?.fontFamily
		// 		}	
		// 	},
		// 	schema.children ? schema.children.map((value, index) => {
		// 		return (
		// 			<BlockWrapper block={value} blockIndex={[...blockIndex, index]} onClick={onClick} />
		// 			// <Renderer elementId={elementId} setElementId={setElementId} schema={value} currentTheme={currentTheme} key={index} handleClick={handleClick} elementRef={elementRef} />
		// 		)
		// 	}) : schema.content
		// )
	)
}
const Renderer = ({ schema, currentTheme, customButtonGroupFunctions, handleClick, elementId, setElementId, elementRef }) => {

	return (
		createElement(
			schema.type,
			{
				id: schema.id,
				className: schema.css?.replaceAll("secondary_color", `[${currentTheme?.secondary_color}]`)
					?.replaceAll("bg_color", `[${currentTheme?.bg_color}]`)
					?.replaceAll("textColor", `[${currentTheme?.textColor}]`)
					?.replaceAll("primary_color", `[${currentTheme?.primary_color}]`)
					?.replaceAll("primary_font_size", `[${currentTheme?.primary_font_size}]`)
					?.replaceAll("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
					?.replaceAll("font_size", `[${currentTheme?.font_size}]`),
				...schema.data,
				onClick: (e) => {
					if (schema.events?.onClick) {
						eval(schema.events.onClick);
					} else {
						// setElementId(schema);
						handleClick(e, schema.id, false, -1, true);
						// console.log("This is elementId:",elementId)

					}
				},
				style: {
					fontFamily: schema.style?.fontFamily
				}
			},
			schema.children ? schema.children.map((value, index) => {
				return (
					<Renderer schema={value} currentTheme={currentTheme} key={index} customButtonGroupFunctions={customButtonGroupFunctions} handleClick={handleClick} />
				)
			}) : schema.content
		)
	)
}

const CustomButtonGroup = ({ schema, currentTheme, ...props }) => {
	return (
		schema.map((value, index) => {
			return (
				<Renderer schema={value} currentTheme={currentTheme} key={index} customButtonGroupFunctions={props} />
			)
		}
		))
}
export default function Carousal2({
	block,
	blockIndex,
	handleMouseOver,
	currentTheme,
	handleClick
}) {
	const { setComponent, componentWithSettingsRef, setElementId, elementId, elementRef } = useContext(context);
	const responsive = (data) => {
		return {
			base: {
				breakpoint: { max: 425, min: 0 },
				items: data.base.items,
				slidesToSlide: data.base.slidesToSlide
			},
			xs: {
				breakpoint: { max: 640, min: 425 },
				items: data.xs.items,
				slidesToSlide: data.xs.slidesToSlide
			},
			sm: {
				breakpoint: { max: 768, min: 640 },
				items: data.sm.items,
				slidesToSlide: data.sm.slidesToSlide
			},
			md: {
				breakpoint: { max: 1024, min: 768 },
				items: data.md.items,
				slidesToSlide: data.md.slidesToSlide
			},
			lg: {
				breakpoint: { max: 1280, min: 1024 },
				items: data.lg.items,
				slidesToSlide: data.lg.slidesToSlide
			},
			xl: {
				breakpoint: { max: 1536, min: 1280 },
				items: data.xl.items,
				slidesToSlide: data.xl.slidesToSlide
			},
			'2xl': {
				breakpoint: { max: 9999, min: 1536 },
				items: data['2xl'].items,
				slidesToSlide: data['2xl'].slidesToSlide
			}
		};
	};
	const responsiveObject = responsive(
		block.data?.responsive
			? block.data.responsive
			: {
				"base": {
					items: 1,
					slidesToSlide: 1
				},
				"xs": {
					items: 1,
					slidesToSlide: 1
				},
				"sm": {
					items: 1,
					slidesToSlide: 1
				},
				"md": {
					items: 1,
					slidesToSlide: 1
				},
				"lg": {
					items: 1,
					slidesToSlide: 1
				},
				"xl": {
					items: 1,
					slidesToSlide: 1
				},
				"2xl": {
					items: 1,
					slidesToSlide: 1
				}
			}
	);

	const handleComponentWithSettingsClick = () => {
		const component = document.getElementById(block.id);
		setComponent(block);
		if (componentWithSettingsRef.current) {
			componentWithSettingsRef.current.style.outline = 'none';
		}
		componentWithSettingsRef.current = component;
		componentWithSettingsRef.current.style.outline = '2px dashed #4F46E5';
	}

	return (
		<div id={block.id} onClick={(e) => {
			handleComponentWithSettingsClick();
			handleClick(e, block.id, true, -1, true);

		}}
			onMouseOver={(e) => {
				handleMouseOver(e);
			}}
		>

			<Carousel

				responsive={responsiveObject}

				draggable={(block.data?.draggable != null || block.data?.draggable != undefined) ? block.data.draggable : true}
				swipeable={(block.data?.swipeable != null || block.data?.swipeable != undefined) ? block.data.swipeable : true}
				infinite={(block.data?.infinite != null || block.data?.infinite != undefined) ? block.data.infinite : false}

				containerClass={block.css ? block.css : null}
				itemClass={block.data?.itemClass ? block.data.itemClass : null}
				sliderClass={block.data?.sliderClass ? block.data.sliderClass : null}

				arrows={(block.data?.arrows != null || block.data?.arrows != undefined) ? block.data.arrows : true}
				customRightArrow={block.data?.customRightArrow ?
					<CustomArrow schema={[block.data?.customRightArrow]} blockIndex={[...blockIndex, blockIndex+1]} currentTheme={currentTheme}  handleClick={handleClick} />
					:
					null
				}
				customLeftArrow={block.data?.customLeftArrow ?
					<CustomArrow schema={[block.data?.customLeftArrow]} blockIndex={[...blockIndex, blockIndex+2]} handleClick={handleClick} currentTheme={currentTheme} />
					:
					null
				}
				removeArrowOnDeviceType={block.data?.removeArrowOnDeviceType ? block.data.removeArrowOnDeviceType : []}

				showDots={(block.data?.showDots != null || block.data?.showDots != undefined) ? block.data.showDots : false}
				dotListClass='!overflow-x-auto w-full @md:w-fit'

				customDot={block.data?.customDot ?
					<CustomDot
						activeCSS={block.data?.customDot?.activeCSS}
						inactiveCSS={block.data?.customDot?.inactiveCSS}
						commonCSS={block.data?.customDot?.commonCSS}
						currentTheme={currentTheme}
						blockIndex={blockIndex}
						handleClick={handleClick}
					/>
					:
					null
				}
				customButtonGroup={block.data?.customButtonGroup ?
					<CustomButtonGroup schema={block.data.customButtonGroup} currentTheme={currentTheme} />
					:
					null
				}

				autoPlay={(block.data?.autoPlay != null || block.data?.autoPlay != undefined) ? block.data.autoPlay : false}
				rewind={(block.data?.rewind != null || block.data?.rewind != undefined) ? block.data.rewind : false}
				autoPlaySpeed={(block.data?.autoPlaySpeed != null || block.data?.autoPlay != undefined) ? block.data.autoPlaySpeed : 3000}

				slidesToSlide={(block.data?.slidesToSlide != null || block.data?.slidesToSlide != undefined) ? block.data.slidesToSlide : 1}
				minimumTouchDrag={(block.data?.minimumTouchDrag != null || block.data?.minimumTouchDrag != undefined) ? block.data.minimumTouchDrag : 50}
				keyBoardControl={(block.data?.keyBoardControl != null || block.data?.keyBoardControl != undefined) ? block.data.keyBoardControl : true}
				renderDotsOutside={(block.data?.renderDotsOutside != null || block.data?.renderDotsOutside != undefined) ? block.data.renderDotsOutside : false}
				customTransition={(block.data?.customTransition != null || block.data?.customTransition != undefined) ? block.data.customTransition : 'transform 300ms ease-in-out'}
				transitionDuration={(block.data?.transitionDuration != null || block.data?.transitionDuration != undefined) ? block.data.transitionDuration : 300}
				centerMode={(block.data?.centerMode != null || block.data?.centerMode != undefined) ? block.data.centerMode : false}
				additionalTransfrom={(block.data?.additionalTransfrom != null || block.data?.additionalTransfrom != undefined) ? block.data.additionalTransfrom : 0}
				shouldResetAutoplay={(block.data?.shouldResetAutoplay != null || block.data?.shouldResetAutoplay != undefined) ? block.data.shouldResetAutoplay : true}
				rewindWithAnimation={(block.data?.rewindWithAnimation != null || block.data?.rewindWithAnimation != undefined) ? block.data.rewindWithAnimation : false}
				rtl={(block.data?.rtl != null || block.data?.rtl != undefined) ? block.data.rtl : false} >
					
				{block?.children?.map((item, index) => (
					<BlockWrapper
						block={item}
						currentTheme={currentTheme}
						blockIndex={[...blockIndex, index]}
						customComponentChildren={true}
						handleComponentWithSettingsClick={handleComponentWithSettingsClick}
					/>
				))}
			</Carousel>
		</div>

	);
}
