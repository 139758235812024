import ReactPlayer from 'react-player';

export default function VideoPlayer({ block, handleClick, handleMouseOver }) {
	return (
		<div 
			className='w-full h-full p-2'
			onClick={(e) => { handleClick(e, block.id) }}
			onMouseOver={(e) => handleMouseOver(e)}
		>
			<ReactPlayer
				id={block?.id}
				width="100%"
				height="100%"
				// height="100%"
				// width={block}
				url={block?.data?.src}
				controls={true}
				playing={false}
				muted={true}

				// Disable download button
  				config={{ file: { attributes: { controlsList: 'nodownload' } } }}
			/>
		</div>
	)
}
