import { useContext } from "react";
import { context } from "../../../context/Context";
import { Formik } from "formik";
import { AddToUndo } from "../../../screens/account/editor";


const GoogleMapSettings = () => {
	const { component, blocks, undo, setUndo, setRedo, strapiSchema, sqliteSchema, selectedSiteData } = useContext(context);
	return (
		<div className="space-y-6">
			<Formik
				enableReinitialize={true}
				initialValues={{
					src: (component.data?.src != null && component.data?.src != undefined) ? component.data.src : ""
				}}
				onSubmit={async (values) => {
					component.data = { ...component.data, ...values };
					AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
				}}
			>
				{
					(formik) => (
						<form onSubmit={formik.handleSubmit} className="space-y-5">

							<div className="space-y-2">
								<div className="text-sm font-medium">Source</div>
								<textarea name="src" id="src" value={formik.values.src} onChange={formik.handleChange} onBlur={formik.handleBlur} rows={10} className="w-full border border-gray-300 rounded-md py-1 text-sm focus:outline-none focus:border-indigo-600 focus:border-2 focus:ring-0 no-scrollbar" />

							</div>

							<button type="submit"
								disabled={formik.isSubmitting}
								className="bg-indigo-600 px-3 py-2 font-medium text-sm rounded-md text-white w-full"
							>
								Save
							</button>
						</form>
					)
				}
			</Formik>
		</div>
	)
}
export default GoogleMapSettings;