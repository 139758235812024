import { Listbox } from "@headlessui/react";

const Dropdown = ({ elementBreakpointSpecificStyleObject, propertyData, updateDesign }) => {

	return (
		<Listbox
			as={'div'}
			className={'relative text-xs'}
			value={
				elementBreakpointSpecificStyleObject[propertyData.propertyName]
			}
			onChange={(value) => {

				updateDesign(
					propertyData.propertyName,
					value
				);
			}}
		>
			<Listbox.Button className={`cursor-pointer py-1 px-2 border border-[#D9D9D9] text-[#373C47] flex items-center justify-between gap-1 capitalize rounded`}>
				<span>
					{
						elementBreakpointSpecificStyleObject[propertyData.propertyName]
					}
				</span>
				<span class="mdi mdi-chevron-down"></span>

			</Listbox.Button>
			<Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
				{
					propertyData.options.map(
						(
							value,
							index
						) => (
							<Listbox.Option
								key={index}
								className={({
									active,
								}) => `${active ? "bg-gray-100" : ""} cursor-pointer`
								}
								value={value}
							>
								{({ selected }) => (
									<div className={`flex items-center gap-1 px-2 py-1.5 text-gray-900 capitalize${selected ? " bg-indigo-100 font-medium" : ""}`}>
										<div>{value}</div>
										{
											elementBreakpointSpecificStyleObject[propertyData.propertyName] == value &&
											<span class="mdi mdi-check"></span>
										}
									</div>
								)}
							</Listbox.Option>
						)
					)}
			</Listbox.Options>
		</Listbox>
	)
}
export default Dropdown;