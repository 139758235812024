import { useContext } from 'react';
import { Container } from '../Sortable';
import { context } from '../../../../context/Context';
import { useLocation, useParams } from 'react-router-dom';
import { generateCss } from '../functions/sortableFunctions';
const Form = ({ block, ...props }) => {

	const {
		setComponent
	} = useContext(context);
	const handleComponentWithSettingsClick = () => {
		setComponent(block);
	}
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (
		<>
			<form
				id={block.id}
				className={generateCss({
					contextVariables: contextVariables,
					props: {
						...props,
						block: block
					},
					params: params,
					location: location
				})}
				style={{
					fontFamily: block?.style?.fontFamily
				}}
				onClick={(e) => {
					props.onClick(e, block.id);
					handleComponentWithSettingsClick();
				}}
				onMouseOverCapture={props.onMouseOverCapture}
				onMouseOut={props.onMouseOut}
				onDragOver={props.onDragOver}
				onDrag={props.onDrag}
				onDragStart={props.onDragStart}
				onDrop={props.onDrop}
			>

				{
					block.children &&
					<Container
						{...props}
						block={block}
						blockIndex={props.blockIndex}
						customComponentChildren={true}
						handleComponentWithSettingsClick={handleComponentWithSettingsClick}
						dragOutsideNotAllowed={true}
					/>
				}
			</form>
		</>
	)

}
export default Form;