import { BlockWrapper } from "../Sortable";
import { createElement, useContext } from "react";
import { context } from "../../../../context/Context";
import { useLocation, useParams } from "react-router-dom";
import { generateCss } from "../functions/sortableFunctions";
const SocialLinks = ({ block, ...props }) => {
	const { componentWithSettingsRef, setComponent } = useContext(context);
	const handleComponentWithSettingsClick = () => {
		const component = document.getElementById(block.id);
		if (componentWithSettingsRef.current != component) {
			setComponent(block);
			if (componentWithSettingsRef.current) {
				componentWithSettingsRef.current.style.outline = 'none';
			}
			componentWithSettingsRef.current = component;
			componentWithSettingsRef.current.style.outline = '2px dashed #4F46E5';
		}
	}
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return createElement(
		'div',
		{
			id: block.id,
			style: {
				fontFamily: block?.style?.fontFamily
			},
			className: generateCss({
				contextVariables: contextVariables,
				props: {
					...props,
					block: block
				},
				params: params,
				location: location
			}),
			...block.data,
			onClick: (e) => {
				props.handleClick(e, block.id, false, -1, true);
				handleComponentWithSettingsClick();
			},
			onMouseOver: (e) => props.handleMouseOver(e),
		},
		block.children.map((value, index) => {
			return <BlockWrapper
				{...props}
				key={index}
				block={value}
				blockIndex={[...props.blockIndex, index]}
				customComponentChildren={true}
				handleComponentWithSettingsClick={handleComponentWithSettingsClick}
				platformsToShow={block.data?.platformsToShow}
			/>
		})

	)
}
export default SocialLinks;