import { Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';


const PAGE_SIZES = [1, 15, 20];

export default function GettingStartedExample({ handleMouseOver }) {
	const [pageSize, setPageSize] = useState(PAGE_SIZES[1]);

	useEffect(() => {
	  setPage(1);
	}, [pageSize]);
  
	const [page, setPage] = useState(1);
  
	useEffect(() => {
	  const from = (page - 1) * pageSize;
	  const to = from + pageSize;
	}, [page, pageSize]);
	return (
		<DataTable
			onMouseOver={(e) => handleMouseOver(e)}
		
			// height={300}
			// scrollAreaProps={{ type: 'never' }}
			withBorder
			borderRadius="sm"
			withColumnBorders
			striped
			highlightOnHover
			// provide data
			records={[
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
				{ id: 1, name: 'Joe Biden', bornIn: 1942, party: 'Democratic' },
			]}

			// define columns
			columns={[
				{
					accessor: 'id',
					// this column has a custom title
					title: '#',
					// right-align column
					textAlignment: 'right',
				},
				{ accessor: 'name' },
				{
					accessor: 'party',
					// this column has custom cell data rendering
					render: ({ party }) => (
						<Text weight={700} color={party === 'Democratic' ? 'blue' : 'red'}>
							{party.slice(0, 3).toUpperCase()}
						</Text>
					),
				},
				{ accessor: 'bornIn' },
			]}
			// execute this callback when a row is clicked
			// onRowClick={({ name, party, bornIn }) =>
			// 	alert(`You clicked on ${name}, a ${party.toLowerCase()} president born in ${bornIn}.`)
			// }
			totalRecords={100}
			paginationColor="grape"
			recordsPerPage={pageSize}
			page={page}
			onPageChange={(p) => setPage(p)}
			recordsPerPageOptions={PAGE_SIZES}
			onRecordsPerPageChange={setPageSize}
		/>
	);
}