import { Listbox } from '@headlessui/react';
import React, { useEffect, useState, useContext, useRef } from 'react'
import removeDuplicateClasses from './removeDuplicateClasses';
import addNewStyleClass from './addNewStyleClass';
import { AddToUndo } from '../../../screens/account/editor';
import { context } from '../../../context/Context';
const AllPositions = [
    {
        name: "top",
        symbol: "up"
    },
    {
        name: "right",
        symbol: "right"
    },
    {
        name: "bottom",
        symbol: "down"
    },
    {
        name: "left",
        symbol: "left"
    }
];

const AllCursors = [
    "auto",
    "default",
    "pointer",
    "wait",
    "text",
    "move",
    "help",
    "Not-Allowed",
    "none",
    "context-menu",
    "progress",
    "cell",
    "crosshair",
    "vertical-text",
    "alias",
    "copy",
    "no-drop",
    "grab",
    "grabbing",
    "all-scroll",
    "col-resize",
    "row-resize",
    "n-resize",
    "e-resize",
    "s-resize",
    "w-resize",
    "ne-resize",
    "nw-resize",
    "se-resize",
    "sw-resize",
    "ew-resize",
    "ns-resize",
    "nesw-resize",
    "nwse-resize",
    "zoom-in",
    "zoom-out",
];

const AllOverflowsValue = [
    "visible",
    "hidden",
    "clip",
    "auto",
    "scroll",
];

const AllOverflowWrapValue = [
    "normal",
    "break-word",
];

const AllAspectratio = [
    {
        name: "auto",
        Value: "auto"
    },
    {
        name: "square",
        Value: "1/1"
    },
    {
        name: "video",
        Value: "16/9",
    },
    {
        name: "custom",
        Value: "1/2"
    }
];



const LayoutSection = ({ elementBreakpointSpecificStyleObject, setElementAllBreakpointsStyleObject, valueTypes, updateDesign, setValueTypes, setElementBreakpointSpecificStyleObject, openedSections, setOpenedSections, classes, setClasses, selectedBreakpoint, selectedState, addImportant }) => {
    const timerRef = useRef();
    const [toggleMoreOptions, setToggleMoreOptions] = useState(false);
    const [currentAspect, setCurrentAspect] = useState("auto");
    const {
        elementId, elementRef, formElementRef,
        selectedSiteData,
        blocks,
        sqliteSchema,
        strapiSchema,
        undo,
        setUndo,
        setRedo
    } = useContext(context);

    const individualProperties = ["overflowX", "overflowY"];
    const customaspects = elementBreakpointSpecificStyleObject?.aspectRatio ? elementBreakpointSpecificStyleObject?.aspectRatio?.split('/') : [2, 1];
    const [customAspect, setCustomAspect] = useState({
        height: customaspects[0],
        width: customaspects[1]
    });


    const handleSelectObjectPosition = (objectValue) => {
        updateDesign("objectPosition", objectValue);
    }


    const getCurrentAspect = () => {
        for (let i = 0; i < AllAspectratio.length; i++) {
            if (!elementBreakpointSpecificStyleObject.aspectRatio) {
                setCurrentAspect("auto");
                break;
            }
            if (AllAspectratio[i].Value === elementBreakpointSpecificStyleObject.aspectRatio) {
                setCurrentAspect(AllAspectratio[i].name);
                break;
            }
            else {
                setCurrentAspect("custom");
            }
        }
    }

    useEffect(() => {
        getCurrentAspect();
    }, [elementBreakpointSpecificStyleObject.aspectRatio])

    return (
        <div>
            <div
                className={`flex items-center justify-between py-1.5 px-3 bg-[#F3F4F6] hover:bg-gray-200 hover cursor-pointer font-medium${openedSections.layout == true
                    ? " border-b border-gray-300"
                    : ""
                    }`}
                onClick={() => {
                    setOpenedSections((prev) => {
                        return {
                            ...prev,
                            layout: !prev.layout,
                        };
                    });
                }}
            >
                <div className="text-[#373C47]">
                    Layout
                </div>

                {openedSections.layout == false ? (
                    <span class="mdi mdi-chevron-right text-lg"></span>
                ) : (
                    <span class="mdi mdi-chevron-down text-lg"></span>
                )}
            </div>
            {openedSections.layout == true && (
                <div className="">
                    {/*Start - Display */}
                    <div className="p-3 space-y-3 border-b text-[#373C47]">
                        <div className="flex items-center justify-between text-[#373C47]">
                            <div className="text-base ">
                                Display
                            </div>
                            <Listbox
                                value={
                                    elementBreakpointSpecificStyleObject.display
                                }
                                onChange={(value) => {
                                    updateDesign("display", value);
                                }}
                            >
                                <div className="relative w-full max-w-[130px]">
                                    <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                        <span className="capitalize">
                                            {
                                                elementBreakpointSpecificStyleObject.display
                                            }
                                        </span>
                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                    </Listbox.Button>
                                    <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                        {[
                                            "none",
                                            "block",
                                            "inline",
                                            "inline-block",
                                            "flex",
                                            "inline-flex",
                                            "grid",
                                            "inline-grid",
                                            "list-item"
                                        ].map((value, index) => (
                                            <Listbox.Option
                                                key={`breakpointmap_${index}`}
                                                className={({
                                                    active,
                                                }) =>
                                                    `${active
                                                        ? "bg-gray-100"
                                                        : ""
                                                    } cursor-pointer`
                                                }
                                                value={value}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <div
                                                            className={`py-1.5 px-3 text-[#373C47] capitalize ${selected
                                                                ? " bg-indigo-100 font-medium"
                                                                : ""
                                                                }`}
                                                        >
                                                            {value}
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </div>
                            </Listbox>
                        </div>

                        {(elementBreakpointSpecificStyleObject.display ==
                            "flex" ||
                            elementBreakpointSpecificStyleObject.display ==
                            "inline-flex") && (
                                <>
                                    {[
                                        {
                                            displayName: "Flex Direction",
                                            propertyName: "flexDirection",
                                            possibleValues: [
                                                "row",
                                                "row-reverse",
                                                "column",
                                                "column-reverse",
                                            ],
                                        },
                                        {
                                            displayName: "Flex Wrap",
                                            propertyName: "flexWrap",
                                            possibleValues: [
                                                "wrap",
                                                "nowrap",
                                                "wrap-reverse",
                                            ],
                                        },
                                    ].map((propertyData, index) => {
                                        return (
                                            <div
                                                className="flex items-center justify-between text-[#373C47] ml-3"
                                                key={index}
                                            >
                                                <div className="text-sm">
                                                    {
                                                        propertyData.displayName
                                                    }
                                                </div>
                                                <Listbox
                                                    value={
                                                        elementBreakpointSpecificStyleObject[
                                                        propertyData
                                                            .propertyName
                                                        ]
                                                    }
                                                    onChange={(value) => {
                                                        updateDesign(
                                                            propertyData.propertyName,
                                                            value
                                                        );
                                                    }}
                                                >
                                                    <div className="relative w-full max-w-[150px]">
                                                        <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                            <span className="capitalize">
                                                                {
                                                                    elementBreakpointSpecificStyleObject[
                                                                    propertyData
                                                                        .propertyName
                                                                    ]
                                                                }
                                                            </span>
                                                            <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                        </Listbox.Button>
                                                        <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                            {propertyData.possibleValues.map(
                                                                (
                                                                    value,
                                                                    index
                                                                ) => (
                                                                    <Listbox.Option
                                                                        key={`breakpointmap_${index}`}
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            `${active
                                                                                ? "bg-gray-100"
                                                                                : ""
                                                                            } cursor-pointer`
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {({
                                                                            selected,
                                                                        }) => (
                                                                            <>
                                                                                <div
                                                                                    className={`py-1.5 px-3 text-[#373C47] capitalize ${selected
                                                                                        ? " bg-indigo-100"
                                                                                        : ""
                                                                                        }`}
                                                                                >
                                                                                    {
                                                                                        value
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                )
                                                            )}
                                                        </Listbox.Options>
                                                    </div>
                                                </Listbox>
                                            </div>
                                        );
                                    })}
                                </>
                            )}

                        {(elementBreakpointSpecificStyleObject.display ==
                            "grid" ||
                            elementBreakpointSpecificStyleObject.display ==
                            "inline-grid" ||
                            elementBreakpointSpecificStyleObject.display ==
                            "inline-flex" ||
                            elementBreakpointSpecificStyleObject.display ==
                            "flex") && (
                                <>
                                    {[
                                        {
                                            displayName: "Justify Content",
                                            propertyName: "justifyContent",
                                            possibleValues: [
                                                "normal",
                                                "flex-start",
                                                "flex-end",
                                                "center",
                                                "space-between",
                                                "space-around",
                                                "space-evenly",
                                                "stretch",
                                            ],
                                        },
                                        {
                                            displayName: "Align Items",
                                            propertyName: "alignItems",
                                            possibleValues: [
                                                "flex-start",
                                                "flex-end",
                                                "center",
                                                "baseline",
                                                "stretch",
                                            ],
                                        },
                                        {
                                            displayName: "Align Content",
                                            propertyName: "alignContent",
                                            possibleValues: [
                                                "normal",
                                                "center",
                                                "flex-start",
                                                "flex-end",
                                                "space-between",
                                                "space-around",
                                                "space-evenly",
                                                "baseline",
                                                "stretch",
                                            ],
                                        },
                                    ].map((propertyData, index) => {
                                        return (
                                            <div
                                                className="flex items-center justify-between text-[#373C47] ml-3"
                                                key={index}
                                            >
                                                <div className="text-sm">
                                                    {
                                                        propertyData.displayName
                                                    }
                                                </div>
                                                <Listbox
                                                    value={
                                                        elementBreakpointSpecificStyleObject[
                                                        propertyData
                                                            .propertyName
                                                        ]
                                                    }
                                                    onChange={(value) => {
                                                        updateDesign(
                                                            propertyData.propertyName,
                                                            value
                                                        );
                                                    }}
                                                >
                                                    <div className="relative w-full max-w-[150px]">
                                                        <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                            <span className="capitalize">
                                                                {
                                                                    elementBreakpointSpecificStyleObject[
                                                                    propertyData
                                                                        .propertyName
                                                                    ]
                                                                }
                                                            </span>

                                                            <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                        </Listbox.Button>
                                                        <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                            {propertyData.possibleValues.map(
                                                                (
                                                                    value,
                                                                    index
                                                                ) => (
                                                                    <Listbox.Option
                                                                        key={`breakpointmap_${index}`}
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            `${active
                                                                                ? "bg-gray-100"
                                                                                : ""
                                                                            } cursor-pointer`
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {({
                                                                            selected,
                                                                        }) => (
                                                                            <>
                                                                                <div
                                                                                    className={`py-1.5 px-3 text-[#373C47] capitalize ${selected
                                                                                        ? " bg-indigo-100 font-medium"
                                                                                        : ""
                                                                                        }`}
                                                                                >
                                                                                    {
                                                                                        value
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                )
                                                            )}
                                                        </Listbox.Options>
                                                    </div>
                                                </Listbox>
                                            </div>
                                        );
                                    })}
                                    {[
                                        {
                                            displayName: "Column Gap",
                                            propertyName: "columnGap",
                                        },
                                        {
                                            displayName: "Row Gap",
                                            propertyName: "rowGap",
                                        },
                                    ].map((propertyData, index) => {
                                        return (
                                            <div
                                                className="flex items-center justify-between text-[#373C47] ml-3"
                                                key={index}
                                            >
                                                <div className="text-sm">
                                                    {
                                                        propertyData.displayName
                                                    }
                                                </div>
                                                <div className="flex items-center ml-auto">
                                                    <input
                                                        type="number"
                                                        className="w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 text-sm focus:ring-0  focus:border-[#D9D9D9] shadow text-xs"
                                                        value={parseInt(
                                                            elementBreakpointSpecificStyleObject[
                                                            propertyData
                                                                .propertyName
                                                            ]
                                                        )}
                                                        onChange={(e) => {
                                                            setElementBreakpointSpecificStyleObject(
                                                                (prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        [propertyData.propertyName]: `${e
                                                                            .target
                                                                            .value
                                                                            }${valueTypes[
                                                                                propertyData
                                                                                    .propertyName
                                                                            ]
                                                                                .unit
                                                                            }`,
                                                                    };
                                                                }
                                                            );
                                                        }}
                                                        onKeyUp={(e) => {
                                                            if (
                                                                e.key ==
                                                                "Enter"
                                                            ) {
                                                                updateDesign(
                                                                    propertyData.propertyName,
                                                                    `${e
                                                                        .target
                                                                        .value
                                                                    }${valueTypes[
                                                                        propertyData
                                                                            .propertyName
                                                                    ]
                                                                        .unit
                                                                    }`
                                                                );
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            updateDesign(
                                                                propertyData.propertyName,
                                                                `${e.target
                                                                    .value
                                                                }${valueTypes[
                                                                    propertyData
                                                                        .propertyName
                                                                ].unit
                                                                }`
                                                            );
                                                        }}
                                                    />
                                                    <Listbox
                                                        value={
                                                            valueTypes[
                                                                propertyData
                                                                    .propertyName
                                                            ].unit
                                                        }
                                                        onChange={(
                                                            value
                                                        ) => {
                                                            updateDesign(
                                                                propertyData.propertyName,
                                                                `${parseInt(
                                                                    elementBreakpointSpecificStyleObject[
                                                                    propertyData
                                                                        .propertyName
                                                                    ]
                                                                )}${value}`
                                                            );
                                                        }}
                                                    >
                                                        <div className="relative w-max">
                                                            <Listbox.Button className="bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs cursor-pointer rounded-r uppercase">
                                                                <span>
                                                                    {
                                                                        valueTypes[
                                                                            propertyData
                                                                                .propertyName
                                                                        ]
                                                                            .unit
                                                                    }
                                                                </span>

                                                                <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                            </Listbox.Button>
                                                            <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                                {[
                                                                    "px",
                                                                    "%",
                                                                    "vh",
                                                                    "vw",
                                                                ].map(
                                                                    (
                                                                        value,
                                                                        index
                                                                    ) => (
                                                                        <Listbox.Option
                                                                            key={`breakpointmap_${index}`}
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                `${active
                                                                                    ? "bg-gray-100"
                                                                                    : ""
                                                                                } cursor-pointer`
                                                                            }
                                                                            value={
                                                                                value
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                            }) => (
                                                                                <>
                                                                                    <div
                                                                                        className={`py-1.5 px-3 uppercase ${selected
                                                                                            ? " bg-indigo-100 font-medium"
                                                                                            : ""
                                                                                            }`}
                                                                                    >
                                                                                        {
                                                                                            value
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )
                                                                )}
                                                            </Listbox.Options>
                                                        </div>
                                                    </Listbox>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            )}

                        {(elementBreakpointSpecificStyleObject.display ==
                            "grid" ||
                            elementBreakpointSpecificStyleObject.display ==
                            "inline-grid") && (
                                <>
                                    {[
                                        {
                                            displayName: "Columns",
                                            propertyName:
                                                "gridTemplateColumns",
                                        },
                                        {
                                            displayName: "Rows",
                                            propertyName:
                                                "gridTemplateRows",
                                        },
                                    ].map((propertyData, index) => {
                                        return (
                                            <div
                                                className="flex items-center justify-between ml-3 text-[#373C47]"
                                                key={index}
                                            >
                                                <div className="text-sm">
                                                    {
                                                        propertyData.displayName
                                                    }
                                                </div>

                                                <div className="flex items-center w-max ml-auto">
                                                    {valueTypes[propertyData.propertyName].type == "custom" && (
                                                        <input
                                                            type="number"
                                                            className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 text-sm focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                                            min={1}
                                                            max={12}
                                                            value={parseInt(
                                                                elementBreakpointSpecificStyleObject[
                                                                propertyData
                                                                    .propertyName
                                                                ]
                                                            )}
                                                            onChange={(
                                                                e
                                                            ) => {
                                                                setElementBreakpointSpecificStyleObject(
                                                                    (
                                                                        prev
                                                                    ) => {
                                                                        return {
                                                                            ...prev,
                                                                            [propertyData.propertyName]:
                                                                                e.target.value,
                                                                        };
                                                                    }
                                                                );
                                                            }}
                                                            onKeyUp={(
                                                                e
                                                            ) => {
                                                                if (
                                                                    e.key ==
                                                                    "Enter"
                                                                ) {
                                                                    updateDesign(
                                                                        propertyData.propertyName,
                                                                        e.target.value
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                updateDesign(
                                                                    propertyData.propertyName,
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    <Listbox
                                                        value={
                                                            valueTypes[
                                                                propertyData
                                                                    .propertyName
                                                            ].type
                                                        }
                                                        onChange={(
                                                            value
                                                        ) => {
                                                            if (
                                                                value ==
                                                                "none"
                                                            ) {
                                                                setValueTypes(
                                                                    (
                                                                        prev
                                                                    ) => {
                                                                        return {
                                                                            ...prev,
                                                                            [propertyData.propertyName]:
                                                                            {
                                                                                type: "none",
                                                                            },
                                                                        };
                                                                    }
                                                                );
                                                                updateDesign(
                                                                    propertyData.propertyName,
                                                                    `none`
                                                                );
                                                            } else {
                                                                setValueTypes(
                                                                    (
                                                                        prev
                                                                    ) => {
                                                                        return {
                                                                            ...prev,
                                                                            [propertyData.propertyName]:
                                                                            {
                                                                                type: "custom",
                                                                            },
                                                                        };
                                                                    }
                                                                );
                                                                updateDesign(
                                                                    propertyData.propertyName,
                                                                    1
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div className="relative w-max">
                                                            <Listbox.Button className={`w-max cursor-pointer ${valueTypes[propertyData.propertyName].type == 'none' ? "rounded" : "rounded-l"} bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs`}>
                                                                <span>
                                                                    {
                                                                        valueTypes[propertyData.propertyName].type
                                                                    }
                                                                </span>

                                                                <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                            </Listbox.Button>
                                                            <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                                {[
                                                                    "none",
                                                                    "custom",
                                                                ].map(
                                                                    (
                                                                        value,
                                                                        index
                                                                    ) => (
                                                                        <Listbox.Option
                                                                            key={`breakpointmap_${index}`}
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                `${active
                                                                                    ? "bg-gray-100"
                                                                                    : ""
                                                                                } cursor-pointer`
                                                                            }
                                                                            value={
                                                                                value
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                            }) => (
                                                                                <>
                                                                                    <div
                                                                                        className={`py-1.5 px-3 text-[#373C47]${selected
                                                                                            ? " bg-indigo-100 font-medium"
                                                                                            : ""
                                                                                            }`}
                                                                                    >
                                                                                        {
                                                                                            value
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )
                                                                )}
                                                            </Listbox.Options>
                                                        </div>
                                                    </Listbox>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {[
                                        {
                                            displayName: "Grid Auto Flow",
                                            propertyName: "gridAutoFlow",
                                            possibleValues: [
                                                "row",
                                                "column",
                                                "dense",
                                                "row dense",
                                                "column dense",
                                            ],
                                        },
                                        {
                                            displayName: "Justify Items",
                                            propertyName: "justifyItems",
                                            possibleValues: [
                                                "stretch",
                                                "center",
                                                "start",
                                                "end",
                                            ],
                                        },
                                    ].map((propertyData, index) => {
                                        return (
                                            <div
                                                className="flex items-center justify-between ml-3"
                                                key={index}
                                            >
                                                <div className="text-sm">
                                                    {
                                                        propertyData.displayName
                                                    }
                                                </div>
                                                <Listbox
                                                    value={
                                                        elementBreakpointSpecificStyleObject[
                                                        propertyData
                                                            .propertyName
                                                        ]
                                                    }
                                                    onChange={(value) => {
                                                        updateDesign(
                                                            propertyData.propertyName,
                                                            value
                                                        );
                                                    }}
                                                >
                                                    <div className="relative w-full max-w-[150px]">
                                                        <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                            <span className="capitalize">
                                                                {
                                                                    elementBreakpointSpecificStyleObject[
                                                                    propertyData
                                                                        .propertyName
                                                                    ]
                                                                }
                                                            </span>

                                                            <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                        </Listbox.Button>
                                                        <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                            {propertyData.possibleValues.map(
                                                                (
                                                                    value,
                                                                    index
                                                                ) => (
                                                                    <Listbox.Option
                                                                        key={`breakpointmap_${index}`}
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            `${active
                                                                                ? "bg-gray-100"
                                                                                : ""
                                                                            } cursor-pointer`
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {({
                                                                            selected,
                                                                        }) => (
                                                                            <>
                                                                                <div
                                                                                    className={`py-1.5 px-3 text-[#373C47] capitalize ${selected
                                                                                        ? " bg-indigo-100 font-medium"
                                                                                        : ""
                                                                                        }`}
                                                                                >
                                                                                    {
                                                                                        value
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                )
                                                            )}
                                                        </Listbox.Options>
                                                    </div>
                                                </Listbox>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        {elementBreakpointSpecificStyleObject.flexChild ==
                            true && (
                                <>
                                    <div className="flex items-center justify-between text-[#373C47] ml-3">
                                        <div className="text-sm">
                                            Flex Grow
                                        </div>
                                        <input
                                            type="number"
                                            className="w-[60px] px-2 py-1 outline-none borer border-gray-300 focus:ring-0 focus:border-[#D9D9D9] shadow text-xs rounded"
                                            value={
                                                elementBreakpointSpecificStyleObject.flexGrow
                                            }
                                            onChange={(e) => {
                                                setElementBreakpointSpecificStyleObject(
                                                    (prev) => {
                                                        return {
                                                            ...prev,
                                                            flexGrow:
                                                                e.target
                                                                    .value,
                                                        };
                                                    }
                                                );
                                            }}
                                            onKeyUp={(e) => {
                                                if (e.key == "Enter") {
                                                    updateDesign(
                                                        "flexGrow",
                                                        e.target.value
                                                    );
                                                }
                                            }}
                                            onBlur={(e) => {
                                                updateDesign(
                                                    "flexGrow",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between text-[#373C47] ml-3">
                                        <div className="text-sm">
                                            Flex Shrink
                                        </div>
                                        <input
                                            type="number"
                                            className="w-[60px] px-2 py-1 outline-none borer border-gray-300 focus:ring-0 focus:border-[#D9D9D9] shadow text-xs rounded"
                                            value={
                                                elementBreakpointSpecificStyleObject.flexShrink
                                            }
                                            onChange={(e) => {
                                                setElementBreakpointSpecificStyleObject(
                                                    (prev) => {
                                                        return {
                                                            ...prev,
                                                            flexShrink:
                                                                e.target
                                                                    .value,
                                                        };
                                                    }
                                                );
                                            }}
                                            onKeyUp={(e) => {
                                                if (e.key == "Enter") {
                                                    updateDesign(
                                                        "flexShrink",
                                                        e.target.value
                                                    );
                                                }
                                            }}
                                            onBlur={(e) => {
                                                updateDesign(
                                                    "flexShrink",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center justify-between text-[#373C47] ml-3">
                                        <div className="text-sm">
                                            Flex Basis
                                        </div>

                                        <div className="flex items-center">

                                            {valueTypes.flexBasis.unit !== 'auto' && (
                                                <input
                                                    type="number"
                                                    className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 text-sm focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                                    value={parseInt(
                                                        elementBreakpointSpecificStyleObject.flexBasis
                                                    )}
                                                    onChange={(e) => {
                                                        setElementBreakpointSpecificStyleObject(
                                                            (prev) => {
                                                                return {
                                                                    ...prev,
                                                                    flexBasis: `${e.target.value}${valueTypes.flexBasis.unit}`,
                                                                };
                                                            }
                                                        );
                                                    }}
                                                    onKeyUp={(e) => {
                                                        if (
                                                            e.key == "Enter"
                                                        ) {
                                                            updateDesign(
                                                                "flexBasis",
                                                                `${e.target.value}${valueTypes.flexBasis.unit}`
                                                            );
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        updateDesign(
                                                            "flexBasis",
                                                            `${e.target.value}${valueTypes.flexBasis.unit}`
                                                        );
                                                    }}
                                                />
                                            )}
                                            <Listbox
                                                value={
                                                    valueTypes.flexBasis
                                                        .unit
                                                }
                                                onChange={(value) => {

                                                    if (value == 'auto') {
                                                        updateDesign('flexBasis', 'auto');
                                                    }
                                                    let prevValue = parseInt(elementBreakpointSpecificStyleObject.flexBasis)
                                                    updateDesign(
                                                        "flexBasis",
                                                        `${isNaN(prevValue) ? 0 : prevValue}${value}`
                                                    );
                                                }}
                                            >
                                                <div className="relative w-full">
                                                    <Listbox.Button className={`ml-auto w-max cursor-pointer ${valueTypes.flexBasis.unit == 'auto' ? "rounded capitalize" : "rounded-r uppercase"} bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs`}>
                                                        <span>
                                                            {
                                                                valueTypes.flexBasis.unit
                                                            }
                                                        </span>

                                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                    </Listbox.Button>
                                                    <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                        {[
                                                            "px",
                                                            "%",
                                                            "vh",
                                                            "vw",
                                                            "auto"
                                                        ].map(
                                                            (
                                                                value,
                                                                index
                                                            ) => (
                                                                <Listbox.Option
                                                                    key={`breakpointmap_${index}`}
                                                                    className={({
                                                                        active,
                                                                    }) =>
                                                                        `${active
                                                                            ? "bg-gray-100"
                                                                            : ""
                                                                        } cursor-pointer`
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                >
                                                                    {({
                                                                        selected,
                                                                    }) => (
                                                                        <>
                                                                            <div
                                                                                className={` ${value == 'auto' ? "capitalize" : "uppercase"} py-1.5 px-3 text-[#373C47]${selected
                                                                                    ? " bg-indigo-100 font-medium"
                                                                                    : ""
                                                                                    }`}
                                                                            >
                                                                                {
                                                                                    value
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            )
                                                        )}
                                                    </Listbox.Options>
                                                </div>
                                            </Listbox>
                                        </div>
                                    </div>
                                </>
                            )}
                        {(elementBreakpointSpecificStyleObject.flexChild ==
                            true ||
                            elementBreakpointSpecificStyleObject.gridChild ==
                            true) && (
                                <>
                                    <div className="flex items-center justify-between text-[#373C47] ml-3">
                                        <div className="text-sm">
                                            Align Self
                                        </div>
                                        <Listbox
                                            value={
                                                elementBreakpointSpecificStyleObject.alignSelf
                                            }
                                            onChange={(value) => {
                                                updateDesign(
                                                    "alignSelf",
                                                    value
                                                );
                                            }}
                                        >
                                            <div className="relative w-full max-w-[150px]">
                                                <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                    <span className="capitalize">
                                                        {
                                                            elementBreakpointSpecificStyleObject.alignSelf
                                                        }
                                                    </span>

                                                    <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                </Listbox.Button>
                                                <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                    {[
                                                        "auto",
                                                        "flex-start",
                                                        "flex-end",
                                                        "center",
                                                        "stretch",
                                                        "baseline",
                                                    ].map(
                                                        (value, index) => (
                                                            <Listbox.Option
                                                                key={`breakpointmap_${index}`}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    `${active
                                                                        ? "bg-gray-100"
                                                                        : ""
                                                                    } cursor-pointer`
                                                                }
                                                                value={
                                                                    value
                                                                }
                                                            >
                                                                {({
                                                                    selected,
                                                                }) => (
                                                                    <>
                                                                        <div
                                                                            className={`py-1.5 px-3 text-[#373C47] capitalize${selected
                                                                                ? " bg-indigo-100 font-medium"
                                                                                : ""
                                                                                }`}
                                                                        >
                                                                            {
                                                                                value
                                                                            }
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        )
                                                    )}
                                                </Listbox.Options>
                                            </div>
                                        </Listbox>
                                    </div>
                                </>
                            )}
                        {elementBreakpointSpecificStyleObject.gridChild ==
                            true && (
                                <>
                                    {[
                                        {
                                            displayName: "Column Span",
                                            propertyName: "gridColumn",
                                            propertyOptions: [
                                                "auto",
                                                "full",
                                                "custom",
                                            ],
                                        },
                                        {
                                            displayName: "Column Start",
                                            propertyName: "gridColumnStart",
                                            propertyOptions: [
                                                "auto",
                                                "custom",
                                            ],
                                        },
                                        {
                                            displayName: "Column End",
                                            propertyName: "gridColumnEnd",
                                            propertyOptions: [
                                                "auto",
                                                "custom",
                                            ],
                                        },
                                        {
                                            displayName: "Row Span",
                                            propertyName: "gridRow",
                                            propertyOptions: [
                                                "auto",
                                                "full",
                                                "custom",
                                            ],
                                        },
                                        {
                                            displayName: "Row Start",
                                            propertyName: "gridRowStart",
                                            propertyOptions: [
                                                "auto",
                                                "custom",
                                            ],
                                        },
                                        {
                                            displayName: "Row End",
                                            propertyName: "gridRowEnd",
                                            propertyOptions: [
                                                "auto",
                                                "custom",
                                            ],
                                        },
                                    ].map((propertyData, index) => {
                                        return (
                                            <div
                                                className="flex items-center justify-between text-[#373C47] ml-3"
                                                key={index}
                                            >
                                                <div className="text-sm">
                                                    {
                                                        propertyData.displayName
                                                    }
                                                </div>

                                                <div className="flex items-center">
                                                    {valueTypes[propertyData.propertyName].type == "custom" && (
                                                        <input
                                                            type="number"
                                                            className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 text-sm focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                                            value={parseInt(
                                                                elementBreakpointSpecificStyleObject[
                                                                propertyData
                                                                    .propertyName
                                                                ]
                                                            )}
                                                            onChange={(
                                                                e
                                                            ) => {
                                                                setElementBreakpointSpecificStyleObject(
                                                                    (
                                                                        prev
                                                                    ) => {
                                                                        return {
                                                                            ...prev,
                                                                            [propertyData.propertyName]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        };
                                                                    }
                                                                );
                                                            }}
                                                            onKeyUp={(
                                                                e
                                                            ) => {
                                                                if (
                                                                    e.key ==
                                                                    "Enter"
                                                                ) {
                                                                    updateDesign(
                                                                        propertyData.propertyName,
                                                                        e
                                                                            .target
                                                                            .value
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                updateDesign(
                                                                    propertyData.propertyName,
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    <Listbox
                                                        value={
                                                            valueTypes[
                                                                propertyData
                                                                    .propertyName
                                                            ].type
                                                        }
                                                        onChange={(
                                                            value
                                                        ) => {
                                                            if (
                                                                value ==
                                                                "auto"
                                                            ) {
                                                                setValueTypes(
                                                                    (
                                                                        prev
                                                                    ) => {
                                                                        return {
                                                                            ...prev,
                                                                            [propertyData.propertyName]:
                                                                            {
                                                                                type: "auto",
                                                                            },
                                                                        };
                                                                    }
                                                                );
                                                                updateDesign(
                                                                    propertyData.propertyName,
                                                                    `auto`
                                                                );
                                                            } else if (
                                                                value ==
                                                                "full"
                                                            ) {
                                                                setValueTypes(
                                                                    (
                                                                        prev
                                                                    ) => {
                                                                        return {
                                                                            ...prev,
                                                                            [propertyData.propertyName]:
                                                                            {
                                                                                type: "full",
                                                                            },
                                                                        };
                                                                    }
                                                                );
                                                                updateDesign(
                                                                    propertyData.propertyName,
                                                                    `full`
                                                                );
                                                            } else {
                                                                setValueTypes(
                                                                    (
                                                                        prev
                                                                    ) => {
                                                                        return {
                                                                            ...prev,
                                                                            [propertyData.propertyName]:
                                                                            {
                                                                                type: "custom",
                                                                            },
                                                                        };
                                                                    }
                                                                );
                                                                updateDesign(
                                                                    propertyData.propertyName,
                                                                    1
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div className="relative  w-max ml-auto text-xs">
                                                            <Listbox.Button className={`${valueTypes[propertyData.propertyName].type !== 'custom' ? 'ml-auto rounded' : 'rounded-r'} capitalize bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs cursor-pointer `}>
                                                                <span>
                                                                    {
                                                                        valueTypes[propertyData.propertyName].type
                                                                    }
                                                                </span>

                                                                <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                            </Listbox.Button>
                                                            <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                                {propertyData.propertyOptions.map(
                                                                    (
                                                                        value,
                                                                        index
                                                                    ) => (
                                                                        <Listbox.Option
                                                                            key={`breakpointmap_${index}`}
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                `${active
                                                                                    ? "bg-gray-100"
                                                                                    : ""
                                                                                } cursor-pointer`
                                                                            }
                                                                            value={
                                                                                value
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                            }) => (
                                                                                <>
                                                                                    <div
                                                                                        className={`capitalize py-1.5 px-3 ${selected
                                                                                            ? " bg-indigo-100 font-medium"
                                                                                            : ""
                                                                                            }`}
                                                                                    >
                                                                                        {
                                                                                            value
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )
                                                                )}
                                                            </Listbox.Options>
                                                        </div>
                                                    </Listbox>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className="flex items-center justify-between text-[#373C47] ml-3">
                                        <div className="text-sm ">
                                            Justify Self
                                        </div>
                                        <Listbox
                                            value={
                                                elementBreakpointSpecificStyleObject.justifySelf
                                            }
                                            onChange={(value) => {
                                                updateDesign(
                                                    "justifySelf",
                                                    value
                                                );
                                            }}
                                        >
                                            <div className="relative w-full max-w-[130px]">
                                                <Listbox.Button className=" ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                    <span className="capitalize">
                                                        {
                                                            elementBreakpointSpecificStyleObject.justifySelf
                                                        }
                                                    </span>

                                                    <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                </Listbox.Button>
                                                <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                    {[
                                                        "auto",
                                                        "start",
                                                        "end",
                                                        "center",
                                                        "stretch",
                                                    ].map(
                                                        (value, index) => (
                                                            <Listbox.Option
                                                                key={`breakpointmap_${index}`}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    `${active
                                                                        ? "bg-gray-100"
                                                                        : ""
                                                                    } cursor-pointer`
                                                                }
                                                                value={
                                                                    value
                                                                }
                                                            >
                                                                {({
                                                                    selected,
                                                                }) => (
                                                                    <>
                                                                        <div
                                                                            className={`py-1.5 px-3 text-[#373C47] capitalize${selected
                                                                                ? " bg-indigo-100 font-medium"
                                                                                : ""
                                                                                }`}
                                                                        >
                                                                            {
                                                                                value
                                                                            }
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        )
                                                    )}
                                                </Listbox.Options>
                                            </div>
                                        </Listbox>
                                    </div>
                                </>
                            )}
                    </div>
                    {/* End - Display */}

                    {/* Start - position */}

                    <div className="p-3 space-y-3 border-b">
                        <div className="flex items-center justify-between mb-2 text-[#373C47]">
                            <div className="text-base">
                                Position
                            </div>
                            <Listbox
                                value={
                                    elementBreakpointSpecificStyleObject.position
                                }
                                onChange={(value) => {
                                    updateDesign("position", value);
                                }}
                            >
                                <div className="relative w-full max-w-[130px]">
                                    <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                        <span className="capitalize">
                                            {
                                                elementBreakpointSpecificStyleObject.position
                                            }
                                        </span>

                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                    </Listbox.Button>
                                    <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                        {[
                                            "static",
                                            "fixed",
                                            "absolute",
                                            "relative",
                                            "sticky",
                                        ].map((value, index) => (
                                            <Listbox.Option
                                                key={`breakpointmap_${index}`}
                                                className={({
                                                    active,
                                                }) =>
                                                    `${active
                                                        ? "bg-gray-100"
                                                        : ""
                                                    } cursor-pointer`
                                                }
                                                value={value}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <div
                                                            className={`py-1.5 px-3 text-[#373C47] capitalize ${selected
                                                                ? " bg-indigo-100 font-medium"
                                                                : ""
                                                                }`}
                                                        >
                                                            {value}
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </div>
                            </Listbox>
                        </div>
                        <div className="grid grid-cols-2 gap-3 gap-y-2 w-[90%] ml-3 text-[#373C47]">

                            {AllPositions.map(
                                (property, index) => {
                                    return (
                                        <div
                                            className="flex items-center w-full"
                                            key={index}
                                        >

                                            <div className="flex items-center w-full text-[#373C47]">

                                                <span className={`mdi mdi-arrow-collapse-${property.symbol} text-xl mr-1`}></span>
                                                {
                                                    valueTypes[property.name].unit !== 'auto' && (
                                                        <input
                                                            type="number"
                                                            className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 text-sm focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                                            value={parseInt(
                                                                elementBreakpointSpecificStyleObject[
                                                                property.name
                                                                ]
                                                            )}
                                                            onChange={(
                                                                e
                                                            ) => {
                                                                setElementBreakpointSpecificStyleObject(
                                                                    (
                                                                        prev
                                                                    ) => {
                                                                        return {
                                                                            ...prev,
                                                                            [property.name]: `${e.target.value}${valueTypes[property.name].unit}`,
                                                                        };
                                                                    }
                                                                );
                                                            }}
                                                            onKeyUp={(
                                                                e
                                                            ) => {
                                                                if (
                                                                    e.key ==
                                                                    "Enter"
                                                                ) {
                                                                    updateDesign(
                                                                        property.name,
                                                                        `${e.target.value}${valueTypes[property.name].unit}`
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                updateDesign(
                                                                    property.name,
                                                                    `${e.target.value}${valueTypes[property.name].unit}`
                                                                );
                                                            }}
                                                        />
                                                    )
                                                }
                                                <Listbox
                                                    value={
                                                        valueTypes[
                                                            property.name
                                                        ].unit
                                                    }
                                                    onChange={(
                                                        value
                                                    ) => {
                                                        if (value === 'auto') {
                                                            updateDesign(
                                                                property.name,
                                                                `auto`
                                                            );
                                                        }
                                                        else {
                                                            let prevValue = parseInt(
                                                                elementBreakpointSpecificStyleObject[
                                                                property.name
                                                                ])
                                                            updateDesign(
                                                                property.name,
                                                                `${isNaN(prevValue) ? 0 : prevValue}${value}`
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <div className="relative w-max text-[#373C47]">
                                                        <Listbox.Button className={`${valueTypes[property.name].unit === 'auto' ? "rounded" : "rounded-r"} bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between w-max gap-1 text-xs cursor-pointer `}>
                                                            <span className={`${valueTypes[property.name].unit == 'auto' ? "capitalize" : "uppercase"}`}>
                                                                {
                                                                    valueTypes[property.name].unit
                                                                }
                                                            </span>

                                                            <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                        </Listbox.Button>
                                                        <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[100px] left-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                            {[
                                                                "px",
                                                                "%",
                                                                "vh",
                                                                "vw",
                                                                "auto"
                                                            ].map(
                                                                (
                                                                    value,
                                                                    index
                                                                ) => (
                                                                    <Listbox.Option
                                                                        key={`breakpointmap_${index}`}
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            `${active
                                                                                ? "bg-gray-100"
                                                                                : ""
                                                                            } cursor-pointer`
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {({
                                                                            selected,
                                                                        }) => (
                                                                            <>
                                                                                <div
                                                                                    className={`${value == 'auto' ? "capitalize" : "uppercase"} py-1.5 px-3 ${selected
                                                                                        ? " bg-indigo-100 font-medium"
                                                                                        : ""
                                                                                        }`}
                                                                                >
                                                                                    {
                                                                                        value
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                )
                                                            )}
                                                        </Listbox.Options>
                                                    </div>
                                                </Listbox>




                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>



                    </div>
                    {/* End - position */}

                    {/* Start - Z-index */}
                    <div className="p-3 space-y-3 border-b text-[#373C47]">
                        <div className="flex items-center justify-between gap-1">
                            <div className="text-[#373C47] text-base">
                                Z-Index
                            </div>

                            <div className="flex items-center justify-center space-x-1">
                                <input type='range' min={-10} max={100} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded appearance-none cursor-pointer range-sm'
                                    value={parseInt(
                                        elementBreakpointSpecificStyleObject.zIndex
                                    ) ? parseInt(
                                        elementBreakpointSpecificStyleObject.zIndex
                                    ) : 0}

                                    onChange={(e) => {
                                        setElementBreakpointSpecificStyleObject(
                                            (prev) => {
                                                return {
                                                    ...prev,
                                                    zIndex: `${e.target.value}`,
                                                };
                                            }
                                        );

                                        clearTimeout(timerRef.current);
                                        timerRef.current = setTimeout(
                                            () => {
                                                updateDesign(
                                                    "zIndex",
                                                    `${e.target.value}`
                                                );
                                            },
                                            1000
                                        );
                                        
                                    }} />
                                <input
                                    type="number"
                                    className="w-[50px] px-2 py-1 border border-gray-300 rounded text-sm focus:ring-0  focus:border-[#D9D9D9]"
                                    value={parseInt(
                                        elementBreakpointSpecificStyleObject.zIndex
                                    ) ? parseInt(
                                        elementBreakpointSpecificStyleObject.zIndex
                                    ) : 0}
                                    onChange={(e) => {
                                        updateDesign(
                                            "zIndex",
                                            `${e.target.value}`
                                        );
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key == "Enter") {
                                            updateDesign(
                                                "zIndex",
                                                `${e.target.value}`
                                            );
                                        }
                                    }}
                                    onBlur={(e) => {
                                        updateDesign(
                                            "zIndex",
                                            `${e.target.value}`
                                        );
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                    {/* End - Z-index */}

                    {/* Start - Overflow */}
                    <div className="p-3 space-y-3 border-b text-[#373C47]">
                        <div className="flex items-center justify-between gap-1">
                            <p className="text-[#373C47] text-base">Overflow</p>
                            <div className="flex items-center gap-2">
                                <p
                                    className={`text-sm px-1.5 py-[1px] rounded-md cursor-pointer ${valueTypes.overflow.type === 'common' ? "bg-[#D9D9D9]" : "bg-white"}`}
                                    onClick={() => {
                                        if (valueTypes['overflow'].type != 'common') {
                                            setElementAllBreakpointsStyleObject((prev) => {
                                                for (let i = 0; i < individualProperties.length; i++) {
                                                    delete prev[selectedBreakpoint.name][selectedState.key][individualProperties[i]];
                                                }
                                                return {
                                                    ...prev,
                                                    [selectedBreakpoint.name]: {
                                                        ...prev[selectedBreakpoint.name],
                                                        [selectedState.key]: {
                                                            ...prev[selectedBreakpoint.name][selectedState.key],
                                                            overflow: 'visible'
                                                        }
                                                    }
                                                };
                                            });
                                            let updatedClasses = classes;
                                            for (let i = 0; i < individualProperties.length; i++) {
                                                updatedClasses = removeDuplicateClasses(
                                                    updatedClasses?.split(" "),
                                                    individualProperties[i],
                                                    selectedBreakpoint.classPrefix,
                                                    selectedState.value
                                                );
                                            }
                                            updatedClasses = removeDuplicateClasses(
                                                updatedClasses?.split(" "),
                                                "overflow",
                                                selectedBreakpoint.classPrefix,
                                                selectedState.value
                                            );
                                            const newClasses = addNewStyleClass(
                                                updatedClasses,
                                                "overflow",
                                                'visible',
                                                selectedBreakpoint.classPrefix,
                                                selectedState.value,
                                                addImportant
                                            );
                                            if (
                                                formElementRef?.current &&
                                                formElementRef?.current.id !== elementId.id
                                            ) {
                                                elementId.data.css[elementRef.current?.dataset?.name] = newClasses;
                                            } else {
                                                elementId.css = newClasses;
                                            }
                                            setClasses(newClasses);
                                            AddToUndo(
                                                selectedSiteData.static,
                                                blocks,
                                                sqliteSchema,
                                                strapiSchema,
                                                undo,
                                                setUndo,
                                                setRedo
                                            );
                                        }
                                    }}
                                >All</p>
                                <p
                                    className={`text-sm px-1.5 py-[1px] rounded-md cursor-pointer ${valueTypes.overflow.type === 'individual' ? "bg-[#D9D9D9]" : "bg-white"}`}
                                    onClick={() => {

                                        if (valueTypes['overflow'].type != 'individual') {
                                            setValueTypes((prev) => {
                                                return {
                                                    ...prev,
                                                    ['overflow']: {
                                                        type: "individual"
                                                    }
                                                }
                                            });
                                        }
                                    }}
                                >Individual</p>
                            </div>
                        </div>
                        {
                            valueTypes.overflow.type == 'common' &&
                            <>
                                <div className="flex items-center gap-2 ml-3 w-full justify-between mb-2">
                                    <p className=''>Type</p>
                                    <Listbox
                                        value={
                                            elementBreakpointSpecificStyleObject['overflow']
                                        }
                                        onChange={(value) => {
                                            updateDesign(
                                                'overflow',
                                                value
                                            );
                                        }}
                                    >
                                        <div className="relative w-full max-w-[150px] mr-1">
                                            <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                <span className="capitalize">
                                                    {
                                                        elementBreakpointSpecificStyleObject['overflow']
                                                    }
                                                </span>
                                                <span className="mdi mdi-chevron-down text-gray-400"></span>
                                            </Listbox.Button>
                                            <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                {AllOverflowsValue.map(
                                                    (value, index) => (
                                                        <Listbox.Option
                                                            key={`breakpointmap_${index}`}
                                                            className={({
                                                                active,
                                                            }) =>
                                                                `${active
                                                                    ? "bg-gray-100"
                                                                    : ""
                                                                } cursor-pointer`
                                                            }
                                                            value={
                                                                value
                                                            }
                                                        >
                                                            {({
                                                                selected,
                                                            }) => (
                                                                <>
                                                                    <div
                                                                        className={`py-1.5 px-3 text-[#373C47] capitalize ${selected
                                                                            ? " bg-indigo-100 font-medium"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        {
                                                                            value
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                )}
                                            </Listbox.Options>
                                        </div>
                                    </Listbox>
                                </div>
                            </>
                        }
                        {
                            valueTypes.overflow.type == 'individual' &&
                            <>
                                <div className="flex items-center gap-2 ml-3 w-full justify-between mb-2">
                                    <p className=''>Horizontal Type</p>
                                    <Listbox
                                        value={
                                            elementBreakpointSpecificStyleObject.overflowX ? elementBreakpointSpecificStyleObject.overflowX : elementBreakpointSpecificStyleObject.overflow
                                        }
                                        onChange={(value) => {
                                            updateDesign(
                                                "overflowX",
                                                value
                                            );
                                        }}
                                    >
                                        <div className="relative w-full max-w-[150px] mr-1">
                                            <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                <span className="capitalize">
                                                    {
                                                        elementBreakpointSpecificStyleObject.overflowX ? elementBreakpointSpecificStyleObject.overflowX : elementBreakpointSpecificStyleObject.overflow
                                                    }
                                                </span>
                                                <span className="mdi mdi-chevron-down text-gray-400"></span>
                                            </Listbox.Button>
                                            <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                {AllOverflowsValue.map(
                                                    (value, index) => (
                                                        <Listbox.Option
                                                            key={`breakpointmap_${index}`}
                                                            className={({
                                                                active,
                                                            }) =>
                                                                `${active
                                                                    ? "bg-gray-100"
                                                                    : ""
                                                                } cursor-pointer`
                                                            }
                                                            value={
                                                                value
                                                            }
                                                        >
                                                            {({
                                                                selected,
                                                            }) => (
                                                                <>
                                                                    <div
                                                                        className={`py-1.5 px-3 text-[#373C47] capitalize ${selected
                                                                            ? " bg-indigo-100 font-medium"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        {
                                                                            value
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                )}
                                            </Listbox.Options>
                                        </div>
                                    </Listbox>
                                </div>

                                <div className="flex items-center gap-2 ml-3 w-full justify-between mb-2">
                                    <p className=''>Vertical Type</p>
                                    <Listbox
                                        value={
                                            elementBreakpointSpecificStyleObject.overflowY ? elementBreakpointSpecificStyleObject.overflowY : elementBreakpointSpecificStyleObject.overflow
                                        }
                                        onChange={(value) => {
                                            updateDesign(
                                                "overflowY",
                                                value
                                            );
                                        }}
                                    >
                                        <div className="relative w-full max-w-[150px] mr-1">
                                            <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                <span className="capitalize">
                                                    {
                                                        elementBreakpointSpecificStyleObject.overflowY ? elementBreakpointSpecificStyleObject.overflowY : elementBreakpointSpecificStyleObject.overflow
                                                    }
                                                </span>
                                                <span className="mdi mdi-chevron-down text-gray-400"></span>
                                            </Listbox.Button>
                                            <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                {AllOverflowsValue.map(
                                                    (value, index) => (
                                                        <Listbox.Option
                                                            key={`breakpointmap_${index}`}
                                                            className={({
                                                                active,
                                                            }) =>
                                                                `${active
                                                                    ? "bg-gray-100"
                                                                    : ""
                                                                } cursor-pointer`
                                                            }
                                                            value={
                                                                value
                                                            }
                                                        >
                                                            {({
                                                                selected,
                                                            }) => (
                                                                <>
                                                                    <div
                                                                        className={`py-1.5 px-3 text-[#373C47] capitalize${selected
                                                                            ? " bg-indigo-100 font-medium"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        {
                                                                            value
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                )}
                                            </Listbox.Options>
                                        </div>
                                    </Listbox>
                                </div>
                            </>
                        }
                        <div className="flex items-center gap-2 ml-3 w-full justify-between ">
                            <p>Wrap</p>
                            <Listbox
                                value={

                                    elementBreakpointSpecificStyleObject["overflowWrap"]
                                }
                                onChange={(value) => {
                                    updateDesign(
                                        "overflowWrap",
                                        value
                                    );
                                }}
                            >
                                <div className="relative w-full max-w-[150px] mr-1">
                                    <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                        <span className="capitalize">
                                            {
                                                elementBreakpointSpecificStyleObject["overflowWrap"]
                                            }
                                        </span>
                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                    </Listbox.Button>
                                    <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                        {AllOverflowWrapValue.map(
                                            (value, index) => (
                                                <Listbox.Option
                                                    key={`breakpointmap_${index}`}
                                                    className={({
                                                        active,
                                                    }) =>
                                                        `${active
                                                            ? "bg-gray-100"
                                                            : ""
                                                        } cursor-pointer`
                                                    }
                                                    value={
                                                        value
                                                    }
                                                >
                                                    {({
                                                        selected,
                                                    }) => (
                                                        <>
                                                            <div
                                                                className={`py-1.5 px-3 text-[#373C47] capitalize${selected
                                                                    ? " bg-indigo-100 font-medium"
                                                                    : ""
                                                                    }`}
                                                            >
                                                                {
                                                                    value
                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            )
                                        )}
                                    </Listbox.Options>
                                </div>
                            </Listbox>
                        </div>
                    </div>
                    {/* End - Overflow */}

                    {/* Start - Cursor */}
                    <div className="p-3 space-y-3 border-b text-[#373C47]">
                        <div className="flex items-center justify-between">
                            <div className="text-base">
                                Cursor
                            </div>
                            <Listbox
                                value={
                                    elementBreakpointSpecificStyleObject.cursor
                                }
                                onChange={(value) => {
                                    updateDesign("cursor", value);
                                }}
                            >
                                <div className="relative w-full max-w-[150px]">
                                    <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                        <span
                                            className={`cursor-${elementBreakpointSpecificStyleObject.cursor} capitalize`}
                                        >
                                            {
                                                elementBreakpointSpecificStyleObject.cursor
                                            }
                                        </span>
                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                    </Listbox.Button>
                                    <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                        {AllCursors.map((value, index) => (
                                            <Listbox.Option
                                                key={`breakpointmap_${index}`}
                                                className={({
                                                    active,
                                                }) =>
                                                    `${active
                                                        ? "bg-gray-100"
                                                        : ""
                                                    } cursor-${value.toLowerCase()}`
                                                }
                                                value={value}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <div
                                                            className={`py-1.5 px-3 capitalize ${selected
                                                                ? " bg-indigo-100 font-medium"
                                                                : ""
                                                                }`}
                                                        >
                                                            {value}
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </div>
                            </Listbox>
                        </div>
                    </div>
                    {/* End - Cursor */}

                    {/* Start - More Options */}
                    <div
                        className="bg-[#F3F4F6] border-y border-[#D9D9D9] flex items-center justify-center py-1 cursor-pointer"
                        onClick={() => { setToggleMoreOptions(!toggleMoreOptions) }}>
                        <p className=''>More options</p>
                        <span className={`mdi mdi-chevron-${toggleMoreOptions ? "up" : "down"} text-lg`}></span>

                    </div>
                    {/* End - More Options */}
                    {
                        toggleMoreOptions === true && (
                            <>

                                {/* Start - Aspect ratio */}

                                <div className="p-3 space-y-3 border-b">
                                    <div className="flex items-center justify-between text-[#373C47]">
                                        <div className="text-base text-[#373C47]">
                                            Aspect-Ratio
                                        </div>

                                        <div className="flex items-center gap-1">
                                            {
                                                currentAspect === 'custom' && (
                                                    <>
                                                        <input
                                                            type="number"
                                                            className={`w-[30px] outline-none rounded px-2 py-1 border border-gray-300 text-xs focus:ring-0  focus:border-[#D9D9D9] shadow`}
                                                            value={customAspect.height}
                                                            onChange={(
                                                                e
                                                            ) => {
                                                                setCustomAspect({
                                                                    ...customAspect,
                                                                    height: e.target.value
                                                                })
                                                                updateDesign(
                                                                    `aspectRatio`,
                                                                    `${e.target.value}/${customAspect.width}`
                                                                );
                                                            }}
                                                            onKeyUp={(
                                                                e
                                                            ) => {
                                                                if (
                                                                    e.key ==
                                                                    "Enter"
                                                                ) {
                                                                    updateDesign(
                                                                        `aspectRatio`,
                                                                        `${e.target.value}/${customAspect.width}`
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                updateDesign(
                                                                    `aspectRatio`,
                                                                    `${e.target.value}/${customAspect.width}`
                                                                );
                                                            }}
                                                        />
                                                        <span className='text-lg'>/</span>
                                                        <input
                                                            type="number"
                                                            className={`w-[30px] outline-none rounded px-2 py-1 border border-gray-300 text-xs focus:ring-0  focus:border-[#D9D9D9] shadow`}
                                                            value={customAspect.width}
                                                            onChange={(
                                                                e
                                                            ) => {
                                                                setCustomAspect({
                                                                    ...customAspect,
                                                                    width: e.target.value
                                                                })
                                                                updateDesign(
                                                                    `aspectRatio`,
                                                                    `${customAspect.height}/${e.target.value}`
                                                                );

                                                            }}
                                                            onKeyUp={(
                                                                e
                                                            ) => {
                                                                if (
                                                                    e.key ==
                                                                    "Enter"
                                                                ) {
                                                                    updateDesign(
                                                                        `aspectRatio`,
                                                                        `${customAspect.height}/${e.target.value}`
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                updateDesign(
                                                                    `aspectRatio`,
                                                                    `${customAspect.height}/${e.target.value}`
                                                                );
                                                            }}
                                                        />

                                                    </>

                                                )
                                            }

                                            <Listbox
                                                value={
                                                    elementBreakpointSpecificStyleObject.aspectRatio
                                                }
                                                onChange={(value) => {

                                                    updateDesign("aspectRatio", value.Value);
                                                }}
                                            >
                                                <div className="relative w-full max-w-[130px] text-[#373C47]">
                                                    <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                        <span className="capitalize">
                                                            {
                                                                currentAspect
                                                            }
                                                        </span>
                                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                    </Listbox.Button>
                                                    <Listbox.Options className="absolute z-[51] max-h-60 mt-1 right-2 overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                        {AllAspectratio.map((value, index) => (
                                                            <Listbox.Option
                                                                key={`breakpointmap_${index}`}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    `${active
                                                                        ? "bg-gray-100"
                                                                        : ""
                                                                    } cursor-pointer`
                                                                }
                                                                value={value}
                                                            >
                                                                {({ selected }) => (
                                                                    <>
                                                                        <div
                                                                            className={`py-1.5 px-3 capitalize ${selected
                                                                                ? " bg-indigo-100 font-medium"
                                                                                : ""
                                                                                }`}
                                                                        >
                                                                            {value.name}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </div>
                                            </Listbox>
                                        </div>
                                    </div>
                                </div>

                                {/* End - Aspect ratio */}

                                {/* Start - Object-fit */}
                                <div className="p-3 space-y-3 border-b text-[#373C47]">
                                    <div className="flex items-center justify-between">
                                        <div className="text-base text-[#373C47]">
                                            Object-Fit
                                        </div>
                                        <Listbox
                                            value={
                                                elementBreakpointSpecificStyleObject.objectFit
                                            }
                                            onChange={(value) => {
                                                updateDesign("objectFit", value);
                                            }}
                                        >
                                            <div className="relative w-full max-w-[130px]">
                                                <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                    <span className="capitalize">
                                                        {
                                                            elementBreakpointSpecificStyleObject.objectFit
                                                        }
                                                    </span>
                                                    <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                </Listbox.Button>
                                                <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                    {[
                                                        "none",
                                                        "contain",
                                                        "cover",
                                                        "fill",
                                                        "scale-down",
                                                    ].map((value, index) => (
                                                        <Listbox.Option
                                                            key={`breakpointmap_${index}`}
                                                            className={({
                                                                active,
                                                            }) =>
                                                                `${active
                                                                    ? "bg-gray-100"
                                                                    : ""
                                                                } cursor-pointer`
                                                            }
                                                            value={value}
                                                        >
                                                            {({ selected }) => (
                                                                <>
                                                                    <div
                                                                        className={`py-1.5 px-3 text-[#373C47] capitalize ${selected
                                                                            ? " bg-indigo-100 font-medium"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        {value}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </div>
                                        </Listbox>
                                    </div>
                                </div>
                                {/* End - Object-fit */}

                                {/* Start - Object-position */}
                                <div className="p-3 space-y-3 border-b">
                                    <div className="flex items-center gap-1 justify-between">
                                        <p className='text-base text-[#373C47]'>Object-position</p>
                                        <div className="flex items-center gap-2">
                                            <p
                                                className={`text-sm px-1.5 py-[1px] rounded-md cursor-pointer ${valueTypes.objectPosition.type === 'normal' ? "bg-[#D9D9D9]" : "bg-white"}`}
                                                onClick={() => {
                                                    if (valueTypes['objectPosition'].type != 'normal') {
                                                        setValueTypes((prev) => {
                                                            return {
                                                                ...prev,
                                                                ['objectPosition']: {
                                                                    type: "normal"
                                                                }
                                                            }
                                                        });
                                                    }
                                                }}
                                            >Normal</p>
                                            <p
                                                className={`text-sm px-1.5 py-[1px] rounded-md cursor-pointer ${valueTypes.objectPosition.type === 'custom' ? "bg-[#D9D9D9]" : "bg-white"}`}
                                                onClick={() => {
                                                    if (valueTypes['objectPosition'].type != 'custom') {
                                                        setValueTypes((prev) => {
                                                            return {
                                                                ...prev,
                                                                ['objectPosition']: {
                                                                    type: "custom"
                                                                }
                                                            }
                                                        });
                                                    }
                                                }}
                                            >Custom</p>
                                        </div>
                                    </div>
                                    {
                                        valueTypes.objectPosition.type === 'normal' && (
                                            <>
                                                <div className="grid grid-cols-3 gap-1 gap-y-1 w-[130px] ml-auto">
                                                    <div
                                                        className={`overlfow-hidden border-2 ${elementBreakpointSpecificStyleObject.objectPosition == 'left-top' ? "border-4 border-[#D9D9D9]" : "border"} hover:border-[#D9D9D9] w-full cursor-pointer`}
                                                        onClick={() => { handleSelectObjectPosition("left-top") }}>

                                                        <img src="/topleft.png" alt="topleft" className="object-cover w-full h-full " />
                                                    </div>
                                                    <div
                                                        className={`overlfow-hidden ${elementBreakpointSpecificStyleObject.objectPosition == 'top' ? "border-4 border-[#D9D9D9]" : "border"} hover:border-[#D9D9D9] w-full cursor-pointer`}
                                                        onClick={() => { handleSelectObjectPosition("top") }}>
                                                        <img src="/topcenter.png" alt="topcenter" className="object-cover w-full h-full" />
                                                    </div>
                                                    <div
                                                        className={`overlfow-hidden ${elementBreakpointSpecificStyleObject.objectPosition == 'right-top' ? "border-4 border-[#D9D9D9]" : "border"} hover:border-[#D9D9D9] w-full cursor-pointer`}
                                                        onClick={() => { handleSelectObjectPosition("right-top") }}>
                                                        <img src="/topright.png" alt="topright" className="object-cover w-full h-full" />
                                                    </div>
                                                    <div
                                                        className={`overlfow-hidden ${elementBreakpointSpecificStyleObject.objectPosition == 'left' ? "border-4 border-[#D9D9D9]" : "border"} hover:border-[#D9D9D9] w-full cursor-pointer`}
                                                        onClick={() => { handleSelectObjectPosition("left") }}>
                                                        <img src="/centerleft.png" alt="centerleft" className="object-cover w-full h-full" />
                                                    </div>
                                                    <div
                                                        className={`overlfow-hidden ${elementBreakpointSpecificStyleObject.objectPosition == 'center' ? "border-4 border-[#D9D9D9]" : "border"} hover:border-[#D9D9D9] w-full cursor-pointer`}
                                                        onClick={() => { handleSelectObjectPosition("center") }} >
                                                        <img src="/center.png" alt="center" className="object-cover w-full h-full" />
                                                    </div>
                                                    <div
                                                        className={`overlfow-hidden ${elementBreakpointSpecificStyleObject.objectPosition == 'right' ? "border-4 border-[#D9D9D9]" : "border"} hover:border-[#D9D9D9] w-full cursor-pointer`}
                                                        onClick={() => { handleSelectObjectPosition("right") }} >
                                                        <img src="/centerright.png" alt="centerright" className="object-cover w-full h-full" />
                                                    </div>
                                                    <div

                                                        className={`overlfow-hidden  ${elementBreakpointSpecificStyleObject.objectPosition == 'left-bottom' ? "border-4 border-[#D9D9D9]" : "border"} hover:border-[#D9D9D9] w-full cursor-pointer`}
                                                        onClick={() => { handleSelectObjectPosition("left-bottom") }}>
                                                        <img src="/bottomleft.png" alt="bottomleft" className="object-cover w-full h-full" />
                                                    </div>
                                                    <div
                                                        className={`overlfow-hidden  ${elementBreakpointSpecificStyleObject.objectPosition == 'bottom' ? "border-4 border-[#D9D9D9]" : "border"} hover:border-[#D9D9D9] w-full cursor-pointer`}
                                                        onClick={() => { handleSelectObjectPosition("bottom") }}>
                                                        <img src="/bottomcenter.png" alt="bottomcenter" className="object-cover w-full h-full" />
                                                    </div>
                                                    <div
                                                        className={`overlfow-hidden ${elementBreakpointSpecificStyleObject.objectPosition == 'right-bottom' ? "border-4 border-[#D9D9D9]" : "border"} hover:border-[#D9D9D9] w-full cursor-pointer`}
                                                        onClick={() => { handleSelectObjectPosition("right-bottom") }} >
                                                        <img src="/bottomright.png" alt="bottomright" className="object-cover w-full h-full" />
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    }
                                    {
                                        valueTypes.objectPosition.type === 'custom' && (
                                            <>
                                                <div className="ml-3 flex items-center gap-3 text-[#373C47]">
                                                    <div className="flex items-center gap-1">
                                                        <p>Left</p>
                                                        <div className="flex items-center">
                                                            <input
                                                                type="number"
                                                                className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 text-sm focus:ring-0 focus:border-[#D9D9D9] shadow text-xs`}
                                                                value={parseInt(elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[0]?.replace(/[!@#$%^&*a-z-]/g, "") || 0)}
                                                                onChange={(
                                                                    e
                                                                ) => {

                                                                    updateDesign(
                                                                        'objectPosition',
                                                                        `${e.target.value}${valueTypes.objectPosition.unit}_${elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[1]?.replace(/[!@#$%^&*a-z-]/g, "") || 0}${valueTypes.objectPosition.unit}`
                                                                    );

                                                                }}
                                                                onKeyUp={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.key ==
                                                                        "Enter"
                                                                    ) {
                                                                        updateDesign(
                                                                            'objectPosition',
                                                                            `${e.target.value}${valueTypes.objectPosition.unit}_${elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[1]?.replace(/[!@#$%^&*a-z-]/g, "") || 0}${valueTypes.objectPosition.unit}`
                                                                        );
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    updateDesign(
                                                                        'objectPosition',
                                                                        `${e.target.value}${valueTypes.objectPosition.unit}_${elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[1]?.replace(/[!@#$%^&*a-z-]/g, "") || 0}${valueTypes.objectPosition.unit}`
                                                                    );
                                                                }}
                                                            />
                                                            <Listbox
                                                                value={valueTypes.objectPosition.unit ? valueTypes.objectPosition.unit : 'px'}
                                                                onChange={(
                                                                    value
                                                                ) => {

                                                                    updateDesign(
                                                                        'objectPosition',
                                                                        `${elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[0]?.replace(/[!@#$%^&*a-z-]/g, "") || 0}${valueTypes.objectPosition.unit}_${elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[1]?.replace(/[!@#$%^&*a-z-]/g, "") || 0}${valueTypes.objectPosition.unit}`
                                                                    );
                                                                }}
                                                            >
                                                                <div className="relative w-max">
                                                                    <Listbox.Button className={`bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs cursor-pointer rounded-r`}>
                                                                        <span className='uppercase'>
                                                                            {
                                                                                valueTypes.objectPosition.unit ? valueTypes.objectPosition.unit : 'px'
                                                                            }
                                                                        </span>

                                                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                                    </Listbox.Button>
                                                                    <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                                        {[
                                                                            "px",
                                                                            "%",
                                                                            "vh",
                                                                            "vw",
                                                                        ].map(
                                                                            (
                                                                                value,
                                                                                index
                                                                            ) => (
                                                                                <Listbox.Option
                                                                                    key={`breakpointmap_${index}`}
                                                                                    className={({
                                                                                        active,
                                                                                    }) =>
                                                                                        `${active
                                                                                            ? "bg-gray-100"
                                                                                            : ""
                                                                                        } cursor-pointer`
                                                                                    }
                                                                                    value={
                                                                                        value
                                                                                    }
                                                                                >
                                                                                    {({
                                                                                        selected,
                                                                                    }) => (
                                                                                        <>
                                                                                            <div
                                                                                                className={`py-1.5 px-3 uppercase ${selected
                                                                                                    ? " bg-indigo-100 font-medium"
                                                                                                    : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {
                                                                                                    value
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </Listbox.Option>
                                                                            )
                                                                        )}
                                                                    </Listbox.Options>
                                                                </div>
                                                            </Listbox>

                                                        </div>
                                                    </div>

                                                    <div className="flex items-center gap-1 text-[#373C47]">
                                                        <p>Top</p>
                                                        <div className="flex items-center">
                                                            <input
                                                                type="number"
                                                                className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 text-sm focus:ring-0 focus:border-[#D9D9D9] shadow text-xs`}
                                                                value={parseInt(elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[1]?.replace(/[!@#$%^&*a-z-]/g, "") || 0)}
                                                                onChange={(
                                                                    e
                                                                ) => {

                                                                    updateDesign(
                                                                        'objectPosition',
                                                                        `${elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[0]?.replace(/[!@#$%^&*a-z-]/g, "") || 0}${valueTypes.objectPosition.unit}_${e.target.value}${valueTypes.objectPosition.unit}`
                                                                    );

                                                                }}
                                                                onKeyUp={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.key ==
                                                                        "Enter"
                                                                    ) {
                                                                        updateDesign(
                                                                            'objectPosition',
                                                                            `${elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[0]?.replace(/[!@#$%^&*a-z-]/g, "") || 0}${valueTypes.objectPosition.unit}_${e.target.value}${valueTypes.objectPosition.unit}`
                                                                        );
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    updateDesign(
                                                                        'objectPosition',
                                                                        `${elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[0]?.replace(/[!@#$%^&*a-z-]/g, "") || 0}${valueTypes.objectPosition.unit}_${e.target.value}${valueTypes.objectPosition.unit}`
                                                                    );
                                                                }}
                                                            />
                                                            <Listbox
                                                                value={valueTypes.objectPosition.unit ? valueTypes.objectPosition.unit : 'px'}
                                                                onChange={(
                                                                    value
                                                                ) => {

                                                                    updateDesign(
                                                                        'objectPosition',
                                                                        `${elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[0]?.replace(/[!@#$%^&*a-z-]/g, "") || 0}${valueTypes.objectPosition.unit}_${elementBreakpointSpecificStyleObject?.objectPosition?.split('_')[1]?.replace(/[!@#$%^&*a-z-]/g, "") || 0}${valueTypes.objectPosition.unit}`
                                                                    );
                                                                }}
                                                            >
                                                                <div className="relative w-max">
                                                                    <Listbox.Button className={`bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs cursor-pointer rounded-r`}>
                                                                        <span className='text-[#373C47] uppercase'>
                                                                            {
                                                                                valueTypes.objectPosition.unit ? valueTypes.objectPosition.unit : 'px'
                                                                            }
                                                                        </span>

                                                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                                    </Listbox.Button>
                                                                    <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                                        {[
                                                                            "px",
                                                                            "%",
                                                                            "vh",
                                                                            "vw",
                                                                        ].map(
                                                                            (
                                                                                value,
                                                                                index
                                                                            ) => (
                                                                                <Listbox.Option
                                                                                    key={`breakpointmap_${index}`}
                                                                                    className={({
                                                                                        active,
                                                                                    }) =>
                                                                                        `${active
                                                                                            ? "bg-gray-100"
                                                                                            : ""
                                                                                        } cursor-pointer`
                                                                                    }
                                                                                    value={
                                                                                        value
                                                                                    }
                                                                                >
                                                                                    {({
                                                                                        selected,
                                                                                    }) => (
                                                                                        <>
                                                                                            <div
                                                                                                className={`py-1.5 px-3 uppercase ${selected
                                                                                                    ? " bg-indigo-100 font-medium"
                                                                                                    : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {
                                                                                                    value
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </Listbox.Option>
                                                                            )
                                                                        )}
                                                                    </Listbox.Options>
                                                                </div>
                                                            </Listbox>

                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                {/* End - Object-position */}

                            </>
                        )
                    }

                </div>
            )}
        </div>
    )
}

export default LayoutSection