// import React, { useEffect, useState } from "react";
// import {
// 	BagOutline,
// 	ChevronForwardOutline,
// 	HeartOutline,
// } from "react-ionicons";
// import { useNavigate, useParams } from "react-router-dom";

// const Product = ({ block }) => {
// 	const [data, setData] = useState([])
// 	const navigate = useNavigate()
// 	const params = useParams()
// 	const fetchData = async () => {
// 		console.log(block, "THis is Block")
// 		if (block?.data?.url) {
// 			if (block?.data?.collection == "products") {
// 				const fetchValue = await fetch(`${block?.data?.url}/""`, {
// 					method: 'GET',
// 					headers: {
// 						"Content-Type": "application/json",
// 					},
// 				})

// 				const response = await fetchValue.json();
// 				console.log(response, "responseData")
// 				setData(response)
// 			} else {
// 				const fetchValue = await fetch(`${block?.data?.url}/${params.siteId}`, {
// 					method: 'GET',
// 					headers: {
// 						"Content-Type": "application/json",
// 					},
// 				})

// 				const response = await fetchValue.json();
// 				console.log(response, "responseData")
// 				setData(response)

// 			}
// 		} else {
// 			// setData(block?.data?.data)
// 		}
// 	}
// 	useEffect(() => {
// 		fetchData();
// 	}, [params.siteId])
// 	return (
// 		<section>
// 			{/* <div className="@xl:ml-36 relative flex flex-wrap justify-center "> */}
// 			<div className={`${block?.css}`}
// 				data-name="component">
// 				{data?.map((product, index) => (
// 					<div key={index} className=" mx-1 p-2 flex flex-col w-full max-w-[250px] hover:shadow-yellow-100 hover:shadow-lg  hover:border"
// 					// onClick={() => {
// 					//     navigate(`/product/${block?.data?.collection}_${product?.id}`)
// 					// }}
// 					>
// 						<div className="relative">
// 							<img src={product?.image} className=" min-h-fit  justify-center " />
// 							{product?.cart && (
// 								<>
// 									<div className=" absolute right-[3%] top-[3%]">
// 										<HeartOutline />
// 									</div>
// 									<div className="absolute bottom-[5%] right-[4%] bg-white p-2 px-3 rounded-full">
// 										<a className=" bg-white" href="#">
// 											{" "}
// 											<span className="flex gap-2 text-black items-center justify-center">
// 												<BagOutline /> ADD
// 											</span>
// 										</a>
// 									</div>
// 								</>
// 							)}
// 						</div>
// 						<p className="text-sm">{product?.type}</p>
// 						<p className="text-lg font-normal">{product?.name}</p>
// 						<div className="flex items-center   relative ">
// 							{product?.colors?.map((color, index) => {
// 								return (
// 									<div
// 										key={index}
// 										style={{ backgroundColor: color }}
// 										className={`h-3  mx-[1px] w-3 bg-[${color}] border border-slate-400`}
// 									></div>
// 								);
// 							})}
// 							{product?.size?.map((item, index) => {
// 								return <p className="mx-2  text-xs text-slate-500">{product?.item}</p>;
// 							})}
// 							<div className=" flex items-center absolute right-0">
// 								{product?.wasPrice && (
// 									<p className="pb-2 text-slate-500 italic">
// 										<strike>{product?.wasPrice}</strike>
// 									</p>
// 								)}
// 								&nbsp;
// 								<p className="text-lg ">{product?.price}</p>
// 							</div>
// 						</div>
// 					</div>
// 				))}
// 			</div>
// 		</section>
// 	);
// };
// export default Product;
