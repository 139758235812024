import { createElement } from "react";
import { useContext } from "react";
import { context } from "../../../../context/Context";
import { useLocation, useParams } from "react-router-dom";
import { generateCss } from "../functions/sortableFunctions";
const GoogleMap = ({ block, ...props }) => {
	const { setComponent, componentWithSettingsRef } = useContext(context);
	const handleComponentWithSettingsClick = () => {
		const component = document.getElementById(block.id);
		if (componentWithSettingsRef.current != component) {
			setComponent(block);
			if (componentWithSettingsRef.current) {
				componentWithSettingsRef.current.style.outline = 'none';
			}
			componentWithSettingsRef.current = component;
			componentWithSettingsRef.current.style.outline = '2px dashed #4F46E5';
		}
	}
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (
		<div id={block.id} className={`p-2 ${generateCss({
			contextVariables: contextVariables,
			props: {
				...props,
				block: block
			},
			params: params,
			location: location
		})}`}
			onClick={(e) => {
				props.handleClick(e, block.id, false, -1, true, block.id);
				handleComponentWithSettingsClick();
			}}
			onMouseOver={(e) => {
				props.handleMouseOver(e);
			}}
		>
			{

				createElement(
					'iframe',
					{
						className: 'w-full h-full',
						...block.data
					},
					null
				)
			}
		</div>
	)
}
export default GoogleMap;