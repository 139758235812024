import { BlockWrapper } from '../Sortable';
import React from 'react';
import { useContext } from 'react';
import { context } from '../../../../context/Context';
import { useLocation, useParams } from 'react-router-dom';
const LogicalAnd = ({ block, ...props }) => {
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (
		eval(block?.data?.condition) &&
		block?.children.map((value, index) => {

			return <BlockWrapper {...props} block={value} key={index} blockIndex={[...props.blockIndex, index]} />
		})
	)
}
export default LogicalAnd; 