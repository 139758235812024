import React, { useState, useEffect } from 'react';
import { BlockWrapper } from '../Sortable';
function Loop({ block, ...props }) {
	const [allData, setAllData] = useState([]);
	useEffect(() => {
		if (block?.data?.type == 'dynamic' || block?.data?.type == 'static') {
			setAllData(block.data.staticData);
		}
	}, []);
	useEffect(() => {
		if (block?.data?.type == 'props') {
			eval(block?.data?.allData);
		}
	}, [props.fileFieldValues, props.singleData, props.allData])
	return (
		<>
			{
				block.children.length == 2 ?
					(
						allData.slice(0, block?.data?.numberOfItemsToDisplay).length == 0 ?
							<BlockWrapper {...props} block={block.children[0]} />
							:
							allData?.slice(0, block?.data?.numberOfItemsToDisplay).map((singleData, index) => {
								if (block?.data?.filter ? eval(block?.data?.filter) : true) {

									return (
										<BlockWrapper {...props} block={block.children[1]} key={index} index={index} singleData={singleData} allData={allData} setAllData={setAllData} />
									)
								}
							})
					)
					:
					allData?.slice(0, block?.data?.numberOfItemsToDisplay).map((singleData, index) => {
						if (block?.data?.filter ? eval(block?.data?.filter) : true) {

							return (
								<BlockWrapper {...props} block={block.children[0]} key={index} index={index} singleData={singleData} allData={allData} setAllData={setAllData} />
							)
						}
					})
			}
		</>

	);
}

export default Loop;