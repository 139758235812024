import { BlockWrapper } from '../Sortable';
const FieldError = ({ block, ...props }) => {

	return (
		(block?.data?.showError && props?.formik?.touched?.[block?.data?.field] && props?.formik?.errors?.[block?.data?.field]) &&
		block?.children.map((value, index) => {

			return <BlockWrapper {...props} block={value} key={index} blockIndex={[...props.blockIndex, index]} />
		})
	)
}
export default FieldError; 