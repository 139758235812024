const colors = {
	'slate-50': '#f8fafc',
	'slate-100': '#f1f5f9',
	'slate-200': '#e2e8f0',
	'slate-300': '#cbd5e1',
	'slate-400': '#94a3b8',
	'slate-500': '#64748b',
	'slate-600': '#475569',
	'slate-700': '#334155',
	'slate-800': '#1e293b',
	'slate-900': '#0f172a',
	'slate-950': '#020617',
	'gray-50': '#f9fafb',
	'gray-100': '#f3f4f6',
	'gray-200': '#e5e7eb',
	'gray-300': '#d1d5db',
	'gray-400': '#9ca3af',
	'gray-500': '#6b7280',
	'gray-600': '#4b5563',
	'gray-700': '#374151',
	'gray-800': '#1f2937',
	'gray-900': '#111827',
	'gray-950': '#030712',
	'zinc-50': '#fafafa',
	'zinc-100': '#f4f4f5',
	'zinc-200': '#e4e4e7',
	'zinc-300': '#d4d4d8',
	'zinc-400': '#a1a1aa',
	'zinc-500': '#71717a',
	'zinc-600': '#52525b',
	'zinc-700': '#3f3f46',
	'zinc-800': '#27272a',
	'zinc-900': '#18181b',
	'zinc-950': '#09090b',
	'neutral-50': '#fafafa',
	'neutral-100': '#f5f5f5',
	'neutral-200': '#e5e5e5',
	'neutral-300': '#d4d4d4',
	'neutral-400': '#a3a3a3',
	'neutral-500': '#737373',
	'neutral-600': '#525252',
	'neutral-700': '#404040',
	'neutral-800': '#262626',
	'neutral-900': '#171717',
	'neutral-950': '#0a0a0a',
	'stone-50': '#fafaf9',
	'stone-100': '#f5f5f4',
	'stone-200': '#e7e5e4',
	'stone-300': '#d6d3d1',
	'stone-400': '#a8a29e',
	'stone-500': '#78716c',
	'stone-600': '#57534e',
	'stone-700': '#44403c',
	'stone-800': '#292524',
	'stone-900': '#1c1917',
	'stone-950': '#0c0a09',
	'red-50': '#fef2f2',
	'red-100': '#fee2e2',
	'red-200': '#fecaca',
	'red-300': '#fca5a5',
	'red-400': '#f87171',
	'red-500': '#ef4444',
	'red-600': '#dc2626',
	'red-700': '#b91c1c',
	'red-800': '#991b1b',
	'red-900': '#7f1d1d',
	'red-950': '#450a0a',
	'orange-50': '#fff7ed',
	'orange-100': '#ffedd5',
	'orange-200': '#fed7aa',
	'orange-300': '#fdba74',
	'orange-400': '#fb923c',
	'orange-500': '#f97316',
	'orange-600': '#ea580c',
	'orange-700': '#c2410c',
	'orange-800': '#9a3412',
	'orange-900': '#7c2d12',
	'orange-950': '#431407',
	'amber-50': '#fffbeb',
	'amber-100': '#fef3c7',
	'amber-200': '#fde68a',
	'amber-300': '#fcd34d',
	'amber-400': '#fbbf24',
	'amber-500': '#f59e0b',
	'amber-600': '#d97706',
	'amber-700': '#b45309',
	'amber-800': '#92400e',
	'amber-900': '#78350f',
	'amber-950': '#451a03',
	'yellow-50': '#fefce8',
	'yellow-100': '#fef9c3',
	'yellow-200': '#fef08a',
	'yellow-300': '#fde047',
	'yellow-400': '#facc15',
	'yellow-500': '#eab308',
	'yellow-600': '#ca8a04',
	'yellow-700': '#a16207',
	'yellow-800': '#854d0e',
	'yellow-900': '#713f12',
	'yellow-950': '#422006',
	'lime-50': '#f7fee7',
	'lime-100': '#ecfccb',
	'lime-200': '#d9f99d',
	'lime-300': '#bef264',
	'lime-400': '#a3e635',
	'lime-500': '#84cc16',
	'lime-600': '#65a30d',
	'lime-700': '#4d7c0f',
	'lime-800': '#3f6212',
	'lime-900': '#365314',
	'lime-950': '#1a2e05',
	'green-50': '#f0fdf4',
	'green-100': '#dcfce7',
	'green-200': '#bbf7d0',
	'green-300': '#86efac',
	'green-400': '#4ade80',
	'green-500': '#22c55e',
	'green-600': '#16a34a',
	'green-700': '#15803d',
	'green-800': '#166534',
	'green-900': '#14532d',
	'green-950': '#052e16',
	'emerald-50': '#ecfdf5',
	'emerald-100': '#d1fae5',
	'emerald-200': '#a7f3d0',
	'emerald-300': '#6ee7b7',
	'emerald-400': '#34d399',
	'emerald-500': '#10b981',
	'emerald-600': '#059669',
	'emerald-700': '#047857',
	'emerald-800': '#065f46',
	'emerald-900': '#064e3b',
	'emerald-950': '#022c22',
	'teal-50': '#f0fdfa',
	'teal-100': '#ccfbf1',
	'teal-200': '#99f6e4',
	'teal-300': '#5eead4',
	'teal-400': '#2dd4bf',
	'teal-500': '#14b8a6',
	'teal-600': '#0d9488',
	'teal-700': '#0f766e',
	'teal-800': '#115e59',
	'teal-900': '#134e4a',
	'teal-950': '#042f2e',
	'cyan-50': '#ecfeff',
	'cyan-100': '#cffafe',
	'cyan-200': '#a5f3fc',
	'cyan-300': '#67e8f9',
	'cyan-400': '#22d3ee',
	'cyan-500': '#06b6d4',
	'cyan-600': '#0891b2',
	'cyan-700': '#0e7490',
	'cyan-800': '#155e75',
	'cyan-900': '#164e63',
	'cyan-950': '#083344',
	'sky-50': '#f0f9ff',
	'sky-100': '#e0f2fe',
	'sky-200': '#bae6fd',
	'sky-300': '#7dd3fc',
	'sky-400': '#38bdf8',
	'sky-500': '#0ea5e9',
	'sky-600': '#0284c7',
	'sky-700': '#0369a1',
	'sky-800': '#075985',
	'sky-900': '#0c4a6e',
	'sky-950': '#082f49',
	'blue-50': '#eff6ff',
	'blue-100': '#dbeafe',
	'blue-200': '#bfdbfe',
	'blue-300': '#93c5fd',
	'blue-400': '#60a5fa',
	'blue-500': '#3b82f6',
	'blue-600': '#2563eb',
	'blue-700': '#1d4ed8',
	'blue-800': '#1e40af',
	'blue-900': '#1e3a8a',
	'blue-950': '#172554',
	'indigo-50': '#eef2ff',
	'indigo-100': '#e0e7ff',
	'indigo-200': '#c7d2fe',
	'indigo-300': '#a5b4fc',
	'indigo-400': '#818cf8',
	'indigo-500': '#6366f1',
	'indigo-600': '#4f46e5',
	'indigo-700': '#4338ca',
	'indigo-800': '#3730a3',
	'indigo-900': '#312e81',
	'indigo-950': '#1e1b4b',
	'violet-50': '#f5f3ff',
	'violet-100': '#ede9fe',
	'violet-200': '#ddd6fe',
	'violet-300': '#c4b5fd',
	'violet-400': '#a78bfa',
	'violet-500': '#8b5cf6',
	'violet-600': '#7c3aed',
	'violet-700': '#6d28d9',
	'violet-800': '#5b21b6',
	'violet-900': '#4c1d95',
	'violet-950': '#2e1065',
	'purple-50': '#faf5ff',
	'purple-100': '#f3e8ff',
	'purple-200': '#e9d5ff',
	'purple-300': '#d8b4fe',
	'purple-400': '#c084fc',
	'purple-500': '#a855f7',
	'purple-600': '#9333ea',
	'purple-700': '#7e22ce',
	'purple-800': '#6b21a8',
	'purple-900': '#581c87',
	'purple-950': '#3b0764',
	'fuchsia-50': '#fdf4ff',
	'fuchsia-100': '#fae8ff',
	'fuchsia-200': '#f5d0fe',
	'fuchsia-300': '#f0abfc',
	'fuchsia-400': '#e879f9',
	'fuchsia-500': '#d946ef',
	'fuchsia-600': '#c026d3',
	'fuchsia-700': '#a21caf',
	'fuchsia-800': '#86198f',
	'fuchsia-900': '#701a75',
	'fuchsia-950': '#4a044e',
	'pink-50': '#fdf2f8',
	'pink-100': '#fce7f3',
	'pink-200': '#fbcfe8',
	'pink-300': '#f9a8d4',
	'pink-400': '#f472b6',
	'pink-500': '#ec4899',
	'pink-600': '#db2777',
	'pink-700': '#be185d',
	'pink-800': '#9d174d',
	'pink-900': '#831843',
	'pink-950': '#500724',
	'rose-50': '#fff1f2',
	'rose-100': '#ffe4e6',
	'rose-200': '#fecdd3',
	'rose-300': '#fda4af',
	'rose-400': '#fb7185',
	'rose-500': '#f43f5e',
	'rose-600': '#e11d48',
	'rose-700': '#be123c',
	'rose-800': '#9f1239',
	'rose-900': '#881337',
	'rose-950': '#4c0519',
};
const enterValueInStyleObject = (styleObject, className, breakpointKey, breakpointClassPrefix, theme, stateKey, stateClassPrefix) => {

	if (className == `${breakpointClassPrefix}${stateClassPrefix}block` || className == `!${breakpointClassPrefix}${stateClassPrefix}block`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			display: 'block'
		};
		return;
	}
	else if (className.includes('text-shadow:')) {
		const value = className.slice(className.indexOf(':') + 1, className.indexOf(']'));
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textShadow: value
		};
		return;
	}

	else if (className.includes('shadow')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			boxShadow: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className == `${breakpointClassPrefix}${stateClassPrefix}inline-block` || className == `!${breakpointClassPrefix}${stateClassPrefix}inline-block`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			display: 'inline-block'
		};
		return;

	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}inline` || className == `!${breakpointClassPrefix}${stateClassPrefix}inline`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			display: 'inline-block'
		};
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}hidden` || className == `!${breakpointClassPrefix}${stateClassPrefix}hidden`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			display: 'none'
		};
		return;

	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}inline-flex` || className == `!${breakpointClassPrefix}${stateClassPrefix}inline-flex`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			display: 'inline-flex'
		};
		return;

	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}flex` || className == `!${breakpointClassPrefix}${stateClassPrefix}flex`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			display: 'flex'
		};
		return;

	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}inline-grid` || className == `!${breakpointClassPrefix}${stateClassPrefix}inline-grid`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			display: 'inline-grid'
		};
		return;

	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}grid` || className == `!${breakpointClassPrefix}${stateClassPrefix}grid`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			display: 'grid'
		};
		return;

	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}flex-row` || className == `!${breakpointClassPrefix}${stateClassPrefix}flex-row`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			flexDirection: 'row'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}flex-row-reverse` || className == `!${breakpointClassPrefix}${stateClassPrefix}flex-row-reverse`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			flexDirection: 'row-reverse'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}flex-col` || className == `!${breakpointClassPrefix}${stateClassPrefix}flex-col`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			flexDirection: 'column'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}flex-col-reverse` || className == `!${breakpointClassPrefix}${stateClassPrefix}flex-col-reverse`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			flexDirection: 'column-reverse'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}flex-wrap` || className == `!${breakpointClassPrefix}${stateClassPrefix}flex-wrap`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			flexWrap: 'wrap'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}flex-nowrap` || className == `!${breakpointClassPrefix}${stateClassPrefix}flex-nowrap`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			flexWrap: 'nowrap'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}flex-wrap-reverse` || className == `!${breakpointClassPrefix}${stateClassPrefix}flex-wrap-reverse`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			flexWrap: 'wrap-reverse'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}justify-start` || className == `!${breakpointClassPrefix}${stateClassPrefix}justify-start`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			justifyContent: 'flex-start'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}justify-end` || className == `!${breakpointClassPrefix}${stateClassPrefix}justify-end`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			justifyContent: 'flex-end'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}justify-center` || className == `!${breakpointClassPrefix}${stateClassPrefix}justify-center`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			justifyContent: 'center'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}justify-between` || className == `!${breakpointClassPrefix}${stateClassPrefix}justify-between`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			justifyContent: 'space-between'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}justify-around` || className == `!${breakpointClassPrefix}${stateClassPrefix}justify-around`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			justifyContent: 'space-around'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}justify-evenly` || className == `!${breakpointClassPrefix}${stateClassPrefix}justify-evenly`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			justifyContent: 'space-evenly'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}justify-normal` || className == `!${breakpointClassPrefix}${stateClassPrefix}justify-normal`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			justifyContent: 'normal'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}justify-stretch` || className == `!${breakpointClassPrefix}${stateClassPrefix}justify-stretch`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			justifyContent: 'stretch'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}items-start` || className == `!${breakpointClassPrefix}${stateClassPrefix}items-start`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignItems: 'flex-start'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}items-end` || className == `!${breakpointClassPrefix}${stateClassPrefix}items-end`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignItems: 'flex-end'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}items-center` || className == `!${breakpointClassPrefix}${stateClassPrefix}items-center`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignItems: 'center'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}items-baseline` || className == `!${breakpointClassPrefix}${stateClassPrefix}items-baseline`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignItems: 'baseline'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}items-stretch` || className == `!${breakpointClassPrefix}${stateClassPrefix}items-stretch`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignItems: 'stretch'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}content-normal` || className == `!${breakpointClassPrefix}${stateClassPrefix}content-normal`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignContent: 'normal'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}content-center` || className == `!${breakpointClassPrefix}${stateClassPrefix}content-center`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignContent: 'center'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}content-start` || className == `!${breakpointClassPrefix}${stateClassPrefix}content-start`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignContent: 'flex-start'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}content-end` || className == `!${breakpointClassPrefix}${stateClassPrefix}content-end`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignContent: 'flex-end'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}content-between` || className == `!${breakpointClassPrefix}${stateClassPrefix}content-between`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignContent: 'space-between'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}content-around` || className == `!${breakpointClassPrefix}${stateClassPrefix}content-around`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignContent: 'space-around'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}content-evenly` || className == `!${breakpointClassPrefix}${stateClassPrefix}content-evenly`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignContent: 'space-evenly'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}content-baseline` || className == `!${breakpointClassPrefix}${stateClassPrefix}content-baseline`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignContent: 'basline'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}content-stretch` || className == `!${breakpointClassPrefix}${stateClassPrefix}content-stretch`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignContent: 'stretch'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}self-auto` || className == `!${breakpointClassPrefix}${stateClassPrefix}self-auto`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignSelf: 'auto'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}self-start` || className == `!${breakpointClassPrefix}${stateClassPrefix}self-start`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignSelf: 'flex-start'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}self-end` || className == `!${breakpointClassPrefix}${stateClassPrefix}self-end`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignSelf: 'flex-end'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}self-center` || className == `!${breakpointClassPrefix}${stateClassPrefix}self-center`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignSelf: 'center'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}self-stretch` || className == `!${breakpointClassPrefix}${stateClassPrefix}self-stretch`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignSelf: 'stretch'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}self-baseline` || className == `!${breakpointClassPrefix}${stateClassPrefix}self-baseline`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			alignSelf: 'baseline'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}object-contain` || className == `!${breakpointClassPrefix}${stateClassPrefix}object-contain`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			objectFit: 'contain'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}object-cover` || className == `!${breakpointClassPrefix}${stateClassPrefix}object-cover`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			objectFit: 'cover'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}object-fill` || className == `!${breakpointClassPrefix}${stateClassPrefix}object-fill`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			objectFit: 'fill'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}object-none` || className == `!${breakpointClassPrefix}${stateClassPrefix}object-none`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			objectFit: 'none'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}object-scale-down` || className == `!${breakpointClassPrefix}${stateClassPrefix}object-scale-down`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			objectFit: 'scale-down'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}static` || className == `!${breakpointClassPrefix}${stateClassPrefix}static`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			position: 'static'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}fixed` || className == `!${breakpointClassPrefix}${stateClassPrefix}fixed`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			position: 'fixed'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}absolute` || className == `!${breakpointClassPrefix}${stateClassPrefix}absolute`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			position: 'absolute'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}relative` || className == `!${breakpointClassPrefix}${stateClassPrefix}relative`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			position: 'relative'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}sticky` || className == `!${breakpointClassPrefix}${stateClassPrefix}sticky`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			position: 'sticky'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}break-normal` || className == `!${breakpointClassPrefix}${stateClassPrefix}break-normal`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			overflowWrap: 'normal'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}break-words` || className == `!${breakpointClassPrefix}${stateClassPrefix}break-words`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			overflowWrap: 'break-word'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}truncate` || className == `!${breakpointClassPrefix}${stateClassPrefix}truncate`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			overflow: 'hidden',
			textOverflow: "ellipsis",
			whiteSpace: "nowrap"
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}uppercase` || className == `!${breakpointClassPrefix}${stateClassPrefix}uppercase`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textTransform: 'uppercase'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}lowercase` || className == `!${breakpointClassPrefix}${stateClassPrefix}lowercase`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textTransform: 'lowercase'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}capitalize` || className == `!${breakpointClassPrefix}${stateClassPrefix}capitalize`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textTransform: 'capitalize'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}normal-case` || className == `!${breakpointClassPrefix}${stateClassPrefix}normal-case`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textTransform: 'none'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}italic` || className == `!${breakpointClassPrefix}${stateClassPrefix}italic`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			fontStyle: 'italic'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}not-italic` || className == `!${breakpointClassPrefix}${stateClassPrefix}not-italic`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textTransform: 'normal'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}underline` || className == `!${breakpointClassPrefix}${stateClassPrefix}underline`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textDecorationLine: 'underline'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}overline` || className == `!${breakpointClassPrefix}${stateClassPrefix}overline`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textDecorationLine: 'overline'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}line-through` || className == `!${breakpointClassPrefix}${stateClassPrefix}line-through`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textDecorationLine: 'line-through'
		}
		return;
	} else if (className == `${breakpointClassPrefix}${stateClassPrefix}no-underline` || className == `!${breakpointClassPrefix}${stateClassPrefix}no-underline`) {
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textDecorationLine: 'none'
		}
		return;
	}

	else if (className.includes('list-image-')) {
		const values = className.split('-')[1];
		if (values == 'none') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				listStyleImage: 'none'
			}
		}
		else if (values.includes('[')) {
			const width = values.slice(1, values.indexOf(']'));
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				listStyleImage: width
			}
		}
	}

	else if (className.includes('bg-')) {
		const values = className.split('-');
		if (values.length == 2) {
			if (values[1] == 'transparent') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					backgroundColor: 'transparent'
				}
				return;
			} else if (values[1] == 'black') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					backgroundColor: 'rgb(0 0 0)'
				}
				return;
			} else if (values[1] == 'white') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					backgroundColor: 'rgb(255 255 255)'
				}
				return;
			} else if (values[1] == 'textColor' || values[1] == 'backgroundColor' || values[1] == 'primary_color' || values[1] == 'secondary_color') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					backgroundColor: theme[values[1]]
				}
				return;
			} else if (values[1] == 'bottom' || values[1] == 'center' || values[1] == 'left' || values[1] == 'right' || values[1] == 'top') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					backgroundPosition: values[1]
				}
				return;
			} else if (values[1] == 'auto' || values[1] == 'cover' || values[1] == 'contain') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					backgroundSize: values[1]
				}
				return;
			} else if (values[1] == 'repeat') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					backgroundRepeat: values[1]
				}
				return;
			} else if (values[1].includes('url')) {
				const css = values[1]
				const url = css?.split('(')?.[1]?.split(')')?.[0]
				if (url) {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						backgroundImage: url
					};
				}
				return;
			} else if (values[1].includes('[')) {
				const index = values[1].indexOf(']');
				const padding = values[1].slice(1, index).trim();
				if (values[1].includes('#') || values[1].includes('rgb') || values[1].includes('hsl')) {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						backgroundColor: padding
					}
					return;
				}
			}
		} else if (values.length == 3) {
			const value = `${values[1]}-${values[2]}`;
			if (values[1] != 'clip' && values[1] != 'origin') {

				if (value == 'left-bottom' || value == 'left-top' || value == 'right-bottom' || value == 'right-top') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						backgroundPosition: `${values[1]} ${values[2]}`
					}
					return;
				} else if (value == 'no-repeat' || value == 'repeat-x' || value == 'repeat-y') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						backgroundRepeat: value
					}
					return;
				} else if (value == 'repeat-round') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						backgroundRepeat: 'round'
					}
					return;
				} else if (value == 'repeat-space') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						backgroundRepeat: 'space'
					}
					return;
				} else {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						backgroundColor: colors[value]
					}
					return;
				}
			}
		}
		else if(values.length == 4){
			
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				backgroundImage: `${values[1]}-${values[2]}-${values[3]}`
			}
			return;
		}
	}


	// Aspect ratio
	else if (className.includes('aspect-')) {
		const value = className.split('-')[1];
		if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				aspectRatio: 'auto'
			}
			return;
		}
		else if (value == 'square') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				aspectRatio: '1/1'
			}
			return;
		}
		else if (value == 'video') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				aspectRatio: '16/9'
			}
			return;
		}
		else if (value.includes('[')) {
			const index = value.indexOf(']');
			const width = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				aspectRatio: width
			}
			return;
		}
	}

	// Object Position
	else if (className.includes('object-')) {
		const values = className.split("-");
		if (values.length == 2) {
			if (values[1].includes('[')) {
				values[1] = values[1].slice(1, values[1].indexOf(']'));
			}

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				objectPosition: values[1]
			}
			return;
		}
		else if (values.length == 3) {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				objectPosition: `${values[1]}-${values[2]}`
			}
			return;
		}
	}

	// textUnderline style as well as LineThickness
	else if (className.includes('decoration-')) {
		const values = className.split('-');
		if (values[1] == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				lineThickness: values[1]
			}
		}
		else if (values[1].includes('[')) {
			const width = values[1].slice(1, values[1].indexOf(']'));
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				lineThickness: width
			}
		}
		else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				textDecoration: values[1]
			}
		}
		return;
	}

	else if (className.includes('underline-offset-')) {
		const values = className.split('-')[2];
		if (values == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				textUnderlineOffset: values
			}
		}
		else {
			const width = values.slice(1, values.indexOf(']'));
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				textUnderlineOffset: width
			}
		}
		return;
	}

	else if (className.includes('tracking-')) {
		const values = className.split('-')[1];
		const width = values.slice(1, values.indexOf(']'));
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			letterSpacing: width
		}
		return;
	}

	else if (className.includes('leading-')) {
		const values = className.split('-')[1];
		if (values == 'none') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				lineHeight: values
			}
		}
		else {
			const width = values.slice(1, values.indexOf(']'));
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				lineHeight: width
			}

		}
		return;
	}

	else if (className.includes('break-')) {
		const values = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			wordBreak: values
		}
		return;
	}

	else if (className.includes('indent-')) {
		const values = className.split('-')[1];
		const width = values.slice(1, values.indexOf(']'));
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			textIndent: width
		}
		return;
	}

	else if (className.includes('list-')) {
		const values = className.split('-')[1];

		if (values == 'inside' || values == 'outside') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				listStylePosition: values
			}
		}
		else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				lineStyleType: values
			}
		}


		return;
	}


	else if (className.includes('gap-y-')) {
		const value = className.split('-')[2];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				rowGap: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const width = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				rowGap: width
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				rowGap: `${value * 4}px`
			}
			return;

		}
	} else if (className.includes('gap-x-')) {
		const value = className.split('-')[2];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				columnGap: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const width = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				columnGap: width
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				columnGap: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('gap-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				columnGap: '1px',
				rowGap: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const width = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				columnGap: width,
				rowGap: width
			}
			return;
		} else {

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				columnGap: `${value * 4}px`,
				rowGap: `${value * 4}px`
			}
			return;

		}
	} else if (className.includes('grow')) {
		const value = className.split('-')[1];
		if (value) {
			if (value.includes('[')) {
				const index = value.indexOf(']');
				const width = value.slice(1, index).trim();
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					flexGrow: width
				}
				return;
			} else {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					flexGrow: value
				}
				return;

			}
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				flexGrow: 1
			}
			return;
		}
	} else if (className.includes('shrink')) {
		const value = className.split('-')[1];
		if (value) {
			if (value.includes('[')) {
				const index = value.indexOf(']');
				const width = value.slice(1, index).trim();
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					flexShrink: width
				}
				return;
			} else {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					flexShrink: value
				}
				return;

			}
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				flexShrink: 1
			}
			return;
		}
	} else if (className.includes('basis')) {
		const value = className.split('-')[1];


		if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				flexBasis: '100%'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				flexBasis: 'auto'
			}
			return;

		} else if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				flexBasis: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim()
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				flexBasis: height
			}
			return;
		} else {
			const values = value.split('/');
			if (values.length == 1) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					flexBasis: `${value * 4}px`
				}
				return;
			} else {
				const height = (values[0] / values[1]) * 100;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					flexBasis: `${height}%`
				}
				return;
			}
		}
	} else if (className.includes('max-w-')) {
		if (className.split('-').length == 3) {
			const value = className.split('-')[2];
			if (value == 'full') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '100%'
				}
				return;
			} else if (value == 'none') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: 'none'
				}
				return;

			} else if (value == 'px') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '1px'
				}
				return;
			} else if (value == 'max') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: 'max-content'
				}
				return;
			} else if (value == 'min') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: 'min-content'
				}
				return;
			} else if (value == 'fit') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: 'fit-content'
				}
				return;
			} else if (value == 'xs') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '320px'
				}
				return;
			} else if (value == 'sm') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '384px'
				}
				return;
			} else if (value == 'md') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '448px'
				}
				return;
			} else if (value == 'lg') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '512px'
				}
				return;
			} else if (value == 'xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '576px'
				}
				return;
			} else if (value == '2xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '672px'
				}
				return;
			} else if (value == '3xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '768px'
				}
				return;
			} else if (value == '4xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '896px'
				}
				return;
			} else if (value == '5xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '1024px'
				}
				return;
			} else if (value == '6xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '1152px'
				}
				return;
			} else if (value == '7xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '1280px'
				}
				return;
			} else if (value.includes('[')) {
				const index = value.indexOf(']');
				const height = value.slice(1, index).trim();
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: height
				}
				return;
			} else {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: `${value * 4}px`
				}
				return;

			}
		} else if (className.split('-').length == 4) {
			const value = `${className.split('-')[2]}-${className.split('-')[3]}`;
			if (value == 'screen-sm') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '640px'
				}
				return;
			} else if (value == 'screen-md') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '768px'
				}
				return;
			} else if (value == 'screen-lg') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '1024px'
				}
				return;
			} else if (value == 'screen-xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '1280px'
				}
				return;
			} else if (value == 'screen-2xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					maxWidth: '1536px'
				}
				return;
			}
		}
	} else if (className.includes('min-w-')) {
		const value = className.split('-')[2];
		if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minWidth: '100%'
			}
			return;
		} else if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minWidth: '1px'
			}
			return;
		} else if (value == 'max') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minWidth: 'max-content'
			}
			return;
		} else if (value == 'min') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minWidth: 'min-content'
			}
			return;
		} else if (value == 'fit') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minWidth: 'fit-content'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minWidth: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minWidth: `${value * 4}px`
			}
			return;

		}
	}

	else if (className.includes('overflow-x-')) {

		const value = className.split('-')[2];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			overflowX: value
		}
		return;
	} else if (className.includes('overflow-y-')) {

		const value = className.split('-')[2];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			overflowY: value
		}
		return;
	} else if (className.includes('overflow-')) {

		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			overflow: value
		}
		return;
	}


	else if (className.includes('w-')) {
		const value = className.split('-')[1];
		if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				width: '100%'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				width: 'auto'
			}
			return;

		} else if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				width: '1px'
			}
			return;
		} else if (value == 'screen') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				width: '100vw'
			}
			return;
		} else if (value == 'max') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				width: 'max-content'
			}
			return;
		} else if (value == 'min') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				width: 'min-content'
			}
			return;
		} else if (value == 'fit') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				width: 'fit-content'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim()
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				width: height
			}
			return;
		} else {
			const values = value.split('/');
			if (values.length == 1) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					width: `${value * 4}px`
				}
				return;
			} else {
				const height = (values[0] / values[1]) * 100;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					width: `${height}%`
				}
				return;
			}
		}
	} else if (className.includes('max-h-')) {
		const value = className.split('-')[2];
		if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				maxHeight: '100%'
			}
			return;
		} else if (value == 'none') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				maxHeight: 'none'
			}
			return;

		} else if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				maxHeight: '1px'
			}
			return;
		} else if (value == 'max') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				maxHeight: 'max-content'
			}
			return;
		} else if (value == 'min') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				maxHeight: 'min-content'
			}
			return;
		} else if (value == 'fit') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				maxHeight: 'fit-content'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				maxHeight: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				maxHeight: `${value * 4}px`
			}
			return;

		}

	} else if (className.includes('min-h-')) {
		const value = className.split('-')[2];
		if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minHeight: '100%'
			}
			return;
		} else if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minHeight: '1px'
			}
			return;
		} else if (value == 'max') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minHeight: 'max-content'
			}
			return;
		} else if (value == 'min') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minHeight: 'min-content'
			}
			return;
		} else if (value == 'fit') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minHeight: 'fit-content'
			}
			return;
		} else if (value == 'screen') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minHeight: '100vh'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minHeight: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				minHeight: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('h-')) {
		const value = className.split('-')[1];
		if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				height: '100%'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				height: 'auto'
			}
			return;

		} else if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				height: '1px'
			}
			return;
		} else if (value == 'screen') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				height: '100vh'
			}
			return;
		} else if (value == 'max') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				height: 'max-content'
			}
			return;
		} else if (value == 'min') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				height: 'min-content'
			}
			return;
		} else if (value == 'fit') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				height: 'fit-content'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				height: height
			}
			return;
		} else {
			const values = value.split('/');
			if (values.length == 1) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					height: `${value * 4}px`
				}
				return;
			} else {
				const height = (values[0] / values[1]) * 100;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					height: `${height}%`
				}
				return;
			}
		}
	} else if (className.includes('pl-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingLeft: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingLeft: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingLeft: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('pt-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingTop: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingTop: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingTop: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('pr-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingRight: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingRight: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingRight: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('pb-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingBottom: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingBottom: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingBottom: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('px-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingLeft: '1px',
				paddingRight: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingLeft: height,
				paddingRight: height

			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingLeft: `${value * 4}px`,
				paddingRight: `${value * 4}px`

			}
			return;
		}
	} else if (className.includes('py-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingTop: '1px',
				paddingBottom: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingTop: height,
				paddingBottom: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				paddingTop: `${value * 4}px`,
				paddingBottom: `${value * 4}px`

			}
			return;
		}
	} else if (className.includes('ml-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginLeft: '1px'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginLeft: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const height = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginLeft: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginLeft: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('mt-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginTop: '1px'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginTop: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const height = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginTop: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginTop: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('mr-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginRight: '1px'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginRight: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const height = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginRight: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginRight: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('mb-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginBottom: '1px'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginBottom: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const height = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginBottom: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginBottom: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('mx-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginLeft: '1px',
				marginRight: '1px'

			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginLeft: 'auto',
				marginRight: 'auto'

			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const height = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginLeft: height,
				marginRight: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginLeft: `${value * 4}px`,
				marginRight: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('my-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginTop: '1px',
				marginBottom: '1px'

			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginTop: 'auto',
				marginBottom: 'auto'

			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const height = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginTop: height,
				marginBottom: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				marginTop: `${value * 4}px`,
				marginBottom: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('border')) {
		const values = className.split('-');
		if (values.length == 1) {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				borderWidth: '1px'
			}
			return;
		} else if (values.length == 2) {
			if (values[1] == 'x') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderLeftWidth: '1px',
					borderRightWidth: '1px'
				}
				return;
			} else if (values[1] == 'y') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderTopWidth: '1px',
					borderBottomWidth: '1px'
				}
				return;
			} else if (values[1] == 'l') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderLeftWidth: '1px'
				}
				return;
			} else if (values[1] == 't') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderTopWidth: '1px'
				}
				return;
			} else if (values[1] == 'r') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderRightWidth: '1px'
				}
				return;
			} else if (values[1] == 'b') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderBottomWidth: '1px'
				}
				return;
			} else if (values[1] == 'solid') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderStyle: 'solid'
				}
				return;
			} else if (values[1] == 'dashed') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderStyle: 'dashed'
				}
				return;
			} else if (values[1] == 'dotted') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderStyle: 'dotted'
				}
				return;
			} else if (values[1] == 'double') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderStyle: 'double'
				}
				return;
			} else if (values[1] == 'hidden') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderStyle: 'hidden'
				}
				return;
			} else if (values[1] == 'none') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderStyle: 'none'
				}
				return;
			} else if (values[1] == 'transparent') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderColor: 'transparent'
				}
				return;
			} else if (values[1] == 'black') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderColor: 'rgb(0 0 0)'
				}
				return;
			} else if (values[1] == 'white') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderColor: 'rgb(255 255 255)'
				}
				return;
			} else if (values[1] == 'textColor' || values[1] == 'backgroundColor' || values[1] == 'primary_color' || values[1] == 'secondary_color') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderColor: theme[values[1]]
				}
				return;
			} else if (values[1].includes('[')) {
				const index = values[1].indexOf(']');
				const padding = values[1].slice(1, index).trim();
				if (values[1].includes('#') || values[1].includes('rgb') || values[1].includes('hsl')) {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderColor: padding
					}
					return;
				} else {

					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderWidth: padding
					}
					return;
				}
			} else if (values[1] == '0' || values[1] == '2' || values[1] == '4' || values[1] == '8') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderWidth: `${values[1]}px`
				}
				return;
			}
		} else if (values.length == 3) {
			if (values[1] == 'x') {
				if (values[2].includes('[')) {
					if (!values[2].includes('#') && !values[2].includes('rgb') && !values[2].includes('hsl')) {
						const index = values[2].indexOf(']');
						const padding = values[2].slice(1, index).trim();
						styleObject[breakpointKey][stateKey] = {
							...styleObject[breakpointKey][stateKey],
							borderLeftWidth: padding,
							borderRightWidth: padding

						}
						return;
					}
				} else if (values[2] == '0' || values[2] == '2' || values[2] == '4' || values[2] == '8') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderLeftWidth: `${values[2]}px`,
						borderRightWidth: `${values[2]}px`
					}
					return;
				}
			} else if (values[1] == 'y') {
				if (values[2].includes('[')) {
					if (!values[2].includes('#') && !values[2].includes('rgb') && !values[2].includes('hsl')) {
						const index = values[2].indexOf(']');
						const padding = values[2].slice(1, index).trim();
						styleObject[breakpointKey][stateKey] = {
							...styleObject[breakpointKey][stateKey],
							borderTopWidth: padding,
							borderBottomWidth: padding

						}
						return;
					}
				} else if (values[2] == '0' || values[2] == '2' || values[2] == '4' || values[2] == '8') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopWidth: `${values[2]}px`,
						borderBottomWidth: `${values[2]}px`
					}
					return;
				}
			} else if (values[1] == 'l') {
				if (values[2].includes('[')) {
					if (!values[2].includes('#') && !values[2].includes('rgb') && !values[2].includes('hsl')) {
						const index = values[2].indexOf(']');
						const padding = values[2].slice(1, index).trim();
						styleObject[breakpointKey][stateKey] = {
							...styleObject[breakpointKey][stateKey],
							borderLeftWidth: padding
						}
						return;
					}
				} else if (values[2] == '0' || values[2] == '2' || values[2] == '4' || values[2] == '8') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderLeftWidth: `${values[2]}px`
					}
					return;
				}
			} else if (values[1] == 't') {
				if (values[2].includes('[')) {
					if (!values[2].includes('#') && !values[2].includes('rgb') && !values[2].includes('hsl')) {
						const index = values[2].indexOf(']');
						const padding = values[2].slice(1, index).trim();
						styleObject[breakpointKey][stateKey] = {
							...styleObject[breakpointKey][stateKey],
							borderTopWidth: padding
						}
						return;
					}
				} else if (values[2] == '0' || values[2] == '2' || values[2] == '4' || values[2] == '8') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopWidth: `${values[2]}px`
					}
					return;
				}
			} else if (values[1] == 'r') {
				if (values[2].includes('[')) {
					if (!values[2].includes('#') && !values[2].includes('rgb') && !values[2].includes('hsl')) {
						const index = values[2].indexOf(']');
						const padding = values[2].slice(1, index).trim();
						styleObject[breakpointKey][stateKey] = {
							...styleObject[breakpointKey][stateKey],
							borderRightWidth: padding
						}
						return;
					}
				} else if (values[2] == '0' || values[2] == '2' || values[2] == '4' || values[2] == '8') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderRightWidth: `${values[2]}px`
					}
					return;
				}
			} else if (values[1] == 'b') {
				if (values[2].includes('[')) {
					if (!values[2].includes('#') && !values[2].includes('rgb') && !values[2].includes('hsl')) {
						const index = values[2].indexOf(']');
						const padding = values[2].slice(1, index).trim();
						styleObject[breakpointKey][stateKey] = {
							...styleObject[breakpointKey][stateKey],
							borderBottomWidth: padding
						}
						return;
					}
				} else if (values[2] == '0' || values[2] == '2' || values[2] == '4' || values[2] == '8') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomWidth: `${values[2]}px`
					}
					return;
				}
			} else {
				const color = `${values[1]}-${values[2]}`;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderColor: colors[color]
				}
				return;
			}
		}
	} else if (className.includes('outline')) {
		const values = className.split('-');
		if (values.length == 1) {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				outlineStyle: 'solid'
			}
			return;
		} else if (values.length == 2) {
			if (values[1] == 'none' || values[1] == 'dashed' || values[1] == 'dotted' || values[1] == 'double') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					outlineStyle: values[1]
				}
				return;
			} else if (values[1] == 'textColor' || values[1] == 'backgroundColor' || values[1] == 'primary_color' || values[1] == 'secondary_color') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					outlineColor: theme[values[1]]
				}
				return;
			} else if (values[1].includes('[')) {
				const index = values[1].indexOf(']');
				const padding = values[1].slice(1, index).trim();
				if (values[1].includes('#') || values[1].includes('rgb') || values[1].includes('hsl')) {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						outlineColor: padding
					}
					return;
				} else {

					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						outlineWidth: padding
					}
					return;
				}
			} else if (values[1] == '0' || values[1] == '2' || values[1] == '4' || values[1] == '8') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					outlineWidth: `${values[1]}px`
				}
				return;
			} else if (values[1] == 'transparent') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					outlineColor: 'transparent'
				}
				return;
			} else if (values[1] == 'black') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					outlineColor: 'rgb(0 0 0)'
				}
				return;
			} else if (values[1] == 'white') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					outlineColor: 'rgb(255 255 255)'
				}
				return;
			}
		} else if (values.length == 3) {
			if (values[1] == 'offset') {
				if (values[2].includes('[')) {
					const index = values[2].indexOf(']');
					const padding = values[2].slice(1, index).trim();
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						outlineOffset: padding

					}
					return;
				} else if (values[2] == '0' || values[2] == '1' || values[2] == '2' || values[2] == '4' || values[2] == '8') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						outlineOffset: `${values[2]}px`
					}
					return;
				}
			} else {
				const color = `${values[1]}-${values[2]}`;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					outlineColor: colors[color]
				}
				return;
			}
		}
	} else if (className.includes('rounded')) {
		const values = className.split('-');
		if (values.length == 1) {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				borderRadius: '4px'
			};
			return;
		} else if (values.length == 2) {
			if (values[1] == 'none') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderRadius: '0px'
				};
				return;
			} else if (values[1] == 'sm') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderRadius: '2px'
				};
				return;
			} else if (values[1] == 'md') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderRadius: '6px'
				};
				return;
			} else if (values[1] == 'lg') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderRadius: '8px'
				};
				return;
			} else if (values[1] == 'xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderRadius: '12px'
				};
				return;
			} else if (values[1] == '2xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderRadius: '16px'
				};
				return;
			} else if (values[1] == '3xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderRadius: '24px'
				};
				return;
			} else if (values[1] == 'full') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderRadius: '9999px'
				};
				return;
			} else if (values[1] == 'tl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderTopLeftRadius: '4px'
				};
				return;
			} else if (values[1] == 'tr') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderTopRightRadius: '4px'
				};
				return;
			} else if (values[1] == 'bl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderBottomLeftRadius: '4px'
				};
				return;
			} else if (values[1] == 'br') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderBottomRightRadius: '4px'
				};
				return;
			} else if (values[1] == 'l') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderTopLeftRadius: '4px',
					borderBottomLeftRadius: '4px'

				};
				return;
			} else if (values[1] == 't') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderTopLeftRadius: '4px',
					borderTopRightRadius: '4px'

				};
				return;
			} else if (values[1] == 'r') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderTopRightRadius: '4px',
					borderBottomRightRadius: '4px'

				};
				return;
			} else if (values[1] == 'b') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderBottomRightRadius: '4px',
					borderBottomLeftRadius: '4px'

				};
				return;
			} else if (values[1].includes('[')) {
				const index = values[1].indexOf(']');
				const padding = values[1].slice(1, index).trim();
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					borderRadius: padding
				}
				return;
			}
		} else if (values.length == 3) {
			if (values[1] == 'tl') {
				if (values[2] == 'none') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '0px'
					};
					return;
				} else if (values[2] == 'sm') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '2px'
					};
					return;
				} else if (values[2] == 'md') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '6px'
					};
					return;
				} else if (values[2] == 'lg') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '8px'
					};
					return;
				} else if (values[2] == 'xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '12px'
					};
					return;
				} else if (values[2] == '2xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '16px'
					};
					return;
				} else if (values[2] == '3xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '24px'
					};
					return;
				} else if (values[2] == 'full') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '9999px'
					};
					return;
				} else if (values[2].includes('[')) {
					const index = values[2].indexOf(']');
					const padding = values[2].slice(1, index).trim();
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: padding
					}
					return;
				}
			} else if (values[1] == 'tr') {

				if (values[2] == 'none') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopRightRadius: '0px'
					};
					return;
				} else if (values[2] == 'sm') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopRightRadius: '2px'
					};
					return;
				} else if (values[2] == 'md') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopRightRadius: '6px'
					};
					return;
				} else if (values[2] == 'lg') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopRightRadius: '8px'
					};
					return;
				} else if (values[2] == 'xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopRightRadius: '12px'
					};
					return;
				} else if (values[2] == '2xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopRightRadius: '16px'
					};
					return;
				} else if (values[2] == '3xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopRightRadius: '24px'
					};
					return;
				} else if (values[2] == 'full') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopRightRadius: '9999px'
					};
					return;
				} else if (values[2].includes('[')) {
					const index = values[2].indexOf(']');
					const padding = values[2].slice(1, index).trim();
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopRightRadius: padding
					}
					return;
				}
			} else if (values[1] == 'bl') {
				if (values[2] == 'none') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomLeftRadius: '0px'
					};
					return;
				} else if (values[2] == 'sm') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomLeftRadius: '2px'
					};
					return;
				} else if (values[2] == 'md') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomLeftRadius: '6px'
					};
					return;
				} else if (values[2] == 'lg') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomLeftRadius: '8px'
					};
					return;
				} else if (values[2] == 'xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomLeftRadius: '12px'
					};
					return;
				} else if (values[2] == '2xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomLeftRadius: '16px'
					};
					return;
				} else if (values[2] == '3xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomLeftRadius: '24px'
					};
					return;
				} else if (values[2] == 'full') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomLeftRadius: '9999px'
					};
					return;
				} else if (values[2].includes('[')) {
					const index = values[2].indexOf(']');
					const padding = values[2].slice(1, index).trim();
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomLeftRadius: padding
					}
					return;
				}
			} else if (values[1] == 'br') {
				if (values[2] == 'none') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '0px'
					};
					return;
				} else if (values[2] == 'sm') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '2px'
					};
					return;
				} else if (values[2] == 'md') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '6px'
					};
					return;
				} else if (values[2] == 'lg') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '8px'
					};
					return;
				} else if (values[2] == 'xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '12px'
					};
					return;
				} else if (values[2] == '2xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '16px'
					};
					return;
				} else if (values[2] == '3xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '24px'
					};
					return;
				} else if (values[2] == 'full') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '9999px'
					};
					return;
				} else if (values[2].includes('[')) {
					const index = values[2].indexOf(']');
					const padding = values[2].slice(1, index).trim();
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: padding
					}
					return;
				}
			} else if (values[1] == 'l') {
				if (values[2] == 'none') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '0px',
						borderBottomLeftRadius: '0px'
					};
					return;
				} else if (values[2] == 'sm') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '2px',
						borderBottomLeftRadius: '2px'
					};
					return;
				} else if (values[2] == 'md') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '6px',
						borderBottomLeftRadius: '6px'
					};
					return;
				} else if (values[2] == 'lg') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '8px',
						borderBottomLeftRadius: '8px'
					};
					return;
				} else if (values[2] == 'xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '12px',
						borderBottomLeftRadius: '12px'
					};
					return;
				} else if (values[2] == '2xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '16px',
						borderBottomLeftRadius: '16px'
					};
					return;
				} else if (values[2] == '3xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '24px',
						borderBottomLeftRadius: '24px'
					};
					return;
				} else if (values[2] == 'full') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '9999px',
						borderBottomLeftRadius: '9999px'
					};
					return;
				} else if (values[2].includes('[')) {
					const index = values[2].indexOf(']');
					const padding = values[2].slice(1, index).trim();
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: padding,
						borderBottomLeftRadius: padding
					}
					return;
				}

			} else if (values[1] == 't') {
				if (values[2] == 'none') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '0px',
						borderTopRightRadius: '0px'
					};
					return;
				} else if (values[2] == 'sm') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '2px',
						borderTopRightRadius: '2px'
					};
					return;
				} else if (values[2] == 'md') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '6px',
						borderTopRightRadius: '6px'
					};
					return;
				} else if (values[2] == 'lg') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '8px',
						borderTopRightRadius: '8px'
					};
					return;
				} else if (values[2] == 'xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '12px',
						borderTopRightRadius: '12px'
					};
					return;
				} else if (values[2] == '2xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '16px',
						borderTopRightRadius: '16px'
					};
					return;
				} else if (values[2] == '3xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '24px',
						borderTopRightRadius: '24px'
					};
					return;
				} else if (values[2] == 'full') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: '9999px',
						borderTopRightRadius: '9999px'
					};
					return;
				} else if (values[2].includes('[')) {
					const index = values[2].indexOf(']');
					const padding = values[2].slice(1, index).trim();
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderTopLeftRadius: padding,
						borderTopRightRadius: padding
					}
					return;
				}
			} else if (values[1] == 'r') {
				if (values[2] == 'none') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '0px',
						borderTopRightRadius: '0px'
					};
					return;
				} else if (values[2] == 'sm') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '2px',
						borderTopRightRadius: '2px'
					};
					return;
				} else if (values[2] == 'md') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '6px',
						borderTopRightRadius: '6px'
					};
					return;
				} else if (values[2] == 'lg') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '8px',
						borderTopRightRadius: '8px'
					};
					return;
				} else if (values[2] == 'xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '12px',
						borderTopRightRadius: '12px'
					};
					return;
				} else if (values[2] == '2xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '16px',
						borderTopRightRadius: '16px'
					};
					return;
				} else if (values[2] == '3xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '24px',
						borderTopRightRadius: '24px'
					};
					return;
				} else if (values[2] == 'full') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '9999px',
						borderTopRightRadius: '9999px'
					};
					return;
				} else if (values[2].includes('[')) {
					const index = values[2].indexOf(']');
					const padding = values[2].slice(1, index).trim();
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: padding,
						borderTopRightRadius: padding
					}
					return;
				}
			} else if (values[1] == 'b') {
				if (values[2] == 'none') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '0px',
						borderBottomLeftRadius: '0px'
					};
					return;
				} else if (values[2] == 'sm') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '2px',
						borderBottomLeftRadius: '2px'
					};
					return;
				} else if (values[2] == 'md') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '6px',
						borderBottomLeftRadius: '6px'
					};
					return;
				} else if (values[2] == 'lg') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '8px',
						borderBottomLeftRadius: '8px'
					};
					return;
				} else if (values[2] == 'xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '12px',
						borderBottomLeftRadius: '12px'
					};
					return;
				} else if (values[2] == '2xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '16px',
						borderBottomLeftRadius: '16px'
					};
					return;
				} else if (values[2] == '3xl') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '24px',
						borderBottomLeftRadius: '24px'
					};
					return;
				} else if (values[2] == 'full') {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: '9999px',
						borderBottomLeftRadius: '9999px'
					};
					return;
				} else if (values[2].includes('[')) {
					const index = values[2].indexOf(']');
					const padding = values[2].slice(1, index).trim();
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						borderBottomRightRadius: padding,
						borderBottomLeftRadius: padding
					}
					return;
				}
			}
		}
	} else if (className.includes('z-')) {
		const value = className.split('-')[1];
		if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				zIndex: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const padding = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				zIndex: padding
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				zIndex: value
			}
			return;
		}
	} else if (className.includes('inset-x-')) {
		const value = className.split('-')[2];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: '1px',
				right: '1px'
			}
			return;
		} else if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: '100%',
				right: '100%'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: 'auto',
				right: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const width = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: width,
				right: width
			}
			return;
		} else {
			const values = value.split('/');
			if (values.length == 1) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					left: `${value * 4}px`,
					right: `${value * 4}px`
				}
				return;
			} else {
				const height = (values[0] / values[1]) * 100;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					left: `${height}%`,
					right: `${height}%`

				}
				return;
			}
		}
	} else if (className.includes('inset-y-')) {
		const value = className.split('-')[2];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				top: '1px',
				bottom: '1px'
			}
			return;
		} else if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				top: '100%',
				bottom: '100%'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				top: 'auto',
				bottom: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const width = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				top: width,
				bottom: width
			}
			return;
		} else {
			const values = value.split('/');
			if (values.length == 1) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					top: `${value * 4}px`,
					bottom: `${value * 4}px`
				}
				return;
			} else {
				const height = (values[0] / values[1]) * 100;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					top: `${height}%`,
					bottom: `${height}%`

				}
				return;
			}
		}
	} else if (className.includes('inset-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: '1px',
				right: '1px',
				top: '1px',
				bottom: '1px'
			}
			return;
		} else if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: '100%',
				right: '100%',
				top: '100%',
				bottom: '100%'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: 'auto',
				right: 'auto',
				top: 'auto',
				bottom: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const width = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: width,
				right: width,
				top: width,
				bottom: width
			}
			return;
		} else {
			const values = value.split('/');
			if (values.length == 1) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					left: `${value * 4}px`,
					right: `${value * 4}px`,
					top: `${value * 4}px`,
					bottom: `${value * 4}px`
				}
				return;
			} else {
				const height = (values[0] / values[1]) * 100;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					left: `${height}%`,
					right: `${height}%`,
					top: `${height}%`,
					bottom: `${height}%`
				}
				return;
			}
		}
	} else if (className.includes('left-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: '1px'
			}
			return;
		} else if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: '100%'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const width = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				left: width
			}
			return;
		} else {
			const values = value.split('/');
			if (values.length == 1) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					left: `${value * 4}px`
				}
				return;
			} else {
				const height = (values[0] / values[1]) * 100;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					left: `${height}%`

				}
				return;
			}
		}
	} else if (className.includes('top-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				top: '1px'
			}
			return;
		} else if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				top: '100%'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				top: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const width = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				top: width
			}
			return;
		} else {
			const values = value.split('/');
			if (values.length == 1) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					top: `${value * 4}px`
				}
				return;
			} else {
				const height = (values[0] / values[1]) * 100;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					top: `${height}%`
				}
				return;
			}
		}
	} else if (className.includes('right-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				right: '1px'
			}
			return;
		} else if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				right: '100%'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				right: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const width = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				right: width
			}
			return;
		} else {
			const values = value.split('/');
			if (values.length == 1) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					right: `${value * 4}px`
				}
				return;
			} else {
				const height = (values[0] / values[1]) * 100;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					right: `${height}%`
				}
				return;
			}
		}
	} else if (className.includes('bottom-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				bottom: '1px'
			}
			return;
		} else if (value == 'full') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				bottom: '100%'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				bottom: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const width = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				bottom: width
			}
			return;
		} else {
			const values = value.split('/');
			if (values.length == 1) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					bottom: `${value * 4}px`
				}
				return;
			} else {
				const height = (values[0] / values[1]) * 100;
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					bottom: `${height}%`
				}
				return;
			}
		}
	} else if (className.includes('p-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				padding: '1px'
			}
			return;
		} else if (value.includes('[')) {
			const index = value.indexOf(']');
			const height = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				padding: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				padding: `${value * 4}px`
			}
			return;
		}
	} else if (className.includes('from-')) {
		const values = className.split('-')
		if (values.length == 2) {
			if (values[1]?.includes('%')) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fromPercent: `${values[1]}`
				}
			} else {
				const width = values[1].slice(1, values[1].indexOf(']'));
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fromColor: `${width}`
				}
			}
		}
		return;
	} else if (className.includes('via')) {
		const values = className.split('-')
		if (values.length == 2) {
			if (values[1]?.includes('%')) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					viaPercent: `${parseInt(values[1], 10)}`
				}
			} else {
				const width = values[1].slice(1, values[1].indexOf(']'));
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					viaColor: `${width}`
				}
			}
		}
		return;
	} else if (className.includes('to')) {
		const values = className.split('-')
		if (values.length == 2) {
			if (values[1]?.includes('%')) {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					toPercent: `${parseInt(values[1], 10)}`
				}
			} else {
				const width = values[1].slice(1, values[1].indexOf(']'));
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					toColor: `${width}`
				}
			}
		}

		return;
	} else if (className.includes('m-')) {
		const value = className.split('-')[1];
		if (value == 'px') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				margin: '1px'
			}
			return;
		} else if (value == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				margin: 'auto'
			}
			return;
		} else if (value.includes('[')) {
			const index = className.indexOf(']');
			const height = className.slice((className.indexOf('[') + 1), index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				margin: height
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				margin: `${value * 4}px`
			}
			return;
		}
	}



	else if (className.includes('break-')) {
		const value = className.split('-')[1];
		if (value == 'normal') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				overflowWrap: 'normal'
			}
			return;
		} else if (value == 'words') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				overflowWrap: 'break-word'
			}
			return;
		}
	} else if (className.includes('text-')) {
		const values = className.split('-');
		if (values.length == 3) {
			const color = `${values[1]}-${values[2]}`;
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				color: colors[color]
			}
			return;
		} else {
			if (values[1] == 'xs') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '12px',
				}
				return;
			} else if (values[1] == 'sm') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '14px'
				}
				return;
			} else if (values[1] == 'base') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '16px'
				}
				return;
			} else if (values[1] == 'lg') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '18px'
				}
				return;
			} else if (values[1] == 'xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '20px'
				}
				return;
			} else if (values[1] == '2xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '24px'
				}
				return;
			} else if (values[1] == '3xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '30px'
				}
				return;
			} else if (values[1] == '4xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '36px'
				}
				return;
			} else if (values[1] == '5xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '48px'
				}
				return;
			} else if (values[1] == '6xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '60px'
				}
				return;
			} else if (values[1] == '7xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '72px'
				}
				return;
			} else if (values[1] == '8xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '96px'
				}
				return;
			} else if (values[1] == '9xl') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: '128px'
				}
				return;
			} else if (values[1].includes('[')) {
				const index = values[1].indexOf(']');
				const padding = values[1].slice(1, index).trim();
				if (values[1].includes('#') || values[1].includes('rgb') || values[1].includes('hsl')) {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						color: padding
					}
					return;
				} else {
					styleObject[breakpointKey][stateKey] = {
						...styleObject[breakpointKey][stateKey],
						fontSize: padding
					}
					return;
				}
			} else if (values[1] == 'left') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					textAlign: "left"
				}
				return;
			} else if (values[1] == 'center') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					textAlign: "center"
				}
				return;
			} else if (values[1] == 'right') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					textAlign: "right"
				}
				return;
			} else if (values[1] == 'justify') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					textAlign: "justify"
				}
				return;
			} else if (values[1] == 'start') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					textAlign: "start"
				}
				return;
			} else if (values[1] == 'ellipsis') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					textOverflow: "ellipsis"
				}
				return;
			} else if (values[1] == 'clip') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					textOverflow: "clip"
				}
				return;
			} else if (values[1] == 'transparent') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					color: 'transparent'
				}
				return;
			} else if (values[1] == 'black') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					color: 'rgb(0 0 0)'
				}
				return;
			} else if (values[1] == 'white') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					color: 'rgb(255 255 255)'
				}
				return;
			} else if (values[1] == 'textColor' || values[1] == 'backgroundColor' || values[1] == 'primary_color' || values[1] == 'secondary_color') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					color: theme[values[1]]
				}
				return;
			} else if (values[1] == 'font_size' || values[1] == 'primary_font_size' || values[1] == 'secondary_font_size') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					fontSize: theme[values[1]]
				}
				return;
			}
		}
	} else if (className.includes('whitespace-')) {
		const values = className.split('-');
		if (values.length == 2) {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				whiteSpace: values[1]
			}
			return;
		} else if (values.length == 3) {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				whiteSpace: `${values[1]}-${values[2]}`
			}
			return;
		}
	} else if (className.includes('font-')) {
		const value = className.split('-')[1];
		if (value == 'thin') {

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				fontWeight: 100
			}
			return;
		} else if (value == 'extralight') {

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				fontWeight: 200
			}
			return;
		} else if (value == 'light') {

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				fontWeight: 300
			}
			return;
		}
		else if (value == 'normal') {

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				fontWeight: 400
			}
			return;
		}
		else if (value == 'medium') {

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				fontWeight: 500
			}
			return;
		}
		else if (value == 'semibold') {

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				fontWeight: 600
			}
			return;
		}
		else if (value == 'bold') {

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				fontWeight: 700
			}
			return;
		}
		else if (value == 'extrabold') {

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				fontWeight: 800
			}
			return;
		}
		else if (value == 'black') {

			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				fontWeight: 900
			}
			return;
		}
	}  else if (className.includes('opacity-')) {
		const value = className.split('-')[1];
		if (value.includes('[')) {
			const index = value.indexOf(']');
			const padding = value.slice(1, index).trim();
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				opacity: padding
			}
			return;
		} else {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				opacity: (value / 100)
			}
			return;
		}
	} else if (className.includes('cursor-')) {
		const value = className.slice((className.indexOf('-') + 1));
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			cursor: value
		}
		return;
	} else if (className.includes('grid-cols-')) {
		const value = className.split('-')[2];
		if (value != 'subgrid' && !value.includes('[')) {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				gridTemplateColumns: value
			}
			return;
		}

	} else if (className.includes('grid-rows-')) {
		const value = className.split('-')[2];
		if (value != 'subgrid' && !value.includes('[')) {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				gridTemplateRows: value


			}
			return;
		}

	} else if (className.includes('justify-self-')) {
		const value = className.split('-')[2];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			justifySelf: value
		}
		return;

	} else if (className.includes('justify-items-')) {
		const value = className.split('-')[2];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			justifyItems: value
		}
		return;

	} else if (className.includes('grid-flow-')) {
		const values = className.split('-');
		if (values.length == 3) {
			if (values[2] == 'col') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					gridAutoFlow: 'column'
				}
				return;
			} else {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					gridAutoFlow: values[2]
				}
				return;
			}
		} else if (values.length == 4) {
			if (values[2] == 'col') {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					gridAutoFlow: 'column dense'
				}
				return;
			} else {
				styleObject[breakpointKey][stateKey] = {
					...styleObject[breakpointKey][stateKey],
					gridAutoFlow: 'row dense'
				}
				return;
			}
		}

	} else if (className.includes('col-')) {
		const values = className.split('-');
		if (values[1] == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				gridColumn: 'auto'
			}
			return;
		} else if (values[2] == 'span') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				gridColumn: values[2]
			}
			return;
		} else if (values[2] == 'start') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				gridColumnStart: values[2]
			}
			return;
		} else if (values[2] == 'end') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				gridColumnEnd: values[2]
			}
			return;
		}
	} else if (className.includes('row-')) {
		const values = className.split('-');
		if (values[1] == 'auto') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				gridRow: 'auto'
			}
			return;
		} else if (values[2] == 'span') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				gridRow: values[2]
			}
			return;
		} else if (values[2] == 'start') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				gridRowStart: values[2]
			}
			return;
		} else if (values[2] == 'end') {
			styleObject[breakpointKey][stateKey] = {
				...styleObject[breakpointKey][stateKey],
				gridRowEnd: values[2]
			}
			return;
		}
	} else if (className.includes('animated-')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			animation: value
		}
		return;
	}
	else if (className.includes('list-decimal')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			listStyle: value
		}
		return;
	}
	else if (className.includes('letter-spacing')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			letterSpacing: value
		}
		return;
	}
	else if (className.includes('line-height')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			lineHeight: value
		}
		return;
	}
	else if (className.includes('blur')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			blur: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('contrast')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			contrast: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('grayscale')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			grayscale: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('hue-rotate')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			hueRotate: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('invert')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			invert: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('saturate')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			saturate: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('sepia')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			sepia: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('backdrop-blur')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			backdropBlur: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('backdrop-contrast')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			backdropContrast: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('backdrop-grayscale')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			backdropGrayscale: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('backdrop-hue-rotate')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			backdropHueRotate: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('backdrop-invert')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			backdropInvert: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('backdrop-opacity')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			backdropOpacity: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('backdrop-saturate')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			backdropSaturate: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
	else if (className.includes('backdrop-sepia')) {
		const value = className.split('-')[1];
		styleObject[breakpointKey][stateKey] = {
			...styleObject[breakpointKey][stateKey],
			backdropSepia: value?.split("[")?.[1]?.split("]")?.[0]
		}
		return;
	}
}
const classesToStyleObjectConvertor = (id, classesArray, elementStyleObj, theme) => {
	let computedStyles;
	let parentComputedStyles;
	if (document.getElementById(id)) {
		computedStyles = getComputedStyle(document.getElementById(id));
		parentComputedStyles = getComputedStyle(document.getElementById(id)?.parentElement);
	}
	let styleObject = {
		base: {
			normal: {
				display: computedStyles?.display,
				flexDirection: 'row',
				flexWrap: "nowrap",
				justifyContent: 'flex-start',
				alignItems: "stretch",
				alignContent: "normal",
				columnGap: '0px',
				rowGap: "0px",
				flexChild: (parentComputedStyles?.display == 'flex' || parentComputedStyles?.display == 'inline-flex') ? true : false,
				gridChild: (parentComputedStyles?.display == 'grid' || parentComputedStyles?.display == 'inline-grid') ? true : false,
				alignSelf: 'auto',
				flexGrow: 0,
				flexShrink: 1,
				flexBasis: 'auto',
				justifyItems: "stretch",
				justifySelf: "auto",
				gridAutoFlow: 'row',
				width: 'auto',
				maxWidth: 'none',
				minWidth: '0px',
				maxHeight: 'none',
				minHeight: '0px',
				height: 'auto',
				padding: '0px',
				margin: '0px',
				borderWidth: '0px',
				borderStyle: "solid",
				borderColor: "#e5e7eb",
				borderRadius: '0px',
				outlineWidth: '3px',
				outlineStyle: "none",
				outlineColor: "black",
				outlineOffset: '0px',
				objectFit: "fill",
				position: "static",
				zIndex: "auto",
				left: 'auto',
				top: 'auto',
				right: 'auto',
				bottom: 'auto',
				overflow: 'visible',
				overflowWrap: 'normal',
				color: computedStyles?.color.replace(/[ ]/g, ""),
				fontSize: computedStyles?.fontSize,
				textOverflow: 'clip',
				whiteSpace: computedStyles?.whiteSpace,
				textTransform: computedStyles?.textTransform,
				fontStyle: computedStyles?.fontStyle,
				fontFamily: computedStyles?.fontFamily,
				fontWeight: computedStyles?.fontWeight,
				textDecorationLine: "none",
				textAlign: computedStyles?.textAlign,
				backgroundColor: 'white',
				backgroundSize: 'auto',
				backgroundPosition: 'left top',
				backgroundRepeat: 'repeat',
				backgroundImage: 'none',
				fromColor: 'rgba(255,255,255,1)',
				fromPercent: '50%',
				// viaColor: 'rgba(255,255,255,1)',
				// viaPercent: '0%',
				toPercent: '50%',
				toColor: 'rgba(255,255,255,1)',
				opacity: '1',
				cursor: computedStyles?.cursor,
				gridTemplateColumns: 'none',
				gridTemplateRows: 'none',
				gridColumn: 'auto',
				gridRow: 'auto',
				gridColumnStart: 'auto',
				gridColumnEnd: 'auto',
				gridRowStart: 'auto',
				gridRowEnd: 'auto',
				animation: "none",
				listStyle: "none",
				blur: "0px",
				textDecoration: "solid",
				lineThickness: "auto",
				textUnderlineOffset: "auto",
				letterSpacing: '0px',
				lineHeight: 'none',
				textIndent: '0px',
				lineStyleType: "none",
				listStylePosition: "inside",
				listStyleImage: 'none',
				objectPosition: 'left-top',


			},
			hover: {},
			focus: {},
			active: {},
			visited: {},
			placeholder: {},
			file: {},
			styleDirectChild: {}
		},
		xs: {
			normal: {},
			hover: {},
			focus: {},
			active: {},
			visited: {},
			placeholder: {},
			file: {},
			styleDirectChild: {}
		},
		sm: {
			normal: {},
			hover: {},
			focus: {},
			active: {},
			visited: {},
			placeholder: {},
			file: {},
			styleDirectChild: {}
		},
		md: {
			normal: {},
			hover: {},
			focus: {},
			active: {},
			visited: {},
			placeholder: {},
			file: {},
			styleDirectChild: {}
		},
		lg: {
			normal: {},
			hover: {},
			focus: {},
			active: {},
			visited: {},
			placeholder: {},
			file: {},
			styleDirectChild: {}
		},
		xl: {
			normal: {},
			hover: {},
			focus: {},
			active: {},
			visited: {},
			placeholder: {},
			file: {},
			styleDirectChild: {}
		},
		twoXl: {
			normal: {},
			hover: {},
			focus: {},
			active: {},
			visited: {},
			placeholder: {},
			file: {},
			styleDirectChild: {}
		}
	};

	if (elementStyleObj?.fontFamily) {
		styleObject.base.normal.fontFamily = elementStyleObj.fontFamily;
	}
	for (let i = 0; i < classesArray?.length; i++) {
		if (classesArray[i].trim() != '') {
			const className = classesArray[i].trim();
			if (className.slice(0, 10) == '@xs:hover:') {
				enterValueInStyleObject(styleObject, className, 'xs', '@xs:', theme, 'hover', 'hover:');
			} else if (className.slice(0, 10) == '@xs:focus:') {
				enterValueInStyleObject(styleObject, className, 'xs', '@xs:', theme, 'focus', 'focus:');
			} else if (className.slice(0, 11) == '@xs:active:') {
				enterValueInStyleObject(styleObject, className, 'xs', '@xs:', theme, 'active', 'active:');
			} else if (className.slice(0, 12) == '@xs:visited:') {
				enterValueInStyleObject(styleObject, className, 'xs', '@xs:', theme, 'visited', 'visited:');
			} else if (className.slice(0, 16) == '@xs:placeholder:') {
				enterValueInStyleObject(styleObject, className, 'xs', '@xs:', theme, 'placeholder', 'placeholder:');
			} else if (className.slice(0, 9) == '@xs:file:') {
				enterValueInStyleObject(styleObject, className, 'xs', '@xs:', theme, 'file', 'file:');
			} else if (className.slice(0, 6) == '@xs:*:') {
				enterValueInStyleObject(styleObject, className, 'xs', '@xs:', theme, 'styleDirectChild', '*:');
			} else if (className.slice(0, 4) == '@xs:') {
				enterValueInStyleObject(styleObject, className, 'xs', '@xs:', theme, 'normal', '');
			} else if (className.slice(0, 10) == '@sm:hover:') {
				enterValueInStyleObject(styleObject, className, 'sm', '@sm:', theme, 'hover', 'hover:');
			} else if (className.slice(0, 10) == '@sm:focus:') {
				enterValueInStyleObject(styleObject, className, 'sm', '@sm:', theme, 'focus', 'focus:');
			} else if (className.slice(0, 11) == '@sm:active:') {
				enterValueInStyleObject(styleObject, className, 'sm', '@sm:', theme, 'active', 'active:');
			} else if (className.slice(0, 12) == '@sm:visited:') {
				enterValueInStyleObject(styleObject, className, 'sm', '@sm:', theme, 'visited', 'visited:');
			} else if (className.slice(0, 16) == '@sm:placeholder:') {
				enterValueInStyleObject(styleObject, className, 'sm', '@sm:', theme, 'placeholder', 'placeholder:');
			} else if (className.slice(0, 9) == '@sm:file:') {
				enterValueInStyleObject(styleObject, className, 'sm', '@sm:', theme, 'file', 'file:');
			} else if (className.slice(0, 6) == '@sm:*:') {
				enterValueInStyleObject(styleObject, className, 'sm', '@sm:', theme, 'styleDirectChild', '*:');
			} else if (className.slice(0, 4) == '@sm:') {
				enterValueInStyleObject(styleObject, className, 'sm', '@sm:', theme, 'normal', '');
			} else if (className.slice(0, 10) == '@md:hover:') {
				enterValueInStyleObject(styleObject, className, 'md', '@md:', theme, 'hover', 'hover:');
			} else if (className.slice(0, 10) == '@md:focus:') {
				enterValueInStyleObject(styleObject, className, 'md', '@md:', theme, 'focus', 'focus:');
			} else if (className.slice(0, 11) == '@md:active:') {
				enterValueInStyleObject(styleObject, className, 'md', '@md:', theme, 'active', 'active:');
			} else if (className.slice(0, 12) == '@md:visited:') {
				enterValueInStyleObject(styleObject, className, 'md', '@md:', theme, 'visited', 'visited:');
			} else if (className.slice(0, 16) == '@md:placeholder:') {
				enterValueInStyleObject(styleObject, className, 'md', '@md:', theme, 'placeholder', 'placeholder:');
			} else if (className.slice(0, 9) == '@md:file:') {
				enterValueInStyleObject(styleObject, className, 'md', '@md:', theme, 'file', 'file:');
			} else if (className.slice(0, 6) == '@md:*:') {
				enterValueInStyleObject(styleObject, className, 'md', '@md:', theme, 'styleDirectChild', '*:');
			} else if (className.slice(0, 4) == '@md:') {
				enterValueInStyleObject(styleObject, className, 'md', '@md:', theme, 'normal', '');
			} else if (className.slice(0, 10) == '@lg:hover:') {
				enterValueInStyleObject(styleObject, className, 'lg', '@lg:', theme, 'hover', 'hover:');
			} else if (className.slice(0, 10) == '@lg:focus:') {
				enterValueInStyleObject(styleObject, className, 'lg', '@lg:', theme, 'focus', 'focus:');
			} else if (className.slice(0, 11) == '@lg:active:') {
				enterValueInStyleObject(styleObject, className, 'lg', '@lg:', theme, 'active', 'active:');
			} else if (className.slice(0, 12) == '@lg:visited:') {
				enterValueInStyleObject(styleObject, className, 'lg', '@lg:', theme, 'visited', 'visited:');
			} else if (className.slice(0, 16) == '@lg:placeholder:') {
				enterValueInStyleObject(styleObject, className, 'lg', '@lg:', theme, 'placeholder', 'placeholder:');
			} else if (className.slice(0, 9) == '@lg:file:') {
				enterValueInStyleObject(styleObject, className, 'lg', '@lg:', theme, 'file', 'file:');
			} else if (className.slice(0, 6) == '@lg:*:') {
				enterValueInStyleObject(styleObject, className, 'lg', '@lg:', theme, 'styleDirectChild', '*:');
			} else if (className.slice(0, 4) == '@lg:') {
				enterValueInStyleObject(styleObject, className, 'lg', '@lg:', theme, 'normal', '');
			} else if (className.slice(0, 10) == '@xl:hover:') {
				enterValueInStyleObject(styleObject, className, 'xl', '@xl:', theme, 'hover', 'hover:');
			} else if (className.slice(0, 10) == '@xl:focus:') {
				enterValueInStyleObject(styleObject, className, 'xl', '@xl:', theme, 'focus', 'focus:');
			} else if (className.slice(0, 11) == '@xl:active:') {
				enterValueInStyleObject(styleObject, className, 'xl', '@xl:', theme, 'active', 'active:');
			} else if (className.slice(0, 12) == '@xl:visited:') {
				enterValueInStyleObject(styleObject, className, 'xl', '@xl:', theme, 'visited', 'visited:');
			} else if (className.slice(0, 16) == '@xl:placeholder:') {
				enterValueInStyleObject(styleObject, className, 'xl', '@xl:', theme, 'placeholder', 'placeholder:');
			} else if (className.slice(0, 9) == '@xl:file:') {
				enterValueInStyleObject(styleObject, className, 'xl', '@xl:', theme, 'file', 'file:');
			} else if (className.slice(0, 6) == '@xl:*:') {
				enterValueInStyleObject(styleObject, className, 'xl', '@xl:', theme, 'styleDirectChild', '*:');
			} else if (className.slice(0, 4) == '@xl:') {
				enterValueInStyleObject(styleObject, className, 'xl', '@xl:', theme, 'normal', '');
			} else if (className.slice(0, 11) == '@2xl:hover:') {
				enterValueInStyleObject(styleObject, className, 'twoXl', '@2xl:', theme, 'hover', 'hover:');
			} else if (className.slice(0, 11) == '@2xl:focus:') {
				enterValueInStyleObject(styleObject, className, 'twoXl', '@2xl:', theme, 'focus', 'focus:');
			} else if (className.slice(0, 12) == '@2xl:active:') {
				enterValueInStyleObject(styleObject, className, 'twoXl', '@2xl:', theme, 'active', 'active:');
			} else if (className.slice(0, 13) == '@2xl:visited:') {
				enterValueInStyleObject(styleObject, className, 'twoXl', '@2xl:', theme, 'visited', 'visited:');
			} else if (className.slice(0, 17) == '@2xl:placeholder:') {
				enterValueInStyleObject(styleObject, className, 'twoXl', '@2xl:', theme, 'placeholder', 'placeholder:');
			} else if (className.slice(0, 10) == '@2xl:file:') {
				enterValueInStyleObject(styleObject, className, 'twoXl', '@2xl:', theme, 'file', 'file:');
			} else if (className.slice(0, 7) == '@2xl:*:') {
				enterValueInStyleObject(styleObject, className, 'twoXl', '@2xl:', theme, 'styleDirectChild', '*:');
			} else if (className.slice(0, 5) == '@2xl:') {
				enterValueInStyleObject(styleObject, className, 'twoXl', '@2xl:', theme, 'normal', '');
			} else if (className.slice(0, 6) == 'hover:') {
				enterValueInStyleObject(styleObject, className, 'base', '', theme, 'hover', 'hover:');
			} else if (className.slice(0, 6) == 'focus:') {
				enterValueInStyleObject(styleObject, className, 'base', '', theme, 'focus', 'focus:');
			} else if (className.slice(0, 7) == 'active:') {
				enterValueInStyleObject(styleObject, className, 'base', '', theme, 'active', 'active:');
			} else if (className.slice(0, 8) == 'visited:') {
				enterValueInStyleObject(styleObject, className, 'base', '', theme, 'visited', 'visited:');
			} else if (className.slice(0, 12) == 'placeholder:') {
				enterValueInStyleObject(styleObject, className, 'base', '', theme, 'placeholder', 'placeholder:');
			} else if (className.slice(0, 5) == 'file:') {
				enterValueInStyleObject(styleObject, className, 'base', '', theme, 'file', 'file:');
			} else if (className.slice(0, 2) == '*:') {
				enterValueInStyleObject(styleObject, className, 'base', '', theme, 'styleDirectChild', '*:');
			} else {
				enterValueInStyleObject(styleObject, className, 'base', '', theme, 'normal', '');
			}

		}
	}
	return styleObject;
}
export default classesToStyleObjectConvertor;