import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import { useNavigate } from 'react-router-dom';

import { BlockWrapper } from '../Sortable';
import ReactPlayer from 'react-player';
import { PlayCircleIcon } from '@heroicons/react/24/solid';
import { createElement } from 'react';
//@ts-ignore

function Icon({ style, name }) {
  return (
    <span
      //@ts-ignore
      icon="true"
      name={name.replace('mdi mdi-', '')}
      source="MaterialCommunityIcons"
      className={`${name} ${style} flex items-center`}
    ></span>
  );
}

const responsive = (item) => {
  return {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: item.largeDesktop,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: item.desktop,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: item.tablet,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: item.mobile,
    },
  };
};

const CustomDot = ({ onClick, active }) => {
  return (
    <button type='button'
      onClick={() => onClick()}
      className={` w-4 h-4 mx-1 rounded-full ${active ? 'bg-[#7DB800]' : 'bg-gray-800'
        }`}
    ></button>
  );
};

const CommonDots = ({
  onClick,
  active,
  activeCSS = '',
  inactiveCSS = '',
  commonCSS = '',
}) => {
  return (
    <button type='button'
      onClick={() => onClick()}
      className={` @xs:hidden @sm:block ${commonCSS} ${active ? activeCSS : inactiveCSS
        }`}
    ></button>
  );
};

const CustomLeftArrow = ({ onClick }) => {
  return (
    <button type='button' onClick={() => onClick()} className=" absolute left-0">
      <Icon name="mdi mdi-arrow-left" style="text-2xl" />
    </button>
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <button type='button' onClick={() => onClick()} className="absolute right-0">
      <Icon name="mdi mdi-arrow-right" style="text-2xl" />
    </button>
  );
};

const Renderer = ({ schema, currentTheme }) => {

  return (
    createElement(
      schema.type,
      {
        id: schema.id,
        className: schema.css?.replaceAll("secondary_color", `[${currentTheme?.secondary_color}]`)
          ?.replaceAll("bg_color", `[${currentTheme?.bg_color}]`)
          ?.replaceAll("textColor", `[${currentTheme?.textColor}]`)
          ?.replaceAll("primary_color", `[${currentTheme?.primary_color}]`)
          ?.replaceAll("primary_font_size", `[${currentTheme?.primary_font_size}]`)
          ?.replaceAll("secondary_font_size", `[${currentTheme?.secondary_font_size}]`)
          ?.replaceAll("font_size", `[${currentTheme?.font_size}]`),
        ...schema.data,
        onClick: () => {
          if (schema.events?.onClick) {
            eval(schema.events.onClick);
          } else {
            return null;
          }
        },
        style: {
          fontFamily: schema.style?.fontFamily
        }
      },
      schema.children ? schema.children.map((value, index) => {
        return (
          <Renderer schema={value} currentTheme={currentTheme} key={index} />
        )
      }) : schema.content
    )
  )
}


const CommonLeftArrow = ({ onClick, buttonCSS, children, currentTheme }) => {
  return (
    <button type='button' onClick={() => onClick()} className={buttonCSS}>
      {children &&
        children?.map((childBlock, i) => {
          return <Renderer key={childBlock.id} schema={childBlock} currentTheme={currentTheme} />;
        })}
    </button>
  );
};

const CommonRightArrow = ({ onClick, buttonCSS, children, currentTheme }) => {
  return (
    <button type='button' onClick={() => onClick()} className={buttonCSS}>
      {children &&
        children?.map((childBlock, i) => {
          return <Renderer key={childBlock.id} schema={childBlock} currentTheme={currentTheme} />;
        })}
    </button>
  );
};

export default function ProductImageSlider({ block, data, handleMouseOver, handleClick, blockIndex, currentTheme }) {
  const [processedData, setProcessedData] = useState(
    Array.isArray(data?.[block?.data?.query?.type]) ? data : []
  );
  const [showImage, setShowImage] = useState(
    data?.[block?.data?.query?.type]?.[0]
  );
  const [renderBlock, setRenderBlock] = useState(block?.children?.[0]);
  useEffect(() => {

    if (Array.isArray(data?.images)) {
      console.log("this is data Images", data?.images);
      if (data?.video?.length > 0) {
        const thumbnails = data?.video?.map((item) => item?.thumbnail) ?? []; // Extract thumbnails from each video object
        setProcessedData([...data?.images, ...thumbnails]); // Concatenate thumbnails with images
      } else {
        setProcessedData(data?.images);
      }
    } else {
      setProcessedData([data?.[block?.data?.query?.type]]);
    }
  }, [data]);

  useEffect(() => {
    setShowImage(processedData[0]);
    console.log("this is processedData", processedData[0])
  }, [processedData]);

  const response = responsive(
    block?.data?.responsive
      ? block.data.responsive
      : {
        largeDesktop: 1,
        desktop: 1,
        tablet: 1,
        mobile: 1,
      }
  );
  console.log(processedData, data, " chimpanji babu")
  return (
    <>
      {processedData.indexOf(showImage) <= ((processedData?.length - 1) - data?.video?.length) ? (
        <>

          <BlockWrapper block={renderBlock} data={showImage} blockIndex={[...blockIndex, 0]} />
        </>
      ) : data?.video?.length > 0 ? (
        <div className={renderBlock?.css}>
          <ReactPlayer
            width="100%"
            height="100%"
            url={data?.video?.[(processedData.indexOf(showImage) - (data?.images?.length - 1)) - 1]?.url}
            controls={true}
            playing={true}
            muted={false}
          />
        </div>
      ) : (
        <>
          {console.log("this is block")}
          <BlockWrapper block={renderBlock} data={showImage} blockIndex={[...blockIndex, 0]} />
        </>
      )}
      <div id={block?.id}
        className='harsh'
        onClick={(e) => {
          handleClick(e, block?.id);
        }}
        onMouseOver={(e) => {
          handleMouseOver(e);
        }}>

        <Carousel
          ssr
          containerClass="carousel-container"
          // containerClass="w-full"
          dotListClass=""
          arrows={block?.data?.showArrows}
          responsive={response}
          centerMode={false}
          className={block?.css}
          itemClass={block?.data?.itemClass}
          draggable
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots={block?.data?.showDots}
          sliderClass=""
          slidesToSlide={1}
          swipeable
          customRightArrow={
            block?.data?.CommonRightArrow ? (
              <CommonRightArrow
                buttonCSS={block?.data?.CommonRightArrow?.buttonCSS}
                children={block?.data?.CommonRightArrow?.children}
                blockIndex={blockIndex}
                currentTheme={currentTheme}
              />
            ) : (
              <CustomRightArrow />
            )
          }
          customLeftArrow={
            block?.data?.CommonLeftArrow ? (
              <CommonLeftArrow
                buttonCSS={block?.data?.CommonLeftArrow?.buttonCSS}
                children={block?.data?.CommonLeftArrow?.children}
                blockIndex={blockIndex}
                currentTheme={currentTheme}
              />
            ) : (
              <CustomLeftArrow />
            )
          }
          customDot={
            block?.data?.customDot ? (
              <CommonDots
                activeCSS={block?.data?.customDot?.activeCSS}
                inactiveCSS={block?.data?.customDot?.inactiveCSS}
                commonCSS={block?.data?.customDot?.commonCSS}
              />
            ) : (
              <CustomDot />
            )
          }
        >
          {processedData ? (
            processedData?.map((image, i) => (
              <>
                {block?.children &&
                  block?.children?.map((childBlock) => (
                    <button type='button'
                      onClick={() => {
                        setShowImage(image);
                        setRenderBlock(childBlock);
                      }}
                      className="relative"
                    >
                      <BlockWrapper block={childBlock} data={image} blockIndex={[...blockIndex, i]} />
                      {processedData.indexOf(image) >
                        ((processedData?.length - 1) - data?.video?.length) ? (
                        <div className='absolute top-0 w-full h-full flex items-center justify-center bg-black bg-opacity-40'>
                          <PlayCircleIcon className="w-10- h-10 text-red-600" />
                        </div>
                      ) : null}
                    </button>
                  ))}
              </>
            ))
          ) : (
            <p>No Image Available</p>
          )}
        </Carousel>
      </div>

    </>
  );
}
