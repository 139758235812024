import { useContext, useState } from "react";
import { context } from "../../../context/Context";
import { Formik } from "formik";
import { Switch } from '@headlessui/react';
import { AddToUndo } from "../../../screens/account/editor";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
const dotsData = [
	{
		url: "https://cdn.qafto.com/common/dots.png",
		schema: {

			activeCSS: "bg-red-600",
			inactiveCSS: "bg-transparent",
			commonCSS: "w-4 h-4 mr-[6px] border-2 border-[#808080] rounded-[50%]"
		}
	},
	{
		url: "https://cdn.qafto.com/common/dots.png",
		schema: {

			activeCSS: "bg-green-600",
			inactiveCSS: "bg-transparent",
			commonCSS: "w-4 h-4 mr-[6px] border-2 border-[#808080] rounded-[50%]"
		}
	},
	{
		url: "https://cdn.qafto.com/common/dots.png",
		schema: {

			activeCSS: "bg-indigo-600",
			inactiveCSS: "bg-transparent",
			commonCSS: "w-4 h-4 mr-[6px] border-2 border-[#808080] rounded-[50%]"
		}
	},
	{
		url: "https://cdn.qafto.com/common/dots.png",
		schema: {

			activeCSS: "bg-yellow-600",
			inactiveCSS: "bg-transparent",
			commonCSS: "w-4 h-4 mr-[6px] border-2 border-[#808080] rounded-[50%]"
		}
	}

];
const leftArrowsData = [
	{
		url: "https://cdn.qafto.com/common/leftArrow.png",
		schema: {
			"type": "button",
			"children": [
				{
					"type": "span",
					"css": "mdi mdi-arrow-left text-2xl"
				}
			],
			"css": "absolute left-0"
		}
	},
	{
		url: "https://cdn.qafto.com/common/leftArrow.png",
		schema: {
			"type": "button",
			"children": [
				{
					"type": "span",
					"css": "mdi mdi-arrow-left text-2xl text-red-600"
				}
			],
			"css": "absolute left-0"
		}
	},
	{
		url: "https://cdn.qafto.com/common/leftArrow.png",
		schema: {
			"type": "button",
			"children": [
				{
					"type": "span",
					"css": "mdi mdi-arrow-left text-2xl text-green-600"
				}
			],
			"css": "absolute left-0"
		}
	},
	{
		url: "https://cdn.qafto.com/common/leftArrow.png",
		schema: {
			"type": "button",
			"children": [
				{
					"type": "span",
					"css": "mdi mdi-arrow-left text-2xl text-yellow-600"
				}
			],
			"css": "absolute left-0"
		}
	}

];
const rightArrowsData = [
	{
		url: "https://cdn.qafto.com/common/rightArrow.png",
		schema: {
			"type": "button",
			"children": [
				{
					"type": "span",
					"css": "mdi mdi-arrow-right text-2xl text-indigo-600"
				}
			],
			"css": "absolute right-0"
		}
	},
	{
		url: "https://cdn.qafto.com/common/rightArrow.png",
		schema: {
			"type": "button",
			"children": [
				{
					"type": "span",
					"css": "mdi mdi-arrow-right text-2xl text-blue-600"
				}
			],
			"css": "absolute right-0"
		}
	},
	{
		url: "https://cdn.qafto.com/common/rightArrow.png",
		schema: {
			"type": "button",
			"children": [
				{
					"type": "span",
					"css": "mdi mdi-arrow-right text-2xl text-gray-600"
				}
			],
			"css": "absolute right-0"
		}
	},
	{
		url: "https://cdn.qafto.com/common/rightArrow.png",
		schema: {
			"type": "button",
			"children": [
				{
					"type": "span",
					"css": "mdi mdi-arrow-right text-2xl"
				}
			],
			"css": "absolute right-0"
		}
	}

];
function getRndInteger(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}
const giveRandomID = (schema) => {
	const randomString = getRndInteger(10000, 99999);
	const id = `qafto_${Date.now()}${randomString}`;
	schema.id = id;
	if (schema.children) {
		for (let i = 0; i < schema.children.length; i++) {
			giveRandomID(schema.children[i]);
		}
	}
}
const Carousel2Settings = () => {
	const [showDialog, setShowDialog] = useState(null);
	const { component, blocks, undo, setUndo, setRedo, strapiSchema, sqliteSchema, selectedSiteData, elementId, Bdispatch } = useContext(context);
	return (
		<div className="space-y-6">
			<button className="text-white font-medium bg-indigo-600 px-2 py-2 rounded-md w-full flex justify-between items-center" onClick={() => {
				const randomString = getRndInteger(10000, 99999);
				const id = `qafto_${Date.now()}${randomString}`;
				component.children = [
					...component.children,
					{
						id: id,
						css: 'w-full h-full flex items-center justify-center border-2',
						type: 'div',
						style: {
						  width: '100%',
						  height: '10vh',
						  display: 'flex',
						  alignItems: 'center',
						  justifyContent: 'center',
						  border: '2px'
						},
						children: [{
						  id: 'jjkiejnskjakjkj',
						  type: 'p',
						  content: 'Dummy text',
						  css: 'text-gray-700',
						  style: {
							color: '#616161'
						  }
						}]
					  }
				];
				AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
			}}>
				<span>Add Slide</span>
				<span class="mdi mdi-plus text-xl"></span>
			</button>
			{
				component.data?.showDots == true &&
				<>
					<button className="text-white font-medium bg-indigo-600 px-2 py-2 rounded-md w-full flex justify-between items-center" onClick={() => {
						setShowDialog('dots');
					}}>
						<span>Change Dots</span>
						<span class="mdi mdi-dots-horizontal text-xl"></span>

					</button>

					<Dialog onClose={() => setShowDialog(null)} open={showDialog == 'dots'} className="fixed inset-0  z-50  bg-gray-500 bg-opacity-75 flex items-center justify-center p-3">

						<Dialog.Panel className="relative rounded-lg bg-white py-8 px-4  shadow-xl sm:w-full sm:max-w-[1000px] sm:px-6" >
							<XMarkIcon className="w-5 h-5 cursor-pointer absolute top-2 right-2 text-gray-700" onClick={() => {
								setShowDialog(null);
							}} />
							<div className="flex items-center flex-wrap justify-center gap-5">

								{
									dotsData.map((value, index) => {

										return (
											<div key={index} className="w-full min-w-[280px] max-w-[300px] border border-gray-300 rounded-md shadow-xl overflow-hidden">
												<img src={value.url} alt="dots image" className="aspect-[4] w-full" />
												<button className="text-white font-medium bg-indigo-600 py-2 w-full text-center flex items-center  justify-center space-x-1" onClick={() => {
													component.data.customDot = value.schema;
													AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
												}}>
													<span className="text-sm">Change</span>
													<span class="mdi mdi-swap-horizontal text-xl"></span>
												</button>
											</div>
										)
									})
								}
							</div>
						</Dialog.Panel>

					</Dialog>
				</>
			}
			{/* {
				component.data?.arrows == true &&
				<>
					<button className="text-white font-medium bg-indigo-600 px-2 py-2 rounded-md w-full flex justify-between items-center" onClick={() => {
						setShowDialog('leftArrow');
					}}>
						<span>Change Left Arrow</span>
						<span class="mdi mdi-arrow-left-thin text-xl"></span>
					</button>
					<Dialog onClose={() => setShowDialog(null)} open={showDialog == 'leftArrow'} className="fixed inset-0  z-50  bg-gray-500 bg-opacity-75 flex items-center justify-center p-3">

						<Dialog.Panel className="relative rounded-lg bg-white px-4 py-8  shadow-xl sm:w-full sm:max-w-[1000px] sm:px-6">
							<XMarkIcon className="w-5 h-5 cursor-pointer absolute top-2 right-2 text-gray-700" onClick={() => {
								setShowDialog(null);
							}} />
							<div className="flex items-center flex-wrap justify-center gap-10">

								{
									leftArrowsData.map((value, index) => {

										return (
											<div key={index} className="w-full min-w-[120px] max-w-[120px] border border-gray-300 rounded-md shadow-xl overflow-hidden">
												<img src={value.url} alt="dots image" className="aspect-1 w-full" />
												<button className="text-white font-medium bg-indigo-600 py-2 w-full text-center flex items-center  justify-center space-x-1" onClick={() => {
													giveRandomID(value.schema);
													component.data.customLeftArrow = value.schema;
													AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
												}}>
													<span className="text-sm">Change</span>
													<span class="mdi mdi-swap-horizontal text-xl"></span>
												</button>
											</div>
										)
									})
								}
							</div>
						</Dialog.Panel>

					</Dialog>
				</>
			}
			{
				component.data?.arrows == true &&
				<>
					<button className="text-white font-medium bg-indigo-600 px-2 py-2 rounded-md w-full flex justify-between items-center" onClick={() => {
						setShowDialog('rightArrow');
					}}>
						<span>Change Right Arrow</span>
						<span class="mdi mdi-arrow-right-thin text-xl"></span>
					</button>
					<Dialog onClose={() => setShowDialog(null)} open={showDialog == 'rightArrow'} className="fixed inset-0  z-50  bg-gray-500 bg-opacity-75 flex items-center justify-center p-3">

						<Dialog.Panel className="relative rounded-lg bg-white px-4 py-8  shadow-xl sm:w-full sm:max-w-[1000px] sm:px-6">
							<XMarkIcon className="w-5 h-5 cursor-pointer absolute top-2 right-2 text-gray-700" onClick={() => {
								setShowDialog(null);
							}} />
							<div className="flex items-center flex-wrap justify-center gap-10">

								{
									rightArrowsData.map((value, index) => {

										return (
											<div key={index} className="w-full min-w-[120px] max-w-[120px] border border-gray-300 rounded-md shadow-xl overflow-hidden">
												<img src={value.url} alt="dots image" className="aspect-1 w-full" />
												<button className="text-white font-medium bg-indigo-600 py-2 w-full text-center flex items-center  justify-center space-x-1" onClick={() => {
													giveRandomID(value.schema);
													component.data.customRightArrow = value.schema;
													AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
												}}>
													<span className="text-sm">Change</span>
													<span class="mdi mdi-swap-horizontal text-xl"></span>
												</button>
											</div>
										)
									})
								}
							</div>
						</Dialog.Panel>

					</Dialog>
				</>
			} */}
			<Formik
				enableReinitialize={true}
				initialValues={{
					draggable: (component.data?.draggable != null || component.data?.draggable != undefined) ? component.data.draggable : true,
					arrows: (component.data?.arrows != null || component.data?.arrows != undefined) ? component.data.arrows : true,
					showDots: (component.data?.showDots != null || component.data?.showDots != undefined) ? component.data.showDots : false,
					swipeable: (component.data?.swipeable != null || component.data?.swipeable != undefined) ? component.data.swipeable : true,
					infinite: (component.data?.infinite != null || component.data?.infinite != undefined) ? component.data.infinite : false,
					autoPlay: (component.data?.autoPlay != null || component.data?.autoPlay != undefined) ? component.data.autoPlay : false,
					rewind: (component.data?.rewind != null || component.data?.rewind != undefined) ? component.data.rewind : false,
					autoPlaySpeed: (component.data?.autoPlaySpeed != null || component.data?.autoPlaySpeed != undefined) ? component.data.autoPlaySpeed : 3000,
					removeArrowOnDeviceType: (component.data?.removeArrowOnDeviceType != null || component.data?.removeArrowOnDeviceType != undefined) ? component.data.removeArrowOnDeviceType : [],
					responsive: (component.data?.responsive != null || component.data?.responsive != undefined) ? component.data.responsive : {
						"base": {
							items: 1,
							slidesToSlide: 1
						},
						"xs": {
							items: 1,
							slidesToSlide: 1
						},
						"sm": {
							items: 1,
							slidesToSlide: 1
						},
						"md": {
							items: 1,
							slidesToSlide: 1
						},
						"lg": {
							items: 1,
							slidesToSlide: 1
						},
						"xl": {
							items: 1,
							slidesToSlide: 1
						},
						"2xl": {
							items: 1,
							slidesToSlide: 1
						}
					}

				}}
				onSubmit={async (values) => {
					await Bdispatch(
						{
							type: "CHANGE_ELEMENT_SCHEMA",
							id: component.id,
							element:
								values,
						}
					);
					AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
				}}
			>
				{
					(formik) => (
						<form onSubmit={formik.handleSubmit} className="space-y-5">
							{
								[
									{
										displayName: "Draggable",
										propertyName: "draggable"
									},
									{
										displayName: "Swipeable",
										propertyName: "swipeable"
									},
									{
										displayName: "Infinite",
										propertyName: "infinite"
									},
									{
										displayName: "Autoplay",
										propertyName: "autoPlay"
									},
									{
										displayName: "Rewind",
										propertyName: "rewind"
									},
									{
										displayName: "Show Arrows",
										propertyName: "arrows"
									},
									{
										displayName: "Show Dots",
										propertyName: "showDots"
									},
								].map((value, index) => {

									return (
										<div className="flex justify-between" key={index}>
											<div className="text-sm font-medium">{value.displayName}</div>
											<div>
												<Switch checked={formik.values[value.propertyName]} onChange={(val) => {
													formik.setFieldValue(value.propertyName, val)
												}} >
													{({ checked }) => (
														<button
															className={`${checked ? 'bg-indigo-600' : 'bg-gray-200'
																} relative inline-flex h-6 w-11 items-center rounded-full`}
														>
															<span
																className={`${checked ? 'translate-x-6' : 'translate-x-1'
																	} inline-block h-4 w-4 transform rounded-full bg-white transition`}
															/>
														</button>
													)}
												</Switch>
											</div>
										</div>
									)
								})
							}


							<div className="flex justify-between items-center">
								<div className="text-sm font-medium">Autoplay Speed (ms)</div>
								<input type="number" name="autoPlaySpeed" id="autoPlaySpeed" value={formik.values.autoPlaySpeed} onChange={formik.handleChange} onBlur={formik.handleBlur} className="w-24 border border-gray-300 rounded-md py-1 text-sm focus:outline-none focus:border-indigo-600 focus:border-2 focus:ring-0" />
							</div>

							<div className="space-y-2">
								<div className="text-sm font-medium">Remove arrow on devices with screen size</div>
								<div className="space-y-2">
									{
										[
											{
												displayName: 'Base (0 - 420)',
												propertyName: "base"
											},
											{
												displayName: 'XS (420 - 640)',
												propertyName: "xs"
											},
											{
												displayName: 'SM (640 - 768)',
												propertyName: "sm"
											},
											{
												displayName: 'MD (768 - 1024)',
												propertyName: "md"
											},
											{
												displayName: 'LG (1024 - 1280)',
												propertyName: "lg"
											},
											{
												displayName: 'XL (1280 - 1536)',
												propertyName: "xl"
											},
											{
												displayName: '2XL (1536 - 9999)',
												propertyName: "2xl"
											},
										].map((value, index) => {
											return (
												<div className='space-x-2' key={index}>
													<input type='checkbox' name='removeArrowOnDeviceType' value={value.propertyName} id={value.propertyName} className='text-indigo-600 focus:ring-0' checked={formik.values.removeArrowOnDeviceType?.includes(value.propertyName)} onChange={formik.handleChange} />
													<label htmlFor={value.propertyName} className="text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
														{value.displayName}
													</label>
												</div>
											)
										})
									}

								</div>

							</div>

							<div className="space-y-2">
								<div className="text-sm font-medium">Number of slides to show on devices with the following sizes</div>
								<div className="space-y-2">
									{
										[
											{
												displayName: 'Base (0 - 420)',
												propertyName: "base"
											},
											{
												displayName: 'XS (420 - 640)',
												propertyName: "xs"
											},
											{
												displayName: 'SM (640 - 768)',
												propertyName: "sm"
											},
											{
												displayName: 'MD (768 - 1024)',
												propertyName: "md"
											},
											{
												displayName: 'LG (1024 - 1280)',
												propertyName: "lg"
											},
											{
												displayName: 'XL (1280 - 1536)',
												propertyName: "xl"
											},
											{
												displayName: '2XL (1536 - 9999)',
												propertyName: "2xl"
											},
										].map((value, index) => {
											return (
												<div className="flex justify-between items-center" key={index}>
													<div className="text-sm font-medium">{value.displayName}</div>
													<input type="number" value={formik.values.responsive[value.propertyName].items} onChange={(e) => {
														formik.setFieldValue('responsive', {
															...formik.values.responsive,
															[value.propertyName]: {
																...formik.values.responsive[value.propertyName],
																items: e.target.value
															}
														});
													}} className="w-24 border border-gray-300 rounded-md py-1 text-sm focus:outline-none focus:border-indigo-600 focus:border-2 focus:ring-0" />
												</div>
											)
										})
									}

								</div>

							</div>

							<button type="submit"
								disabled={formik.isSubmitting}
								className="bg-indigo-600 px-3 py-2 font-medium text-sm rounded-md text-white w-full"
							>
								Save
							</button>
						</form>
					)
				}
			</Formik>
		</div>
	)
}
export default Carousel2Settings;