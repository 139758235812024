import React from 'react'

export default function Map({ block }) {
	console.log(block)
    return (
        <div className={`${block.css} p-2`}>
            {
                [...new Array(6)].map((value, i) => {
                    return block.children.map((c) => {return <Method block={c}/>}) 
                })
            }
        </div>
    )
}

const Method = ({block})=>{
	if (block.children) {
		return (
			<>
				{
					React.createElement(
						block?.type,
						{
							key: block.id,
							id: block?.id,
							className: `${block?.css}`,
							src: block.src && block?.src,
						},
						block?.children &&
							block?.children?.map((childBlock, index) => {
								return (
									<Method
										block={childBlock}
									/>
								);
							})
					)
				}
			</>
		);
	} else {
		return (
			<>
				{
					React.createElement(
						block?.type,
						{
							key: block.id,
							id: block?.id,
							className: `${block?.css} `,
							src: block.src && block.src,
						},
						block.content
					)
				}

			</>
		);
			}
}


// import React, { useContext, useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom';
// import { UserContext } from './context/Context';

// let data;
// export default function Map({ config }) {
// const [data, setData] = useState([])
// const contextData = useContext(UserContext)
// console.log(contextData, "context data")
// const navigate = useNavigate()
//     const func = (config)=>{
//          data += React.createElement(
//             config.component,
//             {
//                 src: config.src,
//                 style: config.style,
//                 id: config.id,
//                 className: config.class,
//                 contentEditable: config.contenteditable,
//                 suppressContentEditableWarning: true, // to suppress contentEditable warning
//                 htmlFor: config.htmlFor,
//                 placeholder: config.placeholder,
//                 type: config.type,
//                 //   onClick: config.href == null ? function (e) { handle(e) } : (e) => { handleInputChange(e, config.href) },
//             },
//             config.children &&
//             (typeof config.children === "string"
//               ? config.children
//               : config.children.map((c) => func(c)))
//         )
//     }
//     const fetch2 = ()=>{
//         fetch(`http://localhost:1337/api/${config.logic}`)
//         .then((response)=> response.json())
//         .then(data => setData([...data.data]))
//         .catch(err => console.log(err))
//     }
//     useEffect(()=>{
//          fetch2()
//     },[])
//     return (
//         <div className='flex overflow-auto items-center'>
//             {
//                 [...new Array(6)].map((value, i) => {
//                     return React.createElement(
//                         config.component,
//                         {
//                             src: config.src,
//                             style: config.style,
//                             id: config.id,
//                             className: config.class,
//                             contentEditable: config.contenteditable,
//                             suppressContentEditableWarning: true, // to suppress contentEditable warning
//                             htmlFor: config.htmlFor,
//                             placeholder: config.placeholder,
//                             type: config.type,
//                             onClick: ()=>{navigate(`product/${i}`)},
//                         },
//                         config.children &&
//                         (typeof config.children === "string"
//                           ? config.children
//                           : config.children.map((c) => <Method config={c} data={value}/>))
//                     )
//                 })
//             }
//         </div>
//     )
// }

// const Method = ({config, data})=>{
    
//     const contextData = useContext(UserContext)
//     return React.createElement(
//         config.component,
//         {
//             src: config.src,
//             style: config.style,
//             id: config.id,
//             className: config.class,
//             contentEditable: config.contenteditable,
//             suppressContentEditableWarning: true, // to suppress contentEditable warning
//             htmlFor: config.htmlFor,
//             placeholder: config.placeholder,
//             type: config.type,
//             //   onClick: config.href == null ? function (e) { handle(e) } : (e) => { handleInputChange(e, config.href) },
//         },
//         config.contextData && contextData[config.contextData],
//         data.attributes.firstname
//         // config.children &&
//         // (typeof config.children === "string"
//         //   ? config.children
//         //   : config.children.map((c) => <Method config={c}/>))
//     )
// }
