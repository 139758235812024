import { randomString } from '../../../../libraries/utility';

// ui schemas
export const uiSchema = {
	// Email
	emailSchema: (newRandomID) => ({
		id: randomString(),
		type: "EmailField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			inputType: "email",
			name: "Email",
			stateName: `Email_${newRandomID}`,
			
			placeholder: "user@guest.com",
			// defaultValue: "James",
						
			required: true,
			
			// unique field value
			unique: false,
			
			// display confirmation email field
			// confirmationEmailField: true,
			
			disabled: false,
			autoFocus: false,
			autoComplete: false,
			readOnly: false,
			
			// maxLength: 10,
			minLength: 6,
			
			label: true,
			labelText: "Email",
			
			help: false,
			helpText: "Sub heading for Email",
			
			validations: [
				{
					type: "required",
					message: "Email is required"
				},
				{
					type: "isEmail",
					message: "Please enter a valid email address"
				},
				{
					type: "minLength",
					value: 6,
					message: "Minimum 6 characters required"
				}
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize",  "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "w-full block rounded-md py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-[1px] ring-gray-200 border-gray-200 focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "borderRadius": "6px", "paddingTop": "6px", "paddingBottom": "6px", "paddingLeft": "8px", "paddingRight": "8px", "color": "#030712", "borderColor": "#e5e7eb" },
			}
		}
	}),
	
	// Number
	numberSchema: (newRandomID) => ({
		id: randomString(),
		type: "NumberField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			inputType: "number",
			name: "Number",
			stateName: `Number_${newRandomID}`,
			
			placeholder: "e.g., 45",
			// defaultValue: "James",
						
			required: true,
						
			disabled: false,
			autoFocus: false,
			autoComplete: false,
			readOnly: false,
			
			numberType: 'biginteger',
			// max: 10,
			// min: 6,
			
			label: true,
			labelText: "Number",
			
			help: false,
			helpText: "Sub heading for Number",
			
			validations: [
				{
					type: "required",
					message: "Number is required"
				}
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize",  "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "w-full block rounded-md py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-[1px] ring-gray-200 border-gray-200 focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "borderRadius": "6px", "paddingTop": "6px", "paddingBottom": "6px", "paddingLeft": "8px", "paddingRight": "8px", "color": "#030712", "borderColor": "#e5e7eb" },
			}
		}
	}),
	
	// Short text
	shortTextSchema: (newRandomID) => ({
		id: randomString(),
		type: "ShortTextField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			inputType: "text",
			name: "Username",
			stateName: `Username_${newRandomID}`,
			
			placeholder: "Enter username",
			
			// defaultValue: "James",
			
			required: true,
			
			disabled: false,
			autoFocus: false,
			autoComplete: false,
			readOnly: false,
			
			// maxLength: 10,
			// minLength: 2,
			
			label: true,
			labelText: "Username",
			
			help: false,
			helpText: "Sub heading for Username",
			
			validations: [
				{
					type: "required",
					message: "Username is required"
				},
				// {
				// 	type: "minLength",
				// 	value: 3,
				// 	message: "Minimum 3 characters required"
				// }
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize",  "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "w-full block rounded-md py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-[1px] ring-gray-200 border-gray-200 focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "borderRadius": "6px", "paddingTop": "6px", "paddingBottom": "6px", "paddingLeft": "8px", "paddingRight": "8px", "color": "#030712", "borderColor": "#e5e7eb" },
			}
		}
	}),
	
	// Long text
	longTextSchema: (newRandomID) => ({
		id: randomString(),
		type: "LongTextField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			name: "Description",
			stateName: `Description_${newRandomID}`,
			
			placeholder: "Enter description",
			
			// defaultValue: "James",
			
			required: true,
			
			disabled: false,
			autoFocus: false,
			autoComplete: false,
			readOnly: false,
			
			rows: 2,
			
			// maxLength: 10,
			// minLength: 2,
			
			label: true,
			labelText: "Description",
			
			help: false,
			helpText: "Sub heading for Description",
			
			validations: [
				{
					type: "required",
					message: "Description is required"
				},
				// {
				// 	type: "minLength",
				// 	value: 3,
				// 	message: "Minimum 3 characters required"
				// }
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize",  "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "w-full block rounded-md py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-[1px] ring-gray-200 border-gray-200 focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "borderRadius": "6px", "paddingTop": "6px", "paddingBottom": "6px", "paddingLeft": "8px", "paddingRight": "8px", "color": "#030712", "borderColor": "#e5e7eb" },
			}
		}
	}),
	
	// select
	selectSchema: (newRandomID) => ({
		id: randomString(),
		type: "SelectField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			name: "Select",
			stateName: `Select_${newRandomID}`,
			
			required: true,	
				
			disabled: false,
			autoFocus: false,
			// multiple: true,
			
			// shuffle options
			// randomize: false,
			
			// options to display at once
			// size: 1,
			
			// pre-defined option
			preDefinedOption: "",		// Countries
			
			defaultValue: "",
			
			emptyOptionValue: "Please Select",
			
			options: [
				"Option 1",
				"Option 2",
				"Option 3"
			],
			
			label: true,
			labelText: "Select options",
			
			help: true,
			helpText: "Select a option",
			
			validations: [
				{
					type: "required",
					message: "Select is required"
				}
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize", "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "w-full block rounded-md py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-[1px] ring-gray-200 border-gray-200 focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "borderRadius": "6px", "paddingTop": "6px", "paddingBottom": "6px", "paddingLeft": "8px", "paddingRight": "8px", "color": "#030712", "borderColor": "#e5e7eb" },
			}
		}
	}),
	
	// password
	passwordSchema: (newRandomID) => ({
		id: randomString(),
		type: "PasswordField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			
			inputType: "password",
			name: "Password",
			stateName: `Password_${newRandomID}`,
			
			placeholder: "Enter password",
			
			required: true,
			
			disabled: false,
			autoFocus: false,
			
			maxLength: 15,
			minLength: 8,
			
			// pattern: "[A-Za-z]{3}",
			
			label: true,
			labelText: "Password",
			
			showPassword: true,
			
			help: true,
			// helpText: "Please enter a secure password that is at least 8 characters long and includes a combination of letters, numbers, and special characters for added security.",
			helpText: "Password must contain 8 characters",
			
			validations: [
				{
					type: "required",
					message: "Password is required"
				},
				{
					type: "minLength",
					value: 8,
					message: "Please create a password with a minimum of 8 characters."
				},
				{
					type: "maxLength",
					value: 15,
					message: "Please keep your password within 15 characters"
				}
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize", "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "w-full block rounded-md py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-[1px] ring-gray-200 border-gray-200 focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "borderRadius": "6px", "paddingTop": "6px", "paddingBottom": "6px", "paddingLeft": "8px", "paddingRight": "8px", "color": "#030712", "borderColor": "#e5e7eb" },
			}
		}
	}),
	
	// upload media - image, video
	uploadSchema: (newRandomID, type) => ({
		id: randomString(),
		type: `${
			type == 'image' ? 'UploadField' :
			type == 'video' ? 'UploadVideoField' :
				''
		}`,
		// css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		css: "",
		data: {
			
			inputType: "file",
			name: "File",
			stateName: `File_${newRandomID}_file`,
			
			required: true,
			
			disabled: false,
			autoFocus: false,
			
			allowedTypes: [
				type == 'image' ? { name: "images", types: ["jpeg", "png", "gif", "svg", "tiff", "ico", "webp"] } :
				type == 'video' ? { name: "videos", types: ["mpeg", "mp4", "quicktime", "wmv", "avi", "flv"] } :
					{}
			],
			// allowedTypes: [ 
			// 	{ name: "images", types: ["jpeg", "png", "gif", "svg", "tiff", "ico", "webp"] },
			// 	{ name: "videos", types: ["mpeg", "mp4", "quicktime", "wmv", "avi", "flv"] },
			// 	{ name: "audios", types: ["mp3", "wav", "ogg"] },
			// 	{ name: "files", types: ["csv", "zip", "pdf", "excel", "json"] }	
			// ],
			
			// max size of file - validation
			maxSize: `${
				type == 'image' ? '200' :
				type == 'video' ? '8388608' :
					''
			}`,
	
			multiple: false,
			// limit number of files - validation
			numberOfFiles: 0,
			
			uploadText: `Upload ${type == 'image' ? 'Files' : type == 'video' ? 'Videos' : ''}`,
			
			label: true,
			labelText: `Upload ${type == 'image' ? 'File' : type == 'video' ? 'Video' : ''}`,
			
			help: false,
			helpText: "Sub heading for Upload",
			
			validations: [
				{
					type: "required",
					message: "File is required"
				},
				{
					type: "maxSize",
					value: `${
						type == 'image' ? 200 :
						type == 'video' ? 8388608 :
							''
					}`,
					message: `File must be of ${
						type == 'image' ? '200 Kb' :
						type == 'video' ? '8 Mb' :
							''
					} or less`
				},
				// {
				// 	type: "numberOfFiles",
				// 	value: 2,
				// 	message: "You can upload up to 2 files at once"
				// }
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize", "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				uploadBlock: "text-center w-full p-4 block rounded-md text-gray-600 sm:text-sm sm:leading-6 ring-[1px] ring-gray-200 border-gray-200",
				uploadBlockStyle: { "textAlign": "center", "width": "100%", "display": "block", "borderRadius": "6px", "padding": "16px", "color": "#4b5563", "borderColor": "#e5e7eb" },
			}
		}
	}),
	
	// radio button
	radioSchema: (newRandomID) => ({
		id: randomString(),
		type: "RadioField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			inputType: "radio",
			name: "Radio",
			stateName: `Radio_${newRandomID}`,
						
			required: true,
			
			disabled: false,
			// autoFocus: false,
			readOnly: false,
			
			// pre-defined option
			preDefinedOption: "",		// Countries
			
			defaultValue: "",
						
			options: [
				"Option 1",
				"Option 2",
				"Option 3"
			],
			
			otherOptionValue: "",
			
			label: true,
			labelText: "Radio",
			
			help: false,
			helpText: "Sub heading for Radio",
			
			validations: [
				{
					type: "required",
					message: "Radio is required"
				}
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize",  "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "color": "#030712" },
			}
		}
	}),

	// check box button
	checkboxSchema: (newRandomID) => ({
		id: randomString(),
		type: "CheckboxField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			inputType: "checkbox",
			name: "Checkbox",
			stateName: `Checkbox_${newRandomID}`,
						
			required: true,
			
			disabled: false,
			// autoFocus: false,
			readOnly: false,
			
			min: 1,
			max: 2,
			
			// pre-defined option
			preDefinedOption: "",		// Countries
			
			defaultValue: "",
						
			options: [
				"Option 1",
				"Option 2",
				"Option 3"
			],
			
			otherOptionValue: "",
			
			label: true,
			labelText: "Checkbox",
			
			help: false,
			helpText: "Sub heading for Checkbox",
			
			validations: [
				{
					type: "required",
					message: "Checkbox is required"
				},
				{
					type: "min",
					value: 1,
					message: "Please select minimumn 1 option"
				},
				{
					type: "max",
					value: 2,
					message: "Please select maximum 2 options"
				}
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize",  "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "color": "#030712" },
			}
		}
	}),
	
	// date
	dateSchema: (newRandomID) => ({
		id: randomString(),
		type: "DateField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			inputType: "date",
			name: "Date",
			stateName: `Date_${newRandomID}`,
						
			required: true,
			
			autoFocus: false,
			readOnly: false,
			disabled: false,
			
			minValue: "",
			maxValue: "",
			
			defaultValue: "",
			
			step: 0,
			
			label: true,
			labelText: "Date",
			
			help: false,
			helpText: "Sub heading for Date",
			
			validations: [
				{
					type: "required",
					message: "Date is required"
				}
			],

			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize", "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "w-full block rounded-md py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-[1px] ring-gray-200 border-gray-200 focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "borderRadius": "6px", "paddingTop": "6px", "paddingBottom": "6px", "paddingLeft": "8px", "paddingRight": "8px", "color": "#030712", "borderColor": "#e5e7eb" },
			}
		}
	}),
		
	// datetime-local
	dateTimeSchema: (newRandomID) => ({
		id: randomString(),
		type: "DateTimeField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			inputType: "datetime-local",
			name: "Date Time",
			stateName: `Datetime_${newRandomID}`,
						
			required: true,
			
			disabled: false,
			autoFocus: false,
			readOnly: false,
			
			minValue: "",
			maxValue: "",
			
			step: 0,
			
			defaultValue: "",
			
			label: true,
			labelText: "Date & Time",
			
			help: false,
			helpText: "Sub heading for Date & Time",
			
			validations: [
				{
					type: "required",
					message: "Date & Time is required"
				}
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize", "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "w-full block rounded-md py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-[1px] ring-gray-200 border-gray-200 focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "borderRadius": "6px", "paddingTop": "6px", "paddingBottom": "6px", "paddingLeft": "8px", "paddingRight": "8px", "color": "#030712", "borderColor": "#e5e7eb" },
			}
		}
	}),
	
	// time
	timeSchema: (newRandomID) => ({
		id: randomString(),
		type: "TimeField",
		css: "block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
		data: {
			inputType: "time",
			name: "Time",
			stateName: `Time_${newRandomID}`,
						
			required: true,
			
			disabled: false,
			autoFocus: false,
			readOnly: false,
			
			minValue: "",
			maxValue: "",
			
			step: 0,
			
			defaultValue: "",

			label: true,
			labelText: "Time",
			
			help: false,
			helpText: "Sub heading for Time",
			
			validations: [
				{
					type: "required",
					message: "Time is required"
				}
			],
			
			css: {
				label: "block text-sm font-medium leading-6 text-gray-900",
				labelStyle: { "display": "block", "fontSize": "14px", "fontWeight": "500", "lineHeight": "24px", "color": "#030712" },
				
				help: "mb-2 text-sm leading-6 text-gray-600",
				helpStyle: { "marginBottom": "8px", "fontSize": "14px", "lineHeight": "24px", "color": "#4b5563" },
				
				warning: "mt-1 capitalize text-sm text-red-600 text-left",
				warningStyle: { "marginTop": "4px", "textTransform": "capitalize", "fontSize": "14px", "color": "#dc2626", "textAlign": "left" },
				
				input: "w-full block rounded-md py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-[1px] ring-gray-200 border-gray-200 focus:ring-indigo-600",
				inputStyle: { "width": "100%", "display": "block", "borderRadius": "6px", "paddingTop": "6px", "paddingBottom": "6px", "paddingLeft": "8px", "paddingRight": "8px", "color": "#030712", "borderColor": "#e5e7eb" },
			}
		}
	}),
	
	
}

// strapi form schemas
export const strapiFormSchemas = {
	
	// Email
	emailStrapiSchema: (newRandomID) => ({
		[`Email_${newRandomID}`]: {
			type: "email",
			required: true,
			unique: false,
			minLength: 6,
			// maxLength: 2,
			// regex: "[A-Za-z]{3}",
			// default: "James Bond"
		}
	}),
	
	// Number
	numberStrapiSchema: (newRandomID, numberType) => ({
		[`Number_${newRandomID}`]: {
			type: numberType,
			required: true,
			// maxLength: 2,
			// regex: "[A-Za-z]{3}",
			// default: "James Bond"
		}
	}),
	
	// Short text
	shortTextStrapiSchema: (newRandomID) => ({
		[`Username_${newRandomID}`]: {
			type: "string",
			required: true,
			// minLength: 10,
			// maxLength: 2,
			// regex: "[A-Za-z]{3}",
			// default: "James Bond"
		}
	}),
	
	// Long text
	longTextStrapiSchema: (newRandomID) => ({
		[`Description_${newRandomID}`]: {
			type: "text",
			required: true,
			// minLength: 10,
			// maxLength: 2,
			// regex: "[A-Za-z]{3}",
			// default: "James Bond"
		}
	}),
	
	// Select
	selectStrapiSchema: (newRandomID) => ({
		[`Select_${newRandomID}`]: {
			type: "enumeration",
			required: true,
			enum: ["Option 1", "Option 2", "Option 3"],
			// default: "Option 1"
		}
	}),
	
	// Password
	passwordStrapiSchema: (newRandomID) => ({
		[`Password_${newRandomID}`]: {
			type: "password",
			required: true,
			// minLength: 10,
			// maxLength: 2,
			// regex: "[A-Za-z]{3}",
		}
	}),

	// Upload
	uploadStrapiSchema: (newRandomID, type) => ({
		[`File_${newRandomID}_file`]: {
			type: "json",
			sub_type: `${
				type == 'image' ? 'media_cdn' :
				type == 'video' ? 'video_cdn' :
					''
			}`,
			required: true,
			multiple: false,
			allowedTypes: [`${
				type == 'image' ? "images" :
				type == 'video' ? 'videos' :
					''
			}`]
			// allowedTypes: [ "images", "files", "videos", "audios" ]
		}
	}),

	// radio button
	radioStrapiSchema: (newRandomID) => ({
		[`Radio_${newRandomID}`]: {
			type: "enumeration",
			sub_type: "radio",
			required: true,
			enum: ["Option 1", "Option 2", "Option 3"],
			// default: "Option 1"
		}
	}),
	
	// check box button
	checkboxStrapiSchema: (newRandomID) => ({	
		[`Checkbox_${newRandomID}`]: {
			type: "json",
			sub_type: "checkbox",
			enum: ["Option 1", "Option 2", "Option 3"],
			required: true
			// "default": ["Option 1", "Option 2"]
		}
	}),
	
	// date
	dateStrapiSchema: (newRandomID) => ({
		[`Date_${newRandomID}`]: {
			type: "date",
			required: true,
			// default: "2023-11-10"
		}
	}),
	
	// datetime
	dateTimeStrapiSchema: (newRandomID) => ({
		[`dateTime_${newRandomID}`]: {
			type: "dateTime",
			required: true,
			// default: "2023-08-06T19:45"
		}
	}),
	
	// time
	timeStrapiSchema: (newRandomID) => ({
		[`Time_${newRandomID}`]: {
			type: "time",
			required: true,
			// default: "10.20:00.000"
		}
	}),
}
