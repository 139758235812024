import { Tab } from "@headlessui/react";
import { BlockWrapper } from "../Sortable";
import { createElement } from "react";
import { useContext } from "react";
import { context } from "../../../../context/Context";
import { AddToUndo } from "../../editor";
import { useLocation, useParams } from "react-router-dom";
import { generateContent, generateCss } from "../functions/sortableFunctions";

const TabComponent = ({ block, ...props }) => {
	const { selectedSiteData, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo } = useContext(context);
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (
		<Tab
			{...block.data}
			as={block.data?.type ? block.data.type : 'button'}
			id={block.id}
			className={generateCss({
				contextVariables: contextVariables,
				props: {
					...props,
					block: block
				},
				params: params,
				location: location
			})}
			style={{
				fontFamily: block?.style?.fontFamily
			}}
			onMouseOver={(e) => props.handleMouseOver(e)}
			onClick={(e) => {
				props.handleClick(e, block.id);
				if (props.customComponentChildren) {
					props.handleComponentWithSettingsClick();
				}
			}}
			onDoubleClick={(e) => {
				e.target.contentEditable = true;
			}}
			suppressContentEditableWarning={true}
			onBlur={(e) => {
				block.content = e.target.innerText;
				AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);
			}}
		>
			{
				({ selected }) => {
					if (block.children) {
						return block.children.map((value, index) => {
							return <BlockWrapper
								{...props}
								key={index}
								block={value}
								selected={selected}
								blockIndex={[...props.blockIndex, index]}
							/>
						})
					} else {
						return generateContent({
							contextVariables: contextVariables,
							props: {
								...props,
								block: block
							},
							params: params,
							location: location
						});
					}
				}
			}
		</Tab>
	)
}
export default TabComponent;