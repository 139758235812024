import { useState, useEffect, useContext, useRef } from "react";
import { Listbox } from "@headlessui/react";

import { useParams } from "react-router-dom";
import { context } from "../../../context/Context";
import { dbCall } from "../../../common/db";
import classesToStyleObjectConvertor from "./classesToStyleObjectConverter";
import removeDuplicateClasses from "./removeDuplicateClasses";
import addNewStyleClass from "./addNewStyleClass";
import axios from "axios";
import { AddToUndo } from "../../../screens/account/editor";
import Dimension from "./Dimension";
import SpacingBorder from "./BorderSpacing";
import Background from "./Background";
import LayoutSection from "./LayoutSection";
import TypographySection from "./TypographySection";
import { toast } from "react-toastify";
import Animation_Parallax from "./Animation_Parallax";

const states = [
	{
		value: "",
		name: "Normal",
		key: "normal",
	},
	{
		value: "hover:",
		name: "Hover",
		key: "hover",
	},
	{
		value: "focus:",
		name: "Focus",
		key: "focus",
	},
	{
		value: "active:",
		name: "Active",
		key: "active",
	},
	{
		value: "visited:",
		name: "Visited",
		key: "visited",
	},
	{
		value: "placeholder:",
		name: "Placeholder",
		key: "placeholder",
	},
	{
		value: "file:",
		name: "File Input Button",
		key: "file",
	},
	{
		value: "*:",
		name: "Style Direct Child",
		key: "styleDirectChild",
	},
];

let myContext = {

}

export const updateDesign = async (key, value) => {
	const { blocks,
		elementRef,
		elementId,
		currentTheme,
		formElementRef,
		undo,
		setUndo,
		setCurrentTheme,
		currentPlatform,
		setTheme,
		Bdispatch,
		selectedSiteData,
		sqliteSchema,
		strapiSchema,
		setRedo,
		params,
		setElementAllBreakpointsStyleObject,
		selectedBreakpoint,
		selectedState,
		classes,
		addImportant,
		setClasses
	} = myContext

	if (key == "fontFamily") {
		setElementAllBreakpointsStyleObject((prev) => {
			return {
				...prev,
				base: {
					...prev.base,
					normal: {
						...prev.base.normal,
						fontFamily: value.name,
					},
				},
			};
		});
		if (
			formElementRef?.current &&
			formElementRef?.current.id !== elementId.id
		) {
			elementId.data.css[
				`${elementRef.current?.dataset?.name}Style`
			] = {
				fontFamily: value.name,
			};
		} else {
			elementId.style = {
				fontFamily: value.name,
			};
		}
		AddToUndo(
			selectedSiteData.static,
			blocks,
			sqliteSchema,
			strapiSchema,
			undo,
			setUndo,
			setRedo
		);

		let match = false;
		for (let i = 0; i < currentTheme.fonts?.length; i++) {
			if (value.name == currentTheme.fonts[i].name) {
				match = true;
				break;
			}
		}
		if (match == false) {
			if (!currentTheme?.fonts) {
				currentTheme["fonts"] = [];
			}

			const fonts = currentTheme?.fonts;
			fonts?.push({
				name: value.name,
				url: value.url,
			});
			dbCall.put("/site/update-theme", {
				siteId: params.siteId,
				theme: {
					fonts: fonts,
					...currentTheme,
				},
				platform: currentPlatform,
			});
			setCurrentTheme({
				fonts: fonts,
				...currentTheme,
			});
			setTheme((prev) => {
				return {
					...prev,
					[`${currentPlatform}Theme`]: {
						fonts: fonts,
						...currentTheme,
					},
				};
			});
		}
		return;
	}

	setElementAllBreakpointsStyleObject((prev) => {
		return {
			...prev,
			[selectedBreakpoint.name]: {
				...prev[selectedBreakpoint.name],
				[selectedState.key]: {
					...prev[selectedBreakpoint.name][selectedState.key],
					[key]: value,
				},
			},
		};
	});
	const classesArray = classes?.split(" ");
	const updatedClasses = await removeDuplicateClasses(
		classesArray,
		key,
		selectedBreakpoint.classPrefix,
		selectedState.value
	);
	const newClasses = await addNewStyleClass(
		updatedClasses,
		key,
		value,
		selectedBreakpoint.classPrefix,
		selectedState.value,
		addImportant
	);
	if (
		formElementRef?.current &&
		formElementRef?.current.id !== elementId.id
	) {
		elementId.data.css[elementRef.current?.dataset?.name] = newClasses;
	} else {
		elementId.css = newClasses;
	}
	setClasses(newClasses);
	AddToUndo(
		selectedSiteData.static,
		blocks,
		sqliteSchema,
		strapiSchema,
		undo,
		setUndo,
		setRedo
	);
};

const Style = () => {

	const [search, setSearch] = useState([]);

	const [selectedState, setSelectedState] = useState(states[0]);
	const [
		elementAllBreakpointsStyleObject,
		setElementAllBreakpointsStyleObject,
	] = useState({});
	const [isSearchingFonts, setIsSearchingFonts] = useState(false);
	const [fonts, setFonts] = useState([]);
	const [fontsToDisplay, setFontsToDisplay] = useState(10);
	const [filteredFonts, setFilteredFonts] = useState([]);
	const [valueTypes, setValueTypes] = useState({
		columnGap: {
			unit: "",
		},
		rowGap: {
			unit: "	",
		},
		flexBasis: {
			unit: "auto",
		},
		width: {
			unit: "auto"
		},
		height: {
			unit: "auto"
		},
		maxWidth: {
			unit: "none"
		},
		maxHeight: {
			unit: "none"
		},
		minWidth: {
			unit: "px"
		},
		minHeight: {
			unit: "px"
		},
		padding: {
			type: "common",
			unit: "px"
		},
		paddingLeft: {
			unit: "px"
		},
		paddingTop: {
			unit: "px"
		},
		paddingRight: {
			unit: "px"
		},
		paddingBottom: {
			unit: "px"
		},
		margin: {
			type: "common",
			unit: "px"
		},
		marginLeft: {
			unit: "px"
		},
		marginTop: {
			unit: "px"
		},
		marginRight: {
			unit: "px"
		},
		marginBottom: {
			unit: "px"
		},
		borderWidth: {
			type: "common",
			unit: "px"
		},
		borderLeftWidth: {
			unit: "px"
		},
		borderTopWidth: {
			unit: "px"
		},
		borderRightWidth: {
			unit: "px"
		},
		borderBottomWidth: {
			unit: "px"
		},
		borderRadius: {
			type: "common",
			unit: "px"
		},
		borderTopLeftRadius: {
			unit: "px"
		},
		borderTopRightRadius: {
			unit: "px"
		},
		borderBottomLeftRadius: {
			unit: "px"
		},
		borderBottomRightRadius: {
			unit: "px"
		},
		outlineWidth: {
			unit: "px"
		},
		outlineOffset: {
			unit: "px"
		},
		lineThickness: {
			unit: "auto"
		},
		letterSpacing: {
			unit: "px"
		},
		lineHeight: {
			unit: "none"
		},
		textIndent: {
			unit: "px"
		},
		textUnderlineOffset: {
			unit: "auto"
		},
		zIndex: {
			type: "auto",
		},
		left: {
			unit: "auto",
		},
		top: {
			unit: "auto",
		},
		right: {
			unit: "auto",
		},
		bottom: {
			unit: "auto",
		},
		fontSize: {
			unit: "px",
		},
		gridTemplateColumns: {
			type: "none",
		},
		gridTemplateRows: {
			type: "none",
		},
		gridColumn: {
			type: "auto",
		},
		gridColumnStart: {
			type: "auto",
		},
		gridColumnEnd: {
			type: "auto",
		},
		gridRow: {
			type: "auto",
		},
		gridRowStart: {
			type: "auto",
		},
		gridRowEnd: {
			type: "auto",
		},
		overflow: {
			type: "common"
		},
		objectPosition: {
			type: "normal",
			unit: 'px'
		},
		textShadow: {
			unit_1: 'px',
			unit_2: 'px',
			unit_3: 'px'
		},
		lineThickness: {
			unit: "auto"
		},
		letterSpacing: {
			unit: "px"
		},
		lineHeight: {
			unit: "none"
		},
		textIndent: {
			unit: "px"
		},
		textUnderlineOffset: {
			unit: "auto"
		}
	});
	const [classes, setClasses] = useState("");
	const [text, setText] = useState("");

	const [addImportant, setAddImportant] = useState(false);
	const params = useParams();

	const timerref = useRef();

	const {
		blocks,
		elementRef,
		elementId,
		currentTheme,
		formElementRef,
		undo,
		setUndo,
		setCurrentTheme,
		currentPlatform,
		setTheme,
		Bdispatch,
		selectedSiteData,
		sqliteSchema,
		strapiSchema,
		setRedo,
		setSaved,
		saved,
		pageName,
		elementBreakpointSpecificStyleObject,
		setElementBreakpointSpecificStyleObject,
		selectedBreakpoint,
		openedSections,
		setOpenedSections,
		currentLanguage,
		setContent,
		content

	} = useContext(context);

	myContext = {
		blocks,
		elementRef,
		elementId,
		currentTheme,
		formElementRef,
		undo,
		setUndo,
		setCurrentTheme,
		setTheme,
		Bdispatch,
		selectedSiteData,
		sqliteSchema,
		strapiSchema,
		setRedo,
		setSaved,
		pageName,
		currentPlatform,
		elementBreakpointSpecificStyleObject,
		setElementBreakpointSpecificStyleObject,
		formElementRef,
		elementId,
		elementRef,
		params,
		setElementAllBreakpointsStyleObject,
		selectedBreakpoint,
		selectedState,
		classes,
		addImportant,
		setClasses,
		isSearchingFonts,
		filteredFonts,
		setFontsToDisplay,
		fontsToDisplay,
		fonts,
		search,
		setSearch,
		timerref,
		setIsSearchingFonts,
		setFilteredFonts
	}


	useEffect(() => {
		setAddImportant(false);
		let css;
		let style;
		if (
			formElementRef.current &&
			formElementRef.current.id !== elementId.id
		) {
			css = elementId.data.css[elementRef.current?.dataset?.name];
			style =
				elementId.data.css[`${elementRef.current?.dataset?.name}Style`];
			if (elementRef.current?.dataset?.name == "input") {
				setAddImportant(true);
			}
		} else {
			css = elementId?.css;
			style = elementId?.style;
			if (elementId?.data?.formButton) {
				setAddImportant(true);
			}
		}
		if (typeof css == 'object') {
			css = "";
		}
		setClasses(css);
		const classesArray = css?.split(" ");
		if (typeof elementId.content == 'object') {
			setText("");
		} else {

			if (selectedSiteData.multiLanguageEnabled) {
				if (currentLanguage.code == selectedSiteData.templateLanguage.code) {
					setText(elementId.content);
				} else {
					if (content?.[currentLanguage.code]?.[elementId.id]) {
						setText(content[currentLanguage.code][elementId.id]);
					} else {
						setText(elementId.content);
					}
				}
			} else {
				setText(elementId.content);
			}
		}

		const allBreakpointsStyleObject = classesToStyleObjectConvertor(
			elementId.id,
			classesArray,
			style,
			currentTheme
		);
		setElementAllBreakpointsStyleObject(allBreakpointsStyleObject);
	}, [elementId, formElementRef, currentTheme, currentLanguage]);
	useEffect(() => {
		const { base, xs, sm, md, lg, xl, twoXl } =
			elementAllBreakpointsStyleObject;
		let obj;
		if (selectedBreakpoint.value == "0px") {
			if (selectedState.name == "Normal") {
				obj = { ...base?.normal };
			} else {
				obj = { ...base.normal, ...base[selectedState.key] };
			}
		} else if (selectedBreakpoint.value == "420px") {
			if (selectedState.name == "Normal") {
				obj = { ...base?.normal, ...xs?.normal };
			} else {
				obj = {
					...base.normal,
					...xs.normal,
					...base[selectedState.key],
					...xs[selectedState.key],
				};
			}
		} else if (selectedBreakpoint.value == "640px") {
			if (selectedState.name == "Normal") {
				obj = { ...base?.normal, ...xs?.normal, ...sm?.normal };
			} else {
				obj = {
					...base.normal,
					...xs.normal,
					...sm.normal,
					...base[selectedState.key],
					...xs[selectedState.key],
					...sm[selectedState.key],
				};
			}
		} else if (selectedBreakpoint.value == "768px") {
			if (selectedState.name == "Normal") {
				obj = {
					...base?.normal,
					...xs?.normal,
					...sm?.normal,
					...md?.normal,
				};
			} else {
				obj = {
					...base.normal,
					...xs.normal,
					...sm.normal,
					...md.normal,
					...base[selectedState.key],
					...xs[selectedState.key],
					...sm[selectedState.key],
					...md[selectedState.key],
				};
			}
		} else if (selectedBreakpoint.value == "1024px") {
			if (selectedState.name == "Normal") {
				obj = {
					...base?.normal,
					...xs?.normal,
					...sm?.normal,
					...md?.normal,
					...lg?.normal,
				};
			} else {
				obj = {
					...base.normal,
					...xs.normal,
					...sm.normal,
					...md.normal,
					...lg.normal,
					...base[selectedState.key],
					...xs[selectedState.key],
					...sm[selectedState.key],
					...md[selectedState.key],
					...lg[selectedState.key],
				};
			}
		} else if (selectedBreakpoint.value == "1280px") {
			if (selectedState.name == "Normal") {
				obj = {
					...base?.normal,
					...xs?.normal,
					...sm?.normal,
					...md?.normal,
					...lg?.normal,
					...xl?.normal,
				};
			} else {
				obj = {
					...base.normal,
					...xs.normal,
					...sm.normal,
					...md.normal,
					...lg.normal,
					...xl.normal,
					...base[selectedState.key],
					...xs[selectedState.key],
					...sm[selectedState.key],
					...md[selectedState.key],
					...lg[selectedState.key],
					...xl[selectedState.key],
				};
			}
		} else if (selectedBreakpoint.value == "1536px") {
			if (selectedState.name == "Normal") {
				obj = {
					...base?.normal,
					...xs?.normal,
					...sm?.normal,
					...md?.normal,
					...lg?.normal,
					...xl?.normal,
					...twoXl?.normal,
				};
			} else {
				obj = {
					...base.normal,
					...xs.normal,
					...sm.normal,
					...md.normal,
					...lg.normal,
					...xl.normal,
					...twoXl.normal,
					...base[selectedState.key],
					...xs[selectedState.key],
					...sm[selectedState.key],
					...md[selectedState.key],
					...lg[selectedState.key],
					...xl[selectedState.key],
					...twoXl[selectedState.key],
				};
			}
		}
		setElementBreakpointSpecificStyleObject((prev) => {
			return obj;
		});
		setValueTypes((prev) => {
			return {
				...prev,
				columnGap: {
					unit: obj.columnGap
						? obj.columnGap.replace(/[0-9]/g, "")
						: "",
				},
				rowGap: {
					unit: obj.rowGap ? obj.rowGap.replace(/[0-9]/g, "") : "",
				},
				flexBasis: {
					unit: obj.flexBasis
						? obj.flexBasis != "auto"
							? obj.flexBasis.replace(/[0-9]/g, "")
							: "auto"
						: "auto",
				},
				width: {
					unit: obj.width ? obj.width.replace(/[0-9]/g, "") : ""
				},
				blur: {
					unit: obj.blur ? obj.blur.replace(/[0-9]/g, "") : ""
				},
				height: {
					unit: obj.height ? obj.height.replace(/[0-9]/g, "") : ""
				},
				maxWidth: {
					unit: obj.maxWidth ? obj.maxWidth.replace(/[0-9]/g, "") : ""
				},
				maxHeight: {
					unit: obj.maxHeight ? obj.maxHeight.replace(/[0-9]/g, "") : ""
				},
				minWidth: {
					unit: obj.minWidth ? obj.minWidth.replace(/[0-9]/g, "") : ""
				},
				minHeight: {
					unit: obj.minHeight ? obj.minHeight.replace(/[0-9]/g, "") : ""
				},
				padding: {
					type: (obj.paddingLeft || obj.paddingTop || obj.paddingRight || obj.paddingBottom) ? "individual" : "common",
					unit: obj.padding ? obj.padding.replace(/[0-9-]/g, "") : ""
				},
				paddingLeft: {
					unit: obj.paddingLeft ? obj.paddingLeft.replace(/[0-9]/g, "") : ""
				},
				paddingTop: {
					unit: obj.paddingTop ? obj.paddingTop.replace(/[0-9]/g, "") : ""
				},
				paddingRight: {
					unit: obj.paddingRight ? obj.paddingRight.replace(/[0-9]/g, "") : ""
				},
				paddingBottom: {
					unit: obj.paddingBottom ? obj.paddingBottom.replace(/[0-9]/g, "") : ""
				},
				margin: {
					type: (obj.marginLeft || obj.marginTop || obj.marginRight || obj.marginBottom) ? "individual" : "common",
					unit: obj.margin ? obj.margin.replace(/[0-9-]/g, "") : ""
				},
				marginLeft: {
					unit: obj.marginLeft ? obj.marginLeft.replace(/[0-9-]/g, "") : ""
				},
				marginTop: {
					unit: obj.marginTop ? obj.marginTop.replace(/[0-9-]/g, "") : ""
				},
				marginRight: {
					unit: obj.marginRight ? obj.marginRight.replace(/[0-9-]/g, "") : ""
				},
				marginBottom: {
					unit: obj.marginBottom ? obj.marginBottom.replace(/[0-9-]/g, "") : ""
				},
				borderWidth: {
					type: (obj.borderLeftWidth || obj.borderTopWidth || obj.borderRightWidth || obj.borderBottomWidth) ? "individual" : "common",
					unit: obj.borderWidth ? obj.borderWidth.replace(/[0-9-]/g, "") : ""
				},
				borderLeftWidth: {
					unit: obj.borderLeftWidth ? obj.borderLeftWidth.replace(/[0-9]/g, "") : ""
				},
				borderTopWidth: {
					unit: obj.borderTopWidth ? obj.borderTopWidth.replace(/[0-9]/g, "") : ""
				},
				borderRightWidth: {
					unit: obj.borderRightWidth ? obj.borderRightWidth.replace(/[0-9]/g, "") : ""
				},
				borderBottomWidth: {
					unit: obj.borderBottomWidth ? obj.borderBottomWidth.replace(/[0-9]/g, "") : ""
				},
				borderRadius: {
					type: (obj.borderTopLeftRadius || obj.borderTopRightRadius || obj.borderBottomLeftRadius || obj.borderBottomRightRadius) ? "individual" : "common",
					unit: obj.borderRadius ? obj.borderRadius.replace(/[0-9]/g, "") : ""
				},
				borderTopLeftRadius: {
					unit: obj.borderTopLeftRadius ? obj.borderTopLeftRadius.replace(/[0-9]/g, "") : ""
				},
				borderTopRightRadius: {
					unit: obj.borderTopRightRadius ? obj.borderTopRightRadius.replace(/[0-9]/g, "") : ""
				},
				borderBottomLeftRadius: {
					unit: obj.borderBottomLeftRadius ? obj.borderBottomLeftRadius.replace(/[0-9]/g, "") : ""
				},
				borderBottomRightRadius: {
					unit: obj.borderBottomRightRadius ? obj.borderBottomRightRadius.replace(/[0-9]/g, "") : ""
				},

				objectPosition: {
					type: obj.objectPosition ? obj.objectPosition.includes('_') ? 'custom' : 'normal' : "normal",
					unit: obj.objectPosition ? obj.objectPosition.includes('px') ? 'px' : obj.objectPosition.includes('vh') ? 'vh' : obj.objectPosition.includes('vw') ? 'vw' : obj.objectPosition.includes('%') ? '%' : 'px' : 'px'
				},
				textShadow: {
					unit_1: obj?.textShadow ? obj.textShadow.split('_')[0].replace(/[0-9-]/g, "") : 'px',
					unit_2: obj?.textShadow ? obj.textShadow.split('_')[1].replace(/[0-9-]/g, "") : 'px',
					unit_3: obj?.textShadow ? obj.textShadow.split('_')[2].replace(/[0-9-]/g, "") : 'px',
				},
				outlineWidth: {
					unit: obj.outlineWidth ? obj.outlineWidth.replace(/[0-9]/g, "") : ""
				},
				outlineOffset: {
					unit: obj.outlineOffset ? obj.outlineOffset.replace(/[0-9]/g, "") : ""
				},
				zIndex: {
					type: obj.zIndex
						? obj.zIndex != "auto"
							? "custom"
							: "auto"
						: "",
				},
				lineThickness: {
					unit: obj.lineThickness
						? obj.lineThickness != "auto"
							? obj.lineThickness.replace(/[0-9-]/g, "")
							: "auto"
						: "auto",
				},
				textUnderlineOffset: {
					unit: obj.textUnderlineOffset
						? obj.textUnderlineOffset != "auto"
							? obj.textUnderlineOffset.replace(/[0-9-]/g, "")
							: "auto"
						: "auto",
				},
				letterSpacing: {
					unit: obj.letterSpacing
						? obj.letterSpacing.replace(/[0-9-]/g, "")
						: "px",
				},
				lineHeight: {
					unit: obj.lineHeight
						? obj.lineHeight != "none"
							? obj.lineHeight.replace(/[0-9-]/g, "")
							: "none"
						: "none",
				},
				textIndent: {
					unit: obj.textIndent
						? obj.textIndent.replace(/[0-9-]/g, "")
						: "px",
				},
				left: {

					unit: obj.left
						? obj.left != "auto"
							? obj.left.replace(/[0-9-]/g, "")
							: "auto"
						: "auto",
				},
				top: {

					unit: obj.top
						? obj.top != "auto"
							? obj.top.replace(/[0-9-]/g, "")
							: "auto"
						: "auto",
				},
				right: {

					unit: obj.right
						? obj.right != "auto"
							? obj.right.replace(/[0-9-]/g, "")
							: "auto"
						: "auto",
				},
				bottom: {

					unit: obj.bottom
						? obj.bottom != "auto"
							? obj.bottom.replace(/[0-9-]/g, "")
							: "auto"
						: "auto",
				},
				fontSize: {
					unit: obj.fontSize
						? obj.fontSize.replace(/[0-9]/g, "")
						: "",
				},
				gridTemplateColumns: {
					type: obj.gridTemplateColumns
						? obj.gridTemplateColumns == "none"
							? "none"
							: "custom"
						: "",
				},
				gridTemplateRows: {
					type: obj.gridTemplateRows
						? obj.gridTemplateRows == "none"
							? "none"
							: "custom"
						: "",
				},
				gridRow: {
					type: obj.gridRow
						? obj.gridRow == "auto" || obj.gridRow == "full"
							? obj.gridRow
							: "custom"
						: "",
				},
				gridRowStart: {
					type: obj.gridRowStart
						? obj.gridRowStart == "auto"
							? "auto"
							: "custom"
						: "",
				},
				gridRowEnd: {
					type: obj.gridRowEnd
						? obj.gridRowEnd == "auto"
							? "auto"
							: "custom"
						: "",
				},
				gridColumn: {
					type: obj.gridColumn
						? obj.gridColumn == "auto" || obj.gridColumn == "full"
							? obj.gridColumn
							: "custom"
						: "",
				},
				gridColumnStart: {
					type: obj.gridColumnStart
						? obj.gridColumnStart == "auto"
							? "auto"
							: "custom"
						: "",
				},
				gridColumnEnd: {
					type: obj.gridColumnEnd
						? obj.gridColumnEnd == "auto"
							? "auto"
							: "custom"
						: "",
				},
				overflow: {
					type: (obj.overflowX || obj.overflowY) ? "individual" : "common"
				}
			};
		});
	}, [elementAllBreakpointsStyleObject, selectedBreakpoint, selectedState]);


	const fetchFonts = async () => {
		try {
			const response = await axios.get(
				"https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCYpcvr0oikaQbdx2XR-cYBXPFx4X52Wc4"
			);
			setFonts((prev) => {
				return response.data.items;
			});
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong!");
		}
	};
	useEffect(() => {
		fetchFonts();
	}, []);
	return (
		<>
			<div className="flex flex-col text-sm h-full font-inter">
				<div className="flex items-center justify-between p-3 border-b border-[#D9D9D9]">
					<div className="font-medium text-gray-900">
						Element State
					</div>

					<Listbox
						as={'div'}
						value={selectedState}
						onChange={setSelectedState}
						className={'relative w-[160px]'}
					>
						<Listbox.Button className="w-full rounded py-1 px-2 border border-[#D9D9D9] flex items-center justify-between gap-1">
							<span>{selectedState.name}</span>
							<span class="mdi mdi-chevron-down"></span>
						</Listbox.Button>
						<Listbox.Options className="absolute z-10 max-h-60 mt-1 w-full overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
							{states.map((value, index) => (
								<Listbox.Option
									key={`breakpointmap_${index}`}
									className={({ active }) =>
										`${active ? "bg-gray-100" : ""
										} cursor-pointer`
									}
									value={value}
								>
									{({ selected }) => (
										<div className={`flex items-center gap-1 px-2 py-1.5 text-gray-900 capitalize${selected ? " bg-indigo-100 font-medium" : ""}`}>
											<div>{value.name}</div>
											{
												selected &&
												<span class="mdi mdi-check"></span>
											}
										</div>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Listbox>
				</div>
				<div className="flex-auto overflow-y-auto no-scrollbar">
					<LayoutSection openedSections={openedSections} setOpenedSections={setOpenedSections} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} valueTypes={valueTypes} updateDesign={updateDesign} setValueTypes={setValueTypes} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} elementId={elementId} classes={classes} setClasses={setClasses} setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} selectedState={selectedState} selectedBreakpoint={selectedBreakpoint} addImportant={addImportant} />

					<Dimension openedSections={openedSections} setOpenedSections={setOpenedSections} updateDesign={updateDesign} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} valueTypes={valueTypes} setValueTypes={setValueTypes} />

					<SpacingBorder openedSections={openedSections} setOpenedSections={setOpenedSections} updateDesign={updateDesign} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} valueTypes={valueTypes} setValueTypes={setValueTypes} selectedBreakpoint={selectedBreakpoint} selectedState={selectedState} setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} classes={classes} addImportant={addImportant} setClasses={setClasses} />


					<div>
						<div
							className={`flex items-center justify-between py-1.5 px-3 border-t bg-gray-100  border-gray-300  hover:bg-gray-200 cursor-pointer font-medium${openedSections.background == true
								? " border-b"
								: ""
								}`}
							onClick={() => {
								setOpenedSections((prev) => {
									return {
										...prev,
										background: !prev.background,
									};
								});
							}}
						>
							<div className="text-[#373C47]">
								Background
							</div>

							{openedSections.background == false ? (
								<span class="mdi mdi-chevron-right text-xl text-[#373C47]"></span>
							) : (
								<span class="mdi mdi-chevron-down text-xl text-[#373C47]"></span>
							)}
						</div>
						{openedSections.background == true && (

							<Background setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} updateDesign={updateDesign} elementId={elementId} classes={classes} addImportant={addImportant} setClasses={setClasses} selectedBreakpoint={selectedBreakpoint} selectedState={selectedState} />
						)}
					</div>



					<TypographySection openedSections={openedSections} setOpenedSections={setOpenedSections} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} valueTypes={valueTypes} updateDesign={updateDesign} setValueTypes={setValueTypes} setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} elementId={elementId} setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} setSearch={setSearch} search={search} setFontsToDisplay={setFontsToDisplay} fontsToDisplay={fontsToDisplay} />


					<div>
						<div
							className={`flex items-center justify-between py-1.5 px-3 border-t bg-gray-100  border-gray-300  hover:bg-gray-200 cursor-pointer font-medium${openedSections.effects == true
								? " border-b"
								: ""
								}`}
							onClick={() => {
								setOpenedSections((prev) => {
									return {
										...prev,
										effects: !prev.effects,
									};
								});
							}}
						>
							<div className="text-[#373C47]">
								Effects
							</div>

							{openedSections.effects == false ? (
								<span class="mdi mdi-chevron-right text-xl text-[#373C47]"></span>
							) : (
								<span class="mdi mdi-chevron-down text-xl text-[#373C47]"></span>
							)}
						</div>

						<Animation_Parallax updateDesign={updateDesign} valueTypes={valueTypes} setValueTypes={setValueTypes} timerref={timerref} selectedState={selectedState} classes={classes} AddToUndo={AddToUndo} addNewStyleClass={addNewStyleClass} addImportant={addImportant} params={params} setClasses={setClasses} setElementAllBreakpointsStyleObject={setElementAllBreakpointsStyleObject} />

					</div>


					{/* Content Tab */}
					{elementId.content && (
						<div>
							<div
								className={`flex items-center justify-between py-1.5 px-3 border-t bg-gray-100  border-gray-300  hover:bg-gray-200 cursor-pointer font-medium ${openedSections.content == true
									? " border-b"
									: ""
									}`}
								onClick={() => {
									setOpenedSections((prev) => {
										return {
											...prev,
											content: !prev.content,
										};
									});
								}}
							>
								<div className="text-[#373C47]">
									Content
								</div>

								{openedSections.content == false ? (
									<span class="mdi mdi-chevron-right text-xl text-[#373C47]"></span>
								) : (
									<span class="mdi mdi-chevron-down text-xl text-[#373C47]"></span>
								)}
							</div>
							{openedSections.content == true && (
								<div className="p-3 space-y-3">
									<textarea
										className="w-full p-2 border border-gray-300 rounded-lg focus:ring-0 focus:border-2 focus:border-indigo-600 text-sm text-gray-900"
										value={text}
										onChange={(e) => {
											setText(e.target.value);
										}}
										onKeyUp={(e) => {
											if (e.key == "Enter") {
												Bdispatch({
													type: "UPDATE_CONTENT", id: elementId.id, value: e.target.value, currentLanguage, setContent, selectedSiteData
												});
												AddToUndo(
													selectedSiteData.static,
													blocks,
													sqliteSchema,
													strapiSchema,
													undo,
													setUndo,
													setRedo
												);
											}
										}}
										onBlur={(e) => {
											Bdispatch({
												type: "UPDATE_CONTENT", id: elementId.id, value: e.target.value, currentLanguage, setContent, selectedSiteData
											});
											AddToUndo(
												selectedSiteData.static,
												blocks,
												sqliteSchema,
												strapiSchema,
												undo,
												setUndo,
												setRedo
											);
										}}
									></textarea>
								</div>
							)}
						</div>
					)}

					{/* Custom CSS Tab */}
					<div>
						<div
							className={`flex items-center justify-between py-1.5 px-3 border-y bg-gray-100  border-gray-300  hover:bg-gray-200 cursor-pointer font-medium`}
							onClick={() => {
								setOpenedSections((prev) => {
									return {
										...prev,
										customCss: !prev.customCss,
									};
								});
							}}
						>
							<div className="text-[#373C47]">
								Custom CSS
							</div>

							{openedSections.customCss == false ? (
								<span class="mdi mdi-chevron-right text-xl text-[#373C47]"></span>
							) : (
								<span class="mdi mdi-chevron-down text-xl text-[#373C47]"></span>
							)}
						</div>
						{openedSections.customCss == true && (
							<div className="p-3 space-y-3">
								<textarea
									className="w-full p-2 border border-gray-300 rounded-lg focus:ring-0 focus:border-2 focus:border-indigo-600 text-sm text-gray-900"
									value={classes}
									onChange={(e) => {
										setClasses(e.target.value);
									}}
									onKeyUp={(e) => {
										if (e.key == "Enter") {
											let style;
											if (
												formElementRef?.current &&
												formElementRef?.current.id !==
												elementId.id
											) {
												style =
													elementId.data.css[
													`${elementRef.current?.dataset?.name}Style`
													];
												elementId.data.css[
													elementRef.current?.dataset?.name
												] = e.target.value;
											} else {
												style = elementId?.style;
												elementId.css = e.target.value;
											}
											const classesArray =
												e.target.value?.split(" ");
											const allBreakpointsStyleObject =
												classesToStyleObjectConvertor(
													elementId.id,
													classesArray,
													style,
													currentTheme
												);
											setElementAllBreakpointsStyleObject(
												allBreakpointsStyleObject
											);
											AddToUndo(
												selectedSiteData.static,
												blocks,
												sqliteSchema,
												strapiSchema,
												undo,
												setUndo,
												setRedo
											);
										}
									}}
									onBlur={(e) => {
										let style;
										if (
											formElementRef?.current &&
											formElementRef?.current.id !==
											elementId.id
										) {
											style =
												elementId.data.css[
												`${elementRef.current?.dataset?.name}Style`
												];
											elementId.data.css[
												elementRef.current?.dataset?.name
											] = e.target.value;
										} else {
											style = elementId?.style;
											elementId.css = e.target.value;
										}
										const classesArray =
											e.target.value?.split(" ");
										const allBreakpointsStyleObject =
											classesToStyleObjectConvertor(
												elementId.id,
												classesArray,
												style,
												currentTheme
											);
										setElementAllBreakpointsStyleObject(
											allBreakpointsStyleObject
										);
										AddToUndo(
											selectedSiteData.static,
											blocks,
											sqliteSchema,
											strapiSchema,
											undo,
											setUndo,
											setRedo
										);
									}}
								></textarea>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
export default Style;


export const FontFamily = ({ search, setSearch, setFontsToDisplay,
	fontsToDisplay, }) => {
	const {
		elementBreakpointSpecificStyleObject,
		isSearchingFonts,
		filteredFonts,
		fonts,
		timerref,
		setIsSearchingFonts,
		setFilteredFonts
	} = myContext
	return (
		<Listbox
			value={
				elementBreakpointSpecificStyleObject.fontFamily
			}
			onChange={(value) => {
				updateDesign("fontFamily", {
					name: value.family,
					url: value?.files?.regular?.includes(
						"https"
					)
						? `${value.files.regular}`
						: `https${value?.files?.regular?.slice(
							4
						)}`,
				});
			}}
		>
			{/* <div className="relative w-full max-w-[150px]"> */}
			<div className="relative w-max">
				<Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs text-[#373C47]`">
					<span
						className="capitalize"
						style={{
							fontFamily:
								elementBreakpointSpecificStyleObject.fontFamily,
						}}
					>
						{
							elementBreakpointSpecificStyleObject.fontFamily
						}
					</span>
					<span className="mdi mdi-chevron-down text-gray-400"></span>
				</Listbox.Button>

				<Listbox.Options
					className="absolute z-[51] right-2 max-h-60 w-[120px] mt-1 w-full overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300"
					onScroll={(e) => {
						if (
							isSearchingFonts == true
						) {
							if (
								filteredFonts.length >
								fontsToDisplay
							) {
								if (
									e.currentTarget
										.offsetHeight +
									e
										.currentTarget
										.scrollTop ==
									e.currentTarget
										.scrollHeight +
									2
								) {
									if (
										fontsToDisplay +
										10 <=
										filteredFonts.length
									) {
										setFontsToDisplay(
											fontsToDisplay +
											5
										);
									} else {
										if (
											(filteredFonts.length -
												fontsToDisplay) %
											10 !=
											0
										) {
											setFontsToDisplay(
												fontsToDisplay +
												((filteredFonts.length -
													fontsToDisplay) %
													10)
											);
										} else {
											setFontsToDisplay(
												fontsToDisplay +
												10
											);
										}
									}
								}
							}
						} else {
							if (
								fonts.length >
								fontsToDisplay
							) {
								if (
									e.currentTarget
										.offsetHeight +
									e
										.currentTarget
										.scrollTop ==
									e.currentTarget
										.scrollHeight +
									2
								) {
									if (
										fontsToDisplay +
										10 <=
										fonts.length
									) {
										setFontsToDisplay(
											fontsToDisplay +
											10
										);
									} else {
										if (
											(fonts.length -
												fontsToDisplay) %
											10 !=
											0
										) {
											setFontsToDisplay(
												fontsToDisplay +
												((fonts.length -
													fontsToDisplay) %
													10)
											);
										} else {
											setFontsToDisplay(
												fontsToDisplay +
												10
											);
										}
									}
								}
							}
						}
					}}
				>
					<div className="sticky top-0 bg-white z-[52]">
						<div className="relative shadow-lg">
							<svg
								className="w-4 h-4 text-gray-700 absolute left-1.5 top-1/4 pointer-events-none"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 20 20"
							>
								<path
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
								/>
							</svg>
							<input
								type="search"
								className="border-0 bg-gray-100 focus:ring-0 w-full py-1.5 pr-3 pl-7 text-sm"
								placeholder="Search font"
								value={search}
								onChange={(e) => {
									if (
										fontsToDisplay !=
										10
									) {
										setFontsToDisplay(
											10
										);
									}
									setSearch(
										e.target
											.value
									);
									if (
										e.target
											.value ==
										""
									) {
										setIsSearchingFonts(
											false
										);
										setFilteredFonts(
											[]
										);
									} else {
										setIsSearchingFonts(
											true
										);
										clearTimeout(
											timerref.current
										);
										timerref.current =
											setTimeout(
												() => {
													const filterSection =
														fonts.filter(
															(
																item
															) =>
																item.family
																	.toLowerCase()
																	?.includes(
																		e.target.value.toLowerCase()
																	)
														);
													setFilteredFonts(
														filterSection
													);
												},
												1000
											);
									}
								}}
								onKeyUp={(e) => {
									if (
										e.key ==
										"Enter"
									) {
										clearTimeout(
											timerref.current
										);
										const filterSection =
											fonts.filter(
												(
													item
												) =>
													item.family
														.toLowerCase()
														?.includes(
															e.target.value.toLowerCase()
														)
											);
										setFilteredFonts(
											filterSection
										);
									}
								}}
							/>
						</div>
					</div>
					{(isSearchingFonts == true
						? filteredFonts.slice(
							0,
							fontsToDisplay
						)
						: fonts.slice(
							0,
							fontsToDisplay
						)
					).map((value, index) => {
						if (value.files.regular) {
							const myFont =
								new FontFace(
									value.family,
									value?.files?.regular?.includes(
										"https"
									)
										? `url(${value.files.regular})`
										: `url('https${value?.files?.regular?.slice(
											4
										)}')`
								);
							myFont.load();
							document.fonts.add(
								myFont
							);
							return (
								<Listbox.Option
									key={`breakpointmap_${index}`}
									className={({
										active,
									}) =>
										`${active
											? "bg-gray-100"
											: ""
										} cursor-pointer`
									}
									value={value}
								>
									{({
										selected,
									}) => (
										<>
											<div
												className={`py-1.5 px-3 text-gray-900 capitalize${selected
													? " bg-indigo-100 font-medium"
													: ""
													}`}
												style={{
													fontFamily:
														value.family,
												}}
											>
												{
													value.family
												}
											</div>
										</>
									)}
								</Listbox.Option>
							);
						}
					})}
				</Listbox.Options>
			</div>
		</Listbox>
	)
}