import React, { useState } from 'react'
import { BlockWrapper } from '../Sortable'

export default function Dropdown({ block, blockIndex, handleClick }) {
	const [show, setShow] = useState(false)

	// console.log(block, "This is a dropdown")
	return (
		<div>
			<button
				type="button"
				id={block?.children[0]?.id}
				class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
				data-name="component"
				onClick={(e) => {
					handleClick(e,e?.target?.id, false)
					setShow(!show)
				}}
				onDoubleClick={(e) => {
					e.target.contentEditable = true
				}}
				onBlur={async (e) => {
					block.children[0].content = e.target.innerText
				}}
			>
				{block?.children[0]?.content}
				<svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
					<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
				</svg>
			</button>

			{
				show &&
				<div id="dropdown" class="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ">
					{block?.children &&
						block?.children[1].children?.map((childBlock, index) => (
							<BlockWrapper key={childBlock.id} block={childBlock} blockIndex={[...blockIndex, index]} />
						))
					}
				</div>
			}
		</div>
	)
}
