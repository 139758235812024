const addNewStyleClass = (updatedClasses, key, value, selectedBreakpoint, selectedState, addImportant) => {
	if (key == 'display') {
		if (value != 'none') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}${value}`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}hidden`;
		}
	} else if (key == 'flexDirection') {
		if (value == 'column') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}flex-col`;
		} else if (value == 'column-reverse') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}flex-col-reverse`;
		} else if (value == 'row-reverse') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}flex-row-reverse`;
		} else if (value == 'row') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}flex-row`;
		}
	} else if (key == 'flexWrap') {
		if (value == 'wrap') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}flex-wrap`;
		} else if (value == 'wrap-reverse') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}flex-wrap-reverse`;
		} else if (value == 'nowrap') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}flex-nowrap`;
		}
	} else if (key == 'justifyContent') {
		if (value == 'normal') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}justify-normal`;
		} else if (value == 'flex-start') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}justify-start`;
		} else if (value == 'flex-end') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}justify-end`;
		} else if (value == 'center') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}justify-center`;
		} else if (value == 'space-between') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}justify-between`;
		} else if (value == 'space-around') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}justify-around`;
		} else if (value == 'space-evenly') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}justify-evenly`;
		} else if (value == 'stretch') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}justify-stretch`;
		}
	} else if (key == 'alignItems') {
		if (value == 'baseline') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}items-baseline`;
		} else if (value == 'flex-start') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}items-start`;
		} else if (value == 'flex-end') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}items-end`;
		} else if (value == 'center') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}items-center`;
		} else if (value == 'stretch') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}items-stretch`;
		}
	} else if (key == 'alignContent') {
		if (value == 'baseline') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}content-baseline`;
		} else if (value == 'flex-start') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}content-start`;
		} else if (value == 'flex-end') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}content-end`;
		} else if (value == 'center') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}content-center`;
		} else if (value == 'stretch') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}content-stretch`;
		} else if (value == 'normal') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}content-normal`;
		} else if (value == 'space-between') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}content-between`;
		} else if (value == 'space-around') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}content-around`;
		} else if (value == 'space-evenly') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}content-evenly`;
		}
	} else if (key == 'columnGap') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}gap-x-[${value}]`;
	} else if (key == 'rowGap') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}gap-y-[${value}]`;
	} else if (key == 'alignSelf') {
		if (value == 'baseline') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}self-baseline`;
		} else if (value == 'flex-start') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}self-start`;
		} else if (value == 'flex-end') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}self-end`;
		} else if (value == 'center') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}self-center`;
		} else if (value == 'stretch') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}self-stretch`;
		} else if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}self-auto`;
		}
	} else if (key == 'flexGrow') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}grow-[${value}]`;
	} else if (key == 'flexShrink') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}shrink-[${value}]`;
	} else if (key == 'flexBasis') {
		if (value != 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}basis-[${value}]`;
		} else if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}basis-auto`;
		}
	} else if (key == 'width') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}w-auto`;
		} else if (value == 'max-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}w-max`;
		} else if (value == 'min-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}w-min`;
		} else if (value == 'fit-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}w-fit`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}w-[${value}]`;
		}
	} else if (key == 'height') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}h-auto`;
		} else if (value == 'max-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}h-max`;
		} else if (value == 'min-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}h-min`;
		} else if (value == 'fit-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}h-fit`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}h-[${value}]`;
		}
	} else if (key == 'maxWidth') {
		if (value == 'none') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}max-w-none`;
		} else if (value == 'max-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}max-w-max`;
		} else if (value == 'min-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}max-w-min`;
		} else if (value == 'fit-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}max-w-fit`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}max-w-[${value}]`;
		}
	} else if (key == 'minWidth') {
		if (value == 'max-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}min-w-max`;
		} else if (value == 'min-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}min-w-min`;
		} else if (value == 'fit-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}min-w-fit`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}min-w-[${value}]`;
		}
	} else if (key == 'maxHeight') {
		if (value == 'none') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}max-h-none`;
		} else if (value == 'max-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}max-h-max`;
		} else if (value == 'min-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}max-h-min`;
		} else if (value == 'fit-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}max-h-fit`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}max-h-[${value}]`;
		}
	} else if (key == 'minHeight') {
		if (value == 'max-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}min-h-max`;
		} else if (value == 'min-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}min-h-min`;
		} else if (value == 'fit-content') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}min-h-fit`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}min-h-[${value}]`;
		}
	} else if (key == 'padding') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}p-[${value}]`;
	} else if (key == 'paddingLeft') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}pl-[${value}]`;
	} else if (key == 'paddingTop') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}pt-[${value}]`;
	} else if (key == 'paddingRight') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}pr-[${value}]`;
	} else if (key == 'paddingBottom') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}pb-[${value}]`;
	} else if (key == 'margin') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}m-auto`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}m-[${value}]`;
		}
	} else if (key == 'marginBottom') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}mb-auto`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}mb-[${value}]`;
		}
	} else if (key == 'marginLeft') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}ml-auto`;

		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}ml-[${value}]`;
		}
	} else if (key == 'marginTop') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}mt-auto`;

		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}mt-[${value}]`;
		}
	} else if (key == 'marginRight') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}mr-auto`;

		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}mr-[${value}]`;
		}
	} else if (key == 'borderWidth' || key == 'borderColor') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}border-[${value}]`;
	} else if (key == 'borderLeftWidth') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}border-l-[${value}]`;
	} else if (key == 'borderTopWidth') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}border-t-[${value}]`;
	} else if (key == 'borderRightWidth') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}border-r-[${value}]`;
	} else if (key == 'borderBottomWidth') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}border-b-[${value}]`;
	} else if (key == 'borderStyle') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}border-${value}`;
	} else if (key == 'borderRadius') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}rounded-[${value}]`;
	} else if (key == 'borderTopRightRadius') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}rounded-tr-[${value}]`;
	} else if (key == 'borderTopLeftRadius') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}rounded-tl-[${value}]`;
	} else if (key == 'borderBottomRightRadius') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}rounded-br-[${value}]`;
	} else if (key == 'borderBottomLeftRadius') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}rounded-bl-[${value}]`;
	} else if (key == 'objectFit') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}object-${value}`;
	} else if (key == 'position') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}${value}`;
	} else if (key == 'zIndex') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}z-auto`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}z-[${value}]`;
		}
	}

	else if(key == 'listStyleImage'){
		if(value == 'none'){
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}list-image-${value}`;
		}
		else{
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}list-image-[${value}]`;
		}
	}

	else if(key == 'textShadow'){
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""} ${selectedBreakpoint}${selectedState}[text-shadow:${value}]`
	}

	else if(key == 'aspectRatio'){
		if(value == 'auto'){
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""} ${selectedBreakpoint}${selectedState}aspect-${value}`
		}
		
		else{
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""} ${selectedBreakpoint}${selectedState}aspect-[${value}]`
		}
	}

	else if(key == 'objectPosition'){
		if(value == 'bottom' || value == 'center' || value == 'left' || value == 'left-bottom' || value == 'left-top' || value == 'right' || value == 'right-bottom' || value == 'right-top' || value == 'top'){
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""} ${selectedBreakpoint}${selectedState}object-${value}`
		}
		else{
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""} ${selectedBreakpoint}${selectedState}object-[${value}]`
		}
	}

	else if(key == 'textDecoration'){
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""} ${selectedBreakpoint}${selectedState}decoration-${value}`
	}

	else if(key == 'lineThickness'){
		if(value == 'auto'){
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""} ${selectedBreakpoint}${selectedState}decoration-${value}`
		}
		else{
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""} ${selectedBreakpoint}${selectedState}decoration-[${value}]`
		}
	}

	else if(key == 'textUnderlineOffset'){
		if(value == 'auto'){
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""} ${selectedBreakpoint}${selectedState}underline-offset-${value}`
		}
		else{
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""} ${selectedBreakpoint}${selectedState}underline-offset-[${value}]`
		}
	}

	else if (key == 'letterSpacing') {
        updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}tracking-[${value}]`;
    }

	else if(key == 'lineHeight'){
		if(value == 'none'){
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}leading-${value}`;
		}
		else{
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}leading-[${value}]`;
		}
	}


	else if(key == 'wordBreak'){
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}break-${value}`;
	}

	else if(key  == 'textIndent'){
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}indent-[${value}]`;
	}

	else if(key == 'lineStyleType' || key == 'listStylePosition'){
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}list-${value}`;
	}
	
	
	 else if (key == 'left') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}left-auto`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}left-[${value}]`;
		}
	} else if (key == 'top') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}top-auto`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}top-[${value}]`;
		}
	} else if (key == 'right') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}right-auto`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}right-[${value}]`;
		}
	} else if (key == 'bottom') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}bottom-auto`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}bottom-[${value}]`;
		}
	} else if (key == 'overflow') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}overflow-${value}`;
	} else if (key == 'overflowX') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}overflow-x-${value}`;
	} else if (key == 'overflowY') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}overflow-y-${value}`;
	} else if (key == 'overflowWrap') {
		if (value == 'normal') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}break-normal`;
		} else if (value == 'break-word') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}break-words`;
		}

	} else if (key == 'textOverflow' || key == 'textAlign') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}text-${value}`;
	} else if (key == 'color' || key == 'fontSize') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}text-[${value}]`;
	} else if (key == 'textTransform') {
		if (value != 'none') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}${value}`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}normal-case`;
		}
	} else if (key == 'fontStyle') {
		if (value == 'italic') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}${value}`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}not-italic`;
		}
	} else if (key == 'whiteSpace') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}whitespace-${value}`;
	} else if (key == 'fontWeight') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}font-[${value}]`;
	} else if (key == 'textDecorationLine') {
		if (value != 'none') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}${value}`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}no-underline`;
		}
	} else if (key == 'backgroundColor') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}bg-[${value}]`;
	} else if (key == 'backgroundImage') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}bg-${value === 'none' ? 'none' : (value?.includes('gradient') ? value : `[url(${value})]`)}`;
	} else if (key == 'fromColor') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}from-[${value}]`;
	} else if (key == 'fromPercent') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}from-${value}`;
	} else if (key == 'viaColor') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}via-[${value}]`;
	} else if (key == 'viaPercent') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}via-${value}`;
	}else if (key == 'toColor') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}to-[${value}]`;
	} else if (key == 'toPercent') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}to-${value}`;
	}else if (key == 'backgroundSize') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}bg-${value}`;
	} else if (key == 'backgroundPosition') {
		const values = value.split(' ');
		if (values.length == 1) {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}bg-${value}`;
		} else if (values.length == 2) {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}bg-${values[0]}-${values[1]}`;
		}
	} else if (key == 'backgroundRepeat') {
		if (value == 'repeat' || value == 'no-repeat' || value == 'repeat-x' || value == 'repeat-y') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}bg-${value}`;
		} else if (value == 'round' || value == 'space') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}bg-repeat-${value}`;
		}
	} else if (key == 'opacity') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}opacity-[${value}]`;
	} else if (key == 'cursor') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}cursor-${value}`;
	} else if (key == 'gridAutoFlow') {
		if (value == 'column') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}grid-flow-col`;
		} else if (value == 'column dense') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}grid-flow-col-dense`;
		} else if (value == 'row dense') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}grid-flow-row-dense`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}grid-flow-${value}`;
		}
	} else if (key == 'justifyItems') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}justify-items-${value}`;
	} else if (key == 'justifySelf') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}justify-self-${value}`;
	} else if (key == 'gridTemplateRows') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}grid-rows-${value}`;
	} else if (key == 'gridTemplateColumns') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}grid-cols-${value}`;
	} else if (key == 'gridColumn') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}col-${value}`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}col-span-${value}`;
		}
	} else if (key == 'gridColumnStart') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}col-start-${value}`;
	} else if (key == 'gridColumnEnd') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}col-end-${value}`;
	} else if (key == 'gridRow') {
		if (value == 'auto') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}row-${value}`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}row-span-${value}`;
		}
	} else if (key == 'gridRowStart') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}row-start-${value}`;
	} else if (key == 'gridRowEnd') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}row-end-${value}`;
	} else if (key == 'animation') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}${value}`;
	} else if (key == 'listStyle') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}${value}`;
	} else if (key == 'verticalAlign') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}align-${value}`;
	} else if (key == 'lineHeight') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}leading-[${value}]`;
	} else if (key == 'letterSpacing') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}tracking-[${value}]`;
	} else if (key == 'blur') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}blur-[${value}]`;
	} 
	else if (key == 'contrast') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}contrast-[${value}]`;
	}
	else if (key == 'grayscale') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}grayscale-[${value}]`;
	}
	else if (key == 'hueRotate') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}hue-rotate-[${value}]`;
	}
	else if (key == 'invert') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}invert-[${value}]`;
	}
	else if (key == 'saturate') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}saturate-[${value}]`;
	}
	else if (key == 'sepia') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}sepia-[${value}]`;
	}
	else if (key == 'backdropBlur') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}backdrop-blur-[${value}]`;
	}
	else if (key == 'backdropContrast') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}backdrop-contrast-[${value}]`;
	}
	else if (key == 'backdropGrayscale') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}backdrop-grayscale-[${value}]`;
	}
	else if (key == 'backdropHueRotate') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}backdrop-hue-rotate-[${value}]`;
	}
	else if (key == 'backdropInvert') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}backdrop-invert-[${value}]`;
	}
	else if (key == 'backdropOpacity') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}backdrop-opacity-[${value}]`;
	}
	else if (key == 'backdropSaturate') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}backdrop-saturate-[${value}]`;
	}
	else if (key == 'backdropSepia') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}backdrop-sepia-[${value}]`;
	}
	else if (key == 'boxShadow') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}shadow-[${value}]`;
	} else if (key == 'outlineWidth' || key == 'outlineColor') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}outline-[${value}]`;
	} else if (key == 'outlineStyle') {
		if (value == 'solid') {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}outline`;
		} else {
			updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}outline-${value}`;
		}
	} else if (key == 'outlineOffset') {
		updatedClasses = `${updatedClasses} ${addImportant == true ? '!' : ""}${selectedBreakpoint}${selectedState}outline-offset-[${value}]`;
	}
	return updatedClasses.trim();
}
export default addNewStyleClass;