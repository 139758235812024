import { BlockWrapper } from '../Sortable';
import { useContext } from 'react';
import { context } from '../../../../context/Context';
import { useLocation, useParams } from 'react-router-dom';
const Ternary = ({ block, ...props }) => {
	const contextVariables = useContext(context);
	const params = useParams();
	const location = useLocation();
	return (
		eval(block?.data?.condition) ?
			<BlockWrapper {...props} block={block?.children[0]} blockIndex={[...props.blockIndex, 0]} />
			:
			<BlockWrapper {...props} block={block?.children[1]} blockIndex={[...props.blockIndex, 1]} />
	)
}
export default Ternary; 