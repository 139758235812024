import { BlockWrapper } from "../Sortable";
import { createElement, useEffect, useState } from "react";
import { useContext } from "react";
import { context } from "../../../../context/Context";
import { generateCss } from "../functions/sortableFunctions";
import { useLocation, useParams } from "react-router-dom";
const TimerPopup = ({ block, ...props }) => {
	const { componentWithSettingsRef, setComponent } = useContext(context);
	const contextVariables = useContext(context);
	const handleComponentWithSettingsClick = () => {
		const component = document.getElementById(block.id);
		if (componentWithSettingsRef.current != component) {
			setComponent(block);
			if (componentWithSettingsRef.current) {
				componentWithSettingsRef.current.style.outline = 'none';
			}
			componentWithSettingsRef.current = component;
			componentWithSettingsRef.current.style.outline = '2px dashed #4F46E5';
		}
	}
	const [openDialog, setOpenDialog] = useState(false);
	const params = useParams();
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => {
			setOpenDialog(true);
		}, block?.data?.openAfter)
	}, [])
	return (
		<>

			{
				openDialog &&
				createElement(
					block.data?.type ? block.data.type : 'div',
					{
						style: {
							fontFamily: block?.style?.fontFamily
						},
						className: generateCss({
							contextVariables: contextVariables,
							props: {
								...props,
								block: block
							},
							params: params,
							location: location
						}),
						id: block.id,
						...block.data,
						onMouseOver: (e) => props.handleMouseOver(e),
						onClick: (e) => {
							props.handleClick(e, block.id, false, -1, true);
							handleComponentWithSettingsClick();
						}
					},
					block.children.map((value, index) => {
						return <BlockWrapper
							{...props}
							key={index}
							block={value}
							blockIndex={[...props.blockIndex, index]}
							customComponentChildren={true}
							handleComponentWithSettingsClick={handleComponentWithSettingsClick}
							openDialog={openDialog}
							setOpenDialog={setOpenDialog}
						/>
					})
				)
			}
		</>
	)
}
export default TimerPopup;