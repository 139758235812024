import { Listbox, Popover } from '@headlessui/react';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import ColorPicker from './ColorPicker';
import { dbCall } from '../../../common/db';
import { SketchPicker } from 'react-color';
import { FontFamily } from './Style';

const AllLineTypes = [
    {
        name: "format-underline",
        property: "underline"
    },
    {
        name: "format-strikethrough-variant",
        property: "line-through"
    },
    {
        name: "format-overline",
        property: "overline"
    }
]

const AllStyles = [
    "solid",
    "double",
    "dotted",
    "dashed",
    "wavy"
];

const AllTextShadowUnits = [
    'px',
    'vh',
    'vw'
]

const temp = '0px_0px_0px_rgb(255,255,255)'

const TypographySection = ({ elementBreakpointSpecificStyleObject, valueTypes, updateDesign, setValueTypes, setElementBreakpointSpecificStyleObject, elementId, openedSections, setOpenedSections, setElementAllBreakpointsStyleObject, setSearch, search, setFontsToDisplay, fontsToDisplay }) => {
    const [moreOptions, setMoreOptions] = useState(false);
    const [textshadowToggle, setTextShadowToggle] = useState(false);
    const timerRef = useRef({
        range_1: null,
        range_2: null,
        range_3: null,
        range_4: null
    });
    return (
        <div>
            <div
                className={`flex items-center justify-between py-1.5 px-3 border-t bg-gray-100  border-gray-300  hover:bg-gray-200 cursor-pointer font-medium ${openedSections.typography == true
                    ? " border-b"
                    : ""
                    }`}
                onClick={() => {
                    setOpenedSections((prev) => {
                        return {
                            ...prev,
                            typography: !prev.typography,
                        };
                    });
                }}
            >
                <div className="text-[#373C47]">
                    Typography
                </div>

                {openedSections.typography == false ? (
                    <span class="mdi mdi-chevron-right text-xl text-[#373C47]"></span>
                ) : (
                    <span class="mdi mdi-chevron-down text-xl text-[#373C47]"></span>
                )}
            </div>
            {openedSections.typography == true && (
                <>
                    {/* Start - Font-Size */}
                    <div className="p-3 space-y-3 border-b text-[#373C47]">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-1 text-[#373C47]">
                                <p className='text-base'>Font Size</p>
                                <span class="mdi mdi-information-outline text-sm text-[#757575]" title="Set your font size to 16px or
above so it’s easy to read."></span>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="number"
                                    className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                    value={parseInt(
                                        elementBreakpointSpecificStyleObject.fontSize
                                    )}
                                    onChange={(e) => {
                                        updateDesign(
                                            "fontSize",
                                            `${e.target.value}${valueTypes.fontSize.unit}`
                                        );
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key == "Enter") {
                                            updateDesign(
                                                "fontSize",
                                                `${e.target.value}${valueTypes.fontSize.unit}`
                                            );
                                        }
                                    }}
                                    onBlur={(e) => {
                                        updateDesign(
                                            "fontSize",
                                            `${e.target.value}${valueTypes.fontSize.unit}`
                                        );
                                    }}
                                />
                                <Listbox
                                    value={valueTypes.fontSize.unit}
                                    onChange={(value) => {
                                        updateDesign(
                                            "fontSize",
                                            `${parseInt(
                                                elementBreakpointSpecificStyleObject.fontSize
                                            )}${value}`
                                        );
                                    }}
                                >
                                    <div className="relative w-max ">
                                        <Listbox.Button className={`cursor-pointer rounded-r bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs`}>
                                            <span className='uppercase'>
                                                {
                                                    valueTypes.fontSize.unit
                                                }
                                            </span>
                                            <span className="mdi mdi-chevron-down text-gray-400"></span>
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                            {[
                                                "px",
                                                "%",
                                                "vh",
                                                "vw",
                                            ].map((value, index) => (
                                                <Listbox.Option
                                                    key={`breakpointmap_${index}`}
                                                    className={({
                                                        active,
                                                    }) =>
                                                        `${active
                                                            ? "bg-gray-100"
                                                            : ""
                                                        } cursor-pointer`
                                                    }
                                                    value={value}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <div
                                                                className={`py-1.5 px-3 uppercase ${selected
                                                                    ? " bg-indigo-100 font-medium"
                                                                    : ""
                                                                    }`}
                                                            >
                                                                {value}
                                                            </div>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>
                            </div>
                        </div>
                    </div>
                    {/* End -  FontSize */}


                    {/* Start - Font-Family */}
                    <div className='p-3 space-y-3 border-b text-[#373C47]'>
                        <div className="flex items-center justify-between">
                        <div className='text-base text-[#373C47]'>Font Family</div>
                        <FontFamily setSearch={setSearch} search={search} setFontsToDisplay={setFontsToDisplay} fontsToDisplay={fontsToDisplay} />
                        </div>

                    </div>
                    {/* End - Font-Family */}

                    {/* Start - Text Decortion */}
                    <div className="p-3 border-b text-[#373C47]">
                        <p className="text-base">Text Decoration</p>
                        <div className="ml-3">
                            {/* Start - lineType */}
                            <div className="flex items-center justify-between mb-3">
                                <p className="text-sm">Line Type</p>
                                <div className="flex items-center gap-1">

                                    {
                                        AllLineTypes.map((linetype, i) => (

                                            <p className={`mdi mdi-${linetype.name} px-1.5 py-[0.6px] rounded-md cursor-pointer text-lg ${(linetype.property == elementBreakpointSpecificStyleObject.textDecorationLine) ? "bg-[#D9D9D9]" : "bg-white"
                                                }`} onClick={() => {
                                                    elementBreakpointSpecificStyleObject.textDecorationLine == linetype.property ? updateDesign("textDecorationLine", 'none') : updateDesign("textDecorationLine", linetype.property)


                                                }}></p>
                                        ))
                                    }

                                </div>

                            </div>

                            {/* Start - Style */}
                            <div className="flex items-center justify-between mb-3">
                                <p className='text-sm'>Style</p>

                                <Listbox
                                    value={
                                        elementBreakpointSpecificStyleObject.textDecoration
                                    }
                                    onChange={(value) => {
                                        updateDesign("textDecoration", value);
                                    }}
                                >
                                    <div className="relative w-max ">
                                        <Listbox.Button className=" ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                            <span className="capitalize">
                                                {
                                                    elementBreakpointSpecificStyleObject.textDecoration
                                                }
                                            </span>

                                            <span className="mdi mdi-chevron-down text-gray-400"></span>
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                            {AllStyles.map((value, index) => (
                                                <Listbox.Option
                                                    key={`breakpointmap_${index}`}
                                                    className={({
                                                        active,
                                                    }) =>
                                                        `${active
                                                            ? "bg-gray-100"
                                                            : ""
                                                        } cursor-pointer`
                                                    }
                                                    value={value}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <div
                                                                className={`py-1.5 px-3 text-gray-900 capitalize ${selected
                                                                    ? " bg-indigo-100 font-medium"
                                                                    : ""
                                                                    }`}
                                                            >
                                                                {value}
                                                            </div>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>

                            </div>
                            {/* End - Style */}

                            {/* Start - Thickeness */}
                            <div className="flex items-center justify-between mb-3">
                                <p className="text-sm">Thickness</p>

                                <div className="flex items-center w-max ml-auto">
                                    {
                                        valueTypes?.lineThickness?.unit !== 'auto' && (
                                            <input
                                                type="number"
                                                className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 text-sm focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                                value={parseInt(
                                                    elementBreakpointSpecificStyleObject.lineThickness
                                                )}
                                                onChange={(
                                                    e
                                                ) => {
                                                    setElementBreakpointSpecificStyleObject(
                                                        (
                                                            prev
                                                        ) => {
                                                            return {
                                                                ...prev,
                                                                lineThickness: `${e.target.value}${valueTypes.lineThickness.unit}`,
                                                            };
                                                        }
                                                    );
                                                }}
                                                onKeyUp={(
                                                    e
                                                ) => {
                                                    if (
                                                        e.key == "Enter"
                                                    ) {
                                                        updateDesign(
                                                            'lineThickness',
                                                            `${e.target.value}${valueTypes?.lineThickness?.unit}`
                                                        );
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    updateDesign(
                                                        'lineThickness',
                                                        `${e.target.value}${valueTypes?.lineThickness?.unit}`
                                                    );
                                                }}
                                            />
                                        )
                                    }
                                    <Listbox
                                        value={
                                            valueTypes?.lineThickness?.unit
                                        }
                                        onChange={(
                                            value
                                        ) => {
                                            if (value === 'auto') {
                                                updateDesign(
                                                    'lineThickness',
                                                    `auto`
                                                );
                                            }
                                            else {
                                                let prevValue = parseInt(
                                                    elementBreakpointSpecificStyleObject.lineThickness)
                                                updateDesign(
                                                    'lineThickness',
                                                    `${isNaN(prevValue) ? 0 : prevValue}${value}`
                                                );
                                            }
                                        }}
                                    >
                                        <div className="relative  w-max ml-auto">
                                            <Listbox.Button className={`${valueTypes?.lineThickness?.unit === 'auto' ? "capitalize rounded" : "rounded-r uppercase"} bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs cursor-pointer `}>
                                                <span className=''>
                                                    {
                                                        valueTypes?.lineThickness?.unit
                                                    }
                                                </span>

                                                <span className="mdi mdi-chevron-down text-gray-400"></span>
                                            </Listbox.Button>
                                            <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                {[
                                                    "px",
                                                    "%",
                                                    "vh",
                                                    "vw",
                                                    "auto"
                                                ].map(
                                                    (
                                                        value,
                                                        index
                                                    ) => (
                                                        <Listbox.Option
                                                            key={`breakpointmap_${index}`}
                                                            className={({
                                                                active,
                                                            }) =>
                                                                `${active
                                                                    ? "bg-gray-100"
                                                                    : ""
                                                                } cursor-pointer`
                                                            }
                                                            value={
                                                                value
                                                            }
                                                        >
                                                            {({
                                                                selected,
                                                            }) => (
                                                                <>
                                                                    <div
                                                                        className={`${value == 'auto' ? 'capitalize' : 'uppercase'} py-1.5 px-3 text-gray-900${selected
                                                                            ? " bg-indigo-100 font-medium"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        {
                                                                            value
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                )}
                                            </Listbox.Options>
                                        </div>
                                    </Listbox>
                                </div>
                            </div>
                            {/* End - Thickeness */}

                            {/* Start - Color */}
                            <div className={`flex items-center justify-between ${elementBreakpointSpecificStyleObject.textDecorationLine != 'none' ? 'mb-3' : ''}`}>
                                <div className="text-sm">
                                    Color
                                </div>
                                <ColorPicker
                                    setElementBreakpointSpecificStyleObject={
                                        setElementBreakpointSpecificStyleObject
                                    }
                                    setElementAllBreakpointsStyleObject={
                                        setElementAllBreakpointsStyleObject
                                    }
                                    elementBreakpointSpecificStyleObject={
                                        elementBreakpointSpecificStyleObject
                                    }
                                    updateDesign={updateDesign}
                                    field={"color"}
                                />
                            </div>
                            {/* End - Color */}

                            {/* Start - TextunderlineOffset */}
                            {
                                elementBreakpointSpecificStyleObject.textDecorationLine != 'none' && (
                                    <div className="">
                                        <div className="flex items-center justify-between mb-2">
                                            <p className="text-sm">Text-underline-offset</p>
                                            <span class="mdi mdi-information-outline text-sm text-[#757575]" title="Spacing between decoration line
and content."></span>
                                        </div>

                                        <div className="flex items-center gap-1">
                                            {
                                                elementBreakpointSpecificStyleObject.textUnderlineOffset !== 'auto' && (
                                                    <input type='range' min={-10} max={100} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
                                                        value={parseInt(
                                                            elementBreakpointSpecificStyleObject.textUnderlineOffset
                                                        ) ? parseInt(
                                                            elementBreakpointSpecificStyleObject.textUnderlineOffset
                                                        ) : 0}

                                                        onChange={(e) => {
                                                            updateDesign(
                                                                'textUnderlineOffset',
                                                                `${e.target.value}${valueTypes.textUnderlineOffset.unit}`
                                                            );
                                                        }} />
                                                )
                                            }

                                            <div className="flex items-center w-max ml-auto">
                                                {
                                                    valueTypes.textUnderlineOffset.unit !== 'auto' && (
                                                        <input
                                                            type="number"
                                                            className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 text-sm focus:ring-0  focus:border-[#D9D9D9] shadow text-xs ${valueTypes.textUnderlineOffset.unit === 'auto' ? "rounded" : "rounded-l"}`}
                                                            value={parseInt(
                                                                elementBreakpointSpecificStyleObject.textUnderlineOffset
                                                            )}
                                                            onChange={(
                                                                e
                                                            ) => {
                                                                updateDesign(
                                                                    'textUnderlineOffset',
                                                                    `${e.target.value}${valueTypes.textUnderlineOffset.unit}`
                                                                );
                                                            }}
                                                            onKeyUp={(
                                                                e
                                                            ) => {
                                                                if (
                                                                    e.key == "Enter"
                                                                ) {
                                                                    updateDesign(
                                                                        'textUnderlineOffset',
                                                                        `${e.target.value}${valueTypes.textUnderlineOffset.unit}`
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                updateDesign(
                                                                    'textUnderlineOffset',
                                                                    `${e.target.value}${valueTypes.textUnderlineOffset.unit}`
                                                                );
                                                            }}
                                                        />
                                                    )
                                                }
                                                <Listbox
                                                    value={
                                                        valueTypes.textUnderlineOffset.unit
                                                    }
                                                    onChange={(
                                                        value
                                                    ) => {
                                                        if (value === 'auto') {
                                                            updateDesign(
                                                                'textUnderlineOffset',
                                                                `auto`
                                                            );
                                                        }
                                                        else {
                                                            let prevValue = parseInt(
                                                                elementBreakpointSpecificStyleObject.textUnderlineOffset)
                                                            updateDesign(
                                                                'textUnderlineOffset',
                                                                `${isNaN(prevValue) ? 0 : prevValue}${value}`
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <div className="relative  w-max ml-auto text-xs">
                                                        <Listbox.Button className={`cursor-pointer ${valueTypes.textUnderlineOffset.unit === 'auto' ? "capitalize rounded" : "uppercase rounded-r"}  bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1`}>
                                                            <span className=''>
                                                                {
                                                                    valueTypes.textUnderlineOffset.unit
                                                                }
                                                            </span>

                                                            <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                        </Listbox.Button>
                                                        <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                            {[
                                                                "px",
                                                                "%",
                                                                "vh",
                                                                "vw",
                                                                "auto"
                                                            ].map(
                                                                (
                                                                    value,
                                                                    index
                                                                ) => (
                                                                    <Listbox.Option
                                                                        key={`breakpointmap_${index}`}
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            `${active
                                                                                ? "bg-gray-100"
                                                                                : ""
                                                                            } cursor-pointer`
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {({
                                                                            selected,
                                                                        }) => (
                                                                            <>
                                                                                <div
                                                                                    className={`${value == 'auto' ? 'capitalize' : 'uppercase'} py-1.5 px-3 text-gray-900${selected
                                                                                        ? " bg-indigo-100 font-medium"
                                                                                        : ""
                                                                                        }`}
                                                                                >
                                                                                    {
                                                                                        value
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                )
                                                            )}
                                                        </Listbox.Options>
                                                    </div>
                                                </Listbox>
                                            </div>
                                        </div>


                                    </div>
                                )
                            }
                            {/* End - TextunderlineOffset */}
                        </div>
                    </div>
                    {/* End - Text Decoration */}

                    {/* Start - Line/Character space */}
                    <div className="p-3 space-y-3 border-b text-[#373C47]">
                        <p className='text-base'>Character & line spacing</p>
                        {/* Start - Letter spacing */}
                        <div className="flex items-center gap-2">
                            <div className="flex items-center gap-1">
                                <p class="mdi mdi-format-line-spacing mdi-rotate-270"></p>
                                <p>Letter</p>
                            </div>
                            <div className="flex items-center gap-1">
                                <input type='range' min={-10} max={100} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
                                    value={parseInt(
                                        elementBreakpointSpecificStyleObject.letterSpacing
                                    ) ? parseInt(
                                        elementBreakpointSpecificStyleObject.letterSpacing
                                    ) : 0}

                                    onChange={(e) => {

                                        updateDesign(
                                            'letterSpacing',
                                            `${e.target.value}${valueTypes.letterSpacing.unit}`
                                        );
                                    }} />

                                <div className="flex items-center w-max ml-auto">
                                    <input
                                        type="number"
                                        className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                        value={parseInt(
                                            elementBreakpointSpecificStyleObject.letterSpacing
                                        )}
                                        onChange={(
                                            e
                                        ) => {

                                            updateDesign(
                                                'letterSpacing',
                                                `${e.target.value}${valueTypes.letterSpacing.unit}`
                                            );
                                        }}
                                        onKeyUp={(
                                            e
                                        ) => {
                                            if (
                                                e.key == "Enter"
                                            ) {
                                                updateDesign(
                                                    'letterSpacing',
                                                    `${e.target.value}${valueTypes.letterSpacing.unit}`
                                                );
                                            }
                                        }}
                                        onBlur={(e) => {
                                            updateDesign(
                                                'letterSpacing',
                                                `${e.target.value}${valueTypes.letterSpacing.unit}`
                                            );
                                        }}
                                    />

                                    <Listbox
                                        value={
                                            valueTypes.letterSpacing.unit
                                        }
                                        onChange={(
                                            value
                                        ) => {

                                            let prevValue = parseInt(
                                                elementBreakpointSpecificStyleObject.letterSpacing)
                                            updateDesign(
                                                'letterSpacing',
                                                `${isNaN(prevValue) ? 0 : prevValue}${value}`
                                            );

                                        }}
                                    >
                                        <div className="relative  w-max ml-auto text-xs">
                                            <Listbox.Button className={`cursor-pointer rounded-r bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1`}>
                                                <span className='uppercase'>
                                                    {
                                                        valueTypes.letterSpacing.unit

                                                    }

                                                </span>

                                                <span className="mdi mdi-chevron-down text-gray-400"></span>
                                            </Listbox.Button>
                                            <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                {[
                                                    "px",
                                                    "vh",
                                                    "vw",
                                                ].map(
                                                    (
                                                        value,
                                                        index
                                                    ) => (
                                                        <Listbox.Option
                                                            key={`breakpointmap_${index}`}
                                                            className={({
                                                                active,
                                                            }) =>
                                                                `${active
                                                                    ? "bg-gray-100"
                                                                    : ""
                                                                } cursor-pointer`
                                                            }
                                                            value={
                                                                value
                                                            }
                                                        >
                                                            {({
                                                                selected,
                                                            }) => (
                                                                <>
                                                                    <div
                                                                        className={`uppercase py-1.5 px-3 text-gray-900${selected
                                                                            ? " bg-indigo-100 font-medium"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        {
                                                                            value
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                )}
                                            </Listbox.Options>
                                        </div>
                                    </Listbox>
                                </div>
                            </div>
                        </div>
                        {/* Start - Line Height */}
                        <div className="flex items-center  gap-2">
                            <div className="flex items-center gap-1">
                                <p class="mdi mdi-format-line-spacing"></p>
                                <p>Line</p>
                            </div>

                            <div className="flex items-center gap-1  w-max ml-auto">
                                {
                                    elementBreakpointSpecificStyleObject.lineHeight !== 'none' && (

                                        <input type='range' min={0} max={100} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
                                            value={parseInt(
                                                elementBreakpointSpecificStyleObject.lineHeight
                                            ) ? parseInt(
                                                elementBreakpointSpecificStyleObject.lineHeight
                                            ) : 0}

                                            onChange={(e) => {
                                                setElementBreakpointSpecificStyleObject(
                                                    (prev) => {
                                                        return {
                                                            ...prev,
                                                            lineHeight: `${e.target.value}`,
                                                        };
                                                    }
                                                );
                                            }} />
                                    )
                                }

                                <div className="flex items-center w-max ml-auto">
                                    {
                                        elementBreakpointSpecificStyleObject.lineHeight != 'none' && (

                                            <input
                                                type="number"
                                                className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 text-sm focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                                value={parseInt(
                                                    elementBreakpointSpecificStyleObject.lineHeight
                                                )}
                                                onChange={(
                                                    e
                                                ) => {
                                                    setElementBreakpointSpecificStyleObject(
                                                        (
                                                            prev
                                                        ) => {
                                                            return {
                                                                ...prev,
                                                                lineHeight: `${e.target.value}${valueTypes.lineHeight.unit}`,
                                                            };
                                                        }
                                                    );
                                                }}
                                                onKeyUp={(
                                                    e
                                                ) => {
                                                    if (
                                                        e.key == "Enter"
                                                    ) {
                                                        updateDesign(
                                                            'lineHeight',
                                                            `${e.target.value}${valueTypes.lineHeight.unit}`
                                                        );
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    updateDesign(
                                                        'lineHeight',
                                                        `${e.target.value}${valueTypes.lineHeight.unit}`
                                                    );
                                                }}
                                            />
                                        )
                                    }

                                    <Listbox
                                        value={
                                            valueTypes.lineHeight.unit
                                        }
                                        onChange={(
                                            value
                                        ) => {

                                            if (value == 'none') {
                                                updateDesign('lineHeight', 'none');
                                            }
                                            else {
                                                let prevValue = parseInt(
                                                    elementBreakpointSpecificStyleObject.lineHeight)
                                                updateDesign(
                                                    'lineHeight',
                                                    `${isNaN(prevValue) ? 0 : prevValue}${value}`
                                                );
                                            }

                                        }}
                                    >
                                        <div className="relative  w-max ml-auto text-xs">
                                            <Listbox.Button className={`${valueTypes.lineHeight.unit == 'none' ? 'ml-auto rounded' : 'rounded-r'} bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs cursor-pointer `}>
                                                <span className={` ${valueTypes.lineHeight.unit == 'none' ? 'capitalize' : 'uppercase'}`}>
                                                    {
                                                        valueTypes.lineHeight.unit
                                                    }

                                                </span>

                                                <span className="mdi mdi-chevron-down text-gray-400"></span>
                                            </Listbox.Button>
                                            <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                {[
                                                    "px",
                                                    '%',
                                                    "vh",
                                                    "vw",
                                                    'none'

                                                ].map(
                                                    (
                                                        value,
                                                        index
                                                    ) => (
                                                        <Listbox.Option
                                                            key={`breakpointmap_${index}`}
                                                            className={({
                                                                active,
                                                            }) =>
                                                                `${active
                                                                    ? "bg-gray-100"
                                                                    : ""
                                                                } cursor-pointer`
                                                            }
                                                            value={
                                                                value
                                                            }
                                                        >
                                                            {({
                                                                selected,
                                                            }) => (
                                                                <>
                                                                    <div
                                                                        className={`${value == 'none' ? 'capitalize' : 'uppercase'} py-1.5 px-3 text-gray-900${selected
                                                                            ? " bg-indigo-100 font-medium"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        {
                                                                            value
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                )}
                                            </Listbox.Options>
                                        </div>
                                    </Listbox>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* End - Line/Character space */}

                    {/* Start - WhiteSpace */}
                    <div className="p-3 space-y-3 border-b text-[#373C47]">
                        <div className="flex items-center justify-between">
                            <p className='text-base'>White Space</p>

                            <Listbox
                                value={
                                    elementBreakpointSpecificStyleObject.whiteSpace
                                }
                                onChange={(value) => {
                                    updateDesign("whiteSpace", value);
                                }}
                            >
                                <div className="relative w-full max-w-[130px]">
                                    <Listbox.Button className="ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                        <span className="capitalize">
                                            {
                                                elementBreakpointSpecificStyleObject.whiteSpace
                                            }
                                        </span>

                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                    </Listbox.Button>
                                    <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                        {[
                                            "normal",
                                            "nowrap",
                                            "pre",
                                            "pre-line",
                                            "pre-wrap",
                                            "break-spaces",
                                        ].map((value, index) => (
                                            <Listbox.Option
                                                key={`breakpointmap_${index}`}
                                                className={({
                                                    active,
                                                }) =>
                                                    `${active
                                                        ? "bg-gray-100"
                                                        : ""
                                                    } cursor-pointer`
                                                }
                                                value={value}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <div
                                                            className={`py-1.5 px-3 text-gray-900 capitalize${selected
                                                                ? " bg-indigo-100 font-medium"
                                                                : ""
                                                                }`}
                                                        >
                                                            {value}
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </div>
                            </Listbox>

                        </div>
                    </div>
                    {/* End - WhiteSpace */}

                    {/* Start - WordBreak */}
                    <div className="p-3 space-y-3 border-b text-[#373C47]">
                        <div className="flex items-center justify-between">
                            <p className='text-base'>Word Break</p>

                            <Listbox
                                value={
                                    elementBreakpointSpecificStyleObject.wordBreak ? elementBreakpointSpecificStyleObject.wordBreak : 'normal'
                                }
                                onChange={(value) => {
                                    updateDesign("wordBreak", value);
                                }}
                            >
                                <div className="relative w-full max-w-[130px]">
                                    <Listbox.Button className=" ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                        <span className="capitalize">
                                            {
                                                elementBreakpointSpecificStyleObject.wordBreak ? elementBreakpointSpecificStyleObject.wordBreak : 'normal'
                                            }
                                        </span>

                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                    </Listbox.Button>
                                    <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                        {[
                                            "normal",
                                            "words",
                                            "all",
                                            "keep"
                                        ].map((value, index) => (
                                            <Listbox.Option
                                                key={`breakpointmap_${index}`}
                                                className={({
                                                    active,
                                                }) =>
                                                    `${active
                                                        ? "bg-gray-100"
                                                        : ""
                                                    } cursor-pointer`
                                                }
                                                value={value}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <div
                                                            className={`py-1.5 px-3 text-gray-900 capitalize${selected
                                                                ? " bg-indigo-100 font-medium"
                                                                : ""
                                                                }`}
                                                        >
                                                            {value}
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </div>
                            </Listbox>

                        </div>
                    </div>
                    {/* End - WordBreak */}



                    {/* Start - More Options */}
                    <div
                        className="bg-[#F3F4F6] border-y border-[#D9D9D9] flex items-center justify-center py-1 cursor-pointer"
                        onClick={() => { setMoreOptions(!moreOptions) }}>
                        <p className=''>More options</p>
                        <span className={`mdi mdi-chevron-${moreOptions ? "up" : "down"} text-lg`}></span>

                    </div>
                    {/* End - More Options */}


                    {
                        moreOptions === true && (
                            <div className=''>
                                <div className="border-b text-[#373C47] p-3 space-y-3">
                                    <div className="flex items-center justify-between gap-2">

                                        <p className=" text-base">Text indent</p>

                                        <div className="flex items-center w-max ml-auto">
                                            <input
                                                type="number"
                                                className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                                value={parseInt(
                                                    elementBreakpointSpecificStyleObject.textIndent
                                                )}
                                                onChange={(
                                                    e
                                                ) => {
                                                    updateDesign(
                                                        'textIndent',
                                                        `${e.target.value}${valueTypes.textIndent.unit}`
                                                    );
                                                }}
                                                onKeyUp={(
                                                    e
                                                ) => {
                                                    if (
                                                        e.key == "Enter"
                                                    ) {
                                                        updateDesign(
                                                            'textIndent',
                                                            `${e.target.value}${valueTypes.textIndent.unit}`
                                                        );
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    updateDesign(
                                                        'textIndent',
                                                        `${e.target.value}${valueTypes.textIndent.unit}`
                                                    );
                                                }}
                                            />

                                            <Listbox
                                                value={
                                                    valueTypes.textIndent.unit
                                                }
                                                onChange={(
                                                    value
                                                ) => {

                                                    let prevValue = parseInt(
                                                        elementBreakpointSpecificStyleObject.textIndent)
                                                    updateDesign(
                                                        'textIndent',
                                                        `${isNaN(prevValue) ? 0 : prevValue}${value}`
                                                    );

                                                }}
                                            >
                                                <div className="relative  w-max ml-auto text-xs">
                                                    <Listbox.Button className={`cursor-pointer rounded-r bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1`}>
                                                        <span className='uppercase'>
                                                            {
                                                                valueTypes.textIndent.unit

                                                            }

                                                        </span>

                                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                    </Listbox.Button>
                                                    <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                        {[
                                                            "px",
                                                            '%',
                                                            "vh",
                                                            "vw",
                                                        ].map(
                                                            (
                                                                value,
                                                                index
                                                            ) => (
                                                                <Listbox.Option
                                                                    key={`breakpointmap_${index}`}
                                                                    className={({
                                                                        active,
                                                                    }) =>
                                                                        `${active
                                                                            ? "bg-gray-100"
                                                                            : ""
                                                                        } cursor-pointer`
                                                                    }
                                                                    value={
                                                                        value
                                                                    }
                                                                >
                                                                    {({
                                                                        selected,
                                                                    }) => (
                                                                        <>
                                                                            <div
                                                                                className={`uppercase py-1.5 px-3 text-gray-900${selected
                                                                                    ? " bg-indigo-100 font-medium"
                                                                                    : ""
                                                                                    }`}
                                                                            >
                                                                                {
                                                                                    value
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            )
                                                        )}
                                                    </Listbox.Options>
                                                </div>
                                            </Listbox>
                                        </div>
                                    </div>

                                </div>

                                <div className="border-b text-[#373C47] p-3 space-y-3">
                                    <div className="flex items-center justify-between ">

                                        <p className='text-base'>Text Overflow</p>

                                        <Listbox
                                            value={
                                                elementBreakpointSpecificStyleObject.textOverflow
                                            }
                                            onChange={(value) => {
                                                updateDesign("textOverflow", value);
                                            }}
                                        >
                                            <div className="relative w-full max-w-[130px]">
                                                <Listbox.Button className=" ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                    <span className="capitalize">
                                                        {
                                                            elementBreakpointSpecificStyleObject.textOverflow
                                                        }
                                                    </span>

                                                    <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                </Listbox.Button>
                                                <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                    {[
                                                        "ellipsis",
                                                        "clip"
                                                    ].map((value, index) => (
                                                        <Listbox.Option
                                                            key={`breakpointmap_${index}`}
                                                            className={({
                                                                active,
                                                            }) =>
                                                                `${active
                                                                    ? "bg-gray-100"
                                                                    : ""
                                                                } cursor-pointer`
                                                            }
                                                            value={value}
                                                        >
                                                            {({ selected }) => (
                                                                <>
                                                                    <div
                                                                        className={`py-1.5 px-3 text-gray-900 capitalize${selected
                                                                            ? " bg-indigo-100 font-medium"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        {value}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </div>
                                        </Listbox>
                                    </div>

                                </div>

                                {/* Start - Line style */}
                                <div className="border-b text-[#373C47] p-3 space-y-3">
                                    <p className="text-base mb-2">
                                        Line Style
                                    </p>
                                    <div className="ml-3">
                                        <div className="flex items-center justify-between gap-2 mb-2">
                                            <p className="text-sm">Type</p>
                                            {/* <div className="flex items-center">
                                                <p 
                                                    className={`text-sm px-1.5 py-[1px] rounded-md cursor-pointer ${currentListStyle === 'normal' ? "bg-[#D9D9D9]" : "bg-white"}`} 
                                                    onClick={()=> setCurrentListStyle('normal')}>Normal</p>
                                                <p 
                                                    className={`text-sm px-1.5 py-[1px] rounded-md cursor-pointer ${currentListStyle === 'image' ? "bg-[#D9D9D9]" : "bg-white"}`} 
                                                    onClick={()=> setCurrentListStyle('image')}>Image</p>
                                            </div> */}
                                            <Listbox
                                                value={
                                                    elementBreakpointSpecificStyleObject.lineStyleType
                                                }
                                                onChange={(value) => {
                                                    updateDesign("lineStyleType", value);
                                                    setTimeout(() => {
                                                        updateDesign('display', 'list-item')
                                                    }, 700)
                                                }}
                                            >
                                                <div className="relative w-full max-w-[130px]">
                                                    <Listbox.Button className=" ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                        <span className="capitalize">
                                                            {
                                                                elementBreakpointSpecificStyleObject.lineStyleType
                                                            }
                                                        </span>

                                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                    </Listbox.Button>
                                                    <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                        {[
                                                            "none",
                                                            "disc",
                                                            "decimal"
                                                        ].map((value, index) => (
                                                            <Listbox.Option
                                                                key={`breakpointmap_${index}`}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    `${active
                                                                        ? "bg-gray-100"
                                                                        : ""
                                                                    } cursor-pointer`
                                                                }
                                                                value={value}
                                                            >
                                                                {({ selected }) => (
                                                                    <>
                                                                        <div
                                                                            className={`py-1.5 px-3 text-gray-900 capitalize${selected
                                                                                ? " bg-indigo-100 font-medium"
                                                                                : ""
                                                                                }`}
                                                                        >
                                                                            {value}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </div>
                                            </Listbox>
                                            {/* {
                                                currentListStyle == 'normal' && (

                                                )
                                            } */}
                                            {/* {
                                                currentListStyle == 'image' && (
                                                    <>
                                                    <label for='dropzone-file' className="border rounded py-1 px-2">Upload</label>
                                                    <input type="file" className='hidden'  id="dropzone-file" onChange={async (e)=> await handleUpload(e.target.files[0])} />
                                                    </>
                                                )
                                            } */}

                                        </div>

                                        <div className="flex items-center justify-between">
                                            <p className="text-sm">Position</p>
                                            <Listbox
                                                value={
                                                    elementBreakpointSpecificStyleObject.listStylePosition
                                                }
                                                onChange={(value) => {
                                                    updateDesign("listStylePosition", value);
                                                }}
                                            >
                                                <div className="relative w-full max-w-[130px]">
                                                    <Listbox.Button className=" ml-auto w-max cursor-pointer rounded bg-white px-2 py-1 border border-gray-300 shadow flex items-center justify-between gap-1 text-xs">
                                                        <span className="capitalize">
                                                            {
                                                                elementBreakpointSpecificStyleObject.listStylePosition
                                                            }
                                                        </span>

                                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                    </Listbox.Button>
                                                    <Listbox.Options className="absolute z-[51] max-h-60 mt-1 w-full overflow-x-visible overflow-y-auto rounded-md bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                        {[
                                                            "inside",
                                                            "outside"
                                                        ].map((value, index) => (
                                                            <Listbox.Option
                                                                key={`breakpointmap_${index}`}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    `${active
                                                                        ? "bg-gray-100"
                                                                        : ""
                                                                    } cursor-pointer`
                                                                }
                                                                value={value}
                                                            >
                                                                {({ selected }) => (
                                                                    <>
                                                                        <div
                                                                            className={`py-1.5 px-3 text-gray-900 capitalize${selected
                                                                                ? " bg-indigo-100 font-medium"
                                                                                : ""
                                                                                }`}
                                                                        >
                                                                            {value}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </div>
                                            </Listbox>
                                        </div>

                                    </div>
                                </div>
                                {/* End - Line style */}

                                {/* Start -  Text-shadows */}
                                <div className="border-b text-[#373C47] p-3 space-y-3">
                                    <div className="flex items-center justify-between gap-1 mb-2">
                                        <p className='text-base'>Text Shadows</p>
                                        < p
                                            className='mdi mdi-plus text-xl font-semibold cursor-pointer'
                                            onClick={() => setTextShadowToggle(!textshadowToggle)} />
                                    </div>
                                    {
                                        elementBreakpointSpecificStyleObject.textShadow && (
                                            <div className='flex items-center gap-2 ml-3 mb-2 text-sm text-[#373C47]'>
                                                <span className=''>Text Shadow:</span>
                                                <span>{elementBreakpointSpecificStyleObject?.textShadow?.split('_')[0]}</span>
                                                <span>{elementBreakpointSpecificStyleObject?.textShadow?.split('_')[1]}</span>
                                                <span>{elementBreakpointSpecificStyleObject?.textShadow?.split('_')[2]}</span>
                                                <span className={`bg-[${elementBreakpointSpecificStyleObject?.textShadow?.split('_')[3]}] w-5 h-4 rounded`}></span>

                                            </div>
                                        )
                                    }
                                    {
                                        textshadowToggle && (
                                            <div className='ml-3 '>
                                                <div className="flex items-center gap-2 justify-between mb-2 text-[#373C47]">
                                                    <p className='text-sm'>X</p>
                                                    <div className="flex items-center gap-1">
                                                        <input type='range' min={-100} max={100} className='w-full h-[1px] outline-none bg-[#757575]   accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
                                                            value={parseInt(
                                                                elementBreakpointSpecificStyleObject?.textShadow?.split('_')[0]?.replace(/[!@#$%^&*a-z]/g, "") || 0
                                                            )}

                                                            onChange={(e) => {
                                                                const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_')
                                                                let str = `${e.target.value}${valueTypes.textShadow.unit_1}_${values[1]}_${values[2]}_${values[3]}`;
                                                                setElementBreakpointSpecificStyleObject((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        textShadow: str
                                                                    };
                                                                })

                                                                clearTimeout(timerRef.current.range_1);
                                                                timerRef.current.range_1 = setTimeout(
                                                                    () => {
                                                                        updateDesign("textShadow", str);
                                                                    },
                                                                    700
                                                                );


                                                            }} />

                                                        <div className="flex items-center w-max ml-auto">
                                                            <input
                                                                type="number"
                                                                className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                                                value={parseInt(
                                                                    elementBreakpointSpecificStyleObject?.textShadow?.split('_')[0]?.replace(/[!@#$%^&*a-z]/g, "") || 0
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {

                                                                    const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                    let str = `${e.target.value}${valueTypes.textShadow.unit_1}_${values[1]}_${values[2]}_${values[3]}`
                                                                    updateDesign("textShadow", str);


                                                                }}
                                                                onKeyUp={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.key == "Enter"
                                                                    ) {
                                                                        const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                        let str = `${e.target.value}${valueTypes.textShadow.unit_1}_${values[1]}_${values[2]}_${values[3]}`
                                                                        updateDesign("textShadow", str);
                                                                    }
                                                                }}
                                                                onBlur={(e) => {

                                                                    const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                    let str = `${e.target.value}${valueTypes.textShadow.unit_1}_${values[1]}_${values[2]}_${values[3]}`
                                                                    updateDesign("textShadow", str);
                                                                }}
                                                            />

                                                            <Listbox
                                                                value={
                                                                    valueTypes.textShadow.unit_1
                                                                }
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                    let str = `${values[0]?.replace(/[!@#$%^&*a-z]/g, "") || 0}${value}_${values[1]}_${values[2]}_${values[3]}`
                                                                    updateDesign("textShadow", str);

                                                                }}
                                                            >
                                                                <div className="relative  w-max ml-auto text-xs">
                                                                    <Listbox.Button className={`cursor-pointer rounded-r bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1`}>
                                                                        <span className='uppercase'>
                                                                            {valueTypes.textShadow.unit_1}

                                                                        </span>

                                                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                                    </Listbox.Button>
                                                                    <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                                        {AllTextShadowUnits.map(
                                                                            (
                                                                                value,
                                                                                index
                                                                            ) => (
                                                                                <Listbox.Option
                                                                                    key={`breakpointmap_${index}`}
                                                                                    className={({
                                                                                        active,
                                                                                    }) =>
                                                                                        `${active
                                                                                            ? "bg-gray-100"
                                                                                            : ""
                                                                                        } cursor-pointer`
                                                                                    }
                                                                                    value={
                                                                                        value
                                                                                    }
                                                                                >
                                                                                    {({
                                                                                        selected,
                                                                                    }) => (
                                                                                        <>
                                                                                            <div
                                                                                                className={`uppercase py-1.5 px-3 text-gray-900${selected
                                                                                                    ? " bg-indigo-100 font-medium"
                                                                                                    : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {
                                                                                                    value
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </Listbox.Option>
                                                                            )
                                                                        )}
                                                                    </Listbox.Options>
                                                                </div>
                                                            </Listbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-2 justify-between mb-2 text-[#373C47]">
                                                    <p className='text-sm'>Y</p>
                                                    <div className="flex items-center gap-1">
                                                        <input type='range' min={-100} max={100} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
                                                            value={parseInt(
                                                                elementBreakpointSpecificStyleObject?.textShadow?.split('_')[1]?.replace(/[!@#$%^&*a-z]/g, "") || 0
                                                            )}

                                                            onChange={(e) => {
                                                                const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                let str = `${values[0]}_${e.target.value}${valueTypes.textShadow.unit_2}_${values[2]}_${values[3]}`;
                                                                setElementBreakpointSpecificStyleObject((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        textShadow: str
                                                                    };
                                                                })

                                                                clearTimeout(timerRef.current.range_2);
                                                                timerRef.current.range_2 = setTimeout(
                                                                    () => {
                                                                        updateDesign("textShadow", str);
                                                                    },
                                                                    700
                                                                );


                                                            }} />

                                                        <div className="flex items-center w-max ml-auto">
                                                            <input
                                                                type="number"
                                                                className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                                                value={parseInt(
                                                                    elementBreakpointSpecificStyleObject?.textShadow?.split('_')[1]?.replace(/[!@#$%^&*a-z]/g, "") || 0
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {

                                                                    const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                    let str = `${values[0]}_${e.target.value}${valueTypes.textShadow.unit_2}_${values[2]}_${values[3]}`;
                                                                    updateDesign("textShadow", str);


                                                                }}
                                                                onKeyUp={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.key == "Enter"
                                                                    ) {
                                                                        const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                        let str = `${values[0]}_${e.target.value}${valueTypes.textShadow.unit_2}_${values[2]}_${values[3]}`;
                                                                        updateDesign("textShadow", str);
                                                                    }
                                                                }}
                                                                onBlur={(e) => {

                                                                    const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                    let str = `${values[0]}_${e.target.value}${valueTypes.textShadow.unit_2}_${values[2]}_${values[3]}`;
                                                                    updateDesign("textShadow", str);
                                                                }}
                                                            />

                                                            <Listbox
                                                                value={
                                                                    valueTypes.textShadow.unit_2
                                                                }
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                    let str = `${values[0]}_${values[1]?.replace(/[!@#$%^&*a-z]/g, "") || 0}${value}_${values[2]}_${values[3]}`;

                                                                    updateDesign("textShadow", str);

                                                                }}
                                                            >
                                                                <div className="relative  w-max ml-auto text-xs">
                                                                    <Listbox.Button className={`cursor-pointer rounded-r bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1`}>
                                                                        <span className='uppercase'>
                                                                            {valueTypes.textShadow.unit_2}

                                                                        </span>

                                                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                                    </Listbox.Button>
                                                                    <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                                        {AllTextShadowUnits.map(
                                                                            (
                                                                                value,
                                                                                index
                                                                            ) => (
                                                                                <Listbox.Option
                                                                                    key={`breakpointmap_${index}`}
                                                                                    className={({
                                                                                        active,
                                                                                    }) =>
                                                                                        `${active
                                                                                            ? "bg-gray-100"
                                                                                            : ""
                                                                                        } cursor-pointer`
                                                                                    }
                                                                                    value={
                                                                                        value
                                                                                    }
                                                                                >
                                                                                    {({
                                                                                        selected,
                                                                                    }) => (
                                                                                        <>
                                                                                            <div
                                                                                                className={`uppercase py-1.5 px-3 text-gray-900${selected
                                                                                                    ? " bg-indigo-100 font-medium"
                                                                                                    : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {
                                                                                                    value
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </Listbox.Option>
                                                                            )
                                                                        )}
                                                                    </Listbox.Options>
                                                                </div>
                                                            </Listbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-2 justify-between mb-2 text-[#373C47]">
                                                    <p className='text-sm'>Blur</p>
                                                    <div className="flex items-center gap-1">
                                                        <input type='range' min={0} max={100} className='w-full h-[1px] outline-none  bg-[#757575] accent-[#757575] rounded-lg appearance-none cursor-pointer range-sm'
                                                            value={parseInt(
                                                                elementBreakpointSpecificStyleObject?.textShadow?.split('_')[2]?.replace(/[!@#$%^&*a-z]/g, "") || 0
                                                            )}

                                                            onChange={(e) => {
                                                                const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                let str = `${values[0]}_${values[1]}_${e.target.value}${valueTypes.textShadow.unit_3}_${values[3]}`;
                                                                setElementBreakpointSpecificStyleObject((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        textShadow: str
                                                                    };
                                                                })

                                                                clearTimeout(timerRef.current.range_3);
                                                                timerRef.current.range_3 = setTimeout(
                                                                    () => {
                                                                        updateDesign("textShadow", str);
                                                                    },
                                                                    700
                                                                );


                                                            }} />

                                                        <div className="flex items-center w-max ml-auto">
                                                            <input
                                                                type="number"
                                                                className={`w-[50px] outline-none rounded-l px-2 py-1 border border-r-0 border-gray-300 focus:ring-0  focus:border-[#D9D9D9] shadow text-xs`}
                                                                value={parseInt(
                                                                    elementBreakpointSpecificStyleObject?.textShadow?.split('_')[2]?.replace(/[!@#$%^&*a-z]/g, "") || 0
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {

                                                                    const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                    let str = `${values[0]}_${values[1]}_${e.target.value}${valueTypes.textShadow.unit_3}_${values[3]}`;
                                                                    updateDesign("textShadow", str);


                                                                }}
                                                                onKeyUp={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.key == "Enter"
                                                                    ) {
                                                                        const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                        let str = `${values[0]}_${values[1]}_${e.target.value}${valueTypes.textShadow.unit_3}_${values[3]}`;
                                                                        updateDesign("textShadow", str);
                                                                    }
                                                                }}
                                                                onBlur={(e) => {

                                                                    const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                    let str = `${values[0]}_${values[1]}_${e.target.value}${valueTypes.textShadow.unit_3}_${values[3]}`;
                                                                    updateDesign("textShadow", str);
                                                                }}
                                                            />

                                                            <Listbox
                                                                value={
                                                                    valueTypes.textShadow.unit_3
                                                                }
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                    let str = `${values[0]}_${values[1]}_${values[2]?.replace(/[!@#$%^&*a-z]/g, "") || 0}${value}_${values[3]}`
                                                                    updateDesign("textShadow", str);

                                                                }}
                                                            >
                                                                <div className="relative  w-max ml-auto text-xs">
                                                                    <Listbox.Button className={`cursor-pointer rounded-r bg-white py-1 px-2 border border-gray-300 shadow flex items-center justify-between gap-1`}>
                                                                        <span className='uppercase'>
                                                                            {valueTypes.textShadow.unit_3}

                                                                        </span>

                                                                        <span className="mdi mdi-chevron-down text-gray-400"></span>
                                                                    </Listbox.Button>
                                                                    <Listbox.Options className="absolute z-10 max-h-60 mt-1 w-[120px] right-0 top-7 overflow-y-auto rounded bg-white shadow-lg no-scrollbar border border-gray-300 divide-y divide-gray-300">
                                                                        {AllTextShadowUnits.map(
                                                                            (
                                                                                value,
                                                                                index
                                                                            ) => (
                                                                                <Listbox.Option
                                                                                    key={`breakpointmap_${index}`}
                                                                                    className={({
                                                                                        active,
                                                                                    }) =>
                                                                                        `${active
                                                                                            ? "bg-gray-100"
                                                                                            : ""
                                                                                        } cursor-pointer`
                                                                                    }
                                                                                    value={
                                                                                        value
                                                                                    }
                                                                                >
                                                                                    {({
                                                                                        selected,
                                                                                    }) => (
                                                                                        <>
                                                                                            <div
                                                                                                className={`uppercase py-1.5 px-3 text-gray-900${selected
                                                                                                    ? " bg-indigo-100 font-medium"
                                                                                                    : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {
                                                                                                    value
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </Listbox.Option>
                                                                            )
                                                                        )}
                                                                    </Listbox.Options>
                                                                </div>
                                                            </Listbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`flex items-center justify-between mb-2 text-[#373C47]`}>
                                                    <div className="text-sm">
                                                        Color
                                                    </div>
                                                    <Popover className="relative">
                                                        <Popover.Button
                                                            className={`flex items-center bg-[#ffffff] cursor-pointer shadow border rounded p-[3px] border-gray-300`}
                                                        >
                                                            <div
                                                                className={`w-[35px] h-[15px] rounded  bg-[${elementBreakpointSpecificStyleObject?.textShadow?.split('_')[3] || temp.split('_')[3]}]`}
                                                            />

                                                        </Popover.Button>

                                                        <Popover.Panel
                                                            className={'absolute z-[51] top-8 right-5'}
                                                        >
                                                            <SketchPicker
                                                                color={elementBreakpointSpecificStyleObject?.textShadow?.split('_')[3] || temp.split('_')[3]}

                                                                onChange={(color) => {
                                                                    const value = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
                                                                    const values = elementBreakpointSpecificStyleObject?.textShadow?.split('_') || temp.split('_');
                                                                    let str = `${values[0]}_${values[1]}_${values[2]}_${value}`
                                                                    setElementBreakpointSpecificStyleObject((prev) => {

                                                                        return {
                                                                            ...prev,
                                                                            textShadow: str
                                                                        }
                                                                    });
                                                                    clearTimeout(timerRef.current.range_4);
                                                                    timerRef.current.range_4 = setTimeout(() => {
                                                                        updateDesign('textShadow', str);
                                                                    }, 1000);
                                                                }}

                                                            />
                                                        </Popover.Panel>
                                                    </Popover>
                                                </div>

                                            </div>
                                        )
                                    }
                                </div>
                                {/* End -  Text-shadows */}
                            </div>
                        )
                    }









                </>
            )}
        </div>
    )
}

export default TypographySection