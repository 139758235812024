import { Dialog, Listbox, Popover, Transition } from '@headlessui/react'
import { Formik } from 'formik'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
	IconPlus,
	IconItalic,
	IconLink,
	IconLetterSpacing,
	IconAlignCenter,
	IconAlignJustified,
	IconAlignLeft,
	IconAlignRight,
	IconLetterCaseUpper,
	IconLetterCaseLower,
	IconLetterCase,
	IconLetterCaseToggle,
	IconLineHeight,
	IconMinus
} from '@tabler/icons-react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@mantine/core'

import ColorPicker from '../components/editor/rightSideBar/ColorPicker'
import { dbCall } from '../common/db'
import { FontFamily, updateDesign } from '../components/editor/rightSideBar/Style'
import { context } from '../context/Context'
import { processChange } from './NewEditor'

export default function Tooltip() {
	const [extraTooltip, setExtraTooltip] = useState(false)
	const [tooltip, setTooltip] = useState("")
	const [fontValue, setFontValue] = useState(0)
	let [isOpen, setIsOpen] = useState(false)
	const [search, setSearch] = useState([])
	const [fontsToDisplay, setFontsToDisplay] = useState(10);

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	const {
		cordinates,
		elementId,
		elementBreakpointSpecificStyleObject,
		setElementBreakpointSpecificStyleObject,
		openedSections, setOpenedSections
	} = useContext(context);

	useEffect(() => {
		if (elementId?.id) {

			const textElement = document?.getElementById(elementId?.id);
			if (textElement) {
				const style = window?.getComputedStyle(textElement);
				const fontSize = style?.fontSize;
				setFontValue(parseInt(fontSize.slice(0, -2)))
			}

			// fontValue = parseInt(fontSize.slice(0, -2))
		}
	}, [elementId?.id])

	useEffect(() => {
		setTooltip(elementId?.["data"]?.["title"])
	}, [elementId?.id])

	return (
		<div
			className={`z-50 duration-100 ease-out ${elementId?.productList && "hidden"} font-inter`}
			id="tooltipDiv"
			style={{
				position: "absolute",
				top: cordinates?.cordinatesValue?.top - document?.getElementById("tooltipDiv")?.getBoundingClientRect()?.height - 2 + "px",
				// left: (document?.getElementById("619")?.getBoundingClientRect()?.left > document?.getElementById("tooltipDiv")?.getBoundingClientRect()?.left) ? cordinates?.cordinatesValue?.left + "px" : cordinates?.cordinatesValue?.right - document?.getElementById("tooltipDiv")?.getBoundingClientRect()?.width + "px",
				left: cordinates?.cordinatesValue?.left < 1197 / 2 ? cordinates?.cordinatesValue?.left + "px" : cordinates?.cordinatesValue?.right - document?.getElementById("tooltipDiv")?.getBoundingClientRect()?.width + "px",
			}}
		>
			<div className=' bg-white border-2 shadow-sm flex flex-col items-center justify-evenly rounded-md w-[100%] '>
				<div className='flex items-center justify-evenly h-10'>

					{/* START - element type */}
					<Popover className="relative h-full">
						{({ open }) => (
							<>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1"
								>
									<Popover.Panel className="absolute bottom-[120%]  left-0 z-10  px-4 sm:px-0 ">
										<div className="w-[120%] rounded shadow-lg ">
											<div className="bg-gray-50 px-3 py-2 flex flex-col">
												{
													[
														{
															name: "Heading 1",
															css: "text-4xl",
															type: "h1"
														},
														{
															name: "Heading 2",
															css: "text-3xl",
															type: "h2"
														},
														{
															name: "Heading 3",
															css: "text-2xl",
															type: "h3"
														},
														{
															name: "Heading 4",
															css: "text-xl",
															type: "h4"
														},
														{
															name: "Heading 5",
															css: "text-lg",
															type: "h5"
														},
														{
															name: "Heading 6",
															css: "text-sm",
															type: "h6"
														},
														{
															name: "paragraph",
															css: "text-sm",
															type: "p"
														},
													].map((data, i) => (
														<span key={i} className={`cursor-pointer font-medium text-gray-900 ${data?.css}`} onClick={() => elementId.type = data?.type}>
															{data?.name}
														</span>
													))
												}
											</div>
										</div>
									</Popover.Panel>
								</Transition>

								<Popover.Button
									className="h-full group inline-flex items-center text-base font-medium  border-none focus:outline-none pl-3 pr-1 gap-[1px]"
									title="Element Type"
								>
									<span className={open ? 'text-gray-900' : 'text-gray-600'}>{elementId?.type}</span>
									{
										open ?
											<ChevronUpIcon className="text-gray-900 h-5 w-5 font-bold" aria-hidden="true" />
											:
											<ChevronDownIcon className="text-gray-600 h-5 w-5 font-bold" aria-hidden="true" />
									}
								</Popover.Button>
							</>
						)}
					</Popover>
					{/* END - element type */}

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					{/* START - font style */}
					<Popover className="relative h-full">
						{({ open }) => (
							<>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1"
								>
									<Popover.Panel className="absolute bottom-[150%]  left-[10%] z-10  px-4 sm:px-0  min-w-[400px]">
										<FontFamily search={search} setSearch={setSearch} fontsToDisplay={fontsToDisplay} setFontsToDisplay={setFontsToDisplay} />
									</Popover.Panel>
								</Transition>

								<Popover.Button
									className="h-full group inline-flex items-center pl-2 pr-1 gap-[1px] text-base font-medium border-none focus:outline-none truncate"
									title="Font Style"
								>
									<span className={open ? 'text-gray-900' : 'text-gray-600'}>{elementBreakpointSpecificStyleObject["fontFamily"] ? elementBreakpointSpecificStyleObject["fontFamily"] : "Fonts"}</span>
									{
										open ?
											<ChevronUpIcon className="text-gray-900 h-5 w-5 font-bold" aria-hidden="true" />
											:
											<ChevronDownIcon className="text-gray-600 h-5 w-5 font-bold" aria-hidden="true" />
									}
								</Popover.Button>
							</>
						)}
					</Popover>
					{/* END - font style */}

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					{/* START - font size */}
					<div className='max-w-[30%] flex items-center gap-[1px]'>
						<IconMinus
							className='h-4 w-4 text-gray-700 cursor-pointer mx-1'
							onClick={() => {
								updateDesign("fontSize", `${fontValue}px`)
								setFontValue(fontValue - 1)
							}}
						/>
						<input
							type="number"
							name="fontSize"
							value={fontValue}
							onChange={(e) => {
								setFontValue(e.target.value);
							}}
							onBlur={() => {
								updateDesign("fontSize", `${fontValue}px`)
							}}
							onKeyDown={(e) => {
								if (e.key == "Enter") {
									updateDesign("fontSize", `${fontValue}px`)
								}
							}}
							title='Font size'
							className='text-gray-500 rounded h-[25px] w-[35px] px-[2px] focus:ring-0 text-center'
						/>
						<IconPlus
							className='h-4 w-4 text-gray-700 cursor-pointer mx-1'
							onClick={() => {
								updateDesign("fontSize", `${fontValue}px`)
								setFontValue(fontValue + 1)
							}}
						/>
					</div>
					{/* END - font size */}

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					<div className='flex items-center gap-1'>
						<span
							className={`${elementBreakpointSpecificStyleObject["fontWeight"] == 700 && "bg-gray-200"} cursor-pointer font-black text-gray-500 text-xl ml-2 hover:bg-gray-200 rounded px-2`}
							onClick={() => {
								console.log(elementBreakpointSpecificStyleObject["fontWeight"])
								if (elementBreakpointSpecificStyleObject["fontWeight"] == 700) {
									updateDesign("fontWeight", 0);
									return
								}
								updateDesign("fontWeight", 700);
							}}
							title="Bold"
						>
							B
						</span>

						<span
							className={`${elementBreakpointSpecificStyleObject["textDecorationLine"] == "underline" && "bg-gray-200"} cursor-pointer text-gray-500 text-xl underline  hover:bg-gray-200 rounded px-2`}
							onClick={() => {
								if (elementBreakpointSpecificStyleObject["textDecorationLine"] == "underline") {
									updateDesign("textDecorationLine", "");
									return
								}
								updateDesign("textDecorationLine", "underline");
							}}
							title="Underline"
						>
							U
						</span>

						<span
							className={`${elementBreakpointSpecificStyleObject["fontStyle"] == "italic" && "bg-gray-200"} cursor-pointer text-gray-500 text-xl italic hover:bg-gray-200 rounded px-1 m`}
							onClick={() => {
								if (elementBreakpointSpecificStyleObject["fontStyle"] == "italic") {
									updateDesign("fontStyle", "");
									return
								}
								updateDesign("fontStyle", "italic");
							}}
							title="italic"
						>
							<IconItalic className='h-7 w-6' stroke="2" />
						</span>

						<span
							className='cursor-pointer h-7 text-gray-500 text-xl underline hover:bg-gray-200 rounded px-1'
							title="Background Color"
						>
							<ColorPicker setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} updateDesign={updateDesign} field={'backgroundColor'} customIcon={"mdi mdi-format-color-text text-2xl underline "} />
						</span>

						<div
							// className='px-1 flex items-center text-gray-500 translate-y-1'
							className='cursor-pointer h-7 text-gray-500 text-xl underline hover:bg-gray-200 rounded px-1 mr-2'
							title="Text Color"
						>
							<ColorPicker setElementBreakpointSpecificStyleObject={setElementBreakpointSpecificStyleObject} elementBreakpointSpecificStyleObject={elementBreakpointSpecificStyleObject} updateDesign={updateDesign} field={'color'} customIcon={"mdi mdi-format-color-fill text-2xl"} />
						</div>
					</div>

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					{/* START - text transform */}
					<Popover className="relative h-full">
						{({ open }) => (
							<>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1"
								>
									<Popover.Panel className="absolute bottom-[100%] left-9 z-10 mt-3  -translate-x-1/2 transform px-1 sm:px-0 lg:max-w-3xl">
										<div className="overflow-hidden rounded shadow-lg ring-1 ring-black/5">
											<div className="bg-gray-50 flex flex-col">
												<span
													className={`${elementBreakpointSpecificStyleObject["textTransform"] == "uppercase" ? "bg-gray-200" : ""} p-1 flex items-center uppercase font-medium text-[#757575] cursor-pointer flex items-center hover:bg-gray-200 px-3`}
													onClick={() => updateDesign("textTransform", "uppercase")}
												>
													<IconLetterCaseUpper className='h-5 w-5 inline' />
													<p className='inline ml-2'>Uppercase</p>
												</span>

												<span
													className={`${elementBreakpointSpecificStyleObject["textTransform"] == "lowercase" ? "bg-gray-200" : ""} flex items-center lowercase font-medium text-[#757575] cursor-pointer flex items-center p-1 hover:bg-gray-200 px-3`}
													onClick={() => updateDesign("textTransform", "lowercase")}
												>
													<IconLetterCaseLower className='h-5 w-5 inline' />
													<p className='inline ml-2'>Lowercase</p>
												</span>

												<span
													className={`${elementBreakpointSpecificStyleObject["textTransform"] == "capitalize" ? "bg-gray-200" : ""} flex items-center capitalize font-medium text-[#757575] cursor-pointer flex items-center p-1 hover:bg-gray-200 px-3`}
													onClick={() => updateDesign("textTransform", "capitalize")}
												>
													<IconLetterCase className='h-5 w-5 inline' />
													<p className='inline ml-2'>Capitialize</p>
												</span>

												<span
													className={`${elementBreakpointSpecificStyleObject["textTransform"] == "none" ? "bg-gray-200" : ""} flex items-center font-medium text-[#757575] cursor-pointer flex items-center p-1 hover:bg-gray-200 px-3`}
													onClick={() => updateDesign("textTransform", "none")}
												>
													<IconLetterCaseToggle className='h-5 w-5 inline' />
													<p className='inline ml-2'>Normal</p>
												</span>
											</div>
										</div>
									</Popover.Panel>
								</Transition>

								<Popover.Button
									className="h-full group inline-flex items-center px-3 py-2 text-base font-medium  border-none border-r-2 focus:outline-none font-black"
									title="Text Transform"
								>
									{
										elementBreakpointSpecificStyleObject["textTransform"] == "none" ?
											<IconLetterCaseToggle className={`h-5 w-5 ${open ? 'text-gray-900' : 'text-gray-600'}`} />
											:
											elementBreakpointSpecificStyleObject["textTransform"] == "capitalize" ?
												<IconLetterCase className={`h-5 w-5 ${open ? 'text-gray-900' : 'text-gray-600'}`} />
												:
												elementBreakpointSpecificStyleObject["textTransform"] == "lowercase" ?
													<IconLetterCaseLower className={`h-5 w-5 ${open ? 'text-gray-900' : 'text-gray-600'}`} />
													:
													elementBreakpointSpecificStyleObject["textTransform"] == "uppercase" ?
														<IconLetterCaseUpper className={`h-5 w-5 ${open ? 'text-gray-900' : 'text-gray-600'}`} />
														:
														<IconLetterCaseToggle className={`h-5 w-5 ${open ? 'text-gray-900' : 'text-gray-600'}`} />
									}
									{
										open ?
											<ChevronUpIcon className="text-gray-900 h-5 w-5 font-bold" aria-hidden="true" />
											:
											<ChevronDownIcon className="text-gray-600 h-5 w-5 font-bold" aria-hidden="true" />
									}
								</Popover.Button>
							</>
						)}
					</Popover>
					{/* END - text transform */}

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					{/* Create link */}
					<span className='mx-2' title="Create Link">
						<IconLink color="#757575" className='w-[22px] h-[22px] text-gray-600 cursor-pointer' onClick={openModal} />
						<LinkModal closeModal={closeModal} openModal={openModal} isOpen={isOpen} />
					</span>

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					{/* show more options */}
					{
						extraTooltip ?
							<ChevronUpIcon
								className="text-gray-900 h-5 w-5 font-bold mx-3 cursor-pointer"
								aria-hidden="true"
								onClick={() => {
									setExtraTooltip(!extraTooltip)
									processChange()
								}}
							/>
							:
							<ChevronDownIcon
								className="text-gray-600 h-5 w-5 font-bold mx-3 cursor-pointer"
								aria-hidden="true"
								onClick={() => {
									setExtraTooltip(!extraTooltip)
									processChange()
								}}
							/>
					}
				</div>

				<div className={`${!extraTooltip && "hidden"} h-[2px] w-full bg-[#D9D9D9] h-10`} />

				{/* START - bottom tooltip */}
				<div className={`${!extraTooltip && "hidden"} duration-500 flex items-center justify-evenly w-full`}>

					{/* START - font weight */}
					<Popover className="relative h-full">
						{({ open }) => (
							<>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1"
								>
									<Popover.Panel className="absolute top-[100%] -left-3 z-[56]  px-4 sm:px-0 ">
										<div className="overflow-hidden rounded shadow-lg ring-1 ring-black/5">
											<div className="bg-gray-50 flex flex-col">
												{
													[
														{
															name: "100",
															css: "font-thin"
														},
														{
															name: "200",
															css: "font-extralight"
														},
														{
															name: "300",
															css: "font-light"
														},
														{
															name: "400",
															css: "font-normal"
														},
														{
															name: "500",
															css: "font-medium"
														},
														{
															name: "600",
															css: "font-semibold"
														},
														{
															name: "700",
															css: "font-bold"
														},
														{
															name: "800",
															css: "font-extrabold"
														},
														{
															name: "900",
															css: "font-black"
														},
													].map((data, i) => (
														<span
															key={i}
															className={`${elementBreakpointSpecificStyleObject["fontWeight"] == data?.name && "bg-gray-200"} text-gray-500 ${data?.css} px-3 cursor-pointer hover:bg-gray-200`}
															onClick={() => updateDesign("fontWeight", parseInt(data.name))}
														>
															{data?.name}
														</span>
													))
												}
											</div>
										</div>
									</Popover.Panel>
								</Transition>

								<Popover.Button
									className="h-full group inline-flex items-center px-1 gap-1 text-base font-medium  border-none focus:outline-none"
									title="Font Weight"
								>
									<span className="text-gray-500">{elementBreakpointSpecificStyleObject["fontWeight"]}</span>
									{
										open ?
											<ChevronUpIcon className="text-gray-900 h-5 w-5 font-bold" aria-hidden="true" />
											:
											<ChevronDownIcon className="text-gray-600 h-5 w-5 font-bold" aria-hidden="true" />
									}
								</Popover.Button>
							</>
						)}
					</Popover>
					{/* END - font weight */}

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					{/* START - text align */}
					<Popover className="relative h-full">
						{({ open }) => (
							<>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1"
								>
									<Popover.Panel className="absolute top-[100%]  -left-[15%] z-[56]  px-4 sm:px-0 ">
										<div className="overflow-hidden rounded shadow-lg ring-1 ring-black/5">
											<div className="bg-gray-50 flex flex-col">
												{

													["Left", "Right", "Center", "Justify"].map((data, i) => {
														const IconName = `IconAlign${data}`
														return (
															<span
																className={`${elementBreakpointSpecificStyleObject["textAlign"] == data && "bg-gray-200"}  p-1 px-2 font-medium text-[#757575] flex items-center cursor-pointer hover:bg-gray-200`}
																key={i}
																onClick={() => updateDesign("textAlign", data)}
															>
																{
																	data == "Left" &&
																	<IconAlignLeft className='h-5 w-5 inline' />
																}
																{
																	data == "Right" &&
																	<IconAlignRight className='h-5 w-5 inline' />
																}
																{
																	data == "Center" &&
																	<IconAlignCenter className='h-5 w-5 inline' />
																}
																{
																	data == "Justify" &&
																	<IconAlignJustified className='h-5 w-5 inline' />
																}
																<p className='inline ml-2'>
																	{data}
																</p>
															</span>
														)
													})
												}
											</div>
										</div>
									</Popover.Panel>
								</Transition>

								<Popover.Button
									className="h-full group inline-flex items-center px-1 gap-1 text-base font-medium  border-none  focus:outline-none"
									title="Text Alignment"
								>
									{
										elementBreakpointSpecificStyleObject["textAlign"] === 'Left' ?
											<span class="mdi mdi-format-align-left text-gray-500 text-xl" />
											:
											elementBreakpointSpecificStyleObject["textAlign"] === 'Right' ?
												<span class="mdi mdi-format-align-right text-gray-500 text-xl" />
												:
												elementBreakpointSpecificStyleObject["textAlign"] === 'Center' ?
													<span class="mdi mdi-format-align-center text-gray-500 text-xl" />
													:
													elementBreakpointSpecificStyleObject["textAlign"] === 'Justify' ?
														<span class="mdi mdi-format-align-justify text-gray-500 text-xl" />
														:
														<span class="mdi mdi-format-align-left text-gray-500 text-xl" />
									}
									{
										open ?
											<ChevronUpIcon className="text-gray-900 h-5 w-5 font-bold" aria-hidden="true" />
											:
											<ChevronDownIcon className="text-gray-600 h-5 w-5 font-bold" aria-hidden="true" />
									}
								</Popover.Button>
							</>
						)}
					</Popover>
					{/* END - text align */}

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					{/* START - vertical align */}
					<Popover className="relative h-full">
						{({ open }) => (
							<>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1"
								>
									<Popover.Panel className="absolute  top-[100%]  -left-[30%] z-[56]  px-4 sm:px-0 ">
										<div className="overflow-hidden rounded shadow-lg ring-1 ring-black/5">
											<div className="bg-gray-50 flex flex-col">
												{
													["top", "middle", "bottom"]
														.map((data, i) => (
															<span
																key={i}
																className={`${elementBreakpointSpecificStyleObject["verticalAlign"] == data && "bg-gray-200"} text-gray-500 p-1 px-2 capitalize font-medium flex items-center cursor-pointer hover:bg-gray-200`}
																onClick={() => updateDesign("verticalAlign", data)}
															>
																<span
																	className={`mdi mdi-${data === 'top'
																		? 'arrow-collapse-up'
																		: data === 'middle'
																			? 'format-vertical-align-center'
																			: data === 'bottom'
																				? 'arrow-collapse-down'
																				: ''
																		} text-sm mr-1`}
																/>
																{data}
															</span>
														))
												}
											</div>
										</div>
									</Popover.Panel>
								</Transition>

								<Popover.Button
									className="h-full group inline-flex items-center gap-1 px-1 text-base font-medium  border-none  focus:outline-none"
									title="verticle Align"
								>
									<span class={`mdi mdi-${elementBreakpointSpecificStyleObject["verticalAlign"] === 'top'
										? 'arrow-collapse-up'
										: elementBreakpointSpecificStyleObject["verticalAlign"] === 'middle'
											? 'format-vertical-align-center'
											: elementBreakpointSpecificStyleObject["verticalAlign"] === 'bottom'
												? 'arrow-collapse-down'
												: 'format-vertical-align-center'
										} text-gray-500 text-xl`}
									/>
									{
										open ?
											<ChevronUpIcon className="text-gray-900 h-5 w-5 font-bold" aria-hidden="true" />
											:
											<ChevronDownIcon className="text-gray-600 h-5 w-5 font-bold" aria-hidden="true" />
									}
								</Popover.Button>
							</>
						)}
					</Popover>
					{/* END - vertical align */}

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					{/* START - list - ol / ul */}
					<div>
						<span
							class={`mdi mdi-format-list-bulleted ${(elementBreakpointSpecificStyleObject["listStyle"] != "list-decimal" && elementBreakpointSpecificStyleObject["display"] == "list-item") && "bg-gray-200"} text-2xl text-gray-500 ml-2 cursor-pointer`}
							onClick={() => {
								if (elementBreakpointSpecificStyleObject["listStyle"] != "list-decimal" && elementBreakpointSpecificStyleObject["display"] == "list-item") {
									updateDesign("display", "")
									setTimeout(() => {
										updateDesign("listStyle", "")
									}, 500)
								} else {
									updateDesign("display", "list-item")
									setTimeout(() => {
										updateDesign("listStyle", "")
									}, 500)
								}
							}}
							title="Bullet points"
						/>

						<span
							class={`mdi mdi-format-list-numbered  text-2xl text-gray-500 ml-2.5 mr-2 cursor-pointer ${(elementBreakpointSpecificStyleObject["listStyle"] == "list-decimal" && elementBreakpointSpecificStyleObject["display"] == "list-item") && "bg-gray-200"}`}
							onClick={async () => {
								if (elementBreakpointSpecificStyleObject["display"] == "list-item" && elementBreakpointSpecificStyleObject["listStyle"] == "list-decimal") {
									updateDesign("display", "")
									setTimeout(() => {
										updateDesign("listStyle", "")
									}, 500)
								} else {
									updateDesign("listStyle", "list-decimal")
									setTimeout(() => {
										updateDesign("display", "list-item")
									}, 500)
								}
							}}
							title="Ordered list"
						/>
					</div>
					{/* END - list - ol / ul */}

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					<div className='flex items-center justify-evenly h-full'>
						{/* START - line height */}
						<Popover className="relative">
							{({ open, close }) => (
								<>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-200"
										enterFrom="opacity-0 translate-y-1"
										enterTo="opacity-100 translate-y-0"
										leave="transition ease-in duration-150"
										leaveFrom="opacity-100 translate-y-0"
										leaveTo="opacity-0 translate-y-1"
									>
										<Popover.Panel className="absolute  top-[100%]  left-[30%] z-[56]  px-4 sm:px-0 ">
											<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
												<div className="bg-gray-50 p-2 flex">
													<Formik
														initialValues={{ lineHeight: elementBreakpointSpecificStyleObject["lineHeight"]?.replace(/[!@#$%^&*a-z-]/g, "") || 0 }}
														onSubmit={(values) => {
															console.log("onSubmit")
															updateDesign("lineHeight", `${values?.lineHeight}px`)
															close()
														}}
													>
														{({
															values,
															errors,
															touched,
															handleChange,
															handleBlur,
															handleSubmit,
															isSubmitting,
															/* and other goodies */
														}) => (
															<form onSubmit={handleSubmit} className='flex items-center gap-1'>
																<div className='relative'>
																	<input
																		type="number"
																		name="lineHeight"
																		onChange={handleChange}
																		value={values?.lineHeight}
																		className='rounded-md w-[80px] focus:ring-0 pl-2 pr-8'
																	/>
																	<div class="absolute inset-y-0 right-2 pl-3 flex items-center  pointer-events-none">px</div>
																</div>
																<button type="submit">
																	<span class="mdi mdi-check text-2xl text-gray-700 cursor-pointer" />
																</button>
																<span class="mdi mdi-close text-2xl text-gray-700 cursor-pointer" onClick={() => close()} />
															</form>
														)}
													</Formik>
												</div>
											</div>
										</Popover.Panel>
									</Transition>

									<Popover.Button
										className={`${open ? 'text-gray-800' : 'text-gray-700'} group inline-flex items-center rounded-md relative z-[10] px-0.5 mt-1.5 text-base font-medium  border-none  focus:outline-none`}
										title="Line Height"
									>
										<IconLineHeight color="#757575" className='h- w-7 text-gray-700' />

										{/* <ChevronDownIcon
													className={`${open ? 'text-orange-300' : 'text-orange-300/70'}
                  ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-orange-300/80`}
													aria-hidden="true"
												/> */}
									</Popover.Button>
								</>
							)}
						</Popover>
						{/* END - line height */}

						{/* START - letter spacing */}
						<Popover className="relative">
							{({ open, close }) => (
								<>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-200"
										enterFrom="opacity-0 translate-y-1"
										enterTo="opacity-100 translate-y-0"
										leave="transition ease-in duration-150"
										leaveFrom="opacity-100 translate-y-0"
										leaveTo="opacity-0 translate-y-1"
									>
										<Popover.Panel className="absolute  top-[100%]  left-[30%] z-[56]  px-4 sm:px-0 ">
											<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
												<div className="bg-gray-50 p-2 flex">
													<Formik
														initialValues={{ lineHeight: elementBreakpointSpecificStyleObject["letterSpacing"]?.replace(/[!@#$%^&*a-z-]/g, "") || 0 }}
														onSubmit={(values) => {
															updateDesign("letterSpacing", `${values?.lineHeight}px`)
															close()
														}}
													>
														{({
															values,
															errors,
															touched,
															handleChange,
															handleBlur,
															handleSubmit,
															isSubmitting,
															/* and other goodies */
														}) => (
															<form onSubmit={handleSubmit} className='flex items-center gap-1'>
																<div className='relative'>
																	<input
																		type="number"
																		name="lineHeight"
																		onChange={handleChange}
																		value={values?.lineHeight}
																		className='rounded-md w-[80px] focus:ring-0 pl-2 pr-8'
																	/>
																	<div class="absolute inset-y-0 right-2 pl-3 flex items-center  pointer-events-none">px</div>
																</div>
																<button type="submit">
																	<span class="mdi mdi-check text-2xl text-gray-700 cursor-pointer" />
																</button>
																<span class="mdi mdi-close text-2xl text-gray-700 cursor-pointer" onClick={() => close()} />
															</form>
														)}
													</Formik>
												</div>
											</div>
										</Popover.Panel>
									</Transition>

									<Popover.Button
										className={`${open ? 'text-gray-800' : 'text-gray-700'} group inline-flex items-center rounded-md  px-0.5 mt-2 text-base font-medium  border-none  focus:outline-none`}
										title="Letter Spacing"
									>
										{/* <IconLineHeight className='h- w-7 text-gray-700 ml-2' /> */}

										<IconLetterSpacing color="#757575" className='h- w-7 text-gray-700' />
										{/* <ChevronDownIcon
													className={`${open ? 'text-orange-300' : 'text-orange-300/70'}
                  ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-orange-300/80`}
													aria-hidden="true"
												/> */}
									</Popover.Button>
								</>
							)}
						</Popover>
						{/* END - letter spacing */}

					</div>

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					{/* START - tooltip */}
					<Popover className="relative h-full">
						{({ open }) => (
							<>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1"
								>
									<Popover.Panel className="absolute  top-[100%]  left-[30%] z-[56]  px-4 sm:px-0 ">
										<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
											<div className="bg-gray-50 p-2 flex">
												<input
													type='text'
													placeholder='enter your Tooltip Message here'
													className='rounded-md'
													value={tooltip}
													onChange={(e) => {
														setTooltip(e.target.value);
													}}
												/>
												<div className="flex items-center gap-1 mx-2 my-auto">
													<span class="mdi mdi-check text-2xl text-gray-700 cursor-pointer"
														onClick={() => {
															elementId["data"]["title"] = tooltip
														}}
													></span>
													<span class="mdi mdi-close text-2xl text-gray-700 cursor-pointer"
														onClick={() => {
															setTooltip("")
														}}
													></span>
												</div>
											</div>
										</div>
									</Popover.Panel>
								</Transition>

								<Popover.Button
									className="h-full group inline-flex items-center rounded-md gap-1 px-1 text-base font-medium  border-none  focus:outline-none"
									title="Tool tip"
								>
									<span class={`mdi mdi-tooltip ${open ? 'text-gray-800' : 'text-gray-600'} text-xl`} />
									{
										open ?
											<ChevronUpIcon className="text-gray-900 h-5 w-5 font-bold" aria-hidden="true" />
											:
											<ChevronDownIcon className="text-gray-600 h-5 w-5 font-bold" aria-hidden="true" />
									}
								</Popover.Button>
							</>
						)}
					</Popover>
					{/* END - tooltip */}

					<div className='h-10 w-[2px] bg-[#D9D9D9]' />

					{/* Animation & Effects */}
					<span
						class="mdi mdi-transition text-gray-500 text-xl mx-4 cursor-pointer"
						title="Animation & Effects"
						onClick={() => setOpenedSections({ ...openedSections, effects: true })}
					/>

				</div>
				{/* END - bottom tooltip */}

			</div>
		</div>
	)
}

const LinkModal = ({ closeModal, openModal, isOpen }) => {
	const [pages, setPages] = useState([]);
	const [modals, setModals] = useState([]);
	const [currentTab, setCurrentTab] = useState("None")
	const [phoneTab, setPhoneTab] = useState("Phone")

	const [navigateToPage, setNavigateToPage] = useState({
		pageName: "",
		newTab: ""
	})
	// const [navigateToModal, setNavigateToModal] = useState("")
	const [webAddress, setWebAddress] = useState({
		href: "",
		newTab: ""
	})
	const [email, setEmail] = useState({
		mail: "",
		subject: ""
	})
	const [phoneWhatsapp, setPhoneWhatsapp] = useState(0)

	const [document, setDocument] = useState({
		attachment: null,
		newTab: "",
		downloadOnComputer: ""
	})
	const [topBottom, setTopBottom] = useState("")

	const [modalName, setModalName] = useState("")
	const [currentPageName, setCurrentPageName] = useState("")

	const {
		elementId,
		setPageName,
		setIsAnimating,
		pagename,
		currentPlatform
	} = useContext(context);

	const params = useParams();

	const uploadMediafromDevice = async (profilePic) => {
		// console.log("files : ", profilePic);
		if (profilePic == null) {
			toast.error('Select Image to change your Profil picture.');
			return;
		}

		console.log(profilePic)
		if (profilePic?.size > 99614720) {
			toast.warning("can't upload file larger than 95 MB")
			return;
		}

		const filesData = new FormData(); 		// use FormData else it will give 'data too long' error

		filesData.append("profilePic", profilePic);
		try {

			const response = await dbCall.post('/marketplace/upload-user-media', filesData)
			console.log(response)
			return response?.data?.key;
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.message)
			} else {
				toast.error('Something went wrong!');
			}
		}
	};

	const saveLink = async () => {

		if (!elementId["data"]) {
			elementId["data"] = {}
		}

		if (currentTab == "None") {
			delete elementId["data"]["href"]
			delete elementId["data"]["target"]
			delete elementId["data"]["to"]
			delete elementId["data"]["download"]
			if (elementId?.events?.onClick) {
				const filtered = elementId?.events?.onClick?.filter((el) => el?.task != "Navigate");
				elementId.events.onClick = filtered
			}
		}
		else if (currentTab == "Open a Page") {
			if (navigateToPage?.newTab) {
				elementId["type"] = "a"
				elementId["data"]["href"] = `/${navigateToPage?.pageName}`
				elementId["data"]["target"] = "_blank"
			} else {
				elementId["type"] = "Link"
				elementId["data"]["to"] = `/${navigateToPage?.pageName}`
				delete elementId["data"]["target"]
			}
		}
		else if (currentTab == "Open a web address") {
			delete elementId["data"]["to"]
			if (webAddress?.newTab) {
				elementId["type"] = "a"
				elementId["data"]["href"] = webAddress?.href
				elementId["data"]["target"] = "_blank"
			} else {
				elementId["type"] = "a"
				elementId["data"]["to"] = webAddress?.href
				delete elementId["data"]["target"]
			}
		}
		else if (currentTab == "Send an email") {
			delete elementId["data"]["target"]
			delete elementId["data"]["to"]
			delete elementId["data"]["download"]
			elementId["type"] = "a"
			elementId["data"]["href"] = `mailto:${email?.mail}?subject=${email?.subject}`

		}
		else if (currentTab == "Phone/Whatsapp") {
			delete elementId["data"]["target"]
			delete elementId["data"]["to"]
			delete elementId["data"]["download"]

			if (phoneTab == "Phone") {
				elementId["type"] = "a"
				elementId["data"]["href"] = `tel:${phoneWhatsapp}`
			}
			else if (phoneTab == "WhatsApp") {
				elementId["type"] = "a"
				elementId["data"]["href"] = `https://wa.me/${phoneWhatsapp}`
			}

		}
		else if (currentTab == "Document") {
			delete elementId["data"]["href"]
			delete elementId["data"]["target"]
			delete elementId["data"]["to"]
			delete elementId["data"]["download"]

			const key = await uploadMediafromDevice(document?.attachment)
			elementId["type"] = "a"
			elementId["data"]["href"] = `${process.env.REACT_APP_USER_CDN}/${key}`
			if (document?.newTab) {
			}
			else if (document?.downloadOnComputer) {
				elementId["data"]["download"] = true
			}
		}
		else if (currentTab == "Top/Bottom of page") {
			delete elementId["data"]["href"]
			delete elementId["data"]["target"]
			delete elementId["data"]["to"]
			delete elementId["data"]["download"]

			if (!elementId["events"]) {
				elementId["events"] = {}
			}
			if (!elementId["events"]["onClick"]) {
				elementId["events"]["onClick"] = []
			}
			elementId["events"]["onClick"].push({
				task: "Navigate",
				parameters: {
					navigationType: topBottom
				}
			})

		}
		else if (currentTab == "Open a Modal") {
			delete elementId["data"]["href"]
			delete elementId["data"]["target"]
			delete elementId["data"]["to"]
			delete elementId["data"]["download"]

			if (!elementId["events"]) {
				elementId["events"] = {}
			}
			if (!elementId["events"]["onClick"]) {
				elementId["events"]["onClick"] = []
			}

			const response = await dbCall.get(`site/get-modal-data/${modalName}/${params?.siteId}/${currentPlatform}`);
			const schema = response?.data?.data?.pageData?.schema

			const objWithIdIndex = elementId["events"]["onClick"]?.findIndex((obj) => obj.task === "custom");
			elementId["events"]["onClick"]?.splice(objWithIdIndex, 1);


			elementId["events"]["onClick"].push({
				"task": "custom",
				"parameters": {
					"function": `if(document.getElementById('${schema[0]?.id}').classList.contains('hidden')) {document.getElementById('${schema[0]?.id}').classList.remove('hidden')} else {document.getElementById('${schema[0]?.id}').classList.add('hidden');}`
				}
			})

		}
		console.log(elementId)
	}

	const getAllPages = async () => {
		try {
			const response = await dbCall.get(`/site/get-all-pages/${params.siteId}/${currentPlatform}`);
			// console.log(response.data.pagesData)
			setPages((prev) => {
				return response.data.pagesData;
			})
			setNavigateToPage({
				...navigateToPage,
				pageName: response.data.pagesData[0]?.pageName
			})
			return response.data.pagesData
		} catch (error) {
			if (error.response) {
				if (error.response.status != 500) {
					toast.error(error.response.data.message);
				}
			}
		}

	};
	const getAllModals = async () => {
		try {
			const response = await dbCall.get(`/site/get-all-modal/${params.siteId}/${currentPlatform}`);
			setModals((prev) => {
				return response.data.pagesData;
			})
			setModalName(response?.data?.pagesData[0]?.ModalName)
		} catch (error) {
			if (error.response) {
				if (error.response.status != 500) {
					toast.error(error.response.data.message);
				}

			}
		}

	};

	// ["None", "Open a Page", "Open a web address", "Send an email", "Phone/Whatsapp", "Document", "Top/Bottom of page"]
	useEffect(() => {
		getAllModals()
		const findCurrenttab = async () => {
			// console.log(elementId)
			const pages = await getAllPages();
			if (elementId?.type == "Link") {
				setCurrentTab("Open a Page")
			}
			else if (elementId?.type == "PopoverButton") {
				setModalName(elementId?.modalName)
				setCurrentTab("Open a Modal")
			}
			else if (elementId?.type == "a" && elementId?.data?.href) {
				if (elementId?.data?.href?.includes("tel:")) {
					setCurrentTab("Phone/Whatsapp")
					setPhoneTab("Phone")
					setPhoneWhatsapp(elementId?.data?.href?.split(":")[1])
					return
				}
				if (elementId?.data?.href?.includes("https://wa.me/")) {
					setCurrentTab("Phone/Whatsapp")
					setPhoneTab("WhatsApp")
					setPhoneWhatsapp(elementId?.data?.href?.split("/")[3])
					return
				}
				if (elementId?.data?.href?.includes("mailto:")) {
					setCurrentTab("Send an email")
					setEmail({
						mail: elementId?.data?.href?.split(":")[1].split("?")[0],
						subject: elementId?.data?.href?.split(":")[1].split("?")[1]?.split("=")[1]
					})
					return
				}
				if (elementId?.data?.download) {
					setCurrentTab("Document")
					return
				}

				const found = await pages?.some(el => `/${el?.pageName}` == `${elementId?.data?.href}`);
				if (found) {
					setCurrentTab("Open a Page")
					setNavigateToPage({
						newTab: elementId?.data?.target ? true : false,
						pageName: elementId?.data?.href?.split("/")[1]
					})
					// setCurrentPageName()
				} else {
					setCurrentTab("Open a web address")
					setWebAddress({
						newTab: elementId?.data?.target ? true : false,
						href: elementId?.data?.href
					})
				}
			} else {
				let topUp = false;
				let bottomUp = false;

				if (elementId?.["events"]?.["onClick"]) {
					// console.log(elementId,elementId?.events?.onClick.length, ",asbfhjasbhbashfba")
					if (Array.isArray(elementId?.events?.onClick)) {
						topUp = elementId?.events?.onClick?.some(el => el?.parameters?.navigationType == "Top Of Page")
						bottomUp = elementId?.events?.onClick?.some(el => el?.parameters?.navigationType == "Bottom Of Page")
					} else {
						topUp = elementId?.events?.onClick?.parameters?.navigationType == "Top Of Page"
						bottomUp = elementId?.events?.onClick?.parameters?.navigationType == "Bottom Of Page"
					}
				}

				if (topUp) {
					setCurrentTab("Top/Bottom of page")
					setTopBottom("Top Of Page")
				} else if (bottomUp) {
					setTopBottom("Bottom Of Page")
					setTopBottom("Top Of Page")
				} else {
					setCurrentTab("None")
				}
			}
		}
		if (elementId?.id) {
			findCurrenttab()
		}
	}, [elementId?.id])

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-50" onClose={closeModal}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-60" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-[600px] transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
								<Dialog.Title as="h3" className="flex justify-between gap-5 self-center w-full text-base text-black px-4 py-2">
									<span className='text-xl font-medium leading-9 text-black'>Setup Your Link</span>
									<button type="button" onClick={closeModal}>
										<XMarkIcon height={25} />
									</button>
								</Dialog.Title>

								<div className="w-full bg-zinc-400 min-h-[1px]" />

								<div className=" flex justify-between px-4">
									<div className="w-[40%] border-r-2 py-2">
										{
											["None", "Open a Page", "Open a web address", "Send an email", "Phone/Whatsapp", "Document", "Open a Modal", "Top/Bottom of page"].map((data, i) => (
												<div className={`flex items-center mt-2 first:mt-0`} key={i}>
													<input
														id={`linkType_${i}`}
														type='radio'
														className='focus:ring-0'
														checked={currentTab == data ? true : false}
														onChange={() => setCurrentTab(data)}
													/>
													<label htmlFor={`linkType_${i}`} className='ml-3 text-lg text-gray-700'>{data}</label>
												</div>
											))
										}
									</div>

									<div className="w-[60%] pt-2 pl-3">
										{
											currentTab == "None" && <p className='text-xl text-gray-400 '>No Link is set up.</p>
										}

										{
											currentTab == "Open a Page" &&
											<>
												<p className='text-lg text-gray-800 mb-2'>Which page to link?</p>
												<select
													className='w-full rounded-md border-zinc-300 outline-gray-500 text-gray-500 focus:ring-0'
													onChange={(e) => {
														setNavigateToPage({
															...navigateToPage,
															pageName: e.target.value
														})
													}}
												>
													{
														pages?.map((page, i) => (
															<option key={i} values={page?.pageName} selected={page?.pageName == navigateToPage?.pageName ? true : false}>{page?.pageName}</option>
														))
													}
												</select>

												<div className={`flex items-center mt-2 `}>
													<input
														id="open_in_new_tab"
														type='checkbox'
														className='rounded focus:ring-0'
														onChange={(e) => setNavigateToPage({ ...navigateToPage, newTab: e.target.checked })}
														checked={navigateToPage?.newTab}
													/>
													<label htmlFor='open_in_new_tab' className='ml-2 text-lg text-gray-700'>Open in new tab</label>
												</div>

												{/* <div className='text-center w-full bg-[#F3F4F6] border-2 border-[#D9D9D9] py-2'>
													<p>More options</p>
												</div>

												<div className='p-5'>
													<p className='text-xl text-gray-800 mb-2'>What rel values should it have?</p>
													<div>
														{
															[...new Array(4)].map(() => (
																<div className={`flex items-center  `} >
																	<input id="open_in_new_tab1" type='checkbox' className='rounded-md' />
																	<label htmlFor="open_in_new_tab1" className='ml-2 text-xl text-[#757575]'>Open in new tab</label>
																</div>
															))
														}
													</div>
													<p className='text-xl text-gray-800 mb-2'>Load link type</p>
													<div>
														{
															[...new Array(3)].map(() => (
																<div className={`flex items-center  `} >
																	<input id="open_in_new_tab2" type='checkbox' className='rounded-md' />
																	<label htmlFor="open_in_new_tab2" className='ml-2 text-xl text-[#757575]'>Open in new tab</label>
																</div>
															))
														}
													</div>
												</div> */}
											</>
										}

										{
											currentTab == "Open a web address" &&
											<>
												<p className='text-lg text-gray-800 mb-2'>Open a web address</p>
												<input
													type='text'
													placeholder='https://qafto.com'
													className='rounded-md border border-zinc-300 w-full focus:ring-0'
													onChange={(e) => setWebAddress({ ...webAddress, href: e.target.value })}
													value={webAddress?.href}
												/>
												<div className={`flex items-center mt-2 `} >
													<input
														id="open_in_new_tab_web"
														type='checkbox'
														className='rounded focus:ring-0'
														onChange={(e) => setWebAddress({ ...webAddress, newTab: e?.target?.checked })}
														checked={webAddress.newTab}
													/>
													<label htmlFor='open_in_new_tab_web' className='ml-2 text-lg text-gray-700'>Open in new tab</label>
												</div>
											</>
										}

										{
											currentTab == "Send an email" &&
											<>
												<div className='space-y-1'>
													<label htmlFor='email_address' className='text-lg text-gray-800 mb-2'>Email address</label>
													<input
														id="email_address"
														type='email'
														className='border border-zinc-300 rounded p-2 w-full'
														placeholder='example@gmail.com'
														required
														onChange={(e) => setEmail({ ...email, mail: e.target.value })}
														value={email?.mail}
													/>
												</div>

												<div className='space-y-1 mt-3'>
													<label htmlFor='email_subject' className='text-lg text-gray-800'>Subject</label>
													<input
														id="email_subject"
														type='text'
														className='border-gray-200 rounded p-2 w-full'
														placeholder='Enter Email Subject'
														onChange={(e) => setEmail({ ...email, subject: e.target.value })}
														value={email?.subject}
													/>
												</div>
											</>
										}

										{
											currentTab == "Document" &&
											<>
												<p className='text-lg text-gray-800'>Upload a file</p>
												<div class="flex items-center w-full relative">
													<label for="dropzone-file" class="flex flex-col w-full cursor-pointer bg-gray-50 my-2">
														<div class="flex items-center ">
															<span className='px-3 py-2 rounded-lg border-2'>Choose file</span>
															<p class="text-gray-600 ml-2">{document.attachment === null ? "No file chosen" : document.attachment?.name}</p>
														</div>
														<input
															id="dropzone-file"
															type="file"
															class="hidden"
															onChange={(e) => setDocument({ ...document, attachment: e.target.files[0] })}
														/>
													</label>
													{
														document.attachment !== null &&
														<XMarkIcon className='h-5 w-5 absolute right-0' onClick={() => setDocument({ ...document, attachment: null })} />
													}
												</div>

												<div className={`flex items-center`}>
													<input
														id="download_on_computer"
														type='checkbox'
														className='rounded focus:ring-0'
														onChange={(e) => setDocument({ ...document, downloadOnComputer: e.target.checked })}
													/>
													<label htmlFor='download_on_computer' className='ml-2 text-lg text-[#757575]'>Download on computer</label>
												</div>

												<div className={`flex items-center`} >
													<input
														id="open_in_new_tab_file"
														type='checkbox'
														className='rounded focus:ring-0'
														onChange={(e) => setDocument({ ...document, newTab: e.target.checked })}
													/>
													<label htmlFor="open_in_new_tab_file" className='ml-2 text-lg text-[#757575]'>Open in new tab</label>
												</div>

												{/* <div className={`flex items-center  `} >
													<input type='checkbox' className='rounded-md' />
													<p className='ml-2 text-xl text-[#757575]'>set Download Url</p>
												</div> */}
											</>
										}
										{
											currentTab == "Top/Bottom of page" &&
											<>
												<p className='text-lg text-gray-800 mb-2'>Link visitors to the top/bottom of the page (whichever page they’re on).</p>
												<div className='flex'>
													<div
														className={`${topBottom == "Top Of Page" ? "border-gray-700" : "border-gray-300"} border rounded-lg w-[70px] h-full py-3 px-4 gap-1.5 flex flex-col items-center justify-center cursor-pointer`}
														onClick={() => setTopBottom("Top Of Page")}
													>
														<span class="mdi mdi-arrow-up-thin text-5xl bg-gray-200 rounded-md"></span>
														Top
													</div>
													<div
														className={`${topBottom == "Bottom Of Page" ? "border-gray-700" : "border-gray-300"} border rounded-lg w-[70px] h-full py-3 px-4 gap-1.5 flex flex-col items-center justify-center ml-4 cursor-pointer`}
														onClick={() => setTopBottom("Bottom Of Page")}
													>
														<span class="mdi mdi-arrow-down-thin text-5xl bg-gray-200 rounded-md"></span>
														Bottom
													</div>
												</div>
											</>
										}

										{
											currentTab == "Phone/Whatsapp" &&
											<>
												<div className='mb-4'>
													<button
														type="button"
														className={`border-[2px] rounded-lg px-3 py-1 tracking-[1px] font-medium text-gray-700 ${phoneTab == "Phone" && "bg-[#D9D9D9]"}`}
														onClick={() => setPhoneTab("Phone")}
													>
														<span class="mdi mdi-phone mr-1.5 text-lg"></span>
														Phone
													</button>
													<button
														type="button"
														className={`border-[2px] rounded-lg px-3 py-1 tracking-[1px] font-medium ml-4 text-gray-700 ${phoneTab == "WhatsApp" && "bg-[#D9D9D9]"}`}
														onClick={() => setPhoneTab("WhatsApp")}
													>
														<span class="mdi mdi-whatsapp mr-1.5 text-lg"></span>
														WhatsApp
													</button>
												</div>

												<div>
													<PhoneInput
														inputStyle={{ width: '100%' }}
														country={'in'}
														containerClass="!mt-3"
														value={phoneWhatsapp}
														onChange={(phone) => {
															setPhoneWhatsapp(phone)
														}}
													// onBlur={formik.handleBlur}
													/>
												</div>

												<p className='text-gray-800 mb-2 mt-4 font-medium'>Note:{" "}
													<span className='text-[#757575] text-base font-normal'>
														{
															phoneTab == "Phone" ?
																"Phone links only work on devices that can place phone calls i.e. mobile or tablet."
																:
																"Whatsapp links only work on devices that have installed Whatsapp app on their device."
														}
													</span>
												</p>
											</>
										}
										{
											(currentTab == "Open a Modal" && modals?.length > 0) &&
											<>
												<div className='p-5'>
													<p className='text-xl font-light text-gray-800 mb-2'>Which Modal To Open?</p>
													<select className='w-full rounded-md border-gray-500 outline-gray-500'
														onChange={(e) => {
															setModalName(e.target.value ? e.target.value : modals[0]?.ModalName)
														}}
													>
														{
															modals?.map((page, i) => (
																<option values={page?.ModalName}
																	selected={page?.ModalName == modalName ? true : false}
																>{page?.ModalName}</option>
															))
														}
													</select>
												</div>
											</>
										}
									</div>
								</div>

								<hr className='border border-zinc-300' />

								<div className="flex w-full gap-5 justify-end items-center py-3 px-4 text-base leading-4 whitespace-nowrap">
									<button
										type='button'
										className="text-[#4D67A9] font-semibold"
										onClick={closeModal}
									>
										Discard
									</button>
									<button
										type='button'
										className="px-6 py-2 w-fit text-base text-white bg-[#4D67A9] font-semibold rounded-md"
										onClick={async () => {
											await saveLink()
											closeModal()
											// console.log(elementId)
										}}
									>
										Save
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export const ImageSettingModal = ({ isOpen, setIsOpen }) => {
	const loadingTypes = [
		{
			name: 'Lazy: loads on scroll',
			key: "lazy"
		},
		{
			name: 'Auto: defaults to browser',
			key: "default"
		}
	]

	const [selected, setSelected] = useState()

	const { elementId } = useContext(context)

	useEffect(() => {
		if (elementId?.data?.loading == "lazy") {
			setSelected(loadingTypes[0])
		} else {
			setSelected(loadingTypes[1])
		}

	}, [elementId?.id])

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-60" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded bg-white text-left align-middle shadow-xl transition-all">
								<Formik
									initialValues={{ alt: elementId?.data?.alt }}
									onSubmit={(values) => {
										if (!elementId["data"]) {
											elementId["data"] = {}
										}

										elementId["data"]["alt"] = values?.alt
										if (selected?.key == "lazy") {
											elementId["data"]["loading"] = "lazy"
										} else {
											delete elementId?.["data"]?.["loading"]
										}

										// console.log(elementId)
										setIsOpen(false);
									}}
								>
									{({
										values,
										handleChange,
										handleSubmit,
									}) => (
										<form onSubmit={handleSubmit} className='w-full'>
											<div className="flex gap-5 justify-between self-center px-5 py-2 w-full text-lg leading-6 text-black">
												<p className='text-xl leading-9 text-black font-medium'>Image Settings</p>
												<button type="button" onClick={() => setIsOpen(false)}>
													<XMarkIcon height={25} />
												</button>
											</div>

											<div className='py-4 space-y-4'>
												<div className="flex flex-col w-full text-[#5C5C5C] space-y-2 px-4">
													<label htmlFor="alt" className='text-base'>Alt text</label>
													<div className='relative w-full'>
														<input
															id="alt"
															type="text"
															name="alt"
															placeholder='Write Image Alt text here'
															onChange={handleChange}
															value={values?.alt}
															className="w-full px-3 py-2 border rounded border-zinc-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
														/>
													</div>
												</div>

												<div className="flex items-center justify-between w-full text-[#5C5C5C] space-y-2 px-4">
													<label htmlFor="type" className='text-base'>Type</label>
													<Listbox value={selected} onChange={setSelected}>
														{({ open }) => (
															<>
																<div className="relative !mt-0">
																	<Listbox.Button className="relative border border-zinc-300 h-full w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
																		<span className="block truncate">{selected.name}</span>
																		<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
																			{
																				open ?
																					<ChevronUpIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
																					:
																					<ChevronDownIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
																			}
																		</span>
																	</Listbox.Button>
																	<Transition
																		as={Fragment}
																		leave="transition ease-in duration-100"
																		leaveFrom="opacity-100"
																		leaveTo="opacity-0"
																	>
																		<Listbox.Options className="absolute bottom-[100%] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
																			{loadingTypes?.map((person, personIdx) => (
																				<Listbox.Option
																					key={personIdx}
																					className={({ active }) =>
																						`relative cursor-default select-none py-2 pl-2 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
																						}`
																					}
																					value={person}
																				// onClick={() => {
																				// 	if (!elementId["data"]) {
																				// 		elementId["data"] = {}
																				// 	}
																				// 	if (person?.key == "lazy") {
																				// 		elementId["data"]["loading"] = "lazy"
																				// 	} else {
																				// 		delete elementId?.["data"]?.["loading"]
																				// 	}
																				// }}
																				>
																					{({ selected }) => (
																						<>
																							<span
																								className={`block truncate ${selected ? 'font-medium' : 'font-normal'
																									}`}
																							>
																								{person.name}
																							</span>
																							{selected ? (
																								<span className="absolute inset-y-0 left-0 flex items-center  text-amber-600">
																									<CheckIcon className="h-5 w-5" aria-hidden="true" />
																								</span>
																							) : null}
																						</>
																					)}
																				</Listbox.Option>
																			))}
																		</Listbox.Options>
																	</Transition>
																</div>
															</>
														)}
													</Listbox>
												</div>
											</div>

											<div className="flex gap-4 w-full items-center justify-end pb-4 px-4 text-base leading-4 whitespace-nowrap">
												<button
													type='button'
													className="2 text-indigo-600 font-semibold"
													onClick={() => setIsOpen(false)}
												>
													Cancel
												</button>
												<button type='submit' className="px-6 py-2 w-fit text-base text-white bg-blue-800 font-semibold rounded-md">
													Save
												</button>
											</div>
										</form>
									)}
								</Formik>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}