import { Popover } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'


const presetColors = [
	'#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE',
	'#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'
];
const rgbaToHex = (color) => {
	if (/^rgb/.test(color)) {
		const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');

		// rgb to hex
		// eslint-disable-next-line no-bitwise
		let hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
			.toString(16)
			.slice(1)}`;

		// added alpha param if exists
		if (rgba[4]) {
			const alpha = Math.round(0o1 * 255);
			const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
			hex += hexAlpha;
		}

		return hex;
	}
	return color;
};

const ColorPicker = ({ elementBreakpointSpecificStyleObject, setElementBreakpointSpecificStyleObject, updateDesign, field, customIcon, boxShadow, boxType, setBoxShadow }) => {
	const timerref = useRef();
	const [boxShadowColor , setBoxShadowColor] = useState("")
	const convertClr = async () => {
		if (field == "boxShadow") {
			const color = await rgbaToHex(boxShadow?.C)
			console.log(color, "Color")
			setBoxShadowColor(color)
		}
	}
	useEffect(() => {
		convertClr()
	}, [])
	return (
		<>

			<Popover className="relative">
				<Popover.Button
					className={`${!customIcon ? "p-[3px] bg-[#ffffff] rounded cursor-pointer shadow border border-[#D9D9D9]" : "outline-none border-none focus:outline-none"}`}
				>
					{
						customIcon ?
							<span class={`${customIcon}`}></span>
							:
							<div
								className={`w-[30px] h-[15px] rounded-[3px] bg-[${elementBreakpointSpecificStyleObject?.[field]}]`}
							/>
					}
				</Popover.Button>

				<Popover.Panel
					className={`${customIcon ? "bottom-[100%]" :"top-8"} absolute z-[51]  -left-44`}
				>
					<SketchPicker
						// color={elementBreakpointSpecificStyleObject?.[field]}
						color={field == "boxShadow" ? `${boxShadowColor}` : elementBreakpointSpecificStyleObject?.[field]}
						onChange={(color) => {
							const value = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
							convertClr(value)
							if (field == "boxShadow") {
								// const shadow = elementBreakpointSpecificStyleObject?.["boxShadow"]?.split("_")
								const Y = boxShadow?.Y
								const X = boxShadow?.X
								const S = boxShadow?.S
								const B = boxShadow?.B

								setElementBreakpointSpecificStyleObject((prev) => {
									return {
										...prev,
										["boxShadow"]: `${Y}_${X}_${S}_${B}_${value}_${boxType}`
									};
								}
								);
								setBoxShadow({
									...boxShadow,
									C: value
								})
								clearTimeout(timerref.current);
								timerref.current = setTimeout(
									() => {
										updateDesign(
											"boxShadow",
											`${Y}_${X}_${S}_${B}_${value}_${boxType}`
										);
									},
									1000
								);
							} else {
								setElementBreakpointSpecificStyleObject((prev) => {

									return {
										...prev,
										[field]: value
									}
								});
								clearTimeout(timerref.current);
								timerref.current = setTimeout(() => {
									updateDesign(field, value);
								}, 1000);
							}
						}}
						presetColors={presetColors}
					/>
				</Popover.Panel>
			</Popover>
		</>
	)
}

export default ColorPicker;