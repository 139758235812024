import { useContext, useEffect, useState } from "react";
import { context } from "../../../context/Context";
import { Formik } from "formik";
import { AddToUndo } from "../../../screens/account/editor";
const SocialLinksSettings = () => {
	const { component, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo, selectedSiteData, setComponent } = useContext(context);
	const [platformsPresent, setPlatformsPresent] = useState([]);
	const [linksSchemas, setLinksSchemas] = useState([])
	const [links, setLinks] = useState({
		Mail: {
			shareOnPlatform: {
				value: "mailto:?subject=Check this out&body=https://qafto.com/",
				sliceLength: 36
			}
		},
		Twitter: {
			openProfile: {
				value: "https://twitter.com/QaftoDotCom",
				sliceLength: 20
			},
			shareOnPlatform: {
				value: "https://twitter.com/intent/tweet?text=Check%20this%20out&url=https://qafto.com/",
				sliceLength: 61
			}
		},
		Facebook: {
			openProfile: {
				value: "https://www.facebook.com/qafto",
				sliceLength: 25
			},
			shareOnPlatform: {
				value: "https://www.facebook.com/sharer/sharer.php?u=https://qafto.com/",
				sliceLength: 45
			}
		},
		WhatsApp: {
			shareOnPlatform: {
				value: "https://api.whatsapp.com/send?text=https://qafto.com/",
				sliceLength: 35
			}
		},
		LinkedIn: {
			openProfile: {
				value: "https://www.linkedin.com/showcase/qafto",
				sliceLength: 34
			},
			shareOnPlatform: {
				value: "https://www.linkedin.com/shareArticle?mini=true&url=https://qafto.com/",
				sliceLength: 52
			}
		},
		YouTube: {
			openProfile: {
				value: "https://www.youtube.com/@Qafto",
				sliceLength: 24
			}
		},
		Instagram: {
			openProfile: {
				value: "https://www.instagram.com/qaftodotcom",
				sliceLength: 26
			}
		},
		Pinterest: {
			openProfile: {
				value: "https://in.pinterest.com/QaftoDotCom",
				sliceLength: 25
			}
		}
	});

	const obj = {
		openProfile: ["Twitter", "Facebook", "LinkedIn", "YouTube", "Instagram", "Pinterest"],
		shareOnPlatform: ["Mail", "Twitter", "Facebook", "WhatsApp", "LinkedIn"]
	};
	useEffect(() => {
		const platformsPresentInSchema = [];
		const linkElements = [];
		const linksData = links;
		for (let i = 0; i < component.children?.length; i++) {
			abc(component.children[i], platformsPresentInSchema, linkElements, linksData);
		}
		setPlatformsPresent(platformsPresentInSchema);
		setLinksSchemas(linkElements);
		setLinks(linksData);
	}, [component]);
	const abc = (schema, platformsPresentInSchema, linkElements, linksData) => {
		if (schema.data?.platform) {
			if (obj[component.data.type].includes(schema.data.platform)) {
				linksData[schema.data.platform][component.data.type].value = schema.data.href;
			}
			platformsPresentInSchema.push(schema.data.platform);
			linkElements.push(schema);
		}
		if (schema.children) {
			for (let i = 0; i < schema.children.length; i++) {
				abc(schema.children[i], platformsPresentInSchema, linkElements, linksData);
			}
		}
	}
	const def = (schema, linksData, type) => {
		if (schema.data?.platform) {
			schema.data.href = linksData[schema.data.platform][type].value
		}
		if (schema.children) {
			for (let i = 0; i < schema.children.length; i++) {
				def(schema.children[i], linksData, type);
			}
		}
	}

	return (
		<>
			<Formik
				enableReinitialize={false}
				initialValues={{
					type: component?.data?.type,
					platformsToShow: component?.data?.platformsToShow,
					linksData: links
				}}
				onSubmit={async (values) => {
					const { linksData, ...rest } = values;
					for (let i = 0; i < component.children.length; i++) {
						def(component.children[i], linksData, values.type);
					}
					component.data = { ...component.data, ...rest };
					AddToUndo(selectedSiteData.static, blocks, sqliteSchema, strapiSchema, undo, setUndo, setRedo);

				}}
			>
				{
					(formik) => (
						<form onSubmit={formik.handleSubmit} className="space-y-5">
							<div className="flex justify-between">
								<div className="font-medium">Type</div>
								<div className="space-y-2">
									<div className='space-x-2'>

										<input type="radio" name="type" id="openProfile" value={'openProfile'} className='text-indigo-600 focus:ring-0' checked={formik.values.type == 'openProfile'}
											onChange={(e) => {
												formik.setFieldValue('type', e.target.value);
												const arr = [];
												for (let i = 0; i < formik.values.platformsToShow.length; i++) {
													if (obj[e.target.value].includes(formik.values.platformsToShow[i])) {
														arr.push(formik.values.platformsToShow[i]);
													}
												}
												formik.setFieldValue('platformsToShow', arr);
											}}
											onBlur={formik.handleBlur} />
										<label htmlFor={'openProfile'}>
											Open Profile
										</label>
									</div>
									<div className='space-x-2'>

										<input type="radio" name="type" id="shareOnPlatform" value={'shareOnPlatform'} className='text-indigo-600 focus:ring-0' checked={formik.values.type == 'shareOnPlatform'}
											onChange={(e) => {
												formik.setFieldValue('type', e.target.value);
												const arr = [];
												for (let i = 0; i < formik.values.platformsToShow.length; i++) {
													if (obj[e.target.value].includes(formik.values.platformsToShow[i])) {
														arr.push(formik.values.platformsToShow[i]);
													}
												}
												formik.setFieldValue('platformsToShow', arr);
											}}
											onBlur={formik.handleBlur} />
										<label htmlFor={'shareOnPlatform'} >
											Share On Platform
										</label>
									</div>
								</div>
							</div>
							<div className="flex justify-between">
								<div className="font-medium">Platforms To Show</div>
								<div className="space-y-2">
									{
										platformsPresent.map((value, index) => {
											if (obj[formik.values.type].includes(value)) {

												return (

													<div className='space-x-2' key={index}>
														<input type="checkbox" name="platformsToShow" id={value} value={value} className='text-indigo-600 focus:ring-0' checked={formik.values.platformsToShow.includes(value)} onChange={formik.handleChange} onBlur={formik.handleBlur} />
														<label htmlFor={value} >
															{value}
														</label>
													</div>
												)
											}
										})
									}
								</div>
							</div>
							{
								linksSchemas.map((value, index) => {
									if (formik.values.platformsToShow.includes(value.data?.platform)) {
										return (
											<div key={index} className="space-y-3">
												<div className="font-medium">{value.data.platform}</div>
												<input type="text" className="text-sm p-2 w-full focus:ring-indigo-600 focus:border-indigo-600 rounded-md"
													value={formik.values.linksData[value.data.platform][formik.values.type].value.slice(formik.values.linksData[value.data.platform][formik.values.type].sliceLength)} onChange={async (e) => {
														await formik.setFieldValue('linksData', {
															...formik.values.linksData,
															[value.data.platform]: {
																...formik.values.linksData[value.data.platform],
																[formik.values.type]: {
																	...formik.values.linksData[value.data.platform][formik.values.type],
																	value: formik.values.linksData[value.data.platform][formik.values.type].value.slice(0, formik.values.linksData[value.data.platform][formik.values.type].sliceLength) + e.target.value
																}
															}
														});
													}} />
											</div>
										)
									}
								})
							}
							<button type="submit"
								disabled={formik.isSubmitting}
								className="bg-indigo-600 px-3 py-2 font-medium text-sm rounded-md text-white w-full"
							>
								Save
							</button>
						</form>
					)
				}
			</Formik>

		</>
	)
}
export default SocialLinksSettings;