import React, { useContext, useState } from 'react'
import { context } from '../context/Context';
import { IconBox, IconCaretUpFilled, IconSection, IconSquareFilled, IconX } from '@tabler/icons-react';
import { IconCaretDownFilled } from '@tabler/icons-react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

export default function Layers() {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div className="relative">
			<button
				type="button"
				onClick={() => setIsOpen(!isOpen)}
				className={`text-black space-x-2 group inline-flex items-center rounded-md  px-3 py-2 text-sm font-medium outline-none focus:outline-none`}
			>
				<span>Layers</span>
				{
					isOpen ?
						<ChevronUpIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
					:
						<ChevronDownIcon className="-mr-1 h-5 w-5 font-bold" aria-hidden="true" />
				}
			</button>
			{isOpen && (
				<>
					<div
						// ref={popoverRef}
						onClick={(e) => {
							e.stopPropagation()
						}}
						// className="h-[50vh] overflow-scroll no-scrollbar bg-gra/y-50 w-[300px] absolute left-1/2 z-10   -translate-x-1/2 transform px-4 sm:px-0 ">
						className="h-[50vh] overflow-scroll no-scrollbar absolute bg-gra/y-50 w-[300px] left-0  z-[999]  px-4 sm:px-0 bg-gray-50"
					// style={{
					// 	left:  `${currentPosition.left}px`,
					// 	top:  `${currentPosition.top}px`
					// }}
					// draggable={true}
					// onDrag={(e) => {
					// 	setCurrentPosition({
					// 		top: e.clientY,
					// 		left:  e.clientX
					// 	})
					// }}
					// onDragEnd={(e)=>{
					// 	setCurrentPosition({
					// 		top: e.clientY,
					// 		left:  e.clientX
					// 	})
					// 	console.log(e, "draggable End")
					// }}
					>
						<div className='w-[300px] bg-white border-b-2 border-b-zinc-300 p-2 flex items-center justify-between fixed z-[999]'>
							<span>Layers</span>
							<IconX
								className='cursor-pointer'
								onClick={() => {
									setIsOpen(false)
								}} />
						</div>
						<div className="bg-gray-50 p-4 mt-4">
							<LayersPanel />
						</div>
						{/* </div> */}
					</div>
				</>
			)}
		</div>
	)
}

const LayersPanel = () => {
	const {
		blocks,
		setElementId
	} = useContext(context);
	return (
		<div>
			{
				blocks?.map((data, i) => (
					<AccordianHead data={data} key={i} section={true} index={i} />
				))
			}
		</div>
	)
}

const AccordianHead = ({ data, key, section, index }) => {
	const [opneIndex, setOpenIndex] = useState([])
	const {
		elementId,
		setElementId,
		elementRef,
		customComponent,
		setCordinates,
		isLayerChange,
		setIsLayerChange
	} = useContext(context);

	return (
		<>
			<span className={`flex items-center mt-4 ${elementId?.id == data?.id ? "bg-indigo-500 text-white rounded-sm p-1" : "hover:bg-indigo-100 py-1 text-gray-600"}`} key={key}>
				<span className={`text-xs  cursor-pointer`}
					onClick={(e) => {
						e.stopPropagation();
						// var elem = document.getElementById(data?.id);
						// elem.scrollIntoView({
						// 	behavior: "smooth"
						// });
						if (elementId?.id != data?.id) {
							setIsLayerChange(isLayerChange == "firstTime" ? true : !isLayerChange)
						}
						const el = document.getElementById(data?.id);
						const cordinatesValue = el?.getBoundingClientRect()
						const cordinateData = {
							cordinatesValue,
							id: data?.id,
							isCustom: false,
							elementId: data
						}
						setCordinates(cordinateData)
						if (elementRef.current != null) {
							elementRef.current.classList.remove("outlineHover")
							elementRef.current.style.outline = "none"
						}
						if (customComponent ? false : true) {
							elementRef.current = document.getElementById(data?.id);
							elementRef.current.style.outline = "1px solid #6366F1"
						}
						setElementId(data)
					}}
				>
					{
						!opneIndex.includes(key) ?
							<IconCaretDownFilled className='h-4 w-4 inline bg'
								onClick={(e) => {
									e.stopPropagation()
									setOpenIndex([...opneIndex, key])
								}}
							/>
							:
							<IconCaretUpFilled className='h-4 w-4 inline bg'
								onClick={(e) => {
									e.stopPropagation()
									if (opneIndex.includes(key)) {
										const indexOf = opneIndex.indexOf(data.id)
										opneIndex.splice(indexOf, 1)
										setOpenIndex([...opneIndex])
									}
								}}
							/>
					}
					{
						index >= 0 ?
							<IconSection className={`h-4 w-4 inline mx-2 ${elementId?.id == data?.id && "text-white"} `} />
							:
							<IconSquareFilled className={`h-4 w-4 inline mx-2  border-0 rounded-sm  ${elementId?.id == data?.id ? "text-white bg-white" : "bg-gray-700 text-gray-700"}`} />
					}

					{section ? `Section${index + 1}` : data?.id}
				</span>
			</span>
			{
				opneIndex?.includes(key) &&
				data?.children?.map((child, i) => (
					<AccordianChild data={child} key={i} />
				))
			}
		</>
	)
}

const AccordianChild = ({ data, key }) => {
	const {
		elementId,
		setElementId,
		elementRef,
		customComponent,
		setCordinates,
		isLayerChange,
		setIsLayerChange
	} = useContext(context);
	return (
		<>
			{
				data?.children ?
					<span className="flex items-center ml-3" key={key}>
						<span >
							<AccordianHead data={data} key={key} />
						</span>
					</span>
					:
					<span className={`flex items-center mt-2 ml-3 cursor-pointer ${elementId?.id == data?.id ? "bg-indigo-500 text-white rounded-sm" : "hover:bg-indigo-100"} `} key={key}>
						<span
							onClick={(e) => {
								// var elem = document.getElementById(data?.id);
								// elem.scrollIntoView({
								// 	behavior: "smooth"
								// });
								// valueY = -500
								// func(e, "ScrollIntoView")
								if (elementId?.id != data?.id) {
									setIsLayerChange(isLayerChange == "firstTime" ? true : !isLayerChange)
								}
								const el = document.getElementById(data?.id);
								const cordinatesValue = el?.getBoundingClientRect()
								const cordinateData = {
									cordinatesValue,
									id: data?.id,
									isCustom: false,
									elementId: data
								}
								setCordinates(cordinateData)
								if (elementRef.current != null) {
									elementRef.current.classList.remove("outlineHover")
									elementRef.current.style.outline = "none"
								}
								if (customComponent ? false : true) {
									elementRef.current = document.getElementById(data?.id);
									if (elementRef?.current?.style) {
										elementRef.current.style.outline = "1px solid #6366F1"
									}
								}
								setElementId(data)
							}}
							className='truncate w-[60%] flex items-center'
						>
							{
								data?.type == "img" ?
									<span class={`mdi mdi-image-outline text-lg text-gray-600 mr-2 ${elementId?.id == data?.id && "text-white"} `}></span>
									:
									data?.type == "button" ?
										<IconBox class={`w-4 h-4 text-gray-600 mr-2 inline ${elementId?.id == data?.id && "text-white"} `} />
										:
										data?.content ?
											<span class={`mdi mdi-format-text text-xl text-gray-600 mr-1 ${elementId?.id == data?.id && "text-white"} `}></span>
											:
											<span class={`mdi mdi-dots-square text-lg text-gray-600 mr-2 ${elementId?.id == data?.id && "text-white"} `}></span>
							}
							{/* <p className='truncate w-[60%]'> */}
							{data?.content ? data?.content : data?.id}
							{/* </p> */}
						</span>
					</span>
			}
		</>
	)
}
